@use "global" as g;

/*
  ---------------
  ディストリビューション詳細 |
  ---------------
*/
#distribution .head-contents {}

// 商品説明
#distribution .head-contents .description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71428571;
  margin-top: 20px;
}

// ヘッダーの非表示コンテンツ
#distribution #category-txc .txc-header {
  display: none;
}

#distribution .pwk-title {
  display: none;
}