@use "global" as g;

/*
  ---------------
  TOP | お知らせ
  ---------------
*/
.select-top .newslist-info {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  div.news-entries div.news-entry {
    border-top: 1px solid g.$border-gray;
  }

  div.news-entries div.news-entry div.entry-text {
    border: 0;
    padding: 0;
  }

  .news-entry:last-child {
    border-bottom: 1px solid g.$border-gray;
  }

  div.news-entries div.header {
    @media all and (max-width: 800px) {
      display: flex;
    }

    @media #{g.$sp} {
      display: block;
    }
  }

  div.news-entries div.header div.meta div.release-date {
    margin-right: 0;
  }

  .news-archives-link {
    margin-top: 0;
  }

  .news-archives-link a {
    border: 0;
    border-radius: 0;
  }

  /*==========================================
    スタイル追加
  ==========================================*/
  margin-top: 40px;

  @media #{g.$sp} {
    margin-top: 50px;
  }

  .panel-newslist {
    padding-right: 3.05343511vw;
    padding-left: 3.05343511vw;

    @media #{g.$pc} {
      max-width: 1280px;
      margin: 0 auto;
      padding-top: 7px;
      padding-right: clamp(0px, 2.77777778vw, 40px);
      padding-left: clamp(0px, 2.77777778vw, 40px);
    }

    position: relative;
  }

  .news-name {
    color: g.$text-black-tertiary;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.44;

    @media #{g.$sp} {
      text-align: center;
    }

    @media #{g.$pc} {
      font-size: 30px;
      line-height: 1.43333333;
    }
  }

  div.panel-heading + div.panel-body {
    margin-block: 30px 0;
  }

  .entry-text {
    width: 100%;
  }

  .release-date {
    color: g.$text-black-secondary;
    font-size: 13px;
    font-weight: 500;

    @media #{g.$pc} {
      font-size: 15px;
    }
  }

  .header {
    width: 100%;

    @media #{g.$sp} {
      padding-top: 14px;
    }

    @media #{g.$pc} {
      padding: 0 6px;

      display: flex;
      align-items: center;
    }
  }

  // li link wrapper
  .headline {
    width: 100%;

    @media #{g.$pc} {
      margin-left: 40px;
    }
  }

  // li link
  .headline a {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857143;
    padding: 3px 0 18px;

    @media #{g.$pc} {
      font-size: 15px;
      line-height: 1.46666667;
      padding: 23px 0;
    }

    .article {
      color: #494949;
      display: block;
      font-weight: 400;
      line-height: 1.4479;
      margin-top: 10px;
    }
  }

  .headline a:hover {
    color: g.$text-green-secondary;
    opacity: 1;
    transition: all .2s ease-in-out;
  }

  // お知らせ一覧ボタン
  .news-archives-link {

    @media #{g.$sp} {
      margin-top: 17px;
    }

    @media #{g.$pc} {
      position: absolute;
      top: 0;
      right: 40px;
    }
  }

  .news-archives-link a {
    @include g.buttonNews(select);
  }

}
