/*
  ---------------
  Fonts file
  ---------------
*/
// Noto Serif JP
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url('/f/resources/fonts/NotoSansJP-Regular.woff2') format('woff2'),
  url('/f/resources/fonts/NotoSansJP-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url('/f/resources/fonts/NotoSansJP-Medium.woff2') format('woff2'),
  url('/f/resources/fonts/NotoSansJP-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url('/f/resources/fonts/NotoSansJP-Bold.woff2') format('woff2'),
  url('/f/resources/fonts/NotoSansJP-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url('/f/resources/fonts/NotoSansJP-Medium.woff2') format('woff2'),
  url('/f/resources/fonts/NotoSansJP-Medium.woff') format('woff');
}

@font-face {
  font-family: 'M PLUS 2';
  font-style: normal;
  font-weight: 700;
  src: url('/f/resources/fonts/MPLUS2-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'M PLUS 2';
  font-style: normal;
  font-weight: 900;
  src: url('/f/resources/fonts/MPLUS2-Black.ttf') format('truetype');
}
