@use "global" as g;

/*
  ---------------
  コミュニティ一覧
  ---------------
*/

body#community__boardlist {
  .boardlist {
    position: relative;
  }

  .boardlist .root-category {
    margin-top: 60px;

    @media #{g.$sp} {
      margin-top: 44px;
    }
  }

  .boardlist .root-category:first-child {
    margin-top: 25px;

    @media #{g.$sp} {
      margin-top: 22px;
    }
  }

  .boardlist .category {
    margin-top: 0;
  }

  .boardlist .header {
    padding-top: 8px;
    padding-bottom: 19px;
    position: relative;

    @media #{g.$sp} {
      padding-bottom: 14px;
    }
  }

  .boardlist .header::before,
  .boardlist .header::after {
    content: "";
    background-color: #005C69;
    display: block;
    height: 1px;
    width: 25%;

    position: absolute;
    top: 0;
    left: 0;

    @media #{g.$sp} {
      width: 54%;
    }
  }

  .boardlist .header::after {
    background-color: #D9D9D9;
    width: 75%;

    right: 0;
    left: auto;

    @media #{g.$sp} {
      width: 46%;
    }
  }

  .boardlist .header .breadcrumbs {
    display: none;
  }

  .boardlist .header .category-name {
    border-bottom: none;
    color: #005C69;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.448;
  }

  .boardlist .content ol {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    @media #{g.$sp} {
      gap: 40px 20px;
    }
  }

  .boardlist .content ol li {
    width: calc((100% - 60px) / 3);

    @media #{g.$sp} {
      width: calc(50% - 10px);
    }
  }

  .boardlist .content .board-summary a {
    width: 100%;
  }

  .boardlist .content .board-summary a:has(.uri-to-img) {
    border: 1px solid g.$border-gray;
    padding: 10px;
  }

  .boardlist .content .board-summary img {
    @media #{g.$pc} {
      object-fit: contain;
      height: 145px;
      width: 100%;
    }
    @media #{g.$sp} {
      width: 100%;
    }
  }

  .boardlist .content .text {
    padding-bottom: 20px;
    position: relative;

    @media #{g.$sp} {
      padding-bottom: 24px;
    }
  }

  .boardlist .content .heading {
    margin-top: 12px;

    @media #{g.$sp} {
      margin-top: 10px;
    }
  }

  .boardlist .content .name {
    color: #000;
    font-family: Noto Sans CJK JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    @media #{g.$sp} {
      font-size: 14px;
    }
  }

  .boardlist .content .threads-count {
    background-color: #F6F6F6;
    border-radius: 2px;
    color: #005C69;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    height: 20px;
    min-width: 86px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0;
    left: 0;

    @media #{g.$sp} {
      font-size: 14px;
      height: 24px;
      min-width: 108px;
    }

  }

  .boardlist .content .body {
    margin: 7px 0 13px;

    @media #{g.$sp} {
      margin: 4px 0 5px;
    }
  }

  .boardlist .content .description {
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4479;

    @media #{g.$sp} {
      font-size: 14px;
    }
  }
}