@use "global"as g;
/*
  ---------------
  ご利用ガイドトップ
  ---------------
*/

.guide-mypage {}

.guide-mypage .guide-mypage__wrap {
  @include g.container();
  padding-top: 60px;

  @media #{g.$sp} {
    padding-top: 50px;
  }
}

.guide-mypage .guide-mypage__body {
  padding-top: 16px;

  display: flex;
  gap: 64px;

  @media #{g.$sp} {
    padding-top: 15px;

    align-items: center;
    flex-direction: column;
    gap: 26px;
  }
}

.guide-mypage .guide-mypage__figure {
  max-width: 476px;
  width: 100%;

  img {
    width: 476px;
  }
}

.guide-mypage .guide-mypage__text {
  font-size: 18px;

  @media #{g.$sp} {
    font-size: 14px;
    line-height: 2;
  }
}
