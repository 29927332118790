@use "global"as g;

/*
  ---------------
  フォーム共通
  ---------------
*/
/*--------------------------------------------------
  ログイン
--------------------------------------------------*/
.form.form-order-signin {
  padding-top: 40px;
}

.formrow .input-control .input-group {
  position: relative;
}

.password-str-meter {
  color: g.$text-gray-primary;
  font-size: 14px;
  text-align: right;
  width: 100%;
  // 横のラベル縦位置調整のため
  position: absolute;
  right: 0;
  bottom: -20px;
}

.form .reminder,
.form #signout-at-signin {
  text-align: center;
  padding-top: 8px;
}

.form .reminder a {
  font-size: 16px;
  text-decoration: underline;
}

.formrow .input-control .input-group .signout a {
  font-size: 14px;
  text-decoration: underline;
}

// パスワードの変更
.form-edit-account {
  .password-str-meter {
    bottom: -10px;
    position: relative;

    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-end;
  }

  .new-password .password-str-meter .label {
    background: g.$bg-black-primary;
    border-radius: 2px;
    color: g.$text-white-primary;
    font-size: 12px;
    padding: 2px 10px 3px;
  }

  .new-password .password-str-meter .msg {
    background: g.$bg-white-primary;
    border: 1px solid g.$border-black;
    border-radius: 0 2px 2px 0;
    font-size: 12px;
    margin-inline: -7px auto ;

    color: g.$text-red-primary;
    line-height: 1.7;
    letter-spacing: 5px;
    text-indent: 5px;
  }
}
/*--------------------------------------------------
  フォーム全般
--------------------------------------------------*/
.formrow .input-control {
  @media #{g.$sp} {
    padding-top: 8px;
  }
}

.formrow .input-control .input-note {
  @media #{g.$sp} {
    padding-top: 8px;
  }
}
// 必須の調整
.form .formrow div.input-label span.input-name {
  @media #{g.$sp} {
    margin-right: 10px;
  }
}

body .form div.form-note,
.form .formrow {
  @media #{g.$sp} {
    padding-inline: 0;
  }
}

// fieldsetがないフォームの調整
.entry_password_reminder .form-body,
.order__points .form-points .form,
.order__sender .form-sender .form .input-group-address,
.order__deliveryservice .form-deliveryservice .form-body,
.update_password .form-body {
  background: g.$bg-gray-primary;
  margin: 1em 0;
  padding: 40px 45px;

  @media #{g.$sp} {
    padding: 20px 16px;
  }
}

// パスワードを表示・非表示
form .formrow {
  .password-str-meter {
    bottom: 0;
    margin: 0;
    padding-top: 8px;
    position: relative;
  }

  .password-str-meter .unmask-pass span {
    @include g.hoverButton;
    font-size: 14px;
    position: relative;
    width: max-content;

    @media #{g.$pc} {
      position: absolute;
      top: -31px;
      right: 20px;
    }
  }

  .password-str-meter .unmask-pass span::before {
    @media #{g.$pc} {
      background: url(/f/resources/images/common/icon_eye.svg) 50% / contain no-repeat;
      content: '';
      display: block;

      height: 13px;
      width: 20px;

      position: absolute;
      top: 50%;
      left: -24px;
      transform: translateY(-50%);
    }
  }

  input[name=PASSWORD],
  input[type=password] {
    @media #{g.$pc} {
      padding-right: 170px;
    }
  }
}

// フォーム全体
#form,
.order__signin,
.order__payment,
.order__orderer,
.update_password {
  fieldset {
    background: g.$bg-gray-primary;
    border-radius: 0;
    border: none;
    margin-bottom: 0;
    padding: 40px 45px;

    @media #{g.$sp} {
      padding: 20px 16px;
    }
  }
}

.account__order_inquiry,
.account__close_account,
.account__edit_address,
.account__edit_account,
.account__edit_account_properties,
.account__edit_profile,
.account__order,
.account__manage_addressbook,
.account__manage_paymentmethod,
.account__view_account_message_history,
.account__view_orders,
.account__view_order_history,
.account__view_inquiries,
.account__view_points_history,
.account__your_coupons,
.create-new-thread,
.entry_password_reminder,
#form,
.order__confirm,
.order__signin_to_checkout,
.order__signin,
.order__payment,
.order__points,
.order__sender .form-sender .form,
.order__consignee .form-consignee .form,
.order__deliveryservice .form-deliveryservice .form-body,
.order__orderer .form-signin form,
.order__orderer,
.post-thread-comment-wrapper,
.update_password {

  fieldset+fieldset {
    border-top: 1px solid g.$border-gray;
    margin: 0;
  }

  .formrow {
    border: none;

    display: flex;
    flex-direction: column;

    @media #{g.$sp} {
      padding: 0;
    }
  }

  .formrow:not(:first-child) {
    margin-top: 16px;
  }

  .formrow .input-label {
    display: flex;
    align-items: center;
  }

  .formrow .input-label,
  .formrow .input-control {
    padding: 0;
  }

  .formrow .input-label span.input-name {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
  }

  .formrow .input-control {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 10px;
  }

  .formrow .input-control .input-note {
    margin-left: 0;
    padding-left: 0;
  }

  .formrow input.required {
    background: g.$bg-white-primary;
  }

  .formrow input {
    border: 1px solid g.$border-gray;
  }

  .formrow.formrow-file input {
    border: none;
  }

  .formrow-yyyymmdd .input-group {
    display: flex;
    gap: 10px;

    @media #{g.$sp} {
      flex: 1px 1px 0;
    }
  }

  .formrow-yyyymmdd .date-yyyy {
    min-width: 105px;
  }

  .formrow select {
    @include g.hoverButton;
    appearance: none;
    background: g.$bg-gray-primary url(/f/resources/images/common/icon_arrow_down_circle.svg) calc(100% - 10px) 50% / 24px no-repeat;
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    padding: 10px;

    max-width: 150px;
    width: 150px;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .formrow-yyyymmdd .date-delim {
    display: none;
  }

  .formrow .input-label span.input-required {
    background: g.$bg-red-secondary;
    border-radius: 2px;
    color: g.$text-white-primary;
    font-weight: 500;
  }

  .formrow-radio {
    .input-group {
      padding: 0;

      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    .input-control .input-group-radio .radio-item {
      @media #{g.$sp} {
        width: 100%;
      }
    }

    label {
      border: 1px solid g.$border-gray;
      border-radius: 2px;
      cursor: pointer;
      padding: 10px 14px 10px 10px;

      display: flex;
      align-items: center;
    }

    input {
      accent-color: g.$bg-green-primary;
      border: 1px solid g.$border-gray;
      margin: 0 8px 0 0;

      height: 20px;
      width: 20px;
    }
  }

  .formrow-checkbox {
    .input-group {
      padding: 0;

      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    label {
      cursor: pointer;
      border: 1px solid g.$border-gray;
      border-radius: 2px;
      font-size: 14px;
      padding: 10px 14px 10px 10px;

      display: flex;
      align-items: center;
    }

    input {
      accent-color: g.$bg-green-primary;
      margin: 0 8px 0 0;
      height: 20px;
      width: 20px;
    }
  }
}

// 必須マーク
.form.signin-form .form-body.form-signin form .input-label .input-required,
.formrow .input-label .input-required,
.formrow .input-label .input-may-required,
#signup .signup-form .form-signup .formrow-input .input-label .input-required,
#signup .signup-form .form-signup .formrow-radio .input-label .input-required,
#signup-form .input-label .input-required、
#signup .signin-form .form-signin .formrow .input-label .input-required {
  background: g.$bg-red-secondary;
  border: 0;
  color: g.$text-white-primary;
  float: none;
  font-size: 12px;
  font-weight: 500;
	padding:1px 6px 2px;

  display: grid;
  place-items: center;
}

/*--------------------------------------------------
signin,signup | 注文・マイページ
--------------------------------------------------*/
#account__close_account,
#account__edit_account,
#account__edit_account_properties,
#account__edit_profile,
#account__order,
#account__manage_addressbook,
#account__manage_paymentmethod,
#account__view_account_message_history,
#account__view_orders,
#account__view_points_history,
#account__view_inquiries,
#account__view_order_history,
#account__your_coupons,
#community__talk .thread-comments .report-form .form,
#entry_password_reminder,
#form,
#order__signin_to_checkout,
#order__signin,
#order__confirm,
#order__sender,
#update_password {
  fieldset {
    background: g.$bg-gray-primary;
  }
}

#account__close_account,
#account__edit_address,
#account__edit_account,
#account__edit_account_properties,
#account__edit_profile,
#account__order,
#account__manage_addressbook,
#account__manage_paymentmethod,
#account__view_account_message_history,
#account__view_orders,
#account__view_points_history,
#account__view_inquiries,
#account__view_order_history,
#account__your_coupons,
#community__talk .thread-comments .report-form .form,
#community__commentlist .thread-comments .report-form .form,
#entry_password_reminder,
#form,
#order__signin_to_checkout,
#order__signin,
#order__confirm,
#order__orderer,
#order__sender,
#update_password {
  #outer {
    @include g.container();
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
    padding: 0;
  }

  .form {
    @media all and (min-width : 800px) {
      padding: 0;
    }
  }

  .form-order-signin,
  .form-order-signup,
  .form-signin form,
  .form-edit-account form {
    background-color: g.$bg-gray-primary;
    padding: 40px 45px;
    margin-top: 40px;

    @media #{g.$sp} {
      padding: 20px 15px;
    }
  }

  .new-password {
    margin-top: 40px;
  }

  legend {
    background: transparent;
    color: g.$text-green-primary;
    font-size: 14px;
  }

  .input-group-address .formrow {
    border-bottom: none;
  }

  // ラジオボタン・チェックボックス
  .formrow-checkbox .input-group,
  .formrow-radio .input-group {
    padding: 0;

    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .formrow-checkbox label,
  .formrow-radio label {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    cursor: pointer;
    padding: 9px 14px 9px 10px;
    position: relative;

    display: flex;
    align-items: center;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .form-signin .formrow-radio label {
    padding: 9px 14px 9px 38px;
  }

  #checkout_form .formrow-checkbox label {
    border: none;
  }

  // ラジオボタン
  .formrow-radio {

    label.is-checked {
      border: 1px solid g.$border-green;
    }
  }

  // チェックボックス
  .formrow-checkbox {
    .input-group {
      padding: 0;

      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    label.is-checked {
      border: 1px solid g.$border-green;
    }
  }

  // セレクト
  select.date,
  .formrow-yyyymmdd select {
    background: transparent url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
    background-position: right 10px center;
    background-size: 24px;
    color: g.$text-black-primary;
    padding-block: 10px;
    padding-inline: 10px 8px;
    width: 100%;
    max-width: 150px;
    min-width: 150px;

    display: inline-flex;
    align-items: center;

    @media #{g.$sp} {
      min-width: 94px;
      padding-inline: 8px;
    }
  }

  .acc-signup legend {
    display: none;
  }

  .formrow-submit .pp-dscr {
    font-size: 12px;
    line-height: 1.41666667;
  }

  .btn-submit {
    margin-top: 10px;
  }

  .reminder {
    font-size: 16px;
    padding-top: 40px;
    text-align: center;
  }
}

#account__order_inquiry,
#form {
  #outer {
    @include g.container();
    padding-top: 40px;
  }

  #outer:has(.form-auth) {
    padding-block: 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 400;
    padding: 0;

    @media #{g.$sp} {
      padding-right: 154px;
    }
  }

  .form-form h3 {
    @media #{g.$sp} {
      padding-right: 0;
    }
  }

  form.form {
    background: g.$bg-gray-primary;
    margin-top: 40px;
    padding: 40px 45px;

    @media #{g.$sp} {
      padding: 20px 16px;
    }
  }

  .formrow-file_upload .optional {
    border: none;
  }

  .after-confirm {
    padding-top: 10px;
  }

  .formrow-submit .btn.btn-confirm,
  .after-confirm .btn.btn-sending {
    background: g.$button-orange;
    border: none;
    box-shadow: none;
    color: g.$text-white-primary !important;
  }

  .after-confirm .btn + .btn {
    box-shadow: none;

    @media #{g.$sp} {
      margin-top: 20px;
      margin-left: 0;
    }
  }

  .formrow-submit button {
    background-image: none;
  }
}

/*--------------------------------------------------
signin,signup
--------------------------------------------------*/
/* サインイン・サインアップ・注文時サインイン */
#signin .authform-anchor__signin,
#signup .authform-anchor__signup,
#order__signin_to_checkout .authform-anchor__signin {
  display: none;
}

#signin .authform-anchor,
#signup .authform-anchor,
body#order__signin_to_checkout .authform-anchor {
  font-size: 18px;
  margin-top: 40px;
  padding-bottom: 20px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;
  gap: 10px;

  @media #{g.$sp} {
    flex-direction: column;
  }
}

#signup .authform-anchor {
  flex-direction: row-reverse;

  @media #{g.$sp} {
    flex-direction: column-reverse;
  }
}

.form-auth.form-signin .authform-anchor + .signin-form,
#signin #container .authform-anchor + .signin-form {
  margin-top: 20px;

  @media #{g.$sp} {
    margin-top: 20px;
  }
}

.authform-anchor__signin,
.authform-anchor__signup {
  color: g.$text-blue-primary;
  text-decoration: underline;
}