@use "global" as g;

/*
  ---------------
  コミュニティ | 新規スレッドの作成
  ---------------
*/

body#community__newthread,
body#community__threadcreated {
  .community .link-search {
    background-color: transparent;
    border: none;
    height: 50px;
    padding: 0;

    display: flex;
    justify-content: center;

    @media #{g.$sp} {
      height: 40px;
      margin-top: 20px;
    }
  }

  .create-new-thread {
    padding-top: 40px;
  }
}

// 完了ページ
body#community__threadcreated {
  h2 {
    margin-top: 40px;
    text-align: center;
  }

  .link ul {
    padding-top: 40px;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
}