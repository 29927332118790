@use "global" as g;
/*
  ----------------
  注文詳細
  ----------------
*/
body#account__order {
  .shipment-contents div.delivery-request {
    display: none;
  }

  div.itemdetail-row .item {
    width: 100%;
  }

  div.item-thumbnail-img img {
    object-fit: contain;
  }

  .tracking-number .delivery-tracking a {
    text-decoration: underline;
  }

  .itemdetails-body .itemdetail-row {
    position: relative;
  }

  .itemdetail-row .extra-controls {
    display: none;
    background-color: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
    padding: 16px;

    position: absolute;
    bottom: 20px;
    right: 0;

    @media #{g.$sp} {
      position: static;
      margin-top: 20px;
      width: 100%;
    }
  }

  .extra-controls .merchandise-contents {
    display: flex;
    align-items: center;

    @media #{g.$sp} {
      flex-direction: column;
    }
  }

  .extra-controls .merchandise-contents .heading {
    font-size: 16px;
    margin-right: 16px;

    @media #{g.$sp} {
      margin-right: 0;
    }
  }

  .extra-controls .merchandise-contents > ul {
    @media #{g.$sp} {
      margin-top: 12px;
      width: 100%;
    }
  }

  .extra-controls .merchandise-contents .btn {
    background: g.$bg-gray-tertiary;
    border-radius: 2px;
    color: g.$text-white-primary !important;
    padding: 8px 12px;

    @media #{g.$tab-under} {
      font-size: 16px;
      width: 100%;
    }
  }
}