@use "global"as g;

/*
  ---------------
  カート | トップ
  ---------------
*/
body#cart {
  .cart-contents>h3 {
    margin-top: 22px;
    padding: 0;
  }

  .itemdetails-cart {
    margin-top: 30px;

    @media #{g.$sp} {
      padding: 0;
    }
  }

  .itemdetails-cart + .itemdetails-cart {
    margin-top: 40px;
  }

  .itemdetails-cart>h3 {
    background-color: g.$bg-gray-secondary;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 18px;
  }

  // パートワーク購読で"UPS"がコードに含まれない商品に文字列を追加
  .itemdetails-cart.pwk-exclusive .itemdetail-row:not([data-distribution*="UPS"]) .entry-item .item-name > a::after,
  .itemdetails-cart.pwk-exclusive .itemdetail-row:not([data-distribution*="UPS"]) .entry-item .item-name > .my-item-name::after {
    content: 'から定期購読';
  }

  .cart-msgs.posterior {
    padding: 20px 0;
  }

  .cart-message-for-dlv-fee .message {
    background-color: g.$bg-gray-primary;
    border: 1px solid g.$bg-green-primary;
    color: g.$bg-green-primary;
    font-size: 18px;
    font-weight: 500;
    padding: 12px;
    text-align: center;
  }

  .cart-msg-no_payments {
    margin-top: 20px;
  }

  .cart-msgs .note {
    @media #{g.$sp} {
      font-size: 14px;
    }
  }

  div.alert.alert-cart-empty {
    padding: 40px 20px;
    margin: 40px 0;

    @media #{g.$sp} {
      padding: 20px 10px;
      margin: 20px 0;
    }
  }

  .cart-contents-saved > h3 {
    font-size: 20px;
    font-weight: 500;
    padding: 0;

    @media #{g.$sp} {
      font-size: 18px;
    }
  }

  .cart-contents-saved {
    padding-top: 50px;
  }

  .cart-contents-saved .itemdetails-header {
    display: none;
  }

  .cart-contents-saved .itemdetails-body {
    margin-top: 16px;

    @media (max-width: 800px) {
      margin-top: 12px;
    }
  }

  .cart-contents-saved .itemdetail-row .item .control {
    @media #{g.$sp} {
      position: relative;
      bottom: unset;
    }
  }

  .cart-contents-saved .itemdetail-row .item .wrapper {
    @media #{g.$sp} {
      flex-direction: row-reverse;
      gap: 20px;
    }
  }

  .display-box-related-cart {
    padding-top: 40px;

    @media #{g.$sp} {
      padding-top: 50px;
    }
  }

  .display-box-related-cart .panel-heading .display-name {
    font-size: 20px;
    font-weight: 500;
    text-align: left;

    @media #{g.$sp} {
      font-size: 18px;
    }
  }

  .display-box-related-cart .item-thumbnail-img-sm {
    height: auto;
    width: 100%;
    aspect-ratio: 1/1;
  }

  .display-box-related-cart .item-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
  }

  .display-box-related-cart .display .item-name {
    font-size: 14px;
    margin-top: 16px;
    padding: 0;
    text-align: left;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
  }

  .display-box-related-cart .display .selling-price {
    font-size: 14px;
    font-weight: 500;
  }

  .display-box-related-cart .slick-next {
    @media #{g.$sp} {
      right: -13px;
    }
  }

  .display-box-related-cart .slick-prev {
    @media #{g.$sp} {
      left: -13px;
    }
  }
}
