@use "global"as g;
/*
  ---------------
  スライダー拡大プレビュー機能
  ---------------
*/
.m-preview {
  display: none;

  @media (min-width: 1025px) {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-gray-dark;
    width: 446px;
    height: 446px;
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
  }

  &.active {
    @media (min-width: 1025px) {
      display: block;
    }
  }

  .partwork-product & {
    @media (min-width: 1025px) {
      width: 560px;
      height: 560px;
    }
  }
}

.m-preview img {
  @media (min-width: 1025px) {
    max-width: none;
  }
}

.m-preview__lens {
  @media (min-width: 1025px) {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}

.m-preview__lens-container {
  @media (min-width: 1025px) {
    cursor: crosshair;
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.m-preview__lens-cursor {
  @media (min-width: 1025px) {
    background-color: #06f;
    display: none;
    width: 150px;
    height: 150px;
    opacity: .3;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.m-preview__lens-container:hover .m-preview__lens-cursor {
  @media (min-width: 1025px) {
    display: block;
  }
}
