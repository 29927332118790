@use "global" as g;
/*
  ----------------
  message common
  ----------------
*/
.l-message {
  background-color: g.$bg-red-primary;

  &__inner {
    padding: 5px 0;

    display: flex;
    align-items: center;

    @media #{g.$pc} {
      padding: 8px 0;
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__title-img {}

  &__title-text {
    color: g.$text-red-primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4375;
    margin-left: 2.79898219vw;

    @media #{g.$pc} {
      margin-left: 0.34722222vw;
    }
  }

  &__date {
    color: g.$text-red-primary;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.41666667;

    @media #{g.$pc} {
      font-size: 14px;
      line-height: 1.42857143;
      margin-left: 2.36111111vw;
    }
  }

  &__text {
    color: g.$text-red-primary;
    font-size: 12px;
    font-weight: 400;
    flex: auto;
    margin-left: 2.54452926vw;

    white-space: nowrap;
    overflow: hidden;

    @media #{g.$pc} {
      font-size: 14px;
      line-height: 1.42857143;
      margin-left: 1.04166667vw;
    }
  }

  &__open {
    border: 1px solid g.$border-red;
    border-radius: 5px;
    min-width: 0;
    padding: 3px 3px 2px 5px;
    // margin-left: auto;

    display: flex;

    @media #{g.$sp} {}
  }

  &__open-text {
    color: g.$text-red-primary;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.4;
  }

  &__open-img {
    max-width: 10px;
    margin-left: 5px;

    display: flex;
    align-items: center;

  }
}
