/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
@mixin tpIconCommonStyle() {
  border-radius: 40px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  max-width: 170px;
  min-height: 24px;
  padding: 5px 13px 3px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 10px;
    max-width: 105px;
    min-height: 20px;
    padding: 4px 8px;
  }
}

@mixin tpIconBgColor($type: default) {

  @if $type == default {
    // background-color: #b2b2b2;
    background-color: #747474;
  }

  @if $type == news {
    background-color: rgb(0, 104, 183);
  }

  @if $type == campaign {
    background-color: rgb(230, 0, 18);
  }

  @if $type == report {
    background-color: rgb(0, 167, 60);
  }

  @if $type == newitem {
    background-color: rgb(250, 190, 0);
  }

  @if $type == others01 {
    background-color: rgb(165, 0, 130);
  }
}
