@use "global" as g;

/*
  ---------------
  アカウント情報の変更
  ---------------
*/
body#account__edit_account_properties {
  fieldset {
    margin-top: 40px;
    padding: 40px;

    @media #{g.$sp} {
      padding: 20px 16px;
    }
  }

  div.btn-submit {
    padding-bottom: 0;
  }

  .btn-submit .btn {
    max-width: max-content;
  }
}