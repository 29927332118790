@use "global" as g;
/*
  ----------------
  responsive utility
  ----------------
*/
.u-pc--hide {
  @media #{g.$pc} {
    display: none;
  }
}

.u-sp--hide {
  @media #{g.$sp} {
    display: none;
  }
}

.u-tab--hide {
  @media #{g.$tab} {
    display: none;
  }
}

.u-tab-under--hide {
  @media #{g.$tab-under} {
    display: none;
  }
}

.u-tab-over--hide {
  @media #{g.$tab-over} {
    display: none;
  }
}
