@use "global" as g;

/*
  ---------------
  パックトイ共通
  ---------------
*/
#itemlist.is-flowpack ul.item-list li.item-list-each .add-item-to-cart,
.flowpack button.add-item-to-cart {
  background-color: #00ada0 !important;
}

#itemlist.is-flowpack ul.item-list li.item-list-each .checkout-item,
.flowpack button.checkout-item {
  background: #ff9c08 !important;
}


#itemlist.is-flowpack ul.item-list li.item-list-each .add-item-to-cart,
#itemlist.is-flowpack ul.item-list li.item-list-each .checkout-item,
.flowpack button.add-item-to-cart,
.flowpack button.checkout-item {
  border-radius: 30px!important;
}

#itemlist.is-flowpack .txc-body .txc-description {
  background-color: transparent;
  border: 0;
  margin-top: 0;
  padding: 0;
}
