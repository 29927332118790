@use "global" as g;
/*
  ----------------
  エラーページ
  ----------------
*/
#error,
#error-page {
  #outer {
    @include g.container();
    padding-top: 40px;
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
    padding: 0;
  }

  #container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .system-error {
    padding-top: 80px;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    @media #{g.$sp} {
      padding-top: 40px;
    }
  }
}