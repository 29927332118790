@use "global" as g;

/* diff sp */

@media screen and (max-width: 800px){
  div.item-search div.item-search-box > div.sb-label{
    display:none;
  }
  div.item-search div.item-search-box > *{
    display:inline-block;
    margin:0.25em 0;
  }

  div.item-thumbnail-img-lg{
    width:unset;
    height:unset;
  }


  div.cart-contents > div{
  	padding:0 1em;
  }
  div.cart-contents-wrapper > div.itemdetails{
  	padding:0;
  }
  div.itemdetail-row div.item div.goods{
  	flex-direction:column;
  }
  div.itemdetail-row div.item div.control{
  	font-size:0.7rem;
  	text-align:left;
  	justify-content:left;
  	padding:0 1em;
  }
  div.itemdetail-row div.item div.control div.cartitem-basic-control > *{
  	display:inline-block;
  }
  div.itemdetail-row div.purchase-contents{
  	flex-direction:column-reverse;

  }
  div.goods-text{
  	padding:1em;
  }
  div.goods-text span.code{
  	display:none;
  }
  div.itemdetails-header{
    display:none;
  }
  div.itemdetail-row div.item div.item-thumbnail-img-wrapper{
    margin:0 auto;
  }

  ul.item-list li.item-list-each div.item-dtl{
    margin:1em 0 2em;
  }

  div.news-entries div.header{
	  display:block;
  }
  div.form div.form-note{
    padding:0 1em;
  }
  div.formrow{
  	padding:1em;
  }
  div.formrow span.input-group-addon{
    display:none;
  }
  .nmessage-default{
    top:-30px;
    right:0;
    left:6em;
  }

  div#item-purchase-table table.item-purchase-table th{
    display:none;
  }
  div#item-purchase-table table.item-purchase-table tr{
    border-top:1px solid #ccc;

  }
  div#item-purchase-table table.item-purchase-table td
  {
    display:block;
    border:none;
    width:100%;
    box-sizing:border-box;
  }
  form.item-purchase div.btn-group-purchase {
    text-align:center;
  }

}