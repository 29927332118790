@use "global" as g;

/*
  ---------------
  News | news機能
  ---------------
*/
// ers-dev.css 追加

.top_news {
  margin-top: 40px;
}

.top_news__navi {
  display: flex;
}

.top_news__category {
  background-color: #f1f1f1;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  margin-left: 4px;
  padding: 15px 0;
  text-align: center;
  width: 100px;
}

.top_news__category.active {
  background-color: #333;
  border: 1px solid #333;
  color: #fff;
}

.top_news__category:hover {
  opacity: 0.8;
}

.top_news__entry_list {
  display: flex;
  overflow-x: scroll;
}

.top_news__entry {
  margin-left: 20px;
}

.top_news__entry_upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.top_news__entry_thumbnail {
  margin-top: 4px;
  object-fit: cover;
  width: 100%;
}

.top_news__entry_cate {
  background: #F1F1F1;
  border: 1px solid #C4C4C4;
  border-radius: 16px;
  font-size: 11px;
  padding: 4px 8px;
}

.top_news__entry_date {
  font-size: 14px;
}

.top_news__entry_title {
  font-size: 14px;
  margin-top: 0;
  text-decoration: none;
}

.top_news__to-list {
  text-align: center;
}

a.top_news__to-list-button {
  background-color: #F1F1F1;
  border: 1px solid #c3c3c3;
  border-radius: 25px;
  color: #333;
  display: inline-block;
  min-width: 300px;
  padding: 12px;
  text-decoration: none;
  width: auto;
}

.newslist-info {
  margin-top: 40px;
}

.newslist-info .news-name {
  text-align: left;
}

.newslist-info .news-entry:last-child {
  border-bottom: 1px solid #ccc;
}
// ers-dev.css ここまで

.newslist-info {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  div.news-entries div.news-entry {
    border-top: 1px solid g.$border-gray;
  }

  div.news-entries div.news-entry div.entry-text {
    border: 0;
    padding: 0;
  }

  .news-entry:last-child {
    border-bottom: 1px solid g.$border-gray;
  }

  div.news-entries div.header {
    @media all and (max-width: 800px) {
      display: flex;
    }

    @media #{g.$sp} {
      display: block;
    }
  }

  div.news-entries div.header div.meta div.release-date {
    margin-right: 0;
  }

  .news-archives-link {
    margin-top: 0;
  }

  .news-archives-link a {
    border: 0;
    border-radius: 0;
  }

  /*==========================================
    スタイル追加
  ==========================================*/
  margin-top: 40px;

  @media #{g.$sp} {
    margin-top: 50px;
  }

  .panel-newslist {
    padding-right: 3.05343511vw;
    padding-left: 3.05343511vw;

    @media #{g.$pc} {
      max-width: 1280px;
      margin: 0 auto;
      padding-top: 7px;
      padding-right: clamp(0px, 2.77777778vw, 40px);
      padding-left: clamp(0px, 2.77777778vw, 40px);
    }

    position: relative;
  }

  .news-name {
    color: #005c69;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.44;

    @media #{g.$sp} {
      text-align: center;
    }

    @media #{g.$pc} {
      font-size: 30px;
      line-height: 1.43333333;
    }
  }

  div.panel-heading + div.panel-body {
    margin-top: 30px;
  }

  .entry-text {
    width: 100%;
  }

  .release-date {
    color: g.$text-black-secondary;
    font-size: 13px;
    font-weight: 500;

    @media #{g.$pc} {
      font-size: 15px;
    }
  }

  .header {
    width: 100%;

    @media #{g.$sp} {
      padding-top: 14px;
    }

    @media #{g.$pc} {
      padding: 0 6px;

      display: flex;
      align-items: center;
    }
  }

  // li link wrapper
  .headline {
    width: 100%;

    @media #{g.$pc} {
      margin-left: 40px;
    }
  }

  // li link
  .headline a {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857143;
    padding: 3px 0 18px;

    @media #{g.$pc} {
      font-size: 15px;
      line-height: 1.46666667;
      padding: 23px 0;
    }

    .article {
      color: #494949;
      display: block;
      font-weight: 400;
      line-height: 1.4479;
      margin-top: 10px;
    }
  }

  .headline a:hover {
    color: g.$text-green-secondary;
    opacity: 1;
    transition: all .2s ease-in-out;
  }

  // お知らせ一覧ボタン
  .news-archives-link {

    @media #{g.$sp} {
      margin-top: 17px;

      display: flex;
      justify-content: center;
    }

    @media #{g.$pc} {
      position: absolute;
      top: 0;
      right: 40px;
    }
  }

  .news-archives-link a {
    @include g.buttonNews();
  }
}
