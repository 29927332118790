@use "global" as g;
/*
  ----------------
  signout
  ----------------
*/
#signout .signout .note {
  margin: 0;
  padding-top: 40px;
  text-align: center;
}

#signout .signout .linkarea__head {
  padding-top: 40px;
  text-align: center;
}

#signout .linkarea__wrapper {
  margin-top: 40px;
}

#signout .signout-message {
  margin-top: 40px;
}