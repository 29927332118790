@use "global"as g;

/*
  ----------------
  Breadcrumb common
  ----------------
*/
#page-title .breadcrumbs {
  background-color: #f6f6f6;
  margin: 0 calc(50% - 50vw) 25px;
  padding: 12px g.$padding-container-inline-pc;

  @media #{g.$pc} {
    width: 100vw;
  }

  @media #{g.$sp} {
    margin-bottom: 23px;
    padding: 12px;
  }
}

#page-title .breadcrumbs ul {
  @media #{g.$pc} {
    margin: 0 auto;
    max-width: 1200px;

    display: flex;
  }
}

#page-title .breadcrumbs li {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  position: relative;
}

#page-title .breadcrumbs > ul li:not(:first-child) {
  margin-left: 20px;
}

#page-title .breadcrumbs > ul li:not(:first-child)::before {
  background: url(/f/resources/images/common/icon_arrow_right_black.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  width: 5px;
  height: 8px;

  position: absolute;
  top: 50%;
  left: -12px;
  transform: translateY(-50%);

  @media #{g.$sp} {
    left: -14px;
  }
}

#page-title .breadcrumbs li.home {
  color: #1d1d1b;
}

#page-title .breadcrumbs li.end {
  color: #b2b2b2;
}


div.breadcrumbs {
  background-color: #f6f6f6;
  margin: 0 calc(50% - 50vw) 25px;
  overflow: hidden;
  padding: 12px g.$padding-container-inline-pc;

  @media #{g.$pc} {
    width: 100vw;
  }

  @media #{g.$sp} {
    margin-bottom: 23px;
    padding: 12px;
  }
}

.breadcrumbs ul {
  @media #{g.$pc} {
    margin: 0 auto;
    max-width: 1200px;

    display: flex;
  }
}

.breadcrumbs li {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  position: relative;
}

.breadcrumbs > ul li:not(:first-child) {
  margin-left: 20px;
}

.breadcrumbs > ul li:not(:first-child)::before {
  background: url(/f/resources/images/common/icon_arrow_right_black.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  width: 5px;
  height: 8px;

  position: absolute;
  top: 50%;
  left: -12px;
  transform: translateY(-50%);

  @media #{g.$sp} {
    left: -14px;
  }
}

.breadcrumbs li.home {
  color: #1d1d1b;
}

.breadcrumbs li.end {
  color: #b2b2b2;
}

div.breadcrumbs > ul li.parent:before,
div.breadcrumbs > ul li.end:before,
div.breadcrumbs > ul li.parent:before,
div.breadcrumbs > ul li.end:before {
  content: "";
}

.breadcrumbs.breadcrumbs-product {
  @media #{g.$sp} {
    margin-top: -5px;
  }
}

.breadcrumbs.breadcrumbs-product .end {
  display: none;
}

.breadcrumbs.breadcrumbs-product > li {
  line-height: 1.1;
}