@use "global" as g;
/*
  ---------------
  Button common
  ---------------
*/
// 必須
.c-btn {
  // &:not(.p-filter__btn-link) {
  //   @include g.hoverLink;
  // }
  border-radius: 2px;

  display: flex;
  align-items: center;

  // --------------------
  // 汎用ボタン | 緑線 角丸
  // --------------------
  &--simple {
    padding: 0 10px;

    justify-content: center;

    @media #{g.$sp} {}
  }

  // border-green small
  &--bd-green-sm {
    border: 1px solid g.$border-green;
    min-height: 34px;
  }

  // border-green medium
  &--bd-green-md {
    border: 1px solid g.$border-green;
    min-height: 40px;
  }

  // border-green large
  &--bd-green-lg {
    border: 1px solid g.$border-green;
    min-height: 50px;
  }

  // --------------------
  // TOP 一覧、もっとみるボタン
  // --------------------
  // おすすめシリーズ、みんなの投稿
  &--more-top-01 {
    background-color: g.$bg-gray-primary;
    min-height: 50px;
    padding: 0 12px 0 22px;
  }

  // TOPICS
  &--more-top-02 {
    background-color: transparent;
    border: 1px solid g.$border-white;
    min-height: 50px;
    padding: 0 12px 0 22px;
  }

  // 汎用 矢印付き四角
  &--arrow-green-square {
    background-color: g.$bg-gray-primary;
    min-height: 50px;
    padding: 0 12px 0 22px;
  }

  // --------------------
  // パートワーク(シリーズ一覧)
  // --------------------
  // バックナンバーボタン
  &--square-rg {
    background-color: g.$bg-green-tertiary;
    border-radius: 2px;
    color: g.$text-green-primary;
    min-height: 40px;
    padding: 0 10px;

    justify-content: center;

    @media #{g.$tab-under} {
      padding: 0 3px;
    }
  }

  // 定期購読ボタン
  &--square-dg {
    background-color: g.$bg-green-primary;
    border-radius: 2px;
    min-height: 40px;
    padding: 0 10px;

    justify-content: center;

    @media #{g.$tab-under} {
      padding: 0 3px;
    }
  }
}
