@use "global" as g;

/*
  ---------------
  発送履歴
  ---------------
*/

body#account__view_order_history {

  #outer {
    padding-bottom: 0;
  }

  .message {
    background-color: g.$bg-red-primary;
    border: 1px solid g.$border-red;
    color: g.$text-red-primary;
    margin-top: 40px;
    padding: 40px;
    text-align: center;
  }

  .order {
    padding-top: 40px;
  }

  .panel-journal > .panel-heading {
    min-height: 80px;
    padding-block: 22px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    @media #{g.$sp} {
      padding: 16px 14px;

      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
    }
  }

  .panel-journal .panel-heading > div {
    flex: auto;

    @media #{g.$sp} {
      width: 100%;

      display: flex;
      justify-content: space-between;
    }
  }

  .payment-method,
  .total-invoice,
  .purchase-date {
    padding: 0;

    display: flex;
    align-items: center;
    gap: 12px;
  }

  .panel-heading h5 {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
  }

  .panel-heading h5 + div {
    font-size: 16px;
    font-weight: 400;
  }

  .journal-status {
    display: flex;
    align-items: center;
  }

  .journal-status h5 {
    @media #{g.$pc} {
      display: none;
    }
  }

  .panel-heading > .control {
    display: flex;
    gap: 70px;

    @media #{g.$sp} {
      margin-top: 6px;
      padding-top: 10px;
      position: relative;

      gap: 20px;
      align-items: flex-end;

      &::before {
        background: g.$border-gray;
        content: '';
        display: block;

        width: calc(100% + 28px);
        height: 1px;

        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .panel-heading > .control h5 {
    font-size: 20px;
    font-weight: 700;

    display: flex;
    align-items: center;
    gap: 70px;

    @media #{g.$sp} {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      gap: 6px;
    }
  }

  .panel-heading > .control h5 span {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
  }

  #outer .panel .panel-body {
    margin-block: 0;
    padding: 20px;

    @media #{g.$sp} {
      padding: 10px;
    }
  }

  ul.panels {
    background: g.$bg-gray-primary;
    border-radius: 2px;
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    @media #{g.$sp} {
      gap: 10px;
      padding: 10px 14px;
    }
  }

  ul.panels li {
    margin: 0;

    display: flex;
    gap: 70px;

    @media #{g.$sp} {
      flex-wrap: wrap;
      gap: 0 24px;
    }
  }

  ul.panels li h6 {
    background: transparent;
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    text-align: left;
    width: 100px;
  }

  ul.panels li h6 + div {
    font-size: 16px;
    padding: 0;
  }

  .simple-person {
    display: flex;
    gap: 10px;
  }

  .itemdetail-row > .extra-controls {
    display: none;
  }

  .itemdetail-row .goods {
    @media #{g.$sp} {
      flex-direction: row;
    }
  }

  .itemdetails-body .itemdetail-row .goods .item-thumbnail-img {
    @media #{g.$sp} {
      margin: 0;
    }
  }

  .goods-text .unit-price {
    display: none;
  }

  .purchase-contents {
    @media #{g.$sp} {
      margin-top: -50px;
    }
  }

  // 料金合計テーブル
  .amount-table {
    background: g.$bg-gray-primary;
    border-radius: 2px;
    margin: 20px 0 0;
    padding: 26px 20px 24px;

    max-width: 100%;
    width: 100%;

    display: flex;
    justify-content: flex-end;

    @media #{g.$sp} {
      padding: 16px 20px;
    }
  }

  .amount-table table {
    width: max-content;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .amount-table tbody {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .amount-table tr {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .amount-table tr.total {
    border-top: 2px solid g.$border-gray;
    margin-top: 10px;
    padding-top: 18px;

    display: flex;
    gap: 60px;

    @media #{g.$sp} {
      gap: 10px;
      justify-content: space-between;
    }
  }

  .amount-table th,
  .amount-table td {
    border: none;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
  }

  .amount-table td {
    padding-left: 14px;
    position: relative;

    &::before {
      content: '：';
      font-size: 14px;
      display: block;

      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
  }

  .amount-table .service-payment_commission .service-label {
    display: none;
  }

  .amount-table tr.total th {
    color: g.$text-green-primary;
    font-size: 24px;
    font-weight: 500;
  }

  .amount-table tr.total td {
    color: g.$text-black-primary;
    font-size: 24px;
    font-weight: 700;

    &::before {
      display: none;
    }
  }

  // 簡易表示板のスタイル | For Simple Journal
  .sj-panel {
    border: 1px solid g.$border-gray;
  }

  .sj-header {
    background-color: g.$bg-gray-primary;
    border-bottom: 1px solid g.$border-gray;

    display: flex;
    align-items: center;

    @media #{g.$tab-under} {
      flex-direction: column;
    }
  }

  .sj-header__summary {
    padding: 16px 12px;

    display: flex;
    align-items: center;
    flex-grow: 1;

    @media #{g.$tab-under} {
      flex-direction: column;
      flex-grow: 0;

      font-size: 14px;
      padding: 10px;
      width: 100%;
    }
  }

  .sj-header__shipmentcode {
    border-left: 1px solid g.$border-gray;
    padding: 16px 12px;

    display: flex;
    align-items: center;

    @media #{g.$tab-under} {
      border-top: 1px solid g.$border-gray;
      border-left: none;
      font-size: 14px;
      padding: 10px;
      width: 100%;
    }

    @media #{g.$sp} {
      justify-content: space-between;
    }
  }

  .sj-header__orderdate,
  .sj-header__total {
    @media #{g.$tab-under} {
      width: 100%;

      display: flex;
    }

    @media #{g.$sp} {
      justify-content: space-between;
    }
  }

  .sj-header__orderdate-label,
  .sj-header__total-label,
  .sj-header__s-status-label,
  .sj-header__p-status-label,
  .sj-header__code-label {
    font-weight: 500;
    padding-right: 8px;

    @media #{g.$tab} {
      width: 50%;
    }
  }

  .sj-header__total {
    margin-left: 40px;

    @media #{g.$tab-under} {
      margin-top: 8px;
      margin-left: 0;
    }
  }

  .sj-header__status {
    margin-left: auto;

    display: flex;
    gap: 8px;

    @media #{g.$tab-under} {
      margin-left: 0;
      padding-top: 8px;
      width: 100%;

      flex-direction: column;
    }
  }

  .sj-header__s-status,
  .sj-header__p-status {
    width: 100%;

    display: flex;

    @media #{g.$sp} {
      justify-content: space-between;
    }
  }

  .s-status-CREATED,
  .s-status-VALIDATED,
  .s-status-ACCEPTED,
  .s-status-PREPARING_FOR_SHIPMENT {
    .sj-header__s-status-tag {
      border: 1px solid g.$text-blue-primary;
      color: g.$border-blue;
    }
  }

  .s-status-SHIPPED,
  .s-status-ARRIVED,
  .s-status-INSPECTED,
  .s-status-COMPLETED {
    .sj-header__s-status-tag {
      border: 1px solid g.$border-green;
      color: g.$text-green-primary;
    }
  }

  .p-status-UNCLAIMED .sj-header__p-status-tag {
    border: 1px solid g.$text-black-secondary;
    color: g.$text-black-secondary;
  }

  .p-status-UNPAID .sj-header__p-status-tag {
    border: 1px solid g.$border-red;
    color: g.$text-red-primary;
  }

  .p-status-DONE .sj-header__p-status-tag {
    border: 1px solid g.$border-green;
    color: g.$text-green-primary;
  }

  .sj-header__s-status-label,
  .sj-header__p-status-label {
    display: none;

    @media #{g.$tab-under} {
      display: block;
    }
  }

  .sj-header__s-status-tag,
  .sj-header__p-status-tag {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$bg-gray-quinary;
    border-radius: 2px;
    color: g.$text-black-secondary;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    min-width: 80px;
    padding: 2px 4px;
    text-align: center;
  }

  .sj-header__code-number {
    font-size: 20px;
    font-weight: 700;
    padding: 0 24px;

    @media #{g.$sp} {
      display: block;
      padding: 0;
    }
  }

  .sj-header__detail-link {
    background-color: g.$bg-gray-tertiary;
    border-radius: 2px;
    color: g.$text-white-primary;
    padding: 2px 12px;

    @media #{g.$tab-under} {
      font-size: 16px;
    }
  }

  .sj-body {
    padding: 10px;
  }

  .sj-shipment {
    background-color: g.$bg-gray-primary;
    border-radius: 2px;
    padding: 8px 10px;

    display: flex;
    flex-wrap: wrap;

    @media #{g.$tab-under} {
      flex-direction: column;
    }
  }

  .sj-shipment > div {
    padding: 4px 0;
    width: 50%;

    @media #{g.$tab-under} {
      display: flex;
      font-size: 14px;
      padding: 0;
      width: 100%;
    }
  }

  .sj-shipment > div:nth-child(even) {
    border-left: 1px solid g.$border-gray;
    padding-left: 20px;

    @media #{g.$tab-under} {
      border: none;
      padding: 0;
    }
  }

  .sj-shipment > div + div {
    @media #{g.$tab-under}{
      margin-top: 4px;
    }
  }

  .sj-shipment__payment-label,
  .sj-shipment__shipped-label,
  .sj-shipment__delivery-label,
  .sj-shipment__tracking-label {
    display: inline-block;
    font-weight: 500;
    min-width: 120px;
  }

  .sj-shipment__tracking-number {
    text-decoration: underline;
  }

  .sj-item {
    margin-top: 10px;
    padding: 10px;

    @media #{g.$tab-under} {
      padding: 0;
    }
  }

  .sj-item-detail {
    font-size: 18px;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{g.$tab-under} {
      font-size: 14px;
      padding: 8px 0;
    }
  }

  .sj-item-detail + .sj-item-detail {
    border-top: 1px solid g.$border-gray;
  }

  .sj-header__PGerror {
    background-color: #ffebeb;
    border: 1px solid #c10000;
    color: #c10000;
    font-size: 14px;
    margin-left: 20px;
    padding: 0 4px;

    @media #{g.$tab-under} {
      margin-top: 8px;
      margin-left: auto;
    }
  }
}