@use "global" as g;
/*
  ----------------
  問合せ前シナリオ（シナリオシーケンス）
  ----------------
*/
.contact-scenario {
  .scenario-sequence {
    @include g.container();
    padding-top: 100px;

    display: grid;
    place-items: center;

    @media #{g.$sp} {
      padding-top: 40px;
    }
  }

  .sequential-contents {
    width: 100%;
  }

  .active-contents {
    font-size: 24px;
    font-weight: 500;
    padding: 0 0 40px 0;

    @media #{g.$tab-under} {
      padding-bottom: 20px;
    }
  }

  ol.subsequence-label {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: left;

    @media #{g.$tab-under} {
      gap: 10px;
    }
  }

  ol.subsequence-label > li {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    min-height: 130px;
    width: calc((100% - 60px) / 4);

    @media #{g.$tab-under} {
      width: calc((100% - 10px) / 2);
    }

    @media #{g.$sp} {
      min-height: 80px;
    }
  }

  ol.subsequence-label > li > a {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: g.$bg-gray-primary;
    color: g.$text-green-primary;
    font-size: 20px;
    font-weight: 700;
    padding: 10px;
    text-align: center;
    height: 100%;
    width: 100%;

    @media #{g.$sp} {
      font-size: 16px;
    }
  }

  .input-area {
    padding-bottom: 20px;

    @media #{g.$tab-under} {
      padding-bottom: 10px;
    }
  }

  .input-area .sequential-contents-input {
    border: 1px solid g.$border-gray;
    border-radius: 2px 0 0 2px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    padding: 5px 16px 5px;
    height: 50px;
    width: 100%;
  }

  .search-result__heading {
    font-weight: 400;

    @media #{g.$sp} {
      font-size: 20px;
    }
  }

  .search-result__faq {
    border: 1px solid g.$border-gray;
    margin-top: 40px;
    padding: 8px 20px;

    @media #{g.$sp} {
      padding: 8px;
      margin-top: 20px;
    }
  }

  .search-result__faq ol li {
    position: relative;
  }

  .search-result__faq ol li + li {
    border-top: 1px solid g.$border-gray;
  }

  .search-result__faq ol li::before {
    background: g.$bg-green-primary;
    border-radius: 2px;
    color: g.$text-white-primary;
    content: "Q";
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    display: grid;
    place-items: center;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 18px;
    left: 12px;
  }

  .search-result__faq ol li a {
    color: g.$text-green-primary;
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding: 18px 40px 18px 47px;

    @media #{g.$sp} {
      padding: 12px 10px 12px 47px;
    }
  }

  .search-result__show-all {
    color: g.$text-green-primary;
    display: block;
    font-size: 16px;
    text-align: center;
    text-decoration: underline;
    margin-top: 20px;
  }

  .search-result__message {
    font-weight: 400;
    margin-top: 80px;
    text-align: center;

    @media #{g.$sp} {
      font-size: 20px;
      margin-top: 40px;
      text-align: left;
    }
  }

  .search-result__message a {
    text-decoration: underline;
  }
}