@use "global" as g;

/*
  ---------------
  パートワーク共通
  ---------------
*/
// ers-dev.css 追加

ol.partwork-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

ol.partwork-title > li {
  width: var(--ers-deago-boxwidth);
}

ol.partwork-title > li > div.thumbnail {
  width: 100%;
  height: var(--ers-deago-boxwidth);
  border: 1px solid #999;
}

ol.partwork-title > li > div.thumbnail img {
  width: 100%;
}

ol.partwork-title > li > div.text div.name {
  padding: 1em 0;
  font-weight: bold;
  text-align: center;
}

div.item-purchase div.partwork-distributions {
  border: 4px solid var(--ers-deago-col1);
  border-radius: 0.5em;
  padding: 1em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

div.item-purchase div.partwork-distributions label {
  background-color: #f5f5f5;
  display: block;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 0.25em;
}

div.item-purchase div.partwork-issues {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: lime;
  border-radius: 0.5rem;
}

div.partwork > div.description {
  border: 2px solid lime;
  padding: 2em;
  margin: 0 0 1em;
}

div.partwork div.txc-body {
  border: 2px solid lime;
  padding: 2em;
  margin: 0 0 1em;
}

div.partwork div.distribution > table {
  border-collapse: collapse;
  margin-bottom: 2rem;
}

div.partwork div.distribution > table caption {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 1rem 0 1rem;
}

div.partwork div.distribution > table th {
  background-color: lime;
  text-align: center;
  white-space: nowrap;
  padding: 1em;
}

div.partwork div.distribution > table td {
  border: 1px solid lime;
  padding: 1em;
}

ol.issues span.price.with-special span.selling-price {
  text-decoration: line-through;
}

ol.issues span.price.with-special span.special-price {
  color: red;
}

div.thread-title {
  border: 2px solid lime;
  padding: 2em;
  position: relative;
}

div.thread-title:before {
  content: "スレッドタイトル";
  position: absolute;
  left: 0;
  top: 0;
  color: lime;
}
/* パートワークカテゴリ一覧 */
.series {
  padding: 40px 0;
}

.category__lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.category__item {
  margin-top: 20px;
  width: calc(33.3333333333% - 20px);
}

.category__img {
  text-align: center;
  height: 160px;
}

.category__img img {
  height: 100%;
}

.category__footer-upper,
.category__footer-lower {
  display: flex;
  justify-content: space-between;
}

.category__footer-upper a,
.category__footer-lower a {
  border: 1px solid #333;
  border-radius: 4px;
  color: #333;
  font-size: 12px;
  margin-top: 8px;
  padding: 4px;
  text-align: center;
  text-decoration: none;
}

.category__footer-upper a {
  width: calc(50% - 16px);
}

.category__footer-lower a {
  width: calc(33.3333333333% - 16px);
}

.related__item-list {
  display: flex;
  justify-content: space-between;
}

.related__item-list li {
  border: 1px solid #333;
  border-radius: 12px;
  cursor: pointer;
  height: 200px;
  width: calc(25% - 20px);
}

.related__item-list li a {
  display: block;
  color: #333;
  font-size: 16px;
  height: 100%;
  line-height: 200px;
  text-align: center;
  text-decoration: none;
  transition: 0.2s ease;
  width: 100%;
}

.related__item-list li a:hover {
  opacity: 0.7;
}

/* パートワーク ナビゲーション */
.rp-subscriptions-nav-list .rp-subscriptions-nav-item > a,
.rp-subscriptions-nav-list .rp-subscriptions-nav-item > span {
  display: inline-block;
  white-space: nowrap;
  width: auto;
}

.rp-subscriptions-nav-list ol {
  overflow-x: scroll;
}

/* ディストリビューション詳細 */
.partwork .option .option-icon {
  display: block;
}

.partwork .option .option-icon img {
  height: 100px;
}

/* 商品一覧 */
.itemlist #page_aside2 {
  max-width: var(--ers-deago-maxwidth);
  margin: 0 auto;
  overflow-x: hidden;
}

.pagination ul {
  display: flex;
  justify-content: center;

  @media #{g.$sp} {
    justify-content: left;
  }
}

.pagination ul li {
  padding: 0 8px;
}

.related__pw {
  border-bottom: 1px solid g.$border-gray-dark;
  padding: 20px 0;
}

.itemlist .txc-header img {
  width: 100%;
}

/* 0件の際の表示 */
.p-series__nocontents,
.search-result-empty {
  border: 1px solid #c10000;
  background-color: #ffebeb;
  color: #c10000;
  margin: 40px 0;
  padding: 40px 10px;
  text-align: center;
}

.p-series__nocontents {
  margin: 4px 0 0;
}

/* カテゴリTOP */
.itemlist .txc-body {
  display: flex;
  justify-content: center;

  @media #{g.$sp} {
    flex-direction: column;
  }
}

.itemlist .txc-body .txc-description {
  background-color: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  font-size: 14px;
  padding: 12px;
  margin-top: 20px;
  width: 100%;
}

.itemlist .txc-body .txc-spec {
  border: 1px solid g.$border-gray;
  background-color: g.$bg-gray-primary;
  display: block;
  font-size: 14px;
  padding: 12px;
  text-align: left;
  margin-top: 20px;
  width: 100%;
}

.itemlist .txc-body > div:nth-child(2) {
  border-left: none;

  @media #{g.$sp} {
    border-left: 1px solid g.$border-gray;
  }
}

.itemlist .txc-body .txc-spec caption {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.itemlist .txc-body .txc-spec th {
  min-width: 60px;
}
// ers-dev.css ここまで

#itemlist #inner,
#item #inner,
#partwork #inner,
#distribution #inner {
  @include g.container();
}

#itemlist #category-txc .txc-header h1,
#partwork .partwork.main > h1,
#distribution .partwork.main > h1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0;

  @media #{g.$pc} {
    font-size: 24px;
    line-height: 1.45833333;
    margin-bottom: 10px;
  }
}

#partwork .partwork.main > h1,
#distribution .partwork.main > h1 {
  margin-top: 20px;
  pointer-events: none;

  @media #{g.$pc} {
    margin-top: 30px;
  }
}

#partwork .head-contents .images,
#distribution .head-contents .images {
  margin-top: 20px;

  @media #{g.$pc} {
    margin-top: 30px;
  }
}

#partwork .head-contents .main,
#distribution .head-contents .main {
  text-align: center;
}

#partwork .head-contents .images .main img,
#distribution .head-contents .images .main img {
  height: 100%;
  object-fit: contain;
}

#partwork #category-txc .txc-body,
#distribution #category-txc .txc-body {
  border: 0;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

#partwork #category-txc .txc-description,
#distribution #category-txc .txc-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71428571;
}

#itemlist div.category-item-list div.item-list-heading {
  display: block;
  padding-top: 20px;
  position: relative;

  @media #{g.$sp} {
    padding-bottom: 10px;
  }
}

#itemlist div.category-item-list div.category-item-list-sorting ul li {
  border: 0;
}

#itemlist div.category-item-list ul.item-list li.item-list-each div.item-dtl div.article-prices,
#itemlist div.category-item-list .item-purchase div.delivery-message,
#itemlist div.category-item-list .points,
#itemlist div.category-item-list .offering-description,
#itemlist div.category-item-list ul.item-list li.item-list-each .regular-price,
#itemlist div.category-item-list .item-list-each form.item-purchase .partwork
#itemlist div.category-item-list .item-list-each .article-price-range,
#itemlist div.category-item-list div.item-add-favorite, div.item-add-itemrequest,
#itemlist div.category-item-list .item-list .item-list-each form.item-purchase .partwork,
#itemlist div.category-item-list .item-list .item-list-each .article-price-range,
#itemlist ul.item-list li.item-list-each .add-item-to-cart::after,
#itemlist ul.item-list li.item-list-each .checkout-item::after,
#itemlist .item-list .offering-price-info .price-label,
#item .item-productclass-flowpack span.item-name-and-code span.item-code,
#item .item-productclass-flowpack .offering-description,
#item .item-productclass-flowpack div.exp-promotion-prices,
#item .item-productclass-flowpack .regular-price,
#item .item-productclass-flowpack .offering-price-info .price-label,
#item .item-productclass-flowpack .points,
#item .item-productclass-flowpack div.stock-message-enough,
#item .item-productclass-flowpack .item-purchase div.delivery-message,
#item .item-productclass-flowpack div.item-add-favorite {
  display: none;
}

div.ac.panel-list-wrapper > div.container {
  box-shadow: none;
  padding: 30px 55px 60px;

  @media #{g.$sp} {
    width: 94.2% !important;
    padding: 17px 20px 23px;
  }
}

// 数量を選択する
#distribution .distribution-detail .body .subscribe .select-qty-buttons-wrap {
  text-align: right;
  margin-top: 40px;

  @media #{g.$sp} {
    margin-top: 20px;
  }
}
