@use "global" as g;

/*
  ---------------
  決済方法選択
  ---------------
*/
.main-payment-method,
#default-payment-entry,
#saved-payment-entries{
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  margin-top: 40px;

  > .note {
    padding: 0 20px;
  }

  div.wrapper {
    border: none;
    margin: 0;
    max-width: 100%;
    padding: 28px;
    position: relative;
    word-break: break-all;
    width: 100%;

    @media #{g.$tab-under} {
      padding-block: 26px;
    }
  }

  div.wrapper > div.method-name,
  .panel-list .panel div.method-name  {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-align: left;

    @media #{g.$tab-under} {
      text-align: center;
      width: 100%;
    }
  }

  .control {
    position: absolute;
    right: 20px;
    bottom: 50%;
    transform: translateY(50%);

    @media #{g.$tab-under} {
      position: static;
      transform: translateY(0%);
      text-align: center;
      margin-top: 16px;
    }
  }

  .btn.btn-payment {
    @media #{g.$tab-under} {
      margin: 0 auto;
    }
  }

  .panel-list {
    padding-inline: 20px;
  }

  div.panel-body {
    margin: 0;

    @media #{g.$tab-under} {
      margin-bottom: 20px;
    }
  }

  .panel {
    border-top: 1px solid g.$border-gray;
    padding: 20px 8px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    @media #{g.$tab-under} {
      flex-direction: column-reverse;
    }
  }

  .panel-heading {
    @media #{g.$sp} {
      width: 100%;
    }
  }

  .btn.btn-inline {
    min-width: 220px;

    @media #{g.$tab-under} {
      margin: 0 auto;
    }
  }

  .use-as-default + .delete-payment {
    margin-top: 12px;
  }
}

div.payment-method-list {
  padding-inline: 20px;

  @media #{g.$sp} {
    padding-inline: 10px;
  }

  div.payment-method {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .payment-method:not(:first-child) {
    border-top: 1px solid g.$border-gray;
  }

   div.payment-method div.pm-wrapper {
    border: none;
    margin: 0;
    max-width: 100%;
    padding-block: 52px;
    padding: 0;
    position: relative;
    word-break: break-all;
    width: 100%;

    @media #{g.$tab-under} {
      padding-block: 0;
    }
  }

  .payment-method .btn {
    margin: 0 auto;
  }

  .pm-heading {
    width: 42%;

    @media #{g.$sp} {
      text-align: center;
      width: 100%;
    }
  }

  div.payment-method div.pm-wrapper div.pm-label {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;

    @media #{g.$tab-under} {
      width: 100%;
    }

    @media #{g.$sp} {
      text-align: center;
    }
  }

  .payment-method .pm-trailing {
    position: absolute;
    right: 0;
    bottom: 50%;
    transform: translateY(50%);

    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 26px;

    @media #{g.$tab-under} {
      position: static;
      transform: translateY(0%);

      flex-direction: column;
      align-items: center;
      gap: 8px;
    }
  }

  .extra-control {
    margin-top: 20px;
  }

  .payment-method .pm-trailing .note {
    text-align: center;
  }

  .pm-use-as-default,
  .checkbox {
    color: g.$text-green-primary;
    font-size: 14px;
  }

  .pm-submit + .pm-use-as-default {
    margin-top: 16px;
  }

  .pm-use-as-default label,
  .checkbox label {
    @include g.hoverButton;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .pm-use-as-default input {
    border-radius: 2px;
    margin: 0;
  }

  .formrow-mmyyyy .input-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }

  .formrow-mmyyyy .date-delim {
    font-size: 12px;
  }

  .formrow-mmyyyy select {
    padding: 5px 30px 5px 10px;
    height: 40px;
    min-width: 100px;

    &.date-yyyy {
      width: 150px;
    }
  }

  .payment-method .pm-body {
    width: 42%;

    @media #{g.$tab-under} {
      width: 100%;
    }
  }

  .payment-method .pm-body .pm-description {
    padding: 0;
  }

  // フォームがある場合
  .payment-method .pm-wrapper:has(.formrow) {
    width: 100%;
  }

  .payment-method .pm-wrapper:has(.formrow) .pm-body {
    background: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    margin-top: 10px;
    padding: 18px 24px;
    width: 100%;

    @media #{g.$sp} {
      padding: 10px;
    }
  }

  .payment-method .pm-wrapper:has(.formrow) .pm-trailing {
    position: static;
    transform: translateY(0);
    margin-top: 26px;

    flex-direction: column;
  }

  .payment-method .pm-wrapper:has(.formrow) .pm-submit {
    text-align: right;
    width: 100%;

    display: flex;
    flex-direction: column-reverse;
  }
}