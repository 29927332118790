@use "global" as g;

/*
  ---------------
  みんなの投稿 | news機能
  ---------------
*/
.newslist-user-post {
  margin-top: 40px;

  @media #{g.$sp} {
    margin-top: 30px;
  }

  > div {
    @include g.container();

    @media #{g.$pc} {
      position: relative;
    }
  }

  > div > div.panel-heading.panel-heading-text > div > span {
    color: g.$text-green-primary;
    display: block;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.44; // 36px
    text-align: left;
    padding: 0;

    @media #{g.$sp} {
      text-align: center;
    }

    @media #{g.$pc} {
      font-size: 30px;
      line-height: 1.43333333; // 43px
    }
  }

  div.panel-heading + div.panel-body {
    margin-top: 20px;
  }

  div.news-entries div.news-entry,
  div.news-entries div.news-entry-with-icon {
    border: 0;
  }

  div.news-entries div.news-entry {
    display: block;
  }

  div.news-entries div.news-entry div.entry-text {
    display: none;
    padding: 0;
  }

  div.news-entries div.news-entry-with-icon {
    padding: 0;
  }

  > div > div.panel-body > div.news-entries {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.03562341vw;

    @media #{g.$pc} {
      grid-template-columns: repeat(4, 1fr);
      gap: 13px;
    }
  }

  > div > div.panel-body > div.news-entries > div:hover {
    @include g.hoverLink();
  }

  > div > div.panel-body > div.news-entries > div > div.entry-icon.heading > span {
    display: block;
    max-width: 290px;
    max-height: 290px;
    aspect-ratio: 1 / 1;
    position: relative;
  }

  > div > div.panel-body > div.news-entries > div > div.entry-icon.heading > span img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  > div > div.panel-body > div.news-archives-link {
    @media #{g.$pc} {
      position: absolute;
      top: 0;
      right: clamp(0px, 2.77777778vw, 40px);
    }

    @media #{g.$sp} {
      text-align: center;
      margin: 17px auto 0;
    }
  }

  > div > div.panel-body > div.news-archives-link > a {
    border-radius: 2px;
    background-color: g.$bg-gray-primary;
    color: g.$text-green-primary;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.33333333; // lh22px
    text-align: center;
    min-height: 50px;
    padding: 0 48px 0 22px;

    display: inline-flex;
    align-items: center;

    position: relative;

    &::after {
      content: "";
      border-radius: 2px;
      background: g.$bg-green-primary url("/f/resources/images/top/icon_arrow_right_bg_green.svg") center / contain no-repeat;
      display: inline-block;
      width: 24px;
      height: 24px;

      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }
  }
}
