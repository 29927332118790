@use "global"as g;

/*
  ---------------
  パートワークモーダル
  ---------------
*/

.show-distribution-target {
  display: flex;
  overscroll-behavior-y: contain;
  overflow-y: scroll;

  &:before,
  &:after {
    content: "";
    width: 1px;
    height: calc(100vh + 1px);
    display: flex;
  }
  
  .distribution-container {
    width: 80%;
    margin: auto;
    overscroll-behavior-y: contain;
    overflow-y: scroll;
    position: static !important;
  
    @media #{g.$sp} {
      width: 94% !important;
    }
  }
  
  .distribution-container > .upper {
    background: linear-gradient(90deg, #005C69 0%, #00ADA0 100%);
    text-align: center;
    padding: 22px;
  
    position: relative;
  
    @media #{g.$sp} {
      padding: 12px;
    }
  }
  
  .distribution-container > .upper > .label {
    color: #fff;
    font-size: 24px;
    line-height: 1.45833333;
    padding-left: 36px;
    position: relative;
  
    @media #{g.$sp} {
      font-size: 18px;
      line-height: 1.44444444;
      padding-left: 23px;
    }
  }
  
  .distribution-container > .upper > .label::before {
    content: "";
    background: transparent url(/f/resources/images/common/icon_cart.svg) center/contain no-repeat;
    display: inline-block;
    width: 26px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  
    @media #{g.$sp} {
      width: 17px;
      height: 20px;
    }
  }
  
  .distribution-container > .upper > .btn-close {
    background: url("/f/resources/images/common/icon_modal_close.svg") center / contain no-repeat;
    cursor: pointer;
    display: inline-block;
    width: 35px;
    height: 35px;
  
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  
    @media #{g.$sp} {
      width: 20px;
      height: 20px;
      right: 14px;
    }
  }
  
  .distribution-container > .heading {
    font-size: 24px;
    line-height: 1.45833333;
    text-align: left;
    padding: 30px 55px 0;
  
    @media #{g.$sp} {
      font-size: 20px;
      padding: 18px 20px 0;
    }
  }
  
  .distribution-container .main-img {
    padding: 19px 55px 0;
    text-align: center;
  
    @media #{g.$sp} {
      padding: 10px 20px 0;
    }
  }
  
  .distribution-container .main-img img {
    border: 1px solid g.$border-gray;
    height: 100%;
    max-height: 300px;
  }
  
  .distribution-container .main-title img,
  .distribution-container .main-title > .pwk-title {
    display: none;
  }
  
  .distribution-container .main-title > .dstr-title {
    display: block;
  }

  // プラン
  div.partwork-distribution {
    background-color: transparent;
    color: g.$text-black-tertiary;
  }

  div.ac.partwork-distribution > div.container {
    width: 100% !important;
  }

  .partwork-distribution > .container > .panel-list {
    display: block !important;
    width: 100% !important;
    position: static !important;
  }

  .partwork-distribution > .container > .panel-list > li {
    border-right: 0;
    padding: 0;
    width: 100% !important;
  }

  .partwork-distribution > .container > .panel-list > li.is-open {}

  .partwork-distribution > .container > .panel-list > li + li {
    margin-top: 10px;
  }

  // prev nextボタン
  .partwork-distribution > .container > .panel-list > li button.btn.slide.next,
  .partwork-distribution > .container > .panel-list > li button.btn.slide.prev {
    display: none;
  }

  // プランタイトル
  .partwork-distribution > .container .distribution > .heading {
    background: linear-gradient(0deg, #F6F6F6, #F6F6F6), linear-gradient(0deg, #005C69, #005C69);
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    cursor: pointer;
    padding: 17px 20px;

    @media #{g.$sp} {
      padding: 15px 11px;
    }
  }

  .partwork-distribution > .container .distribution > .heading .main-title {
    padding-left: 48px;
    position: relative;

    @media #{g.$sp} {
      padding-left: 29px;
    }
  }

  .partwork-distribution > .container .distribution > .heading .main-title::before,
  .partwork-distribution > .container .distribution > .heading .main-title::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .partwork-distribution > .container .distribution > .heading .main-title::before {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-gray;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    left: 0;

    @media #{g.$sp} {
      width: 20px;
      height: 20px;
    }
  }

  .partwork-distribution > .container .distribution > .heading.is-open .main-title::after {
    background-color: g.$bg-green-primary;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    transition: background-color .2s ease-in-out;
    left: calc((30px / 2) - (20px / 2) + 1px);

    @media #{g.$sp} {
      width: 14px;
      height: 14px;
      left: calc((20px / 2) - (14px / 2) + 1px);
    }
  }

  .partwork-distribution > .container .distribution .dstr-title {
    color: g.$text-black-primary;
    font-size: 20px;
    font-weight: 400;

    @media #{g.$sp} {
      font-size: 18px;
    }
  }

  .partwork-distribution > .container .distribution > .heading > .dstr-options {
    display: none;
  }

  .partwork-distribution > .container .distribution > .body {
    display: none;
  }

  .partwork-distribution > .container .distribution > .body {}

  // バックナンバーの購入はこちら
  .partwork-distribution > .container .distribution > .body .purchase-backnumber {}
  .partwork-distribution > .container .distribution > .body .subscribe .purchase-backnumber .heading .title {
    border: 1px solid g.$border-gray;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4375;
    text-decoration: none;
    padding: 13px 35px 13px 20px;

    position: relative;

    @media #{g.$sp} {
      padding: 15px 60px 15px 20px;
    }
  }

  .partwork-distribution > .container .distribution > .body .purchase-backnumber .heading .note {
    margin-top: 0;
  }

  .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body {
    display: none;
    border-left: 1px solid g.$border-gray;
    border-right: 1px solid g.$border-gray;
    border-bottom: 1px solid g.$border-gray;
    padding: 20px;

    @media #{g.$sp} {
      padding: 12px;
    }
  }

  .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body .parcels {
    background-color: #f6f6f6;
    border: 1px solid #d9d9d9;
    max-height: 255px;
    overflow-y: scroll;
    padding: 10px 20px;
  }

  .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body .parcel {
    border: 0;
    margin-top: 0;
    padding: 0;
  }

  .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body .parcel {
    cursor: pointer;
    display: block;
  }

  .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body .issue-thumbnail {
    display: none;
  }

  // 購読を開始する号
  .partwork-distribution > .container .distribution > .body .subscribe .select-start {
    margin-bottom: 40px;
  }

  .partwork-distribution > .container .distribution > .body .subscribe .select-start .body {
    margin-top: 20px;
  }

  .partwork-distribution > .container .distribution > .body .subscribe .heading {
    margin-top: 30px;

    @media #{g.$pc} {
      margin-top: 40px;
    }
  }

  .partwork-distribution > .container .distribution > .body .subscribe .heading .title {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.45;

    @media #{g.$pc} {
      font-size: 24px;
      line-height: 1.45833333;
    }
  }

  .partwork-distribution > .container .distribution > .body .subscribe .heading .note {
    color: g.$text-black-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4285143;
    margin: 0;
    margin-top: 10px;
  }

  // オプションを選択
  .partwork-distribution > .container .distribution > .body .subscribe .choose-option {
    margin-bottom: 40px;
  }

  .partwork-distribution > .container .distribution > .body .subscribe .choose-option .heading {
    font-size: 24px;
    line-height: 1.45833333;
    padding-top: 5px;

    @media #{g.$sp} {
      font-size: 20px;
    }
  }

  .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option {
    background: linear-gradient(0deg, #F6F6F6, #F6F6F6), linear-gradient(0deg, #005C69, #005C69);
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    margin-top: 12px;
    padding: 12px;

    position: relative;
  }

  .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option.is-checked {
    border: 1px solid g.$border-green;
  }

  // オプション画像
  .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option .option-icon {
    border: 1px solid g.$border-gray;
    aspect-ratio: 120 / 105;
    width: 100%;
    height: 100%;
    max-width: 60px;
    max-height: 60px;
    margin-left: 15px;
    object-fit: contain;
    order: 2;

    @media #{g.$sp} {
      aspect-ratio: 102 / 90;
      max-width: 60px;
      margin-left: 8px;
    }
  }

  // オプションタイトル
  .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option .option-label {
    color: g.$text-black-tertiary;
    // display: block;
    font-size: 16px;
    line-height: normal;
    margin-left: 13px;

    order: 3;

    @media #{g.$pc} {
      font-size: 20px;
      margin-left: 19px;
    }
  }

  .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option .option-contents {
    color: g.$text-black-tertiary;
    font-size: 10px;
    line-height: normal;
    margin-top: 4px;

    @media #{g.$sp} {
      width: 100%;
    }

    @media #{g.$pc} {
      font-size: 12px;
    }
  }

  // 各オプション
  .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option > label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  // checkbox
  .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option > label > input[type="checkbox"] {
    accent-color: g.$bg-white-primary;
    display: inline-block;
    margin: 0;
    inline-size: 30px;
    block-size: 30px;

    order: 1;

    @media #{g.$sp} {
      width: 20px;
      height: 20px;
    }
  }

  .partwork-distribution > .container .distribution > .body .subscribe .select-qty {
    margin-left: auto;

    display: inline-flex;
    align-items: center;
  }

  .partwork-distribution > .container .distribution > .body .subscribe .select-qty .heading {
    color: g.$text-black-tertiary;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 15px;
    margin-top: 0;
  }

  .partwork-distribution > .container .distribution > .body .subscribe .select-qty select.item-qty {
    appearance: none; /* デフォルトの矢印を非表示 */
    background: #F6F6F6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
    background-position: right 10px center;
    background-size: 24px;
    border-radius: 2px;
    border: 3px solid #fff;
    color: g.$text-black-tertiary;
    cursor: pointer;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    height: 50px;
    line-height: normal;
    padding: 0 10px 0 29px;
    width: 151px;

    display: inline-flex;
    align-items: center;

    @media #{g.$sp} {
      font-size: 16px;
      height: 35px;
      padding: 0 0 0 13px;
      width: 84px;
    }
  }

  // 定期購読を申し込む
  .partwork-distribution > .container .distribution > .body .subscribe .buttons {
    @media #{g.$sp} {
      margin-top: 15px;
    }

    @media #{g.$pc} {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .partwork-distribution > .container .distribution > .body .subscribe .buttons button[type=submit] {
    background: linear-gradient(90deg, #005C69 0%, #00ADA0 100%);
    border: 0;
    border-radius: 2px;
    color: g.$text-white-primary !important;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    min-height: 50px;
    padding-inline: 46px 18px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .partwork-distribution > .container .distribution > .body .subscribe .buttons button[type=submit]::before {
    background: transparent url("/f/resources/images/packtoy/list/icon_cart.svg") center / contain no-repeat;
    content: "";
    display: inline-block;
    width: 18px;
    height: 20px;

    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
  }

  // 刊行予定
  .partwork-distribution > .container .distribution > .body .distribution-schedule {
    border-top: none;
    margin-top: 50px;
    padding: 0;

    @media #{g.$pc} {
      margin-top: 30px;
    }
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .heading {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.45;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body {
    border-top: 1px solid g.$border-gray-dark;
    margin-top: 10px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .note {
    color: g.$text-black-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4285143;
    margin: 0;
    margin-top: 10px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels {
    gap: 0;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel {
    border: 1px solid g.$border-gray;
    border-bottom: 0;
    margin-top: 10px;
    padding: 0;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel.is-open {
    border-bottom: 1px solid g.$border-gray;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel .summary {
    position: relative;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel .summary .description {
    display: none;
    font-size: 12px;
    line-height: normal;
    margin-top: 13px;
    padding-inline: 20px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel .summary > .label {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4375;
    text-decoration: none;
    padding-right: 0;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel .summary::after {
    content: "";
    background: transparent url("/f/resources/images/common/icon_plus_green.svg") center / contain no-repeat;
    display: inline-block;
    width: 15px;
    height: 13px;

    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .issues,
  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .option-parcels,
  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .subscription-privileges {
    background-color: g.$bg-white-primary;
    margin-top: 15px;
    padding: 0 20px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .issues > .label,
  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .subscription-privileges > .label {
    font-weight: 400;
    line-height: normal;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .issues > .issues {
    margin-top: 14px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues .issue-thumbnail {
    max-width: 140px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues .issue {
    max-width: 140px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues .contents {
    // margin-top: 8px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues .issue-thumbnail {
    display: block;
    max-width: 140px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues .issue-thumbnail img {
    aspect-ratio: 140 / 123;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .item-name,
  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .price {
    display: block;
    line-height: normal;
    * {
      font-size: 12px;
    }
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .item-name {
    font-weight: 500;
    letter-spacing: -0.12px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .price {
    font-weight: 400;
    margin-top: 7px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .item-name {}

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel .shipping > * {
    display: none;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel .shipping .shipping-from,
  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel .shipping .shipping-from .label {
    color: #005C69;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }

  // 刊行予定 - アコーディオン用スタイル
  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel.is-open {
    padding-bottom: 20px;
  }

  .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel.is-open .summary::after {
    background: transparent url("/f/resources/images/common/icon_minus_green.svg") center / contain no-repeat;
  }

  .distribution-schedule .parcel > *:not(.summary) {
    display: none;
  }

  .distribution-schedule .parcel .summary {
    border-bottom: 1px solid g.$border-gray;
    cursor: pointer;
    padding: 12px 60px 12px 20px;
    position: relative;
  
    @media #{g.$sp} {
      padding-right: 40px;
      padding-left: 12px;
    }
  }
}