@use "global" as g;

/*
  ---------------
  購入したコンテンツ一覧
  ---------------
*/
#account__view_purchased_merchandisecontents #outer {
  @include g.container;
}

#account__view_purchased_merchandisecontents .filename {
  margin-top: 12px;
  text-align: center;
}

#account__view_purchased_merchandisecontents .pmc-order {
  margin-top: 12px;
  text-decoration: underline;
}

/* デジタルコンテンツ 非表示要素 */
#account__view_purchased_merchandisecontents .filename,
#account__view_purchased_merchandisecontents .pmc-order,
#account__view_purchased_merchandisecontents .pmc-text .description,
#account__view_purchased_merchandisecontents .download-data {
  display:none;
}

.purchased-merchandise-contents {

  h1 {
    font-size: 20px;
    line-height: 1.45;
    margin-top: 28px;
    padding: 0;

    @media #{g.$sp} {
      font-size: 18px;
      line-height: 1.44444444;
      margin-top: 20px;
    }
  }

  .block-list {
    margin-top: 20px;
  }

  .block-list > li {
    border: 0;
    border-top: 1px solid g.$border-gray;
    padding: 20px;
    width: 100%;


    @media #{g.$pc} {
      display: grid;
      grid-template-columns: 120px 1fr 30%;
      align-items: center;
      gap: 40px;
    }

    @media #{g.$sp} {
      min-height: 130px;
      padding: 20px 0;

      position: relative;
    }
  }

  .block-list > li:last-child {
    border-bottom: 1px solid g.$border-gray;
  }

  .block-list > li > .item-thumbnail-img-wrapper {
    margin: 0;
    flex: 0 0 120px;

    @media #{g.$sp} {
      max-width: 102px;
      min-width: 102px;

      position: absolute;
      top: 20px;
      left: 0;
    }
  }

  .item-thumbnail-img.item-thumbnail-img-sm {
    width: 100%;
    height: 100%;
  }

  .item-thumbnail-img a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .item-thumbnail-img a img,
  .item-thumbnail-img a div {
    aspect-ratio: 120 / 105;
    width: 100%;
    height: 100%;

    @media #{g.$sp} {
      aspect-ratio: 102 / 90;
    }
  }

  .pmc-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;

    @media #{g.$sp} {
      margin-left: 117px;
      height: 50%;
    }
  }

  .pmc-access-control {
    @media #{g.$pc} {
      margin-left: auto;
    }

    @media #{g.$sp} {
      margin-top: 5px;
      margin-left: 117px;
    }
  }


  .pmc-access-control .link div {
    color: g.$text-gray-primary;
    font-size: 14px;
    line-height: 1.42857143;
  }

  .pmc-access-control .merchandise-contents,
  .pmc-access-control .pmc-not-available {
    @media #{g.$pc} {
      text-align: right;
    }
  }

  // 注意事項
  .pmc-access-control .link li div {
    font-size: 12px;
    text-align: left;
    margin-top: 10px;

    @media #{g.$sp} {
      margin-top: 4px;
    }
  }

  // 読むボタン
  .pmc-access-control .link a {
    background: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    box-shadow: none;
    font-size: 16px;
    line-height: 1.4375;
    text-align: center;
    min-width: 126px;
    padding: 14px;

    @media #{g.$sp} {
      margin-top: 10px;
      padding: 8px;
    }
  }

  .notice {
    margin-top: 20px;
  }
}
