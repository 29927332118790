@use "global" as g;
/*
  ---------------
  icon common
  ---------------
*/
// --------------------
// ** 必須クラス **
// ▼ .c-icon
// 基本的なアイコン
//
// ▼ .c-icon--inline
// 閲覧数・ユーザー評価 (横並びの場合)
// --------------------
.c-icon {
  display: block;
  text-align: center;
  width: 100%;
  position: relative;

  &--inline {
    display: inline-block;
    position: relative;
  }


  // --------------------
  // 一覧、もっと見る | 総合TOP
  // --------------------
  // 矢印 緑背景
  &--arrow-green-square {
    color: g.$text-green-primary;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.33333333; // lh22px
    padding-right: 36px;

    &::after {
      content: "";
      border-radius: 2px;
      background: g.$bg-green-primary url("/f/resources/images/top/icon_arrow_right_bg_green.svg") center / contain no-repeat;
      display: inline-block;
      width: 24px;
      height: 24px;

      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  // 矢印 白背景
  &--arrow-white-square {
    color: g.$text-white-primary;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.33333333; // lh22px
    padding-right: 36px;

    &::after {
      content: "";
      border-radius: 2px;
      background: g.$bg-white-primary url("/f/resources/images/top/icon_arrow_right_bg_white.svg") center / contain no-repeat;
      display: inline-block;
      width: 24px;
      height: 24px;

      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }


  // --------------------
  // パートワーク一覧
  // --------------------
  // バックナンバー
  &--series-back-number {
    color: g.$text-white-primary;
    font-size: 12px;
    letter-spacing: 0.01em;
    line-height: 1;

    @media #{g.$pc} {
      padding-left: 14px;
    }

    @media #{g.$sp} {
      font-size: 13px;
      letter-spacing: -0.18em;
      line-height: 1.46153846;
      padding: 39px 0 2px;
    }

    &::before {
      content: "";
      background: transparent url("/f/resources/images/partwork/icon_backnumber.svg") center / contain no-repeat;
      display: inline-block;
      width: 12px;
      height: 12px;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @media #{g.$sp} {
        width: 25px;
        height: 25px;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  // 定期購読
  &--series-subscription {
    color: g.$text-white-primary;
    font-size: 12px;
    letter-spacing: 0.01em;
    line-height: 1;

    @media #{g.$pc} {
      padding-left: 16px;
    }

    @media #{g.$sp} {
      font-size: 13px;
      line-height: 1.46153846;
      padding: 39px 0 2px;
    }

    &::before {
      content: "";
      background: transparent url("/f/resources/images/partwork/icon_subscription.svg") center / contain no-repeat;
      display: inline-block;
      width: 12px;
      height: 14px;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @media #{g.$sp} {
        width: 22px;
        height: 25px;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
