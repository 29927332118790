@use "global" as g;

/*
  ---------------
  パートワーク購読の詳細
  ---------------
*/
#account__view_partwork_subscription {
  #container div.panel-body {
    margin-bottom: 20px;

    @media #{g.$sp} {
      margin-bottom: 30px;
    }
  }

  div.message-wrapper div.error {
    width: 100%;
  }

  .panel-footer {
    border: 1px dotted #ccc;
    padding: 2em;

    @media #{g.$sp} {
      padding: 0.5em;
    }
  }

  .distribution {
    margin-top: 4em;

    @media #{g.$sp} {
      margin-top: 30px;
    }
  }

  .distribution  .main-title {
    text-align: center;
  }

  .distribution .heading .main-title .pwk-title,
  .distribution .heading .main-title .dstr-title {
    text-align: left;

    @media #{g.$sp} {
      padding-top: 0;
    }
  }

  .distribution .heading .main-title .pwk-title {
    @media #{g.$sp} {
      font-size: 22px;
    }
  }

  .distribution .subs-addon-list {
    @media #{g.$sp} {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .distribution > .heading {
    background-color: g.$bg-white-primary;
    border-bottom: 1px solid g.$bg-gray-secondary;
    padding: 20px 0;
  }

  .distribution > .body {
    padding: 20px 0;
  }

  .distribution .distribution-schedule {
    border-top: none;
    margin-top: 40px;
    padding: 0;

    @media #{g.$sp} {
      margin-top: 0;
    }
  }


  .rp-subscriptions .not-subscribable {
    color: g.$text-red-primary;
    font-size: 14px;
    padding: 0;
  }

  .distribution .distribution-schedule .summary {
    border-bottom: 1px solid g.$bg-gray-secondary;
    padding-bottom: 12px;
  }

  .distribution .distribution-schedule .show-all-parcels {
    display: block;

    @media #{g.$sp} {
      text-align: center;
    }
  }

  .distribution .distribution-schedule .show-all-parcels-button {
    color: g.$text-green-primary;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 20px;
    transition: .2s ease-in-out;
    padding: 0 26px 0 0;
    position: relative;

    &::after {
      background: url(/f/resources/images/common/icon_plus_circle_green.svg) center / contain no-repeat;
      content: "";
      display: inline-block;
      margin-top: 2px;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &:hover {
      opacity: .8;
    }
  }

  .distribution .distribution-schedule .parcel .issues {
    background-color: transparent;
    margin-top: 0;
  }

  .distribution .distribution-schedule .parcel .subscription-privileges {
    background-color: transparent;
    margin-top: 0;
  }

  .distribution .distribution-schedule .parcel > .option-parcels {
    background-color: transparent;
  }

  .distribution .distribution-schedule .parcel > .shipping {
    display: flex;

    @media #{g.$sp} {
      background-color: g.$bg-white-primary;
    }
  }
}
