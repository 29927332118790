@use "global" as g;
/*
  ----------------
  signin | 登録済みアカウントにログイン
  ----------------
*/
#wrapper .form.signin-form {
  margin-top: 80px;

  @media #{g.$sp} {
    margin-top: 60px;
  }
}

#wrapper .form.signin-form .form-body.form-signin form {
  background-color: g.$bg-gray-primary;
  padding: 20px 0;
  margin-top: 20px;

  @media #{g.$pc} {
    padding: 25px 0 58px;
  }
}

body:not(#signin):not(#signup) #wrapper .form.signin-form .form-body.form-signin form {
  padding-inline: 40px;

  @media #{g.$sp} {
    padding-inline: 0;
  }
}

#wrapper .form.signin-form .form-body.form-signin form > div {
  display: block;
  margin-top: 15px;
  padding-inline: 15px;

  @media #{g.$pc} {
    padding-inline: 45px;
  }
}

#wrapper .form.signin-form .form-body.form-signin form .input-label {
  padding: 0;

  display: flex;
  align-items: center;
}

#wrapper .form.signin-form .form-body.form-signin form .input-label > .input-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  margin-inline-end: 5px;
}

#wrapper .form.signin-form .form-body.form-signin form .input-control {
  padding: 0;

  display: flex;
  align-items: start;
  flex-direction: column-reverse;
}

#wrapper .form.signin-form .form-body.form-signin form .input-control > .input-group {
  flex: auto;
  margin-top: 10px;
}

#wrapper .form.signin-form .form-body.form-signin form .input-control > .input-group > input {
  background-color: g.$bg-white-primary;
  border: 1px solid g.$border-gray;
}

#wrapper .form.signin-form .form-body.form-signin form > .btn-submit {
  margin-top: 60px;
  padding: 0;
}

#wrapper .form.signin-form .reminder {
  text-align: center;
  padding: 40px 0;
}

#wrapper .form.signin-form .reminder a {
  text-decoration: underline;
}

.form-auth.form-signin {
  #includeDocument {
    padding-inline: 45px;

    @media #{g.$sp} {
      padding: 20px 15px;
    }
  }

  .btn-submit-signup {
    background: g.$bg-green-primary;
    margin-top: 24px;
  }

  div.formrow div.input-note {
    margin-left: 0;
    padding-left: 0;
  }

  div.form.signin-form > div.reminder {
    font-size: 16px;
    padding-top: 40px;
    text-align: center;
  }
}
