@use "global" as g;
/*
  ----------------
  会社情報 | 共通
  ----------------
*/
// 共通スタイル
.company {
  text-align: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{g.$sp} {
    padding: 0 11px;
  }
}

.inner {
  width: 100%;
  max-width: 760px;
  margin-inline: auto;
}

.company .column-2 {
  display: flex;
}

// タイトル
.company h1 {
  color: #005c69;
  font-size: 26px;
  font-weight: bold;
  margin: 40px 0;
  padding: 12px 0;

  @media #{g.$sp} {
    font-size: 22px;
  }
}

.company h2 {
  color: #005c69;
  font-size: 24px;
  font-weight: bold;
  padding: 40px 0;
  text-align: center;

  @media #{g.$sp} {
    font-size: 22px;
  }
}

.company h3 {
  border-bottom: 1px solid #555;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  margin: 16px 0;

  @media #{g.$sp} {
    font-size: 20px;
  }
}

.company h4 {
  font-weight: bold;
  padding: 20px 0;
  text-align: left;
}

// テキスト
.company span,
.company strong,
.company p {
  // padding: 12px 20px;
  text-align: left;

  @media #{g.$sp} {
    font-size: 14px;
  }
}


// ナビゲーションメニュー
.company-navigation {
  background-color: #1d1d1b;

  @media #{g.$sp} {
    overflow-x: auto;
  }
}

.company-navigation ul {
  width: max-content;
  margin-inline: auto;

  display: flex;
}

.company-navigation a {
  color: #fff;
  padding: 14px 10px;

  @media #{g.$sp} {
    font-size: 14px;
  }
}

.company div.column-4 {
  padding-bottom: 40px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 40px;

  @media #{g.$sp} {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

.company div.column-4 > dl dd {
  margin-top: 12px;
}

.company div.column-4 > dl strong {
  color: g.$text-green-primary;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
}

.company div.column-4 > dl span {
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
}

.company p + p {
  line-height: 1.5;
}

.company a.button {
  background-color: #005c69;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  line-height: 40px;
  padding: 0 20px;
}

.company .panel-gray {
  background-color: #ccc;
  color: #1d1d1b;
  margin: 20px 0;
  padding: 32px 0;
  text-align: center;
  width: 100%;
}

.company ol {
  padding: 40px 20px;
}

.company ol li {
  border-bottom: 1px solid #555;
  padding: 20px;
  text-align: left;
}

.company ol li:first-child {
  border-top: 1px solid #555;
}

.company .newslist-press .news-entry {
  padding: 12px 0;
}

// ers-dev.css 追加
.todo {
  background-color: #ffebeb;
  border: 1px solid #c10000;
  color: #c10000;
  margin: 20px 0;
  padding: 8px;
  text-align: center;
  width: 100%;
}
// ers-dev.css ここまで
