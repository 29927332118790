@use "global" as g;
/*
  ----------------
  会社概要
  ----------------
*/
.company .summary {
  border: 1px solid #555;
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.company .summary tr {
  text-align: left;
}

.company .summary tr {
  border-top: 1px solid #555;
}

.company .summary th,
.company .summary td {
  border-right: 1px solid #555;
  padding: 4px 20px;

  @media #{g.$sp} {
    padding: 10px;
  }
}

.company .noborder tr,
.company .noborder th,
.company .noborder td {
  border: none;
}

.company .noborder th,
.company .noborder td {
  padding: 10px;

  @media #{g.$sp} {
    display: block;
    padding: 0;
  }
}

.company .noborder th {
  vertical-align: top;
}

.company .noborder td {
  padding-bottom: 5px;
}

.company iframe {
  max-width: 600px;
}
