@use "global"as g;

/*
  ---------------
  住所編集フォーム共通
  ---------------
*/
div.form {
  padding: 0;
}

.form {

  h1 {
    font-weight: 400;
    padding: 0;
  }

  .form-note {
    font-size: 14px;
    line-height: 1.42857143;
    margin: 0;
    margin-top: 20px;

    @media #{g.$sp} {
      margin-top: 8px;
    }
  }

  // 「アドレス帳」ボタン wrapper
  .form-body .addressbook {
    text-align: center;
    max-width: 100%;
    margin-top: 30px;
  }

  // 「アドレス帳」ボタン
  .form-body .addressbook .btn-addressbook {
    @include g.hoverButton();
    @include g.buttonFormBgGreen(addressbook);
  }

  .addressbook .ab-list {
    border: 0;

    top: 60px;
    right: 50%;
    transform: translateX(50%);
  }

  .addressbook .ab-list > ul {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
  }

  .form-body .input-group-address {
    background: g.$bg-gray-primary;
    border-radius: 0;
    border: none;
    padding: 40px;
    margin-top: 40px;

    @media #{g.$sp} {
      padding: 20px 15px;
    }
  }

  .form-body .formrow {
    border: none;

    display: flex;
    flex-direction: column;

    @media #{g.$sp} {
      padding: 0;
    }
  }

  .form-body .formrow:not(:first-child) {
    margin-top: 16px;
  }

  .form-body .input-label {
    padding: 0;

    display: flex;
    align-items: center;
  }

  .form-body .input-label .input-name {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
  }

  .form-body .input-label .input-required {
    background: g.$bg-red-secondary;
    border-radius: 2px;
    color: g.$text-white-primary;
    font-weight: 500;
  }

  .form-body .input-control {
    padding: 0;

    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 10px;
  }

  .form-body .input-control .input-group input.required {
    background: g.$bg-white-primary;
  }

  .form-body .input-control .input-group input {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
  }

  .form-body .input-control .input-note {
    margin-left: 0;
    padding-left: 0;
  }
}
