div#nowloading{
	position:fixed;
	z-index:2000;
	top:50%;
	left:0;
	right:0;
}
div#nowloading div.msg{
	top:50%;
	background-color:rgba(0,0,0,0.3);
	text-align:center;
	padding:1em;
	margin:0 auto;
	width:200px;
	border-radius:6px;
	box-shadow:0 2px 4px rgba(0,0,0,0.2);
	color:#fff;
	font-size:18px;
	font-family:Arial;
}



#error-msg{
    position:fixed;
    top:0;
    z-index:9999;
    background-color:#ffe;
    width:100%;
    padding:8px;
    border-bottom:1px solid #999;
    text-align:center;
    color:#c00;

}
#non-modal-msg{
    position:fixed;
    top:0;
    z-index:9999;
    background-color:#efe;
    width:100%;
    padding:8px;
    border-bottom:1px solid #090;
    text-align:center;
    color:#090;

}

.modal-bg{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1500;
	background-color:#000;
	opacity:0.3;
}
.modal{
	display:none;
	position:fixed;
	top:50px;
	left:10%;
	width:80%;
	z-index:1501;
}
.modal .modal-dialog{
	border:1px solid #666;
	background-color:#fff;
	border-radius:8px;
	box-shadow:2px 2px 8px rgba(0,0,0,0.75);
}
.modal .modal-dialog .modal-header{
	border-bottom:1px solid #666;
	border-radius:8px 8px 0 0;
	padding:16px;
	background:linear-gradient(#fff, #ddd);
}
.modal .modal-dialog .modal-header h4{
	margin:0;
}
.modal .modal-dialog .modal-body{
	padding:8px 16px;
	height:540px;
	overflow-y:scroll;
}
.modal .modal-dialog .modal-footer{
	padding:16px;
	border-top:1px solid #666;
	background-color:#eee;
	border-radius:0 0 8px 8px;
	text-align:right;
}
.ns-overlay{
	position:absolute;
	top:0;
	left:0;
	z-index:1000;
	width:100%;
	height:100%;
	background-color:#000;
	opacity:0.75;
	color:#fff;
	text-align:center;
}
.ns-overlay > *{
	margin:2em;
}

div.tgl{
	display:none;
}

.dev-page-information{
	padding:16px;
	margin:0;
	background-color:#222;
}
.dev-page-information table{
	font-size:12px;
	border-collapse:collapse;
	width:100%;
	color:#fff;
}
.dev-page-information table th,
.dev-page-information table td{
	border-bottom:1px dotted #ccc;
	padding:2px 4px;
}
.dev-page-information table th{
	border-right:1px solid #ccc;
}
.dev-page-information table td{
}

.dev-page-information .template-variables{
}
.dev-page-information .template-variables pre{
	padding:1em 2em;
	border:1px solid #ccc;
	border-radius:6px;
	color:#eee;
	overflow:hidden;
	white-space:pre-wrap;
}
.dev-page-information div.exception{
	padding:1em 2em;
	border:1px solid #0cc;
	border-radius:6px;
	color:#0cc;

}
div.form div.form-confirm-contents-wrapper.overlay{
	position:fixed;
	top:0;
	left:0;
	background-color:rgba(255,255,255,0.4);
	height:100%;
	width:100%;
	z-index:1000;
    display:flex;
    align-items:center;
    text-align:center;
}
div.form div.form-confirm-contents-wrapper.overlay div.form-confirm-contents{
    margin:auto;
}
.after-confirm{
	display:none;
}
span.ip-cfm{
	white-space:pre-wrap;
}

div.upload-thumbnail{
	padding:2px;
	border:1px solid #ccc;
	display:inline-block;
	vertical-align:middle;
}
div.upload-thumbnail img{
	width:100px;
}


div.move-item-toggle div.disable-moveitem,
div.move-item-wrapper,
div.move-item-wrapper div.new-destination-consignee{
  display:none;
}
div.move-item-wrapper ul.move-item-dst > li.active{
	background-color:#fff;
}
div.move-item-wrapper ul.move-item-dst > li.inactive{
	background-color:#eee;
}

/*----------------------- Site Gadget / Item Search Criteria ----------------------------*/
div.item-search-criteria div.criteria{
	border:1px solid #fff;
	cursor:pointer;
	display:inline-block;
	padding:2px 0.5em;
	border-radius:1em;
}
div.item-search-criteria div.criteria.off{
	background-color:#fff;
	color:#666;
}
div.item-search-criteria div.criteria.on{
	background-color:#f5f5f5;
	border-color:#666;
}


/*---------------Shasow User Badge------------------*/
div.shadow-user-badge{
	position:fixed;
	bottom:0;
	right:0;
	z-index:2000;
	font-size:12px;
	padding:1em;
	background-color:rgb(200 0 0 / 80%);
	border-radius:0.5em 0 0 0;
	color:#fff;
	border:1px solid #c00;
	border-bottom:none;
	border-right:none;
	cursor:pointer;
}
div.shadow-user-badge div.user{
	text-align:center;
	font-weight:bold;
	text-shadow:0 0 2px black;
}
div.shadow-user-badge div.unreleased{
	font-size:0.9em;
}

.toggler .active{
}
.toggler .inactive{
	display:none;
}

div.password-str-meter{
	margin:0.25em 0;
	display:flex;
	font-size:0.85em;
	color:#999;
}
div.password-str-meter span.msg{
	padding:0 2px;
	white-space:nowrap;
}
div.password-str-meter.DANGER span.msg{
	color:#f00;
}
div.password-str-meter.LOW span.msg{
	color:#f93;
}
div.password-str-meter.MEDIUM span.msg{
	color:#39c;
}
div.password-str-meter.HIGH span.msg{
	color:#3a3;
}
div.password-str-meter.GREAT span.msg{
	color:#3c3;
}
div.password-str-meter span.unmask-pass{
	text-align:right;
	margin-left:auto;
	cursor:pointer;
}
div.password-str-meter span.masked .label-mask,
div.password-str-meter span.unmasked .label-unmask{
	display:none;
}


div.site-announce.fixed{
	position:fixed;
}
div.site-announce.FADE_OVERLAY{
	position:fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	display:flex;
	align-items:center;
	justify-content:center;
}
div.site-announce.SLIDEUP_OVERLAY{
	position:fixed;
	bottom:0;
	right:0;
}
div.site-announce.SLIDEDOWN_OVERLAY{
	position:fixed;
	top:0;
	left:0;
}
div.site-announce.SLIDEUP_OVERLAY{
}
div.site-announce.SLIDEDOWN_OVERLAY{
}

div.modify-rpi-option ul.item-options > li div.control.inactive span.option-input{
	display:none;
}
div.modify-rpi-option ul.item-options > li div.control span.option-input.disabled{
	display:none;
}

div.panel-list-wrapper{
	background-color:rgba(0,0,0,0.4);
}
div.panel-list-wrapper > div.container{
	padding:1em 0;
	background-color:#fff;
	box-shadow:1px 1px 4px rgba(0,0,0,0.2);
}
div.panel-list-wrapper ol.panel-list{
}
div.panel-list-wrapper ol.panel-list > li{
	border-right:1px solid #ccc;
	position:relative;
	padding:4em 1em 2em;
}
div.panel-list-wrapper ol.panel-list > li button.btn.slide{
	position:absolute;
	top:0;
}
div.panel-list-wrapper ol.panel-list > li button.btn.slide.prev{
	left:0.5em;
}
div.panel-list-wrapper ol.panel-list > li button.btn.slide.next{
	right:0.5em;
}

div.itemdetails div.partwork-subscription > div.label{
	cursor:pointer;
	text-decoration:underline;
}
div.itemdetails div.partwork-subscription > div.body{
	display:none;
	position:absolute;
	background-color:#f5f5f5;
	z-index:1000;
	width:100%;
	padding:1em;
}
