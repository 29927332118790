@use "global" as g;

/*
  ----------------
  Base styles
  ----------------
  */
html {
  overflow-x: hidden;

  // ハンバーガーメニュー
  &.is-open--sidebar {
    overflow: hidden;
  }

  body.is-open--sidebar {
    overflow: hidden;
  }
}

body {
  // background-color: g.$bg-gray-first;
}

body {
  min-height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
}

#wrapper,
main {
  flex: 1;
  overflow: hidden;
}

img {
  max-width: 100%;
  vertical-align: bottom;
  // width: 100%;
  object-fit: cover;
}

a {
  display: inline-block;
  @include g.hoverLink;
}

figure {
  margin: 0;
}

@media #{g.$pc} {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

@media #{g.$sp} {
  .container {
    padding-left: 5vmin;
    padding-right: 5vmin;
  }
}

/*
  ----------------
  Base font settings
  ----------------
*/

body {
  color: g.$text-black-tertiary;
  font-size: 16px;
  font-family: g.$font-family;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.4375;
  // line-height: 1;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.45833333;
}

h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

@media #{g.$sp} {
  h1 {
    font-size: 14px;
    line-height: 1.71428571;
  }

  h2 {
    font-size: 18px;
    line-height: 1.44444444;
  }

  h3 {
    font-size: 13px;
    line-height: 1.46153846;
  }
}
