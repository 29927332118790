@use "global" as g;

/*
  ---------------
  filter | 絞り込み検索
  ---------------
*/
.p-filter {
  @media #{g.$pc} {
    min-width: 220px;
  }

  @media all and (max-width: 1150px) {
    // min-width: 200px;
  }

  &__inner {}

  &__series {
    // margin-top: 30px;
  }

  &__series-box-list {
    background-color: g.$bg-gray-primary;

    @media #{g.$sp} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{g.$pc} {}
  }

  &__series-box-item {
    border-bottom: 1px solid g.$border-gray;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.42857143;
    min-height: 40px;
    padding: 0 16px;

    display: flex;
    align-items: center;

    @media #{g.$pc} {
      font-size: 14px;
    }

    &:nth-child(2n-1) {
      @media #{g.$sp} {
        border-right: 1px solid g.$border-gray;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    &.is-active {
      background-color: g.$bg-green-primary;
      color: g.$text-white-primary;
      font-weight: 700;

      position: relative;
    }
  }

  &__category {
    margin-top: 24px;

    @media #{g.$sp} {
      margin-top: 12px;
    }
  }

  &__category-inner {}

  &__category-inner.is-open .p-filter__category-btn-text::after {
    transform: translateY(-50%) rotate(-180deg);
  }

  &__category-btn {
    background-color: g.$bg-gray-quaternary;
    color: g.$text-white-primary;
    min-height: 40px;
    padding: 10px 10px 10px 14px;

    display: flex;
    align-items: center;

    @media #{g.$sp} {
      min-height: 50px;
    }
  }

  &__category-btn-text {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857143;
    width: 100%;

    @media #{g.$sp} {
      padding-right: 24px;

      position: relative;
    }

    @media #{g.$pc} {
      font-size: 12px;
      line-height: 1.41666667;
      text-align: center;
    }

    // 絞り込み検索 矢印
    &::after {
      @media #{g.$sp} {
        content: "";
        display: inline-block;
        background: url("/f/resources/images/partwork/icon_arrow_bg_gray.svg") center / contain no-repeat;
        width: 20px;
        height: 20px;

        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }

  &__category-block {
    background-color: g.$bg-gray-primary;
    border-radius: 2px 0 2px 2px;
    padding-block: 10px;

    @media #{g.$sp} {
      display: none;
    }
  }

  &__category-box {

    & + & {
      margin-top: 20px;
    }
  }

  &__category-box-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.45454545;
    padding-inline: 14px;

    @media #{g.$pc} {
      font-size: 11px;
    }
  }

  &__category-box-list {
    margin-top: 7px;

    @media #{g.$pc} {
      margin-top: 9px;
    }
  }

  &__category-box-item-control {
    display: flex;
    gap: 8px;

    padding: 0 10px 8px;
  }

  &__category-box-item {
    cursor: pointer;
    color: g.$text-green-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    padding: 7px 10px 7px 40px;

    @media #{g.$pc} {
      font-size: 14px;
    }
  }

  &__category-box-item.select-all,
  &__category-box-item.unselect-all {
    padding: 0;
    text-align: right;
    width: 100%;
  }

  &__category-box-item.select-all .p-filter__category-box-item-span,
  &__category-box-item.unselect-all .p-filter__category-box-item-span {
    border-radius: 2px;
    border: 1px solid g.$bg-green-primary;
    color: g.$text-green-primary;
    cursor: pointer;
    display: block;
    font-size: 11px;
    padding: 5px 0 5px 12px;
    position: relative;
    text-align: center;
    transition: .2s ease-in-out;
    width: auto;

    @media #{g.$sp} {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &__category-box-item.select-all .p-filter__category-box-item-span:hover,
  &__category-box-item.unselect-all .p-filter__category-box-item-span:hover {
    opacity: .8;
  }

  &__category-box-item:not(.select-all, .unselect-all) .p-filter__category-box-item-span::before {
    content: "";
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-green;
    border-radius: 2px;
    display: inline-block;
    overflow: hidden;

    height: 20px;
    width: 20px;

    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
  }

  &__category-box-item.select-all .p-filter__category-box-item-span::before,
  &__category-box-item.unselect-all .p-filter__category-box-item-span::before {
    content: '';
    display: block;

    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-45%);
  }

  &__category-box-item.select-all .p-filter__category-box-item-span::before {
    background: url(/f/resources/images/common/icon_check_green.svg) 50% / contain no-repeat;
    height: 7px;
    width: 9px;
  }

  &__category-box-item.unselect-all .p-filter__category-box-item-span::before {
    background: url(/f/resources/images/common/icon_cross_green.svg) 50% / contain no-repeat;
    height: 14px;
    width: 14px;
  }

  &__category-box-item.is-active:not(.select-all, .unselect-all) .p-filter__category-box-item-span::after {
    background: url(/f/resources/images/common/icon_check_green.svg) 50% / contain no-repeat;
    content: "";
    color: g.$text-white-primary;
    display: inline-block;

    height: 11px;
    width: 13px;

    position: absolute;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
  }

  &__category-box-item-span {
    display: block;
    width: 100%;
    position: relative;

    &::after {}
  }

  &__link-wrap {
    @media #{g.$sp} {
      display: flex;
      gap: 10px;
      justify-content: space-between;
    }
  }

  &__link {
    margin-top: 12px;

    @media #{g.$sp} {
      width: 50%;
    }
  }

  &__link .c-btn--arrow-green-square {
    @media #{g.$sp} {
      padding-left: 8px;
    }
  }

  &__link .c-icon--arrow-green-square {
    @media #{g.$sp} {
      font-size: 13px;
      padding-right: 32px;
    }
  }
}
