// お知らせボタン
@mixin buttonNews($type: default) {
  @if $type==default {
    background-color: #f6f6f6;
    color: #005c69;

    &::after {
      background: #005c69 url("/f/resources/images/top/icon_arrow_right_bg_green.svg") center / contain no-repeat;
      border-radius: 2px;
    }
  }

  @if $type==select {
    background-color: #f6f6f6;
    border-radius: 30px;
    color: #00ac5a;

    &::after {
      background: transparent url("/f/resources/images/select/common/icon_arrow_bg_green_circle.svg") center / contain no-repeat;
    }
  }

  font-size: 15px;
  font-weight: 500;
  line-height: 1.33333333; // lh22px
  min-width: 192px;
  min-height: 50px;
  padding: 0 48px 0 22px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: relative;

  &::after {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;

    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
}

// アドレス帳ボタン
@mixin buttonFormBgGreen($type: default) {
  border-radius: 2px;
  border: none;
  box-shadow: none;
  color: #fff !important;
  font-size: 16px;
  width: 100%;
  height: 50px;
  margin-inline: auto;

  display: grid;
  place-items: center;

  @if $type==addressbook {
    background: #005c69;
    max-width: 300px;
  }
}

// 確認、解約ボタン
@mixin buttonConfirm($type: default) {
  background: #494949 url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) calc(100% - 10px) 50% / 24px no-repeat;
  border-radius: 2px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 50px 13px;
  min-width: 175px;

  &:hover {
    background: #494949 url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) calc(100% - 10px) 50% / 24px no-repeat;
  }
}

// サインイン、サインアップボタン
@mixin buttonAuth($type: default) {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  box-shadow: none;
  color: #005c69;
  min-width: 240px;
  padding: 12px 50px 12px 12px;
  position: relative;

  &:hover {
    background: #f6f6f6;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-shadow: none;
    color: #005c69;
    padding: 12px 50px 12px 12px;
  }

  &::after {
    background: url("/f/resources/images/common/icon_arrow_right_circle_green.svg") 50% / contain no-repeat;
    content: '';
    display: inline-block;

    height: 24px;
    width: 24px;

    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
  }
}
