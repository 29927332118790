@use "global" as g;

/*
  ---------------
  パックトイ | 商品詳細
  ---------------
*/

.item-productclass-flowpack {
  #item-contents-main {
    padding-block: 30px;
    gap: min(3.33333333vw, 48px);
  }

  #item-contents-purchase {
    min-width: auto;
    flex: 1;
  }

  .item-image-main {
    height: auto;
    width: auto;
  }

  div.item-thumbnail-img-lg a {
    @media #{g.$sp} {
      display: inline-block;
      overflow: hidden;
      height: 150px;
      width: 150px;
    }
  }
}
