@use "global"as g;
/*
  ---------------
  ご利用ガイドトップ
  ---------------
*/
.guide-flow .guide-flow__wrap {
  @include g.container();
  padding-top: 60px;

  @media #{g.$sp} {
    padding-top: 54px;
  }
}

.guide-flow .guide-flow__list {
  padding-top: 28px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 28px;

  @media #{g.$sp} {
    padding-top: 14px;

    grid-template-columns: repeat(1, 1fr);
    gap: 48px;
  }
}

.guide-flow .guide-flow__item {
  background: g.$bg-gray-primary;
  border-top: 3px solid g.$bg-green-secondary;
  position: relative;
}

.guide-flow .guide-flow__item::before {
  @media #{g.$sp} {
    background: url(/f/resources/images/guide/icon_arrow_down.svg) 50% / contain no-repeat;
    content: '';
    display: block;
    display: block;

    height: 24px;
    width: 47px;

    position: absolute;
    bottom: -38px;
    right: 50%;
    transform: translateX(50%);
  }
}

.guide-flow .guide-flow__item + .guide-flow__item::before {
  @media #{g.$pc} {
    background: url(/f/resources/images/guide/icon_arrow_right.svg) 50% / contain no-repeat;
    content: '';
    display: block;

    height: 60px;
    width: 30px;

    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
  }
}

.guide-flow .guide-flow__item + .guide-flow__item:last-child::after {
  background: url(/f/resources/images/guide/icon_arrow_down.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  height: 24px;
  width: 47px;

  position: absolute;
  bottom: -37px;
  right: 50%;
  transform: translateX(50%);

  @media #{g.$sp} {
    display: none;
  }
}

.guide-flow .guide-flow__inner {
  padding: 0 26px 26px;

  display: flex;
  align-items: center;
  flex-direction: column;

  @media #{g.$sp} {
    padding: 18px 16px;
  }
}

.guide-flow .guide-flow__main {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  @media #{g.$sp} {
    width: 100%;

    align-items: flex-start;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }
}

.guide-flow .guide-flow__heading {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  @media #{g.$sp} {
    min-width: 190px;
    align-items: flex-start;
    gap: 0;
  }
}

.guide-flow .guide-flow__number {
  color: g.$text-green-primary;
  font-size: 60px;
  font-weight: 900;
  text-align: center;

  @media #{g.$sp} {
    font-size: 40px;
    text-align: left;
  }
}

.guide-flow .guide-flow__title-sub {
  color: g.$text-green-primary;
  font-size: 25px;
  font-weight: 500;
  height: 88px;
  line-height: 1.24;
  text-align: center;

  display: grid;
  place-items: center;

  @media #{g.$sp} {
    height: auto;
    line-height: 1;
    padding: 0;
    text-align: left;
  }
}

.guide-flow .guide-flow__figure {
  background: g.$bg-white-primary;
  border-radius: 2px;
  padding: 20px;

  height: 150px;
  max-width: 224px;
  width: 100%;

  display: grid;
  place-items: center;

  @media #{g.$sp} {
    padding: 8px;

    height: 93px;
    max-width: 140px;
  }
}

.guide-flow .guide-flow__figure img {
  object-fit: contain;
  max-width: 80%;

  height: 70px;
  width: 120px;
}

.guide-flow .guide-flow__text {
  font-size: 14px;
  margin-top: 16px;
}

.guide-flow .guide-flow__text-s {
  font-size: 12px;
}

.guide-flow .guide-flow__link {
  margin-top: 12px;
  padding: 0;
  text-align: center;

  min-height: 50px;
  max-width: 224px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    padding: 0;
  }

  @media #{g.$sp} {
    max-width: 340px;
  }
}

.guide-flow .guide-flow__last {
  border-radius: 2px;
  border: 1px solid g.$border-gray;
  background: g.$bg-gray-primary;
  margin-top: 46px;
  padding-block: 30px;
  width: 100%;

  display: grid;
  place-items: center;

  @media #{g.$sp} {
    padding-block: 19px;
    padding-left: 84px;
    place-items: start;
  }
}

.guide-flow .guide-flow__last-text {
  color: g.$text-green-primary;
  font-size: 25px;
  font-weight: 500;
  position: relative;
}

.guide-flow .guide-flow__last-text::before {
  background: url(/f/resources/images/guide/icon_house.png) 50% / contain no-repeat;
  content: '';
  display: block;

  height: 32px;
  width: 35px;

  position: absolute;
  top: 50%;
  left: -46px;
  transform: translateY(-50%);

  @media #{g.$sp} {
    left: -60px;
    height: 50px;
    width: 50px;
  }
}
