@use "global" as g;

/*
  ---------------
  Topics common
  ---------------
*/
.p-topics {
  background-color: g.$bg-green-secondary;

  &__inner {
    @include g.container;
    padding-block: 24px 29px;
  }

  &__header {
    // position: relative;
  }

  &__title {
    color: g.$text-white-primary;

    @media #{g.$sp} {
      font-size: 28px;
      font-weight: 600;
      text-align: center;
    }
  }

  &__navi {
    display: none;

    //slickが動いたら表示
    &.slick-slider {
      display: flex;
    }


    @media #{g.$sp} {
      margin-top: 12px;

      justify-content: center;
    }
  }

  &__category {
    cursor: pointer;
    color: g.$text-white-primary;
    font-size: 14px;
    line-height: 1.42857143;
    text-align: center;
    padding: 4px 0;
    white-space: nowrap;

    @media #{g.$pc} {
      font-weight: 500;
      padding: 9px 0;
    }

    &.active {
      position: relative;

      &::after {
        content: "";
        background-color: g.$bg-white-primary;
        display: block;
        width: 100%;
        height: 2px;

        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &:hover {
      color: g.$text-green-tertiary;
      transition: all .2s ease-in-out;
    }
  }

  &__btn {
    text-align: center;

    @media #{g.$sp} {
      max-width: 150px;
      margin: 24px auto 0;
    }

    @media #{g.$pc} {}
  }

  &__btn-link.is-hide {
    visibility: hidden;

    @media #{g.$sp} {
      display: none;
    }
  }

  // slick
  // ナビゲーション
  .p-topics__navi {

    &.slick-slider {
      text-align: center;
      max-width: 339px;
      padding: 0 28px;

      @media #{g.$sp} {
        margin: 16px auto 0;
      }

      @media #{g.$pc} {
        max-width: 570px;
        padding: 0 59px;
      }
    }

    .slick-slide {
      display: inline-block;
      margin: 0 10px;

      @media #{g.$pc} {
        margin: 0 15px;
      }
    }

    .slick-arrow {
      top: 50%;
      transform: translateY(-50%);
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
    }

    .slick-prev, .slick-next {
      width: 10px;
      height: 20px;

      @media #{g.$sp} {
        width: 11px;
        height: 19px;
      }
    }

    .slick-prev::before {
      background: transparent url("/f/resources/images/top/icon_prev_white.svg") center / contain no-repeat;
    }

    .slick-next::before {
      background: transparent url("/f/resources/images/top/icon_next_white.svg") center / contain no-repeat;
    }

    .slick-prev:before, .slick-next:before {
      content: "";
      display: inline-block;
      width: inherit;
      height: inherit;
    }

    .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
      opacity: 0;
    }
  }
}
