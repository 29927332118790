@use "global" as g;

/*
  ---------------
  注文完了
  ---------------
*/
body#order__complete {
  padding-top: 0;

  .order-process-main-complete h1 {
    border-bottom: 1px solid g.$border-black;
    padding-bottom: 10px;
    text-align: left;
  }

  .order-process-main-complete .main-message {
    text-align: left;
  }

  .order-process-main-complete .main-contents {
    margin-top: 0;
    text-align: left;
  }

  div.order-code {
    margin-top: 32px;
  }

  span.order-code {
    display: block;
    background-color: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    font-size: 30px;
    font-weight: 700;
    margin-top: 12px;
    padding: 25px;
    text-align: center;
  }

  .order-process-main-complete .view-order-detail {
    margin-top: 66px;
    text-align: center;
  }

  // 注文失敗
  .request-paymentmethod-reset {
    margin-top: 20px;
  }

  .request-paymentmethod-reset h3 {
    background-color: g.$bg-red-primary;
    border: 1px solid g.$border-red;
    color: g.$border-red;;
    text-align: center;

    @media #{g.$sp} {
      font-size: 16px;
    }
  }

  .request-paymentmethod-reset .form-reset-paymentmethod {
    text-align: center;
  }

  .request-paymentmethod-reset .description {
    text-align: left;
  }

  .request-paymentmethod-reset button.btn {
    margin: 20px 0;
  }

  // 与信失敗時のスタイル
  .complete-with-pgfailed #page-title h1 {
    display: none;
  }

  .complete-with-pgfailed .main-message {
    border-bottom: 1px solid g.$text-black-tertiary;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 10px;
    line-height: 1.45833333;
  }

  .complete-with-pgfailed .main-contents #order-code {
    display: none;
  }

  .complete-with-pgfailed .main-contents .message {
    padding-top: 20px;
  }

  .complete-with-pgfailed .main-contents .view-order-detail {
    margin-top: 20px;
  }
}
