@use "global" as g;

/*
  ---------------
  セレクトTOP | 最近チェックした商品
  ---------------
*/
.select-top .p-history {
  margin-top: 60px;

  @media #{g.$sp} {
    margin-top: 50px;
    overflow: hidden;
  }

  &__inner {
    background-color: g.$bg-gray-primary;
    padding-block: 50px 60px;

    @media #{g.$sp} {
      padding-block: 50px;
    }
  }

  .sc-display {
    margin-top: 0;
  }

  .sc-display .display-name {
    font-size: 20px;
    line-height: 1.45;

    @media #{g.$sp} {
      text-align: center;
    }
  }

  .sc-display .item-display {
    @media #{g.$pc} {
      position: relative;
    }
  }

  .sc-display .panel-body::after {
    display: none;
  }

  .sc-display .item-display::after {
    @media #{g.$pc} {
      content: "";
      display: inline-block;
      background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%);
      width: 181px;
      height: 100%;
      margin-right: calc(50% - 50vw);

      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .sc-display .display-box .item-display .slick-list {
    @media #{g.$pc} {
      max-width: 1220px;
      overflow: hidden;
    }
  }

  .sc-display .item.article {
    max-width: 240px;
  }

  .sc-display div.item-thumbnail-img-sm a {
    aspect-ratio: 1 / 1;
  }

  .sc-display .item-thumbnail-img-sm img {
    position: relative;
    top: unset;
    left: unset;
    transform: none;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    @media #{g.$pc} {
      opacity: 0.25;
    }
  }
}
