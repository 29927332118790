@use "global"as g;

/*
  ---------------
  埋め込み文書 | includeDocument(SignUpAgreement.html, JustBeforeCheckOut.html)
  ---------------
*/
// ers-dev.css 追加
#includeDocument {
  padding: 40px 45px;

  @media #{g.$sp} {
    padding-inline: 15px;
  }
}

#checkout-control #includeDocument {
  padding: 40px 0;

}

.acc-signup + #includeDocument {
  padding: 40px 0;

  @media #{g.$sp} {
    padding-inline: 0;
  }
}

#includeDocument {
  padding-block: 40px 30px;
}

#includeDocument .includeDocument__outer {
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  background-color: #fff;
  height: 120px;
  overflow-y: scroll;
  padding: 0 20px;
  resize: vertical;
}

#includeDocument .includeDocument__inner h1 {
  font-size: 24px;
  font-weight: bold;
  padding: 20px 8px;
}

#includeDocument h2 {
  border-bottom: 1px solid #555;
  font-size: 20px;
  margin: 32px 0 16px;
  padding: 8px 0;
}

#includeDocument h3 {
  font-weight: bold;
}

#includeDocument p {
  margin-top: 8px;
}

#includeDocument ul,
#includeDocument ol {
  padding-left: 24px;
}

#includeDocument li {
  list-style-type: decimal;
  margin-top: 8px;
}

#includeDocument a {
  color: g.$text-blue-primary;
  text-decoration: underline;
}

// ers-dev.css ここまで