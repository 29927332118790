@use "global"as g;

/*
  ---------------
  ページネーション共通
  ---------------
*/

#itemlist .pagination ul li a,
.community .pagination ul li a {
  border-radius: 0;
}

#itemlist .pagination ul li.active a,
.community .pagination ul li.active a {
  background-color: transparent;
  color: g.$text-green-primary;
}

.pagination {
  display: flex;
  margin-top: 57px;

  @media #{g.$sp} {
    margin-top: 40px;
    overflow-x: scroll;
    padding-bottom: 4px;
  }

  ul {
    width: 100%;

    display: flex;
    justify-content: center;
    gap: 8px;

    @media #{g.$sp} {
      justify-content: left;
      gap: 6px;
    }
  }

  li {
    padding: 0;
  }

  li.blank {
    display: none;
  }

  a {
    background-color: #f6f6f6;
    color: #000;
    display: inline-block;
    padding: 12px 20px;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: normal;

    @media #{g.$sp} {
      padding: 7px 15px;
    }
  }

  li.active a {
    background-color: transparent;
    color: g.$text-green-primary;
    padding-inline: 4px;

    @media #{g.$sp} {
      padding-inline: 0;
    }
  }

  > a {
    border-radius: 2px;
    font-size: 12px;
    width: 49%;
    min-height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media #{g.$sp} {
      min-height: 40px;
    }
  }
}

// マイページ
#account__view_account_message_history,
#account__view_orders,
#account__view_order_history,
#account__view_points_history {
  .pagination {
    margin-top: 30px;

    display: flex;
    justify-content: center;
  }

  .pagination:not(:has(*)) {
    margin: 0;
  }

  .pagination a {
    background-color: #f6f6f6;
    padding: 12px 20px;
    position: relative;
    text-align: center;
    width: 100%;

    @media #{g.$sp} {
      padding: 7px 15px;
    }
  }

  .pagination a + a {
    margin-left: 20px;
  }
}

// FAQ,商品詳細
.itemlist,
.newsentries.faq,
.faq_imported {
  .pagination {
    @media #{g.$sp} {
      overflow-x: scroll;
      padding-bottom: 4px;
    }
  }

  // .pagination ul li.boundary.far-side,
  // .pagination ul li:not(.boundary):not(.active) {
  //   @media #{g.$sp} {
  //     display: none;
  //   }
  // }

  .pagination ul {
    @media #{g.$sp} {
      gap: 0;
    }
  }

  .pagination ul li {
    @media #{g.$sp} {
      padding-inline: 4px;
    }
  }

  .pagination ul li.active {
    @media #{g.$sp} {
      padding-inline: 8px;
    }
  }
}
