@use "global" as g;

/*
  ---------------
  パートワーク共通
  ---------------
*/
#partwork ul.distributions {
  margin-top: 12px;
}

#partwork ul.distributions > li {
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 2px;
  border: 1px solid g.$text-black-secondary;
  min-height: 90px;
  padding: 10px 30px 10px 20px;

  position: relative;

  @media #{g.$sp} {
    min-height: auto;
    padding: 16px 20px 16px 16px;
  }

  &:hover {
    opacity: .7;
  }
}

// 通常ボタンのスタイル
#partwork ul.distributions.no-group > li {
  background: g.$bg-gray-primary;
}

#partwork ul.distributions > li + li {
  margin-top: 12px;
}

#partwork ul.distributions > li .distribution-summary::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_chevron_right.svg") center / contain no-repeat;
  display: inline-block;
  width: 6px;
  height: 10px;

  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);

  @media #{g.$sp} {
    right: 8px;
  }
}

#partwork ul.distributions > li .distribution-summary .title {
  color: g.$text-black-tertiary;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;

  @media #{g.$sp} {
    font-size: 18px;
    line-height: 1.2;
  }
}

#partwork .distribution-summary .text-contents.description {
  font-size: 13px;
  margin-top: 4px;
  line-height: 1.1;

  @media #{g.$sp} {
    font-size: 12px;
  }
}

#partwork ul.distributions > li .distribution-summary .btn,
#partwork ul.distributions > li .distribution-summary .btn a {
  font-size: 0;
}

#partwork ul.distributions > li .distribution-summary .btn a {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
}

#partwork #page_aside2 {
  display: none;
}

// ディストリビューショングループ
#partwork .distribution-group-list {
  margin-top: 80px;

  @media #{g.$sp} {
    margin-top: 40px;
  }
}

#partwork .distribution-group-list .distribution-group {
  border-radius: 2px;
  margin-top: 12px;
}

#partwork .distribution-group-list .distribution-group .distributions.group {
  @media #{g.$sp} {
    margin-top: 20px;
  }
}

#partwork .distribution-group-list .distribution-group .heading .group-name {
  font-size: 20px;
  font-weight: 700;

  @media #{g.$sp} {
    font-size: 18px;
  }
}

#partwork .distribution-group-list .distribution-group .heading .description {
  font-size: 12px;
}

// Primaryボタンのスタイル
#partwork .distribution-group-list .distribution-group.primary .distributions.group > li {
  border: 1px solid g.$border-green;
  background: rgba(g.$bg-green-secondary, .1)
}

#partwork .distribution-group.primary ul.distributions > li .distribution-summary::after {
  background: transparent url(/f/resources/images/common/icon_chevron_right_green.svg) center / contain no-repeat;
}

#partwork .distribution-group-list .distribution-group.hide-heading > .heading {
  display: none;
}

// ディストリビューションブループアコーディオンボタンのスタイル
#partwork .distribution-group-list .distribution-group.primary.toggle {
  background: rgba(g.$bg-green-secondary, .1);
  border: 1px solid g.$border-green;

  > .heading {
    cursor: pointer;
    min-height: 90px;
    padding: 20px;
    position: relative;

    @media #{g.$sp} {
      min-height: auto;
      padding: 16px 36px 16px 16px;
    }

    &:hover {
      opacity: .7;
    }
  }

  > .heading::after {
    content: "";
    background: transparent url(/f/resources/images/common/icon_minus_green_bold.svg) center / contain no-repeat;
    display: inline-block;
    width: 15px;
    height: 13px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  &.is-hide > .heading::after {
    background: transparent url(/f/resources/images/common/icon_plus_green_bold.svg) center / contain no-repeat;
  }

  .distributions.group {
    margin-top: 0;
    padding: 0 12px 12px 24px;

    @media #{g.$sp} {
      padding-left: 12px;
    }
  }

  // ディストリビューショングループの子のボタンのスタイル
  .distributions.group > li {
    background: g.$bg-gray-primary;
    border: 1px solid g.$text-black-secondary;
  }

  .distributions.group > li .distribution-summary::after {
    background: transparent url(/f/resources/images/common/icon_chevron_right.svg) center / contain no-repeat;
  }
}