@use "global" as g;

/*
  ---------------
  Internal Banner | 内部バナー
  ---------------
*/
// ers-dev.css 追加
.p-internal-bnr__item-community {
  background-color: #1d1d1b;
}

.p-internal-bnr__item-link {
  display: block;
  color: #fff;
  height: 100%;
  line-height: 78px;
  text-align: center;
  width: 100%;
}
// ers-dev.css ここまで

.p-internal-bnr {

  &__inner {}

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  &__item {
    flex-basis: 232px;
  }

  &__item-link {}
}
