@use "global" as g;

/*
  ---------------
  シリーズトップ | 関連ボタン(定期購読~グッズ)
  ---------------
*/
.related__pw .related__pw-inner {
  display: flex;

  @media #{g.$pc} {
    gap: clamp(0px, 4.16666667vw, 60px);
    align-items: stretch;
    justify-content: space-between;
  }

  @media #{g.$tab-under} {
    flex-direction: column-reverse;
  }
}

.related__pw .related__pw-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  max-width: 400px;
  width: 100%;

  @media #{g.$tab-under} {
    display: block;
    max-width: 100%;
  }
}

.related__item-description {
  font-size: 20px;
  padding-bottom: 20px;

  @media #{g.$sp} {
    font-size: 16px;
  }
}

.related__pw .button-wrap {

  @media #{g.$pc} {
    max-width: 400px;
  }

  @media #{g.$tab-under} {
    max-width: 100%;
  }
}

.related__pw .button-wrap .show-partwork-dtl {
  @include g.hoverLink();

  background: linear-gradient(90deg, #005C69 0%, #00ADA0 100%);
  color: g.$text-white-primary;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.45833333;
  width: 100%;
  min-height: 80px;

  @media #{g.$sp} {
    min-height: 65px;
  }
}

.related__pw .button-wrap .show-partwork-dtl span {
  color: g.$text-white-primary;
  padding-left: 36px;
  position: relative;
}

.related__pw .button-wrap .show-partwork-dtl span::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_cart.svg") center / contain no-repeat;
  display: inline-block;
  width: 26px;
  height: 30px;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.related__pw .related__pw-btn {
  margin-top: 10px;
}

.related__pw .related__pw-btn a {
  background-color: #494949;
  color: g.$text-white-primary;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  min-height: 50px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  @media #{g.$pc} {
    max-width: 400px;
  }

  @media #{g.$tab-under} {
    max-width: 100%;
  }
}

.related__pw .related__pw-btn a::after {
  background: url(/f/resources/images/common/icon_link_othertab_white.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  margin-left: 4px;
  height: 16px;
  width: 16px;
}

.related__pw .related__item-usage {
  margin-top: 10px;

  display: flex;
  gap: 10px;
}

.related__pw .related__item-usage li {
  width: 100%;
}

.related__pw .related__item-usage li a {
  background: linear-gradient(0deg, #F6F6F6, #F6F6F6), linear-gradient(0deg, #B2B2B2, #B2B2B2);
  border: 1px solid g.$border-gray-dark;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42857143;
  width: 100%;
  min-height: 50px;
  padding: 0 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.related__pw .related__item-usage li.button-faq a {
  font-size: 17px;
  letter-spacing: 2px;
}

.related__item-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.related__pw .related__item-list {

  @media #{g.$tab-under} {
    display: block;
    margin-top: 20px;
  }

  @media #{g.$pc} {
    gap: 20px;
  }
}

.related__pw .related__item-list li {
  border-color: g.$border-gray;
  border-radius: 2px;
  width: 100%;
  height: auto;
}

.related__pw .related__item-list li + li {
  @media #{g.$tab-under} {
    margin-top: 10px;
  }
}

.related__pw .related__item-list li a {
  color: g.$text-green-primary;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4375;
  text-align: left;
  width: 100%;
  padding-right: 10px;

  display: flex;
}

.related__pw .related__item-list li a img {
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 108px;
  height: 100%;
  max-height: 108px;

  @media #{g.$sp} {
    max-width: 100px;
    max-height: 100px;
  }
}

.related__pw .related__item-list li a span {
  padding: 20px 0;
  width: 100%;
  margin-left: 20px;

  display: flex;
  align-items: center;

  position: relative;

  @media #{g.$sp} {
    padding: 13px 0 14px;
  }
}

.related__pw .related__item-list li a span::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_chevron_right.svg") center / cover no-repeat;
  display: inline-block;
  width: 6px;
  height: 10px;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
