@use "global" as g;

/*
  ---------------
  パートワーク商品詳細
  ---------------
*/
.partwork-product .p-product {
  margin-top: 40px;
}

.partwork-product .p-product .p-product__block {
  @media all and (min-width: 1025px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: min(5.55555556vw, 80px);
  }
}


.partwork-product .p-product .p-product__btn-group {
  margin-top: 90px;

  @media #{g.$sp} {
    margin-top: 50px;
  }
}

.partwork-product .p-product .p-product__btn {
  background-color: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  width: calc(50% - 15px);

  @media #{g.$sp} {
    width: 100%;
  }
}

// バインダー・ケース・別売りパーツを購入するリンクエリア
.partwork-product .p-product .p-product__btn-group--item {
  @media #{g.$pc} {
    display: flex;
    gap: 30px;
  }
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn + .p-product__btn {
  @media #{g.$sp} {
    margin-top: 10px;
  }
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn-link {
  color: g.$text-green-primary;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4375;
  text-align: left;
  width: 100%;
  padding-right: 20px;

  display: flex;
  align-items: center;

  position: relative;
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn-link > p {
  flex: 0 0 108px;

  @media #{g.$sp} {
    flex: 0 0 100px;
    height: 100px;
  }
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn-link img {
  width: 100%;
  height: 100%;
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn-link span {
  display: block;
  width: 100%;
  margin-left: 20px;
  padding-right: 10px;
  position: relative;
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn-link span::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_chevron_right.svg") center / cover no-repeat;
  display: inline-block;
  width: 6px;
  height: 10px;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

// コミュニティ〜よくある質問リンク
.partwork-product .p-product .p-product__btn-group--usage {
  margin-top: 30px;

  display: flex;
  gap: 30px;

  @media #{g.$sp} {
    gap: 10px;
    margin-top: 16px;
  }
}

.partwork-product .p-product .p-product__btn-group--usage .p-product__btn-link {
  min-height: 70px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.partwork-product .p-product .p-product__btn-group--usage .p-product__btn-link span {
  display: inline-block;
  padding-left: 26px;

  position: relative;
}

.partwork-product .p-product .p-product__btn-group--usage .p-product__btn-link span::after {
  content: "";
  display: inline-block;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__btn-group--usage .p-product__btn-link span.community::after {
  background: transparent url("/f/resources/images/common/icon_community.svg") center / cover no-repeat;
  width: 22px;
  height: 20px;
}

.partwork-product .p-product .p-product__btn-group--usage .p-product__btn-link span.qa::after {
  background: transparent url("/f/resources/images/common/icon_qa.svg") center / cover no-repeat;
  width: 18px;
  height: 20px;
}

// 商品メイン画像エリア
.partwork-product .p-product .p-product__display-btn-group {
  display: grid;
  gap: 20px;

  @media #{g.$sp} {
    gap: 10px;
  }
}

.partwork-product .p-product .p-product__display-btn-link--series {
  background-color: g.$bg-green-secondary;
  color: g.$text-white-primary;
  min-height: 50px;
  padding: 0 10px;

  justify-content: center;
}

.partwork-product .p-product .p-product__display-btn-link--other {
  border: 1px solid g.$border-green;
  color: g.$text-green-secondary;
  min-height: 50px;
  padding: 0 10px;

  justify-content: center;
}

.partwork-product .p-product .p-product__display-btn-icon {
  padding-right: 23px;

  @media #{g.$sp} {
    font-size: 14px;
    line-height: 1.42857143;
  }
}

.partwork-product .p-product .p-product__display-btn-icon::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__display-btn-link--series .p-product__display-btn-icon::after {
  background: transparent url("/f/resources/images/common/icon_link_white.svg") center / contain no-repeat;
}

.partwork-product .p-product .p-product__display-btn-link--other .p-product__display-btn-icon::after {
  background: transparent url("/f/resources/images/common/icon_link_green.svg") center / contain no-repeat;
}

// メインスライド
.partwork-product .p-product .p-product__display-slider {
  margin-top: 20px;

  position: relative;

  @media all and (min-width: 1025px) {
    max-width: 560px;
  }
}

.partwork-product .p-product .p-product__display-slide {
  border: 1px solid g.$border-gray;
  cursor: pointer;
  display: table;
  position: relative;
  text-align: center;
  transition: .2s ease-in-out opacity;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media all and (min-width: 1025px) {
    max-width: 560px;
  }
}

.partwork-product .p-product .p-product__display-slide:hover {
  opacity: .8;
}

.partwork-product .p-product .p-product__display-slide::before {
  background: url("/f/resources/images/common/icon_zoomin_green.svg") center / contain no-repeat;
  content: "";
  display: inline-block;
  width: 23px;
  height: 23px;
  z-index: 1;

  position: absolute;
  right: 68px;
  top: 7px;
}

.partwork-product .p-product .p-product__display-slide::after {
  content: '拡大する';
  color: g.$text-green-primary;
  font-size: 16px;
  z-index: 1;

  position: absolute;
  right: 4px;
  top: 6px;
}

.partwork-product .p-product .p-product__display-slide img {
  aspect-ratio: 560 / 560;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: auto;
}

.partwork-product .p-product .p-product__display-dots {
  position: relative;
}

// 商品タイトル〜購入ボタンエリア
.partwork-product .p-product .p-product__detail {

  @media #{g.$tab-under} {
    margin-top: 20px;
  }
}

.partwork-product .p-product .p-product__detail-header-title {
  display: inline;
}

.partwork-product .p-product div.item-tags {
  display: inline-flex;
  vertical-align: 2px;
}

.partwork-product .p-product div.item-tags div.item-tag {
  border-radius: 0;
  padding: 0 4px 2px;
}

.partwork-product .p-product .p-product__detail-header-title span:first-child {
  font-weight: 700;
}

.partwork-product .p-product .p-product__detail-header-number {
  color: g.$text-black-secondary;
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 7px;

  @media #{g.$sp} {
    margin-top: 9px;
  }
}

// 個別送料
.partwork-product .p-product .p-product__deliveryFee {
  margin-top: 12px;
}

.partwork-product .p-product .p-product__deliveryFee-header {
  font-size: 14px;
}

.partwork-product .p-product .p-product__deliveryFee-body {
  font-weight: 700;
  font-size: 22px;
}

.partwork-product .p-product .p-product__detail form {
  margin-top: 50px;

  @media #{g.$sp} {
    margin-top: 30px;
  }
}

.partwork-product .p-product .p-product__detail form .item-qty-item-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.partwork-product .p-product .p-product__detail form .item-qty-note {
  color: g.$text-red-primary;
  margin-inline-end: 20px;

  @media #{g.$sp} {
    font-size: 14px;
    line-height: 1.42857143;
    margin-inline-end: 17px;
  }
}

// 数量
.partwork-product .p-product .p-product__detail form .item-qty-item {
  display: flex;
  align-items: center;
}

.partwork-product .p-product .p-product__detail form .item-qty-label {
  font-size: 14px;
  line-height: 1.42857143;
  margin-inline-end: 15px;

  @media #{g.$sp} {
    margin-inline-end: 8px;
  }
}

.partwork-product .p-product .p-product__detail form .qty.item-qty {
  appearance: none;
  background: #F6F6F6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  border: 0;
  border-radius: 2px;
  color: #1D1D1B;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  line-height: normal;
  padding-inline: 10px 29px;

  display: inline-flex;
  align-items: center;

  @media #{g.$sp} {
    font-size: 16px;
    height: 35px;
    padding-inline-end: 13px;
    width: 84px;
  }

  @media #{g.$pc} {
    width: 150px;
  }
}

// カートに入れる 今すぐ購入 wrapper
.partwork-product .p-product .p-product__detail form .btn-group-purchase {
  font-size: 0;
  margin-top: 20px;

  display: flex;
  justify-content: flex-end;

  @media #{g.$sp} {
    width: 100%;
    margin-top: 10px;

    justify-content: space-between;
    gap: 2.54452926vw;
  }

  @media #{g.$pc} {
    gap: clamp(0px, 0.69444444vw, 20px);
  }
}

.partwork-product .p-product .p-product__detail form button.btn.add-item-to-cart,
.partwork-product .p-product .p-product__detail form button.btn.checkout-item {
  margin-left: 0;
  border: 0;
  border-radius: 2px;
  color: g.$text-white-primary !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.33333333; // lh22px
  width: 50%;
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  @media #{g.$sp} {
    width: 100%;
  }
}

// カートに入れる
.partwork-product .p-product .p-product__detail form .add-item-to-cart {
  background: #00828c;
  min-width: 178px;
  height: 50px;
  padding: 0 19px 0 47px;

  @media #{g.$sp} {
    min-width: auto;
    padding-left: 35px;
  }
}

.partwork-product .p-product .p-product__detail form .add-item-to-cart::before {
  content: "";
  background: url("/f/resources/images/packtoy/list/icon_cart.svg") center / contain no-repeat;
  display: inline-block;
  width: 17px;
  height: 20px;

  position: absolute;
  top: 50%;
  right: auto;
  left: calc(50% - 66px);
  transform: translateY(-50%);

  @media #{g.$sp} {
    left: calc(50% - 67px);
  }
}

// 今すぐ購入
.partwork-product .p-product .checkout-item {
  background: #ff9c08;
  min-width: 178px;
  height: 50px;
  padding: 0 47px 0 19px;

  @media #{g.$sp} {
    min-width: auto;
  }
}

.partwork-product .p-product .checkout-item::before {
  content: "";
  border-radius: 100%;
  background: url("/f/resources/images/common/icon_arrow_right_bg_white.svg") center / contain no-repeat;
  display: inline-block;
  height: 24px;
  margin-left: 20px;
  width: 24px;

  position: absolute;
  top: 50%;
  right: 14px;
  left: auto;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__detail-btn-group {
  margin-top: 30px;

  @media #{g.$sp} {
    margin-top: 20px;
  }
}

.partwork-product .p-product .p-product__detail-btn-link.subscription {
  @include g.hoverLink();

  background: linear-gradient(90deg, #005C69 0%, #00ADA0 100%);
  color: g.$text-white-primary;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.45833333;
  width: 100%;
  min-height: 80px;
}

.partwork-product .p-product .p-product__detail-btn-link.subscription span {
  color: g.$text-white-primary;
  padding-left: 36px;
  position: relative;
}

.partwork-product .p-product .p-product__detail-btn-link.subscription span::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_cart.svg") center / contain no-repeat;
  display: inline-block;
  width: 26px;
  height: 30px;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__detail-btn-link:not(.subscription) {
  background-color: #494949;
  color: g.$text-white-primary;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  min-height: 50px;
  margin-top: 10px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.partwork-product .p-product .p-product__detail-btn-link:not(.subscription)::after {
  background: url(/f/resources/images/common/icon_link_othertab_white.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  margin-left: 4px;
  height: 16px;
  width: 16px;
}

// 金額エリア
.partwork-product .p-product .p-product__price {}
.partwork-product .p-product .p-product__price-amount {
  margin-top: 28px;

  @media #{g.$sp} {
    margin-top: 24px;
  }
}

.partwork-product .p-product .p-product__price-amount-price {
  font-size: 35px;
  font-weight: 700;
  line-height: 1.45714286;
}

.partwork-product .p-product .p-product__price-amount-tax {
  font-weight: 500;
}

.partwork-product .p-product .p-product__price-base {
  color: g.$text-black-secondary;
  font-size: 14px;
  line-height: 1.42857143;
}

.partwork-product .p-product .p-product__price-original {
  color: g.$text-black-secondary;
  font-size: 14px;
  line-height: 1.42857143;
  text-decoration: line-through;
  margin-top: 5px;
}

// 商品概要エリア
.partwork-product .p-product .p-product__summary {
  border-bottom: 1px solid g.$border-gray;
  margin-top: 30px;
  padding-bottom: 30px;

  @media #{g.$sp} {
    padding-bottom: 20px;
    margin-top: 20px;
  }
}

.partwork-product .p-product .p-product__summary-title {
  font-size: 18px;
  line-height: 1.45833333;
  margin-top: 20px;
}

.partwork-product .p-product .p-product__summary-description {
  font-size: 16px;
  line-height: 1.42857143;
  margin-top: 6px;

  @media #{g.$sp} {
    font-size: 14px;
    margin-top: 12px;
  }
}

.partwork-product .p-product .p-product__summary-description + .p-product__summary-description {
  margin-top: 10px;
}

// 仕様欄 特殊なHTMLタグ
.partwork-product .p-product .p-product__summary-description r {
  color: red;
}

.partwork-product .p-product .p-product__summary-description rb {
  color: red;
  font-weight: bold;
}

.partwork-product .p-product .p-product__summary-description b {
  font-weight: bold;
}

.partwork-product .p-product .p-product__summary-content {
  padding: 27px 30px 40px;
  margin-top: 30px;

  @media #{g.$sp} {
    margin-top: 20px;
    padding: 17px 10px 30px;
  }
}

.partwork-product .p-product .p-product__summary-content-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44444444;

  @media #{g.$sp} {
    font-size: 16px;
    line-height: 1.4375;
  }
}

.partwork-product .p-product .p-product__summary-content-body {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 10px;
}

// その他の号エリア
.partwork-product .p-product .p-product__issue {
  margin-top: 80px;

  @media #{g.$sp} {
    margin-top: 50px;
  }
}

.partwork-product .p-product .p-product__issue-inner {
  @media #{g.$pc} {
    position: relative;
  }
}

.partwork-product .p-product .p-product__issue-btn {
  text-align: center;

  @media #{g.$pc} {
    position: absolute;
    top: 5px;
    right: 0;
  }

  @media #{g.$sp} {
    margin-top: 40px;
  }
}

.partwork-product .p-product .p-product__issue-btn-link {
  color: g.$text-green-primary;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.46666667;
}

.partwork-product .p-product .p-product__issue-btn-link span {
  padding-right: 37px;

  position: relative;
  display: block;
  text-align: center;

  @media #{g.$pc} {
    width: 100%;
  }

  @media #{g.$sp} {
    display: inline-block;
  }
}

.partwork-product .p-product .p-product__issue-btn-link span::after {
  content: "";
  border-radius: 2px;
  background: g.$bg-white-primary url("/f/resources/images/top/icon_arrow_right_bg_green.svg") center / contain no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__issue-slider {
  margin-top: 20px;
}

// その他の号・チェックした商品共通(カードレイアウト)
.partwork-product .p-product .p-product__card {
  margin-inline: min(1.04166667vw, 15px);

  @media #{g.$sp} {
    margin-inline: 2.54452926vw;
  }
}

.partwork-product .p-product .p-product__card-link {
  width: 100%;
}

.partwork-product .p-product .p-product__card-img {
  text-align: center;
  aspect-ratio: 278/245;
  border: 1px solid g.$border-gray;
  position: relative;
}

.partwork-product .p-product .p-product__card-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.partwork-product .p-product .p-product__card-title {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 17px;

  @media #{g.$sp} {
    font-weight: 500;
    margin-top: 10px;
  }
}

.partwork-product .p-product .p-product__card-text {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 10px;

  @media #{g.$sp} {
    font-weight: 500;
  }
}

// slickカスタマイズ
.partwork-product .p-product .p-product__display {

  .slick-dotted.slick-slider {
    margin-bottom: 20px;
    // ピンチイン / ピンチアウト有効化
    touch-action: auto;

    @media #{g.$sp} {
      margin-bottom: 10px;
    }
  }

  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-prev,
  .slick-next {
    @media #{g.$pc} {
      width: 15px;
      height: 30px;
    }
  }

  .slick-prev {
    @media #{g.$pc} {
      left: -30px;
    }
  }

  .slick-next {
    @media #{g.$pc} {
      right: -30px;
    }
  }

  .slick-prev:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/partwork/product/icon_prev.svg") center / contain no-repeat;
      width: 15px;
      height: 30px;
    }
  }

  .slick-next:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/partwork/product/icon_next.svg") center / contain no-repeat;
      width: 15px;
      height: 30px;
    }
  }

  .slick-dots {
    position: static;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 14px;

    @media #{g.$sp} {
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
    }
  }

  .slick-dots li {
    aspect-ratio: 26/23;
    border: 1px solid g.$border-gray;
    display: block;
    width: auto;
    height: auto;
    margin: 0;

    position: relative;
  }

  .slick-dots li:not(.slick-active) {
    opacity: .5;
  }

  .slick-dots img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.partwork-product .p-product .p-product__issue {
  .slick-list {
    @media #{g.$pc} {
      margin: 0 min(-1.04166667vw, -15px);
    }

    @media #{g.$sp} {
      overflow: visible;
    }
  }

  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;

    @media #{g.$sp} {
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev {
    left: min(-2.08333333vw, -30px);

    @media all and (max-width: 1024px) {
      left: min(-2.44140625vw, -25px);
    }

    @media #{g.$sp} {
      left: -14px;
    }
  }

  .slick-next {
    right: min(-2.08333333vw, -30px);

    @media all and (max-width: 1024px) {
      right: min(-2.44140625vw, -25px);
    }

    @media #{g.$sp} {
      right: -14px;
    }
  }

  .slick-prev:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-next:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }
}
