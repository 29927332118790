@use "global" as g;

/*
  ---------------
  top-newslist-announce | 重要なお知らせ header上
  ---------------
*/

// ers-dev.css 追加
.news-entry-announce {
  color: #C10000;
  padding: 2px 0;
}

.news-entry-announce .entry-text {
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.news-entry-announce .text {
  text-decoration: none;
  color: #C10000;
}
// ers-dev.css ここまで

#header .top-newslist-announce {

  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  div.panel-heading + div.panel-body {
    margin-top: 0;
  }

  div.news-entries div.news-entry {
    border: 0;
  }

  div.news-entries div.news-entry div.entry-text {
    padding: 0;
    max-width: 100%;
  }

  div.news-entries div.header {
    line-height: 1;
  }

  div.news-entries div.header div.news-type span {
    background-color: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
  }

  .news-entry-announce {
    padding: 0;
  }

  div.news-entries div.header {

    @media screen and (max-width: 800px) {
      display: flex;
    }
  }


  /*==========================================
    スタイル追加
  ==========================================*/
  background-color: g.$bg-red-primary;

  .panel.panel-news {
    gap: 0;
    padding-top: 9px;
    padding-bottom: 8px;
    padding-right: 3.05343511vw;
    padding-left: 3.05343511vw;

    @media #{g.$pc} {
      max-width: 1280px;
      margin: 0 auto;
      padding-right: clamp(0px, 2.77777778vw, 40px);
      padding-left: clamp(0px, 2.77777778vw, 40px);
    }
  }

  .panel-body {
    position: relative;
  }

  .announce-accordion {
    border: 1px solid g.$text-red-primary;
    border-radius: 4px;
    color: g.$text-red-primary;
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
    padding: 4px 8px;

    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @media #{g.$sp} {
      padding: 4px;
    }
  }

  .announce-accordion img {
    display: inline-block;
    height: 14px;
    margin-left: 2px;
  }

  &.is-open .announce-accordion img {
    transform: rotate(180deg);
  }

  .news-entry:nth-child(n + 2) {
    display: none;
  }

  &.is-open .news-entry:nth-child(n + 2) {
    display: block;

    @media #{g.$sp} {
      margin-top: 4px;
    }
  }

  .meta {
    min-width: 0;

    display: flex;
    align-items: center;
  }

  .release-date {
    color: g.$text-red-primary;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.41666667;
    min-width: 0;
    margin-left: clamp(0px ,2.79898219vw , 11px);

    @media #{g.$pc} {
      font-size: 14px;
      line-height: 1.42857143;
      margin-left: 2.36111111vw;
    }

    order: 2;
  }

  .news-type-announce {
    min-width: 0;
    flex: 1 0 auto;
    padding-left: 20px;

    order: 1;
    position: relative;

    @media #{g.$pc} {
      color: g.$text-red-primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4375;
      padding-left: 24px;
      // margin-left: 2.79898219vw;
      // margin-left: 0.34722222vw;
    }

    &::after {
      content: "";
      background: transparent url("/f/resources/images/common/icon_notice.svg") center / contain no-repeat;
      display: inline-block;
      width: 20px;
      height: 20px;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    span {
      @media #{g.$sp} {
        display: none;
      }
    }
  }

  .headline {
    flex: 1;
    min-width: 0;
    padding-right: 65px;
    margin-left: 2.54452926vw;

    @media #{g.$sp} {
      min-width: 0;

      display: flex;
    }

    @media #{g.$pc} {
      margin-left: 1.04166667vw;
    }
  }

  .headline a {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
  }

  .headline .text {
    color: g.$text-red-primary;
    display: block;
    font-size: 12px;
    font-weight: 400;
    width: 100%;

    overflow: hidden;
    white-space: nowrap;

    @media #{g.$pc} {
      font-size: 14px;
      line-height: 1.42857143;
    }
  }
}
