@use "global" as g;
/*
  ----------------
  お問い合わせ一覧
  ----------------
*/
.contact-list {

}

.contact-list__wrap {
  @include g.container();
  padding-top: 100px;

  display: grid;
  place-items: center;

  @media #{g.$sp} {
    padding-top: 40px;
  }
}

.contact-list__title {
  font-size: 24px;
  font-weight: 500;
  padding: 0;
}

.contact-list__list {
  padding-top: 40px;
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media #{g.$sp} {
    grid-template-columns: repeat(1, 1fr);
  }
}

.contact-list__link {
  @include g.hoverButton();
  background: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  padding: 20px 25px 20px;
  position: relative;
  text-decoration: none;
  min-height: 120px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  @media #{g.$sp} {
    gap: 10px;
    min-height: 70px;
    padding: 10px 12px 10px;
  }
}

.contact-list__icon {
  object-fit: contain;
  object-position: left;
  width: 80px;
  height: 48px;

  @media #{g.$sp} {
    height: 40px;
    width: 45px;
  }
}
