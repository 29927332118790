@use "global" as g;
/*
  ----------------
  デアゴミュージアム 詳細
  ----------------
*/
.news-archive-archive {
  @include g.container;

  .news-entry {
    margin-top: 60px;

    @media #{g.$pc} {
      min-height: 560px;
      position: relative;
    }

    @media #{g.$sp} {
      margin-top: 25px;
    }
  }

  .news-entry-icon {
    border: 1px solid g.$border-gray;
    padding: 56px;

    @media #{g.$pc} {
      display: inline-block;
      width: 46.6666667%;
      max-width: 560px;

      position: absolute;
      top: 0;
      left: 0;
    }

    @media #{g.$sp} {
      max-width: 400px;
      margin-inline: auto;
      padding: 37px;
    }
  }

  .news-entry-icon img {
    aspect-ratio: 1 / 1;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .news-entry-header{
    width: 100%;

    @media #{g.$pc} {
      margin-left: calc(46.6666667% + 6.66666667%);
    }

    @media #{g.$sp} {
      margin-top: 20px;
    }
  }

  .news-entry .news-entry-header .news-entry-subject {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.45833333;

    @media #{g.$sp} {
      font-size: 20px;
      line-height: 1.45;
    }
  }

  .news-entry-body {
    margin-top: 20px;

    @media #{g.$pc} {
      margin-left: calc(46.6666667% + 6.66666667%);
    }

    @media #{g.$sp} {
      margin-top: 14px;
    }
  }

  .museum-item__info-head {
    background-color: g.$bg-gray-primary;
    color: g.$text-green-primary;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.44444444;
    padding: 20px 30px 0;

    @media #{g.$sp} {
      font-size: 16px;
      line-height: 1.4375;
      padding: 12px 14px 0;
    }
  }

  .museum-item__info {
    background-color: g.$bg-gray-primary;
    font-size: 0;
    padding: 13px 30px 20px;

    @media #{g.$sp} {
      padding: 4px 14px 12px;
    }
  }

  .museum-item__info dt,
  .museum-item__info dd {
    display: inline-block;
  }

  .museum-item__info dt {
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
    min-width: 100px;

    @media #{g.$sp} {
      font-size: 14px;
      line-height: 1.78571429;
    }
  }

  .museum-item__info dd {
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
    width: calc(100% - 101px);

    @media #{g.$sp} {
      font-size: 14px;
      line-height: 1.78571429;
    }
  }

  .museum-item__content {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.44444444;
    margin-block: 20px;

    @media #{g.$sp} {
      font-size: 14px;
      line-height: 1.42857143;
      padding-inline: 14px;
    }
  }

  .hashtag {
    color: g.$text-green-primary;
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.44444444;

    @media #{g.$sp} {
      font-size: 14px;
      line-height: 1.42857143;
      padding-inline: 14px;
    }
  }

  .trailer-link {
    text-align: left;
    margin-top: 40px;
    padding: 0;

    @media #{g.$pc} {
      text-align: center;
    }
  }

  .trailer-link a {
    background-color: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    color: g.$text-green-primary;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3125;
    min-height: 50px;
    padding: 13px 21px 13px 48px;

    display: inline-flex;
    align-items: center;

    position: relative;

    @media #{g.$pc} {
      min-width: 390px;
    }

    @media #{g.$sp} {
      width: 100%;
      max-width: 270px;
      min-height: 60px;
      margin-inline: auto;
      padding: 9px 23px 9px 53px;

      display: flex;
    }
  }

  .trailer-link a::after {
    background: transparent url("/f/resources/images/common/icon_arrow_right_bg_green.svg") center / contain no-repeat;
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;

    position: absolute;
    top: 50%;
    left: 17px;
    transform: translateY(-50%);
  }

  div.news-entry div.release-date,
  .reactions.news {
    display: none;
  }
}
