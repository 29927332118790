@use "global"as g;

/*
  ---------------
  Distribution common
  ---------------
*/
// ers-dev.css 追加
/* ディストリビューション | Distribution */
.distribution .dstr-title {
  font-size: 24px;
  font-weight: bold;
}

.parcel {
  list-style-type: none;
}

.parcel {
  border: 1px solid #eee;
  margin-top: 20px;
  padding: 20px;
}

.parcel .summary .label {
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
}

.parcel .issues .label {
  font-weight: bold;
}

.parcel .shipping-from {
  text-decoration: underline;
}
// ers-dev.css ここまで

.distribution {
  text-align: left;
  margin-top: 0;

  > .heading {
     background-color: g.$bg-gray-primary;
     padding: 22px 40px;

     @media #{g.$sp} {
      padding: 20px 12px 12px;
     }
   }

  .heading .main-title img {
    margin-bottom: 8px;
  }

  .heading .main-title .pwk-title,
  .heading .main-title .dstr-title {
    color: g.$text-black-tertiary;
    display: block;
    font-weight: 500;
    line-height: 1.45833333;
  }

  .heading .main-title .pwk-title {
    font-size: 24px;

    @media #{g.$sp} {
      font-size: 18px;
      padding-right: 32px;
    }
  }

  .heading .main-title .dstr-title {
    color: g.$text-black-tertiary;
    font-size: 24px;
    padding-top: 22px;
    position: relative;
    margin-top: 16px;

    display: flex;
    flex-direction: column;

    @media #{g.$sp} {
      font-size: 20px;
    }
  }

  .body & .heading .main-title .dstr-title::before {
    background-color: g.$text-green-primary;
    content: "";
    display: inline-block;
    width: 12px;
    height: 11px;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    position: absolute;
    top: 10px;
    left: 0;
    transform: translateY(-50%);
  }

  .body & .heading .main-title .dstr-title::after {
    color: g.$text-green-primary;
    content: 'お申し込み内容';
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 16px;
  }

  .heading .dstr-description {
    font-size: 14px;
    text-align: left;
    margin-top: 20px;

    @media #{g.$sp} {
      font-size: 14px;
      margin-top: 16px;
    }
  }

  // ▼オプション、オプション２
  .dstr-options,
  .subs-addon-list {
    margin-block: 16px;
    text-align: left;

    @media #{g.$sp} {
    }
  }

  .dstr-options .heading,
  .subs-addon-list .heading {
    color: g.$text-green-primary;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71428571;
    padding-left: 15px;

    position: relative;
  }

  .dstr-options .heading::before,
  .subs-addon-list .heading::before {
    background-color: g.$bg-green-primary;
    content: "";
    display: inline-block;
    width: 12px;
    height: 11px;
    clip-path: polygon(0 0, 100% 0, 50% 100%);

    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .dstr-options > ul,
  .subs-addon-list > ul {
    margin-top: 2px;

    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .option-title,
  .subs-addon-list .addon-label {
    border: 1px solid g.$bg-green-primary;
    border-radius: 2px;
    color: g.$text-green-primary;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.41666667;
    padding: 1px 4px;
  }

  // 刊行予定
  .distribution-schedule {
    border-top: 1px solid g.$border-gray;
    margin-top: 0;
    padding: 16px 30px;

    @media #{g.$sp} {
      padding: 12px;
    }
  }

  .distribution-schedule > .heading {
    font-size: 20px;
    font-weight: 500;
  }

  .distribution-schedule > .body {
    font-size: 14px;
    padding: 0;
  }

  .distribution-schedule .note {
    margin:0;
  }

  .distribution-schedule .parcels {
    margin-top: 16px;
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .itemdetails & .distribution-schedule .parcels:has(li:nth-child(4)) {
    padding-bottom: 30px;

    @media #{g.$sp} {
      padding-bottom: 4px;
    }
  }

  // システム側で制御する方針により無効化
  // .itemdetails & .distribution-schedule .parcels:has(li:nth-child(4))::after {
  //   content: '以降のスケジュールはマイページでご確認いただけます。';
  //   font-size: 14px;
  //   font-weight: 500;
  //   display: block;

  //   position: absolute;
  //   left: 0;
  //   bottom: -5px;
  // }

  .distribution-schedule li.parcel {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    margin: 0;
    padding: 18px 0 0;
    position: relative;
  }

  // システム側で制御する方針により無効化
  // .itemdetails & .distribution-schedule li.parcel:nth-child(n + 4) {
    // display: none;
  // }

  .distribution-schedule .parcel .summary {
    padding-inline: 20px;
  }

  .distribution-schedule .parcel .summary > .label {
    font-weight: normal;
    padding-right: 240px;
    text-decoration: none;
    margin-bottom: 5px;

    @media #{g.$sp} {
      padding-right: 0;
    }
  }

  .distribution-schedule .parcel .summary > .label > .parcel-label {
    vertical-align: middle;
  }

  .distribution-schedule .parcel .summary .description {
    font-weight: normal;
    font-size: 12px;
  }

  .distribution-schedule .parcel .parcel-extra .parcel-fee {
    color: g.$text-red-primary;
  }

  .distribution-schedule .parcel .parcel-extra .parcel-discount {
    color: g.$text-green-quaternary;
  }

  .distribution-schedule .parcel .parcel-extra .parcel-discount > .value::before {
    content: '-';
    display: inline;
  }

  .distribution-schedule .parcel .parcel-extra .label {
    font-size: 14px;
    font-weight: 400;
    margin-right: 4px;
    text-decoration: none;
  }

  .distribution-schedule .parcel .issues .label,
  .distribution-schedule .parcel .option-parcels .heading,
  .distribution-schedule .parcel .subscription-privileges .heading {
    border-bottom: 1px solid g.$border-gray;
  }

  .distribution-schedule .parcel ul.privileges,
  .distribution-schedule .parcel ul.options,
  .distribution-schedule .parcel ol.issues {
    padding-top: 8px;

    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .distribution-schedule .parcel ol.issues .contents,
  .distribution-schedule .parcel ol.issues .contents {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .distribution-schedule .option-parcels .heading {
    font-weight: 500;
  }

  .distribution-schedule .option-parcels .option .contents,
  .distribution-schedule .subscription-privileges .privilege .contents {
    font-weight: normal;
    padding-left: 20px;

    * {
      font-size: 12px;
    }

    .offering {
      display: none;
    }
  }

  .distribution-schedule .parcel > .subscription-privileges,
  .distribution-schedule .parcel > .option-parcels {
    background-color: g.$bg-gray-primary;
    padding: 12px 20px;
  }

  .distribution-schedule .parcel > .issues {
    background-color: g.$bg-gray-primary;
    margin-top: 18px;
    padding: 12px 20px;
  }

  .distribution-schedule .parcel .subscription-privileges .heading {
    font-size: 0;
  }

  .distribution-schedule .parcel .issues .label {
    font-size: 14px;
    font-weight: 500;
  }

  .distribution-schedule .parcel .issues .price {
    font-weight: normal;
    margin-left: 4px;

    // 0円のイシューは価格非表示
    &.free {
      display: none;
    }
  }

  .distribution-schedule .parcel ol.issues .issue-thumbnail {
    display: none;
  }

  .distribution-schedule .parcel ul.privileges .label,
  .distribution-schedule .parcel ul.options .label,
  .distribution-schedule .parcel ol.issues .item-name a {
    color: g.$text-black-primary;
    font-size: 14px;
    font-weight: 400;
  }

  .distribution-schedule .parcel ul.options .contents .label {
    font-size: 12px;
  }

  .distribution-schedule .parcel .first-parcel-marker,
  .distribution-schedule .parcel .next-parcel-marker {
    order: -1;

    border-radius: 2px;
    color: g.$bg-white-primary;
    display: inline-block;
    font-size: 10px;
    font-weight: normal;
    line-height: 2;
    margin-left: 4px;
    margin-right: 0;
    padding: 0 4px;
  }

  .distribution-schedule .parcel .first-parcel-marker {
    background-color: g.$bg-green-secondary;
  }

  .distribution-schedule .parcel .next-parcel-marker {
    background-color: g.$bg-black-primary;
  }

  .distribution-schedule .parcel .next-note {
    color: g.$text-green-primary;
    background-color: g.$bg-gray-primary;
    display: block;
    font-weight: normal;
    padding: 8px 0;
    text-align: center;
    width: 100%;
  }

  //発送日は基本非表示 表示が必要な画面でのみdisplay: flexに設定
  .distribution-schedule .parcel > .shipping {
    background-color: g.$bg-gray-primary;
    border-radius: 2px;
    display: none;
    overflow: hidden;

    position: absolute;
    top: 18px;
    right: 20px;

    @media #{g.$sp} {
      position: static;
      padding: 12px;
    }
  }

  .distribution-schedule .parcel > .shipping li {
    @media #{g.$sp} {
      border: 1px solid g.$border-gray-dark;
      border-radius: 2px;
      width: 100%;
    }
  }

  .distribution-schedule .parcel .date-to-ship,
  .distribution-schedule .parcel .shipping-from {
    font-size: 12px;
    line-height: 1;
    padding-right: 26px;
    text-decoration: none;

    display: inline-flex;
    align-items: center;
  }

  .distribution-schedule .parcel > .shipping .label {
    background-color: #747474;
    color: g.$text-white-primary;
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    margin-right: 6px;
    padding: 4px 6px;
    vertical-align: text-bottom;
  }
}