@use "global" as g;

/*
  ---------------
  セレクトショップ 商品詳細 | 商品バリエーション
  ---------------
*/
.select-product .p-variation {
  margin-top: 50px;

  &__inner {}

  &__title {}

  &__links {
    margin-top: 9px;
    padding-bottom: 10px;
    overflow-x: auto;
  }

  &__links-list {
    width: 470px;
    white-space: nowrap;

    display: flex;
    gap: 10px;
  }

  &__links-item {
    width: 150px;
  }

  &__radio-input input {
    appearance: none;
    margin: 0;
    position: absolute;
  }

  &__links-item-link {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    background-color: g.$bg-gray-primary;
    cursor: pointer;
    display: block;
    padding: 10px;
    transition: all .2s ease-in-out;
  }

  &__links-item-link:hover {
    border: 1px solid g.$border-green-right;
    // opacity: 1;
  }

  &__links-item-link > div {
    border: 1px solid g.$border-gray;
  }

  &__links-item-link img,
  &__links-item-link div.no-img {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__links-item-link div.no-img {
    background-color: #eee;
    // box-shadow: 0 2px 4px rgba(127, 127, 127, 0.3);
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__links-item-link > p {
    font-size: 14px;
    line-height: 1.57142857;
    text-align: center;
    margin-top: 5px;
    white-space: wrap;
  }

  // &__radio-input input:checked + label {
  //   border: 1px solid g.$border-green-right;
  // }

  // &__radio-input label > div {
  //   border: 1px solid g.$border-gray;
  // }

  // &__radio-input label > p {
  //   font-size: 14px;
  //   line-height: 1.57142857;
  //   text-align: center;
  //   margin-top: 5px;
  // }


  &__checkbox-wrap {
    margin-top: 10px;
    padding-bottom: 10px;
    overflow-x: auto;
  }

  &__checkbox {
    width: 470px;
    white-space: nowrap;

    display: flex;
    gap: 8px;
  }

  &__checkbox-input {}

  &__checkbox input {
    appearance: none;
    margin: 0;
    position: absolute;
  }

  &__checkbox label {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    background-color: g.$bg-gray-primary;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    padding-block: 9px;
    padding-inline: 38px 14px;

    position: relative;
  }

  &__checkbox label::before,
  &__checkbox label::after {
    content: "";
    display: inline-block;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__checkbox label::before {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-gray;
    border-radius: 50%;
    width: 18px;
    height: 18px;

    left: 10px;
  }

  &__checkbox input:checked + label {
    border: 1px solid g.$border-green-right;
  }

  &__checkbox input:checked + label::after {
    background-color: g.$bg-green-quaternary;
    border-radius: 50%;
    width: 14px;
    height: 14px;

    left: calc((20px / 2) - (14px / 2) + 10px);
  }
}
