@use "global" as g;

/*
  ---------------
  注文内容確認
  ** 一部SPのブレイクポイントを900pxに変更（レイアウト崩れるため）
  ---------------
*/
body#account__view_orders .order-valid,
body#order__confirm {
  padding-top: 0;

  // 共通パーツ
  #order-entry #order-entry-order,
  #order-entry .shipments .shipment-entry-adr {
    padding-bottom: 0;
    display: flex;
    gap: 30px;

    @media all and (max-width : 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  /*--------------------------------------------------
    購入者情報、お支払い方法、その他共通
  --------------------------------------------------*/
  #order-entry-content h2 {
    font-size: 20px;
    font-weight: 500;
  }

  #order-entry #order-entry-content .panel {
    border: 1px solid g.$border-gray;
    margin: 0;

    @media all and (max-width : 900px) {
      width: 100%;
    }
  }

  #order-entry .panel .header {
    background: g.$bg-gray-primary;
    padding: 13px 10px 14px 20px;
  }

  #order-entry .panel .body {
    padding: 16px 20px;
  }

  #order-entry .panel .partwork-subscription > .body {
    position: fixed;
    padding: 0 0 22px;
  }

  #order-entry .panel .partwork-subscription > .body .body  {
    padding: 0;
  }

  #order-entry .panel .body .person-contents {
    padding: 0;
  }

  #order-entry-content .panel > .body .control {
    padding-top: 10px;

    position: relative;
    top: unset;
    right: unset;
    transform: unset;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media #{g.$sp} {
      flex-direction: column;
    }

    .btn,
    a {
      font-size: 13px;
      margin-bottom: 0;
      min-width: auto;
      padding: 10px 0;

      @media #{g.$sp} {
        text-align: center;
        width: 100%;
      }
    }

    & > a,
    & > form {
      width: 32.352941%;

      @media #{g.$sp} {
        margin-top: 8px;
        width: 100%;
      }
    }
  }

  #order-entry div.panel .control {
    top: 9px;
  }

  .payment.panel .body {
    // position absolute 要素の高さが取れないため
    min-height: 160px;
  }

  .person-contents .names {
    .kana {
      color: g.$text-black-secondary;
      font-size: 12px;
      font-style: normal;
    }

    .name {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .person-contents {
    .addresses {
      margin-top: 12px;
    }

    .addresses .place,
    .phone-numbers .phone-number,
    .email {
      font-size: 14px;
    }
  }

  .payment {
    .use-points-amount,
    .payment-method {
      font-size: 14px;
    }

    .available-points-amount {
      color: g.$text-black-secondary;
      font-size: 12px;
    }

    .payment-total-invoice {
      font-size: 20px;
      margin-top: 16px;
    }
  }
  // 適用中クーポン
  #detach-coupons .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .btn {
      margin-top: 8px;
    }
  }

  /*--------------------------------------------------
    配送情報
  --------------------------------------------------*/
  .shipments {
    padding-top: 20px;

    .shipment-list {
      padding-top: 24px;
    }

    .shipment {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .shipment-each > h3 {
      background: g.$bg-gray-secondary;
      font-size: 20px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 30px;
      padding: 10px 18px;

      @media #{g.$sp} {
        margin-bottom: 20px;
        padding: 4px 12px;
      }
    }

    .shipment-each + .shipment-each {
      margin-top: 60px;
    }
  }

  #order-entry #order-entry-content .shipment-content {
    margin-top: 30px;
    padding-bottom: 20px;
  }

  .shipment-content h4 {
    font-size: 16px;
    font-weight: 500;
    max-width: 135px;
    padding: 0;
  }

  .shipment ul.delivery-service-conf li > h4+div {
    font-size: 16px;
    padding-left: 0;
  }

  .shipment-content ul.delivery-service-conf {
    margin-inline: 0;

    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .select-delivery-service ul.ds-available {
    padding-top: 10px;

    justify-content: flex-start;
    gap: 10px;
  }

  .select-delivery-service ul.ds-available .ds-available {
    border: none;
    margin: 0;
    width: 200px;

    display: flex;
    flex-direction: column-reverse;
    gap: 8px;

    .ds-desc {
      font-size: 16px;
      font-weight: 500;
      padding: 0;
    }
  }

  .select-delivery-service ul.ds-available li label {
    @include g.hoverButton;
    border: 1px solid g.$border-gray;
    border-radius: 2px;

    display: flex;
    align-items: center;
    gap: 8px;

    input {
      accent-color: #005c69;
      border: 1px solid #d9d9d9;
      margin: 0;
      height: 20px;
      width: 20px;
    }
  }

  #order-entry .itemdetails .itemdetails-caption {
    padding-left: 0;
  }

  #order-entry .itemdetails .itemdetails-caption h3 {
    font-size: 20px;
    font-weight: 500;
    padding: 0;
  }

  #order-entry .itemdetails .itemdetails-caption .msg-add-item {
    color: g.$text-green-primary;
    font-size: 14px;
    line-height: 1.428567143;
    letter-spacing: 0em;
    padding-block: 4px 0;
  }

  /*--------------------------------------------------
    テーブル
  --------------------------------------------------*/
  .itemdetails-header .itemdetail-row .purchase-contents {
    padding-top: 0;
  }

  // パートワーク購読で"UPS"がコードに含まれない商品に文字列を追加
  .itemdetail-row[data-distribution]:not([data-distribution*="UPS"]) .entry-item .item-name > a::after,
  .itemdetail-row[data-distribution]:not([data-distribution*="UPS"]) .entry-item .item-name > .my-item-name::after {
    content: 'から定期購読';
  }

  .itemdetail-row .purchase-contents .subtotal {
    font-size: 16px;
  }

  .itemdetail-row .purchase-contents {
    align-items: flex-start;
    padding-top: 10px;
    padding-left: 0;
  }

  .itemdetail-row .purchase-contents .subtotal .amount {
    white-space: nowrap;
  }

  .itemdetail-row .purchase-contents .quantity .remove-one {
    padding-left: 17px;

    position: absolute;
    right: 10px;
    bottom: 10px;

    @media #{g.$sp} {
      bottom: 20px;
    }
  }

  .remove-one .btn {
    color: g.$text-green-primary;
    font-size: 14px;
    position: relative;

    &::before {
      content: '';background: url(/f/resources/images/common/icon_remove.svg) 50% / contain no-repeat;
      display: block;

      height: 12px;
      width: 11px;

      position: absolute;
      top: 50%;
      left: -15px;
      transform: translateY(-50%);
    }
  }

  .price-total {
    max-width: fit-content;
    padding: 0 16px 0 0;

    @media #{g.$sp} {
      margin-left: 16px;
    }
  }

  .price-total .row {
    display: flex;
    align-items: center;
  }

  .price-total .shipment-total-qty,
  .price-total .label {
    font-size: 16px;
    font-weight: 700;

    @media #{g.$sp} {
      font-size: 14px;
      padding-right: 12px;
      white-space: nowrap;
    }
  }

  .price-total .subtotal {
    margin-left: 40px;

    @media #{g.$sp} {
      margin-left: 0;
    }
  }

  .price-total .subtotal .price {
    @media #{g.$sp} {
      font-size: 16px;
    }
  }

  .present-applied {
    background: g.$bg-gray-primary;
    margin: 20px 16px 16px;
    padding: 18px 22px;

    @media #{g.$sp} {
      padding: 15px;
      margin-top: 10px;
    }
  }

  .present-applied .caption {
    color: g.$text-green-primary;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.428567143;
  }

  .list.present-list {
    padding-top: 8px;
  }

  .item.present {
    background: g.$bg-gray-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.428567143;
    letter-spacing: 0em;
    padding-block: 15px;
    padding-inline: 10px 16px;
  }

  // チェックマーク
  .present-applied .name-and-code {
    padding-left: 43px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
  }

  .present-applied .name-and-code::before,
  .present-applied .name-and-code::after {
    content: "";
    display: inline-block;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .present-applied .name-and-code::before {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-gray;
    border-radius: 4px;
    box-sizing: border-box;
    width: 30px;
    height: 30px;

    left: 0;
  }

  .present-applied .name-and-code::after {
    background: transparent url("/f/resources/images/common/icon_checked.svg") center / contain no-repeat;
    width: 16px;
    height: 11px;

    left: calc((30px / 2) - (16px / 2));
  }

  /*--------------------------------------------------
   出荷関連
  --------------------------------------------------*/
  form#checkout_form {
    // display: flex;
    // justify-content: center;

    .checkout {
      text-align: center;
      padding: 40px 12px 0;

      @media #{g.$sp} {
        padding-top: 30px;
      }
    }
  }

  .btn-moveitem {
    display: flex;
    justify-content: center;
  }

  .move-item-wrapper .note {
    color: g.$text-red-primary;
    font-size: 14px;
  }

  .move-item-dst {
    background: transparent;
    padding: 0;
  }

  .move-item-dst > li {
    background: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
    box-shadow: none;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 28px 0;
    position: relative;
  }

  .move-item-dst > li::before {
    background: g.$bg-white-primary url() 50% / contain no-repeat;
    border: 1px solid g.$border-gray;
    border-radius: 3px;
    content: '';
    display: block;

    height: 28px;
    width: 28px;

    position: absolute;
    top: 52px;
    left: 22px;
    transform: translateY(-50%);
  }

  .move-item-dst li.active::before {
    background-image: url(/f/resources/images/common/icon_checkbox_checkmark.svg);
    background-size: 30px;
    background-position: 50%;
  }

  .move-item-dst > li > .label {
    color: g.$text-black-primary;
    font-size: 20px;
    font-weight: 500;
    padding-inline: 74px 20px;
  }

  .move-item-dst > li > .note {
    color: g.$text-black-primary;
    font-size: 16px;
    padding-inline: 74px 20px;
    margin: 0;
  }

  .new-destination-consignee .addressbook {
    max-width: 100%;
    padding-top: 34px;
    text-align: center;
  }

  .move-item-dst li .form-body {
    border-top: 1px solid g.$border-gray;
    padding: 30px 24px 0;
  }

  .purchase-contents .move-item-wrapper-item .move-item-qty-select {
    background: transparent;
    border: none;

    display: flex;
    align-items: center;
    flex-direction: column;

    > span {
      color: g.$text-red-primary;
    }

    select {
      font-size: 14px;
      font-weight: 400;
      margin-left: 0;
      max-width: 150px;
    }
  }

  #separate-billing-msg > li {
    background-color: g.$bg-gray-primary;
    border: 1px solid g.$border-green;
    color: g.$text-green-primary;
    margin-top: 16px;
    padding: 8px;
    text-align: center;

    @media #{g.$sp} {
      font-size: 14px;
    }
  }

  #separate-billing-msg > li + li {
    display: none;
  }

  /*==========================================
    購入時注意事項
  ==========================================*/
  #checkout-control .order-properties > .formrow {
    align-items: center;
    gap: 10px;
  }

  #checkout-control .order-properties .input-label {
    flex: 0 1 auto;
  }

  #checkout-control .order-properties .input-control {
    flex: 0 1 auto;
    flex-direction: row;
  }

  #checkout-control .order-properties .input-control .input-group {
    flex: 1 1 100%;
  }

  // 注文確定後表示
  .order-entry-processing {
    border: 1px solid g.$border-gray;
    padding: 20px 16px 0;

    display: grid;
    place-items: center;
  }
}
