@use "global" as g;

/*
  ---------------
  パートワーク購読一覧
  ---------------
*/
// ers-dev.css 追加
/* マイページ パートワーク一覧 | pwsubslist */
#account__view_partwork_subscriptions #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-bottom: 90px;
}

#account__view_partwork_subscriptions .subscription-list > .subscription {
  border: 1px solid #ccc;
  margin-top: 40px;
  padding: 20px;
}

#account__view_partwork_subscriptions .subscription .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
}

#account__view_partwork_subscriptions .subscription .buttons > a {
  background-color: #1d1d1b;
  color: #fff;
  display: inline-block;
  min-width: 120px;
  padding: 12px;
  text-align: center;
}

#account__view_partwork_subscriptions .subscription .buttons > a + a {
  margin-left: 12px;
}

#account__view_partwork_subscriptions .subscription .distribution {
  margin-top: 20px;
}

#account__view_partwork_subscriptions .subscription .title {
  font-size: 24px;
}

#account__view_partwork_subscriptions .status {
  font-size: 24px;
  font-weight: bold;
}

#account__view_partwork_subscriptions .status.RUNNING {
  color: #00ac5a;
}

#account__view_partwork_subscriptions .status.PEND_WITH_FAILURE {
  color: red;
}

#account__view_partwork_subscriptions #page_aside2 .btn-account {
  position: static;
  transform: none;
}

#account__view_partwork_subscriptions #page_aside1 .btn-account {
  display: none;
}
// ers-dev.css ここまで

#account__view_partwork_subscriptions .rp-subscriptions {

  #page-title > h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.45;

    @media #{g.$sp} {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.44444444;
    }
  }

  .subscription-list {
    margin-top: 40px;
  }

  .subscription {
    border: 0;
    border-bottom: 1px solid g.$border-gray;
    margin-top: 0;
    padding: 20px;

    @media #{g.$sp} {
      padding: 20px 0 10px;
    }
  }

  .subscription .wrapper {
    position: relative;
    min-height: 160px;

    @media #{g.$sp} {
      min-height: 90px;
    }
  }

  .subscription .subscription-code {
    color: g.$text-black-secondary;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71428571;
    margin-inline: 200px 110px;

    @media #{g.$sp} {
      margin-inline: 0 70px;

      position: absolute;
      top: 0;
      left: 112px;
    }
  }

  .subscription .status {
    border: 1px solid g.$border-black;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.41666667;
    min-width: 90px;
    padding: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    right: 0;

    @media #{g.$sp} {
      min-width: 50px;
    }
  }

  .subscription .status.PEND {
    border: 1px solid #ff9c08;
    color: #ff9c08;
  }
  .subscription .status.RUNNING,
  .subscription .status.WAITING,
  .subscription .status.PEND_WITH_FAILURE {
    border: 1px solid g.$border-green;
    color: g.$text-green-primary;
  }

  .subscription .distribution {
    margin-top: 0;

    @media #{g.$pc} {
      margin-inline: 200px 110px;
    }

    @media #{g.$sp} {
      margin-right: 0;
    }
  }

  .subscription .contents {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    @media #{g.$sp} {
      gap: 10px;
    }
  }

  .subscription .thumbnail {
    width: 100%;
    max-width: 180px;

    @media #{g.$pc} {
      position: absolute;
      top: 0;
      left: 0;
    }

    @media #{g.$sp} {
      flex: 0 0 102px;
    }
  }

  .subscription .thumbnail img {
    aspect-ratio: 180 / 159;
    object-fit: contain;
    width: 100%;
    height: 100%;

    @media #{g.$sp} {
      aspect-ratio: 102 / 90;
    }
  }

  .subscription .text {
    @media #{g.$sp} {
      margin-top: 32px;

      display: flex;
      flex-wrap: wrap;
    }
  }

  .subscription .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .subscription .title.distribution {
    display: none;
  }

  .subscription .subscribed-from {
    color: g.$text-black-secondary;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    margin-top: 10px;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .buttons {
    display: inline-block;
    margin-top: 10px;
  }

  .buttons a {
    background-color: transparent;
    color: g.$text-green-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    min-width: auto;
    padding: 0 26px 0 0;

    position: relative;
  }

  .buttons a::after {
    background: url(/f/resources/images/common/icon_plus_circle_green.svg) center / contain no-repeat;
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
