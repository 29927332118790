@use "global"as g;
// ers-dev.css 追加
/* マイページトップへ戻る 調整 */
#account__view_partwork_subscriptions #page_aside2 .btn-account,
#account__view_partwork_subscription #page_aside2 .btn-account,
#account__edit_partwork_consignee #page_aside2 .btn-account,
#account__edit_partwork_payment #page_aside2 .btn-account,
#account__unsubscribe_partwork #page_aside2 .btn-account,
#account__view_purchased_merchandisecontents #page_aside2 .btn-account,
#account__your_communities #page_aside2 .btn-account {
  margin-top: 80px;
  position: static;
  transform: none;
}

#account__view_partwork_subscriptions #page_aside1 .btn-account,
#account__view_partwork_subscription #page_aside1 .btn-account,
#account__edit_partwork_consignee #page_aside1 .btn-account,
#account__edit_partwork_payment #page_aside1 .btn-account,
#account__unsubscribe_partwork #page_aside1 .btn-account,
#account__your_communities #page_aside1 .btn-account,
#account__view_purchased_merchandisecontents #page_aside1 .btn-account {
  display: none;
}
// ers-dev.css ここまで

/*
  ---------------
  マイページ | 共通
  ---------------
*/
#account__account,
#account__close_account,
#account__edit_account,
#account__edit_address,
#account__edit_account_properties,
#account__edit_profile,
#account__manage_addressbook,
#account__manage_paymentmethod,
#account__view_inquiries,
#account__order,
#account__view_account_message_history,
#account__view_order_history,
#account__view_order_history #signout,
#account__view_orders,
#account__your_coupons,
#entry_password_reminder,
#order__payment_status,
#update_password,
#account__view_points_history,
#account__view_partwork_subscription {
  #outer {
    @include g.container();
  }

  #page_aside1,
  #page_aside2 {
    position: relative;
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
    padding: 0;
  }

  // ログインフォーム
  .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div {
    @media #{g.$pc} {
      padding-inline: 0;
    }
  }
}

/*--------------------------------------------------
注文一覧系
--------------------------------------------------*/
#account__order_inquiry,
#account__close_account,
#account__view_order_history,
#account__view_orders,
#account__order,
#account__view_orders,
#order__payment_status {
  .order-list,
  .order-valid {
    padding-top: 30px;
  }

  .order-list li {
    padding-bottom: 0;
  }

  .order-list li+li {
    padding-top: 50px;
  }

  ul.order-list li>.panel {
    border: 1px solid g.$border-gray;
    padding-bottom: 40px;

    @media #{g.$sp} {
      padding-bottom: 16px;
    }
  }

  .panel-order>.panel-heading,
  .panel-order-smr>.panel-heading {
    background: transparent;
    border-bottom: none;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media #{g.$sp} {
      align-items: flex-start;
    }
  }

  .panel-order .panel-heading>div,
  .panel-order-smr .panel-heading>div {
    flex: none;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .panel-order .panel-heading>div.shipment-status,
  .panel-order .panel-heading>div.shipment-code,
  .panel-order-smr .panel-heading>div.shipment-status,
  .panel-order-smr .panel-heading>div.shipment-code {
    @media #{g.$sp} {
      width: unset;
    }
  }

  .panel-heading .order-code h5 {
    font-size: 16px;
    padding: 0;
  }

  .panel-heading .order-code .code {
    font-size: 20px;
    font-weight: 500;
    padding: 0;
  }

  .panel-heading .order-code h5+div {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .panel-heading .order-code+div a,
  .panel-heading .order-code a {
    background: g.$bg-black-primary;
    border-radius: 2px;
    color: g.$text-white-primary;
    display: block;
    font-size: 14px;
    padding: 4px 12px 4px;
    white-space: nowrap;
  }

  .total-invoice {
    padding-top: 20px;
  }

  // 注文履歴詳細のヘッダー
  .payment-status__tag .status,
  .order-status .status,
  .shipment-status .status,
  .journal-status .status {
    border: 1px solid g.$border-blue;
    border-radius: 2px;
    color: g.$text-blue-primary;
    font-size: 12px;
    padding: 4px 15px;
  }


  .payment-status__tag .status.payment-status-DONE {
    border: 1px solid #005c69;
    color: #005c69;
  }

  .payment-status__tag .status.payment-status-UNCLAIMED {
    border: 1px solid #747474;
    color: #747474;
  }

  .payment-status__tag .status.payment-status-UNPAID {
    border: 1px solid #c10000;
    color: #c10000;
  }

  .cdt-RECEIPT a.btn {
    @include g.hoverButton();
    background: g.$bg-gray-tertiary;
    border: 1px solid #494949;
    border-radius: 2px;
    color: g.$text-white-primary !important;
    padding: 5px 16px;
    font-size: 14px;
  }

  .account .panel-heading+.panel-body,
  .form-close-account .panel-heading+.panel-body {
    margin: 0;
    padding: 26px 20px 0;

    @media #{g.$sp} {
      padding: 20px 10px 0;
    }
  }

  .account .panel-heading+.panel-body .panel-body {
    @media #{g.$sp} {
      padding: 0;
    }
  }

  .panel-heading+.panel-body .shipment-header,
  .panel-heading+.panel-body .panel-heading {
    background: g.$bg-gray-secondary;
    padding: 12px 14px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{g.$sp} {
      // flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 14px;
    }
  }

  .shipment-code {
    line-height: 1;
    display: flex;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .shipment-code span {
    font-size: 20px;
    font-weight: 700;

    display: flex;
    gap: 20px;

    &::before {
      background: url() 50% / contain no-repeat;
      content: '出荷番号';
      display: block;
      font-size: 20px;
      font-weight: 400;
    }
  }

  .shipment-status {
    padding: 0;
  }

  .itemdetails-body .itemdetail-row {
    border-bottom: 1px solid g.$border-gray;
    padding-block: 30px;

    display: flex;
    justify-content: space-between;

    @media #{g.$sp} {
      padding-block: 20px;
      flex-direction: column;
    }
  }

  .itemdetail-row .goods {
    @media #{g.$sp} {
      display: flex;
      justify-content: flex-start;
    }
  }

  .item-thumbnail-img-wrapper {
    @media #{g.$sp} {
      margin: 0;
    }
  }

  .itemdetails-body .itemdetail-row .goods .item-thumbnail-img {
    padding: 0;
  }

  .itemdetail-row .goods-text {
    padding: 0 0 0 26px;

    @media #{g.$sp} {
      padding: 0 0 0 14px;
    }
  }

  .itemdetail-row .goods-text .goods-text-heading a,
  .itemdetail-row .goods-text .item-name {
    font-size: 16px;
    font-weight: 500;

    display: flex;
    flex-direction: column;
  }

  .goods-text-heading .unit-price {
    display: none;
  }


  .purchase-contents {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 34px;

    @media #{g.$sp} {
      padding-left: 164px;

      align-items: flex-start;
      flex-direction: column;
      gap: 4px;
      justify-content: flex-start;
    }
  }

  .quantity,
  .subtotal {
    flex: none;
    padding: 0;
  }

  .quantity {
    font-size: 16px;
    font-weight: 500;
  }

  .subtotal {
    font-size: 16px;
    font-weight: 700;
  }
}

/*---------------
  未発送の注文、注文詳細、アカウントのクローズ
---------------*/
#account__order_inquiry,
#account__close_account,
body#account__order,
body#account__view_orders {
  .view-order .panel-heading {
    flex-direction: column-reverse;
  }

  .order-inquiry .panel-heading > div:not(.order-code):not(.shipment-status):not(.shipment-code),
  .view-order .panel-heading > div:not(.order-code):not(.shipment-status):not(.shipment-code),
  .total-invoice,
  .order-date,
  .order-status {
    padding: 12px 20px 0;

    display: flex;
    justify-content: space-between;
    gap: 40px;
  }

  .panel-heading > div:not(.order-code) h5,
  .total-invoice h5,
  .order-date h5,
  .order-status h5 {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    text-align: left;
    width: 115px;

    @media #{g.$sp} {
      width: fit-content;
    }
  }

  .panel-heading > div:not(.order-code) h5 + div,
  .total-invoice h5 + div,
  .order-date h5 + div,
  .order-status h5 + div {
    font-size: 16px;
    text-align: right;
    width: 150px;

    @media #{g.$sp} {
      width: fit-content;
    }
  }

  .panel-heading .order-code {
    background: g.$bg-gray-primary;
    padding: 22px 24px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    @media #{g.$sp} {
      padding: 16px 14px;
    }
  }
}
/*--------------------------------------------------
注文履歴一覧
--------------------------------------------------*/
body#account__view_orders {
  .order-reset-paymentmethod-form {
    padding-block: 20px;
  }

  .order-reset-paymentmethod-form .description {
    padding-top: 20px;
  }
}
/*--------------------------------------------------
  注文詳細
--------------------------------------------------*/
body#account__order_inquiry,
body#account__order {
  #outer {
    padding-bottom: 0;
  }

  .order-inquiry .panel-heading,
  .view-order .panel-heading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
  }

  .order-inquiry .panel .panel-heading > div:nth-child(1),
  .view-order .panel .panel-heading > div:nth-child(1) {
    order: 4;
  }

  .order-inquiry .panel .panel-heading > div:nth-child(2),
  .view-order .panel .panel-heading > div:nth-child(2) {
    order: 3;
  }

  .order-inquiry .panel .panel-heading > div:nth-child(3),
  .view-order .panel .panel-heading > div:nth-child(3) {
    order: 1;

    @media #{g.$sp} {
      width: fit-content;
    }
  }

  .order-inquiry .panel .panel-heading > div:nth-child(4),
  .view-order .panel .panel-heading > div:nth-child(4) {
    order: 2;
    width: 190px;

    @media #{g.$sp} {
      margin-left: auto;
      width: fit-content;
    }
  }

  .order-inquiry .panel-heading > div:nth-child(4):not(.order-code):not(.shipment-status):not(.shipment-code),
  .view-order .panel-heading > div:nth-child(4):not(.order-code):not(.shipment-status):not(.shipment-code) {
    justify-content: flex-end;
  }


  .order-inquiry .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt+div),
  .view-order .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt+div) {
    width: 100%;

    @media #{g.$pc} {
      display: flex;
      justify-content: flex-end;
    }
  }

  .order-inquiry .panel .panel-heading > div,
  .view-order .panel .panel-heading > div {
    @media #{g.$sp} {
      justify-content: space-between;
    }
  }

  .panel-body .request-paymentmethod-reset > h3 {
    background-color: g.$bg-red-primary;
    border: 1px solid g.$border-red;
    color: g.$border-red;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    text-align: center;

    @media #{g.$sp} {
      font-size: 16px;
    }
  }

  .order-reset-paymentmethod-form > form {
    padding-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .order-reset-paymentmethod-form > form .description {
    padding-top: 20px;
    width: 100%;
  }

  .shipment-header a.link-detail {
    @media #{g.$pc} {
      padding-inline: 16px;
    }
  }

  .derived-subscriptions {
    display: flex;
    justify-content: center;
  }

  .derived-subscriptions + div {
    padding-top: 40px;
  }

  .derived-subscriptions ol {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

    @media #{g.$sp} {
      flex-direction: column;
      align-items: center;
    }
  }

  div.shipment {
    margin-bottom: 0;
  }

  #page_aside2 .btn-account {
    margin-top: 40px;
  }

  .shipment-total-invoice .amount {
    @media #{g.$sp} {
      white-space: normal;
    }
  }

  // ボタン
  .order .order-buttons {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: 16px;
  }

  .order .order-buttons > :first-child {
    margin-inline: auto;

    @media #{g.$sp} {
      padding-inline: 0;
    }
  }

  // クーポン表示
  .applied-coupons ul {
    padding: 28px 8px 24px;
    position: relative;

    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @media #{g.$sp} {
      gap: 8px 16px;
      padding-top: 20px;
    }
  }

  .applied-coupons ul::before {
    content: '適用されたクーポン';
    display: block;
    font-size: 16px;
    font-weight: 500;

    position: absolute;
    top: 0;
    left: 8px;
    transform: translateY(-50%);

    @media #{g.$sp} {
      font-size: 14px;
    }
  }

  .applied-coupons ul li {
    background-color: g.$bg-gray-primary;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: g.$text-green-primary !important;
    overflow: hidden;
    font-size: 13px;
    font-weight: 500;
    padding: 14px 22px;
    position: relative;
    text-align: center;

    min-width: 163px;
    width: fit-content;

    @media all and (max-width : 600px) {
      width: calc(50% - 8px);
    }
  }

  .applied-coupons ul li::before {
    background: g.$bg-red-secondary;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    content: '';
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    padding: 4px 0;
    text-align: center;
    width: 50px;
    z-index: 1;

    position: absolute;
    left: -14px;
    top: 8px;
    transform: rotate(-45deg);
  }

  .applied-coupons ul li::after {
    border: 1px solid g.$text-green-primary;
    border-radius: 2px;
    content: '';
    display: block;
    z-index: 1;

    position: absolute;
    inset: 4px;
  }
}

/*--------------------------------------------------
  注文詳細、お支払いの状態
--------------------------------------------------*/
// マイページ - パートワーク購読詳細(body#account__view_partwork_subscription)
body#account__order_inquiry,
body#account__view_partwork_subscription,
body#order__payment_status,
body#account__order {
  .order-shipments {
    padding-top: 20px;
  }

  .form-reset-paymentmethod {
    padding-top: 10px;
  }

  .shipment-valid {
    border-top: none;
  }

  .form-reset-paymentmethod .description {
    padding-top: 10px;
  }

  .panel-heading + .panel-body .shipment-header {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;

    @media #{g.$sp} {
      gap: 8px;
    }
  }

  .shipment-header > div {
    margin: 0;

    display: flex;
    align-items: center;
    flex-direction: row;
    flex: none;
    gap: 20px;

    @media #{g.$sp} {
      gap: 8px;
      flex-wrap: wrap;
      flex: none;
    }
  }

  .shipment-header > .payments {
    @media #{g.$sp} {
      width: 100%;

      flex: auto;
      justify-content: space-between;
    }
  }

  .shipment-header h5 {
    font-size: 16px;
    padding: 0;
  }

  .shipment-code {
    margin: 0;
    gap: 20px;
    justify-content: flex-start;
  }

  .shipment-code span::before {
    display: none;
  }

  .shipment-header .shipment-status {
    margin-left: auto;
  }

  .shipment-status h5 {
    display: none;
  }

  .shipment-header .link-detail {
    background: g.$bg-black-primary;
    border-radius: 2px;
    color: g.$text-white-primary;
    font-size: 14px;
    margin-left: 10px;
    padding: 2px 6px 3px;
  }

  .panel .wrapper,
  .shipment-delivery-address {
    padding-block: 30px 0;
    display: flex;
    gap: 30px;

    @media all and (max-width : 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .panel .wrapper > div,
  .shipment-delivery-address .person {
    flex: auto;
    margin: 0;
    width: calc((100% - 30px) / 2);

    @media all and (max-width : 900px) {
      width: 100%;
    }

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .order-buttons-shipment {
    display: flex;
  }

  .order-buttons > .shipment-cancel  {
    margin-inline: auto;
  }

  .order-buttons > .shipment-cancel form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // パートワーク購読詳細
  .panel .wrapper > div {
    border: 1px dotted #ccc;
  }

  .panel .wrapper h4 {
    font-weight: 700;
    border-bottom: 1px dotted #ccc;
    background-color: #f5f5f5;
    padding: 0.5em;
  }

  .panel .wrapper > div > div {
    padding: .5em;
  }

  .panel .panel-footer .note {
    color: g.$text-red-primary;
    font-size: 14px;
  }

  .panel .btn-group {
    margin-top: 10px;
    padding: 0;
  }

  .shipment-contents {
    margin-top: 30px;
  }

  .delivery {
    flex-direction: column;
    gap: 30px;
  }

  .shipment-contents .delivery > .delivery-request,
  .shipment-contents .delivery > .delivery-info {
    border: 1px solid g.$border-gray;
    margin: 0;
    padding: 0;

    @media all and (max-width : 900px) {
      width: 100%;
    }
  }

  .shipment-contents .delivery table {
    width: 100%;
  }

  .panel caption {
    background: g.$bg-gray-primary;
    padding: 13px 10px 14px 20px;
    text-align: left;
  }

  .delivery-info table {
    padding-bottom: 20px;
  }

  .shipment-contents .delivery table tbody {
    padding: 16px 20px;

    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .shipment-contents .delivery table tr {
    display: flex;
    align-items: center;
  }

  .shipment-contents .delivery table th,
  .shipment-contents .delivery table td {
    border: none;
    font-size: 16px;
    padding: 0;
  }

  .shipment-contents .delivery table th {
    background: transparent;
    font-weight: 500;
    min-width: 135px;
    max-width: 150px;
    width: 100%;
  }

  .order-items h4,
  .order-items .itemdetails-header {
    display: none;
  }

  .itemdetail-row .item {
    flex: auto;
  }

  .itemdetails-body .itemdetail-row .purchase-contents {
    align-items: flex-end;
  }

  .itemdetail-row .item .goods {
    @media #{g.$sp} {
      flex-direction: row;
    }
  }

  .itemdetails-body .itemdetail-row .purchase-contents {
    @media #{g.$sp} {
      align-items: flex-start;
    }
  }

  // 金額テーブル
  .shipment-total-invoice,
  #order-total {
    max-width: 500px;

    @media #{g.$sp} {
      max-width: 100%;
    }

    td {
      min-width: 140px;

      @media #{g.$sp} {
        min-width: fit-content;
      }
    }

    td a {
      @media #{g.$sp} {
        white-space: nowrap;
      }
    }

    th,
    td {
      font-size: 14px;
      border: none;
    }

    tr {
      border-bottom: 1px solid g.$border-gray;

      @media #{g.$sp} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
      }
    }

    tr.service-extra th {
      @media #{g.$sp} {
        padding-block: 7px;
        display: flex;
        flex-direction: column;
      }
    }

    .total {
      border-bottom: 2px solid g.$border-gray;
    }

    .total th,
    .total td {
      color: g.$text-black-primary;
      font-size: 24px;
      font-weight: 700;
    }

    .amount {
      @media #{g.$sp} {
        white-space: nowrap;
      }
    }
  }

  .amount-table {
    @media #{g.$sp} {
      padding: 8px 0;
    }
  }

  .ln-order-inq {
    display: flex;
    justify-content: center;
  }

  .total-item-amount .qty {
    display: none;
  }
}

/*---------------
  仮パスワードの発行
---------------*/
#entry_password_reminder {
  .submit-note {
    margin-top: 20px;
  }

  // 完了画面
  #reminder-done {
    padding-top: 100px;
    display: grid;
    place-items: center;
  }

  #reminder-done .msg-head {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  #reminder-done .msg-body {
    margin-top: 40px;
    text-align: center;
  }

  #reminder-done .btn-submit {
    padding-block: 80px 0;
  }

  #reminder-done .btn-submit a {
    display: grid;
    place-items: center;
  }
}

ol.issues span.price.with-special span.special-price {
  color: g.$text-red-primary;
}
