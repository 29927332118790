@use "global" as g;

/*
  ---------------
  決済方法選択
  ---------------
*/

body#order__payment {
  .form-payment {
    @media #{g.$sp} {
      padding-inline: 12px;
    }
  }

  #select-payment-method {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    margin-top: 40px;
  }

  h2 {
    background: g.$bg-gray-primary;
    font-size: 16px;
    font-weight: 400;
    padding: 14px 18px;
  }

  .payment-method {
    position: relative;
  }

  div.payment-method.toggle .pm-body,
  div.payment-method.toggle .pm-trailing {
    display: none;
  }

  div.payment-method.toggle::after {
    background: url(/f/resources/images/common/icon_plus_circle_green.svg) 50% / contain no-repeat;
    content: '';
    display: block;

    height: 18px;
    width: 18px;
    z-index: -1;

    position: absolute;
    top: 23px;
    left: 9px;
  }

  div.payment-method.toggle:has(.is-open)::after {
    content: '';
    background: url(/f/resources/images/common/icon_minus_circle_green.svg) 50% / contain no-repeat;
    z-index: -1;
  }

  .payment-method-list .payment-method .pm-wrapper .pm-label {
    padding-left: 25px;
    text-align: left;
  }

  // クレジットカードのみ
  .payment-method.CARD,
  .payment-method.CARD2 {
    .pm-heading {
      cursor: pointer;
    }

    .pm-heading::after {
      content: 'おすすめ！';
      color: g.$text-red-primary;
      display: block;
      font-size: 14px;
      margin-left: 25px;

      @media #{g.$sp} {
        text-align: left;
      }
    }

    .pm-label {
      font-size: 18px;
    }
  }

  .amount-table .msg-paymentfee-excluded {
    font-size: 15px;
  }

  .amount-table .service-payment_commission .service-label {
    display: none;
  }
}