@use "global" as g;

/*
  ---------------
  TOP | おすすめの特集
  ---------------
*/
.select-top .p-edition {
  margin-top: 50px;

  @media #{g.$sp} {
    margin-top: 40px;
    overflow: hidden;
  }

  &__inner {}

  &__header {
    @include g.container();

    h2 {
      @include g.h2TitleBgBorder();
    }
  }

  &__bnr {
    @include g.container();
    margin-top: 20px;

    @media #{g.$sp} {
      margin-top: 10px;
    }
  }

  &__bnr .item-tags {
    position: static;
    justify-content: flex-end;
  }

  &__bnr .item-tags div.item-tag {
    @media #{g.$sp} {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }

  &__bnr-link {
    display: block;
    margin-top: 20px;

    @media #{g.$sp} {
      margin-top: 10px;
    }
  }

  .l-slider {
    @include g.container();
    margin-top: 40px;
  }

  .l-slider .item-tags {
    top: -30px;
    right: -10px;

    @media #{g.$sp} {
      top: -26px;
    }
  }

  .l-slider__slide {}
  .l-slider__slide-link {}

  .l-slider__slide-img {
    aspect-ratio: 1 / 1;
    border: 1px solid g.$border-gray;
    overflow: hidden;
    position: relative;
  }

  .l-slider__slide-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .l-slider__slide-title {
    color: g.$text-green-primary;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4375;
    margin-top: 20px;

    @media #{g.$sp} {
      font-size: 14px;
      line-height: 1.42857143;
      margin-top: 10px;
    }
  }

  .l-slider__slide-description {
    font-size: 14px;
    line-height: 1.42857143;
    margin-top: 6px;

    @media #{g.$sp} {
      font-size: 13px;
      line-height: 1.46153846;
    }
  }

  &__btn {
    max-width: 250px;
    margin: 50px auto 0;

    @media #{g.$sp} {
      margin-top: 40px;
    }
  }

  &__btn-link {
    background-color: g.$bg-green-quaternary;
    border-radius: 30px;
    color: g.$text-white-primary;
    display: inline-block;
    width: 100%;
    min-height: 50px;
    padding-inline: 19px;

    display: flex;
    align-items: center;

    span {
      display: block;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.46666667;
      text-align: center;
      width: 100%;
      padding-right: 35px;

      position: relative;

      @media #{g.$sp} {
        font-size: 16px;
        line-height: 1.4375;
      }
    }

    span::after {
      content: "";
      background: transparent url("/f/resources/images/select/common/icon_arrow.svg") center / contain no-repeat;
      display: inline-block;
      width: 24px;
      height: 24px;

      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  // slick
  .l-slider:not(.slick-initialized) {
    display: none;
  }
  
  .slick-list {
    margin-inline: -15px;
    overflow: visible;

    @media #{g.$sp} {
      margin-inline: -5px;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    margin-inline: 15px;

    @media #{g.$sp} {
      margin-inline: 5px;
    }
  }

  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;

    @media #{g.$sp} {
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-next:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    @media #{g.$pc} {
      opacity: 0;
    }
  }
}
