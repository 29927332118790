@use "global" as g;

/*
  ---------------
  TOP | お楽しみいただけそうな商品
  ---------------
*/
.select-top .p-pleasure {
  margin-top: 50px;

  @media #{g.$sp} {
    margin-top: 40px;
    overflow: hidden;
  }
}

.select-top .p-pleasure__header {
  @include g.container();
}

.select-top .p-pleasure h2 {
  @include g.h2TitleBgBorder();
}

.select-top .p-pleasure .l-slider {
  @include g.container();
  margin-top: 40px;

  @media #{g.$sp} {
    margin-top: 28px;
  }
}

.select-top .p-pleasure .l-slider .item-tags {
  top: 4px;
  right: 0;
}

.select-top .p-pleasure .l-slider__slide-link {
  aspect-ratio: 270 / 98;
  border: 1px solid g.$border-gray;
  display: block;
  overflow: visible;
  position: relative;
}

.select-top .p-pleasure .l-slider__slide-link img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// slick
.select-top .p-pleasure .l-slider:not(.slick-initialized) {
  display: none;
}

.select-top .p-pleasure .slick-list {
  margin-inline: -10px;
  overflow: hidden;
}

.select-top .p-pleasure .slick-track {
  display: flex;
}

.select-top .p-pleasure .slick-slide {
  margin-inline: 10px;
}

.select-top .p-pleasure .slick-slide > div {
  font-size: 0;

  display: flex;
  gap: 20px;

  @media #{g.$sp} {
    gap: 16px;
  }

}

.select-top .p-pleasure .slick-slide > div + div {
  margin-top: 20px;

  @media #{g.$sp} {
    margin-top: 16px;
  }
}

.select-top .p-pleasure .slick-slide > div > li > a {
  display: inline-block;
  width: 100%;
}

.select-top .p-pleasure .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}

.select-top .p-pleasure .slick-prev,
.select-top .p-pleasure .slick-next {
  width: 50px;
  height: 50px;

  @media #{g.$sp} {
    width: 30px;
    height: 60px;
  }
}

.select-top .p-pleasure .slick-prev {
  left: 0;
}

.select-top .p-pleasure .slick-next {
  right: 0;
}

.select-top .p-pleasure .slick-prev:before {
  content: "";
  display: inline-block;

  @media #{g.$pc} {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
    width: 50px;
    height: 50px;
  }

  @media #{g.$sp} {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

.select-top .p-pleasure .slick-next:before {
  content: "";
  display: inline-block;

  @media #{g.$pc} {
    background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
    width: 50px;
    height: 50px;
  }

  @media #{g.$sp} {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
