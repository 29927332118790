@use "global" as g;

/*
  ---------------
  シリーズトップ | 刊行一覧
  ---------------
*/
#itemlist ul.item-list {
  @media #{g.$sp} {
    padding: 0;
  }
}

#itemlist ul.item-list li.item-list-each,
#item .item-productclass-flowpack #item-contents-main {
  flex-wrap: nowrap;

  @media #{g.$sp} {
    padding: 20px 0 95px;
    position: relative;

    gap: 18px;
  }

  @media #{g.$pc} {
    padding-block: 30px;
    gap: min(3.33333333vw, 48px);
  }
}

#itemlist ul.item-list li.item-list-each:last-child {
  border-bottom: 1px dotted #ccc;

  @media #{g.$sp} {
    padding-bottom: 95px;
  }

  @media #{g.$pc} {
    padding-bottom: 30px;
  }
}


// 画像エリア
#itemlist .item-list .item-list-each .item-thumbnail-img-wrapper {}

#itemlist #container > div > ul > li .item-thumbnail-img-wrapper > div > a {
  display: inline-block;
  width: 150px;
  height: 150px;
  overflow: hidden;

  @media #{g.$pc} {
    width: 250px;
    height: 250px;
  }
}

// 商品画像
#container > div > ul > li > div.item-thumbnail-img-wrapper > div > a > img {
  border-radius: 0;
  object-fit: contain;
  height: 100%;
}

#itemlist div.item-thumbnail-img div.no-img {
  width: 100%;
}


// 商品詳細エリア
#itemlist ul.item-list li.item-list-each .item-dtl,
#item .item-productclass-flowpack .item-dtl {
  margin: 0;

  @media #{g.$pc} {
    position: relative;
  }
}

// 商品タイトル
#itemlist ul.item-list li.item-list-each .item-name,
#item .item-productclass-flowpack .item-name {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}

// 商品説明
#itemlist ul.item-list li.item-list-each .product-desc,
#item .item-productclass-flowpack .product-desc {
  display: none;
  // margin-top: 14px;

  // @media #{g.$sp} {
  //   font-size: 14px;
  //   font-weight: 500;
  //   line-height: 1.42857143;
  //   margin-top: 12px;
  // }
}

// アイテムタグ - new, 放映中など

// タイトル下のタグ
#itemlist ul.item-list li.item-list-each .product-dtl > .item-tags {
  display: inline-block;
  vertical-align: 19px;

  @media #{g.$sp} {
    vertical-align: 0;
  }
}

// 商品説明下のタグ
#itemlist ul.item-list li.item-list-each div.item-purchase > div.item-tags {
  display: none;
}

#itemlist ul.item-list li.item-list-each .item-dtl > div.item-tags div.item-tag {
  border-radius: 0;
  display: inline-block;
  font-size: 12px;
  padding: 0 4px 2px;
  margin: 0 0 4px 8px;

  @media #{g.$sp} {
    margin: 4px 4px 0 0;
  }
}

// 販売価格
#itemlist .item-list .offering-price-info,
#item .item-productclass-flowpack .offering-price-info {
  margin-top: 3px;
}

#itemlist .item-list .individual-delivery-fee {
  font-size: 14px;
}

#itemlist ul.item-list li.item-list-each .final-price,
#item .item-productclass-flowpack .final-price {
  text-align: left;
}

#itemlist ul.item-list li.item-list-each .final-price span.price,
#item .item-productclass-flowpack .final-price span.price {
  color: g.$text-black-primary;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.45;

  @media #{g.$pc} {
    font-size: 35px;
    line-height: 1.45714286;
  }
}

#itemlist ul.item-list li.item-list-each .final-price span.price > span,
#item .item-productclass-flowpack .final-price span.price > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.41666667;

  @media #{g.$pc} {
    font-size: 16px;
    line-height: 1.4375;
  }
}

#itemlist ul.item-list li.item-list-each .item-code {
  display: block;
}

// formタグ 数量~今すぐ購入
#itemlist ul.item-list li.item-list-each form.item-purchase,
#item .item-productclass-flowpack form.item-purchase {
  font-size: 0;
  padding: 0;
  margin: 0;
  width: 100%;

  @media #{g.$sp} {
    margin-top: 6px;
  }

  @media #{g.$pc} {
    margin-top: auto;

    position: absolute;
    bottom: 0;
  }
}

// 数量
#itemlist ul.item-list li.item-list-each .item-qty.item-qty-item,
#item .item-productclass-flowpack .item-qty.item-qty-item {
  display: inline-flex;
  align-items: center;

  @media all and (max-width: 914px) and (min-width: 768px) {
    margin-bottom: 0.69444444vw;
  }
}

#itemlist ul.item-list li.item-list-each .item-qty-label,
#item .item-productclass-flowpack .item-qty-label {
  font-size: 14px;
  line-height: 1.42857143;
  margin-inline-end: 15px;
}
// 数量セレクトボックス
#itemlist ul.item-list li.item-list-each select.item-qty,
#item .item-productclass-flowpack select.item-qty {
  appearance: none;
  background: #F6F6F6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  border: 0;
  border-radius: 2px;
  color: #1D1D1B;
  cursor: pointer;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  line-height: normal;
  padding-inline: 10px 29px;

  display: inline-flex;
  align-items: center;

  @media #{g.$sp} {
    font-size: 16px;
    height: 35px;
    padding-inline-end: 13px;
    width: 84px;
  }

  @media #{g.$pc} {
    width: calc(50% - (clamp(0px, 0.69444444vw, 20px) / 2));
  }
}

#itemlist ul.item-list li.item-list-each select.item-qty {
  @media all and (max-width : 1119px) {
    width: 84px;
  }
}

// カートに入れる 今すぐ購入 wrapper
#itemlist form.item-purchase div.btn-group-purchase,
#item .item-productclass-flowpack form.item-purchase div.btn-group-purchase {
  font-size: 0;
  display: inline-flex;

  @media #{g.$sp} {
    width: 100%;

    justify-content: space-between;
    gap: 2.54452926vw;

    position: absolute;
    left: 0;
    bottom: 20px;
  }

  @media #{g.$pc} {
    gap: clamp(0px, 0.69444444vw, 20px);
    margin-left: 1.38888889vw;
  }
}

#itemlist ul.item-list li.item-list-each .add-item-to-cart,
#itemlist ul.item-list li.item-list-each .checkout-item,
#item .item-productclass-flowpack .add-item-to-cart,
#item .item-productclass-flowpack .checkout-item {
  @media #{g.$sp} {
    width: 50%;
  }
}

// カートに入れる
#itemlist ul.item-list li.item-list-each .add-item-to-cart,
#itemlist ul.item-list li.item-list-each .checkout-item,
#item .item-productclass-flowpack .add-item-to-cart,
#item .item-productclass-flowpack .checkout-item {
  margin-left: 0;
  border: 0;
  border-radius: 2px;
  color: g.$text-white-primary !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.33333333; // lh22px
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#itemlist ul.item-list li.item-list-each .add-item-to-cart,
#item .item-productclass-flowpack .add-item-to-cart {
  background: #00828c;
  min-width: 178px;
  height: 50px;
  padding: 0 19px 0 52px;


  @media #{g.$sp} {
    min-width: auto;
    padding: 0 14px 0 40px;
  }

  &::before {
    content: "";
    background: url("/f/resources/images/packtoy/list/icon_cart.svg") center / contain no-repeat;
    display: inline-block;
    width: 17px;
    height: 20px;

    position: absolute;
    top: 50%;
    right: auto;
    left: 21px;
    transform: translateY(-50%);

    @media #{g.$sp} {
      left: 17px;
    }
  }

  &::after {
    @media #{g.$sp} {
      min-width: 100%;
    }
  }
}

// 今すぐ購入
#itemlist ul.item-list li.item-list-each .checkout-item,
#item .item-productclass-flowpack .checkout-item {
  background: #ff9c08;
  min-width: 178px;
  height: 50px;
  padding: 0 52px 0 19px;

  @media #{g.$sp} {
    min-width: auto;
    padding: 0 47px 0 19px;
  }

  &::before {
    content: "";
    border-radius: 100%;
    background: url("/f/resources/images/common/icon_arrow_right_bg_white.svg") center / contain no-repeat;
    display: inline-block;
    height: 24px;
    margin-left: 20px;
    width: 24px;

    position: absolute;
    top: 50%;
    right: 14px;
    left: auto;
    transform: translateY(-50%);
  }
}

#itemlist ul.item-list li.item-list-each form button {
  @include g.hoverLink;
}

#itemlist ul.item-list li.item-list-each .screen-message,
#item .item-productclass-flowpack .screen-message {
  display: none;
}

#itemlist ul.item-list li.item-list-each .articles,
#itemlist ul.item-list li.item-list-each .purchase,
#itemlist ul.item-list li.item-list-each .ac,
#item .item-productclass-flowpack .articles,
#item .item-productclass-flowpack .purchase,
#item .item-productclass-flowpack .ac {
  // height: 100%;
}

#itemlist ul.item-list li.item-list-each .article,
#itemlist ul.item-list li.item-list-each .available,
#item .item-productclass-flowpack .item-purchase-list,
#item .item-productclass-flowpack .available {
  @media #{g.$pc} {
    // height: 100%;

    // display: flex;
    // flex-direction: column;
  }
}

#container > div > ul > li > div.item-dtl.product-dtl > div.articles > div > div.purchase > div > div.item-purchase.available.rpa-unavailable {
  margin-bottom: 0;
}

#itemlist ul.item-list li.item-list-each select.item-qty,
#itemlist ul.item-list li.item-list-each .add-item-to-cart,
#itemlist ul.item-list li.item-list-each .checkout-item {
  border-radius: 0;
}

#itemlist .pagination ul li a {
  border-radius: 0;
}

#itemlist .pagination ul li.active a {
  background-color: transparent;
  color: g.$text-green-primary;
}

#item .p-product__inner.l-section--inner,
.flowpack.select-product .p-product__inner {
  @media #{g.$sp} {
    padding-inline: 0;
  }
}
