@use "global" as g;

/*
  ---------------
  series common
  ---------------
*/
.p-series {

  &__wrap {
    margin-top: 50px;
    display: flex;
    gap: min(6.11111111vw, 88px);

    @media all and (max-width: 1186px) {
      gap: min(3.50877193vw, 40px);
    }

    @media #{g.$sp} {
      display: block;
      margin-top: 24px;
    }
  }

  &__content-wrap {
    width: 100%;

    @media #{g.$sp} {
      margin-top: 25px;
    }
  }

  &__content {
    // シリーズ毎filter用
    display: none;

    & + & {
      margin-top: 50px;
    }
  }

  // シリーズ毎filter用
  &__content.is-show {
    display: grid;
  }

  &__content-description {
    margin-top: 3px;
  }

  &__list {
    margin-top: 20px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: min(2.01388889vw, 29px);

    @media all and (max-width: 1150px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media all and (max-width: 1096px) {
    }
  }

  &__item {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 3;
    gap: 10px;
    margin-bottom: 40px;

    @media #{g.$tab-under} {
      gap: 8px;
    }
  }

  // 商品毎filter用
  &__item {
    display: none;
  }
  &__item.is-show {
    display: grid;
  }

  // カードレイアウト
  &__card {
    height: 100%;
  }

  &__card-link {
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  &__card-img {
    text-align: center;
    padding-top: 88.1294%;
    overflow: hidden;
    position: relative;

    @media #{g.$sp} {
      padding-top: 88.0681%;
    }

    &::after {
      content: "";
      background: transparent url("/f/resources/images/partwork/icon_link.svg") center / contain no-repeat;
      display: inline-block;
      width: 22px;
      height: 22px;

      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__card-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__card-genre {
    color: g.$text-gray-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143; // 20px
    margin-top: 6px;

    @media #{g.$pc} {
      font-size: 11px;
      line-height: 1.45454545; // 16px
    }
  }

  &__card-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857143;
    margin-top: 10px;
  }

  &__card-text {
    margin-top: 7px;

    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  // --------------------
  // ボタングループ
  // --------------------
  // 共通
  &__btn-group-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    @media #{g.$tab-under} {
      gap: min(2.03562341vw, 8px);
    }
  }

  &__btn-group-item {
    width: calc(50% - 5px);

    // 要素数1
    &:first-child:last-child {
      width: 100%;
    }

    // 要素数3の最後の要素
    &:nth-child(3):last-child {
      width: 100%;
    }
  }

  // バックナンバー, 定期購読
  &__btn-group--upper & {

    &__btn-group {
      margin-top: 4px;

      @media #{g.$tab-under} {
        margin-top: 2px;
      }
    }
  }

  // バインダーケース ~ パーツ
  &__btn-group--lower & {

    &__btn-group-list {

      @media #{g.$sp} {
        grid-template-columns: repeat(auto-fit, minmax(96px, 1fr));
      }
    }

    &__btn-group-item-link {
      padding: 0 3px;
    }

    &__btn-group-item-text {
      color: g.$text-green-primary;
      font-size: 11px;
      font-weight: 400;
      line-height: 1.45454545;
      text-align: center;
    }
  }
}
