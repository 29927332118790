@use "global" as g;

/*
  ---------------
  TOP | サイトバナー スライダー
  ---------------
*/
.select-top .p-fv {

  &__slider {
    @media #{g.$pc} {
      max-width: 1280px;
      margin-inline: auto;
      padding-inline: clamp(0px, 2.77777778vw, 40px);
    }
  }

  &__slider:not(.slick-initialized) .p-fv__slide + .p-fv__slide {
    display: none;
  }

  &__slide-link {
    display: block;

    img {
      width: 100%;
      // height: 35.9027778vw;
      // max-height: 520px;

      @media #{g.$sp} {
        // height: 106.666667vw;
      }
    }
  }

  // slick
  .slick-slider {
    margin-bottom: 0;
    padding-bottom: 31px;

    @media #{g.$pc} {
      padding-bottom: 33px;
    }
  }

  .slick-slide {
    position: relative;
  }

  .slick-slide:not(.slick-active)::before {
    content: "";
    display: block;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-dots {
    font-size: 0;
    bottom: 0;
  }

  .slick-dots li {
    width: auto;
    height: auto;
    padding: 5px 0;
    margin: 0 4px;
  }

  .slick-dots li button {
    background-color: g.$bg-gray-secondary;
    width: 40px;
    height: 4px;
    padding: 0;

    @media #{g.$pc} {
      height: 5px;
    }
  }

  .slick-dots li button:before {
    display: none;
  }

  .slick-dots li.slick-active button {
    background-color: g.$bg-green-quaternary;
  }

  .slick-arrow {
    top: calc(50% - 16px);
    transform: translateY(-50%);
  }

  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;
    z-index: 2;

    @media #{g.$sp} {
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev {
    left: 11px;

    @media #{g.$sp} {
      left: 0;
    }
  }

  .slick-next {
    right: 11px;

    @media #{g.$sp} {
      right: 0;
    }
  }

  .slick-prev:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-next:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }
}
