@use "global" as g;

/*
  ---------------
  パックトイ | 商品一覧
  ---------------
*/
body.itemlist.is-flowpack,
body.is-flowpack {
  .txc-header {
    display: none!important;
  }

  .txc-body {
      margin: 0 calc(50% - 50vw) 40px;
      width: 100vw;

    @media #{g.$sp} {
      margin-bottom: 20px;
    }

    img {
      width: 100%;
    }
  }

  .slider-logo {
    background-color: #f6f6f6;
    padding: 20px 0;

    @media #{g.$pc} {
      margin: 0 calc(50% - 50vw);
      width: 100vw;
    }

    @media #{g.$sp} {
      padding: 10px 0;
    }

    img {
      height: calc(100vw * 0.083333333);
      // width: calc(100vw * 0.208333333);

      @media #{g.$sp} {
        height: calc(100vw * 0.219053165);
        // width: calc(100vw * 0.208333333);
      }
    }

    .slick-prev,
    .slick-next {
      height: 70px;
      width: 70px;

      top: 50%;
      left: 30px;
      transform: translateY(-50%);

      @media #{g.$sp} {
        height: 50px;
        width: 50px;

        left: 1px;
      }

      &::before {
        background: transparent url(/f/resources/images/packtoy/top/icon_arrow_left.svg) center/contain no-repeat;
        content: "";
        display: inline-block;
        height: inherit;
        width: inherit;
      }
    }

    .slick-next {
      right: 30px;
      left: auto;

      @media #{g.$sp} {
        right: 1px;
      }

      &::before {
        background: transparent url(/f/resources/images/packtoy/top/icon_arrow_right.svg) center/contain no-repeat;
      }
    }
  }

  div.item-thumbnail-img img {
    border-radius: 20px;
    object-fit: cover;
  }

  div.item-thumbnail-img div.no-img {
    border-radius: 20px;
  }

  .article .item-tags {
    display: none;
  }

  .text-contents {
    margin-top: 8px;
  }

  .text-contents a {
    font-size: 20px;
    color: g.$text-green-secondary;
    font-weight: 700;
  }

  select.item-qty {
    border-radius: 30px!important;
  }

  .add-item-to-cart {
    background: #00ada0!important;
    border-radius: 30px!important;

    &::after {
      content: "";
      background-color: #ffd759;
      border-radius: 30px;
      display: inline-block;
      height: 50px;
      min-width: 178px;

      position: absolute;
      right: 0;
      bottom: -5px;
      z-index: -1;
    }
  }

  .checkout-item {
    border-radius: 30px!important;

    &::after {
      content: ""!important;
      background-color: #ffd759;
      border-radius: 30px;
      display: inline-block;
      height: 100%;
      width: 100%;

      position: absolute;
      right: 0;
      bottom: -5px;
      z-index: -1;
    }
  }

  .bnr_sold-out {
    display: block;
    margin-top: 77px;
    text-align: center;

    @media #{g.$sp} {
      margin: 40px 10px 0;
    }
  }
}

body.is-flowpack {
  .breadcrumbs {
    display: none !important;
  }
}

body#itemlist.is-flowpack {
  .breadcrumbs {
    display: block !important;
    margin-bottom: 0;
  }
}
