@use "global" as g;

/*
  ---------------
  仮パスワードの発行
  ---------------
*/
body#init_password {
  #outer {
    @include g.container();
  }

  h1 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.45; // 29px
    padding: 0;

    @media #{g.$pc} {
      font-size: 24px;
      line-height: 1.45833333; // 35px
    }
  }

  .description {
    margin-top: 20px;
  }

  #reminder-entry-form {
    background-color: g.$bg-gray-primary;
    padding: 40px;
    margin-top: 40px;

    @media #{g.$sp} {
      padding: 20px;
    }
  }

  .submit-note {
    margin-top: 20px;
  }
}