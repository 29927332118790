@use "global"as g;

/*
  ---------------
  汎用ニューステンプレート サンプル用
  ---------------
*/
// ers-dev.css 追加
.news-archive-deago .news-entry-icon {
  display: none;
}

.news-archive-deago .news-entry-subject {
  background-color: #333;
  color: #fff;
  padding: 8px;
  margin: 20px 0;
}

.news-archive-deago .news-entry {
  background-color: #efefef;
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px;
  width: 100%;
}

.news-archive-deago h1 {
  font-size: 32px;
  font-weight: bold;
  margin-top: 20px;
}

.news-archive-deago h2 {
  font-size: 32px;
  margin-top: 20px;
}

.news-archive-deago h3 {
  border-bottom: 2px solid #555;
  font-size: 26px;
  font-weight: bold;
  margin-top: 16px;
}

.news-archive-deago h4 {
  font-weight: bold;
}

.news-archive-deago p {
  margin-top: 12px;
}

.news-archive-deago ul,
.news-archive-deago ol {
  padding-left: 20px;
}

.news-archive-deago ul li {
  list-style-type: circle;
}

.news-archive-deago ol li {
  list-style-type: decimal;
}

.news-archive-deago a {
  color: #005c69;
  text-decoration: underline;
}

.news-archive-deago blockquote {
  border: 1px solid #555;
  padding: 40px;
}

.news-archive-deago a.button {
  border-radius: 8px;
  background-color: #005c69;
  color: #fff;
  cursor: pointer;
  display: block;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  margin: 20px auto 0;
  max-width: 200px;
  width: 100%;
}

.news-archive-deago table {
  width: 100%;
}

.news-archive-deago table td {
  border: 1px solid #555;
  border-collapse: collapse;
}

/* みんなの投稿 */
.newsentries.user-post .news-entries {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1220px;
  width: 100%;
}

.newsentries.user-post .news-entry {
  border-top: none;
  padding: 0;
  text-align: center;
  transition: 0.2s ease-in-out;
  margin-left: 20px;
  margin-top: 20px;
  width: calc((100% - 60px) / 4);
}

.newsentries.user-post .news-entry:nth-child(4n+1) {
  margin-left: 0;
}

.newsentries.user-post .news-entry:hover {
  opacity: 0.6;
}

.newsentries.user-post .entry-text {
  display: none;
}

.newsentries.user-post .entry-icon {
  margin: 0 auto;
}

.newsentries.user-post .entry-icon span.icon img {
  width: 100%;
}

#news .news-archive-user-post #page-wrapper {
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
}

#news .news-archive-user-post .news-entry {
  min-height: 400px;
  margin-top: 40px;
  padding-left: 40%;
  position: relative;
}

#news .news-archive-user-post .news-entry-icon {
  position: absolute;
  top: 0;
  left: 0;
}

#news .news-archive-user-post .news-entry-header {
  width: 100%;
}

#news .news-archive-user-post .news-entry-body {
  margin-top: 20px;
}

#news .news-archive-user-post .post-content {
  border: 1px solid #333;
  padding: 10px;
}

#news .news-archive-user-post .post-info {
  margin-top: 20px;
}

#news .news-archive-user-post .autor-link,
#news .news-archive-user-post .post-link {
  text-decoration: underline;
}
// ers-dev.css ここまで

/*--------------------------------------------------
ニュース一覧
--------------------------------------------------*/
.newsentries:not(.faq):not(.archive) {
  background-color: g.$bg-gray-primary;

  .l-footer {
    margin-top: 200px;

    @media #{g.$sp} {
      margin-top: 100px;
    }
  }

  .l-footer__main {
    border-top: 1px solid g.$border-gray;
  }
}

.newsentries:not(.faq):not(.archive) .news-archive {

  #page-title .breadcrumbs {
    margin-bottom: 0;
  }

  #page-title {
    background-color: g.$bg-white-primary;
    padding-inline: g.$padding-container-inline-pc;
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
    padding: 26px 0;

     @media #{g.$pc} {
      margin: 0 auto;
      max-width: 1200px;
    }

    @media #{g.$sp} {
      padding-block: 22px;
    }
  }

  #page-wrapper {
    @include g.container();
  }

  // その他のニュースを見る 一覧ページでは非表示
  .show-othernews {
    display: none;
  }

  .news-header {
    padding-top: 40px;

    display: grid;
    place-items: center;
  }

  .news-icon {
    max-width: 200px;

    @media #{g.$sp} {
      max-width: 150px;
    }
  }

  .news-entries {
    background-color: g.$bg-white-primary;
    margin-block: 30px 50px;

    @media #{g.$sp} {
      margin-block: 12px 30px;
    }
  }

  .news-entry {
    @include g.hoverButton();
    border: none;
    padding: 20px 30px;
    position: relative;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 20px;

    @media #{g.$sp} {
      padding: 12px 16px;
    }
  }

  .news-entry:not(:has(.entry-icon)) .entry-text {
    width: 100%;
  }

  .news-entry:not(:first-child) {
    border-top: 1px solid g.$border-gray;
  }

  .entry-text {
    margin-inline: 0 auto;
    padding: 0;
    width: calc(100% - 110px);
    word-break: break-all;
  }

  .header {
    display: flex;
    flex-direction: column;
  }

  .meta {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .release-date {
    margin: 0;
  }

  .release-date span {
    color: g.$text-black-secondary;
    font-size: 15px;
    font-weight: 500;
  }

  .news-type span {
    background-color: g.$bg-gray-tertiary;
    border-radius: 2px;
    color: g.$text-white-primary;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    padding: 4px 8px 5px 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .headline a:hover {
    opacity: 1;
  }

  .headline a::before {
    content: '';
    display: block;

    position: absolute;
    inset: 0;
  }

  .headline .text {
    color: g.$text-green-primary;
    font-size: 16px;
    font-weight: 700;
  }

  .body {
    font-size: 12px;
    padding: 10px 0 0;
    line-height: 1.7;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media #{g.$sp} {
      font-size: 14px;
    }
  }

  .news-entry .entry-icon .icon {
    display: inline-block;
  }

  .news-entry .entry-icon:has(.no-image) {
    display: none;
  }

  .news-entry .entry-icon:has(.no-image) + .entry-text {
    width: 100%;
  }

  div.news-entry div.entry-icon span.icon img,
  .news-entry .entry-icon span.no-image {
    object-fit: contain;
    height: 142px;
    width: 142px;
    min-width: 142px;
  }

  .pagination a {
    background-color: g.$bg-white-primary;
  }

  .pagination li.active a {
    background-color: transparent;
  }
}

// 重要なお知らせ
.newsentries:not(.faq).announce .news-archive-announce {
  .news-entry .body {
    color: g.$text-black-primary;
  }
}

/*--------------------------------------------------
ニュース詳細
--------------------------------------------------*/
#news:not(:has(.news-archive-faq)):not(:has(.news-archive-archive)) {
  .l-footer {
    margin-top: 0;
  }

  .l-footer__main {
    border-top: 1px solid g.$border-gray;
  }
}

#news .news:not(.news-archive-faq):not(.news-archive-archive) {
  background-color: g.$bg-gray-primary;

  #page-title .breadcrumbs {
    margin-bottom: 0;
  }

  #page-title {
    background-color: g.$bg-white-primary;
    padding-inline: g.$padding-container-inline-pc;
  }


  h1 {
    font-size: 24px;
    font-weight: 400;
    padding: 26px 0;

     @media #{g.$pc} {
      margin: 0 auto;
      max-width: 1200px;
    }

    @media #{g.$sp} {
      padding-block: 20px;
    }
  }

  #page-wrapper {
    @include g.container();
    max-width: 860px;
    padding: 30px 30px 100px;

    @media #{g.$sp} {
      padding-top: 12px;
    }
  }

  .news-entry {
    background-color: g.$bg-white-primary;
  }

  .news-entry-subject {
    color: g.$text-green-primary;
    font-size: 20px;
    font-weight: 700;

    @media #{g.$sp} {
      font-size: 18px;
    }
  }

  .release-date {
    color: g.$text-black-secondary;
    font-size: 15px;
    font-weight: 500;
  }

  .news-entry-header {
    border-top: 2px solid g.$border-green;
    border-bottom: 1px solid g.$border-gray;
    padding: 28px 30px;
    display: flex;
    flex-direction: column-reverse;

    @media #{g.$sp} {
      padding: 15px 12px;
    }
  }

  .news-entry-icon {
    display: none;
  }

  .news-entry-body {
    padding: 24px 30px 140px;

    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media #{g.$sp} {
      padding: 15px 12px 20px;
    }
  }

  .trailer-link {
    padding: 50px 0 0;

    display: flex;
    justify-content: center;

    @media #{g.$sp} {
      padding-top: 30px;
    }
  }

  .trailer-link a {
    @include g.hoverButton();
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    font-size: 16px;
    padding-inline: 48px 15px;
    position: relative;

    height: 50px;
    min-width: 238px;

    display: grid;
    place-items: center;

    @media #{g.$sp} {
      height: auto;
      text-align: left;
      min-height: 50px;
      max-width: 260px;
      width: 100%;
    }
  }

  .trailer-link a::before {
    background: url(/f/resources/images/common/icon_arrow_right_bg_green.svg) 50% / contain no-repeat;
    content: '';
    display: block;

    height: 24px;
    width: 24px;

    position: absolute;
    top: 50%;
    left: 12px;

    transform: translateY(-50%);
  }

  // その他のニュースを見る（一覧へ戻るリンク）
  .show-othernews {
    font-size: 12px;
    padding-left: 8px;
    margin-bottom: 8px;
    position: relative;
  }

  .show-othernews::before {
    content: "";
    background: transparent url(/f/resources/images/common/icon_chevron_left.svg) center / cover no-repeat;
    display: inline-block;
    width: 6px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body {
  * + * {
    margin-top: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 40px;

    @media #{g.$sp} {
      margin-top: 20px;
    }

    & + * {
      margin-top: 10px;
    }
  }

  h1 {
    background-color: g.$bg-green-primary;
    color: g.$text-white-primary;
    font-size: 20px;
    font-weight: 700;
    padding: 8px 12px;
    width: 100%;

    @media #{g.$sp} {
      font-size: 18px;
      padding: 8px;
    }
  }

  h2 {
    background-color: #66aca5;
    color: g.$text-white-primary;
    font-size: 20px;
    font-weight: 700;
    padding: 3px 12px 8px;
    position: relative;
    width: 100%;

    @media #{g.$sp} {
      font-size: 18px;
      padding: 3px 8px 8px;
    }
  }

  h2::before {
    background: g.$bg-white-primary;
    content: '';
    display: block;

    height: 1px;
    width: 100%;

    position: absolute;
    left: 0px;
    bottom: 3px;
    transform: translateY(-50%);
  }

  h3 {
    background-color: #c8e3e0;
    border-left: 2px solid g.$border-green;
    font-size: 18px;
    font-weight: 700;
    padding: 4px 12px;
    width: 100%;

    @media #{g.$sp} {
      font-size: 18px;
      padding: 4px 8px;
    }
  }

  h4 {
    background-color: g.$bg-gray-secondary;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 700;
    padding: 3px 9px 4px;
    width: 100%;

    @media #{g.$sp} {
      font-size: 16px;
    }
  }

  h5 {
    color: g.$text-green-primary;
    font-size: 16px;
    font-weight: 700;
    padding: 0;
  }

  .table-wrap {
    @media #{g.$sp} {
      overflow-x: scroll;
      width: 100%;
    }
  }

  img {
    margin-inline: auto;
    max-width: 500px;
    width: 100%;
  }

  p {
    font-size: 16px;
    margin-top: 20px;
  }

  P + P {
    margin-top: 8px;
  }

  small {
    color: g.$text-black-secondary;
    font-size: 14px;
  }

  big {
    font-size: 20px;
  }

  a {
    @include g.hoverLink();
    color: g.$text-green-secondary;
    text-decoration: underline;
  }

  * + a {
    margin-top: 10px;
  }

  // Well書式のラッパー要素
  div.wl {
    width: 100%;
  }

  .button {
    @include g.hoverButton();
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    color: g.$text-white-primary;
    font-size: 16px;
    margin-top: 20px;
    position: relative;

    min-height: 50px;
    min-width: 220px;
    width: fit-content;

    display: grid;
    place-items: center;

    @media #{g.$sp} {
      min-height: 40px;
      min-width: 210px;
    }
  }

  .button::before {
    background: url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) 50% / contain no-repeat;
    content: '';
    display: block;

    height: 24px;
    width: 24px;

    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);

    @media #{g.$sp} {
      right: 11px;
    }
  }

  // ノーマル
  .button.btn-normal-green,
  .button.btn-normal-orage,
  .button.btn-normal-red,
  .button.btn-normal-gray {
    background-color: g.$bg-green-secondary;
    box-shadow: 0 3px 0 g.$bg-green-primary;
    border: none;

    &::before {
      display: none;
    }
  }

  .button.btn-normal-green {
    background-color: g.$bg-green-secondary;
    box-shadow: 0 3px 0 g.$bg-green-primary;
  }

  .button.btn-normal-orage {
    background-color: g.$button-orange;
    box-shadow: 0 3px 0 #ff7008;
  }

  .button.btn-normal-red {
    background-color: g.$bg-red-secondary;
    box-shadow: 0 3px 0 #770d0d;
  }

  // 矢印アイコン付き
  .button.btn-arrow-green,
  .button.btn-arrow-orage,
  .button.btn-arrow-red,
  .button.btn-arrow-gray {
    padding: 8px 44px 8px 10px;
  }

  .button.btn-arrow-green {
    background-color: g.$bg-green-secondary;
  }

  .button.btn-arrow-orage {
    background-color: g.$button-orange;
  }

  .button.btn-arrow-red {
    background-color: g.$bg-red-secondary;
  }

  // 角丸・矢印アイコン付き
  .button.btn-rounded-green,
  .button.btn-rounded-orage,
  .button.btn-rounded-red,
  .button.btn-rounded-gray {
    border-radius: 50px;
    padding: 8px 44px 8px 10px;
  }

  .button.btn-rounded-green {
    background-color: g.$bg-green-secondary;
  }

  .button.btn-rounded-orage {
    background-color: g.$button-orange;
  }

  .button.btn-rounded-red {
    background-color: g.$bg-red-secondary;
  }

  // グレーボタン
  .button.btn-normal-gray {
    box-shadow: 0 3px 0 g.$border-gray;
  }

  .button.btn-normal-gray,
  .button.btn-arrow-gray,
  .button.btn-rounded-gray {
    color: g.$text-green-primary;
    background-color: g.$bg-gray-primary;

    &::before {
      background: url(/f/resources/images/common/icon_arrow_right_circle_green.svg) 50% / contain no-repeat;
    }
  }

  a.button {
    text-decoration: none;
  }

  // テーブル
  .table-container {
    @media #{g.$sp} {
      overflow: auto;
      width: 100%;
    }
  }

  table {
    border-collapse: collapse;
    margin-top: 40px;

    @media #{g.$sp} {
      margin-top: 20px;
    }
  }

  th,
  td {
    border: 1px solid #c4c4c4;
    padding: 12px 14px;

    @media #{g.$sp} {
      white-space: nowrap;
    }
  }

  th {
    background-color: g.$bg-gray-primary;
    font-weight: 700;
  }

  td {
    font-weight: 500;
  }

  // リスト
  ul,
  ol {
    margin-top: 40px;

    @media #{g.$sp} {
      margin-top: 20px;
    }
  }

  li {
    font-size: 16px;
    font-weight: 700;
    position: relative;
  }

  li::before {
    content: '';
    display: block;

    position: absolute;
    top: 6px;
    left: 0px;
  }

  ul {

  }

  ul li {
    padding-left: 16px;

  }

  ul li::before {
    background-color: g.$bg-black-primary;
    border-radius: 50px;
    content: '';

    height: 12px;
    width: 12px;
  }

  // 番号付きリスト
  ol {

  }

  ol li {
    counter-increment: item;
    position: relative;
    padding-left: 18px;
  }

  ol li::before {
    content: counter(item) ".";
    color: g.$text-black-tertiary;
    font-size: 16px;
    top: 0;
  }
}

// おすすめ記事
#news .top-newslist-related-news {
  @include g.container();
  padding-bottom: 100px;
  max-width: 860px;

  .panel-news {
    padding-inline: 0;
  }

  .news-name {
    font-size: 30px;
    font-weight: 500;
  }

  .panel-body {
    margin-top: 30px;
  }

  .news-entries .news-entry {
    border-top: none;
    border-bottom: 1px solid g.$border-gray;
    padding: 20px 0;
  }

  .news-entries .news-entry:first-child {
    border-top: 1px solid g.$border-gray;
  }

  .entry-text {
    padding-inline: 0;
  }

  .header {
    display: flex;
    gap: 40px;

    @media #{g.$sp} {
      flex-direction: column;
      gap: 4px;
    }
  }

  .news-type {
    display: flex;
    align-items: center;

  }

  .news-type span {
    font-size: 12px;
    font-weight: 500;
    margin-right: 0;
    padding: 0 8px;
  }
}