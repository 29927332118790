@use "global" as g;
/*
  ---------------
  Section
  ---------------
*/
// メインコンテンツ用 余白無し
.l-section {

  // セクションインナー
  &--inner {
    padding-right: g.vw(11);
    padding-left: g.vw(11);

    @media #{g.$pc} {
      box-sizing: content-box;
      max-width: 1200px;
      margin: 0 auto;
      padding-right: min(2.77777778%, 40px);
      padding-left: min(2.77777778%, 40px);
    }
  }

  // セクションヘッダー
  &--header {

    @media #{g.$pc} {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
