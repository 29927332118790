@use "global" as g;

/*
  ---------------
  マイページ | ポイント履歴
  ---------------
*/
body#account__view_points_history {
  #page-title + .note {
    font-size: 14px;
    margin-block: 10px 0;
  }

  .points-summary {
    background: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
    margin-top: 20px;

    @media #{g.$sp} {
      display: block;
    }
  }

  .points-summary .points-amount {
    flex: auto;
  }

  .points-amount-available {
    width: 60%;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .points-amount-pending {
    width: 40%;

    @media #{g.$pc} {
      border-left: 1px solid g.$border-gray;
    }

    @media #{g.$sp} {
      border-top: 1px solid g.$border-gray;
      width: 100%;
    }
  }

  .points-summary .points-amount .wrapper {
    border: none;
    height: 67px;
    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{g.$sp} {
      height: auto;
      padding: 12px 14px;
    }
  }

  .wrapper .label {
    font-size: 16px;
    font-weight: 500;
  }

  .points-summary .points-amount-available .wrapper {
    @media #{g.$sp} {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .points-summary .points-amount-available .wrapper .value {
    @media #{g.$sp} {
      text-align: right;
      width: 100%;
    }
  }

  .points-summary .points-amount-available .wrapper span.value {
    color: g.$text-green-primary;
    font-size: 20px;
    font-weight: 700;
  }

  .points-detail {
    margin: 20px 0 0;
    width: 100%;
  }

  .points-detail tr:first-child {
    background: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
  }

  .points-detail tr:not(:first-child) {
    border: 1px solid g.$border-gray;
    height: 58px;
  }

  .points-detail th {
    border-bottom: none;
    font-size: 14px;
  }

  .points-detail td {
    font-size: 16px;
  }

  .points-detail th,
  .points-detail td {
    padding: 10px 24px;
    text-align: left;

    @media #{g.$sp} {
      font-size: 13px;
      padding: 10px 8px;
      text-align: center;
    }
  }

  .points-detail tr th:last-child,
  .points-detail tr .pd-expiry {
    display: none;
  }

  .points-detail span.pd-type {
    font-size: 12px;
    padding: 4px 32px;
    white-space: nowrap;

    @media #{g.$sp} {
      padding: 4px 8px;
    }
  }

  .points-detail tr.pd-type-pending span.pd-type {

  }
}