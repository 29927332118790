@use "global"as g;

/*
  ---------------
  ボタン共通
  ---------------
*/
button.btn {
  @include g.hoverButton();
  background: g.$bg-gray-primary;
  border: 1px solid g.$border-gray-dark;
  border-radius: 2px;
  box-shadow: none;
  font-size: 13px;
  margin-bottom: 0;
  min-width: 164px;
  padding: 10px 18px;
}

a.btn-edit,
a.btn-edit:hover {
  @include g.hoverButton();
  background: #f6f6f6;
  border: 1px solid #b2b2b2;
  border-radius: 2px;
  box-shadow: none;
  font-size: 14px;
  text-align: center;
  padding: 10px 18px;
  width: 100%;
  min-width: 164px;
}

// 確定、送信、ログインボタン
#reminder-done .btn-submit a,
.community__talk .signin-to-post .signin,
.account__order_inquiry .order-inq .btn-submit,
.btn-submit .btn,
.cart-checkout .btn {
  @include g.hoverButton;
  border: none;
  border-radius: 2px;
  background: g.$button-orange;
  box-shadow: none;
  color: g.$text-white-primary !important;
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  position: relative;
  text-align: center;

  height: 50px;
  max-width: 175px;
  min-width: 175px;
  width: 100%;

  &:hover {
    background: g.$button-orange;
  }
}
// カート
.cart-checkout .btn::before {
  background: url(/f/resources/images/common/icon_arrow_right_orange.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  height: 24px;
  width: 24px;

  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.cart-contents .cart-clear-all,
.cart-contents-saved .cart-clear-all {
  padding-block: 20px 0;
  display: flex;
  justify-content: center;

  @media #{g.$sp} {
    padding-top: 20px;
  }
}

// クリアボタン
.order-cancel .btn-cancel-order,
.shipment-cancel .btn-cancel-shipment,
.cart-contents .cart-clear-all .btn,
.cart-contents-saved .cart-clear-all .btn {
  @include g.hoverButton;
  border: none;
  border-radius: 2px;
  border: 1px solid g.$border-gray;
  background: g.$bg-gray-primary;
  box-shadow: none;
  font-size: 16px;
  padding: 10px 15px 10px 30px;
  position: relative;

  height: 50px;
  max-width: fit-content;
  min-width: 175px;
  width: 100%;


  @media #{g.$sp} {
    padding-block: 6px;
    height: 40px;
  }

  &:hover {
    background: g.$bg-gray-primary;
  }

  &::before {
    background: url(/f/resources/images/common/icon_remove_black.svg) 50% / contain no-repeat;
    content: '';
    display: block;

    height: 13px;
    width: 11px;

    position: absolute;
    top: 52%;
    left: 15px;
    transform: translateY(-50%);
  }

}

.cart-contents-saved .cart-clear-all .btn {
  max-width: fit-content;
}

// カート | モーダル閉じるボタン
.partwork-subscription .btn.close {
  @include g.hoverButton();
  background-color: g.$text-black-primary;
  border: 1px solid g.$text-black-primary;
  border-radius: 0;
  box-shadow: none;
  font-size: 0;
  margin-inline: auto;
  min-width: auto;
  padding: 0;
  text-align: left;
  height: 60px;
  width: 60px;

  position: fixed;
  top: 0;
  right: 0;

  @media #{g.$sp} {
    height: 50px;
    width: 50px;
  }
}

.partwork-subscription .btn.close::before {
  background: url(/f/resources/images/common/icon_close_large_white.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  height: 30px;
  width: 30px;

  position: absolute;
  top: 14px;
  right: 14px;

  @media #{g.$sp} {
    top: 8px;
    right: 8px;
  }
}

// 確認ボタン｜背景ダークグレイ
.guide-flow .guide-flow__link,
.formrow-submit button,
.ln-order-inq a,
.request-paymentmethod-reset button.btn {
  @include g.hoverButton;
  background: g.$bg-gray-tertiary url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) calc(100% - 10px) 50% / 24px no-repeat;
  border-radius: 2px;
  color: g.$text-white-primary !important;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 50px 13px;
  min-width: 175px;

  @media #{g.$sp} {
    width: 100%;
  }

  &:hover {
    background: g.$bg-gray-tertiary url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) calc(100% - 10px) 50% / 24px no-repeat;
    color: g.$text-white-primary !important;
    font-size: 16px;
    font-weight: 700;
    padding: 14px 50px 13px;
    min-width: 175px;
  }

  &:visited {
    color: g.$text-white-primary !important;
  }
}

// 注文ページ
#order-entry #order-entry-content .panel > .control .btn {
  @include g.hoverButton();
  background: g.$bg-gray-tertiary;
  border: 1px solid #494949;
  border-radius: 2px;
  color: g.$text-white-primary !important;
  padding: 5px 16px;
  font-size: 14px;
}

#detach-coupons .btn,
#order-entry-content .panel > .body .control .btn {
  @include g.hoverButton();
  background: g.$bg-gray-primary;
  border: 1px solid g.$border-gray-dark;
  border-radius: 2px;
  box-shadow: none;
  font-size: 14px;
  text-align: center;
  padding: 10px 18px;
  width: 100%;
  min-width: 164px;
}

// 注文確定
#order__confirm .btn-submit-checkout {
  @include g.hoverButton();
  border-radius: 2px;
  background: g.$button-orange-gradient;
  border: none;
  box-shadow: none;
  color: g.$text-white-primary !important;
  font-size: 24px;

  margin-inline: auto;
  height: 80px;

  display: grid;
  place-items: center;

  @media #{g.$pc} {
    min-width: 560px;
  }

  @media #{g.$sp} {
    font-size: 24px;
    padding: 10px 20px;
    height: 60px;
    width: 100%;
    max-width: 370px;
  }
}

// 注文ページ
.rp-payment-method .btn-payment,
.manage-paymentmethod .btn-payment,
.manage-paymentmethod .btn-payment,
#order__payment .btn-payment,
#order__sender .addressbook .btn-addressbook,
#order__consignee .addressbook .btn-addressbook,
#order__orderer .addressbook .btn-addressbook,
#order__confirm .btn-moveitem .btn {
  @include g.hoverButton();
  border-radius: 2px;
  background: g.$bg-green-primary;
  border: none;
  box-shadow: none;
  color: g.$text-white-primary !important;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 8px;

  // height: 50px;
  min-width: 300px;

  display: grid;
  place-items: center;

  @media #{g.$sp} {
    min-height: 50px;
    min-width: 250px;
    max-width: 300px;
    height: fit-content;
    width: 100%;
  }
}

// 編集キャンセル・ご登録住所 | 背景グレー
.manage-paymentmethod .btn-inline,
.manage-addressbook .btn-use-as-default,
.manage-addressbook .btn-group a,
.manage-addressbook .btn-delete,
.order-cancel-edit > a {
  @include g.hoverButton;
  border: none;
  border-radius: 2px;
  border: 1px solid g.$border-gray;
  background: g.$bg-gray-primary;
  box-shadow: none;
  font-size: 16px;
  padding: 10px 15px 9px;
  position: relative;

  display: grid;
  place-items: center;

  height: 50px;
  max-width: max-content;
  min-width: 175px;
  width: 100%;

  @media #{g.$sp} {
    // height: 40px;
    max-width: 100%;
  }

  &:hover {
    background: g.$bg-gray-primary;
    box-shadow: none;
    font-size: 16px;
    display: grid;
    padding: 10px 15px 9px;
  }
}
// アドレス帳
.manage-paymentmethod .btn-inline,
.manage-addressbook .btn-use-as-default,
.manage-addressbook .btn-group a,
.manage-addressbook .btn-delete {
  font-size: 14px;
  height: 40px;
  padding: 8px 15px;
  min-width: 100px;

  &:hover {
    font-size: 14px;
    padding: 8px 15px;
  }
}

// 注文完了
#order__complete .order-process-main-complete .view-order-detail a {
  @include g.hoverButton;
  background: g.$bg-green-primary;
  border-radius: 2px;
  color: g.$text-white-primary !important;
  font-size: 16px;
  margin-top: 0;
  padding: 13px 24px 14px 50px;
  position: relative;
  text-decoration: none;

  min-width: min-content;
  width: fit-content;

  &::before {
    background: url(/f/resources/images/common/icon_receipt.svg) 50% / contain no-repeat;
    content: '';
    display: block;

    height: 19px;
    width: 19px;

    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
  }
}

// お問い合わせ履歴
#account__view_inquiries .inq-response button.btn-submit {
  @include g.hoverButton;
  background: g.$bg-green-primary;
  border-radius: 2px;
  color: g.$text-white-primary !important;
  font-size: 16px;
  margin-top: 0;
  padding: 13px 24px 14px 50px;
  position: relative;

  min-width: min-content;
  width: fit-content;

  &::before {
    background: url(/f/resources/images/common/icon_edit.svg) 50% / contain no-repeat;
    content: '';
    display: block;

    height: 19px;
    width: 19px;

    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
  }
}

// マイページトップへ戻るボタン、FAQ詳細
.btn-account {
  position: absolute;
  right: 0;
  bottom: -100px;
}

#page_aside1 .btn-account {
  @media #{g.$sp} {
    display: none;
  }
}

#page_aside2 .btn-account {
  text-align: center;
  margin-top: 40px;

  position: static;

  @media #{g.$sp} {
    margin-top: 50px;
  }
}

.guide-faq .trailer-link a,
.derived-subscriptions a.btn-view-pwsubscription,
body.news .news-archive-faq .trailer-link a,
body.news .news-archive-faq_imported .trailer-link a,
body.community__threadcreated #page-wrapper .link a,
.btn-account a,
.contact-complete__link a {
  background: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  box-shadow: none;
  color: g.$text-green-primary;
  padding: 12px 50px 12px 12px;
  position: relative;
  width: fit-content;

  @media #{g.$sp} {
    padding: 8px 12px;
  }

  &:hover {
    background: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    box-shadow: none;
    color: g.$text-green-primary;
    padding: 12px 50px 12px 12px;
  }
}

.guide-faq .trailer-link a::before,
.derived-subscriptions .btn-view-pwsubscription::before,
body.news .news-archive-faq .trailer-link a::before,
body.news .news-archive-faq_imported .trailer-link a::before,
body.community__threadcreated #page-wrapper .link a::before,
.btn-account a::before,
.contact-complete__link a::before {
  background: url(/f/resources/images/common/icon_arrow_right_circle_green.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  height: 24px;
  width: 24px;

  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);

  @media #{g.$sp} {
    display: none;
  }
}

body.news .news-archive-faq .trailer-link a,
body.news .news-archive-faq_imported .trailer-link a,
body.community__threadcreated #page-wrapper .link a,
#page_aside2 .btn-account a,
.contact-complete__link a {
  padding: 12px 12px 12px 50px;

  @media #{g.$sp} {
    display: inline-block;
    width: max-content;
  }

  &:hover {
    padding: 12px 12px 12px 50px;
  }
}

body.news .news-archive-faq .trailer-link a::before,
body.news .news-archive-faq_imported .trailer-link a::before,
body.community__threadcreated #page-wrapper .link a::before,
#page_aside2 .btn-account a::before,
.contact-complete__link a::before {
  right: auto;
  left: 14px;
  transform: translateY(-50%) rotate(180deg);

  @media #{g.$sp} {
    display: block;
  }
}

// 緑背景ボタン｜サインイン、サインアップ
#form .form-auth.form-signin .btn-submit-signup,
#container .form-auth.form-signin .btn-submit-signup,
#signup .signup-form button,
#signin .signup-form button,
#signout .signout .linkarea__button {
  background-image: none;
  background-color: g.$bg-green-primary;
  border: 0;
  color: g.$text-white-primary !important;
  font-size: 16px;
  font-weight: 700;
  margin: 10px auto 0;
  padding: 10px;
  position: relative;

  display: grid;
  place-items: center;

  width: 100%;
  max-width: 420px;
  height: 50px;
}

// // 緑背景ボタン・アイコン付き｜検索モーダル
.isp__linkbtn {
  background-color: g.$bg-green-secondary;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  color: g.$text-white-primary;
  font-size: 20px;
  font-weight: 700;
  position: relative;

  height: 70px;
  width: 470px;

  display: grid;
  place-items: center;

  @media #{g.$sp} {
    height: 60px;
    width: 330px;
  }
}

.isp__linkbtn::before {
  background: url(/f/resources/images/common/icon_arrow_right_circle_white.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  height: 24px;
  width: 24px;

  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

// コミュニティ一覧へ
.backlink.community-list a {
  @include g.hoverLink;
  background: g.$bg-green-primary;
  border: none;
  border-radius: 30px;
  box-shadow: none;
  // form.cssの影響で[!important]必要
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  padding-inline: 16px 38px;
  position: relative;

  display: flex;
  align-items: center;

  @media #{g.$sp} {
    font-size: 14px;
    height: 40px;
    margin-top: 0;
  }

  &::after {
    background: transparent url(/f/resources/images/common/icon_arrow_right_circle_white.svg) center/contain no-repeat;
    content: "";
    display: inline-block;

    height: 20px;
    width: 20px;

    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }
}
