
@use "global"as g;

/*
  ---------------
  ご利用ガイドトップ
  ---------------
*/

.guide-nav {
  min-width: 100%;
  width: 100%;
}

.guide-nav .guide-nav__title {
  @include g.container();
  font-size: 30px;
  font-weight: 500;
  padding-block: 20px 16px;

  @media #{g.$sp} {
    font-size: 24px;
    padding-block: 20px;
  }
}

.guide-nav .guide-nav__related {
  padding-bottom: 20px;
}

.guide-nav .guide-nav__related-list {
  @include g.container();

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media #{g.$sp} {
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
  }
}

.guide-nav .guide-nav__related-item {
  min-height: 80px;

  display: flex;
  flex-direction: column;

  @media #{g.$sp} {
    min-height: 60px;
  }
}

.guide-nav .guide-nav__related-link {
  background-color: rgb(0, 92, 105, .2);
  display: block;
  color: g.$text-green-primary;
  font-size: 20px;
  font-weight: 500;
  position: relative;

  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media #{g.$sp} {
    font-size: 11px;
    padding-inline: 8px;
  }
}

.guide-nav .guide-nav__related-link::after {
  content: '';
  background: transparent url(/f/resources/images/common/icon_chevron_right.svg) center/cover no-repeat;
  display: inline-block;

  height: 20px;
  width: 12px;

  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  @media #{g.$sp} {
    right: 5px;
    height: 10px;
    width: 6px;
  }
}

.guide-nav .guide-nav__body {
  @include g.container();
  padding-top: 30px;
}

.guide-nav .guide-nav__body .section-title {
  border-left: 0;
  padding-left: 0;
}

.guide-nav .guide-nav__body-wrap {
  background: g.$bg-gray-primary;
  width: 100vw;
  margin-top: 20px;
  margin-inline: calc(50% - 50vw);

  @media #{g.$pc} {
    border-top: 1px solid g.$border-gray;
  }

  @media #{g.$sp} {
    background: g.$bg-white-primary;
  }
}

.guide-nav .guide-nav__list {
  @include g.container();

  display: flex;
  align-items: center;
  gap: 30px;

  @media #{g.$sp} {
    max-width: 100%;
    padding-inline: 0;

    flex-wrap: wrap;
    gap: 2px;
  }
}

.guide-nav .guide-nav__item {
  @media #{g.$sp} {
    width: calc(50% - 2px);
  }
}

.guide-nav .guide-nav__link {
  display: inline-block;
  font-size: 14px;
  padding-block: 20px;
  position: relative;

  @media #{g.$sp} {
    background: g.$bg-gray-primary;
    padding-block: 15px;
    text-align: center;
    width: 100%;
  }
}

@media (hover: hover) and (pointer: fine) {
  .guide-nav .guide-nav__link:hover {
    color: g.$text-green-primary;
    font-weight: 700;
    opacity: 1;
  }

  .guide-nav .guide-nav__link:hover {
    &::before {
      background: g.$bg-black-primary;
      content: '';
      display: block;

      height: 2px;
      width: 98%;

      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
