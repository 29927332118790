@use "global" as g;

/*
  ---------------
  セレクトショップ 商品詳細 | 関連商品
  ---------------
*/
.select-product .p-relation {
  margin-top: 45px;

  @media #{g.$sp} {
    margin-top: 40px;
  }

  &__inner {
    @media #{g.$tab-over} {
      max-width: 562px;
    }
  }

  &__list {
    margin-top: 10px;
  }

  &__item {
    margin-inline: 5px;

    @media #{g.$tab-over} {
      max-width: 133px;
    }
  }

  &__item-link {
    width: 100%;
  }

  &__item-img {
    border: 1px solid g.$border-gray;
    aspect-ratio: 1 / 1;
    width: 100%;
    height: 100%;

    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
    }
  }

  &__item-title {
    font-size: 13px;
    line-height: 1.46153846;
    margin-top: 10px;

    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__item-price {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.46153846;
    margin-top: 2px;
  }

  // slick
  .slick-list {
    margin-inline: -5px;
  }

  .slick-track {
    margin: 0;

    display: flex;
  }

  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;

    @media #{g.$sp} {
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev {
    left: -25px;

    @media #{g.$sp} {
      left: -12px;
    }
  }

  .slick-next {
    right: -25px;

    @media #{g.$sp} {
      right: -12px;
    }
  }

  .slick-prev:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-next:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
  }
}
