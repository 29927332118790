@use "global"as g;

/*
  ---------------
  フリーページ 汎用ベースデザイン | セレクトショップ 特集から探す
  ---------------
*/
// ers-dev.css 追加
#freepage-base-01 {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 20px;
  width: 100%;
}

#freepage-base-01 h1 {
  font-size: 30px;
  margin-top: 40px;
  padding: 12px;
}

#freepage-base-01 h2 {
  border-bottom: 1px solid #333;
  font-size: 20px;
  margin: 40px 0 10px;
  padding: 12px 0;
}

#freepage-base-01 h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  padding: 12px 0;
}

#freepage-base-01 p {
  font-size: 16px;
  padding: 12px 0;
}

#freepage-base-01 .flex-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

#freepage-base-01 .flex-2 > * {
  width: 50%;
}

#freepage-base-01 .content {
  padding: 20px 40px;
}

#freepage-base-01 a.button {
  display: inline-block;
  background-color: #00ac5a;
  border-radius: 4px;
  color: #fff;
  padding: 10px 20px;
  margin-top: 20px;
}
// ers-dev.css ここまで