@use "global"as g;
/*
  ---------------
  ご利用ガイドトップ
  ---------------
*/
.select-shop-guide .select-shop-guide__wrap {
  @include g.container();
  padding-top: 50px;

  @media #{g.$sp} {
    padding-top: 24px;
  }
}

.select-shop-guide .select-shop-guide__list {
  padding-top: 22px;

  display: grid;
  gap: 8px;
  grid-auto-rows: 200px;

  @media #{g.$tab-over} {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }

  @media #{g.$tab} {
    grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
    gap: 16px;
  }

  @media #{g.$sp} {
    padding-top: 12px;

    gap: 0;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 140px;
  }
}

.select-shop-guide .select-shop-guide__item {
  border: 1px solid g.$border-gray;
  padding: 16px 8px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: -1px;
  margin-left: -1px;
}

.select-shop-guide .select-shop-guide__title-sub {
  color: g.$text-green-primary;
  font-size: min(1.38vw,20px);
  font-weight: 500;
  height: 47px;
  line-height: 1.16;
  position: relative;
  text-align: center;

  @media #{g.$tab} {
    font-size: 20px;
  }

  @media #{g.$sp} {
    font-size: 13px;
    height: 31px;
  }
}

.select-shop-guide .select-shop-guide__mark {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.16;
}

.select-shop-guide .select-shop-guide__figure {
  display: inline-block;
  flex: 1 1 auto;

  display: grid;
  place-items: center;
}

.select-shop-guide .select-shop-guide__image {
  max-width: max-content;

  @media #{g.$sp} {
    max-width: 60%;
  }
}

.select-shop-guide .select-shop-guide__annotation {
  font-size: 12px;
  margin-top: 12px;

  & + .select-shop-guide__annotation {
    margin-top: 4px;
  }
}

.select-shop-guide .select-shop-guide__annotation a {
  text-decoration: underline;
}

.select-shop-guide .select-shop-guide__annotation a:hover {
  text-decoration: none;
  transition: none;
}
