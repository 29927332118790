@use "global" as g;

/*
  ---------------
  シリーズトップ | 商品画像, title, description
  ---------------
*/
#itemlist #category-txc {}
#itemlist #category-txc .txc-header {
  display: flex;
  flex-direction: column;
}

#itemlist #category-txc .txc-header {
  order: 1;
}

#itemlist #category-txc .txc-header div {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.71428571;
  margin-top: 20px;
  order: 3;

  @media #{g.$pc} {
    margin-top: 10px;
  }
}

#itemlist #category-txc .txc-header img {
  border: 1px solid g.$border-gray;
  height: 250px;
  width: 250px;
  object-fit: contain;
  order: 2;
  margin: 0 auto;

  @media #{g.$pc} {
    max-height: 460px;
  }

  @media #{g.$sp} {
    height: 200px;
    width: 200px;
  }
}

#itemlist #category-txc .txc-header div {
  order: 3;
}

#itemlist #category-txc .txc-html {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71428571;
  margin-top: 20px;
}
