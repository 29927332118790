@use "global" as g;
/*
  ----------------
  会社情報トップ
  ----------------
*/
.company .panel-list ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media #{g.$pc} {
    gap: 0 20px;
  }

  @media #{g.$sp} {
    grid-template-columns: 1fr;
  }
}

.company .panel-list ul > li {
  border-top: 1px dashed g.$border-gray;
}

.company .panel-list ul > li:nth-child(4) {
  @media #{g.$pc} {
    border-bottom: 1px dashed g.$border-gray;
  }
}

.company .panel-list ul > li:last-child {
  border-bottom: 1px dashed g.$border-gray;
}

.company .panel-list > ul > li a {
  display: block;
  text-align: left;
  width: 100%;
  padding: 16px 0 16px 16px;
}

.company .panel-list > ul > li span {
  display: block;
  width: 100%;
  padding-left: 22px;

  position: relative;
}

.company .panel-list > ul > li span::before {
  background: transparent url("/f/resources/images/common/icon_arrow_right_green.svg") center / contain no-repeat;
  content: "";
  display: inline-block;
  width: 6px;
  height: 10px;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
