@use "global"as g;
/*
  ---------------
  クーポン一覧
  ---------------
*/
body#account__your_coupons {
  .yourcoupons {
    padding: 0;
  }

  .yourcoupon-list {
    padding-top: 40px;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 20px;

    @media #{g.$sp} {
      flex-direction: column;
    }
  }

  .coupon {
    background-color: g.$bg-gray-primary;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
    width: calc((100% - 40px) / 3);
    overflow: hidden;
    padding: 10px;
    position: relative;

    @media #{g.$tab} {
      width: calc((100% - 20px) / 2);
    }

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .coupon::before {
    content: '';
    display: inline-block;
    position: absolute;
    padding: 8px 0;
    left: -23px;
    top: 22px;
    width: 100px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    background: g.$bg-red-secondary;
    color: #fff;
    letter-spacing: 0.05em;
    transform: rotate(-45deg);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .coupon::after {
    content: 'DEAGOSTINI \A COUPON';
    color: g.$text-green-primary;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    white-space:pre;

    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%) rotate(-90deg);
  }

  .coupon-props {
    border-radius: 2px;
    border: 1px solid g.$text-green-primary;
    padding: 20px 60px 8px 8px;
    position: relative;
    text-align: center;

    display: flex;
    flex-direction: column;
  }

  .coupon-props::before {
    content: '';
    background-image : linear-gradient(to bottom, g.$text-green-primary, g.$text-green-primary 2px, transparent 2px, transparent 6px);
    background-size: 1px 6px;
    background-position: right top;
    background-repeat: repeat-y;
    width: 1px;

    position: absolute;
    top: 5px;
    bottom: 5px;
    right: 60px;
  }

  .label {
    padding: 0;
  }

  .code {
    background: transparent;
    color: g.$text-black-primary;
    display: block;
    font-size: 10px;
    font-weight: normal;
    padding: 0;
  }

  .name {
    border-bottom: 1px solid g.$border-green;
    color: g.$text-green-primary;
    display: block;
    font-size: 24px;
    font-weight: 500;
    margin: 0 auto;
    padding: 2px 0;
    text-align: center;
    width: 80%;
  }

  .expiry {
    color: g.$text-black-primary;
    padding: 0;
    margin-top: 8px;

    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .coupon-props > .description {
    color: g.$text-black-primary;
    cursor: pointer;
    font-size: 11px;
    height: 16px;
    margin-top: 4px;
    padding-top: 12px;
    overflow-y: hidden;
    position: relative;
    line-height: 2;
  }

  .coupon-props > .description:hover {
    opacity: .8;
  }

  .coupon-props > .description:not(:empty)::before {
    content: url(/f/resources/images/common/icon_help_sp.svg);
    display: block;
    margin-top: 3px;
    position: absolute;
    top: -4px;
    left: 31%;
    transform: translateX(-100%) scale(.9);
  }

  .coupon-props > .description:not(:empty)::after {
    content: "利用上の注意事項はこちら";
    display: block;
    position: absolute;
    top: -4px;
    right: 50%;
    transform: translateX(60%);
  }

  .coupon-props > .description:empty {
    pointer-events: none;
  }

  .text-panel {
    background-color: rgba(g.$bg-black-primary, .5);

    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .text-panel .text-panel__inner {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 80vh;
    width: 90vw;

    @media #{g.$sp} {
      height: auto;
      max-height: 70vh;
    }
  }

  .text-panel .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-green;
    border-radius: 2px;
    max-height: 900px;
    max-width: 900px;
    padding: 60px;
    height: auto;
    width: 100%;

    @media #{g.$sp} {
      overflow-y: scroll;
      padding: 62px 10px 40px;
    }
  }

  .text-panel .text-panel__closer {
    cursor: pointer;
    height: 60px;
    width: 60px;
    z-index: 1;

    position: absolute;
    top: 0;
    right: 0;
  }

  .text-panel__closer::before,
  .text-panel__closer::after {
    content: '';
    display: block;
    background-color: g.$border-black;
    height: 50px;
    width: 2px;

    position: absolute;
    top: 5px;
    right: 30px;
  }

  .text-panel__closer::before {
    transform: rotate(45deg);
  }

  .text-panel__closer::after {
    transform: rotate(-45deg);
  }

  .text-panel  .description {
    object-fit: contain;
  }
}