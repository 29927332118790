/*
  ----------------
  Loading Spinner
  ----------------
*/

@mixin loadingSpinner() {
  content: '';
  display: inline-flex;

  border: solid 3px;
  border-color: #333 #f6f6f6 #f6f6f6;
  border-radius: 50%;
  height: 30px;
  opacity: .7;
  width: 30px;

  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}
