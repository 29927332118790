@use "global"as g;

/*
  ---------------
  商品一覧・詳細 | アイテムオプション
  ---------------
*/
// 商品詳細
.partwork-product .p-product .p-product__detail-inner,
.select-product .p-product .p-product__detail-purchase {
  .item-options {
    margin-top: 16px;
  }

  .item-option + .item-option {
    margin-top: 10px;
  }

  .item-option-name {
    font-size: 16px;
    font-weight: 500;
  }

  .item-option-ctrl {
    margin-top: 6px;
    overflow-x: auto;
    padding-bottom: 10px;
  }

  .item-option-checks {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    @media #{g.$sp} {
      flex-direction: column;
    }
  }

  .item-option-checks label {
    cursor: pointer;
  }

  .item-option-fee {
    font-weight: 700;
  }

  .item-option-checks input {
  }

  .item-option-dsc {
    display: none;
  }
}

// パックトイ
.flowpack.select-product .p-product .p-product__detail-purchase {
  .item-option-checks label.is-checked {
    border: 1px solid #00ada0;
  }

  .item-option-checks label.is-checked::after {
    background-color: #00ada0;
  }
}

// パートワーク
.partwork-product .p-product .p-product__detail-inner {
  .item-option-checks label.is-checked {
    border: 1px solid g.$bg-green-secondary;
  }

  .item-option-checks label.is-checked::after {
    background-color: g.$bg-green-secondary;
  }
}

// 商品一覧
#itemlist {
  .item-options {
    padding-block: 8px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    @media #{g.$sp} {
      padding-block: 4px;
    }
  }

  .item-purchase .item-options .item-option {
    border: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .item-option-name {
    order: 1;
  }

  .item-option-dsc {
    order: 2;
  }

  .item-option-ctrl {
    order: 3;
  }

  .item-option-input {
    display: none;
  }

  .item-option-checks {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .item-option-checks label {
    @include g.hoverButton;
    font-size: 14px;
    padding: 0;

    display: flex;
    align-items: center;
    gap: 4px;
  }

  .item-option-checks input {
    margin: 0;

    height: 16px;
    width: 16px;
  }
}