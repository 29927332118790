@use "global" as g;
/*
  ----------------
  お問い合わせ完了ページ
  ----------------
*/
.contact-complete .contact-complete__wrap {
  @include g.container();
  padding-top: 100px;

  display: grid;
  place-items: center;

  @media #{g.$sp} {
    padding-top: 40px;
  }
}

.contact-complete .contact-complete__title {
  font-size: 24px;
  font-weight: 500;
  padding: 0;
}

.contact-complete .contact-complete__text {
  font-size: 16px;
  font-weight: 400;
  margin-top: 40px;
  padding: 0;

  @media #{g.$sp} {
    font-size: 14px;
    margin-top: 20px;
  }
}

.contact-complete .contact-complete__text+.contact-complete__text {
  margin-top: 8px;
}

.contact-complete .contact-complete__link {
  padding-top: 80px;
  text-align: center;
}
