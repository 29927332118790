@use "global" as g;

/*
  ---------------
  TOP | サイトバナー スライダー下
  ---------------
*/
article#container  {
  max-width: 100%;
  margin: 0;
  overflow: visible;
}

.site-banners-TOP_SUB_01 {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  .site-banner {
    border: 0;
    border-radius: 0;
    height: auto;
    padding: 0;
  }

  .banners ul > li {
    padding: 0;
    width: auto;
  }

  a {
    display: block;
  }


  /*==========================================
    スタイル追加
  ==========================================*/
  margin-top: 18px;

  @media #{g.$pc} {
    margin-top: 27px;
  }

  .heading {
    display: none;
  }

  .banners {
    padding-right: 3.05343511vw;
    padding-left: 3.05343511vw;

    @media #{g.$pc} {
      max-width: 1280px;
      margin: 0 auto;
      padding-right: clamp(0px, 2.77777778vw, 40px);
      padding-left: clamp(0px, 2.77777778vw, 40px);
    }
  }

  .banners ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5.08905852vw;

    @media #{g.$pc} {
      gap: 5.27777778vw;
    }
  }
}

.site-banners-TOP_SUB_PC_01 {
  padding-top: 24px;

  .banners {
    max-width: 1280px;
    margin: 0 auto;
    padding-right: clamp(0px, 2.77777778vw, 40px);
    padding-left: clamp(0px, 2.77777778vw, 40px);
  }

  .banners ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }

  .site-banner .item-tags {
    top: 6px;
    right: -8px;
  }
}
