@use "global"as g;

/*
  ---------------
  コミュニティ共通
  ---------------
*/

body#community__boardlist,
body#community__search,
body#community__commentlist,
body#community__board,
body#community__talk,
body#community__newthread,
body#community__threadcreated,
body#community__comment {
  #outer {
    @include g.container();
    width: 100%;
  }

  #container {
    overflow-x: visible;
  }

  // お知らせ関連
  #page_aside1 {
    background-color: #d9d9d9;
    margin: 0 calc(50% - 50vw);
    padding-inline: g.$padding-container-inline-pc;

    @media #{g.$pc} {
      width: 100vw;
    }

    @media #{g.$sp} {
      padding-inline: 12px;
    }
  }

  #page_aside1 .newslist-community-announce {
    margin-top: 0;
    position: relative;
  }

  #page_aside1 .newslist-community-announce .panel-heading {
    @include g.hoverLink;
    position: relative;
    z-index: 5;
  }

  #page_aside1 .panel-heading .news-name {
    @include g.container();
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    padding: 14px 0;
    position: relative;
    text-align: left;

    @media #{g.$sp} {
      font-size: 14px;
      padding-inline: 0;
    }
  }

  #page_aside1 .panel-heading .news-name::before {
    background: url(/f/resources/images/common/icon_plus_circle.svg) 50% / contain no-repeat;
    content: '';
    display: block;

    height: 20px;
    width: 20px;

    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  #page_aside1 .panel-heading.is-open .news-name::before {
    background: url(/f/resources/images/common/icon_minus_circle_green.svg) 50% / contain no-repeat;
  }

  #page_aside1 .panel-body {
    background-color: #d9d9d9;
    border: none;
    line-height: 0;
    margin: 0;
    max-height: 0;
    padding: 0 g.$padding-container-inline-pc;
    opacity: 0;
    overflow: hidden;
    width: 100vw;
    z-index: 1;

    transition:
      max-height 0.3s ease-out,
      line-height 0.3s ease-out,
      padding 0.3s ease-out,
      opacity 0.2s ease-out;

    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);

    @media #{g.$sp} {
      padding-inline: 12px;
      margin: 0;
    }
  }

  #page_aside1 .panel-body.is-open {
    line-height: 1.5;
    max-height: 500px;
    padding-bottom: 14px;
    opacity: 1;

    @media #{g.$sp} {}
  }

  #page_aside1 .panel-body .news-entries {
    @include g.container();
    padding-inline: 0;

    max-width: 1200px;
    width: 100%;

    @media #{g.$sp} {
      padding-inline: 0;
    }
  }

  #page_aside1 .panel-body .news-entries .news-entry {
    border-top: none;
  }

  #page_aside1 .panel-body .entry-text {
    padding: 0;
  }

  #page_aside1 .panel-body .entry-text span {
    font-size: 14px;
  }

  .breadcrumbs {
    display: none;
  }

  #page-title h1 {
    color: #333;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    margin-top: 24px;
    padding: 7px 0;

    @media #{g.$sp} {
      font-size: 20px;
      padding: 3px 0 3px 12px;
    }
  }

  // ヘッダー
  .community-board-header {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 40px;

    @media #{g.$sp} {
      flex-direction: column;
      margin-top: 20px;
    }
  }

  .news-icon {
    text-align: center;
    width: 50%;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .news-icon img {
    object-fit: contain;
    max-height: 200px;
    width: 100%;
  }

  .community-board-header-contents {
    background-color: g.$bg-gray-primary;
    padding: 40px;
    width: 50%;

    @media #{g.$sp} {
      width: 100%;
    }
  }
}

// 一覧へ戻るボタン
.community .backlink {
  margin-top: -50px;
  padding-bottom: 30px;

  display: flex;
  justify-content: flex-end;

  @media #{g.$sp} {
    display: inline-block;
    margin-top: 0;
    padding-block: 16px;
    vertical-align: middle;
    width: 49%;
  }
}

.community .backlink a {
  @include g.hoverLink;
  background: g.$bg-green-primary;
  border: none;
  border-radius: 30px;
  box-shadow: none;
  // form.cssの影響で[!important]必要
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  padding-inline: 16px 38px;
  position: relative;

  display: flex;
  align-items: center;

  @media #{g.$sp} {
    font-size: 14px;
    height: 40px;
    margin-top: 0;
    padding-inline: 8px 30px;
  }
}

.community .backlink a::before {
  background: transparent url(/f/resources/images/common/icon_arrow_right_circle_white.svg) center/contain no-repeat;
  content: "";

  height: 20px;
  width: 20px;

  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);

  @media #{g.$sp} {}
}

// 検索ボタン
body#community__boardlist .link-search {
  background-color: transparent;
  border: none;
  height: 50px;
  margin-top: -50px;
  padding: 0;

  position: absolute;
  right: 0;

  @media #{g.$sp} {
    height: 40px;
    margin-top: -40px;
    right: 12px;
  }
}

body:not(#community__boardlist) .community .link-search {
  @media #{g.$sp} {
    display: inline-block;
    height: auto;
    margin-top: 0;
    padding-block: 16px;
    position: static;
    vertical-align: middle;
    width: 49%;
  }
}

body#community__boardlist .link-search a,
body#community__talk .link-search a,
body#community__board .link-search a,
body#community__comment .link-search a,
body#community__newthread .link-search a,
body#community__threadcreated .link-search a,
body#community__commentlist .search-btn button.btn,
body#community__search .search-btn button.btn {
  @include g.hoverLink;
  background: g.$bg-green-primary;
  border: none;
  border-radius: 30px;
  box-shadow: none;
  // form.cssの影響で[!important]必要
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  padding-right: 22px;

  display: flex;
  align-items: center;

  @media #{g.$sp} {
    font-size: 14px;
    height: 40px;
    margin-top: 0;
    right: 12px;
  }
}

body#community__boardlist .link-search a::before,
body#community__talk .link-search a::before,
body#community__board .link-search a::before,
body#community__comment .link-search a::before,
body#community__newthread .link-search a::before,
body#community__threadcreated .link-search a::before,
body#community__commentlist .search-btn button::before,
body#community__search .search-btn button::before {
  background: transparent url(/f/resources/images/common/icon_search.svg) center/contain no-repeat;
  content: "";
  height: 20px;
  margin: 0 12px 0 18px;
  width: 20px;

  @media #{g.$sp} {
    margin: 0 6px 0 10px;
    width: 36px;
  }
}
// MV
body#community__board,
body#community__talk,
body#community__newthread,
body#community__comment {
  .community-board-mv {
    padding-top: 30px;
  }

  .community-board-mv .community-board-mv__wrap {
    display: flex;

    @media #{g.$sp} {
      display: block;
    }
  }

  .community-board-mv .community-board-mv__visual {
    width: 50%;

    display: grid;
    place-items: center;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .community-board-mv .community-board-mv__img {
    width: 100%;
  }

  .community-board-mv .community-board-mv__text {
    background-color: g.$text-gray-secondary;
    padding: 50px 40px;
    width: 50%;

    @media #{g.$sp} {
      padding: 12px 15px;
      width: 100%;
    }
  }

  .community-board-mv .community-board-mv__text-inner {
    font-size: 14px;
    line-height: 2.1;
  }
}

// コメント｜リンク、報告ボタン
.thread-comment .trailer {
  border: none;
  padding: 12px 0 0;

  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.thread-comment .trailer .send-report {
  position: relative;
}

.thread-comment .trailer .report-form {
  background-color: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  max-width: 500px;
  padding: 16px;
  width: 90vw;

  position: absolute;
  top: 32px;
  right: 0;
  z-index: 1;

  @media #{g.$sp} {
    right: -14px;
  }
}

.thread-comment .trailer .report-form div.btn-submit {
  padding-bottom: 12px;
}

.thread-comment .trailer .send-report > button {
  @include g.hoverLink;
  background: none;
  border: none;
  box-shadow: none;
  // form.cssの影響で[!important]必要
  color: #929292 !important;
  font-size: 12px;
  padding: 0;
  position: relative;

  @media #{g.$sp} {
    font-size: 14px;
  }

}

.thread-comment .trailer .send-report > button::before {
  background: url(/f/resources/images/common/icon_attention_mark.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  height: 14px;
  width: 14px;

  position: absolute;
  top: 50%;
  left: -18px;
  transform: translateY(-50%);
}

.thread-comment .trailer .comment-link {
  margin: 0 auto 0 0;
}

.thread-comment .trailer .comment-link a {
  color: g.$text-green-primary;
  font-size: 14px;
}

// スレッドタイトル
body#community__talk,
body#community__comment {
  .thread-title {
    border: 1px solid g.$border-gray;
    margin-top: 40px;
    padding: 24px;

    @media #{g.$sp} {
      padding: 16px;
    }
  }

  .thread-title::before {
    display: none;
  }

  .thread-title h3 {
    font-size: 24px;
    padding: 0;

    @media #{g.$sp} {
      font-size: 22px;
    }
  }

  .thread-comment ul.images {
    padding-top: 10px;
  }
}

// ers-dev.css 追加
/* リアクション（評価）| Reactions */
.reactions {
  display: flex;
}

.reactions > li {
  border: 1px solid #222;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 2px;
}

.reactions > li + li {
  margin-left: 20px;
}

.reactions .count {
  font-weight: bold;
  margin-left: 4px;
}
// ers-dev.css ここまで

// アンカーリンク
.community-anchor-link {
  background-color: g.$bg-white-primary;
  margin: auto calc((50vw - 50%) * -1);
}

.community-anchor-link__wrap {
  padding-block: 40px;
  padding-inline: clamp(0px, 2.77777778vw, 40px);

  @media #{g.$sp} {
    padding-block: 20px;
  }
}

.community-anchor-link ul {
  width: 100%;

  display: flex;
  gap: 16px;

  @media #{g.$sp} {
    flex-wrap: wrap;
  }
}

.community-anchor-link ul li {
  margin-right: 20px;
}

.community-anchor-link button {
  @include g.hoverLink;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  text-decoration: underline;
}