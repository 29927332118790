@use "global" as g;
/*
  ----------------
  沿革
  ----------------
*/
.company.history > p {
  margin-bottom: 80px;

  @media #{g.$sp} {
    margin-bottom: 60px;
  }
}

.company.history h2 {
  padding: 0 0 20px;
}

.company.history h2 span {
  border: 1px solid #005c69;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  max-width: 430px;
  margin-inline: auto;
  padding: 10px 40px 10px 20px;

  position: relative;

  @media #{g.$sp} {
    font-size: 20px;
    padding: 8px 34px 8px 14px;
  }
}

.company.history h2 span.is-open {
  background-color: g.$bg-green-primary;
  color: g.$text-white-primary;
}

.company.history h2 span.is-open::after {
  background: g.$bg-white-primary;
  width: 15px;
  height: 1px;
}

.company.history h2 span::after {
  content: "";
  background: transparent url(/f/resources/images/common/icon_plus_green.svg) center/contain no-repeat;
  display: inline-block;
  width: 15px;
  height: 13px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);

  @media #{g.$sp} {
    right: 10px;
  }
}

.company.history .content {
  display: none;
  margin-bottom: 100px;

  @media #{g.$sp} {
    margin-bottom: 16px;
  }
}

.company.history dt.img {
  aspect-ratio: 1 / 1;
}

.company.history dt.img img {
  height: 100%;
}


.company.history div.img {
  text-align: left;
}
