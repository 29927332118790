@use "global"as g;

/*
  ---------------
  画像拡大表示モーダル
  ---------------
*/

#image-modal {
  background-color: rgba(g.$bg-black-primary, .5);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  .image-modal__inner {
    display: flex;
    justify-content: center;
    align-items: center;

    max-height: 900px;
    height: 90vh;
    width: 90vw;

    @media #{g.$sp} {
      height: 70vh;
      width: 95vw;
    }
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-green;
    border-radius: 2px;
    max-height: 900px;
    max-width: 1200px;
    padding: 60px;
    height: 100%;
    width: 100%;

    @media #{g.$sp} {
      padding: 10px;
    }
  }

  .image-modal__closer {
    cursor: pointer;
    height: 60px;
    width: 60px;
    z-index: 1;

    position: absolute;
    top: 0;
    right: 0;
  }

  .image-modal__closer::before,
  .image-modal__closer::after {
    content: '';
    display: block;
    background-color: g.$border-black;
    height: 50px;
    width: 2px;

    position: absolute;
    top: 5px;
    right: 30px;
  }

  .image-modal__closer::before {
    transform: rotate(45deg);
  }

  .image-modal__closer::after {
    transform: rotate(-45deg);
  }

  .image-wrapper img {
    height: 100%;
    object-fit: contain;
  }
}