
// Breakpoint設定
$sp: "all and (max-width : 768px)";
$pc: "all and (min-width: 769px)";
$tab: "all and (max-width: 1024px) and (min-width: 769px)";
$tab-under: "all and (max-width: 1024px)";
$tab-over: "all and (min-width: 1025px)";

// Font-family
$font-family: "Noto Sans JP",
YuGothic,
"游ゴシック",
"Yu Gothic",
'ヒラギノ角ゴ Pro W3',
'Hiragino Kaku Gothic Pro',
'ＭＳ Ｐゴシック',
'MS PGothic',
sans-serif;

// text color
$text-black-primary: #333;
$text-black-secondary: #747474;
$text-black-tertiary: #1d1d1b;
$text-white-primary: #fff;
$text-gray-primary: #b2b2b2;
$text-gray-secondary: #f6f6f6;
$text-red-primary: #c10000;
$text-green-primary: #005c69;
$text-green-secondary: #00828c;
$text-green-tertiary: #00ada0;
$text-green-quaternary: #00ac5a;
$text-blue-primary: #396dcf;

// bg color
$bg-green-primary: #005c69;
$bg-green-secondary: #00828c;
$bg-green-tertiary: #66aca5;
$bg-green-quaternary: #00ac5a;
$bg-black-primary: #1d1d1b;
$bg-white-primary: #fff;
$bg-gray-primary: #f6f6f6;
$bg-gray-secondary: #d9d9d9;
$bg-gray-tertiary: #494949;
$bg-gray-quaternary: #b2b2b2;
$bg-gray-quinary: #747474;
$bg-red-primary: #ffebeb;
$bg-red-secondary: #c10000;
$bg-red-tertiary: #ff3658;
$bg-orange-primary: #ffbd08;

// border color
$border-black: #1d1d1b;
$border-white: #fff;
$border-gray: #d9d9d9;
$border-gray-dark: #b2b2b2;
$border-green: #005c69;
$border-green-right: #00ac5a;
$border-red: #c10000;
$border-blue: #396dcf;

// Button color
$button-orange: #ff9c08;
$button-orange-gradient: linear-gradient(90deg, #ff9c08 0%, #ffbd08 100%);

// icon color
$icon-red: rgb(230, 0, 18);
$icon-blue: rgb(0, 104, 183);
$icon-yellow: rgb(250, 190, 0);
$icon-green: rgb(0, 167, 60);
$icon-purple: rgb(165, 0, 130);

// Loyout
$padding-container-inline-pc: clamp(0px, 2.77777778vw, 40px);

// ers-dev.css 追加
:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}
// ers-dev.css ここまで
