@use "global" as g;

/*
  ---------------
  TOP | サイトバナー アイコンリスト
  ---------------
*/
.site-banners-TOP_ICON_LIST {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  .banners ul {
    display: block;
  }

  .banners > ul:not(.slick-initialized) {
    display: none;
  }

  .uri-to-img {
    width: 100%;
  }

  .slick-arrow {
    top: calc(50% - 24px);
    transform: translateY(-50%);
  }


  /*==========================================
    スタイル追加
  ==========================================*/
  // margin-top: 52px;

  .heading {
    display: none;
  }

  .banners {
    padding-right: 3.05343511vw;
    padding-left: 3.05343511vw;

    @media #{g.$pc} {
      max-width: 1280px;
      margin: 0 auto;
      padding-right: clamp(0px, 2.77777778vw, 40px);
      padding-left: clamp(0px, 2.77777778vw, 40px);
    }
  }

  .banners ul {
    padding-inline: max(5.59796438vw, 18px);

    @media #{g.$pc} {
      padding-inline: 40px;
    }
  }

  .banners a {
    display: block;
  }

  .caption {
    font-size: 16px;
    height: 38px;
    line-height: 1.41666667;
    overflow: hidden;
    text-align: center;
    margin-top: 10px;
    word-break: break-all;

    @media #{g.$sp} {
      font-size: 10px;
      height: 40px;
      margin-top: 7px;
    }
  }

  .banners ul.slick-slider {
    display: flex;
  }

  .item-tags {
    top: 0;
    right: -15px;
  }

  // slick
  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev, .slick-next {
    width: 14px;
    height: 23px;

    @media #{g.$sp} {
      width: 11px;
      height: 19px;
    }
  }

  .slick-prev::before {
    background: transparent url("/f/resources/images/top/icon_prev_green.svg") center / contain no-repeat;
  }

  .slick-next::before {
    background: transparent url("/f/resources/images/top/icon_next_green.svg") center / contain no-repeat;
  }

  .slick-prev:before, .slick-next:before {
    content: "";
    display: inline-block;
    width: inherit;
    height: inherit;
  }

  .slick-list {
    margin: 0 -1.90839695vw;

    @media #{g.$pc} {
      margin: 0 -1.38888889vw;
    }
  }

  .slick-slide {
    margin: 0 1.90839695vw;

    @media #{g.$pc} {
      margin: 0 1.38888889vw;
      padding: 0 20px;
    }
  }
}
