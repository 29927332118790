@use "global"as g;

/*
  ---------------
  パートワークお届け先の編集
  ---------------
*/
.account__edit_partwork_consignee {

  #outer {
    @include g.container();
  }

  .form-body .input-group-address {
    margin-top: 40px;
  }
}
