@use "global" as g;

/*
  ---------------
  マイページ | お問い合わせ一覧
  ---------------
*/
body#account__view_inquiries {
  .inquiries-list {
    padding-top: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .panel-inquiry {
    background: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
  }

  .panel-inquiry.has-response:not(.is-open):hover {
    opacity: .9;
  }

  .panel-heading,
  .inq-item {
    display: flex;
    gap: 18px;
  }

  .panel-heading,
  .inq-item-SELECT_ONE_RADIO {
    background: g.$bg-gray-secondary;
    padding-inline: 24px;

    @media #{g.$sp} {
      padding-inline: 12px;
    }
  }

  .account .panel-heading {
    padding-top: 22px;

    @media #{g.$sp} {
      padding-top: 16px;
    }
  }

  .view-inquiries .panel-body {
    margin-bottom: 0;
    padding-bottom: 26px;
    position: relative;
  }

  .view-inquiries .panel-inquiry.has-response .panel-body::before,
  .view-inquiries .panel-inquiry.has-response .panel-body::after {
    background: g.$bg-green-primary;
    content: '';
    display: block;
    position: absolute;
  }

  .view-inquiries .panel-body::before {
    height: 1px;
    width: 15px;

    top: 151px;
    right: 20px;

    @media #{g.$sp} {
      top: 71px;
    }
  }

  .view-inquiries .panel-body::after {
    height: 15px;
    width: 1px;

    top: 144px;
    right: 27px;
    transform: rotate(0);
    transition: transform 0.3s ease;

    @media #{g.$sp} {
      top: 64px;
    }
  }

  .panel-inquiry.is-open .panel-body::after {
    transform: rotate(90deg);
  }

  .inq-item-SELECT_ONE_RADIO {
    padding-top: 6px;
    padding-bottom: 22px;

    @media #{g.$sp} {
      padding-bottom: 16px;
    }
  }

  .panel-body {
    margin-top: 0;
  }

  .panel-heading .inq-type,
  .inq-item-label {
    font-size: 14px;
    font-weight: 500;
  }

  .panel-heading .inq-date,
  .inq-item-values span {
    font-size: 14px;
  }

  .inq-item {
    padding-inline: 24px;

    @media #{g.$sp} {
      padding-inline: 12px;
    }
  }

  .has-response .inq-item {
    cursor: pointer;
  }

  .inq-item:not(:first-child) {
    padding-top: 16px;
  }

  .inq-item-WORD {
    padding-top: 18px;
  }

  .panel-body .inq-item-WORD,
  .panel-body .inq-item-TEXT,
  .panel-body .inq-item-FILE_UPLOAD {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .inq-item-FILE_UPLOAD img {
    max-height: 200px;
  }

  .inq-response {
    border-top: 1px solid g.$border-gray;
    display: none;
    margin-top: 20px;
    padding: 26px 24px 0;

    @media #{g.$sp} {
      border: none;
      padding: 15px 10px 0;
    }
  }

  .inq-response-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .inq-resp {
    border: 1px solid g.$border-gray;
    font-size: 14px;
    padding: 18px 22px;

    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
  }

  .inq-resp-heading {
    display: flex;
    justify-content: space-between;
  }

  .inq-resp-date {
    color: g.$text-black-secondary;
  }

  .inq-re-entry h6 {
    display: none;
  }

  .inq-re-entry form {
    padding-top: 10px;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .inq-resp-content {
    word-break: break-all;
  }

  .inq-re-entry textarea {
    appearance: none;
    border: 1px solid g.$border-gray;
    overflow: auto;
    padding: 10px;
    resize: none;

    height: 110px;
    width: 100%;
  }
}