@use "global" as g;
/*
  ----------------
  signup
  ----------------
*/
#signup .signup-form {
  margin-top: 20px;
}

#signup .signup-form .form-signup .formrow .input-control .input-group,
#signup .signup-form .form-signup .formrow.formrow-checkbox.formrow-boolean .checkbox {
  font-size: 0;
  padding: 0;
  margin-top: 8px;
  @media #{g.$sp} {
    width: 100%;
  }
}

#signup .signup-form .form-signup fieldset .formrow .input-control .input-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#signup .signup-form .form-signup fieldset .formrow .input-control .input-group {
}

#signup .signup-form .form-signup fieldset .formrow .input-control .input-group .radio-item {
  @media #{g.$sp} {
    width: 100%;
  }
}

#signup .signup-form .form-signup fieldset .formrow .input-control .input-group .radio-item label {
  @media #{g.$sp} {
    width: 100%;
  }
}

#signup .signup-form .form-signup .formrow-yyyymmdd .input-control > div.input-group {
  gap: 5px;

  @media #{g.$sp} {
    flex-wrap: nowrap;
  }
}

#signup .signup-form .form-signup .formrow-input .input-control .input-group input {
  background-color: g.$bg-white-primary;
  border: 1px solid g.$border-gray;
}

#signup .signup-form .form-signup .formrow-input .input-label,
#signup .signup-form .form-signup .formrow-radio .input-label {
  display: flex;
  align-items: center;
}

#signup .signup-form .form-signup .formrow .input-label .input-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  margin-inline-end: 5px;
}

#signup .signup-form fieldset {
  background-color: transparent;
  border-top: 1px solid g.$border-gray;
  border-radius: 0;
  margin: 0;
  padding: 0;
  margin-block-start: 50px;
  padding-block-start: 20px;

  @media #{g.$pc} {
    padding-block-start: 25px;
  }
}

// ボタン類スタイル
#signup .signup-form .form-signup fieldset .formrow-radio .input-control label,
#signup .signup-form .form-signup fieldset .formrow-yyyymmdd .input-control select {
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;

  position: relative;

  @media #{g.$sp} {
    width: calc((100% - 10px) / 3);
  }
}

#signup .signup-form .form-signup fieldset .formrow-radio .input-control label {
  padding-block: 9px;
  padding-inline: 38px 14px;
}

// ボタン類デフォルトスタイル削除
#signup .signup-form .form-signup .formrow-radio input[type="radio"],
#signup .signup-form .form-signup .input-control .input-group select {
  appearance: none;
  margin: 0;
  // position: absolute;
  border: none;
}

// ラジオボタンカスタマイズ
#signup .signup-form .form-signup fieldset .formrow-radio .input-control label {
  &::before,
  &::after {
    content: "";
    display: inline-block;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-gray;
    border-radius: 50%;
    width: 18px;
    height: 18px;

    left: 10px;
  }
}

#signup .signup-form .form-signup fieldset .formrow-radio .input-control label.is-checked {
  border: 1px solid g.$border-green;

  &::after {
    background-color: g.$bg-green-primary;
    border-radius: 50%;
    width: 14px;
    height: 14px;

    left: calc((20px / 2) - (14px / 2) + 10px);
  }
}

// セレクトタグ(生年月日)カスタマイズ
#signup .signup-form .form-signup .input-control .input-group select {
  background: transparent url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  color: g.$text-black-primary;
  padding-block: 10px;
  padding-inline: 10px 8px;
  width: 100%;
  max-width: 150px;
  min-width: 150px;

  display: inline-flex;
  align-items: center;

  @media #{g.$sp} {
    min-width: 94px;
    padding-inline: 8px;
  }
}

// チェックボックスカスタマイズ
#signup .signup-form .form-signup .formrow-checkbox .input-control label {
  cursor: pointer;
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  font-size: 14px;
  padding: 10px 14px 10px 10px;

  display: flex;
  align-items: center;

  @media #{g.$sp} {
    width: 100%;
  }
}

#signup .signup-form .form-signup .formrow-checkbox .input-control input {
  accent-color: g.$bg-green-primary;
  margin: 0 8px 0 0;
  height: 20px;
  width: 20px;
}

#signup .signup-form .form-signup .formrow-checkbox .input-control label.is-checked {
  border: 1px solid g.$border-green;
}

#signup .signup-form .formrow-submit {
  margin-top: 60px;
  padding-inline: 15px;
}

#signup .signup-form .formrow-submit .note {
  margin: 0;
}

#signup .signup-form .signup-agree-and-submit .note-signup {
  font-size: 16px;
  padding-bottom: 12px;
}