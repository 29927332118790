@use "global" as g;

/*
  ---------------
  TOP | サイトバナー スライダー
  ---------------
*/
// PC ,SP
.site-banners-TOP_ROTATION_01,
.site-banners-TOP_ROTATION_SP_01 {

  a {
    display: block;
    height: auto;
  }

  .heading {
    display: none;
  }

  .banners ul {
    width: 100%;
  }

  .banners ul {
    display: flex;
    justify-content: center;

    @media #{g.$pc} {
      display: block;
    }
  }

  // slick
  .slick-slider {
    margin-bottom: 0;
  }

  .slick-slider .site-banner {
    width: 100%;
  }

  .slick-slide {
    position: relative;
  }

  .slick-slide:not(.slick-active)::before {
    content: "";
    display: block;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
  }

  .slick-dots {
    font-size: 0;
    bottom: 0;
  }

  .slick-dots li {
    width: auto;
    height: auto;
    padding: 5px 0;
    margin: 0 4px;
  }

  .slick-dots li button:before {
    display: none;
  }

  .slick-dots li.slick-active button {
    background-color: g.$bg-green-secondary;
  }

  .slick-dots li button {
    background-color: g.$bg-black-primary;
    width: 40px;
    padding: 0;
  }

  .slick-arrow {
    top: calc(50% - 16px);
    transform: translateY(-50%);
  }

  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;
    z-index: 2;

    @media #{g.$sp} {
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev {
    left: -25px;

    @media #{g.$sp} {
      left: 0;
    }
  }

  .slick-next {
    right: -25px;

    @media #{g.$sp} {
      right: 0;
    }
  }

  .slick-prev:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-next:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }
}

// PC
.site-banners-TOP_ROTATION_01 {
  @media #{g.$sp} {
    display: none;
  }

  img {
    // height: 35.9027778vw;
    max-height: 520px;
  }

  .banners {
    max-width: 1280px;
    margin-inline: auto;
    padding-inline: clamp(0px, 2.77777778vw, 40px);
  }

  div.banners > ul:not(.slick-initialized) {
    padding-bottom: 33px;
  }

  // slick
  .slick-slider {
    padding-bottom: 33px;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-dots li button {
    height: 5px;
  }
}

// SP
.site-banners-TOP_ROTATION_SP_01 {
  @media #{g.$pc} {
    display: none;
  }

  img {
    width: 100%;
    height: 106.666667vw;
  }

  div.banners:not(:has(.slick-initialized)) {
    padding: 0 3.56234097%;
  }

  div.banners > ul:not(.slick-initialized) {
    padding-bottom: 31px;
  }

  // slick
  .slick-slider {
    padding-bottom: 31px;
  }

  .slick-dots li button {
    height: 4px;
  }
}
