@use "global" as g;

/*
  ---------------
  配送に関するご要望
  ---------------
*/

body#order__deliveryservice {
  .form-body {
    margin-top: 40px;
  }

  .form-body .form {
    margin-top: 16px;
  }

  .formrow {
    margin-top: 16px;
  }

  .input-label {
    font-weight: 700;
  }

  select {
    padding: 5px 30px 5px 10px;

    height: 40px;
    min-width: 100px;
    max-width: 300px;
    width: 100%;
  }
}
