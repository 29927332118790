@use "global"as g;

/*
  ---------------
  ログインして注文
  ---------------
*/
body#order__signin_to_checkout {
  #outer {
    padding-top: 20px;
  }

  .form.form-order-signin {
    margin-top: 20px;
  }

  .form .form-note {
    margin: 1em 0;
  }

  div.form fieldset {
    border-top: 0;
  }

  .form .form-body .input-group-address {
    padding: 0;
    margin-top: 0;
  }
}
