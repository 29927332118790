@use "global"as g;

/*
  ---------------
  ご利用ガイド | 規約系ページ
  ---------------
*/
.guide-terms {

}

.guide-terms .guide-terms__wrap {
  @include g.container();
  padding-top: 120px;

  @media #{g.$sp} {
    padding-top: 80px;
  }
}

.guide-terms__title {
  font-size: 32px;
  font-weight: bold;

  @media #{g.$sp} {
    font-size: 24px;
  }
}

.guide-terms .guide-terms__block {
  padding-top: 20px;
}

.guide-terms .guide-terms__block + .guide-terms__block {
  padding-top: 40px;
}

.guide-terms .guide-terms__title-sub {
  border-bottom: 1px solid g.$border-gray;
  color: g.$text-green-primary;
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 20px;
  position: relative;
}

.guide-terms .guide-terms__title-sub::before {
  background: g.$border-green;
  content: '';
  display: block;

  height: 1px;
  width: 300px;

  position: absolute;
  bottom: -1px;
  left: 0px;
}

.guide-terms .guide-terms__title-detail {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.62;
  margin-top: 40px;
  padding: 0;
}

.guide-terms .guide-terms__title-sub +  .guide-terms__title-detail {
  margin-top: 20px;
}

.guide-terms .guide-terms__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.85;
  margin-top: 20px;
}

.guide-terms .guide-terms__title-sub + .guide-terms__text {
  margin-top: 28px;
}

.guide-terms .guide-terms__title-detail + .guide-terms__text {
  margin-top: 0;
}

.guide-terms .guide-terms__link {
  color: g.$text-green-primary;
  font-size: 14px;
  text-decoration: underline;
  padding: 0;
}

.guide-terms .guide-terms__link-pdf {
  display: block;
  color: g.$text-green-primary;
  font-size: 14px;
  margin-top: 8px;
  padding-left: 34px;
  position: relative;
  text-decoration: underline;
}

.guide-terms .guide-terms__link-pdf::before {
  background: url(/f/resources/images/common/icon_pdf.png) 50% / contain no-repeat;
  content: '';
  display: block;

  height: 30px;
  width: 30px;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
/*--------------------------------------------------
  個人情報保護方針
--------------------------------------------------*/
.guide-terms .guide-terms__privacy {
  padding-top: 20px;

  display: flex;
  align-items: flex-end;
  gap: 40px;
  justify-content: space-between;

  @media #{g.$sp} {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

.guide-terms .guide-terms__privacy-left {
  @media #{g.$sp} {
    width: 100%;

    display: flex;
    justify-content: flex-start;
  }
}

.guide-terms .guide-terms__privacy .guide-terms__text,
.guide-terms .guide-terms__privacy-contact .guide-terms__text {
  margin-top: 0;
}

.guide-terms .guide-terms__privacy-right .guide-terms__text {
  text-align: right;
}

.guide-terms .guide-terms__privacy-icon {
  padding-top: 10px;
  text-align: right;
}

.guide-terms .guide-terms__privacy-contact {
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  margin-top: 20px;
  margin-inline: auto;
  padding: 16px;

  max-width: 450px;
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

}

.guide-terms .guide-terms__privacy-contact .guide-terms__text {
  text-align: center;
}
/*--------------------------------------------------
特定商取引に基づく表記
--------------------------------------------------*/
.guide-terms.certainly table {
  padding-top: 20px;
}

.guide-terms.certainly tbody {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.guide-terms.certainly tr {
  @media #{g.$sp} {
    display: flex;
    flex-direction: column;
  }
}

.guide-terms.certainly th {
  text-align: left;
  vertical-align: top;
  width: 120px;
}

.guide-terms.certainly th,
.guide-terms.certainly td {
  line-height: 1.7;

  @media #{g.$sp} {
    width: 100%;
  }
}
/*--------------------------------------------------
公表事項
--------------------------------------------------*/
.guide-terms.public table {
  border-collapse: collapse;
  margin-top: 8px;
  width: 100%;
}

.guide-terms.public tbody {

}

.guide-terms.public tr {
  @media #{g.$sp} {
  }
}

.guide-terms.public th {
  background: g.$bg-gray-secondary;
  padding: 8px;
}

.guide-terms.public td {
  padding: 16px;
  text-align: left;

  max-width: 220px;
  width: 30%;

  @media #{g.$sp} {
    padding: 8px;
  }
}

.guide-terms.public td+td {
  width: 70%;

  @media #{g.$sp} {

  }
}

.guide-terms.public th,
.guide-terms.public td {
  border: 1px solid g.$border-gray;
  line-height: 1.7;
}

/*--------------------------------------------------
セキュリティについて
--------------------------------------------------*/

.guide-terms.security .guide-terms__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media #{g.$sp} {
    flex-direction: column;
  }
}

.guide-terms.security .guide-terms__flex .guide-terms__text {
  margin-top: 0;
  width: calc(100% - 170px);

  @media #{g.$sp} {
    margin-top: 20px;
    width: 100%;
  }
}

.guide-terms.security .globalsign_img__wrap {
  width: 128px;

  @media #{g.$sp} {
    width: 160px;
  }
}

.guide-terms {

}
