@use "global" as g;

/*
  ---------------
  Contributions common | みんなの投稿
  ---------------
*/
.p-contributions {

  &__title {
    color: g.$text-green-primary;
  }

  &__list {
    margin-top: 20px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.03562341vw;

    @media #{g.$pc} {
      grid-template-columns: repeat(4, 1fr);
      gap: 13px;
    }
  }

  &__item-link {
    display: inline-block;
  }

  &__btn {

    @media #{g.$sp} {
      text-align: center;
      max-width: 192px;
      margin: 17px auto 0;
    }
  }
}
