@use 'global' as g;

/*
  ---------------
  パートワーク購読の解約
  ---------------
*/
.account__unsubscribe_partwork {
  #outer {
    @include g.container;
  }

  h1 {
    @media #{g.$sp} {
      font-size: 20px;
    }
  }

  h4 {
    padding: 0;
  }

  .partwork-unsubscribe {
    @media #{g.$sp} {
      margin-top: 16px;
    }
  }

  .distribution .dstr-options {
    text-align: left;
  }

  // 各項目(刊行予定から解約、刊行予定、アンケート)のタイトル
  .form-partwork-unsubscribe h4,
  .form-partwork-unsubscribe .input-label .input-name,
  .unsubscribe-parcel .distribution-schedule .heading {
    font-size: 20px;
    font-weight: 500;

    @media #{g.$sp} {
      font-size: 18px;
    }
  }

  .form-partwork-unsubscribe .note {
    font-size: 11px;
    color: #666;
    margin-top: 0;
  }

  .unsubscribe-parcel .distribution > .heading {
    background-color: transparent;
    border-bottom: 1px solid g.$bg-gray-secondary;
    text-align: center;
    padding: 20px 0;

    @media #{g.$sp} {
      padding-top: 0;
    }
  }

  .unsubscribe-parcel .distribution > .heading .pwk-title,
  .unsubscribe-parcel .distribution > .heading .dstr-title {
    text-align: left;
  }

  .unsubscribe-parcel .distribution > .heading .pwk-title {
    @media #{g.$sp} {
      font-size: 22px;
    }
  }

  .unsubscribe-parcel .distribution > .heading .dstr-title {
    @media #{g.$sp} {
      padding-top: 0;
    }
  }

  .distribution .subs-addon-list {
    @media #{g.$sp} {
      margin-bottom: 0;
    }
  }

  .unsubscribe-parcel .parcel-label {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.44444444;
    margin-left: 5px;

    @media #{g.$sp} {
      font-size: 16px;
    }
  }

  .unsubscribe-parcel .distribution-schedule {
    border: 0;
    padding: 0;
    margin-top: 40px;
  }

  .unsubscribe-parcel .distribution-schedule .summary {
    border-bottom: 1px solid g.$bg-gray-secondary;
    padding-bottom: 12px;
  }

  .unsubscribe-parcel .distribution-schedule .summary > .label {
    display: block;
    width: 100%;
    padding-right: 0;
  }

  .unsubscribe-parcel .distribution-schedule .summary > .label label {
    cursor: pointer;
    width: 100%;

    display: flex;
    align-items: center;
  }

  .unsubscribe-parcel .distribution-schedule .summary > .label input[type='radio'] {
    accent-color: g.$bg-green-primary;
    width: 20px;
    height: 20px;
  }

  .unsubscribe-parcel .distribution-schedule .parcel .issues {
    background-color: transparent;
    margin-top: 0;
  }

  .unsubscribe-parcel .distribution-schedule .parcel .subscription-privileges {
    background-color: transparent;
    margin-top: 0;
  }

  .unsubscribe-parcel .distribution-schedule .parcel > .option-parcels {
    background-color: transparent;
  }

  .unsubscribe-parcel .distribution-schedule .parcel > .option-parcels .heading {
    font-size: 14px;
    font-weight: 500;
  }

  .unsubscribe-reason {
    margin-top: 40px;
  }

  .unsubscribe-reason .input-note {
    padding-top: 0;
  }

  .unsubscribe-reason .confirm-group {
    width: 100%;
  }

  .unsubscribe-reason .confirm-group .ip-cfm {
    background-color: g.$bg-gray-primary;
    border-radius: 2px;
    display: block;
    width: 100%;
    padding: 16px;

    @media #{g.$sp} {
      font-size: 14px;
      padding: 12px 16px;
    }
  }

  .unsubscribe-reason .confirm-group .ip-cfm.empty {
    display: none;
  }

  .form.form-confirm .unsubscribe-reason .input-control {
    flex-direction: column;
  }

  .unsubscribe-submit {
    text-align: center;
    margin-top: 30px;
  }

  .unsubscribe-submit .after-confirm {
    font-size: 0;
  }

  .unsubscribe-submit .btn-confirm {
    @include g.hoverButton;
    @include g.buttonConfirm;
  }

  .unsubscribe-submit .btn-back {
    font-size: 16px;
    font-weight: 700;
    min-width: 175px;
  }

  .unsubscribe-submit .btn-submit {
    @include g.buttonConfirm;

    @media #{g.$sp} {
      margin-left: 0;
    }
  }

  .unsubscribe-submit .btn-back,
  .unsubscribe-submit .btn-submit {
    @include g.hoverButton;
    min-height: 50px;
    margin: 10px .5em 0;
    vertical-align: middle;
  }
}
