@use "global" as g;

/*
  ---------------
  セレクトショップ 商品詳細 | 商品タイトル〜購入ボタンエリア
  ---------------
*/
.select-product .p-product__block {
  @media #{g.$pc} {
    padding-right: min(2.77777778%, 40px);
    padding-left: min(2.77777778%, 40px);
  }
}

.select-product .p-product__detail {
  min-width: 0;

  @media #{g.$tab-under} {
    margin-top: 40px;
  }
}

.select-product .p-product__detail-title {
  display: inline;
  font-weight: 700;
  margin-top: 6px;

  @media #{g.$sp} {
    margin-top: 4px;
  }
}

div.item-tags {
  display: inline-flex;
  vertical-align: 2px;
}

div.item-tags div.item-tag {
  border-radius: 0;
  padding: 0 4px 2px;
}

.select-product .p-product__detail-title-text {
  display: inline;
}

.select-product .p-product__detail-summary-wrap {
  margin-top: 30px;
}

.select-product .p-product__detail-summary-title {
  font-size: 18px;
}

.select-product .p-product__detail-summary {
  display: block;
  font-size: 14px;
  margin-top: 12px;
}

.select-product .p-product__detail-number {
  color: g.$text-black-secondary;
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 7px;

  @media #{g.$tab-under} {
    margin-top: 60px;
  }
}

// 金額エリア
.select-product .p-product__price-amount {

  @media #{g.$tab-over} {
    margin-top: 28px;
  }
}

.select-product .p-product__price-amount-price {
  font-size: 35px;
  font-weight: 700;
  line-height: 1.45714286;
}

.select-product .p-product__price-amount-tax {
  font-weight: 500;
}

.select-product .p-product__price-base {
  color: g.$text-black-secondary;
  font-size: 14px;
  line-height: 1.42857143;
}

.select-product .p-product__price-original {
  color: g.$text-black-secondary;
  font-size: 14px;
  line-height: 1.42857143;
  text-decoration: line-through;
  margin-top: 5px;
}

// 個別送料
.select-product .p-product__deliveryFee {
  margin-top: 12px;
}

.select-product .p-product__deliveryFee-header {
  font-size: 14px;
}

.select-product .p-product__deliveryFee-body {
  font-weight: 700;
  font-size: 18px;
}

.select-product .p-product__deliveryFee-whole {
  font-size: 14px;
  font-weight: 500;
}

// 獲得ポイント
.select-product .p-product__point {
  background-color: g.$bg-green-quaternary;
  border-radius: 2px;
  font-size: 12px;
  line-height: 1.41666667;
  margin-top: 14px;
  padding: 1px;

  display: inline-flex;
}

.select-product .p-product__point-header {
  border-radius: 2px;
  color: g.$text-white-primary;
  padding: 0 4px 1px 6px;
}

.select-product .p-product__point-body {
  background-color: g.$bg-white-primary;
  color: g.$text-green-quaternary;
  padding: 0 6px 1px;
}

// アイテムオプション ~ 今すぐ購入まで
.select-product .p-product__detail-purchase {
  margin-top: 50px;

  @media #{g.$sp} {
    margin-top: 30px;
  }

  .item-qty-item-wrap {
    margin-top: 40px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media #{g.$sp} {
      margin-top: 20px;
    }
  }

  .item-qty-note {
    color: g.$text-red-primary;
    margin-inline-end: 20px;

    @media #{g.$sp} {
      font-size: 14px;
      line-height: 1.42857143;
      margin-inline-end: 17px;
    }
  }

  // 数量
  .item-qty-item {
    display: flex;
    align-items: center;
  }

  .item-qty-label {
    font-size: 14px;
    line-height: 1.42857143;
    margin-inline-end: 15px;

    @media #{g.$sp} {
      margin-inline-end: 8px;
    }
  }

  .qty.item-qty {
    appearance: none;
    background: #F6F6F6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
    background-position: right 10px center;
    background-size: 24px;
    border: 0;
    border-radius: 2px;
    color: #1D1D1B;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    height: 50px;
    line-height: normal;
    padding-inline: 10px 29px;

    display: inline-flex;
    align-items: center;

    @media #{g.$sp} {
      font-size: 16px;
      height: 35px;
      padding-inline-end: 13px;
      width: 84px;
    }

    @media #{g.$pc} {
      width: 150px;
    }
  }

  // カートに入れる 今すぐ購入 wrapper
  .btn-group-purchase {
    font-size: 0;
    margin-top: 20px;

    display: flex;
    justify-content: flex-end;

    @media #{g.$sp} {
      width: 100%;
      margin-top: 10px;

      justify-content: space-between;
      gap: 2.54452926vw;
    }

    @media #{g.$pc} {
      gap: clamp(0px, 0.69444444vw, 20px);
    }
  }

  button.btn.add-item-to-cart,
  button.btn.checkout-item {
    margin-left: 0;
    border: 0;
    border-radius: 2px;
    color: g.$text-white-primary !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.33333333; // lh22px
    width: 50%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    @media #{g.$sp} {
      width: 100%;
    }
  }

  // カートに入れる
  .add-item-to-cart {
    background: #00ac5a;
    min-width: 178px;
    height: 50px;
    padding: 0 19px 0 47px;

    &::before {
      content: "";
      background: url("/f/resources/images/packtoy/list/icon_cart.svg") center / contain no-repeat;
      display: inline-block;
      width: 17px;
      height: 20px;

      position: absolute;
      top: 50%;
      right: auto;
      left: calc(50% - 66px);
      transform: translateY(-50%);

      @media #{g.$sp} {
        left: calc(50% - 67px);
      }
    }
  }

  // 今すぐ購入
  .checkout-item {
    background: #ff9c08;
    min-width: 178px;
    height: 50px;
    padding: 0 47px 0 19px;

    @media #{g.$sp} {
      min-width: auto;
    }

    &::before {
      content: "";
      border-radius: 100%;
      background: url("/f/resources/images/common/icon_arrow_right_bg_white.svg") center / contain no-repeat;
      display: inline-block;
      height: 24px;
      width: 24px;

      position: absolute;
      top: 50%;
      right: 13px;
      left: auto;
      transform: translateY(-50%);
    }
  }
}

// お届け
// 申込締切日
.select-product .p-product__info {
  border-top: 1px solid g.$border-gray;
  padding-top: 20px;
  margin-top: 30px;

  @media #{g.$sp} {
    padding-top: 15px;
    margin-top: 20px;
  }
}

.select-product .p-product__info-delivery,
.select-product .p-product__info-period {
  display: flex;
  align-items: center;
}

.select-product .p-product__info-delivery-header,
.select-product .p-product__info-period-header {
  background-color: g.$bg-gray-quinary;
  border-radius: 2px;
  color: g.$text-white-primary;
  font-size: 14px;
  line-height: 1.42857143;
  width: 80px;
  min-height: 30px;
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.select-product .p-product__info-delivery-body,
.select-product .p-product__info-period-body {
  margin-left: 10px;
}

.select-product .p-product__info-delivery-body-text,
.select-product .p-product__info-period-body-text {
  font-size: 16px;
  line-height: 1.4375;
}

.select-product .p-product__info-delivery-body-note,
.select-product .p-product__info-period-body-note {
  font-size: 12px;
  line-height: 1.41666667;
}

.select-product .p-product__info-period {
  margin-top: 6px;
}

// 商品概要エリア
.select-product .p-product__summary {
  margin-top: 30px;
}

.select-product .p-product__summary-title {
  font-size: 18px;
  line-height: 1.45833333;
}

.select-product .p-product__summary-description {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 6px;

  & + & {
    margin-top: 10px;
  }

  // 仕様欄 特殊なHTMLタグ
  r {
    color: red;
  }

  rb {
    color: red;
    font-weight: bold;
  }

  b {
    font-weight: bold;
  }
}
