@use "global"as g;

/*
  ---------------
  商品一覧 | 検索結果
  ---------------
*/
// 絞り込み条件


// 絞り込み検索結果(並べ替え機能下)
#itemlist .to-partwork-linkArea {
  margin-top: 20px;
}

#itemlist .to-partwork {
  width: 100%;
  height: auto;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;

  @media #{g.$sp} {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
}

#itemlist .to-partwork-link {
  // background-color: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  width: 100%;
  padding-right: 10px;

  display: flex;

  @media #{g.$sp} {
    padding-right: 2px;
  }
}

#itemlist .to-partwork-link img {
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 108px;
  height: 100%;
  max-height: 108px;

  @media #{g.$sp} {
    max-width: 60px;
    max-height: 60px;
  }
}

#itemlist .to-partwork-link .to-partwork-link-text {
  color: g.$text-green-primary;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4375;
  text-align: left;
  width: 100%;
  margin-left: 10px;
  padding-right: 10px;

  display: flex;
  align-items: center;
  position: relative;

  @media #{g.$sp} {
    font-size: 11px;
    margin-left: 6px;
    padding-right: 8px;
  }

  @media (max-width: 390px) {
    font-size: 10px;
  }
}

#itemlist .to-partwork-link .to-partwork-link-text::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_chevron_right.svg") center / cover no-repeat;
  display: inline-block;
  width: 6px;
  height: 10px;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
