@use "global" as g;

/*
  ---------------
  パックトイ | シリーズTOP
  ---------------
*/
#itemlist.is-flowpack ul.item-list li.item-list-each {}

// 商品画像
#itemlist.is-flowpack ul.item-list li.item-list-each .item-thumbnail-img img {
  border-radius: 30px;
}

#itemlist.is-flowpack ul.item-list li.item-list-each .item-dtl .item-tags .item-tag .c-itemtag {
  border-radius: 40px;
}

// 数量、カートに入れる、今すぐ購入
#itemlist.is-flowpack ul.item-list li.item-list-each select.item-qty,
#itemlist.is-flowpack ul.item-list li.item-list-each .add-item-to-cart,
#itemlist.is-flowpack ul.item-list li.item-list-each .checkout-item {
  border-radius: 30px;
}

#itemlist.is-flowpack ul.item-list li.item-list-each select.item-qty {
  cursor: pointer;
  padding-inline: 20px 29px;
}

// #itemlist.is-flowpack ul.item-list li.item-list-each form button {
//   @include g.hoverLink;
// }

// ページネーション
#itemlist.is-flowpack .pagination {}
#itemlist.is-flowpack .pagination ul li a {
  border-radius: 30px;
}

#itemlist.is-flowpack .pagination ul li.active a {
  color: g.$text-green-tertiary;
}
