@use "global" as g;
/*
  ---------------
  アドレス帳一覧・編集
  ---------------
*/
body#account__manage_addressbook {
  #page-title + .btn-group {
    padding-top: 40px;
  }

  .btn-group {
    padding: 0;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  .btn-group > div {
    padding: 0;
  }

  .btn-group > div:nth-child(3) {
    position: absolute;
    top: 0;
    right: 0;
  }

  .panel-footer .btn-group {
    justify-content: flex-end;

    @media #{g.$sp} {
      justify-content: center;
    }
  }

  .panel-list {
    padding-top: 40px;

    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    @media #{g.$sp} {
      flex-direction: column;
    }
  }

  .panel-list li {
    border: 1px solid g.$border-gray;
    display: flex;
    padding: 16px;
    width: calc((100% - 30px) / 2);

    @media #{g.$sp} {
      width: 100%;
    }
  }

  .panel {
    position: relative;

    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
  }

  .panel-body {
    margin-bottom: 0;
  }

  .person-contents {
    padding: 0;
  }
  .names {
    padding-right: 175px;
  }

  .addresses {
    @media #{g.$sp} {
      margin-top: 8px;
    }
  }
}

// 編集
body#account__edit_address {

  div.form fieldset {
    border-top: 0;
    padding: 0;
    margin: 0;
  }

  .form-body .input-group-address {
    margin-top: 40px;
  }

  fieldset + fieldset {
    margin-top: 0;
  }

  fieldset .formrow-checkbox {
    background: g.$bg-gray-primary;
    border-top: 1px solid g.$border-gray;
    padding: 40px;
  }
}
