@use "global"as g;

/*
  ---------------
  注文 | 共通
  ---------------
*/
// ers-dev.css 追加
/* 注文内容の確認 | Order Confirm */
#order__confirm #checkout_form .order-properties input {
  display: block;
}

#order__confirm #checkout_form .order-properties label::before {
  display: none;
}
// ers-dev.css ここまで

// 注文系ページ
body#cart,
body#order__complete,
body#order__confirm,
body#order__consignee,
body#order__deliveryservice,
body#order__itemoptions,
body#order__order_error,
body#order__orderer,
body#order__payment,
body#order__points,
body#order__sender {
  #outer {
    @include g.container();
    padding-top: 20px;
  }

  // 再ログイン
  .signin-form {
    margin-top: 10px;
  }

  .signup-form {
    padding-top: 40px;
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
    padding: 0;
  }

  .order-cancel-edit,
  .form-body>.addressbook {
    max-width: 100%;

    display: flex;
    justify-content: center;
  }

  .order-cancel-edit {
    padding: 0;
    margin-top: 12px;
  }

  .formrow-submit {
    padding-bottom: 0;
  }
}

// 金額テーブル
body#order__payment,
body#order__confirm {
  #order-total {
    max-width: 500px;

    th,
    td {
      font-size: 14px;
      border: none;
    }

    .inv-subtotal,
    .odr-ttl-amt,
    .total.odr-ttl-amt {
      border-bottom: 1px solid g.$border-gray;
    }

    .odr-ttl-amt {
      border-bottom: 2px solid g.$border-gray;
    }

    .odr-ttl-amt th,
    .odr-ttl-amt td {
      color: g.$text-black-primary;
      font-size: 24px;
      font-weight: 700;

      @media #{g.$sp} {
        font-size: 20px;
      }
    }

    .total.odr-ttl-amt th,
    .total.odr-ttl-amt td {
      color: g.$bg-green-primary;
      white-space: nowrap;
    }

    .amount {
      @media #{g.$sp} {
        white-space: nowrap;
      }
    }
  }
}

.order__orderer .orderer fieldset {
  margin-bottom: 0;
}

// 高さが足りないページ
.order__itemoptions,
body#order__order_error:not(:has(.signin-form)),
body#order__complete:not(:has(.signin-form)) {
  #outer {
    flex: 1 1 0;
  }

  .breadcrumbs {
    @media #{g.$pc} {
      margin-bottom: 100px;
    }
  }

  h1 {
    text-align: center;
  }

  .order-process-main-complete h1 {
    font-size: 20px;
    font-weight: 500;
  }

  .description {
    margin-top: 20px;
    text-align: center;
  }

  .main-message,
  .main-contents {
    margin-top: 16px;
    text-align: center;
  }

  .order-process-main-complete h1,
  .order-process-main-complete .main-message,
  .order-process-main-complete .main-contents {
    text-align: left;
  }

  .order-process-main-complete .main-contents {
    margin-top: 0;
  }

  .main-contents {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .view-order-detail a {
    color: g.$text-green-primary;
    text-decoration: underline;
  }

  .order-process-main-complete .view-order-detail a {
    text-decoration: none;
  }

  .order-error a {
    color: g.$text-green-primary;
    display: block;
    text-align: center;
    text-decoration: underline;
  }
}

// クーポン表示
#order__confirm {
  #detach-coupons {
    padding-block: 40px;

    @media #{g.$sp} {
      padding-block: 20px;
    }
  }

  .cpn-exp {
    display: flex;
  }

  #detach-coupons ul span.cpn-exp span.date {
    color: g.$text-red-primary;
  }
}

#apply-coupons {
  .wrapper .coupons {
    padding: 8px 8px 24px;

    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @media #{g.$sp} {
      gap: 8px 16px;
    }
  }

  .wrapper .coupon {
    background-color: g.$bg-gray-primary;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    outline: 1px solid g.$border-green;
    outline-offset: -4px;
    padding: 4px;
    position: relative;

    height: fit-content;
    max-width: 250px;

    display: grid;
    place-items: center;

    @media all and (max-width : 600px) {
      max-width: calc(50% - 8px);
      width: calc(50% - 8px);
    }
  }

  .wrapper .coupon::before {
    background: g.$bg-red-secondary;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    content: '';
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    padding: 4px 0;
    text-align: center;
    transform: rotate(-45deg);
    z-index: 1;
    width: 50px;

    position: absolute;
    left: -14px;
    top: 8px;
  }

  .wrapper .coupon .notation {
    color: g.$text-green-primary;
    font-size: 10px;
    padding: 0 8px 6px;
  }

  .btn-apply-coupon {
    border: none;
    color: g.$text-green-primary !important;
    font-weight: 500;

    height: 100%;
    min-width: 155px;
    width: 100%;
  }
}
// ステップ表示
.step-progress {
  padding-top: 20px;
  padding-bottom: 80px;

  display: flex;
  justify-content: center;

  @media #{g.$sp} {
    padding-top: 10px;
    padding-bottom: 40px;
  }
}