@charset "UTF-8";
/*
  ----------------
  Import all
  ----------------
*/
:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*-- n2karyon 2020  --*/
/*-- reset --*/
body {
  margin: 0;
  padding: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

ul li,
ol li {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* base style */
html {
  font-size: 14px;
}

body {
  font-size: 16px;
}

body #wrapper {
  display: flex;
  flex-direction: column;
}

body #wrapper #outer {
  margin-bottom: auto;
  width: 100%;
}

#outer,
#nav > div.contents-wrapper,
#footer > div.contents-wrapper {
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0.5em;
}

h1 {
  /*-- Page Title --*/
  font-size: 2rem;
}

h2 {
  /*-- Sub Titles --*/
  font-size: 1.5rem;
  padding: 0.5em 0;
}

h3 {
  /*-- Adhoc Title --*/
  font-size: 1.25rem;
}

h4 {
  /*-- Caption for panels and tables --*/
  font-size: 1em;
}

h5 {
  /*-- Titles in Small Panels --*/
  font-size: 1em;
}

h6 {
  /*-- Titles in Small Panels --*/
  font-size: 1em;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
  color: inherit;
}

.clickable {
  cursor: pointer;
}

span.badge {
  margin-left: 1em;
}

div.pop-msg {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(220, 220, 220, 0.9);
  border-bottom: 1px solid #ccc;
  color: #666;
  padding: 0.5em 1em;
  z-index: 9000;
}

div.alert {
  color: #c44;
  text-align: center;
  border: 1px solid #c44;
  padding: 0.5em 1em;
  background-color: rgba(255, 245, 245, 0.9607843137);
  margin: 1em 0;
}

/*-- Panel Common --*/
div.panel div.panel-heading {
  text-align: center;
}

div.panel div.panel-heading div.description {
  text-align: center;
  font-size: 0.75em;
  padding: 0.5em;
}

div.panel-heading + div.panel-body {
  margin-top: 2em;
}

div.panel-body {
  margin-bottom: 4em;
}

div.panel div.panel-body div.panel div.panel-heading {
  background-color: #eee;
  border: none;
}

/*-- Panel Common END --*/
ul.panels {
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  font-size: 12px;
}

ul.panels > li {
  flex: 1;
  min-width: 150px;
  margin: 0.5em;
}

ul.panels > li > h6 {
  text-align: center;
  font-weight: normal;
  padding: 0.5em;
  background-color: #eee;
  border-radius: 3px;
}

ul.panels > li > h6 + div {
  text-align: center;
  padding: 0.5em;
}

div.close-slide-menu {
  text-align: right;
  border-top: 1px solid #ccc;
  padding-top: 1em;
  margin-top: 1em;
}

div.open-slide-menu {
  padding: 0.5em;
  position: absolute;
  top: 0;
}

div.open-slide-menu-prepend {
  left: 0;
}

div.open-slide-menu-append {
  right: 0;
}

div.close-slide-menu button {
  font-size: 11px;
}

div.error {
  padding: 1em;
}

div.error span.message {
  color: #c00;
}

div.error ul.message-list {
  padding: 0.5em 1em;
  color: #c00;
  background-color: #ffebeb;
  text-align: left;
  border-radius: 3px;
  border: 1px solid #c00;
}

div.error ul.message-list li.msg {
  font-size: 14px;
  padding: 0.25em 0;
}

.price-overridden {
  text-decoration: line-through;
}

/*-- Item Search Box --*/
div.item-search {
  position: relative;
}

div.item-search div.sb-suggest {
  position: absolute;
  left: 0;
  z-index: 1000;
  width: 280px;
}

div.item-search div.item-search-suggest {
  background-color: #fff;
}

div.item-search div.item-search-suggest a {
  text-decoration: none;
}

div.item-search div.item-search-suggest ul {
  border: 1px solid #ccc;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
}

div.item-search div.item-search-suggest li.item-list-each {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

div.item-search div.item-search-suggest div.item-dtl {
  padding-left: 8px;
}

div.item-search div.item-search-suggest div.item-dtl div.name {
  font-size: 14px;
  font-weight: bold;
}

div.item-search div.item-search-suggest div.item-dtl div.summary {
  font-size: 11px;
}

div.item-search div.item-search-suggest div.view-more-items {
  text-align: right;
  font-size: 12px;
  padding: 8px;
}

/*-- Item Search Box End --*/
/*-- Item Tag base --*/
div.item-tags {
  display: flex;
  flex-wrap: wrap;
}

div.item-tags div.item-tag {
  padding: 0.25em 1em;
  margin: 0 0.5em 0.5em 0;
  background-color: #bbb;
  color: #fff;
  white-space: nowrap;
}

/*-- Item Tag base End --*/
/*-- Category List --*/
div.category-list div.category-list-desc {
  font-size: 0.75rem;
  padding: 1em 0;
}

div.category-list div.list-item {
  padding: 0.5em;
}

div.category-list div.list-item div.desc {
  font-size: 75%;
  padding-left: 1em;
}

div.category-list div.list-items div.category-name a {
  color: inherit;
  text-decoration: none;
}

div.category-list .active {
  font-weight: bold;
}

/*-- Category List End --*/
/*-- Item Search Filter --*/
div.item-search-filter .active {
  font-weight: bold;
}

/*-- Item Search Filter End--*/
/*-- Item with Thumbnail End--*/
/*-- ItemDetails --*/
div.itemdetail-row {
  display: flex;
  flex-wrap: nowrap;
}

div.itemdetail-row div.item {
  flex: 2;
}

div.itemdetail-row div.purchase-contents {
  flex: 1;
}

div.itemdetails-header div.itemdetail-row {
  align-items: center;
  text-align: center;
  justify-content: center;
}

div.itemdetails-body div.itemdetail-row {
  padding: 1em 0;
  border-bottom: 1px solid #999;
}

div.itemdetail-row div.item > div.control {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  white-space: nowrap;
  text-align: center;
}

div.itemdetail-row div.item div.item-thumbnail-img-wrapper {
  margin: 0;
}

div.itemdetail-row div.purchase-contents {
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

div.itemdetail-row div.purchase-contents > div {
  padding: 1em;
  flex: 1;
}

div.itemdetail-row div.purchase-contents > div.subtotal {
  text-align: right;
}

div.goods {
  display: flex;
  position: relative;
}

div.goods-text div.error-message {
  color: #c00;
  background-color: rgba(255, 245, 245, 0.9607843137);
  border: 1px solid #a00;
  padding: 2px 4px;
  margin: 0.5em 0;
}

div.goods-text-heading {
  display: flex;
  flex-direction: column-reverse;
}

div.goods-text div.unit-price {
  text-align: right;
}

div.goods-text div.unit-price span.price {
  display: block;
}

div.goods-text div.item-options {
  font-size: 12px;
}

div.goods-text div.paymentmethod-restriction {
  color: #c66;
  font-size: 11px;
}

div.goods-text div.paymentmethod-restriction > div {
  display: inline;
}

div.goods-text div.manufacturer {
  display: flex;
  font-size: 0.9rem;
}

div.goods-text div.manufacturer > div {
  margin-right: 1em;
}

div.goods-text span.rp-opt-conf {
  border: 1px solid #d51;
  color: #d51;
  padding: 1px 2px;
  border-radius: 3px;
}

div.goods span.rp-opt-next {
  display: block;
  color: #d51;
}

div.goods div.applied-promotions span.promotion {
  font-size: 12px;
  background-color: #fff;
  color: #f82;
  border: 1px solid #f82;
  border-radius: 2px;
  display: inline-block;
  padding: 1px 4px;
  margin: 2px 0;
}

div.goods div.applied-itemset span.itemset {
  display: inline-block;
  font-size: 12px;
  background-color: #f82;
  border: 1px solid #f82;
  color: #fff;
  border-radius: 2px;
  padding: 1px 4px;
  margin: 2px 0;
}

div.itemdetails div.msg-add-item {
  padding: 0.5em 0;
  font-size: 12px;
  color: #090;
}

div.move-item-wrapper ul.move-item-dst {
  margin: 1em 0;
  background-color: #f5f5f5;
  padding: 1em;
}

div.move-item-wrapper ul.move-item-dst > li {
  cursor: pointer;
  margin-bottom: 0.5em;
  padding: 0.5em 1em;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

div.move-item-wrapper ul.move-item-dst > li.inactive {
  background-color: #eee;
}

div.move-item-wrapper ul.move-item-dst > li.active,
div.move-item-wrapper ul.move-item-dst > li.inactive:hover {
  background-color: #fff;
}

div.move-item-wrapper ul.move-item-dst > li.active {
  border: 1px solid #090;
}

div.move-item-wrapper ul.move-item-dst > li:last-child {
  margin-bottom: 0;
}

div.move-item-wrapper ul.move-item-dst > li > div.label {
  color: #090;
  font-size: 90%;
}

div.move-item-wrapper ul.move-item-dst > li > div.note {
  font-size: 75%;
  color: #666;
}

div.move-item-wrapper ul.move-item-dst > li > div.note-person {
  display: flex;
}

div.move-item-wrapper ul.move-item-dst > li > div.note > div.items-summary ul > li > span {
  padding: 0 1em;
}

div.move-item-wrapper div.note {
  padding: 0 1em;
  color: #e70;
}

div.move-item-wrapper div.submit-button {
  padding: 1em 0;
  text-align: center;
}

div.move-item-qty-select {
  text-align: center;
  color: #fff;
  font-size: 12px;
  background-color: #0c0;
  border: 1px solid #090;
  border-radius: 4px;
  padding: 0.5em;
  overflow: hidden;
}

div.move-item-qty-select > span {
  display: inline-block;
  padding: 0.5em;
}

div.move-item-qty-select > select {
  border: none;
}

div.btn-moveitem button {
  background: linear-gradient(#0d0, #0c0);
  color: #fff !important;
  border: 1px solid #090;
  margin: 1em;
}

div.submit-moveitem {
  text-align: center;
}

div.submit-moveitem button {
  font-size: 1.5rem;
}

/*-- ItemDetails End --*/
/*-- Total Price --*/
div.price-total {
  padding: 1em 0;
  max-width: 400px;
  margin-left: auto;
  font-size: 1.2rem;
  font-weight: bold;
}

div.price-total div.row {
  display: flex;
}

div.price-total div.qty {
  padding: 0 1em;
}

div.price-total div.subtotal {
  color: #a00;
  margin-left: auto;
}

/*-- Total Price End --*/
/*-- EntryItems Table Row --*/
div.present-applied {
  padding: 2em 1em;
}

div.present-applied div.caption {
  text-align: left;
  color: #0a0;
}

div.present-applied div.present-list ul {
  padding: 0;
}

div.present-applied div.present-list ul > li {
  background-color: #eee;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 0 0.5em 0.5em 0;
}

div.present-applied div.present-list ul > li div.present {
  padding: 0.5em;
}

div.present-applied div.present-list ul > li div.present div.description {
  font-size: 75%;
  color: #999;
}

div.present-applied div.present-list ul > li div.present div.present-option {
  font-size: 75%;
}

div.present-applied div.present-list ul > li div.present span.present-option-value {
  margin: 0 0.5em;
}

/*-- EntryItems Table Row End --*/
/*-- Order History --*/
ul.order-list li {
  padding: 0 0 2em;
}

ul.order-list li > div.panel {
  border: 1px solid #ccc;
}

div.panel-order-smr div.panel-heading {
  display: flex;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
}

div.panel-order-smr div.panel-heading > div {
  flex: 1;
  padding: 0.5em;
}

div.panel div.panel-body div.ordersmr-shipment div.panel-heading {
  background-color: transparent;
  display: block;
}

div.panel div.panel-body div.ordersmr-shipment div.panel-heading div.shipment-code {
  padding: 0;
  font-size: 2rem;
}

div.panel div.panel-footer {
  display: flex;
  flex-wrap: wrap;
}

div.panel div.panel-footer > div {
  flex: 1;
  min-width: 200px;
  text-align: center;
  padding: 1em;
}

div.panel div.panel-footer > div div.note {
  font-size: 0.9rem;
}

div.extra-controls {
  text-align: center;
}

div.order-history-extra-btns > div {
  padding: 4px;
  text-align: center;
}

div.order-history-extra-btns .btn,
div.order-history-extra-btns .btn:hover {
  font-size: 0.8rem;
}

/*-- Order History End --*/
/*-- Item -- */
h1.item-name,
h2.item-name {
  margin: 0;
  background-color: inherit;
  color: inherit;
  text-shadow: none;
}

span.item-name-and-code span.item-code {
  font-size: 12px;
  font-weight: normal;
}

#item-contents-main {
  display: flex;
  flex-wrap: wrap;
}

#item-contents-image {
  position: relative;
}

#item-contents-purchase {
  min-width: 300px;
}

.item-purchase {
  margin-bottom: 1em;
  text-align: left;
}

.item-purchase .purchase-item-prices {
  text-align: right;
}

form.item-purchase {
  text-align: right;
  padding: 1em 0 2em;
}

.item-purchase div.exp-promotion-prices {
  text-align: right;
}

.item-purchase div.exp-promotion-prices ol li {
  margin-left: auto;
}

.item-purchase div.exp-promotion-prices ol li > div {
  display: inline-block;
}

.item-purchase .promotion-price span.applied-promotion {
  color: #444;
  border: 1px solid #444;
  font-size: 12px;
  background-color: #fff;
  padding: 1px 2px;
  border-radius: 2px;
  white-space: nowrap;
}

.price-label {
  padding-right: 1em;
}

.regular-price {
  font-size: 0.8em;
}

.item-purchase .selling-price .price {
  font-weight: bold;
  white-space: nowrap;
}

.item-purchase .promotion-price .price {
  font-weight: bold;
}

.selling-price .price,
.final-price .price {
  color: #a00;
}

div.final-price span.price {
  font-size: 1.25em;
}

.overridden {
  text-decoration: line-through;
}

div.stock-message {
  font-size: 14px;
  text-align: left;
}

div.stock-message-enough {
  color: #090;
}

div.stock-message-low {
  color: #f73;
}

.item-purchase div.stock-message-none {
  color: #c00;
}

.item-purchase div.delivery-message {
  font-size: 12px;
  color: #977;
}

.item-option-ctrl .item-option-out-of-stock {
  color: #c00;
  font-size: 11px;
  border: 1px solid #c00;
  padding: 2px;
  border-radius: 2px;
}

table.item-purchase-table {
  width: 100%;
  font-size: 90%;
  border-collapse: collapse;
  margin: 2em 0;
}

table.item-purchase-table th {
  border: 1px solid #ccc;
  padding: 8px;
  white-space: nowrap;
  background-color: #eee;
  text-align: center;
}

table.item-purchase-table td {
  border: 1px solid #ccc;
  padding: 8px;
}

table.item-purchase-table td.summary div.summary-txt {
  padding: 8px;
  text-align: center;
}

table.item-purchase-table td.purchase button.btn {
  font-size: inherit;
}

table.item-purchase-table td.purchase form {
  text-align: center;
}

div.item-thumbnail-img-wrapper {
  margin: 0 auto;
}

div.item-thumbnail-img {
  margin: 0 auto;
}

div.item-thumbnail-img a:has(.no-img) {
  display: inline;
}

div.item-thumbnail-img img {
  width: 100%;
  overflow-y: hidden;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(127, 127, 127, 0.3);
}

div.item-thumbnail-img div.no-img {
  height: 100%;
  border: 1px solid #ccc;
  background-color: #eee;
  box-shadow: 0 2px 4px rgba(127, 127, 127, 0.3);
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.item-thumbnail-img-xs {
  width: 75px;
  height: 75px;
}

div.item-thumbnail-img-sm {
  width: 150px;
  height: 150px;
}

div.item-thumbnail-img-md {
  width: 240px;
  height: 240px;
}

div.item-thumbnail-img-lg {
  width: 300px;
  height: 300px;
}

.item-image-main {
  margin: 0;
  width: 300px;
  max-height: 300px;
  overflow: hidden;
  position: relative;
}

.item-image div.item-tags {
  position: absolute;
  top: 2px;
  left: 2px;
}

.item-image-subs {
  padding: 0.5em 0;
  display: flex;
  flex-wrap: wrap;
}

.item-image-sub .item-thumbnail-img {
  margin: 4px 4px 0 0;
  width: 74px;
  height: 74px;
  line-height: 70px;
  overflow: hidden;
}

div.item-add-favorite,
div.item-add-itemrequest {
  text-align: right;
  padding: 0.25em 0;
}

div.item-add-favorite button.btn-add-favorite-item,
div.item-add-itemrequest button.btn-add-itemrequest {
  font-size: 1rem;
  background: #aaa;
  border: 1px solid #999 !important;
  box-shadow: none;
  color: #fff !important;
  border: none;
}

div.item-add-favorite button.btn-remove-favorite-item,
div.item-add-itemrequest button.btn-remove-itemrequest {
  font-size: 90%;
  width: 12em;
  background: #ccc;
  border: 1px solid #999 !important;
  box-shadow: none;
  color: #fff !important;
  border: none;
}

div.item-variation ul li {
  display: inline-block;
  padding: 0.5em;
}

div.item-variation ul li > div.item {
  text-align: center;
  width: 100px;
}

div.item-variation ul li > div.item div.item-text {
  padding: 0.5em 0;
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

form.item-purchase div.item-options {
  text-align: left;
  width: 100%;
}

form.item-purchase div.item-options div.item-option {
  padding: 1em;
  margin: 1em 0;
  border: 1px solid #ccc;
}

form.item-purchase div.item-options div.item-option-name {
  font-size: 0.8rem;
  font-weight: bold;
}

form.item-purchase div.item-options div.item-option-dsc {
  font-size: 0.8rem;
  color: #999;
}

form.item-purchase div.item-options div.item-option-ctrl {
  padding: 0.5em 0;
}

form.item-purchase div.item-options div.item-option-ctrl select,
form.item-purchase div.item-options div.item-option-ctrl input[type=text] {
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  margin-bottom: 0.5em;
}

/*-- Item End -- */
/*-- Item List Common--*/
ul.item-list li.item-list-each {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 1em 0;
  border-bottom: 1px dotted #ccc;
}

ul.item-list li.item-list-each:last-child {
  border-bottom: none;
  padding-bottom: 4em;
}

ul.item-list li.item-list-each div.item-dtl {
  width: 90%;
  margin: 0 auto;
}

ul.item-list li.item-list-each div.item-dtl div.article-prices {
  text-align: right;
  padding: 1em 0;
  font-size: 12px;
}

/*-- Item List Common End--*/
/*-- Category Item List --*/
div.category-item-list div.item-list-heading {
  display: flex;
  flex-wrap: wrap;
  padding: 1em 0 2em;
}

div.category-item-list div.item-list-heading > div.page-position {
  margin-left: auto;
  color: #888;
}

/*-- Category Item List End --*/
/*-- CartItem Reponse --*/
div.cart-add-item-resp {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1500;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

div.cart-add-item-resp > div {
  width: 90%;
}

div.cart-add-item-resp div.checkout-cart {
  padding: 1em;
  background-color: #fff;
  text-align: left;
  position: relative;
}

div.cart-add-item-resp div.checkout-cart > ul {
  height: 300px;
  overflow-y: scroll;
}

div.cart-add-item-resp div.checkout-cart > ul > li {
  display: flex;
  padding: 0.5em 0;
}

div.cart-add-item-resp div.checkout-cart > ul > li div.item-thumbnail-img-wrapper {
  flex: 1;
}

div.cart-add-item-resp div.checkout-cart > ul > li div.cart-item {
  flex: 2;
}

div.cart-add-item-resp div.checkout-cart > ul > li div.cart-item span.error {
  color: #c00;
}

div.cart-add-item-resp div.checkout-cart div.total {
  text-align: right;
}

div.cart-add-item-resp div.checkout-cart div.controls {
  text-align: right;
}

div.cart-add-item-resp div.checkout-cart div.closer {
  position: absolute;
  top: 0.5em;
  right: 1em;
}

/*-- CartItem Reponse End --*/
/*-- SignIn/Up --*/
div.signin-form div.reminder {
  text-align: right;
  font-size: 12px;
  padding: 0 2em;
}

/*-- SignIn/Up End --*/
/*-- Order Entry --*/
div.order-process-main {
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

#order-entry div.panel {
  border: 1px solid #ccc;
  margin: 0.5em;
  position: relative;
}

#order-entry div.panel div.header {
  background-color: #eee;
  padding: 1em;
}

#order-entry div.panel div.body {
  position: relative;
  padding: 1em;
}

#order-entry div.panel .control {
  position: absolute;
  top: 0.75em;
  right: 1em;
  text-align: right;
}

#order-entry div.panel .control .btn {
  padding: 4px 8px;
  margin: 0 0 4px;
}

#order-entry div.itemdetails {
  padding: 1em;
}

#order-entry div.itemdetails div.itemdetails-caption {
  padding: 0 1em;
}

#order-entry div.itemdetails div.update-itemoptions {
  text-align: right;
}

#order-entry div.itemdetails div.update-itemoptions a {
  color: #38c;
  text-decoration: underline;
}

#order-entry div.panel .footer {
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
}

#order-entry #order-entry-order {
  display: flex;
  flex-wrap: wrap;
}

#order-entry #order-entry-order div.panel {
  flex: 1;
  min-width: 320px;
}

#order-entry #order-entry-order div.payment {
  flex: 1;
}

#order-entry div.shipments div.shipment-entry-adr {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4px;
}

#order-entry div.shipments div.shipment-entry-adr div.consignee {
  flex: 1;
}

#order-entry div.shipments div.shipment-entry-adr div.sender {
  flex: 1;
}

#order-entry .shipment ul.delivery-service-conf {
  margin: 0 1em;
}

#order-entry .shipment ul.delivery-service-conf li h4 {
  display: block;
  flex: 1;
}

#order-entry .shipment ul.delivery-service-conf li h4 + div {
  flex: 2;
  padding-left: 1em;
}

#order-entry .shipment .update-present-option {
  position: relative;
  height: 2em;
}

#order-entry div.checkout {
  padding: 4em 1em 4em;
  text-align: center;
}

#order-entry div.order-cancel-edit {
  text-align: right;
}

div.main-payment-method div.wrapper {
  max-width: 620px;
  margin: 0 auto 2em;
}

div.main-payment-method div.method-name {
  font-size: 150%;
  padding: 1em;
  width: 300px;
  border-radius: 4px;
  margin: 0 auto;
}

div.main-payment-method div.wrapper > div {
  text-align: center;
}

div.payment-method-list {
  position: relative;
}

div.payment-method-list div.payment-method {
  padding: 8px;
}

div.payment-method-list div.payment-method div.pm-wrapper {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-label {
  font-size: 12px;
  font-weight: bold;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-heading div.pm-submit {
  text-align: center;
  padding: 8px;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-form {
  padding-bottom: 2em;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-body div.pm-description {
  font-size: 12px;
  padding: 1em;
  line-height: 1.5;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-trailing {
  text-align: center;
}

button.btn-payment {
  width: 300px;
  padding: 1em;
  margin: 0.5em;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-use-as-default {
  font-size: 12px;
}

div.order-cancel-edit {
  text-align: right;
  padding: 1em;
  font-size: 14px;
}

div#detach-coupons {
  text-align: right;
}

div#detach-coupons h3 {
  text-align: left;
  font-size: inherit;
  margin-bottom: 0.5em;
}

div#detach-coupons div.wrapper {
  background-color: #f3f3f3;
  border-radius: 6px;
  padding: 0.5em 1em;
  max-width: 400px;
  margin-left: auto;
}

div#detach-coupons ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

div#detach-coupons ul span.cpn-exp span.date {
  padding-left: 1em;
  color: #f90;
}

div.select-delivery-service ul.ds-available {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

div.select-delivery-service ul.ds-available li {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0.5em 1em;
  width: 300px;
  text-align: center;
}

div.select-delivery-service ul.ds-available li label {
  display: block;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.5em 1em;
}

div.select-delivery-service ul.ds-available li label span.ds-avl-fat {
  display: block;
  font-size: 11px;
  color: #666;
}

div.select-delivery-service ul.ds-available li div.ds-desc {
  padding: 1em;
  background-color: #fff;
  font-size: 11px;
  text-align: left;
}

/*-- Order Entry End --*/
/*-- Amount Table --*/
div.amount-table {
  max-width: 400px;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: 0;
  padding: 0.5em 1em;
  background-color: #fff;
  border-radius: 2px;
}

@media all and (max-width : 768px) {
  #order-total .amount-table {
    padding-inline: 0;
  }
}

div.amount-table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

div.amount-table th {
  text-align: left;
  font-weight: normal;
}

div.amount-table td {
  font-weight: bold;
  padding: 0.5em 0;
}

div.amount-table th,
div.amount-table td {
  border-bottom: 1px solid #999;
}

div.amount-table td.amount {
  text-align: right;
}

div.amount-table .service-payment_commission .service-label {
  display: none;
}

div.amount-table tr.total {
  color: #a00;
}

div.amount-table div.msg-paymentfee-excluded {
  font-size: 12px;
  color: #c00;
  margin-top: 8px;
}

/*-- Amount Table End --*/
/*-- PG Launcher  --*/
div.pg-auth-launcher-note {
  padding: 1em;
  color: #e73;
}

div.launcher-form {
  padding: 1em 0 2em;
  text-align: center;
}

/*-- PG Launcher End --*/
/*-- Bread Crumbs -- */
div.breadcrumbs {
  margin-bottom: 1em;
}

div.breadcrumbs > ul {
  padding: 0;
}

div.breadcrumbs > ul li {
  display: inline-block;
}

div.breadcrumbs > ul li.parent:before,
div.breadcrumbs > ul li.end:before {
  content: " > ";
}

/*-- Bread Crumbs End -- */
/*-- Well --*/
div.wl h1 + div,
div.wl h2 + div,
div.wl h3 + div,
div.wl h4 + div,
div.wl h5 + div,
div.wl h6 + div {
  padding: 0 1em 1em;
}

div.wl h1 {
  font-size: 300%;
}

div.wl h2 {
  font-size: 250%;
}

div.wl h3 {
  font-size: 200%;
}

div.wl h4 {
  font-size: 175%;
}

div.wl h5 {
  font-size: 150%;
  font-weight: bold;
}

div.wl div.wl-img {
  width: 40%;
  margin: 1em auto;
  padding: 4px;
  border: 1px solid #eee;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
}

div.wl div.wl-img-left {
  float: left;
}

div.wl div.wl-img-right {
  float: right;
}

div.wl div.wl-img img {
  width: 100%;
}

div.wl h2,
div.wl h3,
div.wl h4 {
  clear: both;
}

/*-- Well End --*/
/*-- for slick slider -- */
div.sc-carousel-slider {
  padding: 0 0 40px;
  width: 900px;
  margin: 0 auto;
}

div.sc-carousel-slider div.sc-carousel-slick {
  display: none;
  height: 420px;
}

div.sc-carousel-slider div.sc-carousel-slick.slick-initialized {
  display: block;
}

div.sc-carousel-slider ul.sc-carousel-slick-nav li img {
  height: 80px;
  margin: 0 auto;
}

div.sc-carousel-slider {
  width: 100%;
}

/*-- for slick slider end-- */
/*-- for Journal History -- */
div.order div.panel div.panel-heading {
  padding: 8px 16px;
}

div.order div.panel div.panel-body div.delivery > div {
  padding-bottom: 4px;
}

div.order div.panel div.panel-body div.delivery > div > h6 {
  display: inline-block;
  min-width: 120px;
  padding-right: 1em;
}

div.order div.panel div.panel-body div.delivery > div > div {
  display: inline-block;
}

/*-- for Journal History end-- */
/*-- AC My Coupon List --*/
div.yourcoupons {
  padding: 1em 0;
}

div.yourcoupons div.leading {
  text-align: center;
  border-bottom: 1px solid #ccc;
  font-size: 18px;
}

div.yourcoupons div.yourcoupon-list {
  display: flex;
}

div.yourcoupons div.coupon {
  padding: 4px;
  position: relative;
}

div.yourcoupons div.coupon div.coupon-props {
  border: 1px solid #999;
  border-radius: 4px;
}

div.yourcoupons div.coupon div.coupon-props div.label {
  text-align: center;
  padding: 4px 8px;
}

div.yourcoupons div.coupon div.coupon-props span.code {
  display: inline-block;
  background-color: #0c0;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
}

div.yourcoupons div.coupon div.coupon-props div.expiry {
  text-align: right;
  font-size: 12px;
  padding: 2px 8px;
  color: darkorange;
}

div.yourcoupons div.coupon div.coupon-props div.description {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.3;
  color: #666;
}

div.yourcoupons div.coupon-clearing-date {
  text-align: center;
  padding: 2px;
}

div.yourcoupons div.coupon-clearing-date span {
  font-size: 11px;
  color: #900;
  background-color: #fee;
  border: 1px solid #966;
  display: inline-block;
  padding: 2px 1em;
  border-radius: 4px;
}

/*-- AC My Coupon List End--*/
/*-- Present Select --*/
div.form-present div.present-list > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

div.form-present div.present-list > ul li {
  padding: 0 0.5em;
  margin: 0.5em;
  max-width: 400px;
  min-width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
}

div.form-present div.present-list div.present {
  padding: 1em;
}

div.form-present div.present-list div.present div.property {
  padding: 1em;
}

div.form-present div.present-list div.present div.property span.code {
  color: #999;
  padding: 0 0.5em;
  border: 1px solid #999;
  border-radius: 2px;
  margin: 0 1em;
}

div.form-present div.present-list div.present div.property span.name {
  display: inline;
  font-size: 110%;
}

div.form-present div.present-list div.present div.property div.description {
  padding: 1em 0;
  font-size: 90%;
  color: #996;
}

div.form-present div.present-list div.present div.present-option div.no-option {
  color: #ccc;
  font-size: 90%;
}

/*-- Present Select End--*/
/*-- Points History --*/
div.points-history div.points-summary {
  display: flex;
}

div.points-history div.points-summary div.points-amount {
  flex: 1;
  text-align: center;
}

div.points-history div.points-summary div.points-amount div.wrapper {
  padding: 1em;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 6px;
}

div.points-history div.points-summary div.points-amount-available div.wrapper span.value {
  color: #0c0;
}

table.points-detail {
  width: 90%;
  margin: 1em auto;
  border-collapse: collapse;
}

table.points-detail th {
  font-weight: normal;
  border-bottom: 1px solid #999;
}

table.points-detail td {
  border-bottom: 1px solid #ccc;
  padding: 0.5em;
  text-align: center;
}

table.points-detail td span.pd-type {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 2px 4px;
}

table.points-detail td.pdd-type,
table.points-detail td.pd-note {
  text-align: left;
}

table.points-detail tr.pd-type-confirmed span.pd-type {
  border-color: #0c0;
  color: #0c0;
}

table.points-detail tr.pd-type-pending span.pd-type {
  border-color: #f90;
  color: #f90;
}

table.points-detail tr.pd-type-redeemed span.pd-type {
  border-color: #c00;
  color: #c00;
}

table.points-detail tr.pd-type-redeemed td.pd-amount {
  color: #c00;
}

table.points-detail tr.pd-type-redeemed td.pd-amount span:before {
  content: "-";
}

/*-- Points History End--*/
/*-- RP Subscriptions --*/
div.rp-subscriptions div.panel div.panel-body div.wrapper {
  display: flex;
  flex-wrap: wrap;
}

div.rp-subscriptions div.panel div.panel-body div.wrapper > div {
  min-width: 200px;
  flex: 1;
}

div.rp-subscriptions div.panel div.panel-body div.message-wrapper {
  padding: 1em 0.5em;
  text-align: center;
}

div.rp-subscriptions div.panel div.panel-body div.message-wrapper div.error {
  border: 1px solid #c99;
  color: #c44;
  background-color: #fee;
  padding: 1em;
  display: inline-block;
  border-radius: 4px;
}

div.rp-subscriptions div.panel div.panel-footer span.note {
  font-size: 90%;
  color: #944;
}

div.rp-subscriptions-nav-list ol {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 0.5em 0;
}

div.rp-subscriptions-nav-list ol li > * {
  margin: 0 0.5em;
  padding: 0.5em 1em;
  border: 1px solid #ccc;
  background-color: #eee;
}

div.rp-subscriptions-nav-list ol li.active > span {
  background-color: #fff;
  border-bottom: 1px solid #fff;
}

/*-- RP Subscriptions End --*/
/*-- Account Message History --*/
div.account-message-history div.message-body {
  white-space: pre-wrap;
  max-height: 20em;
  overflow-y: scroll;
}

/*-- Account Message History End --*/
/*-- Display --*/
div.display-box ul.item-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

div.display-box ul.item-display li {
  padding: 0 1em 1.5em;
}

div.display-box ul.item-display li > div {
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
}

div.display-box ul.item-display li > div.text {
  white-space: nowrap;
}

div.display-box ul.item-display li div.display-xs {
  width: 100px;
}

div.display-box ul.item-display li div.display-sm {
  width: 152px;
}

div.display-box ul.item-display li div.display-md {
  width: 242px;
}

div.display-box ul.item-display li div.display-lg {
  width: 302px;
}

div.display-box ul.item-display li div.item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5em 0;
}

div.display-box ul.item-display li div.item-desc {
  font-size: 0.75em;
}

div.display-box ul.item-display li div.thumbnail {
  width: 150px;
}

div.display-box ul.item-display li div.catalogue {
  max-width: 400px;
}

/*-- Display End--*/
/*-- News Entry (List,Archive) --*/
div.news-entries div.news-entry-with-icon {
  border-top: 1px solid #ccc;
  padding-top: 1em;
  padding-bottom: 1em;
}

div.news-entries div.news-entry-has-body-link:hover {
  cursor: pointer;
}

div.news-entries div.news-entry {
  display: flex;
  border-top: 1px solid #ccc;
}

div.news-entry div.entry-icon.inline {
  float: left;
  padding: 0 1em 0.5em 0;
}

div.news-entry div.entry-icon span.icon img {
  width: 150px;
}

div.news-entry div.entry-icon span.no-image {
  display: block;
  width: 150px;
  height: 150px;
  border: 1px solid #ccc;
  background-color: #fefefe;
  text-align: center;
  line-height: 150px;
}

div.news-entry div.entry-icon span.no-image:after {
  content: "No Image";
  display: block;
  color: #999;
  font-size: 14px;
  font-family: Arial;
}

div.news-entries div.news-entry div.entry-text {
  padding: 0 1em;
}

div.news-entries div.header {
  display: flex;
  line-height: 24px;
}

div.news-entries div.header div.meta {
  display: flex;
}

div.news-entries div.header div.meta div.release-date {
  margin-right: 1em;
}

div.news-entries div.header div.news-type span {
  margin: 0 auto;
  background-color: #666;
  color: #fff;
  padding: 0.25em 0.5em;
  white-space: nowrap;
  margin-right: 1em;
}

div.news-entries div.body {
  padding: 1em 0;
  font-size: 90%;
  line-height: 175%;
}

div.news-images {
  display: flex;
}

div.news-images div.news-image {
  max-width: 300px;
  padding: 0.5em;
}

div.news-images div.news-image img {
  max-width: 100%;
}

/*-- News Entry (List,Archive) End --*/
/*-- News Entry (Archive) --*/
div.news-archive div.news-entry div.news-entry-subject {
  font-size: 28px;
}

div.news-archive div.news-entry div.release-date {
  color: #666;
}

/*-- News Entry (Archive) End--*/
/*-- Item Review --*/
/*-- Item Review  ----------------------------------------------------------------------*/
.item-reviews .progress-bar {
  background: linear-gradient(#fc9, #FFA32A);
}

.rv-stars {
  margin-right: 1em;
  color: #FFA32A;
  text-shadow: 1px 2px 4px rgba(255, 64, 0, 0.4);
}

.rv-star {
  font-size: 14px;
}

.rv-star-fill:before {
  content: "★";
}

.rv-star-empty:before {
  content: "☆";
}

.item-reviews {
  clear: both;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1em;
  margin: 1em 0;
}

.customer-review {
  border-top: 1px solid #ccc;
  padding: 1em 2em;
}

.customer-review > .heading {
  height: 24px;
}

.customer-review > .heading > div {
  float: left;
  height: 24px;
  line-height: 24px;
}

.customer-review .heading .reviewer {
  color: #999;
  font-size: 80%;
}

.customer-review .heading .subject {
  font-weight: bold;
  margin-right: 1em;
}

.customer-review .heading .written {
  margin-left: 2em;
  color: #999;
  font-size: 80%;
}

.customer-review .heading .item-link {
  padding-left: 1em;
}

.customer-review .content {
  font-size: 90%;
  word-wrap: break-word;
}

.customer-review div.images {
  display: flex;
  flex-wrap: wrap;
}

.customer-review div.images div.image {
  padding: 4px;
}

.customer-review div.control {
  display: flex;
}

.customer-review div.control > div {
  padding: 0.5em;
}

.rv-summary {
  float: left;
}

.review-avg {
  color: #99b;
}

.review-count {
  color: #99b;
}

.rv-histgram {
  padding-left: 220px;
}

.rv-histgram-each {
  position: relative;
  height: 21px;
}

.rv-histgram-each .rate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
}

.rv-histgram-each .count {
  position: absolute;
  top: 0;
  left: 100px;
}

.rv-histgram-each .percentage {
  position: absolute;
  top: 0;
  left: 120px;
  width: 100px;
}

.write-review .article {
  margin: 0 1em;
  float: left;
  width: 200px;
}

.write-review-form .rv-stars {
  font-size: 200%;
  cursor: pointer;
}

.write-review-form #rrl {
  font-size: 14px;
}

.review-smr {
  /*-- Review Summary in ItemList --*/
}

.review-smr div.count {
  font-size: 75%;
  color: #999;
}

/*-- Item Review End --*/
div.item-review-comments ul.comments > li div.comment {
  padding-left: 1em;
  padding-bottom: 1em;
}

div.item-review-comments ul.comments > li div.comment > div.heading {
  display: flex;
  align-items: center;
}

div.item-review-comments ul.comments > li div.comment > div.heading div.date {
  margin-left: 1em;
}

div.poster div.nickname {
  display: flex;
  align-items: center;
}

div.poster div.avatar img {
  width: 50px;
}

/*-- Person --*/
div.person {
  margin: 1em 0;
  padding: 0;
  border: 1px dotted #ccc;
}

div.person-label {
  font-weight: 700;
  border-bottom: 1px dotted #ccc;
  background-color: #f5f5f5;
  padding: 0.5em;
}

div.person-contents {
  padding: 0.5em;
}

div.person-contents div.kana,
div.person-contents div.place,
div.person-contents div.phone-number,
div.person-contents div.email {
  font-size: 80%;
}

div.person-contents div.locale,
div.person-contents div.kana {
  color: #ccc;
  font-style: oblique;
}

div.person-contents div.place > div {
  display: inline;
}

/*-- Person END --*/
/*-- Order --*/
div.panel-order div.panel-heading {
  display: flex;
  background-color: #f5f5f5;
}

div.panel-order div.panel-heading > div {
  flex: 1;
}

span.status {
  border: 1px solid #444;
  padding: 2px 4px;
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
}

span.status-canceled {
  border-color: #e00;
  color: #e00;
}

span.status {
  border-color: #09f;
  color: #09f;
}

.order-status-COMPLETED span.status,
.shipment-status-COMPLETED span.status {
  border-color: #0c0;
  color: #0c0;
}

div.shipment-header {
  text-align: center;
}

div.shipment-header > div {
  margin: 4px;
  border-radius: 3px;
}

div.shipment-delivery {
  margin-top: 1em;
  border-top: 1px solid #ccc;
  padding: 1em 0;
}

div.shipment {
  padding-bottom: 2em;
  margin-bottom: 2em;
  border-top: 2px solid #ccc;
}

div.shipment div.shipment-header {
  display: flex;
  flex-wrap: wrap;
}

div.shipment div.shipment-header > div {
  flex: 1;
}

div.shipment div.shipment-delivery-address {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

div.shipment div.shipment-delivery-address > div {
  min-width: 300px;
  flex: 1;
  margin: 0.5em;
}

div.shipment div.shipment-contents div.delivery {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

div.shipment div.shipment-contents div.delivery > div {
  flex: 1;
}

div.shipment div.shipment-contents div.delivery table {
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
}

div.shipment div.shipment-contents div.delivery table caption {
  padding: 0.5em;
}

div.shipment div.shipment-contents div.delivery table tr > * {
  padding: 0.5em;
  border-bottom: 1px dotted #ccc;
}

div.shipment div.shipment-contents div.delivery table th {
  text-align: left;
  font-weight: normal;
  background-color: #f5f5f5;
  min-width: 150px;
}

div.shipment div.shipment-contents div.delivery table td {
  min-width: 150px;
}

div.order div.order-buttons {
  padding: 0 1em;
  display: flex;
}

div.order div.order-buttons > div {
  padding: 0 1em;
}

div.order div.order-buttons > div:first-child {
  margin-left: auto;
}

/*-- Order End --*/
/*-- Account Menu --*/
ul.account-menu-list li div.note {
  margin: 0.5em 0 1em;
}

ul.account-menu-list li div.custompropkeys {
  color: #999;
  font-size: 11px;
}

/*-- Account Menu End--*/
/*-- SC Resume Panel --*/
div.ac-resume-checkout {
  position: fixed;
  top: 10%;
  left: 1em;
  z-index: 1000;
  background-color: rgba(240, 255, 240, 0.9);
  border: 1px solid #0a0;
  padding: 1em;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 12px;
}

div.sc-resume-checkout div.resume-checkout-msg {
  color: #090;
}

div.sc-resume-checkout div.resume-checkout-content {
  text-align: center;
}

div.sc-resume-checkout div.resume-checkout-btn {
  text-align: center;
  padding: 0.5em 1em;
}

/*-- SC Resume Panel End--*/
/*-- One-time message --*/
div.onetime-message {
  color: #c00;
  padding: 1em;
}

div.xp-onetime-message {
  position: fixed;
  z-index: 2000;
  top: 0;
  width: 100%;
  padding: 0.5em 1em;
  color: #a00;
  background-color: #ffe;
  border-bottom: 1px solid #a00;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/*-- One-time message End --*/
/*-- Trailer Link --*/
div.trailer-link {
  padding: 2em 0;
  text-align: right;
}

/*-- Trailer Link End--*/
/*-- ExRP --*/
div.exrp-subscription > div.panel {
  border: 1px solid #ccc;
}

div.exrp-subscription div.subscription-summary {
  display: flex;
  padding: 0.5em;
}

div.exrp-subscription div.subscription-summary > div {
  flex: 1;
  margin: 2px;
  border: 1px solid #ddd;
  border-radius: 2px;
}

div.exrp-subscription div.subscription-summary > div h4 {
  display: block;
  font-weight: normal;
  background-color: #eee;
  border-bottom: 1px solid #ddd;
  margin: 0;
  padding: 1em 0;
}

div.exrp-subscription div.subscription-summary > div h4 + div {
  padding: 1em;
}

div.exrp-subscription div.subscription-details ul.course-details li {
  display: flex;
  flex-wrap: wrap;
}

div.exrp-subscription div.subscription-details ul.course-details li div.detail-id {
  width: 100px;
}

div.exrp-subscription div.subscription-details ul.course-details li div.course {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.exrp-subscription div.subscription-details ul.course-details li div.config {
  width: 100px;
  padding: 0 1em;
  color: #0a0;
}

div.exrp-subscription div.subscription-details ul.course-details li div.course-dsc {
  color: #966;
}

.exrp-shipment span.mylabel {
  font-size: 11px;
  display: block;
  color: #900;
}

ul.exrp-shipments li.exrp-shipment {
  border: 1px solid #ccc;
  margin-bottom: 2em;
}

.exrp-shipment div.content,
.exrp-shipment div.properties {
  display: flex;
}

.exrp-shipment div.content > div,
.exrp-shipment div.properties > div {
  flex: 1;
  text-align: center;
  padding: 0.5em 1em;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  margin: 0.5em;
  border-radius: 4px;
}

/*-- ExRP END --*/
/*-- Points to Grant --*/
div.points-not-positive {
  display: none;
}

/*-- Points to Grant END--*/
/*-- Async Cart --*/
div.ac-cart div.cart-contents {
  border: 1px solid #999;
  font-size: 0.8em;
}

div.ac-cart div.cart-contents-heading {
  text-align: center;
}

div.ac-cart div.cart-contents-heading div.main {
  padding: 1em;
}

div.ac-cart div.cart-contents-heading div.checkout button {
  margin: 0 auto 1em;
  width: 90%;
}

div.ac-cart div.cart-contents-heading a {
  color: inherit;
  text-decoration: none;
}

div.ac-cart div.cart-contents ul.cart-details {
  display: flex;
  flex-wrap: wrap;
}

div.ac-cart div.cart-contents li.cart-detail {
  width: 100%;
  max-width: 300px;
  padding-bottom: 1em;
}

div.ac-cart div.cart-contents li.cart-detail div.image {
  padding-top: 1em;
}

div.ac-cart div.cart-contents li.cart-detail div.text .desc {
  text-align: center;
  padding: 0 1em;
}

div.ac-cart div.cart-contents li.cart-detail div.text .desc .code {
  font-size: 0.5rem;
}

div.ac-cart div.cart-contents li.cart-detail div.text div.options ul {
  font-size: 0.5rem;
  text-align: center;
}

div.ac-cart div.cart-contents li.cart-detail div.control {
  display: flex;
  padding: 0 1em;
}

div.ac-cart div.cart-contents li.cart-detail div.control div.remove {
  cursor: pointer;
  white-space: nowrap;
}

div.ac-cart div.cart-contents li.cart-detail div.control > div {
  padding: 0.25em 0.5em;
  white-space: nowrap;
}

/*-- Async Cart END--*/
/*-- Tracking GW --*/
div.delivery-tracker-gw {
  position: relative;
}

div.delivery-tracker-gw div.delvery-tracking {
  border: 1px solid #ccc;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 2em;
  background-color: #fff;
  z-index: 1000;
}

div.delivery-tracker-gw div.delvery-tracking div.heading {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 1em;
}

div.delivery-tracker-gw div.delvery-tracking div.main {
  padding: 1em;
}

div.delivery-tracker-gw div.delvery-tracking div.main ol li {
  position: relative;
  padding: 0 0 1em;
}

div.delivery-tracker-gw div.delvery-tracking div.main ol li div.location-name {
  background-color: #ccc;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0 auto;
  line-height: 50px;
  text-align: center;
  white-space: pre;
  font-size: 13px;
}

div.delivery-tracker-gw div.delvery-tracking div.main ol li div.dt-timestamp {
  font-size: 11px;
}

div.delivery-tracker-gw div.delvery-tracking div.main ol li.pre-ship div.location-name {
  background-color: #9cc;
}

div.delivery-tracker-gw div.delvery-tracking div.main ol li.undone div.location-name {
  background-color: #fff;
  border: 1px solid #ccc;
}

div.delivery-tracker-gw div.delvery-tracking div.main div.contents {
  padding: 1em;
}

/*-- Tracking GW END--*/
/*-- ExAuthenticator --*/
div.exag-signin {
  text-align: center;
}

/*-- ExAuthenticator END--*/
/*-- ExAuthenticator --*/
div.exag-signin {
  text-align: center;
}

/*-- ExAuthenticator END--*/
/*-- ItemPurchase PropVar --*/
div#item-purchase-propvar div.dominant-properties > ul > li {
  display: flex;
}

div#item-purchase-propvar div.dominant-properties > ul > li {
  border-top: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0.5em 0;
  padding-top: 1em;
}

div#item-purchase-propvar div.dominant-properties > ul > li div.item-prop-dominant {
  min-width: 150px;
  text-align: center;
}

div#item-purchase-propvar div.articles > ul > li.article {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
}

div#item-purchase-propvar div.articles > ul > li.article > div {
  min-width: 150px;
}

div#item-purchase-propvar div.articles > ul > li.article:last-child {
  border-bottom: none;
}

div#item-purchase-propvar div.offerings > ul > li.offering {
  display: flex;
}

div#item-purchase-propvar div.offerings > ul > li.offering form.item-purchase {
  padding: 0;
}

div#item-purchase-propvar div.offerings > ul > li.offering div.btn-group-purchase {
  padding: 0 1em;
}

div#item-purchase-propvar div.prop-value .code {
  padding: 0 1em;
  color: #aaa;
}

/*-- ItemPurchase PropVar END--*/
/*-- ItemProperties --*/
ul.item-properties {
  margin-bottom: 1em;
}

li.item-property {
  padding: 0.5em 0.5em 0.5em 0;
}

li.item-property {
  display: inline-block;
  padding: 2px 4px;
  font-size: 0.8em;
}

li.item-property div.wrapper-no-contents {
  border: 1px solid #444;
  border-radius: 2px;
  padding: 4px;
}

li.item-property div.wrapper-no-contents > div {
  display: inline;
}

li.item-property .code:before {
  content: "(";
}

li.item-property .code:after {
  content: ")";
}

/*-- ItemProperties END--*/
/*-- VariationProperties --*/
div.item-variation-properties div.varprop {
  margin-bottom: 1em;
}

div.item-variation-properties div.varprop div.varprop-key {
  background-color: #eee;
  padding: 0.5em;
  text-align: center;
}

div.item-variation-properties div.varprop div.varprop-key .code:before {
  content: " (";
}

div.item-variation-properties div.varprop div.varprop-key .code:after {
  content: ")";
}

div.item-variation-properties div.varprop ul.varprop-values {
  display: flex;
  font-size: 0.75em;
}

div.item-variation-properties div.varprop ul.varprop-values li {
  width: 120px;
  padding: 0.5em;
  text-align: center;
}

div.item-variation-properties div.varprop ul.varprop-values li:hover {
  cursor: pointer;
}

div.item-variation-properties div.varprop ul.varprop-values li > div.wrapper {
  padding: 0.5em;
  height: 100%;
  border: 1px solid transparent;
}

div.item-variation-properties div.varprop ul.varprop-values li.out-of-stock > div.wrapper {
  color: #aaa;
  background-color: #f5f5f5;
}

div.item-variation-properties div.varprop ul.varprop-values li.out-of-stock img {
  opacity: 0.3;
}

div.item-variation-properties div.varprop ul.varprop-values li.active > div.wrapper {
  border: 1px solid #ccc;
}

div.item-variation-properties div.varprop ul.varprop-values li div.varprop-value-desc {
  font-size: 0.8em;
  text-align: left;
}

div.item-variation-properties div.varprop ul.varprop-values li span.varprop-stock-msg.instock {
  color: #080;
}

div.item-variation-properties div.varprop ul.varprop-values li span.varprop-stock-msg.nostock {
  color: #c66;
}

div.variation-properties ul.vp-labels li.variation-property {
  display: inline-block;
  font-size: 0.75em;
  background-color: #369;
  color: #fff;
  padding: 4px 8px;
  margin-right: 1em;
  margin-top: 0.5em;
}

div.variation-properties ul.vp-labels li.variation-property .code:before {
  content: " (";
}

div.variation-properties ul.vp-labels li.variation-property .code:after {
  content: ")";
}

div.variation-properties ul.vp-labels li.variation-property .vp-key:after {
  content: ":";
}

div.variation-properties ul.vp-labels li.variation-property div.wrapper > div {
  display: inline-block;
}

div.variation-properties ul.vp-descriptions {
  margin: 1em 0;
  font-size: 0.8em;
}

div.variation-properties ul.vp-descriptions li div.label {
  display: inline-block;
}

div.variation-properties ul.vp-descriptions li div.desc {
  display: inline-block;
  padding-left: 1em;
}

/*-- VariationProperties END --*/
/*-- Order Journal History --*/
div.panel-journal {
  border: 1px solid #ccc;
  margin-bottom: 2em;
}

div.panel-journal div.panel-heading {
  background-color: #eee;
  display: flex;
}

div.panel-journal div.panel-heading > div {
  flex: 1;
}

/*-- Order Journal History END--*/
/*-- Common Input-Submit --*/
div.input-submit {
  white-space: nowrap;
  font-size: 11px;
}

div.input-submit input {
  margin: 0;
  width: 120px;
}

div.input-submit button {
  margin: 0;
}

/*-- Common Input-Submit END--*/
/*-- SubmitBox --*/
div.submit-box {
  text-align: center;
  background-color: #eee;
  padding: 1em 0;
}

div.submit-box .btn {
  font-size: 18px;
}

div.submit-box div.note {
  font-size: 12px;
}

/*-- SubmitBox END--*/
/*-- OrderEntryControl --*/
div.sc-orderentry-control {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: linear-gradient(rgba(0, 0, 0, 0.6), #000);
  color: #fff;
}

div.ac-orderentry-control {
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
}

div.ac-orderentry-control div.main {
  min-width: 300px;
}

div.ac-orderentry-control div.main div.heading {
  font-size: 18px;
  color: #f82;
}

div.ac-orderentry-control div.main div.description {
  font-size: 12px;
}

div.ac-orderentry-control div.summary {
  display: flex;
  align-items: center;
  padding: 0 1em;
  color: #f33;
  font-weight: bold;
  min-width: 100px;
  margin-left: auto;
}

div.ac-orderentry-control div.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

div.ac-orderentry-control div.controls > div {
  padding: 0 0.5em;
}

div.sc-orderentry-control div.not-verified {
  text-align: center;
  padding: 1em 0;
}

div.sc-orderentry-control div.not-verified div.msg {
  font-size: 12px;
}

div.sc-orderentry-control div.not-verified div.signin a {
  color: #fff;
}

div.remove-one span.btn {
  font-size: 11px;
  cursor: pointer;
  color: #999;
}

div.remove-one span.btn:hover {
  text-decoration: underline;
}

div.update-admail-opt-in {
  padding: 1em;
}

div.update-admail-opt-in-note {
  font-size: 80%;
}

/*-- OrderEntryControl END--*/
/*-- RegularPurchaseOrderEntries--*/
.rp-orderentry div.panel {
  border: 1px solid #ccc;
  margin-bottom: 1em;
}

.rp-orderentry div.panel-heading div {
  height: 30px;
  line-height: 30px;
}

.rp-orderentry div.panel-heading button.btn {
  font-size: 12px;
  margin: 0;
}

.rp-orderentry div.panel-body {
  padding: 1em;
}

span.rpoe-status {
  display: inline-block;
  background-color: #ccc;
  color: #fff;
  padding: 0 1em;
  border-radius: 3px;
  font-size: 14px;
}

span.rpoe-status-WAITING_CHECKOUT {
  background-color: #0c0;
}

/*-- RegularPurchaseOrderEntries END--*/
/*-- RegularPurchaseOrderEntries END--*/
/*-- Item Custom Field --*/
div.item-custom-fields div.field-def {
  border-top: 2px solid #999;
  padding: 0.5em;
  text-align: center;
}

div.item-custom-fields div.field-def .cf-desc {
  font-size: 0.8em;
}

div.item-custom-fields div.field-entry .cf-subject {
  font-size: 1.25em;
  color: #fff;
  padding: 0.5em;
  background-color: #666;
  text-align: center;
}

div.item-custom-fields div.field-entry .cf-contents.with-body-and-file {
  padding: 1em 0;
  display: flex;
}

div.item-custom-fields div.field-entry .cf-contents .cf-body {
  padding: 1em;
}

div.item-custom-fields div.field-entry .cf-contents .cf-files {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

/*-- Item Custom Field END--*/
/*-- Return Request--*/
ol.items-to-return li.item-to-return {
  margin-bottom: 1em;
  border: 1px solid #eee;
  display: flex;
}

ol.items-to-return li.item-to-return > div {
  display: flex;
  align-items: center;
}

/*-- Return Request END--*/
/*-- Calendar --*/
table.calendar th,
table.calendar td {
  text-align: center;
  padding: 0.25em;
}

table.calendar td.last-month,
table.calendar td.next-month {
  color: #ccc;
}

table.calendar .SUNDAY {
  color: red;
}

table.calendar .SATURDAY {
  color: #00c;
}

table.calendar .close,
table.calendar .unshippable {
  background-color: #eee;
}

/*-- Calendar END--*/
/*-- Cart Configurator --*/
#cart-configurator #add-destination {
  display: none;
}

#cart-configurator .toggle-add-dst {
  padding: 2em 0;
}

#cart-configurator .formrow-submit.configurator > div {
  padding: 1em;
}

#cart-configurator ul.itemdetails li {
  display: flex;
}

#cart-configurator ul.itemdetails div.qty {
  display: flex;
  align-items: center;
}

#cart-configurator ul.itemdetails div.qty select {
  font-size: 18px;
}

#cart-configurator #destination-to-add {
  background-color: #eee;
  padding: 0.5em;
}

#cart-configurator #destination-to-add ol {
  display: flex;
  flex-wrap: wrap;
}

#cart-configurator #destination-to-add ol li.dest {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  margin: 0.5em;
}

#cart-configurator #destination-to-add ol li.dest.active {
  border: 1px solid #0c0;
  background-color: #fff;
}

/*-- Cart Configurator END --*/
div.stub-contents {
  margin: 1rem;
  border: 2px solid #f82;
  padding: 4rem 1rem;
  font-size: 1.5rem;
  color: #f82;
  text-align: center;
  position: relative;
  border-radius: 0.25rem;
}

div.stub-contents.html:before {
  content: "任意のHTML";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  background-color: #f82;
  color: #fff;
  padding: 2px 4px;
}

article#container {
  max-width: var(--ers-deago-maxwidth);
  margin: 0 auto;
  overflow-x: hidden;
}

div.display-box-history_recommendation {
  margin-top: 80px;
}

div.display-box-history_recommendation .display-name {
  font-size: 24px;
  text-align: left;
}

div.display-box-history_recommendation ul.item-display {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
}

/* LP共通 | LP Common */
.lp-outer {
  padding: 80px;
}

.lp-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px solid green;
  height: 500px;
  padding: 20px;
}

.lp-contents p {
  font-size: 20px;
}

.lp-outer .show-partwork-dtl.btn {
  font-size: 24px;
  margin: 40px auto 0;
}

.lp-outer .button-wrap {
  text-align: center;
}

/* 抽選ゲーム | Game */
.game {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.game .sequential-contents {
  padding: 0;
}

.game h1 {
  border: 1px solid #333;
  padding: 80px;
  text-align: center;
  margin: 20px;
}

.game h2 {
  background-color: #494949;
  color: #fff;
  padding: 12px;
  text-align: center;
  margin: 40px 20px 20px;
}

.game h3 {
  border-bottom: 2px solid #555;
  display: inline-block;
  font-size: 20px;
  margin: 0 auto;
}

.game select {
  font-size: 20px;
  padding: 8px;
  margin: 20px 0;
}

.game ul {
  text-align-last: left;
  max-width: 700px;
  margin: 20px auto 0;
  width: 100%;
}

.game li {
  margin-top: 8px;
}

.game .input-area textarea {
  display: none;
}

.game .stab-contents {
  background-color: #f6f6f6;
  margin: 40px 20px;
  padding: 160px 0;
}

.game .subsequence-label {
  margin-top: 80px;
}

.game .subsequence-label li {
  text-align: center;
  width: 100%;
}

.game .subseq-link {
  background-color: #00ac5a;
  color: #fff;
  display: block;
  font-size: 30px;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
}

.game .message-wrap {
  font-size: 20px;
  margin: 20px;
  padding: 40px 0;
}

.game .message-wrap.win {
  color: #00ac5a;
  font-size: 36px;
  font-weight: bold;
  padding: 40px 0;
}

.game .message-wrap.lose {
  background-color: #efefef;
  padding: 80px 0;
}

.game .go-top {
  display: block;
  font-size: 18px;
  margin-top: 80px;
  text-decoration: underline;
}

.game__history-inner {
  margin: 40px 20px 20px;
}

.game__history-table {
  border-collapse: collapse;
  width: 100%;
}

.game__history-table td {
  border: 1px solid #aaa;
  padding: 8px 4px;
}

.game__history-table .game__win {
  color: #00ac5a;
  font-size: 20px;
  font-weight: bold;
  padding: 8px 0;
}

.game__win-info {
  background-color: #efefef;
  margin-top: 8px;
  padding: 8px;
}

.game .active-contents {
  text-align: center;
}

.game__win-info button,
.game .active-contents .button {
  background-color: #005c69;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  margin-top: 8px;
  text-decoration: none;
  padding: 4px 8px;
}

.game__win-info button:hover {
  opacity: 0.5;
}

.game__win-info .no-address {
  font-weight: bold;
}

.game div.no-address {
  padding: 20px 0 40px;
}

.game div.waiting {
  display: block;
}

.game div.result {
  display: none;
}

.game .waiting .message-wrap {
  color: red;
  font-size: 24px;
  animation: blink 0.5s linear 0s infinite normal;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  29.9% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.game .game-kusudama {
  height: 300px;
}

.slick-arrow {
  top: 45%;
  z-index: 1;
}

.slick-arrow:before {
  color: #000;
  font-size: 50px;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 40px;
}

/*-- n2karyon defaults --*/
/*-- AddressBook --*/
div.addressbook {
  max-width: 800px;
  text-align: right;
  position: relative;
}

div.addressbook div.ab-list {
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: 1px solid #ccc;
  border-radius: 4px;
}

div.addressbook div.ab-list ul li {
  padding: 0.5em 2em;
  text-align: left;
  cursor: pointer;
}

div.addressbook div.ab-list ul li:hover {
  background-color: #eee;
}

div.addressbook div.ab-list ul li div.naa {
  display: flex;
}

div.addressbook div.ab-list ul li span.name {
  padding: 0 1em 0 0;
  width: 8em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

div.addressbook div.ab-list ul li span.address {
  max-width: 16em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

/*-- AddressBook End--*/
/*-- Form Element --*/
div.form {
  padding: 0;
}

div.form h3 {
  padding: 0;
}

div.form fieldset {
  border: none;
  border-top: 1px solid #eee;
  background-color: #fefefe;
  margin: 0 0 1em;
  border-radius: 4px;
  padding: 1em 0 1em;
  margin: 1em 0;
}

div.form fieldset legend {
  background-color: #fff;
  padding: 0 1em;
  text-align: center;
  color: #966;
}

div.form fieldset fieldset {
  background-color: #ececec;
  margin: 1em;
}

div.form fieldset legend {
  font-size: 12px;
}

div.form-note, div.note {
  margin: 1em 0;
  padding: 0;
  color: #444;
}

div.formrow input[type=text],
div.formrow input[type=email],
div.formrow input[type=tel],
div.formrow input[type=number],
div.formrow input[type=password],
div.formrow select,
div.formrow textarea {
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
  padding: 8px 8px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
}

div.formrow textarea {
  height: 8em;
}

div.formrow select.date {
  padding: 4px 0;
}

div.formrow select.date-yyyy {
  width: 5em;
}

div.formrow select.date-mm {
  width: 4em;
}

div.formrow select.date-dd {
  width: 4em;
}

div.formrow {
  position: relative;
  margin: 0;
}

div.input-group-address div.formrow {
  border-bottom: 1px dotted #ccc;
}

div.input-group-address div.formrow:last-child {
  border: none;
}

div.formrow div.input-label {
  position: relative;
  display: flex;
}

div.formrow div.input-label span {
  display: block;
}

div.formrow div.input-label span.input-name {
  margin-right: auto;
}

div.formrow div.input-label span.input-required,
div.formrow div.input-label span.input-may-required,
div.formrow div.input-label span.input-disabled {
  background-color: transparent;
  color: #c00;
  padding: 1px 4px;
  font-size: 11px;
  border: 1px solid #c00;
  border-radius: 2px;
  display: inline-block;
  float: right;
}

div.formrow div.input-label span.input-may-required {
  background-color: #eee;
}

div.formrow div.input-control {
  position: relative;
}

div.formrow div.input-control div.input-group {
  width: 100%;
}

div.formrow div.input-note {
  font-size: 14px;
  color: #666;
}

div.formrow div.input-control.input-control-group {
  display: flex;
  width: 100%;
}

div.formrow-radio div.input-control div.input-group,
div.formrow-checkbox div.input-control div.input-group {
  padding: 8px 4px;
}

div.formrow div.input-control div.input-group-radio div.radio-item {
  display: inline-block;
}

div.formrow div.input-control.input-control-group div.input-group {
  flex: 1;
  padding-left: 8px;
  position: relative;
}

div.formrow div.input-control.input-control-group div.input-group:first-child {
  padding-left: 0;
}

div.formrow span.input-group-addon {
  position: absolute;
  top: -13px;
  line-height: 11px;
  font-size: 11px;
  padding: 0;
}

div.formrow input.required {
  background-color: #fee;
}

div.formrow input.error {
  border: 1px solid #c99;
}

div.formrow input.valid {
  background-color: #efe;
  border: 1px solid #9c9;
}

.nmessage-default {
  z-index: 100;
  position: absolute;
  top: 2px;
  left: 1em;
  font-size: 12px;
  white-space: nowrap;
  background-color: #fff;
  padding: 4px 8px;
  border: 1px solid #fac;
  border-radius: 4px;
  color: #e06;
  animation: blink 1.2s ease-out infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
div.btn-submit,
div.formrow-submit {
  padding: 2em 0 4em;
  text-align: center;
  font-size: 16px;
}

div.btn-group {
  padding: 0.5em;
  text-align: right;
}

div.btn-group > div {
  display: inline-block;
  padding: 4px;
}

.btn + .btn {
  margin-left: 1em;
}

button.btn, a.btn, input[type=button], a.btn:visited, a.btn:hover {
  display: inline-block;
  font-size: 14px;
  line-height: 1.3;
  font-family: inherit;
  padding: 0.5em 1em;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  background: linear-gradient(#fff, #eee);
  color: #444 !important;
  border: 1px solid #999;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

button.btn-submit {
  font-size: 24px;
  font-family: inherit;
  padding: 16px 24px;
  min-width: 280px;
  border-radius: 6px;
  background: linear-gradient(#fff, #eee);
  color: #444;
  border: 1px solid #999;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

button.btn-submit:hover {
  background: linear-gradient(#fff, #ddd);
}

button.btn-submit[disabled] {
  color: #666;
  opacity: 0.4;
  pointer-events: none;
}

/*-- Form Element END--*/
/* diff pc */
@media screen and (min-width: 800px) {
  div.item-search div.item-search-box {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 0;
    position: relative;
    overflow: hidden;
  }
  div.item-search div.item-search-box > div {
    border: none;
    height: 2em;
  }
  div.item-search div.item-search-box > div.sb-label {
    padding: 0 1em;
    border-right: 1px solid #ccc;
    line-height: 2em;
  }
  div.item-search div.item-search-box > div.sb-category,
  div.item-search div.item-search-box > div.sb-input {
    border-right: 1px solid #ccc;
  }
  div.item-search div.item-search-box div.sb-category select {
    border: none;
    height: 100%;
  }
  div.item-search div.item-search-box div.sb-keywords input {
    border: none;
    padding: 0 1em;
    height: 100%;
  }
  div.item-search div.item-search-box div.sb-btn {
    border-left: 1px solid #ccc;
  }
  div.item-search div.item-search-box div.sb-btn button {
    height: 100%;
    font-family: inherit;
    margin: 0;
    border: none;
    outline: none;
    appearance: none;
    padding: 0 1em;
    cursor: pointer;
  }
  h1.item-name, h2.item-name {
    text-align: left;
    padding: 0;
    margin: 0;
  }
  div.itemdetails-header div.itemdetail-row {
    border-bottom: 2px solid #999;
  }
  div.itemdetails-body div.itemdetail-row div.goods div.item-thumbnail-img {
    padding: 0 1em;
  }
  div.itemdetails-body div.itemdetail-row div.item div.control {
    width: 300px;
  }
  div.itemdetails-body div.itemdetail-row div.item {
    display: flex;
  }
  div.itemdetails-body div.itemdetail-row div.item div.goods {
    width: 100%;
  }
  div.itemdetail-row div.item > div.control {
    width: 200px;
  }
  #order-entry .shipment ul.delivery-service-conf li {
    display: flex;
    flex-wrap: wrap;
  }
  /*--------------- form -----------------*/
  div.form {
    padding: 0 1em;
  }
  div.formrow {
    display: flex;
    margin: 0 auto;
  }
  div.formrow > div {
    padding: 1.5em 1em;
    display: flex;
    align-items: center;
  }
  div.formrow div.input-label {
    flex: 1;
  }
  div.formrow div.input-control {
    display: flex;
    flex: 3;
  }
  div.formrow div.input-control div.input-group {
    flex: 2;
  }
  div.formrow div.input-note {
    padding-left: 2em;
    margin-left: auto;
    flex: 1;
  }
  div.cart-add-item-resp > div {
    max-width: 600px;
  }
  form.item-purchase div.btn-group-purchase {
    display: inline-block;
  }
  form.item-purchase div.item-qty-item {
    display: inline-block;
  }
}
/* diff sp */
@media screen and (max-width: 800px) {
  div.item-search div.item-search-box > div.sb-label {
    display: none;
  }
  div.item-search div.item-search-box > * {
    display: inline-block;
    margin: 0.25em 0;
  }
  div.item-thumbnail-img-lg {
    width: unset;
    height: unset;
  }
  div.cart-contents > div {
    padding: 0 1em;
  }
  div.cart-contents-wrapper > div.itemdetails {
    padding: 0;
  }
  div.itemdetail-row div.item div.goods {
    flex-direction: column;
  }
  div.itemdetail-row div.item div.control {
    font-size: 0.7rem;
    text-align: left;
    justify-content: left;
    padding: 0 1em;
  }
  div.itemdetail-row div.item div.control div.cartitem-basic-control > * {
    display: inline-block;
  }
  div.itemdetail-row div.purchase-contents {
    flex-direction: column-reverse;
  }
  div.goods-text {
    padding: 1em;
  }
  div.goods-text span.code {
    display: none;
  }
  div.itemdetails-header {
    display: none;
  }
  div.itemdetail-row div.item div.item-thumbnail-img-wrapper {
    margin: 0 auto;
  }
  ul.item-list li.item-list-each div.item-dtl {
    margin: 1em 0 2em;
  }
  div.news-entries div.header {
    display: block;
  }
  div.form div.form-note {
    padding: 0 1em;
  }
  div.formrow {
    padding: 1em;
  }
  div.formrow span.input-group-addon {
    display: none;
  }
  .nmessage-default {
    top: -30px;
    right: 0;
    left: 6em;
  }
  div#item-purchase-table table.item-purchase-table th {
    display: none;
  }
  div#item-purchase-table table.item-purchase-table tr {
    border-top: 1px solid #ccc;
  }
  div#item-purchase-table table.item-purchase-table td {
    display: block;
    border: none;
    width: 100%;
    box-sizing: border-box;
  }
  form.item-purchase div.btn-group-purchase {
    text-align: center;
  }
}
div#nowloading {
  position: fixed;
  z-index: 2000;
  top: 50%;
  left: 0;
  right: 0;
}

div#nowloading div.msg {
  top: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 1em;
  margin: 0 auto;
  width: 200px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 18px;
  font-family: Arial;
}

#error-msg {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #ffe;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid #999;
  text-align: center;
  color: #c00;
}

#non-modal-msg {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #efe;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid #090;
  text-align: center;
  color: #090;
}

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  background-color: #000;
  opacity: 0.3;
}

.modal {
  display: none;
  position: fixed;
  top: 50px;
  left: 10%;
  width: 80%;
  z-index: 1501;
}

.modal .modal-dialog {
  border: 1px solid #666;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.75);
}

.modal .modal-dialog .modal-header {
  border-bottom: 1px solid #666;
  border-radius: 8px 8px 0 0;
  padding: 16px;
  background: linear-gradient(#fff, #ddd);
}

.modal .modal-dialog .modal-header h4 {
  margin: 0;
}

.modal .modal-dialog .modal-body {
  padding: 8px 16px;
  height: 540px;
  overflow-y: scroll;
}

.modal .modal-dialog .modal-footer {
  padding: 16px;
  border-top: 1px solid #666;
  background-color: #eee;
  border-radius: 0 0 8px 8px;
  text-align: right;
}

.ns-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.75;
  color: #fff;
  text-align: center;
}

.ns-overlay > * {
  margin: 2em;
}

div.tgl {
  display: none;
}

.dev-page-information {
  padding: 16px;
  margin: 0;
  background-color: #222;
}

.dev-page-information table {
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;
  color: #fff;
}

.dev-page-information table th,
.dev-page-information table td {
  border-bottom: 1px dotted #ccc;
  padding: 2px 4px;
}

.dev-page-information table th {
  border-right: 1px solid #ccc;
}

.dev-page-information .template-variables pre {
  padding: 1em 2em;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #eee;
  overflow: hidden;
  white-space: pre-wrap;
}

.dev-page-information div.exception {
  padding: 1em 2em;
  border: 1px solid #0cc;
  border-radius: 6px;
  color: #0cc;
}

div.form div.form-confirm-contents-wrapper.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  text-align: center;
}

div.form div.form-confirm-contents-wrapper.overlay div.form-confirm-contents {
  margin: auto;
}

.after-confirm {
  display: none;
}

span.ip-cfm {
  white-space: pre-wrap;
}

div.upload-thumbnail {
  padding: 2px;
  border: 1px solid #ccc;
  display: inline-block;
  vertical-align: middle;
}

div.upload-thumbnail img {
  width: 100px;
}

div.move-item-toggle div.disable-moveitem,
div.move-item-wrapper,
div.move-item-wrapper div.new-destination-consignee {
  display: none;
}

div.move-item-wrapper ul.move-item-dst > li.active {
  background-color: #fff;
}

div.move-item-wrapper ul.move-item-dst > li.inactive {
  background-color: #eee;
}

/*----------------------- Site Gadget / Item Search Criteria ----------------------------*/
div.item-search-criteria div.criteria {
  border: 1px solid #fff;
  cursor: pointer;
  display: inline-block;
  padding: 2px 0.5em;
  border-radius: 1em;
}

div.item-search-criteria div.criteria.off {
  background-color: #fff;
  color: #666;
}

div.item-search-criteria div.criteria.on {
  background-color: #f5f5f5;
  border-color: #666;
}

/*---------------Shasow User Badge------------------*/
div.shadow-user-badge {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2000;
  font-size: 12px;
  padding: 1em;
  background-color: rgba(200, 0, 0, 0.8);
  border-radius: 0.5em 0 0 0;
  color: #fff;
  border: 1px solid #c00;
  border-bottom: none;
  border-right: none;
  cursor: pointer;
}

div.shadow-user-badge div.user {
  text-align: center;
  font-weight: bold;
  text-shadow: 0 0 2px black;
}

div.shadow-user-badge div.unreleased {
  font-size: 0.9em;
}

.toggler .inactive {
  display: none;
}

div.password-str-meter {
  margin: 0.25em 0;
  display: flex;
  font-size: 0.85em;
  color: #999;
}

div.password-str-meter span.msg {
  padding: 0 2px;
  white-space: nowrap;
}

div.password-str-meter.DANGER span.msg {
  color: #f00;
}

div.password-str-meter.LOW span.msg {
  color: #f93;
}

div.password-str-meter.MEDIUM span.msg {
  color: #39c;
}

div.password-str-meter.HIGH span.msg {
  color: #3a3;
}

div.password-str-meter.GREAT span.msg {
  color: #3c3;
}

div.password-str-meter span.unmask-pass {
  text-align: right;
  margin-left: auto;
  cursor: pointer;
}

div.password-str-meter span.masked .label-mask,
div.password-str-meter span.unmasked .label-unmask {
  display: none;
}

div.site-announce.fixed {
  position: fixed;
}

div.site-announce.FADE_OVERLAY {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.site-announce.SLIDEUP_OVERLAY {
  position: fixed;
  bottom: 0;
  right: 0;
}

div.site-announce.SLIDEDOWN_OVERLAY {
  position: fixed;
  top: 0;
  left: 0;
}

div.modify-rpi-option ul.item-options > li div.control.inactive span.option-input {
  display: none;
}

div.modify-rpi-option ul.item-options > li div.control span.option-input.disabled {
  display: none;
}

div.panel-list-wrapper {
  background-color: rgba(0, 0, 0, 0.4);
}

div.panel-list-wrapper > div.container {
  padding: 1em 0;
  background-color: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

div.panel-list-wrapper ol.panel-list > li {
  border-right: 1px solid #ccc;
  position: relative;
  padding: 4em 1em 2em;
}

div.panel-list-wrapper ol.panel-list > li button.btn.slide {
  position: absolute;
  top: 0;
}

div.panel-list-wrapper ol.panel-list > li button.btn.slide.prev {
  left: 0.5em;
}

div.panel-list-wrapper ol.panel-list > li button.btn.slide.next {
  right: 0.5em;
}

div.itemdetails div.partwork-subscription > div.label {
  cursor: pointer;
  text-decoration: underline;
}

div.itemdetails div.partwork-subscription > div.body {
  display: none;
  position: absolute;
  background-color: #f5f5f5;
  z-index: 1000;
  width: 100%;
  padding: 1em;
}

* {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  font-size: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  backface-visibility: visible;
}

ul,
ol,
dl,
dt,
dd {
  padding: 0;
  margin: 0;
}

li, ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

input[type=submit],
input[type=reset],
input[type=button],
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

button:focus {
  outline: 0;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

[role=button] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@media all and (min-width: 769px) {
  a[href^=tel] {
    pointer-events: none;
  }
}

/*
  ---------------
  Fonts file
  ---------------
*/
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  src: url("/f/resources/fonts/NotoSansJP-Regular.woff2") format("woff2"), url("/f/resources/fonts/NotoSansJP-Regular.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  src: url("/f/resources/fonts/NotoSansJP-Medium.woff2") format("woff2"), url("/f/resources/fonts/NotoSansJP-Medium.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  src: url("/f/resources/fonts/NotoSansJP-Bold.woff2") format("woff2"), url("/f/resources/fonts/NotoSansJP-Bold.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 500;
  src: url("/f/resources/fonts/NotoSansJP-Medium.woff2") format("woff2"), url("/f/resources/fonts/NotoSansJP-Medium.woff") format("woff");
}
@font-face {
  font-family: "M PLUS 2";
  font-style: normal;
  font-weight: 700;
  src: url("/f/resources/fonts/MPLUS2-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "M PLUS 2";
  font-style: normal;
  font-weight: 900;
  src: url("/f/resources/fonts/MPLUS2-Black.ttf") format("truetype");
}
/*
  ----------------
  Base styles
  ----------------
  */
html {
  overflow-x: hidden;
}
html.is-open--sidebar {
  overflow: hidden;
}
html body.is-open--sidebar {
  overflow: hidden;
}

body {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

#wrapper,
main {
  flex: 1;
  overflow: hidden;
}

img {
  max-width: 100%;
  vertical-align: bottom;
  object-fit: cover;
}

a {
  display: inline-block;
}
@media (hover: hover) and (pointer: fine) {
  a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  a:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
a::before, a::after {
  backface-visibility: hidden;
}

figure {
  margin: 0;
}

@media all and (min-width: 769px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}
@media all and (max-width : 768px) {
  .container {
    padding-left: 5vmin;
    padding-right: 5vmin;
  }
}
/*
  ----------------
  Base font settings
  ----------------
*/
body {
  color: #1d1d1b;
  font-size: 16px;
  font-family: "Noto Sans JP", YuGothic, "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.4375;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.45833333;
}

h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

@media all and (max-width : 768px) {
  h1 {
    font-size: 14px;
    line-height: 1.71428571;
  }
  h2 {
    font-size: 18px;
    line-height: 1.44444444;
  }
  h3 {
    font-size: 13px;
    line-height: 1.46153846;
  }
}
/*
  ----------------
  Header common
  ----------------
*/
.account-panel__logo {
  height: 36px;
}

.account-panel__text {
  color: #fff;
  display: block;
  font-size: 12px;
  text-decoration: none;
}

/* 開発向け情報 */
.sc.dev-page-information {
  overflow-x: scroll;
}

li.search {
  cursor: pointer;
}

div#header {
  display: flex;
  background-color: #333;
}

#wrapper.open-modal #header {
  z-index: 99999;
}

.header-logo {
  width: 100px;
}

header#header ul#global-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--ers-deago-maxwidth);
  margin: 0 auto;
}

header#header ul#global-menu > li {
  padding: 1rem;
}

header#header ul#global-menu ul.link {
  display: flex;
  flex-wrap: wrap;
}

header#header ul#global-menu ul.link > li {
  padding: 0 1rem;
}

header#header ul#global-menu ul.link > li a {
  color: #fff;
  text-decoration: none;
}

header#header ul#global-menu li.search button {
  font-size: 2rem;
}

header#header ul.account-panel-content {
  display: flex;
}

header#header ul.account-panel-content li {
  text-align: center;
}

header#header ul.account-panel-content li a {
  text-align: center;
}

/*==========================================
  デフォルトのスタイル削除
==========================================*/
header#header ul.account-panel-content li {
  margin: 0;
}

.l-header {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
}
.l-header.is-fixed {
  width: 100%;
  position: fixed; /* 位置を固定する */
  top: 0;
  z-index: 100;
}
.l-header__inner {
  position: relative;
  z-index: 2;
}
.l-header__main {
  background-color: #005c69;
  position: relative;
  z-index: 1;
}
.l-header__main-inner {
  padding-left: min(3.05343511vw, 15px);
  display: flex;
}
@media all and (max-width : 768px) {
  .l-header__main-inner {
    align-items: center;
    padding-left: 0;
  }
}
@media all and (min-width: 769px) {
  .l-header__main-inner {
    padding: 12px min(2.15277778vw, 31px) 13px min(1.66666667vw, 24px);
    justify-content: space-between;
  }
}
.l-header__logo {
  line-height: 1;
  max-width: 100px;
}
@media all and (max-width : 768px) {
  .l-header__logo {
    margin-inline: 3.3427%;
  }
}
@media all and (min-width: 769px) {
  .l-header__logo {
    max-width: 110px;
  }
}
.l-header__logo-link {
  height: 100%;
  display: flex;
  align-items: center;
}
.l-header__global-list {
  display: flex;
}
@media all and (min-width: 769px) {
  .l-header__global-list {
    height: 100%;
    gap: min(4.86111111vw, 50px);
  }
}
@media all and (max-width : 768px) {
  .l-header__global-item {
    background-color: #f6f6f6;
    border-bottom: 1px solid #d9d9d9;
    width: 33.3333333333%;
    min-height: 51px;
    position: relative;
  }
}
@media all and (min-width: 769px) {
  .l-header__global-item {
    min-width: 0;
    max-width: 100%;
    display: flex;
    align-items: center;
  }
}
@media all and (max-width : 768px) {
  .l-header__global-item + .l-header__global-item {
    border-left: 1px solid #d9d9d9;
  }
}
@media all and (max-width : 768px) {
  .l-header__global-item:first-child .l-header__global-item-tooltip::before {
    transform: translateX(100px);
  }
}
@media all and (max-width : 768px) {
  .l-header__global-item:last-child .l-header__global-item-tooltip::before {
    transform: translateX(-16px);
  }
}
.l-header__global-item-link {
  height: 100%;
  display: flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .l-header__global-item-link {
    font-size: 13px;
    text-align: center;
    justify-content: center;
  }
}
@media all and (min-width: 769px) {
  .l-header__global-item-link {
    color: #fff;
  }
}
.l-header__global-item-tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .l-header__global-item-tooltip {
    position: absolute;
    right: 7px;
    bottom: 7px;
  }
}
@media all and (min-width: 769px) {
  .l-header__global-item-tooltip {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-left: min(0.34722222vw, 5px);
    position: relative;
  }
}
.l-header__global-item-tooltip:hover::before, .l-header__global-item-tooltip:hover::after {
  visibility: visible;
  opacity: 1;
  z-index: 10;
}
.l-header__global-item-tooltip::before {
  content: attr(data-tooltip);
  background-color: #f6f6f6;
  box-shadow: 0px 10px 20px -5px #d9d9d9;
  color: #333;
  display: block;
  font-size: 12px;
  font-weight: 500;
  width: 204px;
  padding: 17px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  position: absolute;
  top: 32px;
  right: -20px;
  z-index: -1;
}
.l-header__global-item-tooltip::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #f6f6f6 transparent;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  position: absolute;
  top: 26px;
  right: 50%;
  z-index: -1;
  transform: translateX(50%);
}
@media all and (max-width : 768px) {
  .l-header__user {
    margin-top: 8px;
    margin-right: clamp(0px, 7.12468193vw, 28px);
    margin-left: auto;
  }
}
.l-header__user-list {
  height: 100%;
  display: flex;
  gap: 1.875vw;
}
@media all and (max-width : 768px) {
  .l-header__user-list {
    gap: min(5.59796438vw, 22px);
  }
}
.l-header__user-item {
  height: 100%;
  min-width: 30px;
}
.l-header__user-item-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (hover: hover) and (pointer: fine) {
  .l-header__user-item-link {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .l-header__user-item-link:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.l-header__user-item-link::before, .l-header__user-item-link::after {
  backface-visibility: hidden;
}
.l-header__user-item-link figure {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .l-header__user-item-link.deago-item-search figure .l-header__user-item-figcaption {
    margin-top: 4px;
    line-height: 16px;
  }
}
.l-header__user-item-img {
  display: flex;
  width: 20px;
  margin: 0 auto;
}
.l-header__user-item-figcaption {
  color: #fff;
  font-size: 9px;
  line-height: 1;
  text-align: center;
  margin-top: 4px;
  white-space: nowrap;
}
@media all and (min-width: 769px) {
  .l-header__user-item-figcaption {
    font-size: 10px;
    font-weight: 500;
  }
}
.l-header #deago-account-panel {
  height: 100%;
}
.l-header .account-panel-content {
  height: 100%;
  display: flex;
  gap: 1.40625vw;
}
@media all and (max-width : 768px) {
  .l-header .account-panel-content {
    gap: min(4.19847328vw, 21px);
  }
}
.l-header .mypage,
.l-header .cart-smr {
  height: 100%;
  min-width: 30px;
}
.l-header .mypage a,
.l-header .cart-smr a {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.l-header .cart-smr a {
  position: relative;
}
.l-header .mypage a .account-panel__logo,
.l-header .cart-smr a .account-panel__logo {
  width: 20px;
  height: auto;
  margin: 0 auto;
}
.l-header .cart-smr .cart-smr-quantity {
  background-color: #c10000;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  height: 16px;
  line-height: 14px;
  text-align: center;
  width: 16px;
  position: absolute;
  top: 0;
  right: -6px;
}
@media all and (max-width : 768px) {
  .l-header .cart-smr .cart-smr-quantity {
    top: -6px;
    right: -8px;
  }
}
.l-header .account-panel__text {
  color: #fff;
  font-size: 9px;
  line-height: 16px;
  text-align: center;
  margin-top: 4px;
  white-space: nowrap;
}
@media all and (min-width: 769px) {
  .l-header .account-panel__text {
    font-size: 10px;
    font-weight: 500;
  }
}
@media all and (max-width : 768px) {
  .l-header__hamburger {
    border-left: 1px solid #fff;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
  }
}
@media all and (max-width : 768px) {
  .l-header__hamburger-btn {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 100;
  }
}
.l-header .c-btn--hamburger {
  border-radius: 0;
}
@media all and (max-width : 768px) {
  .l-header__hamburger-icon {
    background-color: #fff;
    border-radius: 2px;
    display: block;
    font-size: 0;
    width: 30px;
    height: 1px;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform 0.2s ease-in-out;
  }
}
@media all and (max-width : 768px) {
  .l-header__hamburger-icon::before, .l-header__hamburger-icon::after {
    content: "";
    background-color: inherit;
    border-radius: inherit;
    display: block;
    font-size: 0;
    width: 100%;
    height: 100%;
    transition: inherit;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media all and (max-width : 768px) {
  .l-header__hamburger-icon::before {
    top: -9px;
  }
}
@media all and (max-width : 768px) {
  .l-header__hamburger-icon::after {
    top: 9px;
  }
}

@media all and (max-width : 768px) {
  html.is-open--drawer {
    overflow-y: hidden;
  }
}
@media all and (max-width : 768px) {
  html.is-open--drawer body {
    overflow-y: hidden;
  }
}
html.is-open--drawer .l-header__hamburger-btn {
  background-color: #f6f6f6;
}
@media all and (max-width : 768px) {
  html.is-open--drawer .l-header__hamburger-icon {
    background-color: transparent;
    width: 36px;
  }
}
@media all and (max-width : 768px) {
  html.is-open--drawer .l-header__hamburger-icon::before, html.is-open--drawer .l-header__hamburger-icon::after {
    background-color: #005c69;
    top: 0;
  }
}
@media all and (max-width : 768px) {
  html.is-open--drawer .l-header__hamburger-icon::before {
    transform: rotate(33deg);
  }
}
@media all and (max-width : 768px) {
  html.is-open--drawer .l-header__hamburger-icon::after {
    transform: rotate(-33deg);
  }
}

/*
  ----------------
  Drawer common
  ----------------
*/
@media all and (max-width : 768px) {
  .l-drawer {
    background: #f6f6f6;
    width: 100%;
    max-width: 393px;
    margin-top: 104px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    z-index: 110;
    transform: translateX(105%);
    transition: transform 0.5s;
    display: flex;
    flex-direction: column;
  }
}
html.is-open--drawer .l-drawer {
  transform: translateX(0);
}
@media all and (max-width : 768px) {
  .l-drawer__inner {
    min-height: 100%;
    padding: 16px 11px;
    display: flex;
    flex-direction: column;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__global-list {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__global-item {
    border-bottom: 1px solid #d9d9d9;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__global-item:last-child {
    border-bottom: 0;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__global-item-link {
    color: #005c69;
    display: block;
    font-weight: 500;
    padding: 12px;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__usage {
    margin-top: 20px;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__usage-item-link {
    color: #005c69;
    display: block;
    font-weight: 500;
    padding: 6px 0;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__btn-group {
    margin-top: auto;
    display: flex;
    gap: 10px;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__signup {
    width: 100%;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__signup-link {
    display: block;
    background-color: #005c69;
    border-radius: 5px;
    color: #fff;
    width: 100%;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__bookstore {
    width: 100%;
  }
}
@media all and (max-width : 768px) {
  .l-drawer__bookstore-link {
    display: block;
    background-color: #1d1d1b;
    border-radius: 5px;
    color: #fff;
    width: 100%;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/*
  ----------------
  Footer common
  ----------------
*/
footer#footer a {
  color: inherit;
}

footer#footer div#footer-menu {
  display: flex;
  max-width: var(--ers-deago-maxwidth);
  margin: 0 auto;
  justify-content: center;
}

footer#footer div#footer-menu > div {
  padding: 1em;
}

footer#footer div#footer-menu div.head {
  padding: 1em 0;
}

footer#footer div#footer-menu div.body {
  display: flex;
}

footer#footer div#footer-menu div.body ol {
  padding-right: 2em;
}

footer#footer a.l-footer__global-link {
  color: #005c69;
}

.l-footer {
  color: #fff;
  margin-top: 100px;
}
@media all and (max-width : 768px) {
  .l-footer {
    margin-top: 60px;
  }
}
@media all and (min-width: 769px) {
  .l-footer__main {
    background-color: #f6f6f6;
  }
}
@media all and (min-width: 769px) {
  .l-footer__main-inner {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
  }
}
.l-footer__global-list {
  height: 100%;
  display: flex;
  gap: min(2.77777778vw, 40px);
}
.l-footer__global-item {
  min-width: 0;
}
.l-footer__global-link {
  color: #005c69;
  height: 100%;
  display: flex;
  align-items: center;
}
.l-footer__global-link.c-text {
  font-weight: 500;
  font-size: 15px;
}
@media all and (max-width : 768px) {
  .l-footer__signup {
    width: 100%;
  }
}
@media all and (min-width: 769px) {
  .l-footer__signup {
    min-width: 132px;
    margin-left: auto;
  }
}
.l-footer__signup-link {
  background-color: #005c69;
  border-radius: 4px;
  color: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (min-width: 769px) {
  .l-footer__signup-link {
    height: 40px;
  }
}
@media all and (max-width : 768px) {
  .l-footer__bookstore {
    width: 100%;
  }
}
@media all and (min-width: 769px) {
  .l-footer__bookstore {
    min-width: 132px;
    margin-left: 0.69444444vw;
  }
}
.l-footer__bookstore-link {
  background-color: #1d1d1b;
  border-radius: 4px;
  color: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (min-width: 769px) {
  .l-footer__bookstore-link {
    height: 40px;
  }
}
.l-footer__sub {
  background-color: #005c69;
}
.l-footer__sub-inner {
  padding: clamp(0px, 6.36132316vw, 25px) clamp(0px, 7.63358779vw, 30px) clamp(0px, 10.178117vw, 40px);
}
@media all and (min-width: 769px) {
  .l-footer__sub-inner {
    padding: 23px 0 26px;
    max-width: 1280px;
    margin: 0 auto;
    padding-right: clamp(0px, 2.77777778vw, 40px);
    padding-left: clamp(0px, 2.77777778vw, 40px);
    display: flex;
    justify-content: space-between;
  }
}
@media all and (max-width : 768px) {
  .l-footer__sub-box-01 {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
}
@media all and (min-width: 769px) {
  .l-footer__usage-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.69444444vw min(1.38888889vw, 20px);
  }
}
.l-footer__usage-link {
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: bold;
}
@media all and (max-width : 768px) {
  .l-footer__usage-link {
    padding: 5px 0;
  }
}
.l-footer__usage + .l-footer__usage {
  margin-top: 10px;
}
.l-footer__usage + .l-footer__usage .l-footer__usage-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
}
.l-footer__sub-box-02 {
  flex-shrink: 0;
  min-width: 0;
  display: flex;
}
@media all and (max-width : 768px) {
  .l-footer__sub-box-02 {
    justify-content: center;
  }
}
.l-footer__sns {
  flex-shrink: 0;
  min-width: 0;
  display: flex;
}
@media all and (max-width : 768px) {
  .l-footer__sns {
    margin-top: 47px;
  }
}
@media all and (min-width: 769px) {
  .l-footer__sns {
    margin: 0 -0.69444444vw;
  }
}
.l-footer__sns-list {
  flex-grow: 1;
  display: flex;
}
.l-footer__sns-item-link {
  height: 100%;
  padding: 0 clamp(5px, 2.54452926vw, 10px);
  display: flex;
  align-items: center;
}
@media all and (min-width: 769px) {
  .l-footer__sns-item-link {
    padding: 0 min(0.69444444vw, 10px);
  }
}
.l-footer__privacy {
  display: flex;
  align-items: center;
}
.l-footer__privacy img {
  width: 65px;
}
@media all and (min-width: 769px) {
  .l-footer__privacy img {
    width: 45px;
  }
}
.l-footer__copyright {
  font-size: 11px;
  color: #fff;
  line-height: 1.45454545;
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .l-footer__copyright {
    text-align: center;
  }
}
@media all and (min-width: 769px) {
  .l-footer__copyright {
    margin-top: 10px;
  }
}

/*
  ---------------
  Section
  ---------------
*/
.l-section--inner {
  padding-right: 2.7989821883vw;
  padding-left: 2.7989821883vw;
}
@media all and (min-width: 769px) {
  .l-section--inner {
    box-sizing: content-box;
    max-width: 1200px;
    margin: 0 auto;
    padding-right: min(2.77777778%, 40px);
    padding-left: min(2.77777778%, 40px);
  }
}
@media all and (min-width: 769px) {
  .l-section--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/*
  ----------------
  Sidebar common
  ----------------
*/
/*
  ----------------
  Breadcrumb common
  ----------------
*/
#page-title .breadcrumbs {
  background-color: #f6f6f6;
  margin: 0 calc(50% - 50vw) 25px;
  padding: 12px clamp(0px, 2.77777778vw, 40px);
}
@media all and (min-width: 769px) {
  #page-title .breadcrumbs {
    width: 100vw;
  }
}
@media all and (max-width : 768px) {
  #page-title .breadcrumbs {
    margin-bottom: 23px;
    padding: 12px;
  }
}

@media all and (min-width: 769px) {
  #page-title .breadcrumbs ul {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
  }
}

#page-title .breadcrumbs li {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  position: relative;
}

#page-title .breadcrumbs > ul li:not(:first-child) {
  margin-left: 20px;
}

#page-title .breadcrumbs > ul li:not(:first-child)::before {
  background: url(/f/resources/images/common/icon_arrow_right_black.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  width: 5px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: -12px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  #page-title .breadcrumbs > ul li:not(:first-child)::before {
    left: -14px;
  }
}

#page-title .breadcrumbs li.home {
  color: #1d1d1b;
}

#page-title .breadcrumbs li.end {
  color: #b2b2b2;
}

div.breadcrumbs {
  background-color: #f6f6f6;
  margin: 0 calc(50% - 50vw) 25px;
  overflow: hidden;
  padding: 12px clamp(0px, 2.77777778vw, 40px);
}
@media all and (min-width: 769px) {
  div.breadcrumbs {
    width: 100vw;
  }
}
@media all and (max-width : 768px) {
  div.breadcrumbs {
    margin-bottom: 23px;
    padding: 12px;
  }
}

@media all and (min-width: 769px) {
  .breadcrumbs ul {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
  }
}

.breadcrumbs li {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  position: relative;
}

.breadcrumbs > ul li:not(:first-child) {
  margin-left: 20px;
}

.breadcrumbs > ul li:not(:first-child)::before {
  background: url(/f/resources/images/common/icon_arrow_right_black.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  width: 5px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: -12px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .breadcrumbs > ul li:not(:first-child)::before {
    left: -14px;
  }
}

.breadcrumbs li.home {
  color: #1d1d1b;
}

.breadcrumbs li.end {
  color: #b2b2b2;
}

div.breadcrumbs > ul li.parent:before,
div.breadcrumbs > ul li.end:before,
div.breadcrumbs > ul li.parent:before,
div.breadcrumbs > ul li.end:before {
  content: "";
}

@media all and (max-width : 768px) {
  .breadcrumbs.breadcrumbs-product {
    margin-top: -5px;
  }
}

.breadcrumbs.breadcrumbs-product .end {
  display: none;
}

.breadcrumbs.breadcrumbs-product > li {
  line-height: 1.1;
}

/*
  ----------------
  message common
  ----------------
*/
.l-message {
  background-color: #ffebeb;
}
.l-message__inner {
  padding: 5px 0;
  display: flex;
  align-items: center;
}
@media all and (min-width: 769px) {
  .l-message__inner {
    padding: 8px 0;
  }
}
.l-message__title {
  display: flex;
  align-items: center;
}
.l-message__title-text {
  color: #c10000;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4375;
  margin-left: 2.79898219vw;
}
@media all and (min-width: 769px) {
  .l-message__title-text {
    margin-left: 0.34722222vw;
  }
}
.l-message__date {
  color: #c10000;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.41666667;
}
@media all and (min-width: 769px) {
  .l-message__date {
    font-size: 14px;
    line-height: 1.42857143;
    margin-left: 2.36111111vw;
  }
}
.l-message__text {
  color: #c10000;
  font-size: 12px;
  font-weight: 400;
  flex: auto;
  margin-left: 2.54452926vw;
  white-space: nowrap;
  overflow: hidden;
}
@media all and (min-width: 769px) {
  .l-message__text {
    font-size: 14px;
    line-height: 1.42857143;
    margin-left: 1.04166667vw;
  }
}
.l-message__open {
  border: 1px solid #c10000;
  border-radius: 5px;
  min-width: 0;
  padding: 3px 3px 2px 5px;
  display: flex;
}
.l-message__open-text {
  color: #c10000;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
}
.l-message__open-img {
  max-width: 10px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

/*
  ----------------
  scroll top common
  ----------------
*/
.l-scroll-top {
  display: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  transition: opacity 0.2s;
  position: fixed;
  right: 30px;
  z-index: 10;
}
@media all and (max-width : 768px) {
  .l-scroll-top {
    right: 11px;
  }
}

/*
  ----------------
  message common
  ----------------
*/
.l-modal-bg {
  background-color: rgba(0, 0, 0, 0.6);
  content: "";
  display: none;
  height: 110vh;
  z-index: 0;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

/*
  ----------------
  heading common
  ----------------
*/
.l-heading__inner {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-block: 20px;
  display: flex;
  align-items: center;
}
@media all and (max-width: 768px) {
  .l-heading__inner {
    padding-inline: 12px;
  }
}
.l-heading__title {
  flex: 0 0 200px;
}
@media all and (max-width : 768px) {
  .l-heading__title {
    flex: 0 0 120px;
  }
}
.l-heading__title.flowpack-title {
  font-size: 25px;
  font-weight: 400;
}
@media all and (max-width : 768px) {
  .l-heading__title.flowpack-title {
    font-size: 20px;
  }
}
.l-heading__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-left: 20px;
  margin-right: auto;
}
@media all and (max-width : 768px) {
  .l-heading__description {
    font-size: 11px;
    line-height: 1.36363636;
    margin-left: 12px;
  }
}
.l-heading__btn {
  flex: 0 0 250px;
  margin-left: 12px;
}
@media all and (max-width: 1024px) {
  .l-heading__btn {
    flex: 0 0 36px;
  }
}
.l-heading__btn-link {
  background-color: #00ac5a;
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (min-width: 1025px) {
  .l-heading__btn-link {
    padding-inline: 18px;
  }
}
@media all and (max-width: 1024px) {
  .l-heading__btn-link {
    font-size: 0;
    min-height: 36px;
    position: relative;
  }
}
.l-heading__btn-link > span {
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
}
@media all and (min-width: 1025px) {
  .l-heading__btn-link > span {
    padding-left: 27px;
  }
}
@media all and (max-width: 1024px) {
  .l-heading__btn-link > span {
    width: 36px;
    height: 36px;
  }
}
.l-heading__btn-link > span::before {
  content: "";
  background: transparent url("/f/resources/images/common/icon_search.svg") center/contain no-repeat;
  display: inline-block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media all and (max-width: 1024px) {
  .l-heading__btn-link > span::before {
    width: 18px;
    height: 18px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
/*
  ---------------
  最近チェックした商品、あなたにおすすめの商品 | 共通
  ---------------
*/
.sc-display {
  margin-top: 60px;
}
@media all and (max-width : 768px) {
  .sc-display {
    margin-top: 50px;
  }
}

.sc-display div.display-box {
  position: relative;
}

.sc-display .panel-heading {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .sc-display .panel-heading {
    padding-inline: 12px;
  }
}

.sc-display .display-name {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
  text-align: left;
  padding: 0;
}
@media all and (min-width: 769px) {
  .producttemplate .sc-display .display-name {
    font-size: 24px;
    line-height: 1.45833333;
  }
}

.sc-display div.panel-heading + div.panel-body {
  margin-top: 20px;
  margin-bottom: 0;
}

.sc-display div.display-box ul.item-display {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  display: block;
}
@media all and (max-width: 768px) {
  .sc-display div.display-box ul.item-display {
    padding-inline: 12px;
  }
}

.sc-display div.display-box ul.item-display li {
  padding: 0;
  margin-inline: min(1.04166667vw, 15px);
}
@media all and (max-width : 768px) {
  .sc-display div.display-box ul.item-display li {
    margin-inline: min(2.54452926vw, 10px);
  }
}

.sc-display div.display-box ul.item-display li div.display-sm {
  width: auto;
}

.sc-display div.item-thumbnail-img {
  width: auto;
  height: auto;
}

.sc-display div.item-thumbnail-img-sm a {
  aspect-ratio: 278/245;
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.sc-display div.item-thumbnail-img-sm img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sc-display div.item-thumbnail-img div.no-img {
  border: 0;
  width: 100%;
  height: 100%;
}

.sc-display div.display-box ul.item-display li div.item-name {
  font-size: 14px;
  line-height: 1.42857143;
  text-align: left;
  margin-top: 17px;
  padding: 0;
}
@media all and (max-width : 768px) {
  .sc-display div.display-box ul.item-display li div.item-name {
    font-weight: 500;
    margin-top: 10px;
  }
}

.sc-display .selling-price {
  font-size: 14px;
  line-height: 1.42857143;
  text-align: left;
  margin-top: 10px;
}
@media all and (max-width : 768px) {
  .sc-display .selling-price {
    font-weight: 500;
  }
}

.sc-display div.display-box ul.item-display li div.item-desc {
  display: none;
}

@media all and (min-width: 769px) {
  .sc-display .slick-list {
    margin: 0 min(-1.04166667vw, -15px);
  }
}
@media all and (max-width : 768px) {
  .sc-display .slick-list {
    overflow: visible;
  }
}
.sc-display .slick-track {
  margin: 0;
}
.sc-display .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.sc-display .slick-prev,
.sc-display .slick-next {
  width: 50px;
  height: 50px;
}
@media all and (max-width : 768px) {
  .sc-display .slick-prev,
  .sc-display .slick-next {
    width: 30px;
    height: 60px;
  }
}
.sc-display .slick-prev {
  left: 0;
}
.sc-display .slick-next {
  right: 0;
}
.sc-display .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .sc-display .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .sc-display .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.sc-display .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .sc-display .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .sc-display .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

#product div.sc.partwork,
#product div.sc.related-items.related-products {
  display: none;
}

@media all and (max-width : 768px) {
  body.item .sc-display .display-name {
    text-align: center;
  }
}
@media all and (min-width: 769px) {
  body.item .sc-display .item-display {
    position: relative;
  }
}
@media all and (min-width: 769px) {
  body.item .sc-display .item-display::after {
    content: "";
    display: inline-block;
    background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%);
    width: 181px;
    height: 100%;
    margin-right: calc(50% - 50vw);
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media all and (min-width: 769px) {
  body.item .sc-display .slick-list {
    overflow: visible;
  }
}
@media all and (min-width: 769px) {
  body.item .sc-display .item.article {
    max-width: 240px;
  }
}
body.item .sc-display div.item-thumbnail-img-sm a {
  aspect-ratio: 1/1;
}
@media all and (min-width: 769px) {
  body.item .slick-prev.slick-disabled:before,
  body.item .slick-next.slick-disabled:before {
    opacity: 0;
  }
}

/*
  ----------------
  Pop Message common
  ----------------
*/
div.pop-msg {
  background-color: #ffebeb;
  position: static;
}
@media all and (max-width : 768px) {
  div.pop-msg {
    font-size: 10px;
  }
}
div.pop-msg .msg {
  color: #c10000;
}
div.pop-msg a {
  color: #c10000;
  text-decoration: underline;
}

/*
  ----------------
  Related News 関連コンテンツ
  ----------------
*/
.top-newslist-related-news {
  margin: 0 auto;
  max-width: 1280px;
  padding-top: 70px;
}
.top-newslist-related-news .panel-newslist {
  padding-right: 3.05343511vw;
  padding-left: 3.05343511vw;
  position: relative;
}
@media all and (min-width: 769px) {
  .top-newslist-related-news .panel-newslist {
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 7px;
    padding-right: clamp(0px, 2.77777778vw, 40px);
    padding-left: clamp(0px, 2.77777778vw, 40px);
  }
}
.top-newslist-related-news .panel-news {
  padding-inline: clamp(0px, 2.77777778vw, 40px);
}
.top-newslist-related-news .news-name {
  font-size: 24px;
  text-align: left;
}
.top-newslist-related-news .news-entry {
  border-bottom: 1px solid #d9d9d9;
  padding: 16px 0;
}
@media all and (max-width : 768px) {
  .top-newslist-related-news .news-entry {
    padding: 8px 0;
  }
}
.top-newslist-related-news .news-entry:first-child {
  border-top: 1px solid #d9d9d9;
}
@media all and (max-width : 768px) {
  .top-newslist-related-news .news-type {
    font-size: 12px;
  }
}
.top-newslist-related-news .news-entry .release-date {
  display: none;
}
@media all and (max-width : 768px) {
  .top-newslist-related-news .headline {
    margin-top: 4px;
  }
}
@media all and (max-width : 768px) {
  .top-newslist-related-news div.news-entries div.news-entry div.entry-text {
    padding-left: 0;
    padding-right: 0;
  }
}

/*
  ---------------
  ボタン共通
  ---------------
*/
button.btn {
  background: #f6f6f6;
  border: 1px solid #b2b2b2;
  border-radius: 2px;
  box-shadow: none;
  font-size: 13px;
  margin-bottom: 0;
  min-width: 164px;
  padding: 10px 18px;
}
@media (hover: hover) and (pointer: fine) {
  button.btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  button.btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
button.btn::before, button.btn::after {
  backface-visibility: hidden;
}

a.btn-edit,
a.btn-edit:hover {
  background: #f6f6f6;
  border: 1px solid #b2b2b2;
  border-radius: 2px;
  box-shadow: none;
  font-size: 14px;
  text-align: center;
  padding: 10px 18px;
  width: 100%;
  min-width: 164px;
}
@media (hover: hover) and (pointer: fine) {
  a.btn-edit,
  a.btn-edit:hover {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  a.btn-edit:hover,
  a.btn-edit:hover:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
a.btn-edit::before, a.btn-edit::after,
a.btn-edit:hover::before,
a.btn-edit:hover::after {
  backface-visibility: hidden;
}

#reminder-done .btn-submit a,
.community__talk .signin-to-post .signin,
.account__order_inquiry .order-inq .btn-submit,
.btn-submit .btn,
.cart-checkout .btn {
  border: none;
  border-radius: 2px;
  background: #ff9c08;
  box-shadow: none;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  position: relative;
  text-align: center;
  height: 50px;
  max-width: 175px;
  min-width: 175px;
  width: 100%;
}
@media (hover: hover) and (pointer: fine) {
  #reminder-done .btn-submit a,
  .community__talk .signin-to-post .signin,
  .account__order_inquiry .order-inq .btn-submit,
  .btn-submit .btn,
  .cart-checkout .btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #reminder-done .btn-submit a:hover,
  .community__talk .signin-to-post .signin:hover,
  .account__order_inquiry .order-inq .btn-submit:hover,
  .btn-submit .btn:hover,
  .cart-checkout .btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#reminder-done .btn-submit a::before, #reminder-done .btn-submit a::after,
.community__talk .signin-to-post .signin::before,
.community__talk .signin-to-post .signin::after,
.account__order_inquiry .order-inq .btn-submit::before,
.account__order_inquiry .order-inq .btn-submit::after,
.btn-submit .btn::before,
.btn-submit .btn::after,
.cart-checkout .btn::before,
.cart-checkout .btn::after {
  backface-visibility: hidden;
}
#reminder-done .btn-submit a:hover,
.community__talk .signin-to-post .signin:hover,
.account__order_inquiry .order-inq .btn-submit:hover,
.btn-submit .btn:hover,
.cart-checkout .btn:hover {
  background: #ff9c08;
}

.cart-checkout .btn::before {
  background: url(/f/resources/images/common/icon_arrow_right_orange.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.cart-contents .cart-clear-all,
.cart-contents-saved .cart-clear-all {
  padding-block: 20px 0;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .cart-contents .cart-clear-all,
  .cart-contents-saved .cart-clear-all {
    padding-top: 20px;
  }
}

.order-cancel .btn-cancel-order,
.shipment-cancel .btn-cancel-shipment,
.cart-contents .cart-clear-all .btn,
.cart-contents-saved .cart-clear-all .btn {
  border: none;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background: #f6f6f6;
  box-shadow: none;
  font-size: 16px;
  padding: 10px 15px 10px 30px;
  position: relative;
  height: 50px;
  max-width: fit-content;
  min-width: 175px;
  width: 100%;
}
@media (hover: hover) and (pointer: fine) {
  .order-cancel .btn-cancel-order,
  .shipment-cancel .btn-cancel-shipment,
  .cart-contents .cart-clear-all .btn,
  .cart-contents-saved .cart-clear-all .btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .order-cancel .btn-cancel-order:hover,
  .shipment-cancel .btn-cancel-shipment:hover,
  .cart-contents .cart-clear-all .btn:hover,
  .cart-contents-saved .cart-clear-all .btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.order-cancel .btn-cancel-order::before, .order-cancel .btn-cancel-order::after,
.shipment-cancel .btn-cancel-shipment::before,
.shipment-cancel .btn-cancel-shipment::after,
.cart-contents .cart-clear-all .btn::before,
.cart-contents .cart-clear-all .btn::after,
.cart-contents-saved .cart-clear-all .btn::before,
.cart-contents-saved .cart-clear-all .btn::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .order-cancel .btn-cancel-order,
  .shipment-cancel .btn-cancel-shipment,
  .cart-contents .cart-clear-all .btn,
  .cart-contents-saved .cart-clear-all .btn {
    padding-block: 6px;
    height: 40px;
  }
}
.order-cancel .btn-cancel-order:hover,
.shipment-cancel .btn-cancel-shipment:hover,
.cart-contents .cart-clear-all .btn:hover,
.cart-contents-saved .cart-clear-all .btn:hover {
  background: #f6f6f6;
}
.order-cancel .btn-cancel-order::before,
.shipment-cancel .btn-cancel-shipment::before,
.cart-contents .cart-clear-all .btn::before,
.cart-contents-saved .cart-clear-all .btn::before {
  background: url(/f/resources/images/common/icon_remove_black.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 13px;
  width: 11px;
  position: absolute;
  top: 52%;
  left: 15px;
  transform: translateY(-50%);
}

.cart-contents-saved .cart-clear-all .btn {
  max-width: fit-content;
}

.partwork-subscription .btn.close {
  background-color: #333;
  border: 1px solid #333;
  border-radius: 0;
  box-shadow: none;
  font-size: 0;
  margin-inline: auto;
  min-width: auto;
  padding: 0;
  text-align: left;
  height: 60px;
  width: 60px;
  position: fixed;
  top: 0;
  right: 0;
}
@media (hover: hover) and (pointer: fine) {
  .partwork-subscription .btn.close {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .partwork-subscription .btn.close:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.partwork-subscription .btn.close::before, .partwork-subscription .btn.close::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .partwork-subscription .btn.close {
    height: 50px;
    width: 50px;
  }
}

.partwork-subscription .btn.close::before {
  background: url(/f/resources/images/common/icon_close_large_white.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 14px;
  right: 14px;
}
@media all and (max-width : 768px) {
  .partwork-subscription .btn.close::before {
    top: 8px;
    right: 8px;
  }
}

.guide-flow .guide-flow__link,
.formrow-submit button,
.ln-order-inq a,
.request-paymentmethod-reset button.btn {
  background: #494949 url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) calc(100% - 10px) 50%/24px no-repeat;
  border-radius: 2px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 50px 13px;
  min-width: 175px;
}
@media (hover: hover) and (pointer: fine) {
  .guide-flow .guide-flow__link,
  .formrow-submit button,
  .ln-order-inq a,
  .request-paymentmethod-reset button.btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .guide-flow .guide-flow__link:hover,
  .formrow-submit button:hover,
  .ln-order-inq a:hover,
  .request-paymentmethod-reset button.btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.guide-flow .guide-flow__link::before, .guide-flow .guide-flow__link::after,
.formrow-submit button::before,
.formrow-submit button::after,
.ln-order-inq a::before,
.ln-order-inq a::after,
.request-paymentmethod-reset button.btn::before,
.request-paymentmethod-reset button.btn::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__link,
  .formrow-submit button,
  .ln-order-inq a,
  .request-paymentmethod-reset button.btn {
    width: 100%;
  }
}
.guide-flow .guide-flow__link:hover,
.formrow-submit button:hover,
.ln-order-inq a:hover,
.request-paymentmethod-reset button.btn:hover {
  background: #494949 url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) calc(100% - 10px) 50%/24px no-repeat;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 50px 13px;
  min-width: 175px;
}
.guide-flow .guide-flow__link:visited,
.formrow-submit button:visited,
.ln-order-inq a:visited,
.request-paymentmethod-reset button.btn:visited {
  color: #fff !important;
}

#order-entry #order-entry-content .panel > .control .btn {
  background: #494949;
  border: 1px solid #494949;
  border-radius: 2px;
  color: #fff !important;
  padding: 5px 16px;
  font-size: 14px;
}
@media (hover: hover) and (pointer: fine) {
  #order-entry #order-entry-content .panel > .control .btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #order-entry #order-entry-content .panel > .control .btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#order-entry #order-entry-content .panel > .control .btn::before, #order-entry #order-entry-content .panel > .control .btn::after {
  backface-visibility: hidden;
}

#detach-coupons .btn,
#order-entry-content .panel > .body .control .btn {
  background: #f6f6f6;
  border: 1px solid #b2b2b2;
  border-radius: 2px;
  box-shadow: none;
  font-size: 14px;
  text-align: center;
  padding: 10px 18px;
  width: 100%;
  min-width: 164px;
}
@media (hover: hover) and (pointer: fine) {
  #detach-coupons .btn,
  #order-entry-content .panel > .body .control .btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #detach-coupons .btn:hover,
  #order-entry-content .panel > .body .control .btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#detach-coupons .btn::before, #detach-coupons .btn::after,
#order-entry-content .panel > .body .control .btn::before,
#order-entry-content .panel > .body .control .btn::after {
  backface-visibility: hidden;
}

#order__confirm .btn-submit-checkout {
  border-radius: 2px;
  background: linear-gradient(90deg, #ff9c08 0%, #ffbd08 100%);
  border: none;
  box-shadow: none;
  color: #fff !important;
  font-size: 24px;
  margin-inline: auto;
  height: 80px;
  display: grid;
  place-items: center;
}
@media (hover: hover) and (pointer: fine) {
  #order__confirm .btn-submit-checkout {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #order__confirm .btn-submit-checkout:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#order__confirm .btn-submit-checkout::before, #order__confirm .btn-submit-checkout::after {
  backface-visibility: hidden;
}
@media all and (min-width: 769px) {
  #order__confirm .btn-submit-checkout {
    min-width: 560px;
  }
}
@media all and (max-width : 768px) {
  #order__confirm .btn-submit-checkout {
    font-size: 24px;
    padding: 10px 20px;
    height: 60px;
    width: 100%;
    max-width: 370px;
  }
}

.rp-payment-method .btn-payment,
.manage-paymentmethod .btn-payment,
.manage-paymentmethod .btn-payment,
#order__payment .btn-payment,
#order__sender .addressbook .btn-addressbook,
#order__consignee .addressbook .btn-addressbook,
#order__orderer .addressbook .btn-addressbook,
#order__confirm .btn-moveitem .btn {
  border-radius: 2px;
  background: #005c69;
  border: none;
  box-shadow: none;
  color: #fff !important;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 300px;
  display: grid;
  place-items: center;
}
@media (hover: hover) and (pointer: fine) {
  .rp-payment-method .btn-payment,
  .manage-paymentmethod .btn-payment,
  .manage-paymentmethod .btn-payment,
  #order__payment .btn-payment,
  #order__sender .addressbook .btn-addressbook,
  #order__consignee .addressbook .btn-addressbook,
  #order__orderer .addressbook .btn-addressbook,
  #order__confirm .btn-moveitem .btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .rp-payment-method .btn-payment:hover,
  .manage-paymentmethod .btn-payment:hover,
  .manage-paymentmethod .btn-payment:hover,
  #order__payment .btn-payment:hover,
  #order__sender .addressbook .btn-addressbook:hover,
  #order__consignee .addressbook .btn-addressbook:hover,
  #order__orderer .addressbook .btn-addressbook:hover,
  #order__confirm .btn-moveitem .btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.rp-payment-method .btn-payment::before, .rp-payment-method .btn-payment::after,
.manage-paymentmethod .btn-payment::before,
.manage-paymentmethod .btn-payment::after,
.manage-paymentmethod .btn-payment::before,
.manage-paymentmethod .btn-payment::after,
#order__payment .btn-payment::before,
#order__payment .btn-payment::after,
#order__sender .addressbook .btn-addressbook::before,
#order__sender .addressbook .btn-addressbook::after,
#order__consignee .addressbook .btn-addressbook::before,
#order__consignee .addressbook .btn-addressbook::after,
#order__orderer .addressbook .btn-addressbook::before,
#order__orderer .addressbook .btn-addressbook::after,
#order__confirm .btn-moveitem .btn::before,
#order__confirm .btn-moveitem .btn::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .rp-payment-method .btn-payment,
  .manage-paymentmethod .btn-payment,
  .manage-paymentmethod .btn-payment,
  #order__payment .btn-payment,
  #order__sender .addressbook .btn-addressbook,
  #order__consignee .addressbook .btn-addressbook,
  #order__orderer .addressbook .btn-addressbook,
  #order__confirm .btn-moveitem .btn {
    min-height: 50px;
    min-width: 250px;
    max-width: 300px;
    height: fit-content;
    width: 100%;
  }
}

.manage-paymentmethod .btn-inline,
.manage-addressbook .btn-use-as-default,
.manage-addressbook .btn-group a,
.manage-addressbook .btn-delete,
.order-cancel-edit > a {
  border: none;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background: #f6f6f6;
  box-shadow: none;
  font-size: 16px;
  padding: 10px 15px 9px;
  position: relative;
  display: grid;
  place-items: center;
  height: 50px;
  max-width: max-content;
  min-width: 175px;
  width: 100%;
}
@media (hover: hover) and (pointer: fine) {
  .manage-paymentmethod .btn-inline,
  .manage-addressbook .btn-use-as-default,
  .manage-addressbook .btn-group a,
  .manage-addressbook .btn-delete,
  .order-cancel-edit > a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .manage-paymentmethod .btn-inline:hover,
  .manage-addressbook .btn-use-as-default:hover,
  .manage-addressbook .btn-group a:hover,
  .manage-addressbook .btn-delete:hover,
  .order-cancel-edit > a:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.manage-paymentmethod .btn-inline::before, .manage-paymentmethod .btn-inline::after,
.manage-addressbook .btn-use-as-default::before,
.manage-addressbook .btn-use-as-default::after,
.manage-addressbook .btn-group a::before,
.manage-addressbook .btn-group a::after,
.manage-addressbook .btn-delete::before,
.manage-addressbook .btn-delete::after,
.order-cancel-edit > a::before,
.order-cancel-edit > a::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .manage-paymentmethod .btn-inline,
  .manage-addressbook .btn-use-as-default,
  .manage-addressbook .btn-group a,
  .manage-addressbook .btn-delete,
  .order-cancel-edit > a {
    max-width: 100%;
  }
}
.manage-paymentmethod .btn-inline:hover,
.manage-addressbook .btn-use-as-default:hover,
.manage-addressbook .btn-group a:hover,
.manage-addressbook .btn-delete:hover,
.order-cancel-edit > a:hover {
  background: #f6f6f6;
  box-shadow: none;
  font-size: 16px;
  display: grid;
  padding: 10px 15px 9px;
}

.manage-paymentmethod .btn-inline,
.manage-addressbook .btn-use-as-default,
.manage-addressbook .btn-group a,
.manage-addressbook .btn-delete {
  font-size: 14px;
  height: 40px;
  padding: 8px 15px;
  min-width: 100px;
}
.manage-paymentmethod .btn-inline:hover,
.manage-addressbook .btn-use-as-default:hover,
.manage-addressbook .btn-group a:hover,
.manage-addressbook .btn-delete:hover {
  font-size: 14px;
  padding: 8px 15px;
}

#order__complete .order-process-main-complete .view-order-detail a {
  background: #005c69;
  border-radius: 2px;
  color: #fff !important;
  font-size: 16px;
  margin-top: 0;
  padding: 13px 24px 14px 50px;
  position: relative;
  text-decoration: none;
  min-width: min-content;
  width: fit-content;
}
@media (hover: hover) and (pointer: fine) {
  #order__complete .order-process-main-complete .view-order-detail a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #order__complete .order-process-main-complete .view-order-detail a:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#order__complete .order-process-main-complete .view-order-detail a::before, #order__complete .order-process-main-complete .view-order-detail a::after {
  backface-visibility: hidden;
}
#order__complete .order-process-main-complete .view-order-detail a::before {
  background: url(/f/resources/images/common/icon_receipt.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 19px;
  width: 19px;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
}

#account__view_inquiries .inq-response button.btn-submit {
  background: #005c69;
  border-radius: 2px;
  color: #fff !important;
  font-size: 16px;
  margin-top: 0;
  padding: 13px 24px 14px 50px;
  position: relative;
  min-width: min-content;
  width: fit-content;
}
@media (hover: hover) and (pointer: fine) {
  #account__view_inquiries .inq-response button.btn-submit {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #account__view_inquiries .inq-response button.btn-submit:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#account__view_inquiries .inq-response button.btn-submit::before, #account__view_inquiries .inq-response button.btn-submit::after {
  backface-visibility: hidden;
}
#account__view_inquiries .inq-response button.btn-submit::before {
  background: url(/f/resources/images/common/icon_edit.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 19px;
  width: 19px;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
}

.btn-account {
  position: absolute;
  right: 0;
  bottom: -100px;
}

@media all and (max-width : 768px) {
  #page_aside1 .btn-account {
    display: none;
  }
}

#page_aside2 .btn-account {
  text-align: center;
  margin-top: 40px;
  position: static;
}
@media all and (max-width : 768px) {
  #page_aside2 .btn-account {
    margin-top: 50px;
  }
}

.guide-faq .trailer-link a,
.derived-subscriptions a.btn-view-pwsubscription,
body.news .news-archive-faq .trailer-link a,
body.news .news-archive-faq_imported .trailer-link a,
body.community__threadcreated #page-wrapper .link a,
.btn-account a,
.contact-complete__link a {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  box-shadow: none;
  color: #005c69;
  padding: 12px 50px 12px 12px;
  position: relative;
  width: fit-content;
}
@media all and (max-width : 768px) {
  .guide-faq .trailer-link a,
  .derived-subscriptions a.btn-view-pwsubscription,
  body.news .news-archive-faq .trailer-link a,
  body.news .news-archive-faq_imported .trailer-link a,
  body.community__threadcreated #page-wrapper .link a,
  .btn-account a,
  .contact-complete__link a {
    padding: 8px 12px;
  }
}
.guide-faq .trailer-link a:hover,
.derived-subscriptions a.btn-view-pwsubscription:hover,
body.news .news-archive-faq .trailer-link a:hover,
body.news .news-archive-faq_imported .trailer-link a:hover,
body.community__threadcreated #page-wrapper .link a:hover,
.btn-account a:hover,
.contact-complete__link a:hover {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  box-shadow: none;
  color: #005c69;
  padding: 12px 50px 12px 12px;
}

.guide-faq .trailer-link a::before,
.derived-subscriptions .btn-view-pwsubscription::before,
body.news .news-archive-faq .trailer-link a::before,
body.news .news-archive-faq_imported .trailer-link a::before,
body.community__threadcreated #page-wrapper .link a::before,
.btn-account a::before,
.contact-complete__link a::before {
  background: url(/f/resources/images/common/icon_arrow_right_circle_green.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .guide-faq .trailer-link a::before,
  .derived-subscriptions .btn-view-pwsubscription::before,
  body.news .news-archive-faq .trailer-link a::before,
  body.news .news-archive-faq_imported .trailer-link a::before,
  body.community__threadcreated #page-wrapper .link a::before,
  .btn-account a::before,
  .contact-complete__link a::before {
    display: none;
  }
}

body.news .news-archive-faq .trailer-link a,
body.news .news-archive-faq_imported .trailer-link a,
body.community__threadcreated #page-wrapper .link a,
#page_aside2 .btn-account a,
.contact-complete__link a {
  padding: 12px 12px 12px 50px;
}
@media all and (max-width : 768px) {
  body.news .news-archive-faq .trailer-link a,
  body.news .news-archive-faq_imported .trailer-link a,
  body.community__threadcreated #page-wrapper .link a,
  #page_aside2 .btn-account a,
  .contact-complete__link a {
    display: inline-block;
    width: max-content;
  }
}
body.news .news-archive-faq .trailer-link a:hover,
body.news .news-archive-faq_imported .trailer-link a:hover,
body.community__threadcreated #page-wrapper .link a:hover,
#page_aside2 .btn-account a:hover,
.contact-complete__link a:hover {
  padding: 12px 12px 12px 50px;
}

body.news .news-archive-faq .trailer-link a::before,
body.news .news-archive-faq_imported .trailer-link a::before,
body.community__threadcreated #page-wrapper .link a::before,
#page_aside2 .btn-account a::before,
.contact-complete__link a::before {
  right: auto;
  left: 14px;
  transform: translateY(-50%) rotate(180deg);
}
@media all and (max-width : 768px) {
  body.news .news-archive-faq .trailer-link a::before,
  body.news .news-archive-faq_imported .trailer-link a::before,
  body.community__threadcreated #page-wrapper .link a::before,
  #page_aside2 .btn-account a::before,
  .contact-complete__link a::before {
    display: block;
  }
}

#form .form-auth.form-signin .btn-submit-signup,
#container .form-auth.form-signin .btn-submit-signup,
#signup .signup-form button,
#signin .signup-form button,
#signout .signout .linkarea__button {
  background-image: none;
  background-color: #005c69;
  border: 0;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  margin: 10px auto 0;
  padding: 10px;
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  max-width: 420px;
  height: 50px;
}

.isp__linkbtn {
  background-color: #00828c;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  height: 70px;
  width: 470px;
  display: grid;
  place-items: center;
}
@media all and (max-width : 768px) {
  .isp__linkbtn {
    height: 60px;
    width: 330px;
  }
}

.isp__linkbtn::before {
  background: url(/f/resources/images/common/icon_arrow_right_circle_white.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

.backlink.community-list a {
  background: #005c69;
  border: none;
  border-radius: 30px;
  box-shadow: none;
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  padding-inline: 16px 38px;
  position: relative;
  display: flex;
  align-items: center;
}
@media (hover: hover) and (pointer: fine) {
  .backlink.community-list a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .backlink.community-list a:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.backlink.community-list a::before, .backlink.community-list a::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .backlink.community-list a {
    font-size: 14px;
    height: 40px;
    margin-top: 0;
  }
}
.backlink.community-list a::after {
  background: transparent url(/f/resources/images/common/icon_arrow_right_circle_white.svg) center/contain no-repeat;
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
}

/*
  ---------------
  フォーム共通
  ---------------
*/
/*--------------------------------------------------
  ログイン
--------------------------------------------------*/
.form.form-order-signin {
  padding-top: 40px;
}

.formrow .input-control .input-group {
  position: relative;
}

.password-str-meter {
  color: #b2b2b2;
  font-size: 14px;
  text-align: right;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: -20px;
}

.form .reminder,
.form #signout-at-signin {
  text-align: center;
  padding-top: 8px;
}

.form .reminder a {
  font-size: 16px;
  text-decoration: underline;
}

.formrow .input-control .input-group .signout a {
  font-size: 14px;
  text-decoration: underline;
}

.form-edit-account .password-str-meter {
  bottom: -10px;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.form-edit-account .new-password .password-str-meter .label {
  background: #1d1d1b;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  padding: 2px 10px 3px;
}
.form-edit-account .new-password .password-str-meter .msg {
  background: #fff;
  border: 1px solid #1d1d1b;
  border-radius: 0 2px 2px 0;
  font-size: 12px;
  margin-inline: -7px auto;
  color: #c10000;
  line-height: 1.7;
  letter-spacing: 5px;
  text-indent: 5px;
}

/*--------------------------------------------------
  フォーム全般
--------------------------------------------------*/
@media all and (max-width : 768px) {
  .formrow .input-control {
    padding-top: 8px;
  }
}

@media all and (max-width : 768px) {
  .formrow .input-control .input-note {
    padding-top: 8px;
  }
}

@media all and (max-width : 768px) {
  .form .formrow div.input-label span.input-name {
    margin-right: 10px;
  }
}

@media all and (max-width : 768px) {
  body .form div.form-note,
  .form .formrow {
    padding-inline: 0;
  }
}

.entry_password_reminder .form-body,
.order__points .form-points .form,
.order__sender .form-sender .form .input-group-address,
.order__deliveryservice .form-deliveryservice .form-body,
.update_password .form-body {
  background: #f6f6f6;
  margin: 1em 0;
  padding: 40px 45px;
}
@media all and (max-width : 768px) {
  .entry_password_reminder .form-body,
  .order__points .form-points .form,
  .order__sender .form-sender .form .input-group-address,
  .order__deliveryservice .form-deliveryservice .form-body,
  .update_password .form-body {
    padding: 20px 16px;
  }
}

form .formrow .password-str-meter {
  bottom: 0;
  margin: 0;
  padding-top: 8px;
  position: relative;
}
form .formrow .password-str-meter .unmask-pass span {
  font-size: 14px;
  position: relative;
  width: max-content;
}
@media (hover: hover) and (pointer: fine) {
  form .formrow .password-str-meter .unmask-pass span {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  form .formrow .password-str-meter .unmask-pass span:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
form .formrow .password-str-meter .unmask-pass span::before, form .formrow .password-str-meter .unmask-pass span::after {
  backface-visibility: hidden;
}
@media all and (min-width: 769px) {
  form .formrow .password-str-meter .unmask-pass span {
    position: absolute;
    top: -31px;
    right: 20px;
  }
}
@media all and (min-width: 769px) {
  form .formrow .password-str-meter .unmask-pass span::before {
    background: url(/f/resources/images/common/icon_eye.svg) 50%/contain no-repeat;
    content: "";
    display: block;
    height: 13px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translateY(-50%);
  }
}
@media all and (min-width: 769px) {
  form .formrow input[name=PASSWORD],
  form .formrow input[type=password] {
    padding-right: 170px;
  }
}

#form fieldset,
.order__signin fieldset,
.order__payment fieldset,
.order__orderer fieldset,
.update_password fieldset {
  background: #f6f6f6;
  border-radius: 0;
  border: none;
  margin-bottom: 0;
  padding: 40px 45px;
}
@media all and (max-width : 768px) {
  #form fieldset,
  .order__signin fieldset,
  .order__payment fieldset,
  .order__orderer fieldset,
  .update_password fieldset {
    padding: 20px 16px;
  }
}

.account__order_inquiry fieldset + fieldset,
.account__close_account fieldset + fieldset,
.account__edit_address fieldset + fieldset,
.account__edit_account fieldset + fieldset,
.account__edit_account_properties fieldset + fieldset,
.account__edit_profile fieldset + fieldset,
.account__order fieldset + fieldset,
.account__manage_addressbook fieldset + fieldset,
.account__manage_paymentmethod fieldset + fieldset,
.account__view_account_message_history fieldset + fieldset,
.account__view_orders fieldset + fieldset,
.account__view_order_history fieldset + fieldset,
.account__view_inquiries fieldset + fieldset,
.account__view_points_history fieldset + fieldset,
.account__your_coupons fieldset + fieldset,
.create-new-thread fieldset + fieldset,
.entry_password_reminder fieldset + fieldset,
#form fieldset + fieldset,
.order__confirm fieldset + fieldset,
.order__signin_to_checkout fieldset + fieldset,
.order__signin fieldset + fieldset,
.order__payment fieldset + fieldset,
.order__points fieldset + fieldset,
.order__sender .form-sender .form fieldset + fieldset,
.order__consignee .form-consignee .form fieldset + fieldset,
.order__deliveryservice .form-deliveryservice .form-body fieldset + fieldset,
.order__orderer .form-signin form fieldset + fieldset,
.order__orderer fieldset + fieldset,
.post-thread-comment-wrapper fieldset + fieldset,
.update_password fieldset + fieldset {
  border-top: 1px solid #d9d9d9;
  margin: 0;
}
.account__order_inquiry .formrow,
.account__close_account .formrow,
.account__edit_address .formrow,
.account__edit_account .formrow,
.account__edit_account_properties .formrow,
.account__edit_profile .formrow,
.account__order .formrow,
.account__manage_addressbook .formrow,
.account__manage_paymentmethod .formrow,
.account__view_account_message_history .formrow,
.account__view_orders .formrow,
.account__view_order_history .formrow,
.account__view_inquiries .formrow,
.account__view_points_history .formrow,
.account__your_coupons .formrow,
.create-new-thread .formrow,
.entry_password_reminder .formrow,
#form .formrow,
.order__confirm .formrow,
.order__signin_to_checkout .formrow,
.order__signin .formrow,
.order__payment .formrow,
.order__points .formrow,
.order__sender .form-sender .form .formrow,
.order__consignee .form-consignee .form .formrow,
.order__deliveryservice .form-deliveryservice .form-body .formrow,
.order__orderer .form-signin form .formrow,
.order__orderer .formrow,
.post-thread-comment-wrapper .formrow,
.update_password .formrow {
  border: none;
  display: flex;
  flex-direction: column;
}
@media all and (max-width : 768px) {
  .account__order_inquiry .formrow,
  .account__close_account .formrow,
  .account__edit_address .formrow,
  .account__edit_account .formrow,
  .account__edit_account_properties .formrow,
  .account__edit_profile .formrow,
  .account__order .formrow,
  .account__manage_addressbook .formrow,
  .account__manage_paymentmethod .formrow,
  .account__view_account_message_history .formrow,
  .account__view_orders .formrow,
  .account__view_order_history .formrow,
  .account__view_inquiries .formrow,
  .account__view_points_history .formrow,
  .account__your_coupons .formrow,
  .create-new-thread .formrow,
  .entry_password_reminder .formrow,
  #form .formrow,
  .order__confirm .formrow,
  .order__signin_to_checkout .formrow,
  .order__signin .formrow,
  .order__payment .formrow,
  .order__points .formrow,
  .order__sender .form-sender .form .formrow,
  .order__consignee .form-consignee .form .formrow,
  .order__deliveryservice .form-deliveryservice .form-body .formrow,
  .order__orderer .form-signin form .formrow,
  .order__orderer .formrow,
  .post-thread-comment-wrapper .formrow,
  .update_password .formrow {
    padding: 0;
  }
}
.account__order_inquiry .formrow:not(:first-child),
.account__close_account .formrow:not(:first-child),
.account__edit_address .formrow:not(:first-child),
.account__edit_account .formrow:not(:first-child),
.account__edit_account_properties .formrow:not(:first-child),
.account__edit_profile .formrow:not(:first-child),
.account__order .formrow:not(:first-child),
.account__manage_addressbook .formrow:not(:first-child),
.account__manage_paymentmethod .formrow:not(:first-child),
.account__view_account_message_history .formrow:not(:first-child),
.account__view_orders .formrow:not(:first-child),
.account__view_order_history .formrow:not(:first-child),
.account__view_inquiries .formrow:not(:first-child),
.account__view_points_history .formrow:not(:first-child),
.account__your_coupons .formrow:not(:first-child),
.create-new-thread .formrow:not(:first-child),
.entry_password_reminder .formrow:not(:first-child),
#form .formrow:not(:first-child),
.order__confirm .formrow:not(:first-child),
.order__signin_to_checkout .formrow:not(:first-child),
.order__signin .formrow:not(:first-child),
.order__payment .formrow:not(:first-child),
.order__points .formrow:not(:first-child),
.order__sender .form-sender .form .formrow:not(:first-child),
.order__consignee .form-consignee .form .formrow:not(:first-child),
.order__deliveryservice .form-deliveryservice .form-body .formrow:not(:first-child),
.order__orderer .form-signin form .formrow:not(:first-child),
.order__orderer .formrow:not(:first-child),
.post-thread-comment-wrapper .formrow:not(:first-child),
.update_password .formrow:not(:first-child) {
  margin-top: 16px;
}
.account__order_inquiry .formrow .input-label,
.account__close_account .formrow .input-label,
.account__edit_address .formrow .input-label,
.account__edit_account .formrow .input-label,
.account__edit_account_properties .formrow .input-label,
.account__edit_profile .formrow .input-label,
.account__order .formrow .input-label,
.account__manage_addressbook .formrow .input-label,
.account__manage_paymentmethod .formrow .input-label,
.account__view_account_message_history .formrow .input-label,
.account__view_orders .formrow .input-label,
.account__view_order_history .formrow .input-label,
.account__view_inquiries .formrow .input-label,
.account__view_points_history .formrow .input-label,
.account__your_coupons .formrow .input-label,
.create-new-thread .formrow .input-label,
.entry_password_reminder .formrow .input-label,
#form .formrow .input-label,
.order__confirm .formrow .input-label,
.order__signin_to_checkout .formrow .input-label,
.order__signin .formrow .input-label,
.order__payment .formrow .input-label,
.order__points .formrow .input-label,
.order__sender .form-sender .form .formrow .input-label,
.order__consignee .form-consignee .form .formrow .input-label,
.order__deliveryservice .form-deliveryservice .form-body .formrow .input-label,
.order__orderer .form-signin form .formrow .input-label,
.order__orderer .formrow .input-label,
.post-thread-comment-wrapper .formrow .input-label,
.update_password .formrow .input-label {
  display: flex;
  align-items: center;
}
.account__order_inquiry .formrow .input-label,
.account__order_inquiry .formrow .input-control,
.account__close_account .formrow .input-label,
.account__close_account .formrow .input-control,
.account__edit_address .formrow .input-label,
.account__edit_address .formrow .input-control,
.account__edit_account .formrow .input-label,
.account__edit_account .formrow .input-control,
.account__edit_account_properties .formrow .input-label,
.account__edit_account_properties .formrow .input-control,
.account__edit_profile .formrow .input-label,
.account__edit_profile .formrow .input-control,
.account__order .formrow .input-label,
.account__order .formrow .input-control,
.account__manage_addressbook .formrow .input-label,
.account__manage_addressbook .formrow .input-control,
.account__manage_paymentmethod .formrow .input-label,
.account__manage_paymentmethod .formrow .input-control,
.account__view_account_message_history .formrow .input-label,
.account__view_account_message_history .formrow .input-control,
.account__view_orders .formrow .input-label,
.account__view_orders .formrow .input-control,
.account__view_order_history .formrow .input-label,
.account__view_order_history .formrow .input-control,
.account__view_inquiries .formrow .input-label,
.account__view_inquiries .formrow .input-control,
.account__view_points_history .formrow .input-label,
.account__view_points_history .formrow .input-control,
.account__your_coupons .formrow .input-label,
.account__your_coupons .formrow .input-control,
.create-new-thread .formrow .input-label,
.create-new-thread .formrow .input-control,
.entry_password_reminder .formrow .input-label,
.entry_password_reminder .formrow .input-control,
#form .formrow .input-label,
#form .formrow .input-control,
.order__confirm .formrow .input-label,
.order__confirm .formrow .input-control,
.order__signin_to_checkout .formrow .input-label,
.order__signin_to_checkout .formrow .input-control,
.order__signin .formrow .input-label,
.order__signin .formrow .input-control,
.order__payment .formrow .input-label,
.order__payment .formrow .input-control,
.order__points .formrow .input-label,
.order__points .formrow .input-control,
.order__sender .form-sender .form .formrow .input-label,
.order__sender .form-sender .form .formrow .input-control,
.order__consignee .form-consignee .form .formrow .input-label,
.order__consignee .form-consignee .form .formrow .input-control,
.order__deliveryservice .form-deliveryservice .form-body .formrow .input-label,
.order__deliveryservice .form-deliveryservice .form-body .formrow .input-control,
.order__orderer .form-signin form .formrow .input-label,
.order__orderer .form-signin form .formrow .input-control,
.order__orderer .formrow .input-label,
.order__orderer .formrow .input-control,
.post-thread-comment-wrapper .formrow .input-label,
.post-thread-comment-wrapper .formrow .input-control,
.update_password .formrow .input-label,
.update_password .formrow .input-control {
  padding: 0;
}
.account__order_inquiry .formrow .input-label span.input-name,
.account__close_account .formrow .input-label span.input-name,
.account__edit_address .formrow .input-label span.input-name,
.account__edit_account .formrow .input-label span.input-name,
.account__edit_account_properties .formrow .input-label span.input-name,
.account__edit_profile .formrow .input-label span.input-name,
.account__order .formrow .input-label span.input-name,
.account__manage_addressbook .formrow .input-label span.input-name,
.account__manage_paymentmethod .formrow .input-label span.input-name,
.account__view_account_message_history .formrow .input-label span.input-name,
.account__view_orders .formrow .input-label span.input-name,
.account__view_order_history .formrow .input-label span.input-name,
.account__view_inquiries .formrow .input-label span.input-name,
.account__view_points_history .formrow .input-label span.input-name,
.account__your_coupons .formrow .input-label span.input-name,
.create-new-thread .formrow .input-label span.input-name,
.entry_password_reminder .formrow .input-label span.input-name,
#form .formrow .input-label span.input-name,
.order__confirm .formrow .input-label span.input-name,
.order__signin_to_checkout .formrow .input-label span.input-name,
.order__signin .formrow .input-label span.input-name,
.order__payment .formrow .input-label span.input-name,
.order__points .formrow .input-label span.input-name,
.order__sender .form-sender .form .formrow .input-label span.input-name,
.order__consignee .form-consignee .form .formrow .input-label span.input-name,
.order__deliveryservice .form-deliveryservice .form-body .formrow .input-label span.input-name,
.order__orderer .form-signin form .formrow .input-label span.input-name,
.order__orderer .formrow .input-label span.input-name,
.post-thread-comment-wrapper .formrow .input-label span.input-name,
.update_password .formrow .input-label span.input-name {
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}
.account__order_inquiry .formrow .input-control,
.account__close_account .formrow .input-control,
.account__edit_address .formrow .input-control,
.account__edit_account .formrow .input-control,
.account__edit_account_properties .formrow .input-control,
.account__edit_profile .formrow .input-control,
.account__order .formrow .input-control,
.account__manage_addressbook .formrow .input-control,
.account__manage_paymentmethod .formrow .input-control,
.account__view_account_message_history .formrow .input-control,
.account__view_orders .formrow .input-control,
.account__view_order_history .formrow .input-control,
.account__view_inquiries .formrow .input-control,
.account__view_points_history .formrow .input-control,
.account__your_coupons .formrow .input-control,
.create-new-thread .formrow .input-control,
.entry_password_reminder .formrow .input-control,
#form .formrow .input-control,
.order__confirm .formrow .input-control,
.order__signin_to_checkout .formrow .input-control,
.order__signin .formrow .input-control,
.order__payment .formrow .input-control,
.order__points .formrow .input-control,
.order__sender .form-sender .form .formrow .input-control,
.order__consignee .form-consignee .form .formrow .input-control,
.order__deliveryservice .form-deliveryservice .form-body .formrow .input-control,
.order__orderer .form-signin form .formrow .input-control,
.order__orderer .formrow .input-control,
.post-thread-comment-wrapper .formrow .input-control,
.update_password .formrow .input-control {
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  gap: 10px;
}
.account__order_inquiry .formrow .input-control .input-note,
.account__close_account .formrow .input-control .input-note,
.account__edit_address .formrow .input-control .input-note,
.account__edit_account .formrow .input-control .input-note,
.account__edit_account_properties .formrow .input-control .input-note,
.account__edit_profile .formrow .input-control .input-note,
.account__order .formrow .input-control .input-note,
.account__manage_addressbook .formrow .input-control .input-note,
.account__manage_paymentmethod .formrow .input-control .input-note,
.account__view_account_message_history .formrow .input-control .input-note,
.account__view_orders .formrow .input-control .input-note,
.account__view_order_history .formrow .input-control .input-note,
.account__view_inquiries .formrow .input-control .input-note,
.account__view_points_history .formrow .input-control .input-note,
.account__your_coupons .formrow .input-control .input-note,
.create-new-thread .formrow .input-control .input-note,
.entry_password_reminder .formrow .input-control .input-note,
#form .formrow .input-control .input-note,
.order__confirm .formrow .input-control .input-note,
.order__signin_to_checkout .formrow .input-control .input-note,
.order__signin .formrow .input-control .input-note,
.order__payment .formrow .input-control .input-note,
.order__points .formrow .input-control .input-note,
.order__sender .form-sender .form .formrow .input-control .input-note,
.order__consignee .form-consignee .form .formrow .input-control .input-note,
.order__deliveryservice .form-deliveryservice .form-body .formrow .input-control .input-note,
.order__orderer .form-signin form .formrow .input-control .input-note,
.order__orderer .formrow .input-control .input-note,
.post-thread-comment-wrapper .formrow .input-control .input-note,
.update_password .formrow .input-control .input-note {
  margin-left: 0;
  padding-left: 0;
}
.account__order_inquiry .formrow input.required,
.account__close_account .formrow input.required,
.account__edit_address .formrow input.required,
.account__edit_account .formrow input.required,
.account__edit_account_properties .formrow input.required,
.account__edit_profile .formrow input.required,
.account__order .formrow input.required,
.account__manage_addressbook .formrow input.required,
.account__manage_paymentmethod .formrow input.required,
.account__view_account_message_history .formrow input.required,
.account__view_orders .formrow input.required,
.account__view_order_history .formrow input.required,
.account__view_inquiries .formrow input.required,
.account__view_points_history .formrow input.required,
.account__your_coupons .formrow input.required,
.create-new-thread .formrow input.required,
.entry_password_reminder .formrow input.required,
#form .formrow input.required,
.order__confirm .formrow input.required,
.order__signin_to_checkout .formrow input.required,
.order__signin .formrow input.required,
.order__payment .formrow input.required,
.order__points .formrow input.required,
.order__sender .form-sender .form .formrow input.required,
.order__consignee .form-consignee .form .formrow input.required,
.order__deliveryservice .form-deliveryservice .form-body .formrow input.required,
.order__orderer .form-signin form .formrow input.required,
.order__orderer .formrow input.required,
.post-thread-comment-wrapper .formrow input.required,
.update_password .formrow input.required {
  background: #fff;
}
.account__order_inquiry .formrow input,
.account__close_account .formrow input,
.account__edit_address .formrow input,
.account__edit_account .formrow input,
.account__edit_account_properties .formrow input,
.account__edit_profile .formrow input,
.account__order .formrow input,
.account__manage_addressbook .formrow input,
.account__manage_paymentmethod .formrow input,
.account__view_account_message_history .formrow input,
.account__view_orders .formrow input,
.account__view_order_history .formrow input,
.account__view_inquiries .formrow input,
.account__view_points_history .formrow input,
.account__your_coupons .formrow input,
.create-new-thread .formrow input,
.entry_password_reminder .formrow input,
#form .formrow input,
.order__confirm .formrow input,
.order__signin_to_checkout .formrow input,
.order__signin .formrow input,
.order__payment .formrow input,
.order__points .formrow input,
.order__sender .form-sender .form .formrow input,
.order__consignee .form-consignee .form .formrow input,
.order__deliveryservice .form-deliveryservice .form-body .formrow input,
.order__orderer .form-signin form .formrow input,
.order__orderer .formrow input,
.post-thread-comment-wrapper .formrow input,
.update_password .formrow input {
  border: 1px solid #d9d9d9;
}
.account__order_inquiry .formrow.formrow-file input,
.account__close_account .formrow.formrow-file input,
.account__edit_address .formrow.formrow-file input,
.account__edit_account .formrow.formrow-file input,
.account__edit_account_properties .formrow.formrow-file input,
.account__edit_profile .formrow.formrow-file input,
.account__order .formrow.formrow-file input,
.account__manage_addressbook .formrow.formrow-file input,
.account__manage_paymentmethod .formrow.formrow-file input,
.account__view_account_message_history .formrow.formrow-file input,
.account__view_orders .formrow.formrow-file input,
.account__view_order_history .formrow.formrow-file input,
.account__view_inquiries .formrow.formrow-file input,
.account__view_points_history .formrow.formrow-file input,
.account__your_coupons .formrow.formrow-file input,
.create-new-thread .formrow.formrow-file input,
.entry_password_reminder .formrow.formrow-file input,
#form .formrow.formrow-file input,
.order__confirm .formrow.formrow-file input,
.order__signin_to_checkout .formrow.formrow-file input,
.order__signin .formrow.formrow-file input,
.order__payment .formrow.formrow-file input,
.order__points .formrow.formrow-file input,
.order__sender .form-sender .form .formrow.formrow-file input,
.order__consignee .form-consignee .form .formrow.formrow-file input,
.order__deliveryservice .form-deliveryservice .form-body .formrow.formrow-file input,
.order__orderer .form-signin form .formrow.formrow-file input,
.order__orderer .formrow.formrow-file input,
.post-thread-comment-wrapper .formrow.formrow-file input,
.update_password .formrow.formrow-file input {
  border: none;
}
.account__order_inquiry .formrow-yyyymmdd .input-group,
.account__close_account .formrow-yyyymmdd .input-group,
.account__edit_address .formrow-yyyymmdd .input-group,
.account__edit_account .formrow-yyyymmdd .input-group,
.account__edit_account_properties .formrow-yyyymmdd .input-group,
.account__edit_profile .formrow-yyyymmdd .input-group,
.account__order .formrow-yyyymmdd .input-group,
.account__manage_addressbook .formrow-yyyymmdd .input-group,
.account__manage_paymentmethod .formrow-yyyymmdd .input-group,
.account__view_account_message_history .formrow-yyyymmdd .input-group,
.account__view_orders .formrow-yyyymmdd .input-group,
.account__view_order_history .formrow-yyyymmdd .input-group,
.account__view_inquiries .formrow-yyyymmdd .input-group,
.account__view_points_history .formrow-yyyymmdd .input-group,
.account__your_coupons .formrow-yyyymmdd .input-group,
.create-new-thread .formrow-yyyymmdd .input-group,
.entry_password_reminder .formrow-yyyymmdd .input-group,
#form .formrow-yyyymmdd .input-group,
.order__confirm .formrow-yyyymmdd .input-group,
.order__signin_to_checkout .formrow-yyyymmdd .input-group,
.order__signin .formrow-yyyymmdd .input-group,
.order__payment .formrow-yyyymmdd .input-group,
.order__points .formrow-yyyymmdd .input-group,
.order__sender .form-sender .form .formrow-yyyymmdd .input-group,
.order__consignee .form-consignee .form .formrow-yyyymmdd .input-group,
.order__deliveryservice .form-deliveryservice .form-body .formrow-yyyymmdd .input-group,
.order__orderer .form-signin form .formrow-yyyymmdd .input-group,
.order__orderer .formrow-yyyymmdd .input-group,
.post-thread-comment-wrapper .formrow-yyyymmdd .input-group,
.update_password .formrow-yyyymmdd .input-group {
  display: flex;
  gap: 10px;
}
@media all and (max-width : 768px) {
  .account__order_inquiry .formrow-yyyymmdd .input-group,
  .account__close_account .formrow-yyyymmdd .input-group,
  .account__edit_address .formrow-yyyymmdd .input-group,
  .account__edit_account .formrow-yyyymmdd .input-group,
  .account__edit_account_properties .formrow-yyyymmdd .input-group,
  .account__edit_profile .formrow-yyyymmdd .input-group,
  .account__order .formrow-yyyymmdd .input-group,
  .account__manage_addressbook .formrow-yyyymmdd .input-group,
  .account__manage_paymentmethod .formrow-yyyymmdd .input-group,
  .account__view_account_message_history .formrow-yyyymmdd .input-group,
  .account__view_orders .formrow-yyyymmdd .input-group,
  .account__view_order_history .formrow-yyyymmdd .input-group,
  .account__view_inquiries .formrow-yyyymmdd .input-group,
  .account__view_points_history .formrow-yyyymmdd .input-group,
  .account__your_coupons .formrow-yyyymmdd .input-group,
  .create-new-thread .formrow-yyyymmdd .input-group,
  .entry_password_reminder .formrow-yyyymmdd .input-group,
  #form .formrow-yyyymmdd .input-group,
  .order__confirm .formrow-yyyymmdd .input-group,
  .order__signin_to_checkout .formrow-yyyymmdd .input-group,
  .order__signin .formrow-yyyymmdd .input-group,
  .order__payment .formrow-yyyymmdd .input-group,
  .order__points .formrow-yyyymmdd .input-group,
  .order__sender .form-sender .form .formrow-yyyymmdd .input-group,
  .order__consignee .form-consignee .form .formrow-yyyymmdd .input-group,
  .order__deliveryservice .form-deliveryservice .form-body .formrow-yyyymmdd .input-group,
  .order__orderer .form-signin form .formrow-yyyymmdd .input-group,
  .order__orderer .formrow-yyyymmdd .input-group,
  .post-thread-comment-wrapper .formrow-yyyymmdd .input-group,
  .update_password .formrow-yyyymmdd .input-group {
    flex: 1px 1px 0;
  }
}
.account__order_inquiry .formrow-yyyymmdd .date-yyyy,
.account__close_account .formrow-yyyymmdd .date-yyyy,
.account__edit_address .formrow-yyyymmdd .date-yyyy,
.account__edit_account .formrow-yyyymmdd .date-yyyy,
.account__edit_account_properties .formrow-yyyymmdd .date-yyyy,
.account__edit_profile .formrow-yyyymmdd .date-yyyy,
.account__order .formrow-yyyymmdd .date-yyyy,
.account__manage_addressbook .formrow-yyyymmdd .date-yyyy,
.account__manage_paymentmethod .formrow-yyyymmdd .date-yyyy,
.account__view_account_message_history .formrow-yyyymmdd .date-yyyy,
.account__view_orders .formrow-yyyymmdd .date-yyyy,
.account__view_order_history .formrow-yyyymmdd .date-yyyy,
.account__view_inquiries .formrow-yyyymmdd .date-yyyy,
.account__view_points_history .formrow-yyyymmdd .date-yyyy,
.account__your_coupons .formrow-yyyymmdd .date-yyyy,
.create-new-thread .formrow-yyyymmdd .date-yyyy,
.entry_password_reminder .formrow-yyyymmdd .date-yyyy,
#form .formrow-yyyymmdd .date-yyyy,
.order__confirm .formrow-yyyymmdd .date-yyyy,
.order__signin_to_checkout .formrow-yyyymmdd .date-yyyy,
.order__signin .formrow-yyyymmdd .date-yyyy,
.order__payment .formrow-yyyymmdd .date-yyyy,
.order__points .formrow-yyyymmdd .date-yyyy,
.order__sender .form-sender .form .formrow-yyyymmdd .date-yyyy,
.order__consignee .form-consignee .form .formrow-yyyymmdd .date-yyyy,
.order__deliveryservice .form-deliveryservice .form-body .formrow-yyyymmdd .date-yyyy,
.order__orderer .form-signin form .formrow-yyyymmdd .date-yyyy,
.order__orderer .formrow-yyyymmdd .date-yyyy,
.post-thread-comment-wrapper .formrow-yyyymmdd .date-yyyy,
.update_password .formrow-yyyymmdd .date-yyyy {
  min-width: 105px;
}
.account__order_inquiry .formrow select,
.account__close_account .formrow select,
.account__edit_address .formrow select,
.account__edit_account .formrow select,
.account__edit_account_properties .formrow select,
.account__edit_profile .formrow select,
.account__order .formrow select,
.account__manage_addressbook .formrow select,
.account__manage_paymentmethod .formrow select,
.account__view_account_message_history .formrow select,
.account__view_orders .formrow select,
.account__view_order_history .formrow select,
.account__view_inquiries .formrow select,
.account__view_points_history .formrow select,
.account__your_coupons .formrow select,
.create-new-thread .formrow select,
.entry_password_reminder .formrow select,
#form .formrow select,
.order__confirm .formrow select,
.order__signin_to_checkout .formrow select,
.order__signin .formrow select,
.order__payment .formrow select,
.order__points .formrow select,
.order__sender .form-sender .form .formrow select,
.order__consignee .form-consignee .form .formrow select,
.order__deliveryservice .form-deliveryservice .form-body .formrow select,
.order__orderer .form-signin form .formrow select,
.order__orderer .formrow select,
.post-thread-comment-wrapper .formrow select,
.update_password .formrow select {
  appearance: none;
  background: #f6f6f6 url(/f/resources/images/common/icon_arrow_down_circle.svg) calc(100% - 10px) 50%/24px no-repeat;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 10px;
  max-width: 150px;
  width: 150px;
}
@media (hover: hover) and (pointer: fine) {
  .account__order_inquiry .formrow select,
  .account__close_account .formrow select,
  .account__edit_address .formrow select,
  .account__edit_account .formrow select,
  .account__edit_account_properties .formrow select,
  .account__edit_profile .formrow select,
  .account__order .formrow select,
  .account__manage_addressbook .formrow select,
  .account__manage_paymentmethod .formrow select,
  .account__view_account_message_history .formrow select,
  .account__view_orders .formrow select,
  .account__view_order_history .formrow select,
  .account__view_inquiries .formrow select,
  .account__view_points_history .formrow select,
  .account__your_coupons .formrow select,
  .create-new-thread .formrow select,
  .entry_password_reminder .formrow select,
  #form .formrow select,
  .order__confirm .formrow select,
  .order__signin_to_checkout .formrow select,
  .order__signin .formrow select,
  .order__payment .formrow select,
  .order__points .formrow select,
  .order__sender .form-sender .form .formrow select,
  .order__consignee .form-consignee .form .formrow select,
  .order__deliveryservice .form-deliveryservice .form-body .formrow select,
  .order__orderer .form-signin form .formrow select,
  .order__orderer .formrow select,
  .post-thread-comment-wrapper .formrow select,
  .update_password .formrow select {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .account__order_inquiry .formrow select:hover,
  .account__close_account .formrow select:hover,
  .account__edit_address .formrow select:hover,
  .account__edit_account .formrow select:hover,
  .account__edit_account_properties .formrow select:hover,
  .account__edit_profile .formrow select:hover,
  .account__order .formrow select:hover,
  .account__manage_addressbook .formrow select:hover,
  .account__manage_paymentmethod .formrow select:hover,
  .account__view_account_message_history .formrow select:hover,
  .account__view_orders .formrow select:hover,
  .account__view_order_history .formrow select:hover,
  .account__view_inquiries .formrow select:hover,
  .account__view_points_history .formrow select:hover,
  .account__your_coupons .formrow select:hover,
  .create-new-thread .formrow select:hover,
  .entry_password_reminder .formrow select:hover,
  #form .formrow select:hover,
  .order__confirm .formrow select:hover,
  .order__signin_to_checkout .formrow select:hover,
  .order__signin .formrow select:hover,
  .order__payment .formrow select:hover,
  .order__points .formrow select:hover,
  .order__sender .form-sender .form .formrow select:hover,
  .order__consignee .form-consignee .form .formrow select:hover,
  .order__deliveryservice .form-deliveryservice .form-body .formrow select:hover,
  .order__orderer .form-signin form .formrow select:hover,
  .order__orderer .formrow select:hover,
  .post-thread-comment-wrapper .formrow select:hover,
  .update_password .formrow select:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.account__order_inquiry .formrow select::before, .account__order_inquiry .formrow select::after,
.account__close_account .formrow select::before,
.account__close_account .formrow select::after,
.account__edit_address .formrow select::before,
.account__edit_address .formrow select::after,
.account__edit_account .formrow select::before,
.account__edit_account .formrow select::after,
.account__edit_account_properties .formrow select::before,
.account__edit_account_properties .formrow select::after,
.account__edit_profile .formrow select::before,
.account__edit_profile .formrow select::after,
.account__order .formrow select::before,
.account__order .formrow select::after,
.account__manage_addressbook .formrow select::before,
.account__manage_addressbook .formrow select::after,
.account__manage_paymentmethod .formrow select::before,
.account__manage_paymentmethod .formrow select::after,
.account__view_account_message_history .formrow select::before,
.account__view_account_message_history .formrow select::after,
.account__view_orders .formrow select::before,
.account__view_orders .formrow select::after,
.account__view_order_history .formrow select::before,
.account__view_order_history .formrow select::after,
.account__view_inquiries .formrow select::before,
.account__view_inquiries .formrow select::after,
.account__view_points_history .formrow select::before,
.account__view_points_history .formrow select::after,
.account__your_coupons .formrow select::before,
.account__your_coupons .formrow select::after,
.create-new-thread .formrow select::before,
.create-new-thread .formrow select::after,
.entry_password_reminder .formrow select::before,
.entry_password_reminder .formrow select::after,
#form .formrow select::before,
#form .formrow select::after,
.order__confirm .formrow select::before,
.order__confirm .formrow select::after,
.order__signin_to_checkout .formrow select::before,
.order__signin_to_checkout .formrow select::after,
.order__signin .formrow select::before,
.order__signin .formrow select::after,
.order__payment .formrow select::before,
.order__payment .formrow select::after,
.order__points .formrow select::before,
.order__points .formrow select::after,
.order__sender .form-sender .form .formrow select::before,
.order__sender .form-sender .form .formrow select::after,
.order__consignee .form-consignee .form .formrow select::before,
.order__consignee .form-consignee .form .formrow select::after,
.order__deliveryservice .form-deliveryservice .form-body .formrow select::before,
.order__deliveryservice .form-deliveryservice .form-body .formrow select::after,
.order__orderer .form-signin form .formrow select::before,
.order__orderer .form-signin form .formrow select::after,
.order__orderer .formrow select::before,
.order__orderer .formrow select::after,
.post-thread-comment-wrapper .formrow select::before,
.post-thread-comment-wrapper .formrow select::after,
.update_password .formrow select::before,
.update_password .formrow select::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .account__order_inquiry .formrow select,
  .account__close_account .formrow select,
  .account__edit_address .formrow select,
  .account__edit_account .formrow select,
  .account__edit_account_properties .formrow select,
  .account__edit_profile .formrow select,
  .account__order .formrow select,
  .account__manage_addressbook .formrow select,
  .account__manage_paymentmethod .formrow select,
  .account__view_account_message_history .formrow select,
  .account__view_orders .formrow select,
  .account__view_order_history .formrow select,
  .account__view_inquiries .formrow select,
  .account__view_points_history .formrow select,
  .account__your_coupons .formrow select,
  .create-new-thread .formrow select,
  .entry_password_reminder .formrow select,
  #form .formrow select,
  .order__confirm .formrow select,
  .order__signin_to_checkout .formrow select,
  .order__signin .formrow select,
  .order__payment .formrow select,
  .order__points .formrow select,
  .order__sender .form-sender .form .formrow select,
  .order__consignee .form-consignee .form .formrow select,
  .order__deliveryservice .form-deliveryservice .form-body .formrow select,
  .order__orderer .form-signin form .formrow select,
  .order__orderer .formrow select,
  .post-thread-comment-wrapper .formrow select,
  .update_password .formrow select {
    width: 100%;
  }
}
.account__order_inquiry .formrow-yyyymmdd .date-delim,
.account__close_account .formrow-yyyymmdd .date-delim,
.account__edit_address .formrow-yyyymmdd .date-delim,
.account__edit_account .formrow-yyyymmdd .date-delim,
.account__edit_account_properties .formrow-yyyymmdd .date-delim,
.account__edit_profile .formrow-yyyymmdd .date-delim,
.account__order .formrow-yyyymmdd .date-delim,
.account__manage_addressbook .formrow-yyyymmdd .date-delim,
.account__manage_paymentmethod .formrow-yyyymmdd .date-delim,
.account__view_account_message_history .formrow-yyyymmdd .date-delim,
.account__view_orders .formrow-yyyymmdd .date-delim,
.account__view_order_history .formrow-yyyymmdd .date-delim,
.account__view_inquiries .formrow-yyyymmdd .date-delim,
.account__view_points_history .formrow-yyyymmdd .date-delim,
.account__your_coupons .formrow-yyyymmdd .date-delim,
.create-new-thread .formrow-yyyymmdd .date-delim,
.entry_password_reminder .formrow-yyyymmdd .date-delim,
#form .formrow-yyyymmdd .date-delim,
.order__confirm .formrow-yyyymmdd .date-delim,
.order__signin_to_checkout .formrow-yyyymmdd .date-delim,
.order__signin .formrow-yyyymmdd .date-delim,
.order__payment .formrow-yyyymmdd .date-delim,
.order__points .formrow-yyyymmdd .date-delim,
.order__sender .form-sender .form .formrow-yyyymmdd .date-delim,
.order__consignee .form-consignee .form .formrow-yyyymmdd .date-delim,
.order__deliveryservice .form-deliveryservice .form-body .formrow-yyyymmdd .date-delim,
.order__orderer .form-signin form .formrow-yyyymmdd .date-delim,
.order__orderer .formrow-yyyymmdd .date-delim,
.post-thread-comment-wrapper .formrow-yyyymmdd .date-delim,
.update_password .formrow-yyyymmdd .date-delim {
  display: none;
}
.account__order_inquiry .formrow .input-label span.input-required,
.account__close_account .formrow .input-label span.input-required,
.account__edit_address .formrow .input-label span.input-required,
.account__edit_account .formrow .input-label span.input-required,
.account__edit_account_properties .formrow .input-label span.input-required,
.account__edit_profile .formrow .input-label span.input-required,
.account__order .formrow .input-label span.input-required,
.account__manage_addressbook .formrow .input-label span.input-required,
.account__manage_paymentmethod .formrow .input-label span.input-required,
.account__view_account_message_history .formrow .input-label span.input-required,
.account__view_orders .formrow .input-label span.input-required,
.account__view_order_history .formrow .input-label span.input-required,
.account__view_inquiries .formrow .input-label span.input-required,
.account__view_points_history .formrow .input-label span.input-required,
.account__your_coupons .formrow .input-label span.input-required,
.create-new-thread .formrow .input-label span.input-required,
.entry_password_reminder .formrow .input-label span.input-required,
#form .formrow .input-label span.input-required,
.order__confirm .formrow .input-label span.input-required,
.order__signin_to_checkout .formrow .input-label span.input-required,
.order__signin .formrow .input-label span.input-required,
.order__payment .formrow .input-label span.input-required,
.order__points .formrow .input-label span.input-required,
.order__sender .form-sender .form .formrow .input-label span.input-required,
.order__consignee .form-consignee .form .formrow .input-label span.input-required,
.order__deliveryservice .form-deliveryservice .form-body .formrow .input-label span.input-required,
.order__orderer .form-signin form .formrow .input-label span.input-required,
.order__orderer .formrow .input-label span.input-required,
.post-thread-comment-wrapper .formrow .input-label span.input-required,
.update_password .formrow .input-label span.input-required {
  background: #c10000;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
}
.account__order_inquiry .formrow-radio .input-group,
.account__close_account .formrow-radio .input-group,
.account__edit_address .formrow-radio .input-group,
.account__edit_account .formrow-radio .input-group,
.account__edit_account_properties .formrow-radio .input-group,
.account__edit_profile .formrow-radio .input-group,
.account__order .formrow-radio .input-group,
.account__manage_addressbook .formrow-radio .input-group,
.account__manage_paymentmethod .formrow-radio .input-group,
.account__view_account_message_history .formrow-radio .input-group,
.account__view_orders .formrow-radio .input-group,
.account__view_order_history .formrow-radio .input-group,
.account__view_inquiries .formrow-radio .input-group,
.account__view_points_history .formrow-radio .input-group,
.account__your_coupons .formrow-radio .input-group,
.create-new-thread .formrow-radio .input-group,
.entry_password_reminder .formrow-radio .input-group,
#form .formrow-radio .input-group,
.order__confirm .formrow-radio .input-group,
.order__signin_to_checkout .formrow-radio .input-group,
.order__signin .formrow-radio .input-group,
.order__payment .formrow-radio .input-group,
.order__points .formrow-radio .input-group,
.order__sender .form-sender .form .formrow-radio .input-group,
.order__consignee .form-consignee .form .formrow-radio .input-group,
.order__deliveryservice .form-deliveryservice .form-body .formrow-radio .input-group,
.order__orderer .form-signin form .formrow-radio .input-group,
.order__orderer .formrow-radio .input-group,
.post-thread-comment-wrapper .formrow-radio .input-group,
.update_password .formrow-radio .input-group {
  padding: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
@media all and (max-width : 768px) {
  .account__order_inquiry .formrow-radio .input-control .input-group-radio .radio-item,
  .account__close_account .formrow-radio .input-control .input-group-radio .radio-item,
  .account__edit_address .formrow-radio .input-control .input-group-radio .radio-item,
  .account__edit_account .formrow-radio .input-control .input-group-radio .radio-item,
  .account__edit_account_properties .formrow-radio .input-control .input-group-radio .radio-item,
  .account__edit_profile .formrow-radio .input-control .input-group-radio .radio-item,
  .account__order .formrow-radio .input-control .input-group-radio .radio-item,
  .account__manage_addressbook .formrow-radio .input-control .input-group-radio .radio-item,
  .account__manage_paymentmethod .formrow-radio .input-control .input-group-radio .radio-item,
  .account__view_account_message_history .formrow-radio .input-control .input-group-radio .radio-item,
  .account__view_orders .formrow-radio .input-control .input-group-radio .radio-item,
  .account__view_order_history .formrow-radio .input-control .input-group-radio .radio-item,
  .account__view_inquiries .formrow-radio .input-control .input-group-radio .radio-item,
  .account__view_points_history .formrow-radio .input-control .input-group-radio .radio-item,
  .account__your_coupons .formrow-radio .input-control .input-group-radio .radio-item,
  .create-new-thread .formrow-radio .input-control .input-group-radio .radio-item,
  .entry_password_reminder .formrow-radio .input-control .input-group-radio .radio-item,
  #form .formrow-radio .input-control .input-group-radio .radio-item,
  .order__confirm .formrow-radio .input-control .input-group-radio .radio-item,
  .order__signin_to_checkout .formrow-radio .input-control .input-group-radio .radio-item,
  .order__signin .formrow-radio .input-control .input-group-radio .radio-item,
  .order__payment .formrow-radio .input-control .input-group-radio .radio-item,
  .order__points .formrow-radio .input-control .input-group-radio .radio-item,
  .order__sender .form-sender .form .formrow-radio .input-control .input-group-radio .radio-item,
  .order__consignee .form-consignee .form .formrow-radio .input-control .input-group-radio .radio-item,
  .order__deliveryservice .form-deliveryservice .form-body .formrow-radio .input-control .input-group-radio .radio-item,
  .order__orderer .form-signin form .formrow-radio .input-control .input-group-radio .radio-item,
  .order__orderer .formrow-radio .input-control .input-group-radio .radio-item,
  .post-thread-comment-wrapper .formrow-radio .input-control .input-group-radio .radio-item,
  .update_password .formrow-radio .input-control .input-group-radio .radio-item {
    width: 100%;
  }
}
.account__order_inquiry .formrow-radio label,
.account__close_account .formrow-radio label,
.account__edit_address .formrow-radio label,
.account__edit_account .formrow-radio label,
.account__edit_account_properties .formrow-radio label,
.account__edit_profile .formrow-radio label,
.account__order .formrow-radio label,
.account__manage_addressbook .formrow-radio label,
.account__manage_paymentmethod .formrow-radio label,
.account__view_account_message_history .formrow-radio label,
.account__view_orders .formrow-radio label,
.account__view_order_history .formrow-radio label,
.account__view_inquiries .formrow-radio label,
.account__view_points_history .formrow-radio label,
.account__your_coupons .formrow-radio label,
.create-new-thread .formrow-radio label,
.entry_password_reminder .formrow-radio label,
#form .formrow-radio label,
.order__confirm .formrow-radio label,
.order__signin_to_checkout .formrow-radio label,
.order__signin .formrow-radio label,
.order__payment .formrow-radio label,
.order__points .formrow-radio label,
.order__sender .form-sender .form .formrow-radio label,
.order__consignee .form-consignee .form .formrow-radio label,
.order__deliveryservice .form-deliveryservice .form-body .formrow-radio label,
.order__orderer .form-signin form .formrow-radio label,
.order__orderer .formrow-radio label,
.post-thread-comment-wrapper .formrow-radio label,
.update_password .formrow-radio label {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  padding: 10px 14px 10px 10px;
  display: flex;
  align-items: center;
}
.account__order_inquiry .formrow-radio input,
.account__close_account .formrow-radio input,
.account__edit_address .formrow-radio input,
.account__edit_account .formrow-radio input,
.account__edit_account_properties .formrow-radio input,
.account__edit_profile .formrow-radio input,
.account__order .formrow-radio input,
.account__manage_addressbook .formrow-radio input,
.account__manage_paymentmethod .formrow-radio input,
.account__view_account_message_history .formrow-radio input,
.account__view_orders .formrow-radio input,
.account__view_order_history .formrow-radio input,
.account__view_inquiries .formrow-radio input,
.account__view_points_history .formrow-radio input,
.account__your_coupons .formrow-radio input,
.create-new-thread .formrow-radio input,
.entry_password_reminder .formrow-radio input,
#form .formrow-radio input,
.order__confirm .formrow-radio input,
.order__signin_to_checkout .formrow-radio input,
.order__signin .formrow-radio input,
.order__payment .formrow-radio input,
.order__points .formrow-radio input,
.order__sender .form-sender .form .formrow-radio input,
.order__consignee .form-consignee .form .formrow-radio input,
.order__deliveryservice .form-deliveryservice .form-body .formrow-radio input,
.order__orderer .form-signin form .formrow-radio input,
.order__orderer .formrow-radio input,
.post-thread-comment-wrapper .formrow-radio input,
.update_password .formrow-radio input {
  accent-color: #005c69;
  border: 1px solid #d9d9d9;
  margin: 0 8px 0 0;
  height: 20px;
  width: 20px;
}
.account__order_inquiry .formrow-checkbox .input-group,
.account__close_account .formrow-checkbox .input-group,
.account__edit_address .formrow-checkbox .input-group,
.account__edit_account .formrow-checkbox .input-group,
.account__edit_account_properties .formrow-checkbox .input-group,
.account__edit_profile .formrow-checkbox .input-group,
.account__order .formrow-checkbox .input-group,
.account__manage_addressbook .formrow-checkbox .input-group,
.account__manage_paymentmethod .formrow-checkbox .input-group,
.account__view_account_message_history .formrow-checkbox .input-group,
.account__view_orders .formrow-checkbox .input-group,
.account__view_order_history .formrow-checkbox .input-group,
.account__view_inquiries .formrow-checkbox .input-group,
.account__view_points_history .formrow-checkbox .input-group,
.account__your_coupons .formrow-checkbox .input-group,
.create-new-thread .formrow-checkbox .input-group,
.entry_password_reminder .formrow-checkbox .input-group,
#form .formrow-checkbox .input-group,
.order__confirm .formrow-checkbox .input-group,
.order__signin_to_checkout .formrow-checkbox .input-group,
.order__signin .formrow-checkbox .input-group,
.order__payment .formrow-checkbox .input-group,
.order__points .formrow-checkbox .input-group,
.order__sender .form-sender .form .formrow-checkbox .input-group,
.order__consignee .form-consignee .form .formrow-checkbox .input-group,
.order__deliveryservice .form-deliveryservice .form-body .formrow-checkbox .input-group,
.order__orderer .form-signin form .formrow-checkbox .input-group,
.order__orderer .formrow-checkbox .input-group,
.post-thread-comment-wrapper .formrow-checkbox .input-group,
.update_password .formrow-checkbox .input-group {
  padding: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.account__order_inquiry .formrow-checkbox label,
.account__close_account .formrow-checkbox label,
.account__edit_address .formrow-checkbox label,
.account__edit_account .formrow-checkbox label,
.account__edit_account_properties .formrow-checkbox label,
.account__edit_profile .formrow-checkbox label,
.account__order .formrow-checkbox label,
.account__manage_addressbook .formrow-checkbox label,
.account__manage_paymentmethod .formrow-checkbox label,
.account__view_account_message_history .formrow-checkbox label,
.account__view_orders .formrow-checkbox label,
.account__view_order_history .formrow-checkbox label,
.account__view_inquiries .formrow-checkbox label,
.account__view_points_history .formrow-checkbox label,
.account__your_coupons .formrow-checkbox label,
.create-new-thread .formrow-checkbox label,
.entry_password_reminder .formrow-checkbox label,
#form .formrow-checkbox label,
.order__confirm .formrow-checkbox label,
.order__signin_to_checkout .formrow-checkbox label,
.order__signin .formrow-checkbox label,
.order__payment .formrow-checkbox label,
.order__points .formrow-checkbox label,
.order__sender .form-sender .form .formrow-checkbox label,
.order__consignee .form-consignee .form .formrow-checkbox label,
.order__deliveryservice .form-deliveryservice .form-body .formrow-checkbox label,
.order__orderer .form-signin form .formrow-checkbox label,
.order__orderer .formrow-checkbox label,
.post-thread-comment-wrapper .formrow-checkbox label,
.update_password .formrow-checkbox label {
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
  padding: 10px 14px 10px 10px;
  display: flex;
  align-items: center;
}
.account__order_inquiry .formrow-checkbox input,
.account__close_account .formrow-checkbox input,
.account__edit_address .formrow-checkbox input,
.account__edit_account .formrow-checkbox input,
.account__edit_account_properties .formrow-checkbox input,
.account__edit_profile .formrow-checkbox input,
.account__order .formrow-checkbox input,
.account__manage_addressbook .formrow-checkbox input,
.account__manage_paymentmethod .formrow-checkbox input,
.account__view_account_message_history .formrow-checkbox input,
.account__view_orders .formrow-checkbox input,
.account__view_order_history .formrow-checkbox input,
.account__view_inquiries .formrow-checkbox input,
.account__view_points_history .formrow-checkbox input,
.account__your_coupons .formrow-checkbox input,
.create-new-thread .formrow-checkbox input,
.entry_password_reminder .formrow-checkbox input,
#form .formrow-checkbox input,
.order__confirm .formrow-checkbox input,
.order__signin_to_checkout .formrow-checkbox input,
.order__signin .formrow-checkbox input,
.order__payment .formrow-checkbox input,
.order__points .formrow-checkbox input,
.order__sender .form-sender .form .formrow-checkbox input,
.order__consignee .form-consignee .form .formrow-checkbox input,
.order__deliveryservice .form-deliveryservice .form-body .formrow-checkbox input,
.order__orderer .form-signin form .formrow-checkbox input,
.order__orderer .formrow-checkbox input,
.post-thread-comment-wrapper .formrow-checkbox input,
.update_password .formrow-checkbox input {
  accent-color: #005c69;
  margin: 0 8px 0 0;
  height: 20px;
  width: 20px;
}

.form.signin-form .form-body.form-signin form .input-label .input-required,
.formrow .input-label .input-required,
.formrow .input-label .input-may-required,
#signup .signup-form .form-signup .formrow-input .input-label .input-required,
#signup .signup-form .form-signup .formrow-radio .input-label .input-required,
#signup-form .input-label .input-required、 #signup .signin-form .form-signin .formrow .input-label .input-required {
  background: #c10000;
  border: 0;
  color: #fff;
  float: none;
  font-size: 12px;
  font-weight: 500;
  padding: 1px 6px 2px;
  display: grid;
  place-items: center;
}

/*--------------------------------------------------
signin,signup | 注文・マイページ
--------------------------------------------------*/
#account__close_account fieldset,
#account__edit_account fieldset,
#account__edit_account_properties fieldset,
#account__edit_profile fieldset,
#account__order fieldset,
#account__manage_addressbook fieldset,
#account__manage_paymentmethod fieldset,
#account__view_account_message_history fieldset,
#account__view_orders fieldset,
#account__view_points_history fieldset,
#account__view_inquiries fieldset,
#account__view_order_history fieldset,
#account__your_coupons fieldset,
#community__talk .thread-comments .report-form .form fieldset,
#entry_password_reminder fieldset,
#form fieldset,
#order__signin_to_checkout fieldset,
#order__signin fieldset,
#order__confirm fieldset,
#order__sender fieldset,
#update_password fieldset {
  background: #f6f6f6;
}

#account__close_account #outer,
#account__edit_address #outer,
#account__edit_account #outer,
#account__edit_account_properties #outer,
#account__edit_profile #outer,
#account__order #outer,
#account__manage_addressbook #outer,
#account__manage_paymentmethod #outer,
#account__view_account_message_history #outer,
#account__view_orders #outer,
#account__view_points_history #outer,
#account__view_inquiries #outer,
#account__view_order_history #outer,
#account__your_coupons #outer,
#community__talk .thread-comments .report-form .form #outer,
#community__commentlist .thread-comments .report-form .form #outer,
#entry_password_reminder #outer,
#form #outer,
#order__signin_to_checkout #outer,
#order__signin #outer,
#order__confirm #outer,
#order__orderer #outer,
#order__sender #outer,
#update_password #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  #account__close_account #outer,
  #account__edit_address #outer,
  #account__edit_account #outer,
  #account__edit_account_properties #outer,
  #account__edit_profile #outer,
  #account__order #outer,
  #account__manage_addressbook #outer,
  #account__manage_paymentmethod #outer,
  #account__view_account_message_history #outer,
  #account__view_orders #outer,
  #account__view_points_history #outer,
  #account__view_inquiries #outer,
  #account__view_order_history #outer,
  #account__your_coupons #outer,
  #community__talk .thread-comments .report-form .form #outer,
  #community__commentlist .thread-comments .report-form .form #outer,
  #entry_password_reminder #outer,
  #form #outer,
  #order__signin_to_checkout #outer,
  #order__signin #outer,
  #order__confirm #outer,
  #order__orderer #outer,
  #order__sender #outer,
  #update_password #outer {
    padding-inline: 12px;
  }
}
#account__close_account h1,
#account__edit_address h1,
#account__edit_account h1,
#account__edit_account_properties h1,
#account__edit_profile h1,
#account__order h1,
#account__manage_addressbook h1,
#account__manage_paymentmethod h1,
#account__view_account_message_history h1,
#account__view_orders h1,
#account__view_points_history h1,
#account__view_inquiries h1,
#account__view_order_history h1,
#account__your_coupons h1,
#community__talk .thread-comments .report-form .form h1,
#community__commentlist .thread-comments .report-form .form h1,
#entry_password_reminder h1,
#form h1,
#order__signin_to_checkout h1,
#order__signin h1,
#order__confirm h1,
#order__orderer h1,
#order__sender h1,
#update_password h1 {
  font-size: 24px;
  font-weight: 400;
  padding: 0;
}
@media all and (min-width: 800px) {
  #account__close_account .form,
  #account__edit_address .form,
  #account__edit_account .form,
  #account__edit_account_properties .form,
  #account__edit_profile .form,
  #account__order .form,
  #account__manage_addressbook .form,
  #account__manage_paymentmethod .form,
  #account__view_account_message_history .form,
  #account__view_orders .form,
  #account__view_points_history .form,
  #account__view_inquiries .form,
  #account__view_order_history .form,
  #account__your_coupons .form,
  #community__talk .thread-comments .report-form .form .form,
  #community__commentlist .thread-comments .report-form .form .form,
  #entry_password_reminder .form,
  #form .form,
  #order__signin_to_checkout .form,
  #order__signin .form,
  #order__confirm .form,
  #order__orderer .form,
  #order__sender .form,
  #update_password .form {
    padding: 0;
  }
}
#account__close_account .form-order-signin,
#account__close_account .form-order-signup,
#account__close_account .form-signin form,
#account__close_account .form-edit-account form,
#account__edit_address .form-order-signin,
#account__edit_address .form-order-signup,
#account__edit_address .form-signin form,
#account__edit_address .form-edit-account form,
#account__edit_account .form-order-signin,
#account__edit_account .form-order-signup,
#account__edit_account .form-signin form,
#account__edit_account .form-edit-account form,
#account__edit_account_properties .form-order-signin,
#account__edit_account_properties .form-order-signup,
#account__edit_account_properties .form-signin form,
#account__edit_account_properties .form-edit-account form,
#account__edit_profile .form-order-signin,
#account__edit_profile .form-order-signup,
#account__edit_profile .form-signin form,
#account__edit_profile .form-edit-account form,
#account__order .form-order-signin,
#account__order .form-order-signup,
#account__order .form-signin form,
#account__order .form-edit-account form,
#account__manage_addressbook .form-order-signin,
#account__manage_addressbook .form-order-signup,
#account__manage_addressbook .form-signin form,
#account__manage_addressbook .form-edit-account form,
#account__manage_paymentmethod .form-order-signin,
#account__manage_paymentmethod .form-order-signup,
#account__manage_paymentmethod .form-signin form,
#account__manage_paymentmethod .form-edit-account form,
#account__view_account_message_history .form-order-signin,
#account__view_account_message_history .form-order-signup,
#account__view_account_message_history .form-signin form,
#account__view_account_message_history .form-edit-account form,
#account__view_orders .form-order-signin,
#account__view_orders .form-order-signup,
#account__view_orders .form-signin form,
#account__view_orders .form-edit-account form,
#account__view_points_history .form-order-signin,
#account__view_points_history .form-order-signup,
#account__view_points_history .form-signin form,
#account__view_points_history .form-edit-account form,
#account__view_inquiries .form-order-signin,
#account__view_inquiries .form-order-signup,
#account__view_inquiries .form-signin form,
#account__view_inquiries .form-edit-account form,
#account__view_order_history .form-order-signin,
#account__view_order_history .form-order-signup,
#account__view_order_history .form-signin form,
#account__view_order_history .form-edit-account form,
#account__your_coupons .form-order-signin,
#account__your_coupons .form-order-signup,
#account__your_coupons .form-signin form,
#account__your_coupons .form-edit-account form,
#community__talk .thread-comments .report-form .form .form-order-signin,
#community__talk .thread-comments .report-form .form .form-order-signup,
#community__talk .thread-comments .report-form .form .form-signin form,
#community__talk .thread-comments .report-form .form .form-edit-account form,
#community__commentlist .thread-comments .report-form .form .form-order-signin,
#community__commentlist .thread-comments .report-form .form .form-order-signup,
#community__commentlist .thread-comments .report-form .form .form-signin form,
#community__commentlist .thread-comments .report-form .form .form-edit-account form,
#entry_password_reminder .form-order-signin,
#entry_password_reminder .form-order-signup,
#entry_password_reminder .form-signin form,
#entry_password_reminder .form-edit-account form,
#form .form-order-signin,
#form .form-order-signup,
#form .form-signin form,
#form .form-edit-account form,
#order__signin_to_checkout .form-order-signin,
#order__signin_to_checkout .form-order-signup,
#order__signin_to_checkout .form-signin form,
#order__signin_to_checkout .form-edit-account form,
#order__signin .form-order-signin,
#order__signin .form-order-signup,
#order__signin .form-signin form,
#order__signin .form-edit-account form,
#order__confirm .form-order-signin,
#order__confirm .form-order-signup,
#order__confirm .form-signin form,
#order__confirm .form-edit-account form,
#order__orderer .form-order-signin,
#order__orderer .form-order-signup,
#order__orderer .form-signin form,
#order__orderer .form-edit-account form,
#order__sender .form-order-signin,
#order__sender .form-order-signup,
#order__sender .form-signin form,
#order__sender .form-edit-account form,
#update_password .form-order-signin,
#update_password .form-order-signup,
#update_password .form-signin form,
#update_password .form-edit-account form {
  background-color: #f6f6f6;
  padding: 40px 45px;
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  #account__close_account .form-order-signin,
  #account__close_account .form-order-signup,
  #account__close_account .form-signin form,
  #account__close_account .form-edit-account form,
  #account__edit_address .form-order-signin,
  #account__edit_address .form-order-signup,
  #account__edit_address .form-signin form,
  #account__edit_address .form-edit-account form,
  #account__edit_account .form-order-signin,
  #account__edit_account .form-order-signup,
  #account__edit_account .form-signin form,
  #account__edit_account .form-edit-account form,
  #account__edit_account_properties .form-order-signin,
  #account__edit_account_properties .form-order-signup,
  #account__edit_account_properties .form-signin form,
  #account__edit_account_properties .form-edit-account form,
  #account__edit_profile .form-order-signin,
  #account__edit_profile .form-order-signup,
  #account__edit_profile .form-signin form,
  #account__edit_profile .form-edit-account form,
  #account__order .form-order-signin,
  #account__order .form-order-signup,
  #account__order .form-signin form,
  #account__order .form-edit-account form,
  #account__manage_addressbook .form-order-signin,
  #account__manage_addressbook .form-order-signup,
  #account__manage_addressbook .form-signin form,
  #account__manage_addressbook .form-edit-account form,
  #account__manage_paymentmethod .form-order-signin,
  #account__manage_paymentmethod .form-order-signup,
  #account__manage_paymentmethod .form-signin form,
  #account__manage_paymentmethod .form-edit-account form,
  #account__view_account_message_history .form-order-signin,
  #account__view_account_message_history .form-order-signup,
  #account__view_account_message_history .form-signin form,
  #account__view_account_message_history .form-edit-account form,
  #account__view_orders .form-order-signin,
  #account__view_orders .form-order-signup,
  #account__view_orders .form-signin form,
  #account__view_orders .form-edit-account form,
  #account__view_points_history .form-order-signin,
  #account__view_points_history .form-order-signup,
  #account__view_points_history .form-signin form,
  #account__view_points_history .form-edit-account form,
  #account__view_inquiries .form-order-signin,
  #account__view_inquiries .form-order-signup,
  #account__view_inquiries .form-signin form,
  #account__view_inquiries .form-edit-account form,
  #account__view_order_history .form-order-signin,
  #account__view_order_history .form-order-signup,
  #account__view_order_history .form-signin form,
  #account__view_order_history .form-edit-account form,
  #account__your_coupons .form-order-signin,
  #account__your_coupons .form-order-signup,
  #account__your_coupons .form-signin form,
  #account__your_coupons .form-edit-account form,
  #community__talk .thread-comments .report-form .form .form-order-signin,
  #community__talk .thread-comments .report-form .form .form-order-signup,
  #community__talk .thread-comments .report-form .form .form-signin form,
  #community__talk .thread-comments .report-form .form .form-edit-account form,
  #community__commentlist .thread-comments .report-form .form .form-order-signin,
  #community__commentlist .thread-comments .report-form .form .form-order-signup,
  #community__commentlist .thread-comments .report-form .form .form-signin form,
  #community__commentlist .thread-comments .report-form .form .form-edit-account form,
  #entry_password_reminder .form-order-signin,
  #entry_password_reminder .form-order-signup,
  #entry_password_reminder .form-signin form,
  #entry_password_reminder .form-edit-account form,
  #form .form-order-signin,
  #form .form-order-signup,
  #form .form-signin form,
  #form .form-edit-account form,
  #order__signin_to_checkout .form-order-signin,
  #order__signin_to_checkout .form-order-signup,
  #order__signin_to_checkout .form-signin form,
  #order__signin_to_checkout .form-edit-account form,
  #order__signin .form-order-signin,
  #order__signin .form-order-signup,
  #order__signin .form-signin form,
  #order__signin .form-edit-account form,
  #order__confirm .form-order-signin,
  #order__confirm .form-order-signup,
  #order__confirm .form-signin form,
  #order__confirm .form-edit-account form,
  #order__orderer .form-order-signin,
  #order__orderer .form-order-signup,
  #order__orderer .form-signin form,
  #order__orderer .form-edit-account form,
  #order__sender .form-order-signin,
  #order__sender .form-order-signup,
  #order__sender .form-signin form,
  #order__sender .form-edit-account form,
  #update_password .form-order-signin,
  #update_password .form-order-signup,
  #update_password .form-signin form,
  #update_password .form-edit-account form {
    padding: 20px 15px;
  }
}
#account__close_account .new-password,
#account__edit_address .new-password,
#account__edit_account .new-password,
#account__edit_account_properties .new-password,
#account__edit_profile .new-password,
#account__order .new-password,
#account__manage_addressbook .new-password,
#account__manage_paymentmethod .new-password,
#account__view_account_message_history .new-password,
#account__view_orders .new-password,
#account__view_points_history .new-password,
#account__view_inquiries .new-password,
#account__view_order_history .new-password,
#account__your_coupons .new-password,
#community__talk .thread-comments .report-form .form .new-password,
#community__commentlist .thread-comments .report-form .form .new-password,
#entry_password_reminder .new-password,
#form .new-password,
#order__signin_to_checkout .new-password,
#order__signin .new-password,
#order__confirm .new-password,
#order__orderer .new-password,
#order__sender .new-password,
#update_password .new-password {
  margin-top: 40px;
}
#account__close_account legend,
#account__edit_address legend,
#account__edit_account legend,
#account__edit_account_properties legend,
#account__edit_profile legend,
#account__order legend,
#account__manage_addressbook legend,
#account__manage_paymentmethod legend,
#account__view_account_message_history legend,
#account__view_orders legend,
#account__view_points_history legend,
#account__view_inquiries legend,
#account__view_order_history legend,
#account__your_coupons legend,
#community__talk .thread-comments .report-form .form legend,
#community__commentlist .thread-comments .report-form .form legend,
#entry_password_reminder legend,
#form legend,
#order__signin_to_checkout legend,
#order__signin legend,
#order__confirm legend,
#order__orderer legend,
#order__sender legend,
#update_password legend {
  background: transparent;
  color: #005c69;
  font-size: 14px;
}
#account__close_account .input-group-address .formrow,
#account__edit_address .input-group-address .formrow,
#account__edit_account .input-group-address .formrow,
#account__edit_account_properties .input-group-address .formrow,
#account__edit_profile .input-group-address .formrow,
#account__order .input-group-address .formrow,
#account__manage_addressbook .input-group-address .formrow,
#account__manage_paymentmethod .input-group-address .formrow,
#account__view_account_message_history .input-group-address .formrow,
#account__view_orders .input-group-address .formrow,
#account__view_points_history .input-group-address .formrow,
#account__view_inquiries .input-group-address .formrow,
#account__view_order_history .input-group-address .formrow,
#account__your_coupons .input-group-address .formrow,
#community__talk .thread-comments .report-form .form .input-group-address .formrow,
#community__commentlist .thread-comments .report-form .form .input-group-address .formrow,
#entry_password_reminder .input-group-address .formrow,
#form .input-group-address .formrow,
#order__signin_to_checkout .input-group-address .formrow,
#order__signin .input-group-address .formrow,
#order__confirm .input-group-address .formrow,
#order__orderer .input-group-address .formrow,
#order__sender .input-group-address .formrow,
#update_password .input-group-address .formrow {
  border-bottom: none;
}
#account__close_account .formrow-checkbox .input-group,
#account__close_account .formrow-radio .input-group,
#account__edit_address .formrow-checkbox .input-group,
#account__edit_address .formrow-radio .input-group,
#account__edit_account .formrow-checkbox .input-group,
#account__edit_account .formrow-radio .input-group,
#account__edit_account_properties .formrow-checkbox .input-group,
#account__edit_account_properties .formrow-radio .input-group,
#account__edit_profile .formrow-checkbox .input-group,
#account__edit_profile .formrow-radio .input-group,
#account__order .formrow-checkbox .input-group,
#account__order .formrow-radio .input-group,
#account__manage_addressbook .formrow-checkbox .input-group,
#account__manage_addressbook .formrow-radio .input-group,
#account__manage_paymentmethod .formrow-checkbox .input-group,
#account__manage_paymentmethod .formrow-radio .input-group,
#account__view_account_message_history .formrow-checkbox .input-group,
#account__view_account_message_history .formrow-radio .input-group,
#account__view_orders .formrow-checkbox .input-group,
#account__view_orders .formrow-radio .input-group,
#account__view_points_history .formrow-checkbox .input-group,
#account__view_points_history .formrow-radio .input-group,
#account__view_inquiries .formrow-checkbox .input-group,
#account__view_inquiries .formrow-radio .input-group,
#account__view_order_history .formrow-checkbox .input-group,
#account__view_order_history .formrow-radio .input-group,
#account__your_coupons .formrow-checkbox .input-group,
#account__your_coupons .formrow-radio .input-group,
#community__talk .thread-comments .report-form .form .formrow-checkbox .input-group,
#community__talk .thread-comments .report-form .form .formrow-radio .input-group,
#community__commentlist .thread-comments .report-form .form .formrow-checkbox .input-group,
#community__commentlist .thread-comments .report-form .form .formrow-radio .input-group,
#entry_password_reminder .formrow-checkbox .input-group,
#entry_password_reminder .formrow-radio .input-group,
#form .formrow-checkbox .input-group,
#form .formrow-radio .input-group,
#order__signin_to_checkout .formrow-checkbox .input-group,
#order__signin_to_checkout .formrow-radio .input-group,
#order__signin .formrow-checkbox .input-group,
#order__signin .formrow-radio .input-group,
#order__confirm .formrow-checkbox .input-group,
#order__confirm .formrow-radio .input-group,
#order__orderer .formrow-checkbox .input-group,
#order__orderer .formrow-radio .input-group,
#order__sender .formrow-checkbox .input-group,
#order__sender .formrow-radio .input-group,
#update_password .formrow-checkbox .input-group,
#update_password .formrow-radio .input-group {
  padding: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
#account__close_account .formrow-checkbox label,
#account__close_account .formrow-radio label,
#account__edit_address .formrow-checkbox label,
#account__edit_address .formrow-radio label,
#account__edit_account .formrow-checkbox label,
#account__edit_account .formrow-radio label,
#account__edit_account_properties .formrow-checkbox label,
#account__edit_account_properties .formrow-radio label,
#account__edit_profile .formrow-checkbox label,
#account__edit_profile .formrow-radio label,
#account__order .formrow-checkbox label,
#account__order .formrow-radio label,
#account__manage_addressbook .formrow-checkbox label,
#account__manage_addressbook .formrow-radio label,
#account__manage_paymentmethod .formrow-checkbox label,
#account__manage_paymentmethod .formrow-radio label,
#account__view_account_message_history .formrow-checkbox label,
#account__view_account_message_history .formrow-radio label,
#account__view_orders .formrow-checkbox label,
#account__view_orders .formrow-radio label,
#account__view_points_history .formrow-checkbox label,
#account__view_points_history .formrow-radio label,
#account__view_inquiries .formrow-checkbox label,
#account__view_inquiries .formrow-radio label,
#account__view_order_history .formrow-checkbox label,
#account__view_order_history .formrow-radio label,
#account__your_coupons .formrow-checkbox label,
#account__your_coupons .formrow-radio label,
#community__talk .thread-comments .report-form .form .formrow-checkbox label,
#community__talk .thread-comments .report-form .form .formrow-radio label,
#community__commentlist .thread-comments .report-form .form .formrow-checkbox label,
#community__commentlist .thread-comments .report-form .form .formrow-radio label,
#entry_password_reminder .formrow-checkbox label,
#entry_password_reminder .formrow-radio label,
#form .formrow-checkbox label,
#form .formrow-radio label,
#order__signin_to_checkout .formrow-checkbox label,
#order__signin_to_checkout .formrow-radio label,
#order__signin .formrow-checkbox label,
#order__signin .formrow-radio label,
#order__confirm .formrow-checkbox label,
#order__confirm .formrow-radio label,
#order__orderer .formrow-checkbox label,
#order__orderer .formrow-radio label,
#order__sender .formrow-checkbox label,
#order__sender .formrow-radio label,
#update_password .formrow-checkbox label,
#update_password .formrow-radio label {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  padding: 9px 14px 9px 10px;
  position: relative;
  display: flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  #account__close_account .formrow-checkbox label,
  #account__close_account .formrow-radio label,
  #account__edit_address .formrow-checkbox label,
  #account__edit_address .formrow-radio label,
  #account__edit_account .formrow-checkbox label,
  #account__edit_account .formrow-radio label,
  #account__edit_account_properties .formrow-checkbox label,
  #account__edit_account_properties .formrow-radio label,
  #account__edit_profile .formrow-checkbox label,
  #account__edit_profile .formrow-radio label,
  #account__order .formrow-checkbox label,
  #account__order .formrow-radio label,
  #account__manage_addressbook .formrow-checkbox label,
  #account__manage_addressbook .formrow-radio label,
  #account__manage_paymentmethod .formrow-checkbox label,
  #account__manage_paymentmethod .formrow-radio label,
  #account__view_account_message_history .formrow-checkbox label,
  #account__view_account_message_history .formrow-radio label,
  #account__view_orders .formrow-checkbox label,
  #account__view_orders .formrow-radio label,
  #account__view_points_history .formrow-checkbox label,
  #account__view_points_history .formrow-radio label,
  #account__view_inquiries .formrow-checkbox label,
  #account__view_inquiries .formrow-radio label,
  #account__view_order_history .formrow-checkbox label,
  #account__view_order_history .formrow-radio label,
  #account__your_coupons .formrow-checkbox label,
  #account__your_coupons .formrow-radio label,
  #community__talk .thread-comments .report-form .form .formrow-checkbox label,
  #community__talk .thread-comments .report-form .form .formrow-radio label,
  #community__commentlist .thread-comments .report-form .form .formrow-checkbox label,
  #community__commentlist .thread-comments .report-form .form .formrow-radio label,
  #entry_password_reminder .formrow-checkbox label,
  #entry_password_reminder .formrow-radio label,
  #form .formrow-checkbox label,
  #form .formrow-radio label,
  #order__signin_to_checkout .formrow-checkbox label,
  #order__signin_to_checkout .formrow-radio label,
  #order__signin .formrow-checkbox label,
  #order__signin .formrow-radio label,
  #order__confirm .formrow-checkbox label,
  #order__confirm .formrow-radio label,
  #order__orderer .formrow-checkbox label,
  #order__orderer .formrow-radio label,
  #order__sender .formrow-checkbox label,
  #order__sender .formrow-radio label,
  #update_password .formrow-checkbox label,
  #update_password .formrow-radio label {
    width: 100%;
  }
}
#account__close_account .form-signin .formrow-radio label,
#account__edit_address .form-signin .formrow-radio label,
#account__edit_account .form-signin .formrow-radio label,
#account__edit_account_properties .form-signin .formrow-radio label,
#account__edit_profile .form-signin .formrow-radio label,
#account__order .form-signin .formrow-radio label,
#account__manage_addressbook .form-signin .formrow-radio label,
#account__manage_paymentmethod .form-signin .formrow-radio label,
#account__view_account_message_history .form-signin .formrow-radio label,
#account__view_orders .form-signin .formrow-radio label,
#account__view_points_history .form-signin .formrow-radio label,
#account__view_inquiries .form-signin .formrow-radio label,
#account__view_order_history .form-signin .formrow-radio label,
#account__your_coupons .form-signin .formrow-radio label,
#community__talk .thread-comments .report-form .form .form-signin .formrow-radio label,
#community__commentlist .thread-comments .report-form .form .form-signin .formrow-radio label,
#entry_password_reminder .form-signin .formrow-radio label,
#form .form-signin .formrow-radio label,
#order__signin_to_checkout .form-signin .formrow-radio label,
#order__signin .form-signin .formrow-radio label,
#order__confirm .form-signin .formrow-radio label,
#order__orderer .form-signin .formrow-radio label,
#order__sender .form-signin .formrow-radio label,
#update_password .form-signin .formrow-radio label {
  padding: 9px 14px 9px 38px;
}
#account__close_account #checkout_form .formrow-checkbox label,
#account__edit_address #checkout_form .formrow-checkbox label,
#account__edit_account #checkout_form .formrow-checkbox label,
#account__edit_account_properties #checkout_form .formrow-checkbox label,
#account__edit_profile #checkout_form .formrow-checkbox label,
#account__order #checkout_form .formrow-checkbox label,
#account__manage_addressbook #checkout_form .formrow-checkbox label,
#account__manage_paymentmethod #checkout_form .formrow-checkbox label,
#account__view_account_message_history #checkout_form .formrow-checkbox label,
#account__view_orders #checkout_form .formrow-checkbox label,
#account__view_points_history #checkout_form .formrow-checkbox label,
#account__view_inquiries #checkout_form .formrow-checkbox label,
#account__view_order_history #checkout_form .formrow-checkbox label,
#account__your_coupons #checkout_form .formrow-checkbox label,
#community__talk .thread-comments .report-form .form #checkout_form .formrow-checkbox label,
#community__commentlist .thread-comments .report-form .form #checkout_form .formrow-checkbox label,
#entry_password_reminder #checkout_form .formrow-checkbox label,
#form #checkout_form .formrow-checkbox label,
#order__signin_to_checkout #checkout_form .formrow-checkbox label,
#order__signin #checkout_form .formrow-checkbox label,
#order__confirm #checkout_form .formrow-checkbox label,
#order__orderer #checkout_form .formrow-checkbox label,
#order__sender #checkout_form .formrow-checkbox label,
#update_password #checkout_form .formrow-checkbox label {
  border: none;
}
#account__close_account .formrow-radio label.is-checked,
#account__edit_address .formrow-radio label.is-checked,
#account__edit_account .formrow-radio label.is-checked,
#account__edit_account_properties .formrow-radio label.is-checked,
#account__edit_profile .formrow-radio label.is-checked,
#account__order .formrow-radio label.is-checked,
#account__manage_addressbook .formrow-radio label.is-checked,
#account__manage_paymentmethod .formrow-radio label.is-checked,
#account__view_account_message_history .formrow-radio label.is-checked,
#account__view_orders .formrow-radio label.is-checked,
#account__view_points_history .formrow-radio label.is-checked,
#account__view_inquiries .formrow-radio label.is-checked,
#account__view_order_history .formrow-radio label.is-checked,
#account__your_coupons .formrow-radio label.is-checked,
#community__talk .thread-comments .report-form .form .formrow-radio label.is-checked,
#community__commentlist .thread-comments .report-form .form .formrow-radio label.is-checked,
#entry_password_reminder .formrow-radio label.is-checked,
#form .formrow-radio label.is-checked,
#order__signin_to_checkout .formrow-radio label.is-checked,
#order__signin .formrow-radio label.is-checked,
#order__confirm .formrow-radio label.is-checked,
#order__orderer .formrow-radio label.is-checked,
#order__sender .formrow-radio label.is-checked,
#update_password .formrow-radio label.is-checked {
  border: 1px solid #005c69;
}
#account__close_account .formrow-checkbox .input-group,
#account__edit_address .formrow-checkbox .input-group,
#account__edit_account .formrow-checkbox .input-group,
#account__edit_account_properties .formrow-checkbox .input-group,
#account__edit_profile .formrow-checkbox .input-group,
#account__order .formrow-checkbox .input-group,
#account__manage_addressbook .formrow-checkbox .input-group,
#account__manage_paymentmethod .formrow-checkbox .input-group,
#account__view_account_message_history .formrow-checkbox .input-group,
#account__view_orders .formrow-checkbox .input-group,
#account__view_points_history .formrow-checkbox .input-group,
#account__view_inquiries .formrow-checkbox .input-group,
#account__view_order_history .formrow-checkbox .input-group,
#account__your_coupons .formrow-checkbox .input-group,
#community__talk .thread-comments .report-form .form .formrow-checkbox .input-group,
#community__commentlist .thread-comments .report-form .form .formrow-checkbox .input-group,
#entry_password_reminder .formrow-checkbox .input-group,
#form .formrow-checkbox .input-group,
#order__signin_to_checkout .formrow-checkbox .input-group,
#order__signin .formrow-checkbox .input-group,
#order__confirm .formrow-checkbox .input-group,
#order__orderer .formrow-checkbox .input-group,
#order__sender .formrow-checkbox .input-group,
#update_password .formrow-checkbox .input-group {
  padding: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
#account__close_account .formrow-checkbox label.is-checked,
#account__edit_address .formrow-checkbox label.is-checked,
#account__edit_account .formrow-checkbox label.is-checked,
#account__edit_account_properties .formrow-checkbox label.is-checked,
#account__edit_profile .formrow-checkbox label.is-checked,
#account__order .formrow-checkbox label.is-checked,
#account__manage_addressbook .formrow-checkbox label.is-checked,
#account__manage_paymentmethod .formrow-checkbox label.is-checked,
#account__view_account_message_history .formrow-checkbox label.is-checked,
#account__view_orders .formrow-checkbox label.is-checked,
#account__view_points_history .formrow-checkbox label.is-checked,
#account__view_inquiries .formrow-checkbox label.is-checked,
#account__view_order_history .formrow-checkbox label.is-checked,
#account__your_coupons .formrow-checkbox label.is-checked,
#community__talk .thread-comments .report-form .form .formrow-checkbox label.is-checked,
#community__commentlist .thread-comments .report-form .form .formrow-checkbox label.is-checked,
#entry_password_reminder .formrow-checkbox label.is-checked,
#form .formrow-checkbox label.is-checked,
#order__signin_to_checkout .formrow-checkbox label.is-checked,
#order__signin .formrow-checkbox label.is-checked,
#order__confirm .formrow-checkbox label.is-checked,
#order__orderer .formrow-checkbox label.is-checked,
#order__sender .formrow-checkbox label.is-checked,
#update_password .formrow-checkbox label.is-checked {
  border: 1px solid #005c69;
}
#account__close_account select.date,
#account__close_account .formrow-yyyymmdd select,
#account__edit_address select.date,
#account__edit_address .formrow-yyyymmdd select,
#account__edit_account select.date,
#account__edit_account .formrow-yyyymmdd select,
#account__edit_account_properties select.date,
#account__edit_account_properties .formrow-yyyymmdd select,
#account__edit_profile select.date,
#account__edit_profile .formrow-yyyymmdd select,
#account__order select.date,
#account__order .formrow-yyyymmdd select,
#account__manage_addressbook select.date,
#account__manage_addressbook .formrow-yyyymmdd select,
#account__manage_paymentmethod select.date,
#account__manage_paymentmethod .formrow-yyyymmdd select,
#account__view_account_message_history select.date,
#account__view_account_message_history .formrow-yyyymmdd select,
#account__view_orders select.date,
#account__view_orders .formrow-yyyymmdd select,
#account__view_points_history select.date,
#account__view_points_history .formrow-yyyymmdd select,
#account__view_inquiries select.date,
#account__view_inquiries .formrow-yyyymmdd select,
#account__view_order_history select.date,
#account__view_order_history .formrow-yyyymmdd select,
#account__your_coupons select.date,
#account__your_coupons .formrow-yyyymmdd select,
#community__talk .thread-comments .report-form .form select.date,
#community__talk .thread-comments .report-form .form .formrow-yyyymmdd select,
#community__commentlist .thread-comments .report-form .form select.date,
#community__commentlist .thread-comments .report-form .form .formrow-yyyymmdd select,
#entry_password_reminder select.date,
#entry_password_reminder .formrow-yyyymmdd select,
#form select.date,
#form .formrow-yyyymmdd select,
#order__signin_to_checkout select.date,
#order__signin_to_checkout .formrow-yyyymmdd select,
#order__signin select.date,
#order__signin .formrow-yyyymmdd select,
#order__confirm select.date,
#order__confirm .formrow-yyyymmdd select,
#order__orderer select.date,
#order__orderer .formrow-yyyymmdd select,
#order__sender select.date,
#order__sender .formrow-yyyymmdd select,
#update_password select.date,
#update_password .formrow-yyyymmdd select {
  background: transparent url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  color: #333;
  padding-block: 10px;
  padding-inline: 10px 8px;
  width: 100%;
  max-width: 150px;
  min-width: 150px;
  display: inline-flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  #account__close_account select.date,
  #account__close_account .formrow-yyyymmdd select,
  #account__edit_address select.date,
  #account__edit_address .formrow-yyyymmdd select,
  #account__edit_account select.date,
  #account__edit_account .formrow-yyyymmdd select,
  #account__edit_account_properties select.date,
  #account__edit_account_properties .formrow-yyyymmdd select,
  #account__edit_profile select.date,
  #account__edit_profile .formrow-yyyymmdd select,
  #account__order select.date,
  #account__order .formrow-yyyymmdd select,
  #account__manage_addressbook select.date,
  #account__manage_addressbook .formrow-yyyymmdd select,
  #account__manage_paymentmethod select.date,
  #account__manage_paymentmethod .formrow-yyyymmdd select,
  #account__view_account_message_history select.date,
  #account__view_account_message_history .formrow-yyyymmdd select,
  #account__view_orders select.date,
  #account__view_orders .formrow-yyyymmdd select,
  #account__view_points_history select.date,
  #account__view_points_history .formrow-yyyymmdd select,
  #account__view_inquiries select.date,
  #account__view_inquiries .formrow-yyyymmdd select,
  #account__view_order_history select.date,
  #account__view_order_history .formrow-yyyymmdd select,
  #account__your_coupons select.date,
  #account__your_coupons .formrow-yyyymmdd select,
  #community__talk .thread-comments .report-form .form select.date,
  #community__talk .thread-comments .report-form .form .formrow-yyyymmdd select,
  #community__commentlist .thread-comments .report-form .form select.date,
  #community__commentlist .thread-comments .report-form .form .formrow-yyyymmdd select,
  #entry_password_reminder select.date,
  #entry_password_reminder .formrow-yyyymmdd select,
  #form select.date,
  #form .formrow-yyyymmdd select,
  #order__signin_to_checkout select.date,
  #order__signin_to_checkout .formrow-yyyymmdd select,
  #order__signin select.date,
  #order__signin .formrow-yyyymmdd select,
  #order__confirm select.date,
  #order__confirm .formrow-yyyymmdd select,
  #order__orderer select.date,
  #order__orderer .formrow-yyyymmdd select,
  #order__sender select.date,
  #order__sender .formrow-yyyymmdd select,
  #update_password select.date,
  #update_password .formrow-yyyymmdd select {
    min-width: 94px;
    padding-inline: 8px;
  }
}
#account__close_account .acc-signup legend,
#account__edit_address .acc-signup legend,
#account__edit_account .acc-signup legend,
#account__edit_account_properties .acc-signup legend,
#account__edit_profile .acc-signup legend,
#account__order .acc-signup legend,
#account__manage_addressbook .acc-signup legend,
#account__manage_paymentmethod .acc-signup legend,
#account__view_account_message_history .acc-signup legend,
#account__view_orders .acc-signup legend,
#account__view_points_history .acc-signup legend,
#account__view_inquiries .acc-signup legend,
#account__view_order_history .acc-signup legend,
#account__your_coupons .acc-signup legend,
#community__talk .thread-comments .report-form .form .acc-signup legend,
#community__commentlist .thread-comments .report-form .form .acc-signup legend,
#entry_password_reminder .acc-signup legend,
#form .acc-signup legend,
#order__signin_to_checkout .acc-signup legend,
#order__signin .acc-signup legend,
#order__confirm .acc-signup legend,
#order__orderer .acc-signup legend,
#order__sender .acc-signup legend,
#update_password .acc-signup legend {
  display: none;
}
#account__close_account .formrow-submit .pp-dscr,
#account__edit_address .formrow-submit .pp-dscr,
#account__edit_account .formrow-submit .pp-dscr,
#account__edit_account_properties .formrow-submit .pp-dscr,
#account__edit_profile .formrow-submit .pp-dscr,
#account__order .formrow-submit .pp-dscr,
#account__manage_addressbook .formrow-submit .pp-dscr,
#account__manage_paymentmethod .formrow-submit .pp-dscr,
#account__view_account_message_history .formrow-submit .pp-dscr,
#account__view_orders .formrow-submit .pp-dscr,
#account__view_points_history .formrow-submit .pp-dscr,
#account__view_inquiries .formrow-submit .pp-dscr,
#account__view_order_history .formrow-submit .pp-dscr,
#account__your_coupons .formrow-submit .pp-dscr,
#community__talk .thread-comments .report-form .form .formrow-submit .pp-dscr,
#community__commentlist .thread-comments .report-form .form .formrow-submit .pp-dscr,
#entry_password_reminder .formrow-submit .pp-dscr,
#form .formrow-submit .pp-dscr,
#order__signin_to_checkout .formrow-submit .pp-dscr,
#order__signin .formrow-submit .pp-dscr,
#order__confirm .formrow-submit .pp-dscr,
#order__orderer .formrow-submit .pp-dscr,
#order__sender .formrow-submit .pp-dscr,
#update_password .formrow-submit .pp-dscr {
  font-size: 12px;
  line-height: 1.41666667;
}
#account__close_account .btn-submit,
#account__edit_address .btn-submit,
#account__edit_account .btn-submit,
#account__edit_account_properties .btn-submit,
#account__edit_profile .btn-submit,
#account__order .btn-submit,
#account__manage_addressbook .btn-submit,
#account__manage_paymentmethod .btn-submit,
#account__view_account_message_history .btn-submit,
#account__view_orders .btn-submit,
#account__view_points_history .btn-submit,
#account__view_inquiries .btn-submit,
#account__view_order_history .btn-submit,
#account__your_coupons .btn-submit,
#community__talk .thread-comments .report-form .form .btn-submit,
#community__commentlist .thread-comments .report-form .form .btn-submit,
#entry_password_reminder .btn-submit,
#form .btn-submit,
#order__signin_to_checkout .btn-submit,
#order__signin .btn-submit,
#order__confirm .btn-submit,
#order__orderer .btn-submit,
#order__sender .btn-submit,
#update_password .btn-submit {
  margin-top: 10px;
}
#account__close_account .reminder,
#account__edit_address .reminder,
#account__edit_account .reminder,
#account__edit_account_properties .reminder,
#account__edit_profile .reminder,
#account__order .reminder,
#account__manage_addressbook .reminder,
#account__manage_paymentmethod .reminder,
#account__view_account_message_history .reminder,
#account__view_orders .reminder,
#account__view_points_history .reminder,
#account__view_inquiries .reminder,
#account__view_order_history .reminder,
#account__your_coupons .reminder,
#community__talk .thread-comments .report-form .form .reminder,
#community__commentlist .thread-comments .report-form .form .reminder,
#entry_password_reminder .reminder,
#form .reminder,
#order__signin_to_checkout .reminder,
#order__signin .reminder,
#order__confirm .reminder,
#order__orderer .reminder,
#order__sender .reminder,
#update_password .reminder {
  font-size: 16px;
  padding-top: 40px;
  text-align: center;
}

#account__order_inquiry #outer,
#form #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 40px;
}
@media all and (max-width: 768px) {
  #account__order_inquiry #outer,
  #form #outer {
    padding-inline: 12px;
  }
}
#account__order_inquiry #outer:has(.form-auth),
#form #outer:has(.form-auth) {
  padding-block: 0;
}
#account__order_inquiry h3,
#form h3 {
  font-size: 24px;
  font-weight: 400;
  padding: 0;
}
@media all and (max-width : 768px) {
  #account__order_inquiry h3,
  #form h3 {
    padding-right: 154px;
  }
}
@media all and (max-width : 768px) {
  #account__order_inquiry .form-form h3,
  #form .form-form h3 {
    padding-right: 0;
  }
}
#account__order_inquiry form.form,
#form form.form {
  background: #f6f6f6;
  margin-top: 40px;
  padding: 40px 45px;
}
@media all and (max-width : 768px) {
  #account__order_inquiry form.form,
  #form form.form {
    padding: 20px 16px;
  }
}
#account__order_inquiry .formrow-file_upload .optional,
#form .formrow-file_upload .optional {
  border: none;
}
#account__order_inquiry .after-confirm,
#form .after-confirm {
  padding-top: 10px;
}
#account__order_inquiry .formrow-submit .btn.btn-confirm,
#account__order_inquiry .after-confirm .btn.btn-sending,
#form .formrow-submit .btn.btn-confirm,
#form .after-confirm .btn.btn-sending {
  background: #ff9c08;
  border: none;
  box-shadow: none;
  color: #fff !important;
}
#account__order_inquiry .after-confirm .btn + .btn,
#form .after-confirm .btn + .btn {
  box-shadow: none;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .after-confirm .btn + .btn,
  #form .after-confirm .btn + .btn {
    margin-top: 20px;
    margin-left: 0;
  }
}
#account__order_inquiry .formrow-submit button,
#form .formrow-submit button {
  background-image: none;
}

/*--------------------------------------------------
signin,signup
--------------------------------------------------*/
/* サインイン・サインアップ・注文時サインイン */
#signin .authform-anchor__signin,
#signup .authform-anchor__signup,
#order__signin_to_checkout .authform-anchor__signin {
  display: none;
}

#signin .authform-anchor,
#signup .authform-anchor,
body#order__signin_to_checkout .authform-anchor {
  font-size: 18px;
  margin-top: 40px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;
  gap: 10px;
}
@media all and (max-width : 768px) {
  #signin .authform-anchor,
  #signup .authform-anchor,
  body#order__signin_to_checkout .authform-anchor {
    flex-direction: column;
  }
}

#signup .authform-anchor {
  flex-direction: row-reverse;
}
@media all and (max-width : 768px) {
  #signup .authform-anchor {
    flex-direction: column-reverse;
  }
}

.form-auth.form-signin .authform-anchor + .signin-form,
#signin #container .authform-anchor + .signin-form {
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .form-auth.form-signin .authform-anchor + .signin-form,
  #signin #container .authform-anchor + .signin-form {
    margin-top: 20px;
  }
}

.authform-anchor__signin,
.authform-anchor__signup {
  color: #396dcf;
  text-decoration: underline;
}

/*
  ---------------
  ページネーション共通
  ---------------
*/
#itemlist .pagination ul li a,
.community .pagination ul li a {
  border-radius: 0;
}

#itemlist .pagination ul li.active a,
.community .pagination ul li.active a {
  background-color: transparent;
  color: #005c69;
}

.pagination {
  display: flex;
  margin-top: 57px;
}
@media all and (max-width : 768px) {
  .pagination {
    margin-top: 40px;
    overflow-x: scroll;
    padding-bottom: 4px;
  }
}
.pagination ul {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
}
@media all and (max-width : 768px) {
  .pagination ul {
    justify-content: left;
    gap: 6px;
  }
}
.pagination li {
  padding: 0;
}
.pagination li.blank {
  display: none;
}
.pagination a {
  background-color: #f6f6f6;
  color: #000;
  display: inline-block;
  padding: 12px 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: normal;
}
@media all and (max-width : 768px) {
  .pagination a {
    padding: 7px 15px;
  }
}
.pagination li.active a {
  background-color: transparent;
  color: #005c69;
  padding-inline: 4px;
}
@media all and (max-width : 768px) {
  .pagination li.active a {
    padding-inline: 0;
  }
}
.pagination > a {
  border-radius: 2px;
  font-size: 12px;
  width: 49%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .pagination > a {
    min-height: 40px;
  }
}

#account__view_account_message_history .pagination,
#account__view_orders .pagination,
#account__view_order_history .pagination,
#account__view_points_history .pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
#account__view_account_message_history .pagination:not(:has(*)),
#account__view_orders .pagination:not(:has(*)),
#account__view_order_history .pagination:not(:has(*)),
#account__view_points_history .pagination:not(:has(*)) {
  margin: 0;
}
#account__view_account_message_history .pagination a,
#account__view_orders .pagination a,
#account__view_order_history .pagination a,
#account__view_points_history .pagination a {
  background-color: #f6f6f6;
  padding: 12px 20px;
  position: relative;
  text-align: center;
  width: 100%;
}
@media all and (max-width : 768px) {
  #account__view_account_message_history .pagination a,
  #account__view_orders .pagination a,
  #account__view_order_history .pagination a,
  #account__view_points_history .pagination a {
    padding: 7px 15px;
  }
}
#account__view_account_message_history .pagination a + a,
#account__view_orders .pagination a + a,
#account__view_order_history .pagination a + a,
#account__view_points_history .pagination a + a {
  margin-left: 20px;
}

@media all and (max-width : 768px) {
  .itemlist .pagination,
  .newsentries.faq .pagination,
  .faq_imported .pagination {
    overflow-x: scroll;
    padding-bottom: 4px;
  }
}
@media all and (max-width : 768px) {
  .itemlist .pagination ul,
  .newsentries.faq .pagination ul,
  .faq_imported .pagination ul {
    gap: 0;
  }
}
@media all and (max-width : 768px) {
  .itemlist .pagination ul li,
  .newsentries.faq .pagination ul li,
  .faq_imported .pagination ul li {
    padding-inline: 4px;
  }
}
@media all and (max-width : 768px) {
  .itemlist .pagination ul li.active,
  .newsentries.faq .pagination ul li.active,
  .faq_imported .pagination ul li.active {
    padding-inline: 8px;
  }
}

/*
  ---------------
  テーブル系
  ---------------
*/
body#order__confirm .itemdetails-note,
body#cart .itemdetails-note,
body#account__view_partwork_subscription .itemdetails-note {
  font-size: 14px;
  margin-top: 16px;
}
body#order__confirm .itemdetails-body,
body#cart .itemdetails-body,
body#account__view_partwork_subscription .itemdetails-body {
  border-top: 1px solid #d9d9d9;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetails-body,
  body#cart .itemdetails-body,
  body#account__view_partwork_subscription .itemdetails-body {
    margin-top: 30px;
  }
}
body#order__confirm .itemdetails-body .itemdetail-row,
body#cart .itemdetails-body .itemdetail-row,
body#account__view_partwork_subscription .itemdetails-body .itemdetail-row {
  border-bottom: 1px solid #d9d9d9;
  padding: 30px 0;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetails-body .itemdetail-row,
  body#cart .itemdetails-body .itemdetail-row,
  body#account__view_partwork_subscription .itemdetails-body .itemdetail-row {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
  }
}
body#order__confirm .itemdetails-body .itemdetail-row .item,
body#cart .itemdetails-body .itemdetail-row .item,
body#account__view_partwork_subscription .itemdetails-body .itemdetail-row .item {
  min-width: 0;
}
body#order__confirm .itemdetails-body .item-thumbnail-img,
body#cart .itemdetails-body .item-thumbnail-img,
body#account__view_partwork_subscription .itemdetails-body .item-thumbnail-img {
  padding: 0;
}
body#order__confirm .item-thumbnail-img .no-img,
body#cart .item-thumbnail-img .no-img,
body#account__view_partwork_subscription .item-thumbnail-img .no-img {
  box-shadow: none;
}
body#order__confirm .itemdetails-body .goods.entry-item,
body#cart .itemdetails-body .goods.entry-item,
body#account__view_partwork_subscription .itemdetails-body .goods.entry-item {
  display: flex;
  gap: 20px;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetails-body .goods.entry-item,
  body#cart .itemdetails-body .goods.entry-item,
  body#account__view_partwork_subscription .itemdetails-body .goods.entry-item {
    flex-direction: row;
    gap: 8px;
  }
}
body#order__confirm .itemdetails-body .goods.entry-item .item-thumbnail-img,
body#cart .itemdetails-body .goods.entry-item .item-thumbnail-img,
body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .item-thumbnail-img {
  overflow: hidden;
  height: 158px;
  width: 180px;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetails-body .goods.entry-item .item-thumbnail-img,
  body#cart .itemdetails-body .goods.entry-item .item-thumbnail-img,
  body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .item-thumbnail-img {
    height: 90px;
    width: 100px;
  }
}
body#order__confirm .itemdetails-body .goods.entry-item .item-thumbnail-img > a,
body#cart .itemdetails-body .goods.entry-item .item-thumbnail-img > a,
body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .item-thumbnail-img > a {
  height: 100%;
  width: 100%;
}
body#order__confirm .itemdetails-body .goods.entry-item .item-thumbnail-img > img,
body#cart .itemdetails-body .goods.entry-item .item-thumbnail-img > img,
body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .item-thumbnail-img > img {
  height: 100%;
  object-fit: contain;
}
body#order__confirm .itemdetails-body .goods.entry-item .goods-text,
body#cart .itemdetails-body .goods.entry-item .goods-text,
body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .goods-text {
  padding-right: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetails-body .goods.entry-item .goods-text,
  body#cart .itemdetails-body .goods.entry-item .goods-text,
  body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .goods-text {
    padding: 0;
  }
}
body#order__confirm .itemdetails-body .goods.entry-item .goods-text > .purchases-messages,
body#cart .itemdetails-body .goods.entry-item .goods-text > .purchases-messages,
body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .goods-text > .purchases-messages {
  padding-top: 6px;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetails-body .goods.entry-item .goods-text > .purchases-messages,
  body#cart .itemdetails-body .goods.entry-item .goods-text > .purchases-messages,
  body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .goods-text > .purchases-messages {
    padding-top: 0;
  }
}
body#order__confirm .itemdetails-body .goods.entry-item .goods-text > .purchases-messages .stock-msg-dtl,
body#cart .itemdetails-body .goods.entry-item .goods-text > .purchases-messages .stock-msg-dtl,
body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .goods-text > .purchases-messages .stock-msg-dtl {
  color: #005c69;
}
body#order__confirm .itemdetails-body .goods.entry-item:not([data-product-class^=ECOM]) .goods-text > .unit-price,
body#cart .itemdetails-body .goods.entry-item:not([data-product-class^=ECOM]) .goods-text > .unit-price,
body#account__view_partwork_subscription .itemdetails-body .goods.entry-item:not([data-product-class^=ECOM]) .goods-text > .unit-price {
  display: none;
}
body#order__confirm .itemdetails-body .itemdetail-row.free-item-row .goods .goods-text > .unit-price,
body#cart .itemdetails-body .itemdetail-row.free-item-row .goods .goods-text > .unit-price,
body#account__view_partwork_subscription .itemdetails-body .itemdetail-row.free-item-row .goods .goods-text > .unit-price {
  display: none;
}
body#order__confirm .itemdetails-body .goods.entry-item .goods-text > .unit-price,
body#cart .itemdetails-body .goods.entry-item .goods-text > .unit-price,
body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .goods-text > .unit-price {
  font-size: 14px;
  line-height: 1.7;
  text-align: left;
  order: 4;
}
@media all and (min-width: 769px) {
  body#order__confirm .itemdetails-body .goods.entry-item .goods-text > .unit-price,
  body#cart .itemdetails-body .goods.entry-item .goods-text > .unit-price,
  body#account__view_partwork_subscription .itemdetails-body .goods.entry-item .goods-text > .unit-price {
    margin-top: 4px;
  }
}
body#order__confirm .itemdetails-body .unit-price .individual-delivery-fee,
body#cart .itemdetails-body .unit-price .individual-delivery-fee,
body#account__view_partwork_subscription .itemdetails-body .unit-price .individual-delivery-fee {
  font-size: 11px;
  display: block;
}
body#order__confirm .itemdetails-body .goods-text .item-name,
body#cart .itemdetails-body .goods-text .item-name,
body#account__view_partwork_subscription .itemdetails-body .goods-text .item-name {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
body#order__confirm .itemdetails-body .goods-text .item-name a,
body#cart .itemdetails-body .goods-text .item-name a,
body#account__view_partwork_subscription .itemdetails-body .goods-text .item-name a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
body#order__confirm .itemdetails-body .goods-text .item-name .code,
body#cart .itemdetails-body .goods-text .item-name .code,
body#account__view_partwork_subscription .itemdetails-body .goods-text .item-name .code {
  color: #747474;
  font-size: 12px;
}
body#order__confirm .itemdetail-row,
body#cart .itemdetail-row,
body#account__view_partwork_subscription .itemdetail-row {
  position: relative;
}
body#order__confirm .itemdetail-row .item .control,
body#cart .itemdetail-row .item .control,
body#account__view_partwork_subscription .itemdetail-row .item .control {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 0;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetail-row .item .control,
  body#cart .itemdetail-row .item .control,
  body#account__view_partwork_subscription .itemdetail-row .item .control {
    padding-left: 0;
    left: 0;
    right: unset;
  }
}
body#order__confirm .itemdetail-row .item .control .wrapper,
body#cart .itemdetail-row .item .control .wrapper,
body#account__view_partwork_subscription .itemdetail-row .item .control .wrapper {
  display: flex;
  flex-direction: row;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetail-row .item .control .wrapper,
  body#cart .itemdetail-row .item .control .wrapper,
  body#account__view_partwork_subscription .itemdetail-row .item .control .wrapper {
    flex-direction: column;
    gap: 0;
  }
}
body#order__confirm .itemdetail-row .item .cartitem-basic-control,
body#order__confirm .itemdetail-row .item .wrapper,
body#cart .itemdetail-row .item .cartitem-basic-control,
body#cart .itemdetail-row .item .wrapper,
body#account__view_partwork_subscription .itemdetail-row .item .cartitem-basic-control,
body#account__view_partwork_subscription .itemdetail-row .item .wrapper {
  margin-left: 20px;
  display: flex;
  gap: 20px;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetail-row .item .cartitem-basic-control,
  body#order__confirm .itemdetail-row .item .wrapper,
  body#cart .itemdetail-row .item .cartitem-basic-control,
  body#cart .itemdetail-row .item .wrapper,
  body#account__view_partwork_subscription .itemdetail-row .item .cartitem-basic-control,
  body#account__view_partwork_subscription .itemdetail-row .item .wrapper {
    padding-top: 12px;
    margin-left: 0;
    gap: 10px;
  }
}
body#order__confirm .itemdetail-row .item .wrapper .ready-cartitem,
body#order__confirm .itemdetail-row .item .wrapper > a,
body#order__confirm .itemdetail-row .item .cartitem-basic-control .remove-cartitem,
body#order__confirm .itemdetail-row .item .cartitem-basic-control .save-cartitem,
body#cart .itemdetail-row .item .wrapper .ready-cartitem,
body#cart .itemdetail-row .item .wrapper > a,
body#cart .itemdetail-row .item .cartitem-basic-control .remove-cartitem,
body#cart .itemdetail-row .item .cartitem-basic-control .save-cartitem,
body#account__view_partwork_subscription .itemdetail-row .item .wrapper .ready-cartitem,
body#account__view_partwork_subscription .itemdetail-row .item .wrapper > a,
body#account__view_partwork_subscription .itemdetail-row .item .cartitem-basic-control .remove-cartitem,
body#account__view_partwork_subscription .itemdetail-row .item .cartitem-basic-control .save-cartitem {
  color: #005c69;
  font-size: 14px;
  padding-left: 16px;
  position: relative;
}
body#order__confirm .itemdetail-row .item .wrapper .ready-cartitem::before,
body#order__confirm .itemdetail-row .item .wrapper > a::before,
body#order__confirm .itemdetail-row .item .cartitem-basic-control .remove-cartitem::before,
body#order__confirm .itemdetail-row .item .cartitem-basic-control .save-cartitem::before,
body#cart .itemdetail-row .item .wrapper .ready-cartitem::before,
body#cart .itemdetail-row .item .wrapper > a::before,
body#cart .itemdetail-row .item .cartitem-basic-control .remove-cartitem::before,
body#cart .itemdetail-row .item .cartitem-basic-control .save-cartitem::before,
body#account__view_partwork_subscription .itemdetail-row .item .wrapper .ready-cartitem::before,
body#account__view_partwork_subscription .itemdetail-row .item .wrapper > a::before,
body#account__view_partwork_subscription .itemdetail-row .item .cartitem-basic-control .remove-cartitem::before,
body#account__view_partwork_subscription .itemdetail-row .item .cartitem-basic-control .save-cartitem::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
body#order__confirm .itemdetail-row .item .wrapper > a::before,
body#order__confirm .itemdetail-row .item .cartitem-basic-control .remove-cartitem::before,
body#cart .itemdetail-row .item .wrapper > a::before,
body#cart .itemdetail-row .item .cartitem-basic-control .remove-cartitem::before,
body#account__view_partwork_subscription .itemdetail-row .item .wrapper > a::before,
body#account__view_partwork_subscription .itemdetail-row .item .cartitem-basic-control .remove-cartitem::before {
  background: url(/f/resources/images/common/icon_remove.svg) 50%/contain no-repeat;
  height: 12px;
  width: 11px;
}
body#order__confirm .itemdetail-row .item .cartitem-basic-control .save-cartitem::before,
body#cart .itemdetail-row .item .cartitem-basic-control .save-cartitem::before,
body#account__view_partwork_subscription .itemdetail-row .item .cartitem-basic-control .save-cartitem::before {
  background: url(/f/resources/images/common/icon_bug_cross.svg) 50%/contain no-repeat;
  height: 15px;
  width: 13px;
}
body#order__confirm .itemdetail-row .item .wrapper .ready-cartitem::before,
body#cart .itemdetail-row .item .wrapper .ready-cartitem::before,
body#account__view_partwork_subscription .itemdetail-row .item .wrapper .ready-cartitem::before {
  background: url(/f/resources/images/common/icon_bug.svg) 50%/contain no-repeat;
  height: 15px;
  width: 13px;
}
body#order__confirm .itemdetail-row .item .partwork-subs-control button,
body#cart .itemdetail-row .item .partwork-subs-control button,
body#account__view_partwork_subscription .itemdetail-row .item .partwork-subs-control button {
  background: none;
  border: none;
  box-shadow: none;
  display: none;
  font-size: 14px;
  padding-block: 0;
  padding-right: 25px;
  position: relative;
}
@media (hover: hover) and (pointer: fine) {
  body#order__confirm .itemdetail-row .item .partwork-subs-control button,
  body#cart .itemdetail-row .item .partwork-subs-control button,
  body#account__view_partwork_subscription .itemdetail-row .item .partwork-subs-control button {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#order__confirm .itemdetail-row .item .partwork-subs-control button:hover,
  body#cart .itemdetail-row .item .partwork-subs-control button:hover,
  body#account__view_partwork_subscription .itemdetail-row .item .partwork-subs-control button:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#order__confirm .itemdetail-row .item .partwork-subs-control button::before, body#order__confirm .itemdetail-row .item .partwork-subs-control button::after,
body#cart .itemdetail-row .item .partwork-subs-control button::before,
body#cart .itemdetail-row .item .partwork-subs-control button::after,
body#account__view_partwork_subscription .itemdetail-row .item .partwork-subs-control button::before,
body#account__view_partwork_subscription .itemdetail-row .item .partwork-subs-control button::after {
  backface-visibility: hidden;
}
body#order__confirm .itemdetail-row .item .partwork-subs-control button::before,
body#cart .itemdetail-row .item .partwork-subs-control button::before,
body#account__view_partwork_subscription .itemdetail-row .item .partwork-subs-control button::before {
  background: url(/f/resources/images/common/icon_arrow_right_circle.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}
body#order__confirm .itemdetail-row .purchase-contents,
body#cart .itemdetail-row .purchase-contents,
body#account__view_partwork_subscription .itemdetail-row .purchase-contents {
  min-width: 400px;
  display: flex;
  align-items: flex-start;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetail-row .purchase-contents,
  body#cart .itemdetail-row .purchase-contents,
  body#account__view_partwork_subscription .itemdetail-row .purchase-contents {
    min-width: unset;
    padding-left: 162px;
    margin-top: 10px;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
  }
}
body#order__confirm .itemdetail-row .purchase-contents .quantity,
body#cart .itemdetail-row .purchase-contents .quantity,
body#account__view_partwork_subscription .itemdetail-row .purchase-contents .quantity {
  padding: 0;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetail-row .purchase-contents .quantity .item-qty,
  body#cart .itemdetail-row .purchase-contents .quantity .item-qty,
  body#account__view_partwork_subscription .itemdetail-row .purchase-contents .quantity .item-qty {
    display: flex;
    align-items: center;
  }
}
body#order__confirm .itemdetail-row .purchase-contents .quantity .item-qty-label,
body#cart .itemdetail-row .purchase-contents .quantity .item-qty-label,
body#account__view_partwork_subscription .itemdetail-row .purchase-contents .quantity .item-qty-label {
  font-size: 14px;
  white-space: nowrap;
}
body#order__confirm .itemdetail-row .purchase-contents .quantity select,
body#cart .itemdetail-row .purchase-contents .quantity select,
body#account__view_partwork_subscription .itemdetail-row .purchase-contents .quantity select {
  appearance: none;
  background: #f6f6f6 url(/f/resources/images/common/icon_arrow_down_circle.svg) calc(100% - 10px) 50%/24px no-repeat;
  border: none;
  border-radius: 2px;
  font-size: 20px;
  font-weight: 700;
  margin-left: 16px;
  padding: 5px 34px 5px 20px;
  height: 40px;
  max-width: 150px;
  width: 100%;
}
@media (hover: hover) and (pointer: fine) {
  body#order__confirm .itemdetail-row .purchase-contents .quantity select,
  body#cart .itemdetail-row .purchase-contents .quantity select,
  body#account__view_partwork_subscription .itemdetail-row .purchase-contents .quantity select {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#order__confirm .itemdetail-row .purchase-contents .quantity select:hover,
  body#cart .itemdetail-row .purchase-contents .quantity select:hover,
  body#account__view_partwork_subscription .itemdetail-row .purchase-contents .quantity select:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#order__confirm .itemdetail-row .purchase-contents .quantity select::before, body#order__confirm .itemdetail-row .purchase-contents .quantity select::after,
body#cart .itemdetail-row .purchase-contents .quantity select::before,
body#cart .itemdetail-row .purchase-contents .quantity select::after,
body#account__view_partwork_subscription .itemdetail-row .purchase-contents .quantity select::before,
body#account__view_partwork_subscription .itemdetail-row .purchase-contents .quantity select::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetail-row .purchase-contents .quantity select,
  body#cart .itemdetail-row .purchase-contents .quantity select,
  body#account__view_partwork_subscription .itemdetail-row .purchase-contents .quantity select {
    font-size: 16px;
    min-width: 84px;
    padding: 5px 30px 5px 12px;
  }
}
body#order__confirm .itemdetail-row .purchase-contents .subtotal,
body#cart .itemdetail-row .purchase-contents .subtotal,
body#account__view_partwork_subscription .itemdetail-row .purchase-contents .subtotal {
  font-size: 20px;
  font-weight: 700;
  padding: 4px 0 0 0;
}
@media all and (max-width : 768px) {
  body#order__confirm .itemdetail-row .purchase-contents .subtotal,
  body#cart .itemdetail-row .purchase-contents .subtotal,
  body#account__view_partwork_subscription .itemdetail-row .purchase-contents .subtotal {
    font-size: 18px;
  }
}
body#order__confirm .itemdetail-row .purchase-contents .subtotal.free .amount,
body#cart .itemdetail-row .purchase-contents .subtotal.free .amount,
body#account__view_partwork_subscription .itemdetail-row .purchase-contents .subtotal.free .amount {
  visibility: hidden;
}
@media all and (min-width: 769px) {
  body#order__confirm .purchase-contents .subtotal .partwork-subscription,
  body#cart .purchase-contents .subtotal .partwork-subscription,
  body#account__view_partwork_subscription .purchase-contents .subtotal .partwork-subscription {
    margin-left: 12px;
  }
}
@media all and (max-width : 768px) {
  body#order__confirm .price-total.cart-total,
  body#cart .price-total.cart-total,
  body#account__view_partwork_subscription .price-total.cart-total {
    padding-top: 18px;
    padding-inline: 0;
    display: flex;
    justify-content: flex-end;
  }
}
body#order__confirm .cart-total .label,
body#order__confirm .cart-total .qty,
body#cart .cart-total .label,
body#cart .cart-total .qty,
body#account__view_partwork_subscription .cart-total .label,
body#account__view_partwork_subscription .cart-total .qty {
  font-size: 16px;
  font-weight: 500;
}
@media all and (max-width : 768px) {
  body#order__confirm .cart-total .row,
  body#cart .cart-total .row,
  body#account__view_partwork_subscription .cart-total .row {
    align-items: center;
  }
}
body#order__confirm .price-total .qty,
body#cart .price-total .qty,
body#account__view_partwork_subscription .price-total .qty {
  display: none;
}
@media all and (max-width : 768px) {
  body#order__confirm .cart-total .subtotal,
  body#cart .cart-total .subtotal,
  body#account__view_partwork_subscription .cart-total .subtotal {
    margin-left: 0;
  }
}
body#order__confirm .cart-total .subtotal .price,
body#order__confirm .cart-total .cart-total-amt,
body#cart .cart-total .subtotal .price,
body#cart .cart-total .cart-total-amt,
body#account__view_partwork_subscription .cart-total .subtotal .price,
body#account__view_partwork_subscription .cart-total .cart-total-amt {
  color: #005c69;
  font-size: 20px;
  font-weight: 700;
}
@media all and (max-width : 768px) {
  body#order__confirm .cart-msgs,
  body#cart .cart-msgs,
  body#account__view_partwork_subscription .cart-msgs {
    padding: 0;
  }
}
body#order__confirm .partwork-subscription,
body#cart .partwork-subscription,
body#account__view_partwork_subscription .partwork-subscription {
  padding-top: 6px;
}
body#order__confirm .partwork-subscription > .label,
body#cart .partwork-subscription > .label,
body#account__view_partwork_subscription .partwork-subscription > .label {
  color: #005c69;
  font-size: 14px;
  line-height: 1.7;
  padding-right: 25px;
  position: relative;
  width: fit-content;
}
@media (hover: hover) and (pointer: fine) {
  body#order__confirm .partwork-subscription > .label,
  body#cart .partwork-subscription > .label,
  body#account__view_partwork_subscription .partwork-subscription > .label {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#order__confirm .partwork-subscription > .label:hover,
  body#cart .partwork-subscription > .label:hover,
  body#account__view_partwork_subscription .partwork-subscription > .label:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#order__confirm .partwork-subscription > .label::before, body#order__confirm .partwork-subscription > .label::after,
body#cart .partwork-subscription > .label::before,
body#cart .partwork-subscription > .label::after,
body#account__view_partwork_subscription .partwork-subscription > .label::before,
body#account__view_partwork_subscription .partwork-subscription > .label::after {
  backface-visibility: hidden;
}
body#order__confirm .partwork-subscription > .label::before,
body#cart .partwork-subscription > .label::before,
body#account__view_partwork_subscription .partwork-subscription > .label::before {
  background: url(/f/resources/images/common/icon_plus_circle_green.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0%;
  right: 0px;
}
body#order__confirm .partwork-subscription > .body,
body#cart .partwork-subscription > .body,
body#account__view_partwork_subscription .partwork-subscription > .body {
  background-color: #fff;
  overflow: auto;
  padding: 0 0 22px;
  z-index: 100;
  height: 80vh;
  width: 80vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media all and (max-width : 768px) {
  body#order__confirm .partwork-subscription > .body,
  body#cart .partwork-subscription > .body,
  body#account__view_partwork_subscription .partwork-subscription > .body {
    height: 90svh;
    width: 90vw;
  }
}
body#order__confirm div.itemdetails div.partwork-subscription > div.body,
body#cart div.itemdetails div.partwork-subscription > div.body,
body#account__view_partwork_subscription div.itemdetails div.partwork-subscription > div.body {
  display: none;
}

div.itemdetails.pwk-exclusive .stock-msg-note.note {
  display: none;
}

@media all and (max-width : 768px) {
  body#account__view_partwork_subscription .btn-group > div {
    display: block;
  }
}

@media (min-width: 801px) {
  body#order__payment_status div.order-items .itemdetails-header,
  body#account__order_inquiry div.order-items .itemdetails-header,
  body#account__order div.order-items .itemdetails-header {
    display: block;
  }
}

.itemdetails-header {
  margin-top: 16px;
}

@media (min-width: 800px) {
  div.itemdetails-header div.itemdetail-row {
    border: 0;
  }
}

#account__order .itemdetails-header .itemdetail-row .purchase-contents,
body#cart .itemdetails-header .itemdetail-row .purchase-contents,
.itemdetails-header .itemdetail-row .purchase-contents {
  display: flex;
  align-items: center;
}

#order__payment_status .itemdetails-header .itemdetail-row .purchase-contents .quantity,
#order__payment_status .itemdetails-header .itemdetail-row .purchase-contents .subtotal,
#order__confirm .itemdetails-header .itemdetail-row .purchase-contents .quantity,
#order__confirm .itemdetails-header .itemdetail-row .purchase-contents .subtotal,
#account__order_inquiry .itemdetails-header .itemdetail-row .purchase-contents .quantity,
#account__order_inquiry .itemdetails-header .itemdetail-row .purchase-contents .subtotal,
#account__order .itemdetails-header .itemdetail-row .purchase-contents .quantity,
#account__order .itemdetails-header .itemdetail-row .purchase-contents .subtotal,
body#cart .itemdetails-header .itemdetail-row .purchase-contents .quantity,
body#cart .itemdetails-header .itemdetail-row .purchase-contents .subtotal,
.itemdetails-header .itemdetail-row .purchase-contents .quantity,
.itemdetails-header .itemdetail-row .purchase-contents .subtotal {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-block: 10px;
}

.itemdetails-body {
  border-top: 1px solid #d9d9d9;
}
@media all and (max-width : 768px) {
  .itemdetails-body {
    margin-top: 16px;
  }
}
.itemdetails-body + .itemdetails-body {
  border-top: 0;
}

@media all and (max-width : 768px) {
  .itemdetails-body .itemdetail-row .purchase-contents .quantity .qty::before {
    content: "数量";
    margin-right: 6px;
  }
}

.itemdetails-body .goods.purchased-item .goods-text > .unit-price {
  text-align: left;
}

.itemdetails-body .goods.purchased-item .goods-text > .individual-delivery-fee {
  font-size: 11px;
}

.itemdetails-body .goods.purchased-item .goods-text > .individual-delivery-fee .note {
  margin: 0;
}

.itemdetails-body .goods.purchased-item .goods-text > .unit-price::before,
.itemdetails-body .goods.entry-item .goods-text > .unit-price .final::before {
  content: "単価";
  margin-right: 6px;
}

@media all and (max-width : 768px) {
  .itemdetails-body .itemdetail-row .purchase-contents .subtotal {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
@media all and (max-width : 768px) {
  #order__confirm .itemdetails-body .itemdetail-row .purchase-contents .subtotal {
    width: auto;
    display: block;
  }
}

@media all and (max-width : 768px) {
  .itemdetails-body .itemdetail-row .purchase-contents .subtotal .amount::before {
    content: "小計";
    margin-right: 4px;
  }
}

.itemdetails-body .itemdetail-row .purchase-contents .subtotal:has(.alert) {
  position: relative;
}
.itemdetails-body .itemdetail-row .purchase-contents .subtotal:has(.alert) .amount {
  display: none;
}

.itemdetails-body .itemdetail-row .purchase-contents .subtotal .alert {
  font-size: 13px;
  text-align: left;
  margin-top: 0;
  width: 400px;
  position: absolute;
  right: 0;
  top: 70px;
}
@media screen and (max-width: 800px) {
  .itemdetails-body .itemdetail-row .purchase-contents .subtotal .alert {
    position: static;
    margin-bottom: 0;
  }
}

#order__payment_status .itemdetail-row .purchase-contents .quantity,
#order__payment_status .itemdetail-row .purchase-contents .subtotal,
#order__confirm .itemdetail-row .purchase-contents .quantity,
#order__confirm .itemdetail-row .purchase-contents .subtotal,
body#cart .itemdetail-row .purchase-contents .quantity,
body#cart .itemdetail-row .purchase-contents .subtotal,
#account__order_inquiry .itemdetail-row .purchase-contents .quantity,
#account__order_inquiry .itemdetail-row .purchase-contents .subtotal,
#account__order .itemdetail-row .purchase-contents .quantity,
#account__order .itemdetail-row .purchase-contents .subtotal,
.itemdetail-row .purchase-contents .quantity,
.itemdetail-row .purchase-contents .subtotal {
  flex: 1;
  text-align: center;
}

body#order__payment_status div.itemdetail-row .item,
body#account__order_inquiry div.itemdetail-row .item,
body#account__order div.itemdetail-row .item {
  flex: 2;
}

@media all and (min-width: 769px) {
  body#order__payment_status div.purchase-contents,
  body#account__order_inquiry div.purchase-contents,
  #account__order div.purchase-contents {
    gap: 0;
  }
}

body#account__order .itemdetails-body .itemdetail-row {
  align-items: flex-start;
}
@media all and (max-width : 768px) {
  body#account__order .itemdetails-body .itemdetail-row {
    align-items: flex-end;
    width: 100%;
  }
}

@media all and (max-width : 768px) {
  body#account__order .itemdetails-body .itemdetail-row .quantity {
    text-align: right;
    width: 100%;
  }
}

body#account__order .itemdetails-body .itemdetail-row.free-item-row .unit-price,
body#account__order .itemdetails-body .itemdetail-row.free-item-row .subtotal .amount {
  display: none;
}

/*
  ---------------
  パートワークモーダル
  ---------------
*/
.show-distribution-target {
  display: flex;
  overscroll-behavior-y: contain;
  overflow-y: scroll;
}
.show-distribution-target:before, .show-distribution-target:after {
  content: "";
  width: 1px;
  height: calc(100vh + 1px);
  display: flex;
}
.show-distribution-target .distribution-container {
  width: 80%;
  margin: auto;
  overscroll-behavior-y: contain;
  overflow-y: scroll;
  position: static !important;
}
@media all and (max-width : 768px) {
  .show-distribution-target .distribution-container {
    width: 94% !important;
  }
}
.show-distribution-target .distribution-container > .upper {
  background: linear-gradient(90deg, #005C69 0%, #00ADA0 100%);
  text-align: center;
  padding: 22px;
  position: relative;
}
@media all and (max-width : 768px) {
  .show-distribution-target .distribution-container > .upper {
    padding: 12px;
  }
}
.show-distribution-target .distribution-container > .upper > .label {
  color: #fff;
  font-size: 24px;
  line-height: 1.45833333;
  padding-left: 36px;
  position: relative;
}
@media all and (max-width : 768px) {
  .show-distribution-target .distribution-container > .upper > .label {
    font-size: 18px;
    line-height: 1.44444444;
    padding-left: 23px;
  }
}
.show-distribution-target .distribution-container > .upper > .label::before {
  content: "";
  background: transparent url(/f/resources/images/common/icon_cart.svg) center/contain no-repeat;
  display: inline-block;
  width: 26px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .show-distribution-target .distribution-container > .upper > .label::before {
    width: 17px;
    height: 20px;
  }
}
.show-distribution-target .distribution-container > .upper > .btn-close {
  background: url("/f/resources/images/common/icon_modal_close.svg") center/contain no-repeat;
  cursor: pointer;
  display: inline-block;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .show-distribution-target .distribution-container > .upper > .btn-close {
    width: 20px;
    height: 20px;
    right: 14px;
  }
}
.show-distribution-target .distribution-container > .heading {
  font-size: 24px;
  line-height: 1.45833333;
  text-align: left;
  padding: 30px 55px 0;
}
@media all and (max-width : 768px) {
  .show-distribution-target .distribution-container > .heading {
    font-size: 20px;
    padding: 18px 20px 0;
  }
}
.show-distribution-target .distribution-container .main-img {
  padding: 19px 55px 0;
  text-align: center;
}
@media all and (max-width : 768px) {
  .show-distribution-target .distribution-container .main-img {
    padding: 10px 20px 0;
  }
}
.show-distribution-target .distribution-container .main-img img {
  border: 1px solid #d9d9d9;
  height: 100%;
  max-height: 300px;
}
.show-distribution-target .distribution-container .main-title img,
.show-distribution-target .distribution-container .main-title > .pwk-title {
  display: none;
}
.show-distribution-target .distribution-container .main-title > .dstr-title {
  display: block;
}
.show-distribution-target div.partwork-distribution {
  background-color: transparent;
  color: #1d1d1b;
}
.show-distribution-target div.ac.partwork-distribution > div.container {
  width: 100% !important;
}
.show-distribution-target .partwork-distribution > .container > .panel-list {
  display: block !important;
  width: 100% !important;
  position: static !important;
}
.show-distribution-target .partwork-distribution > .container > .panel-list > li {
  border-right: 0;
  padding: 0;
  width: 100% !important;
}
.show-distribution-target .partwork-distribution > .container > .panel-list > li + li {
  margin-top: 10px;
}
.show-distribution-target .partwork-distribution > .container > .panel-list > li button.btn.slide.next,
.show-distribution-target .partwork-distribution > .container > .panel-list > li button.btn.slide.prev {
  display: none;
}
.show-distribution-target .partwork-distribution > .container .distribution > .heading {
  background: linear-gradient(0deg, #F6F6F6, #F6F6F6), linear-gradient(0deg, #005C69, #005C69);
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  padding: 17px 20px;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .heading {
    padding: 15px 11px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .heading .main-title {
  padding-left: 48px;
  position: relative;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .heading .main-title {
    padding-left: 29px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .heading .main-title::before,
.show-distribution-target .partwork-distribution > .container .distribution > .heading .main-title::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.show-distribution-target .partwork-distribution > .container .distribution > .heading .main-title::before {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  left: 0;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .heading .main-title::before {
    width: 20px;
    height: 20px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .heading.is-open .main-title::after {
  background-color: #005c69;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: background-color 0.2s ease-in-out;
  left: 6px;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .heading.is-open .main-title::after {
    width: 14px;
    height: 14px;
    left: 4px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution .dstr-title {
  color: #333;
  font-size: 20px;
  font-weight: 400;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution .dstr-title {
    font-size: 18px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .heading > .dstr-options {
  display: none;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body {
  display: none;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .purchase-backnumber .heading .title {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  text-decoration: none;
  padding: 13px 35px 13px 20px;
  position: relative;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .purchase-backnumber .heading .title {
    padding: 15px 60px 15px 20px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .purchase-backnumber .heading .note {
  margin-top: 0;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body {
  display: none;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 20px;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body {
    padding: 12px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body .parcels {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  max-height: 255px;
  overflow-y: scroll;
  padding: 10px 20px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body .parcel {
  border: 0;
  margin-top: 0;
  padding: 0;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body .parcel {
  cursor: pointer;
  display: block;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .purchase-backnumber > .body .issue-thumbnail {
  display: none;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .select-start {
  margin-bottom: 40px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .select-start .body {
  margin-top: 20px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .heading {
  margin-top: 30px;
}
@media all and (min-width: 769px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .heading {
    margin-top: 40px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .heading .title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
}
@media all and (min-width: 769px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .heading .title {
    font-size: 24px;
    line-height: 1.45833333;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .heading .note {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285143;
  margin: 0;
  margin-top: 10px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option {
  margin-bottom: 40px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .heading {
  font-size: 24px;
  line-height: 1.45833333;
  padding-top: 5px;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .heading {
    font-size: 20px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option {
  background: linear-gradient(0deg, #F6F6F6, #F6F6F6), linear-gradient(0deg, #005C69, #005C69);
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 12px;
  padding: 12px;
  position: relative;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option.is-checked {
  border: 1px solid #005c69;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option .option-icon {
  border: 1px solid #d9d9d9;
  aspect-ratio: 120/105;
  width: 100%;
  height: 100%;
  max-width: 60px;
  max-height: 60px;
  margin-left: 15px;
  object-fit: contain;
  order: 2;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option .option-icon {
    aspect-ratio: 102/90;
    max-width: 60px;
    margin-left: 8px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option .option-label {
  color: #1d1d1b;
  font-size: 16px;
  line-height: normal;
  margin-left: 13px;
  order: 3;
}
@media all and (min-width: 769px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option .option-label {
    font-size: 20px;
    margin-left: 19px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option .option-contents {
  color: #1d1d1b;
  font-size: 10px;
  line-height: normal;
  margin-top: 4px;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option .option-contents {
    width: 100%;
  }
}
@media all and (min-width: 769px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option .option-contents {
    font-size: 12px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option > label {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option > label > input[type=checkbox] {
  accent-color: #fff;
  display: inline-block;
  margin: 0;
  inline-size: 30px;
  block-size: 30px;
  order: 1;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .choose-option .body .option > label > input[type=checkbox] {
    width: 20px;
    height: 20px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .select-qty {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .select-qty .heading {
  color: #1d1d1b;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 15px;
  margin-top: 0;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .select-qty select.item-qty {
  appearance: none; /* デフォルトの矢印を非表示 */
  background: #F6F6F6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  border-radius: 2px;
  border: 3px solid #fff;
  color: #1d1d1b;
  cursor: pointer;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  line-height: normal;
  padding: 0 10px 0 29px;
  width: 151px;
  display: inline-flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .select-qty select.item-qty {
    font-size: 16px;
    height: 35px;
    padding: 0 0 0 13px;
    width: 84px;
  }
}
@media all and (max-width : 768px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .buttons {
    margin-top: 15px;
  }
}
@media all and (min-width: 769px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .buttons {
    display: inline-block;
    margin-left: 10px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .buttons button[type=submit] {
  background: linear-gradient(90deg, #005C69 0%, #00ADA0 100%);
  border: 0;
  border-radius: 2px;
  color: #fff !important;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  min-height: 50px;
  padding-inline: 46px 18px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .subscribe .buttons button[type=submit]::before {
  background: transparent url("/f/resources/images/packtoy/list/icon_cart.svg") center/contain no-repeat;
  content: "";
  display: inline-block;
  width: 18px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule {
  border-top: none;
  margin-top: 50px;
  padding: 0;
}
@media all and (min-width: 769px) {
  .show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule {
    margin-top: 30px;
  }
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .heading {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body {
  border-top: 1px solid #b2b2b2;
  margin-top: 10px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .note {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285143;
  margin: 0;
  margin-top: 10px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels {
  gap: 0;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel {
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  margin-top: 10px;
  padding: 0;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel.is-open {
  border-bottom: 1px solid #d9d9d9;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel .summary {
  position: relative;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel .summary .description {
  display: none;
  font-size: 12px;
  line-height: normal;
  margin-top: 13px;
  padding-inline: 20px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel .summary > .label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  text-decoration: none;
  padding-right: 0;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel .summary::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_plus_green.svg") center/contain no-repeat;
  display: inline-block;
  width: 15px;
  height: 13px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .issues,
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .option-parcels,
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .subscription-privileges {
  background-color: #fff;
  margin-top: 15px;
  padding: 0 20px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .issues > .label,
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .subscription-privileges > .label {
  font-weight: 400;
  line-height: normal;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcels .parcel > .issues > .issues {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues .issue-thumbnail {
  max-width: 140px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues .issue {
  max-width: 140px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues .issue-thumbnail {
  display: block;
  max-width: 140px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues .issue-thumbnail img {
  aspect-ratio: 140/123;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .item-name,
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .price {
  display: block;
  line-height: normal;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .item-name *,
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .price * {
  font-size: 12px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .item-name {
  font-weight: 500;
  letter-spacing: -0.12px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel > .issues > ol .price {
  font-weight: 400;
  margin-top: 7px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel .shipping > * {
  display: none;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel .shipping .shipping-from,
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel .shipping .shipping-from .label {
  color: #005C69;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel.is-open {
  padding-bottom: 20px;
}
.show-distribution-target .partwork-distribution > .container .distribution > .body .distribution-schedule .body .parcel.is-open .summary::after {
  background: transparent url("/f/resources/images/common/icon_minus_green.svg") center/contain no-repeat;
}
.show-distribution-target .distribution-schedule .parcel > *:not(.summary) {
  display: none;
}
.show-distribution-target .distribution-schedule .parcel .summary {
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  padding: 12px 60px 12px 20px;
  position: relative;
}
@media all and (max-width : 768px) {
  .show-distribution-target .distribution-schedule .parcel .summary {
    padding-right: 40px;
    padding-left: 12px;
  }
}

/*
  ---------------
  決済方法選択
  ---------------
*/
.main-payment-method,
#default-payment-entry,
#saved-payment-entries {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 40px;
}
.main-payment-method > .note,
#default-payment-entry > .note,
#saved-payment-entries > .note {
  padding: 0 20px;
}
.main-payment-method div.wrapper,
#default-payment-entry div.wrapper,
#saved-payment-entries div.wrapper {
  border: none;
  margin: 0;
  max-width: 100%;
  padding: 28px;
  position: relative;
  word-break: break-all;
  width: 100%;
}
@media all and (max-width: 1024px) {
  .main-payment-method div.wrapper,
  #default-payment-entry div.wrapper,
  #saved-payment-entries div.wrapper {
    padding-block: 26px;
  }
}
.main-payment-method div.wrapper > div.method-name,
.main-payment-method .panel-list .panel div.method-name,
#default-payment-entry div.wrapper > div.method-name,
#default-payment-entry .panel-list .panel div.method-name,
#saved-payment-entries div.wrapper > div.method-name,
#saved-payment-entries .panel-list .panel div.method-name {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-align: left;
}
@media all and (max-width: 1024px) {
  .main-payment-method div.wrapper > div.method-name,
  .main-payment-method .panel-list .panel div.method-name,
  #default-payment-entry div.wrapper > div.method-name,
  #default-payment-entry .panel-list .panel div.method-name,
  #saved-payment-entries div.wrapper > div.method-name,
  #saved-payment-entries .panel-list .panel div.method-name {
    text-align: center;
    width: 100%;
  }
}
.main-payment-method .control,
#default-payment-entry .control,
#saved-payment-entries .control {
  position: absolute;
  right: 20px;
  bottom: 50%;
  transform: translateY(50%);
}
@media all and (max-width: 1024px) {
  .main-payment-method .control,
  #default-payment-entry .control,
  #saved-payment-entries .control {
    position: static;
    transform: translateY(0%);
    text-align: center;
    margin-top: 16px;
  }
}
@media all and (max-width: 1024px) {
  .main-payment-method .btn.btn-payment,
  #default-payment-entry .btn.btn-payment,
  #saved-payment-entries .btn.btn-payment {
    margin: 0 auto;
  }
}
.main-payment-method .panel-list,
#default-payment-entry .panel-list,
#saved-payment-entries .panel-list {
  padding-inline: 20px;
}
.main-payment-method div.panel-body,
#default-payment-entry div.panel-body,
#saved-payment-entries div.panel-body {
  margin: 0;
}
@media all and (max-width: 1024px) {
  .main-payment-method div.panel-body,
  #default-payment-entry div.panel-body,
  #saved-payment-entries div.panel-body {
    margin-bottom: 20px;
  }
}
.main-payment-method .panel,
#default-payment-entry .panel,
#saved-payment-entries .panel {
  border-top: 1px solid #d9d9d9;
  padding: 20px 8px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 1024px) {
  .main-payment-method .panel,
  #default-payment-entry .panel,
  #saved-payment-entries .panel {
    flex-direction: column-reverse;
  }
}
@media all and (max-width : 768px) {
  .main-payment-method .panel-heading,
  #default-payment-entry .panel-heading,
  #saved-payment-entries .panel-heading {
    width: 100%;
  }
}
.main-payment-method .btn.btn-inline,
#default-payment-entry .btn.btn-inline,
#saved-payment-entries .btn.btn-inline {
  min-width: 220px;
}
@media all and (max-width: 1024px) {
  .main-payment-method .btn.btn-inline,
  #default-payment-entry .btn.btn-inline,
  #saved-payment-entries .btn.btn-inline {
    margin: 0 auto;
  }
}
.main-payment-method .use-as-default + .delete-payment,
#default-payment-entry .use-as-default + .delete-payment,
#saved-payment-entries .use-as-default + .delete-payment {
  margin-top: 12px;
}

div.payment-method-list {
  padding-inline: 20px;
}
@media all and (max-width : 768px) {
  div.payment-method-list {
    padding-inline: 10px;
  }
}
div.payment-method-list div.payment-method {
  padding-top: 20px;
  padding-bottom: 20px;
}
div.payment-method-list .payment-method:not(:first-child) {
  border-top: 1px solid #d9d9d9;
}
div.payment-method-list div.payment-method div.pm-wrapper {
  border: none;
  margin: 0;
  max-width: 100%;
  padding-block: 52px;
  padding: 0;
  position: relative;
  word-break: break-all;
  width: 100%;
}
@media all and (max-width: 1024px) {
  div.payment-method-list div.payment-method div.pm-wrapper {
    padding-block: 0;
  }
}
div.payment-method-list .payment-method .btn {
  margin: 0 auto;
}
div.payment-method-list .pm-heading {
  width: 42%;
}
@media all and (max-width : 768px) {
  div.payment-method-list .pm-heading {
    text-align: center;
    width: 100%;
  }
}
div.payment-method-list div.payment-method div.pm-wrapper div.pm-label {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
@media all and (max-width: 1024px) {
  div.payment-method-list div.payment-method div.pm-wrapper div.pm-label {
    width: 100%;
  }
}
@media all and (max-width : 768px) {
  div.payment-method-list div.payment-method div.pm-wrapper div.pm-label {
    text-align: center;
  }
}
div.payment-method-list .payment-method .pm-trailing {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 26px;
}
@media all and (max-width: 1024px) {
  div.payment-method-list .payment-method .pm-trailing {
    position: static;
    transform: translateY(0%);
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}
div.payment-method-list .extra-control {
  margin-top: 20px;
}
div.payment-method-list .payment-method .pm-trailing .note {
  text-align: center;
}
div.payment-method-list .pm-use-as-default,
div.payment-method-list .checkbox {
  color: #005c69;
  font-size: 14px;
}
div.payment-method-list .pm-submit + .pm-use-as-default {
  margin-top: 16px;
}
div.payment-method-list .pm-use-as-default label,
div.payment-method-list .checkbox label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
@media (hover: hover) and (pointer: fine) {
  div.payment-method-list .pm-use-as-default label,
  div.payment-method-list .checkbox label {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  div.payment-method-list .pm-use-as-default label:hover,
  div.payment-method-list .checkbox label:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
div.payment-method-list .pm-use-as-default label::before, div.payment-method-list .pm-use-as-default label::after,
div.payment-method-list .checkbox label::before,
div.payment-method-list .checkbox label::after {
  backface-visibility: hidden;
}
div.payment-method-list .pm-use-as-default input {
  border-radius: 2px;
  margin: 0;
}
div.payment-method-list .formrow-mmyyyy .input-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}
div.payment-method-list .formrow-mmyyyy .date-delim {
  font-size: 12px;
}
div.payment-method-list .formrow-mmyyyy select {
  padding: 5px 30px 5px 10px;
  height: 40px;
  min-width: 100px;
}
div.payment-method-list .formrow-mmyyyy select.date-yyyy {
  width: 150px;
}
div.payment-method-list .payment-method .pm-body {
  width: 42%;
}
@media all and (max-width: 1024px) {
  div.payment-method-list .payment-method .pm-body {
    width: 100%;
  }
}
div.payment-method-list .payment-method .pm-body .pm-description {
  padding: 0;
}
div.payment-method-list .payment-method .pm-wrapper:has(.formrow) {
  width: 100%;
}
div.payment-method-list .payment-method .pm-wrapper:has(.formrow) .pm-body {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 10px;
  padding: 18px 24px;
  width: 100%;
}
@media all and (max-width : 768px) {
  div.payment-method-list .payment-method .pm-wrapper:has(.formrow) .pm-body {
    padding: 10px;
  }
}
div.payment-method-list .payment-method .pm-wrapper:has(.formrow) .pm-trailing {
  position: static;
  transform: translateY(0);
  margin-top: 26px;
  flex-direction: column;
}
div.payment-method-list .payment-method .pm-wrapper:has(.formrow) .pm-submit {
  text-align: right;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}

/*
  ---------------
  Distribution common
  ---------------
*/
/* ディストリビューション | Distribution */
.distribution .dstr-title {
  font-size: 24px;
  font-weight: bold;
}

.parcel {
  list-style-type: none;
}

.parcel {
  border: 1px solid #eee;
  margin-top: 20px;
  padding: 20px;
}

.parcel .summary .label {
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
}

.parcel .issues .label {
  font-weight: bold;
}

.parcel .shipping-from {
  text-decoration: underline;
}

.distribution {
  text-align: left;
  margin-top: 0;
}
.distribution > .heading {
  background-color: #f6f6f6;
  padding: 22px 40px;
}
@media all and (max-width : 768px) {
  .distribution > .heading {
    padding: 20px 12px 12px;
  }
}
.distribution .heading .main-title img {
  margin-bottom: 8px;
}
.distribution .heading .main-title .pwk-title,
.distribution .heading .main-title .dstr-title {
  color: #1d1d1b;
  display: block;
  font-weight: 500;
  line-height: 1.45833333;
}
.distribution .heading .main-title .pwk-title {
  font-size: 24px;
}
@media all and (max-width : 768px) {
  .distribution .heading .main-title .pwk-title {
    font-size: 18px;
    padding-right: 32px;
  }
}
.distribution .heading .main-title .dstr-title {
  color: #1d1d1b;
  font-size: 24px;
  padding-top: 22px;
  position: relative;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width : 768px) {
  .distribution .heading .main-title .dstr-title {
    font-size: 20px;
  }
}
.body .distribution .heading .main-title .dstr-title::before {
  background-color: #005c69;
  content: "";
  display: inline-block;
  width: 12px;
  height: 11px;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  position: absolute;
  top: 10px;
  left: 0;
  transform: translateY(-50%);
}
.body .distribution .heading .main-title .dstr-title::after {
  color: #005c69;
  content: "お申し込み内容";
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 16px;
}
.distribution .heading .dstr-description {
  font-size: 14px;
  text-align: left;
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .distribution .heading .dstr-description {
    font-size: 14px;
    margin-top: 16px;
  }
}
.distribution .dstr-options,
.distribution .subs-addon-list {
  margin-block: 16px;
  text-align: left;
}
.distribution .dstr-options .heading,
.distribution .subs-addon-list .heading {
  color: #005c69;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71428571;
  padding-left: 15px;
  position: relative;
}
.distribution .dstr-options .heading::before,
.distribution .subs-addon-list .heading::before {
  background-color: #005c69;
  content: "";
  display: inline-block;
  width: 12px;
  height: 11px;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.distribution .dstr-options > ul,
.distribution .subs-addon-list > ul {
  margin-top: 2px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.distribution .option-title,
.distribution .subs-addon-list .addon-label {
  border: 1px solid #005c69;
  border-radius: 2px;
  color: #005c69;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.41666667;
  padding: 1px 4px;
}
.distribution .distribution-schedule {
  border-top: 1px solid #d9d9d9;
  margin-top: 0;
  padding: 16px 30px;
}
@media all and (max-width : 768px) {
  .distribution .distribution-schedule {
    padding: 12px;
  }
}
.distribution .distribution-schedule > .heading {
  font-size: 20px;
  font-weight: 500;
}
.distribution .distribution-schedule > .body {
  font-size: 14px;
  padding: 0;
}
.distribution .distribution-schedule .note {
  margin: 0;
}
.distribution .distribution-schedule .parcels {
  margin-top: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.itemdetails .distribution .distribution-schedule .parcels:has(li:nth-child(4)) {
  padding-bottom: 30px;
}
@media all and (max-width : 768px) {
  .itemdetails .distribution .distribution-schedule .parcels:has(li:nth-child(4)) {
    padding-bottom: 4px;
  }
}
.distribution .distribution-schedule li.parcel {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin: 0;
  padding: 18px 0 0;
  position: relative;
}
.distribution .distribution-schedule .parcel .summary {
  padding-inline: 20px;
}
.distribution .distribution-schedule .parcel .summary > .label {
  font-weight: normal;
  padding-right: 240px;
  text-decoration: none;
  margin-bottom: 5px;
}
@media all and (max-width : 768px) {
  .distribution .distribution-schedule .parcel .summary > .label {
    padding-right: 0;
  }
}
.distribution .distribution-schedule .parcel .summary > .label > .parcel-label {
  vertical-align: middle;
}
.distribution .distribution-schedule .parcel .summary .description {
  font-weight: normal;
  font-size: 12px;
}
.distribution .distribution-schedule .parcel .parcel-extra .parcel-fee {
  color: #c10000;
}
.distribution .distribution-schedule .parcel .parcel-extra .parcel-discount {
  color: #00ac5a;
}
.distribution .distribution-schedule .parcel .parcel-extra .parcel-discount > .value::before {
  content: "-";
  display: inline;
}
.distribution .distribution-schedule .parcel .parcel-extra .label {
  font-size: 14px;
  font-weight: 400;
  margin-right: 4px;
  text-decoration: none;
}
.distribution .distribution-schedule .parcel .issues .label,
.distribution .distribution-schedule .parcel .option-parcels .heading,
.distribution .distribution-schedule .parcel .subscription-privileges .heading {
  border-bottom: 1px solid #d9d9d9;
}
.distribution .distribution-schedule .parcel ul.privileges,
.distribution .distribution-schedule .parcel ul.options,
.distribution .distribution-schedule .parcel ol.issues {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.distribution .distribution-schedule .parcel ol.issues .contents,
.distribution .distribution-schedule .parcel ol.issues .contents {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.distribution .distribution-schedule .option-parcels .heading {
  font-weight: 500;
}
.distribution .distribution-schedule .option-parcels .option .contents,
.distribution .distribution-schedule .subscription-privileges .privilege .contents {
  font-weight: normal;
  padding-left: 20px;
}
.distribution .distribution-schedule .option-parcels .option .contents *,
.distribution .distribution-schedule .subscription-privileges .privilege .contents * {
  font-size: 12px;
}
.distribution .distribution-schedule .option-parcels .option .contents .offering,
.distribution .distribution-schedule .subscription-privileges .privilege .contents .offering {
  display: none;
}
.distribution .distribution-schedule .parcel > .subscription-privileges,
.distribution .distribution-schedule .parcel > .option-parcels {
  background-color: #f6f6f6;
  padding: 12px 20px;
}
.distribution .distribution-schedule .parcel > .issues {
  background-color: #f6f6f6;
  margin-top: 18px;
  padding: 12px 20px;
}
.distribution .distribution-schedule .parcel .subscription-privileges .heading {
  font-size: 0;
}
.distribution .distribution-schedule .parcel .issues .label {
  font-size: 14px;
  font-weight: 500;
}
.distribution .distribution-schedule .parcel .issues .price {
  font-weight: normal;
  margin-left: 4px;
}
.distribution .distribution-schedule .parcel .issues .price.free {
  display: none;
}
.distribution .distribution-schedule .parcel ol.issues .issue-thumbnail {
  display: none;
}
.distribution .distribution-schedule .parcel ul.privileges .label,
.distribution .distribution-schedule .parcel ul.options .label,
.distribution .distribution-schedule .parcel ol.issues .item-name a {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
.distribution .distribution-schedule .parcel ul.options .contents .label {
  font-size: 12px;
}
.distribution .distribution-schedule .parcel .first-parcel-marker,
.distribution .distribution-schedule .parcel .next-parcel-marker {
  order: -1;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  line-height: 2;
  margin-left: 4px;
  margin-right: 0;
  padding: 0 4px;
}
.distribution .distribution-schedule .parcel .first-parcel-marker {
  background-color: #00828c;
}
.distribution .distribution-schedule .parcel .next-parcel-marker {
  background-color: #1d1d1b;
}
.distribution .distribution-schedule .parcel .next-note {
  color: #005c69;
  background-color: #f6f6f6;
  display: block;
  font-weight: normal;
  padding: 8px 0;
  text-align: center;
  width: 100%;
}
.distribution .distribution-schedule .parcel > .shipping {
  background-color: #f6f6f6;
  border-radius: 2px;
  display: none;
  overflow: hidden;
  position: absolute;
  top: 18px;
  right: 20px;
}
@media all and (max-width : 768px) {
  .distribution .distribution-schedule .parcel > .shipping {
    position: static;
    padding: 12px;
  }
}
@media all and (max-width : 768px) {
  .distribution .distribution-schedule .parcel > .shipping li {
    border: 1px solid #b2b2b2;
    border-radius: 2px;
    width: 100%;
  }
}
.distribution .distribution-schedule .parcel .date-to-ship,
.distribution .distribution-schedule .parcel .shipping-from {
  font-size: 12px;
  line-height: 1;
  padding-right: 26px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.distribution .distribution-schedule .parcel > .shipping .label {
  background-color: #747474;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  margin-right: 6px;
  padding: 4px 6px;
  vertical-align: text-bottom;
}

/*
  ---------------
  住所編集フォーム共通
  ---------------
*/
div.form {
  padding: 0;
}

.form h1 {
  font-weight: 400;
  padding: 0;
}
.form .form-note {
  font-size: 14px;
  line-height: 1.42857143;
  margin: 0;
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .form .form-note {
    margin-top: 8px;
  }
}
.form .form-body .addressbook {
  text-align: center;
  max-width: 100%;
  margin-top: 30px;
}
.form .form-body .addressbook .btn-addressbook {
  border-radius: 2px;
  border: none;
  box-shadow: none;
  color: #fff !important;
  font-size: 16px;
  width: 100%;
  height: 50px;
  margin-inline: auto;
  display: grid;
  place-items: center;
  background: #005c69;
  max-width: 300px;
}
@media (hover: hover) and (pointer: fine) {
  .form .form-body .addressbook .btn-addressbook {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .form .form-body .addressbook .btn-addressbook:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.form .form-body .addressbook .btn-addressbook::before, .form .form-body .addressbook .btn-addressbook::after {
  backface-visibility: hidden;
}
.form .addressbook .ab-list {
  border: 0;
  top: 60px;
  right: 50%;
  transform: translateX(50%);
}
.form .addressbook .ab-list > ul {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.form .form-body .input-group-address {
  background: #f6f6f6;
  border-radius: 0;
  border: none;
  padding: 40px;
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  .form .form-body .input-group-address {
    padding: 20px 15px;
  }
}
.form .form-body .formrow {
  border: none;
  display: flex;
  flex-direction: column;
}
@media all and (max-width : 768px) {
  .form .form-body .formrow {
    padding: 0;
  }
}
.form .form-body .formrow:not(:first-child) {
  margin-top: 16px;
}
.form .form-body .input-label {
  padding: 0;
  display: flex;
  align-items: center;
}
.form .form-body .input-label .input-name {
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}
.form .form-body .input-label .input-required {
  background: #c10000;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
}
.form .form-body .input-control {
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  gap: 10px;
}
.form .form-body .input-control .input-group input.required {
  background: #fff;
}
.form .form-body .input-control .input-group input {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.form .form-body .input-control .input-note {
  margin-left: 0;
  padding-left: 0;
}

/*
  ----------------
  商品検索パネル
  ----------------
*/
#deago-item-search-panel {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#select-item-search-panel {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

#deago-item-search-panel > .item-search-panel.panel,
#select-item-search-panel > .item-search-panel.panel {
  background-color: #fff;
  overflow: auto;
  padding: 0;
  height: 90vh;
  width: 90vw;
  max-width: 1000px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media all and (max-width : 768px) {
  #deago-item-search-panel > .item-search-panel.panel,
  #select-item-search-panel > .item-search-panel.panel {
    overflow: hidden;
    height: 90svh;
    width: 95vw;
  }
}

.item-search-header {
  display: flex;
}

.item-search-list {
  display: flex;
  width: calc(100% - 50px);
}

.item-search-class {
  background-color: #f6f6f6;
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  height: 80px;
  text-align: center;
  width: 33.3333333333%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .item-search-class {
    font-size: 16px;
    height: 50px;
    line-height: 1;
    padding-inline: 10px;
    text-align: left;
  }
}

.item-search-class.is-active {
  background-color: #fff;
  border-top: 4px solid #00828c;
  border-bottom: none;
  color: #00828c;
  padding-bottom: 4px;
}

.item-search-class:nth-child(2).is-active {
  border-top: 4px solid #ffbd08;
  color: #ffbd08;
}

.item-search-class:nth-child(3).is-active {
  border-top: 4px solid #00ac5a;
  color: #00ac5a;
}

.item-search-closer {
  border: 1px solid #333;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 36px;
  line-height: 42px;
  margin-left: auto;
  text-align: center;
  height: 80px;
  width: 80px;
  display: grid;
  place-items: center;
}
@media all and (max-width : 768px) {
  .item-search-closer {
    height: 50px;
    width: 50px;
  }
}

@media all and (max-width : 768px) {
  .item-search-body {
    position: relative;
  }
}

.isp__body {
  height: calc(90vh - 190px);
  overflow-y: scroll;
  padding: 36px 50px 50px;
}
@media all and (max-width : 768px) {
  .isp__body {
    height: calc(90svh - 120px);
    padding: 30px 20px;
  }
}

.isp__footer {
  background-color: #f6f6f6;
  padding: 20px;
}
@media all and (max-width : 768px) {
  .isp__footer {
    padding: 10px 8px;
    position: absolute;
    left: 0;
    right: 0;
    border: 0;
  }
}

.isp__by-series + .isp__by-input {
  padding-top: 40px;
}

.isp__action {
  display: flex;
  justify-content: center;
  gap: 20px;
}
@media all and (max-width : 768px) {
  .isp__action {
    gap: 8px;
  }
}

.isp__btn {
  border-radius: 4px;
  color: #fff;
  height: 70px;
  width: 100%;
  display: grid;
  place-items: center;
}
@media (hover: hover) and (pointer: fine) {
  .isp__btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .isp__btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.isp__btn::before, .isp__btn::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .isp__btn {
    font-size: 18px;
    height: 50px;
  }
}

.isp__clear-criteria {
  background-color: #747474;
}

.isp__submit {
  background: linear-gradient(90deg, #005c69 0%, #00ada0 100%);
  position: relative;
}

.isp__submit::before {
  background: url(/f/resources/images/common/icon_search.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  left: calc(50% - 36px);
  transform: translate(-50%, -50%);
}

.isp__searchby-heading {
  background-color: #f6f6f6;
  border-left: 4px solid #005c69;
  color: #005c69;
  font-size: 16px;
  font-weight: 700;
  padding: 13px 18px 14px;
}
@media all and (max-width : 768px) {
  .isp__searchby-heading {
    font-size: 18px;
    padding: 12px;
  }
}

.isp__searchby-body {
  padding-top: 18px;
}
@media all and (max-width : 768px) {
  .isp__searchby-body {
    padding-top: 20px;
  }
}

.isp__by-input .isp__searchby-body {
  padding: 16px 0 0;
}
@media all and (max-width : 768px) {
  .isp__by-input .isp__searchby-body {
    padding-top: 20px;
  }
}

.isp__criteria + .isp__criteria {
  padding-top: 25px;
}

.isp__link-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.isp__link-item {
  display: inline-block;
  margin-right: 8px;
}

.isp__link-item a {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  font-size: 12px;
  padding: 5px 10px 6px;
}
@media all and (max-width : 768px) {
  .isp__link-item a {
    font-size: 14px;
  }
}

.isp__linkbtn-wrap {
  padding-top: 30px;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .isp__linkbtn-wrap {
    padding-top: 20px;
  }
}

.isp__criteria-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.isp__criteria-heading-label {
  color: #005c69;
  font-size: 16px;
  font-weight: bold;
}

.isp__criteria-control-wrap {
  display: flex;
  gap: 5px;
}

.isp__criteria-control-btn {
  background-color: #f6f6f6;
  border-radius: 2px;
  color: #005c69;
  cursor: pointer;
  font-size: 12px;
  padding: 3px 8px 5px 20px;
  position: relative;
}
@media (hover: hover) and (pointer: fine) {
  .isp__criteria-control-btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .isp__criteria-control-btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.isp__criteria-control-btn::before, .isp__criteria-control-btn::after {
  backface-visibility: hidden;
}

.isp__criteria-control-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
}

.isp__criteria-btn-checkall::before {
  background: url(/f/resources/images/common/icon_check_green.svg) 50%/contain no-repeat;
  height: 7px;
  width: 9px;
}

.isp__criteria-btn-uncheckall::before {
  background: url(/f/resources/images/common/icon_cross_green.svg) 50%/contain no-repeat;
  height: 14px;
  width: 14px;
}

.isp__criteria-body {
  padding-top: 14px;
}

.isp__criteria-badge {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.isp__criteria-badge-item {
  border: 1px solid #005c69;
  border-radius: 2px;
  color: #005c69;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  padding: 5px 11px 6px;
}
@media all and (max-width : 768px) {
  .isp__criteria-badge-item {
    font-size: 14px;
  }
}

.isp__criteria-badge-item.is-hide {
  display: none;
}

.isp__criteria-badge-item.is-active {
  background-color: #005c69;
  color: #fff;
}

.isp__criteria-badge-footer {
  text-align: center;
  padding-top: 16px;
}
@media all and (max-width : 768px) {
  .isp__criteria-badge-footer {
    padding-top: 18px;
  }
}

.isp__badge-show-all {
  color: #005c69;
  cursor: pointer;
  font-size: 14px;
  position: relative;
}

.isp__badge-show-all::before {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  right: -27px;
  transform: translateY(-50%);
}

.isp__badge-show-all.is-hide::before {
  background: url(/f/resources/images/common/icon_plus_circle_green.svg) 50%/contain no-repeat;
}

.isp__badge-show-all.is-show::before {
  background: url(/f/resources/images/common/icon_minus_circle_green.svg) 50%/contain no-repeat;
}

.isp__criteria-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
@media all and (max-width : 768px) {
  .isp__criteria-filter {
    gap: 10px 20px;
  }
}

.isp__criteria-filter-item {
  margin-left: 0;
  padding: 8px 14px 8px 40px;
}

.isp__criteria-filter-checkbox {
  appearance: none;
  margin: 0;
  position: absolute;
}

.isp__criteria-filter-label {
  color: #005c69;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.isp__criteria-filter-label::before,
.isp__criteria-filter-label::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.isp__criteria-filter-label::before {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 28px;
  width: 28px;
  left: -40px;
}

.is-active .isp__criteria-filter-label::after {
  background: transparent url("/f/resources/images/common/icon_checked.svg") center/contain no-repeat;
  height: 12px;
  width: 16px;
  left: -31px;
}

.isp__criteria-body:has(.isp__criteria-keyword) {
  padding-block: 8px 0;
}

.isp__criteria-keyword input {
  border: 1px solid #005c69;
  border-radius: 2px;
  font-size: 16px;
  padding: 15px 14px;
  width: 100%;
}

.isp__criteria-badge-box {
  margin: 8px 0;
}

.isp__select .isp__criteria-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.isp__select .isp__criteria-badge-box {
  margin: 0;
}

.isp__select .isp__criteria-badge-wrap {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.isp__select .isp__criteria-badge-control {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.isp__select .isp__criteria-badge-control .isp__criteria-control-btn {
  padding-block: 11px 12px;
}

.isp__select .isp__criteria-price {
  padding-top: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.isp__select .isp__criteria-price-item {
  display: flex;
}
@media all and (max-width : 768px) {
  .isp__select .isp__criteria-price-item {
    width: calc((100% - 10px) / 2);
  }
}

@media all and (max-width : 768px) {
  .isp__select .isp__criteria-price-item:first-child {
    width: 100%;
  }
}

.isp__select .isp__criteria-price-label {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  padding-block: 9px;
  padding-inline: 38px 14px;
  position: relative;
}
@media all and (max-width : 768px) {
  .isp__select .isp__criteria-price-label {
    font-size: 14px;
    padding-inline: 30px 2px;
    width: 100%;
  }
}
.isp__select .isp__criteria-price-label::before, .isp__select .isp__criteria-price-label::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.isp__select .isp__criteria-price-label::before {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  left: 10px;
}
@media all and (max-width : 768px) {
  .isp__select .isp__criteria-price-label::before {
    left: 4px;
  }
}

.isp__select .is-active .isp__criteria-price-label {
  border: 1px solid #005c69;
}
.isp__select .is-active .isp__criteria-price-label::after {
  background-color: #005c69;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  left: 13px;
}
@media all and (max-width : 768px) {
  .isp__select .is-active .isp__criteria-price-label::after {
    left: 7px;
  }
}

.isp__select .isp__criteria-price-radio {
  appearance: none;
  margin: 0;
  position: absolute;
}

.isp__criteria-badge-title {
  font-size: 20px;
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: #005c69;
  cursor: pointer;
  padding: 12px 30px 12px 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width : 768px) {
  .isp__criteria-badge-title {
    font-size: 14px;
    padding: 14px 30px 14px 20px;
  }
}

.isp__criteria-badge-title::after {
  font-size: 24px;
  background: url(/f/resources/images/common/icon_plus_green.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.isp__criteria-badge-box.is-show .isp__criteria-badge-title::after {
  background: url(/f/resources/images/common/icon_minus_green.svg) 50%/contain no-repeat;
  height: 2px;
}

.isp__criteria-badge-control {
  margin-top: 8px;
}

.isp__criteria-price {
  display: flex;
}

.isp__criteria-badge-box.is-show .isp__criteria-badge-selected,
.isp__criteria-badge-box.is-show .isp__criteria-badge-selected-item {
  display: none;
}

.isp__criteria-badge-selected:has(li) {
  white-space: nowrap;
  overflow: scroll;
  padding-block: 16px 20px;
  display: flex;
  gap: 10px;
}

.isp__criteria-badge-selected-item {
  display: inline-block;
  background-color: #f6f6f6;
  border-radius: 2px;
  color: #747474;
  font-size: 12px;
  padding: 6px 8px;
}

/*
  ---------------
  スライダー拡大プレビュー機能
  ---------------
*/
.m-preview {
  display: none;
}
@media (min-width: 1025px) {
  .m-preview {
    background-color: #fff;
    border: 1px solid #b2b2b2;
    width: 446px;
    height: 446px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
  }
}
@media (min-width: 1025px) {
  .m-preview.active {
    display: block;
  }
}
@media (min-width: 1025px) {
  .partwork-product .m-preview {
    width: 560px;
    height: 560px;
  }
}

@media (min-width: 1025px) {
  .m-preview img {
    max-width: none;
  }
}

@media (min-width: 1025px) {
  .m-preview__lens {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}

@media (min-width: 1025px) {
  .m-preview__lens-container {
    cursor: crosshair;
    width: 100%;
    height: 100%;
    position: relative;
  }
}

@media (min-width: 1025px) {
  .m-preview__lens-cursor {
    background-color: #06f;
    display: none;
    width: 150px;
    height: 150px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

@media (min-width: 1025px) {
  .m-preview__lens-container:hover .m-preview__lens-cursor {
    display: block;
  }
}

/*
  ---------------
  汎用ニューステンプレート サンプル用
  ---------------
*/
.news-archive-deago .news-entry-icon {
  display: none;
}

.news-archive-deago .news-entry-subject {
  background-color: #333;
  color: #fff;
  padding: 8px;
  margin: 20px 0;
}

.news-archive-deago .news-entry {
  background-color: #efefef;
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px;
  width: 100%;
}

.news-archive-deago h1 {
  font-size: 32px;
  font-weight: bold;
  margin-top: 20px;
}

.news-archive-deago h2 {
  font-size: 32px;
  margin-top: 20px;
}

.news-archive-deago h3 {
  border-bottom: 2px solid #555;
  font-size: 26px;
  font-weight: bold;
  margin-top: 16px;
}

.news-archive-deago h4 {
  font-weight: bold;
}

.news-archive-deago p {
  margin-top: 12px;
}

.news-archive-deago ul,
.news-archive-deago ol {
  padding-left: 20px;
}

.news-archive-deago ul li {
  list-style-type: circle;
}

.news-archive-deago ol li {
  list-style-type: decimal;
}

.news-archive-deago a {
  color: #005c69;
  text-decoration: underline;
}

.news-archive-deago blockquote {
  border: 1px solid #555;
  padding: 40px;
}

.news-archive-deago a.button {
  border-radius: 8px;
  background-color: #005c69;
  color: #fff;
  cursor: pointer;
  display: block;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  margin: 20px auto 0;
  max-width: 200px;
  width: 100%;
}

.news-archive-deago table {
  width: 100%;
}

.news-archive-deago table td {
  border: 1px solid #555;
  border-collapse: collapse;
}

/* みんなの投稿 */
.newsentries.user-post .news-entries {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1220px;
  width: 100%;
}

.newsentries.user-post .news-entry {
  border-top: none;
  padding: 0;
  text-align: center;
  transition: 0.2s ease-in-out;
  margin-left: 20px;
  margin-top: 20px;
  width: calc((100% - 60px) / 4);
}

.newsentries.user-post .news-entry:nth-child(4n+1) {
  margin-left: 0;
}

.newsentries.user-post .news-entry:hover {
  opacity: 0.6;
}

.newsentries.user-post .entry-text {
  display: none;
}

.newsentries.user-post .entry-icon {
  margin: 0 auto;
}

.newsentries.user-post .entry-icon span.icon img {
  width: 100%;
}

#news .news-archive-user-post #page-wrapper {
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
}

#news .news-archive-user-post .news-entry {
  min-height: 400px;
  margin-top: 40px;
  padding-left: 40%;
  position: relative;
}

#news .news-archive-user-post .news-entry-icon {
  position: absolute;
  top: 0;
  left: 0;
}

#news .news-archive-user-post .news-entry-header {
  width: 100%;
}

#news .news-archive-user-post .news-entry-body {
  margin-top: 20px;
}

#news .news-archive-user-post .post-content {
  border: 1px solid #333;
  padding: 10px;
}

#news .news-archive-user-post .post-info {
  margin-top: 20px;
}

#news .news-archive-user-post .autor-link,
#news .news-archive-user-post .post-link {
  text-decoration: underline;
}

/*--------------------------------------------------
ニュース一覧
--------------------------------------------------*/
.newsentries:not(.faq):not(.archive) {
  background-color: #f6f6f6;
}
.newsentries:not(.faq):not(.archive) .l-footer {
  margin-top: 200px;
}
@media all and (max-width : 768px) {
  .newsentries:not(.faq):not(.archive) .l-footer {
    margin-top: 100px;
  }
}
.newsentries:not(.faq):not(.archive) .l-footer__main {
  border-top: 1px solid #d9d9d9;
}

.newsentries:not(.faq):not(.archive) .news-archive #page-title .breadcrumbs {
  margin-bottom: 0;
}
.newsentries:not(.faq):not(.archive) .news-archive #page-title {
  background-color: #fff;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
}
.newsentries:not(.faq):not(.archive) .news-archive h1 {
  font-size: 24px;
  font-weight: 400;
  padding: 26px 0;
}
@media all and (min-width: 769px) {
  .newsentries:not(.faq):not(.archive) .news-archive h1 {
    margin: 0 auto;
    max-width: 1200px;
  }
}
@media all and (max-width : 768px) {
  .newsentries:not(.faq):not(.archive) .news-archive h1 {
    padding-block: 22px;
  }
}
.newsentries:not(.faq):not(.archive) .news-archive #page-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .newsentries:not(.faq):not(.archive) .news-archive #page-wrapper {
    padding-inline: 12px;
  }
}
.newsentries:not(.faq):not(.archive) .news-archive .show-othernews {
  display: none;
}
.newsentries:not(.faq):not(.archive) .news-archive .news-header {
  padding-top: 40px;
  display: grid;
  place-items: center;
}
.newsentries:not(.faq):not(.archive) .news-archive .news-icon {
  max-width: 200px;
}
@media all and (max-width : 768px) {
  .newsentries:not(.faq):not(.archive) .news-archive .news-icon {
    max-width: 150px;
  }
}
.newsentries:not(.faq):not(.archive) .news-archive .news-entries {
  background-color: #fff;
  margin-block: 30px 50px;
}
@media all and (max-width : 768px) {
  .newsentries:not(.faq):not(.archive) .news-archive .news-entries {
    margin-block: 12px 30px;
  }
}
.newsentries:not(.faq):not(.archive) .news-archive .news-entry {
  border: none;
  padding: 20px 30px;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 20px;
}
@media (hover: hover) and (pointer: fine) {
  .newsentries:not(.faq):not(.archive) .news-archive .news-entry {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .newsentries:not(.faq):not(.archive) .news-archive .news-entry:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.newsentries:not(.faq):not(.archive) .news-archive .news-entry::before, .newsentries:not(.faq):not(.archive) .news-archive .news-entry::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .newsentries:not(.faq):not(.archive) .news-archive .news-entry {
    padding: 12px 16px;
  }
}
.newsentries:not(.faq):not(.archive) .news-archive .news-entry:not(:has(.entry-icon)) .entry-text {
  width: 100%;
}
.newsentries:not(.faq):not(.archive) .news-archive .news-entry:not(:first-child) {
  border-top: 1px solid #d9d9d9;
}
.newsentries:not(.faq):not(.archive) .news-archive .entry-text {
  margin-inline: 0 auto;
  padding: 0;
  width: calc(100% - 110px);
  word-break: break-all;
}
.newsentries:not(.faq):not(.archive) .news-archive .header {
  display: flex;
  flex-direction: column;
}
.newsentries:not(.faq):not(.archive) .news-archive .meta {
  display: flex;
  align-items: center;
  gap: 8px;
}
.newsentries:not(.faq):not(.archive) .news-archive .release-date {
  margin: 0;
}
.newsentries:not(.faq):not(.archive) .news-archive .release-date span {
  color: #747474;
  font-size: 15px;
  font-weight: 500;
}
.newsentries:not(.faq):not(.archive) .news-archive .news-type span {
  background-color: #494949;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 4px 8px 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsentries:not(.faq):not(.archive) .news-archive .headline a:hover {
  opacity: 1;
}
.newsentries:not(.faq):not(.archive) .news-archive .headline a::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
}
.newsentries:not(.faq):not(.archive) .news-archive .headline .text {
  color: #005c69;
  font-size: 16px;
  font-weight: 700;
}
.newsentries:not(.faq):not(.archive) .news-archive .body {
  font-size: 12px;
  padding: 10px 0 0;
  line-height: 1.7;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media all and (max-width : 768px) {
  .newsentries:not(.faq):not(.archive) .news-archive .body {
    font-size: 14px;
  }
}
.newsentries:not(.faq):not(.archive) .news-archive .news-entry .entry-icon .icon {
  display: inline-block;
}
.newsentries:not(.faq):not(.archive) .news-archive .news-entry .entry-icon:has(.no-image) {
  display: none;
}
.newsentries:not(.faq):not(.archive) .news-archive .news-entry .entry-icon:has(.no-image) + .entry-text {
  width: 100%;
}
.newsentries:not(.faq):not(.archive) .news-archive div.news-entry div.entry-icon span.icon img,
.newsentries:not(.faq):not(.archive) .news-archive .news-entry .entry-icon span.no-image {
  object-fit: contain;
  height: 142px;
  width: 142px;
  min-width: 142px;
}
.newsentries:not(.faq):not(.archive) .news-archive .pagination a {
  background-color: #fff;
}
.newsentries:not(.faq):not(.archive) .news-archive .pagination li.active a {
  background-color: transparent;
}

.newsentries:not(.faq).announce .news-archive-announce .news-entry .body {
  color: #333;
}

/*--------------------------------------------------
ニュース詳細
--------------------------------------------------*/
#news:not(:has(.news-archive-faq)):not(:has(.news-archive-archive)) .l-footer {
  margin-top: 0;
}
#news:not(:has(.news-archive-faq)):not(:has(.news-archive-archive)) .l-footer__main {
  border-top: 1px solid #d9d9d9;
}

#news .news:not(.news-archive-faq):not(.news-archive-archive) {
  background-color: #f6f6f6;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) #page-title .breadcrumbs {
  margin-bottom: 0;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) #page-title {
  background-color: #fff;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) h1 {
  font-size: 24px;
  font-weight: 400;
  padding: 26px 0;
}
@media all and (min-width: 769px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) h1 {
    margin: 0 auto;
    max-width: 1200px;
  }
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) h1 {
    padding-block: 20px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) #page-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  max-width: 860px;
  padding: 30px 30px 100px;
}
@media all and (max-width: 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) #page-wrapper {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) #page-wrapper {
    padding-top: 12px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry {
  background-color: #fff;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-subject {
  color: #005c69;
  font-size: 20px;
  font-weight: 700;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-subject {
    font-size: 18px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .release-date {
  color: #747474;
  font-size: 15px;
  font-weight: 500;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-header {
  border-top: 2px solid #005c69;
  border-bottom: 1px solid #d9d9d9;
  padding: 28px 30px;
  display: flex;
  flex-direction: column-reverse;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-header {
    padding: 15px 12px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-icon {
  display: none;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body {
  padding: 24px 30px 140px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body {
    padding: 15px 12px 20px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .trailer-link {
  padding: 50px 0 0;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .trailer-link {
    padding-top: 30px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .trailer-link a {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 16px;
  padding-inline: 48px 15px;
  position: relative;
  height: 50px;
  min-width: 238px;
  display: grid;
  place-items: center;
}
@media (hover: hover) and (pointer: fine) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .trailer-link a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .trailer-link a:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .trailer-link a::before, #news .news:not(.news-archive-faq):not(.news-archive-archive) .trailer-link a::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .trailer-link a {
    height: auto;
    text-align: left;
    min-height: 50px;
    max-width: 260px;
    width: 100%;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .trailer-link a::before {
  background: url(/f/resources/images/common/icon_arrow_right_bg_green.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .show-othernews {
  font-size: 12px;
  padding-left: 8px;
  margin-bottom: 8px;
  position: relative;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .show-othernews::before {
  content: "";
  background: transparent url(/f/resources/images/common/icon_chevron_left.svg) center/cover no-repeat;
  display: inline-block;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body * + * {
  margin-top: 10px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h1,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h2,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h3,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h4,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h5 {
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h1,
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h2,
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h3,
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h4,
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h5 {
    margin-top: 20px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h1 + *,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h2 + *,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h3 + *,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h4 + *,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h5 + * {
  margin-top: 10px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h1 {
  background-color: #005c69;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 8px 12px;
  width: 100%;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h1 {
    font-size: 18px;
    padding: 8px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h2 {
  background-color: #66aca5;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 3px 12px 8px;
  position: relative;
  width: 100%;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h2 {
    font-size: 18px;
    padding: 3px 8px 8px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h2::before {
  background: #fff;
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 3px;
  transform: translateY(-50%);
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h3 {
  background-color: #c8e3e0;
  border-left: 2px solid #005c69;
  font-size: 18px;
  font-weight: 700;
  padding: 4px 12px;
  width: 100%;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h3 {
    font-size: 18px;
    padding: 4px 8px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h4 {
  background-color: #d9d9d9;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 700;
  padding: 3px 9px 4px;
  width: 100%;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h4 {
    font-size: 16px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body h5 {
  color: #005c69;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .table-wrap {
    overflow-x: scroll;
    width: 100%;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body img {
  margin-inline: auto;
  max-width: 500px;
  width: 100%;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body p {
  font-size: 16px;
  margin-top: 20px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body P + P {
  margin-top: 8px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body small {
  color: #747474;
  font-size: 14px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body big {
  font-size: 20px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body a {
  color: #00828c;
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body a:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body a::before, #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body a::after {
  backface-visibility: hidden;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body * + a {
  margin-top: 10px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body div.wl {
  width: 100%;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  position: relative;
  min-height: 50px;
  min-width: 220px;
  width: fit-content;
  display: grid;
  place-items: center;
}
@media (hover: hover) and (pointer: fine) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button::before, #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button {
    min-height: 40px;
    min-width: 210px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button::before {
  background: url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button::before {
    right: 11px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-green,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-orage,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-red,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-gray {
  background-color: #00828c;
  box-shadow: 0 3px 0 #005c69;
  border: none;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-green::before,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-orage::before,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-red::before,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-gray::before {
  display: none;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-green {
  background-color: #00828c;
  box-shadow: 0 3px 0 #005c69;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-orage {
  background-color: #ff9c08;
  box-shadow: 0 3px 0 #ff7008;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-red {
  background-color: #c10000;
  box-shadow: 0 3px 0 #770d0d;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-arrow-green,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-arrow-orage,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-arrow-red,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-arrow-gray {
  padding: 8px 44px 8px 10px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-arrow-green {
  background-color: #00828c;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-arrow-orage {
  background-color: #ff9c08;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-arrow-red {
  background-color: #c10000;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-rounded-green,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-rounded-orage,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-rounded-red,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-rounded-gray {
  border-radius: 50px;
  padding: 8px 44px 8px 10px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-rounded-green {
  background-color: #00828c;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-rounded-orage {
  background-color: #ff9c08;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-rounded-red {
  background-color: #c10000;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-gray {
  box-shadow: 0 3px 0 #d9d9d9;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-gray,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-arrow-gray,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-rounded-gray {
  color: #005c69;
  background-color: #f6f6f6;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-normal-gray::before,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-arrow-gray::before,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .button.btn-rounded-gray::before {
  background: url(/f/resources/images/common/icon_arrow_right_circle_green.svg) 50%/contain no-repeat;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body a.button {
  text-decoration: none;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body .table-container {
    overflow: auto;
    width: 100%;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body table {
  border-collapse: collapse;
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body table {
    margin-top: 20px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body th,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body td {
  border: 1px solid #c4c4c4;
  padding: 12px 14px;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body th,
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body td {
    white-space: nowrap;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body th {
  background-color: #f6f6f6;
  font-weight: 700;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body td {
  font-weight: 500;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body ul,
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body ol {
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body ul,
  #news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body ol {
    margin-top: 20px;
  }
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body li {
  font-size: 16px;
  font-weight: 700;
  position: relative;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body li::before {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 0px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body ul li {
  padding-left: 16px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body ul li::before {
  background-color: #1d1d1b;
  border-radius: 50px;
  content: "";
  height: 12px;
  width: 12px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body ol li {
  counter-increment: item;
  position: relative;
  padding-left: 18px;
}
#news .news:not(.news-archive-faq):not(.news-archive-archive) .news-entry-body ol li::before {
  content: counter(item) ".";
  color: #1d1d1b;
  font-size: 16px;
  top: 0;
}

#news .top-newslist-related-news {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-bottom: 100px;
  max-width: 860px;
}
@media all and (max-width: 768px) {
  #news .top-newslist-related-news {
    padding-inline: 12px;
  }
}
#news .top-newslist-related-news .panel-news {
  padding-inline: 0;
}
#news .top-newslist-related-news .news-name {
  font-size: 30px;
  font-weight: 500;
}
#news .top-newslist-related-news .panel-body {
  margin-top: 30px;
}
#news .top-newslist-related-news .news-entries .news-entry {
  border-top: none;
  border-bottom: 1px solid #d9d9d9;
  padding: 20px 0;
}
#news .top-newslist-related-news .news-entries .news-entry:first-child {
  border-top: 1px solid #d9d9d9;
}
#news .top-newslist-related-news .entry-text {
  padding-inline: 0;
}
#news .top-newslist-related-news .header {
  display: flex;
  gap: 40px;
}
@media all and (max-width : 768px) {
  #news .top-newslist-related-news .header {
    flex-direction: column;
    gap: 4px;
  }
}
#news .top-newslist-related-news .news-type {
  display: flex;
  align-items: center;
}
#news .top-newslist-related-news .news-type span {
  font-size: 12px;
  font-weight: 500;
  margin-right: 0;
  padding: 0 8px;
}

/*
  ---------------
  埋め込み文書 | includeDocument(SignUpAgreement.html, JustBeforeCheckOut.html)
  ---------------
*/
#includeDocument {
  padding: 40px 45px;
}
@media all and (max-width : 768px) {
  #includeDocument {
    padding-inline: 15px;
  }
}

#checkout-control #includeDocument {
  padding: 40px 0;
}

.acc-signup + #includeDocument {
  padding: 40px 0;
}
@media all and (max-width : 768px) {
  .acc-signup + #includeDocument {
    padding-inline: 0;
  }
}

#includeDocument {
  padding-block: 40px 30px;
}

#includeDocument .includeDocument__outer {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  height: 120px;
  overflow-y: scroll;
  padding: 0 20px;
  resize: vertical;
}

#includeDocument .includeDocument__inner h1 {
  font-size: 24px;
  font-weight: bold;
  padding: 20px 8px;
}

#includeDocument h2 {
  border-bottom: 1px solid #555;
  font-size: 20px;
  margin: 32px 0 16px;
  padding: 8px 0;
}

#includeDocument h3 {
  font-weight: bold;
}

#includeDocument p {
  margin-top: 8px;
}

#includeDocument ul,
#includeDocument ol {
  padding-left: 24px;
}

#includeDocument li {
  list-style-type: decimal;
  margin-top: 8px;
}

#includeDocument a {
  color: #396dcf;
  text-decoration: underline;
}

/*
  ---------------
  フリーページ 汎用ベースデザイン | セレクトショップ 特集から探す
  ---------------
*/
#freepage-base-01 {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 20px;
  width: 100%;
}

#freepage-base-01 h1 {
  font-size: 30px;
  margin-top: 40px;
  padding: 12px;
}

#freepage-base-01 h2 {
  border-bottom: 1px solid #333;
  font-size: 20px;
  margin: 40px 0 10px;
  padding: 12px 0;
}

#freepage-base-01 h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  padding: 12px 0;
}

#freepage-base-01 p {
  font-size: 16px;
  padding: 12px 0;
}

#freepage-base-01 .flex-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

#freepage-base-01 .flex-2 > * {
  width: 50%;
}

#freepage-base-01 .content {
  padding: 20px 40px;
}

#freepage-base-01 a.button {
  display: inline-block;
  background-color: #00ac5a;
  border-radius: 4px;
  color: #fff;
  padding: 10px 20px;
  margin-top: 20px;
}

/*
  ---------------
  画像拡大表示モーダル
  ---------------
*/
#image-modal {
  background-color: rgba(29, 29, 27, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}
#image-modal .image-modal__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 900px;
  height: 90vh;
  width: 90vw;
}
@media all and (max-width : 768px) {
  #image-modal .image-modal__inner {
    height: 70vh;
    width: 95vw;
  }
}
#image-modal .image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  border: 1px solid #005c69;
  border-radius: 2px;
  max-height: 900px;
  max-width: 1200px;
  padding: 60px;
  height: 100%;
  width: 100%;
}
@media all and (max-width : 768px) {
  #image-modal .image-wrapper {
    padding: 10px;
  }
}
#image-modal .image-modal__closer {
  cursor: pointer;
  height: 60px;
  width: 60px;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
}
#image-modal .image-modal__closer::before,
#image-modal .image-modal__closer::after {
  content: "";
  display: block;
  background-color: #1d1d1b;
  height: 50px;
  width: 2px;
  position: absolute;
  top: 5px;
  right: 30px;
}
#image-modal .image-modal__closer::before {
  transform: rotate(45deg);
}
#image-modal .image-modal__closer::after {
  transform: rotate(-45deg);
}
#image-modal .image-wrapper img {
  height: 100%;
  object-fit: contain;
}

/*
  ---------------
  商品一覧
  ---------------
*/
@media (min-width: 1120px) {
  #itemlist #container .item-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each {
    align-items: flex-start;
    gap: min(1.38888889vw, 10px);
  }
}
@media all and (max-width : 768px) {
  #itemlist #container .item-list .item-list-each {
    padding-bottom: 20px;
  }
}
@media all and (max-width : 768px) {
  #itemlist #container .item-list .item-list-each:has(.add-item-to-cart) {
    padding-bottom: 80px;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-thumbnail-img-wrapper {
    flex: 0 4 43.220339%;
    width: 100%;
    max-width: 255px;
    max-height: 255px;
    margin: 0;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-thumbnail-img-wrapper > .item-thumbnail-img {
    aspect-ratio: 1/1;
    width: auto;
    height: auto;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-thumbnail-img-wrapper > div > a {
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-thumbnail-img-wrapper > .item-thumbnail-img > a > img,
  #itemlist #container .item-list .item-thumbnail-img-wrapper > .item-thumbnail-img > a > div {
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
  }
}
@media all and (min-width: 769px) {
  #itemlist #container .item-list .item-list-each .item-dtl {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each .item-dtl {
    flex: 1;
    height: 100%;
    min-height: 255px;
  }
}
@media all and (max-width : 768px) {
  #itemlist #container .item-list .item-list-each .item-dtl .item-tags {
    vertical-align: top;
  }
}
#itemlist #container .item-list .item-list-each .item-dtl .item-tag {
  margin: 0;
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each .item-dtl .item-tag {
    padding: 0;
  }
}
@media all and (min-width: 769px) {
  #itemlist #container .item-list .item-list-each .item-dtl .articles,
  #itemlist #container .item-list .item-list-each .item-dtl .article,
  #itemlist #container .item-list .item-list-each .item-dtl div.purchase,
  #itemlist #container .item-list .item-list-each .item-dtl div.item-purchase {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
@media all and (min-width: 769px) {
  #itemlist #container .item-list .item-list-each .item-dtl div.ac {
    height: 100%;
  }
}
#itemlist #container .item-list .item-list-each .item-dtl span.item-name {
  font-size: 18px;
  font-weight: 600;
}
@media all and (max-width : 768px) {
  #itemlist #container .item-list .item-list-each .item-dtl span.item-name {
    font-size: 16px;
  }
}
#itemlist #container .item-list .item-list-each .item-dtl .item-code {
  font-size: 11px;
}
#itemlist #container .item-list .item-list-each .item-dtl .text-contents.article-summary {
  font-size: 12px;
  padding-bottom: 4px;
  display: -webkit-box;
  margin-top: 5px;
}
#itemlist #container .item-list .item-list-each .item-dtl .offering-price-info .offering-prices {
  text-align: left;
}
#itemlist #container .item-list .item-list-each .item-dtl .offering-price-info .regular-price.overridden {
  display: flex;
  text-align: left;
  width: 100%;
}
#itemlist #container .item-list .item-list-each .item-dtl .offering-price-info .regular-price.overridden .price-label {
  display: block;
  padding-right: 0;
}
#itemlist #container .item-list .item-list-each .item-dtl .offering-price-info .final-price {
  display: inline-block;
  margin-right: 4px;
}
#itemlist #container .item-list .item-list-each .item-dtl .offering-price-info .final-price .price-label {
  display: none;
}
#itemlist #container .item-list .item-list-each .item-dtl .offering-price-info .discount-price {
  color: #090;
  display: inline-block;
  font-size: 12px;
}
#itemlist #container .item-list .item-list-each .item-dtl .offering-price-info .individual-delivery-fee {
  display: block;
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each .item-dtl .stock-message {
    flex-grow: 1;
  }
}
#itemlist #container .item-list .item-list-each .item-dtl .stock-message > div {
  font-size: 11px;
  margin: 0;
}
#itemlist #container .item-list .item-list-each .item-dtl .stock-message .stock-msg-dtl {
  font-size: 12px;
}
#itemlist #container .item-list .item-list-each .item-purchase .final-price .price {
  font-size: 20px;
  font-weight: 500;
  white-space: wrap;
}
@media all and (max-width : 768px) {
  #itemlist #container .item-list .item-list-each .item-purchase .final-price .price {
    font-size: 16px;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each form.item-purchase {
    margin-top: 0;
  }
}
@media all and (min-width: 769px) {
  #itemlist #container .item-list .item-list-each form.item-purchase {
    position: static;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each form.item-purchase div.item-qty {
    margin-top: 2px;
    justify-content: flex-end;
    width: 100%;
  }
}
@media (max-width: 914px) and (min-width: 768px) {
  #itemlist #container .item-list .item-list-each form.item-purchase div.item-qty {
    margin-bottom: 0;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each form.item-purchase .btn-group {
    margin-top: 10px;
    margin-left: 0;
    display: flex;
  }
}
@media (max-width: 1119px) {
  #itemlist #container .item-list .item-list-each form.item-purchase div.item-qty,
  #itemlist #container .item-list .item-list-each form.item-purchase .btn-group {
    margin-top: 2px;
  }
}
@media all and (max-width : 768px) {
  #itemlist #container .item-list .item-list-each form.item-purchase div.item-qty,
  #itemlist #container .item-list .item-list-each form.item-purchase .btn-group {
    margin-top: 6px;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each form.item-purchase .btn-group .btn {
    font-size: 13px;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each form.item-purchase .btn-group .add-item-to-cart {
    padding-inline: 30px 10px;
  }
}
@media all and (max-width : 768px) {
  #itemlist #container .item-list .item-list-each form.item-purchase .btn-group .add-item-to-cart {
    width: 100%;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each form.item-purchase .btn-group .checkout-item {
    padding-inline: 10px 30px;
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each form.item-purchase .btn-group .add-item-to-cart::before {
    left: 104px;
    left: calc(50% - 58px);
  }
}
@media all and (max-width : 768px) {
  #itemlist #container .item-list .item-list-each form.item-purchase .btn-group .add-item-to-cart::before {
    left: calc(50% - 67px);
  }
}
@media (min-width: 1120px) {
  #itemlist #container .item-list .item-list-each form.item-purchase .btn-group .checkout-item::before {
    right: 14px;
  }
}

/*
  ---------------
  商品一覧・詳細 | アイテムオプション
  ---------------
*/
.partwork-product .p-product .p-product__detail-inner .item-options,
.select-product .p-product .p-product__detail-purchase .item-options {
  margin-top: 16px;
}
.partwork-product .p-product .p-product__detail-inner .item-option + .item-option,
.select-product .p-product .p-product__detail-purchase .item-option + .item-option {
  margin-top: 10px;
}
.partwork-product .p-product .p-product__detail-inner .item-option-name,
.select-product .p-product .p-product__detail-purchase .item-option-name {
  font-size: 16px;
  font-weight: 500;
}
.partwork-product .p-product .p-product__detail-inner .item-option-ctrl,
.select-product .p-product .p-product__detail-purchase .item-option-ctrl {
  margin-top: 6px;
  overflow-x: auto;
  padding-bottom: 10px;
}
.partwork-product .p-product .p-product__detail-inner .item-option-checks,
.select-product .p-product .p-product__detail-purchase .item-option-checks {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail-inner .item-option-checks,
  .select-product .p-product .p-product__detail-purchase .item-option-checks {
    flex-direction: column;
  }
}
.partwork-product .p-product .p-product__detail-inner .item-option-checks label,
.select-product .p-product .p-product__detail-purchase .item-option-checks label {
  cursor: pointer;
}
.partwork-product .p-product .p-product__detail-inner .item-option-fee,
.select-product .p-product .p-product__detail-purchase .item-option-fee {
  font-weight: 700;
}
.partwork-product .p-product .p-product__detail-inner .item-option-dsc,
.select-product .p-product .p-product__detail-purchase .item-option-dsc {
  display: none;
}

.flowpack.select-product .p-product .p-product__detail-purchase .item-option-checks label.is-checked {
  border: 1px solid #00ada0;
}
.flowpack.select-product .p-product .p-product__detail-purchase .item-option-checks label.is-checked::after {
  background-color: #00ada0;
}

.partwork-product .p-product .p-product__detail-inner .item-option-checks label.is-checked {
  border: 1px solid #00828c;
}
.partwork-product .p-product .p-product__detail-inner .item-option-checks label.is-checked::after {
  background-color: #00828c;
}

#itemlist .item-options {
  padding-block: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media all and (max-width : 768px) {
  #itemlist .item-options {
    padding-block: 4px;
  }
}
#itemlist .item-purchase .item-options .item-option {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
#itemlist .item-option-name {
  order: 1;
}
#itemlist .item-option-dsc {
  order: 2;
}
#itemlist .item-option-ctrl {
  order: 3;
}
#itemlist .item-option-input {
  display: none;
}
#itemlist .item-option-checks {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
#itemlist .item-option-checks label {
  font-size: 14px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 4px;
}
@media (hover: hover) and (pointer: fine) {
  #itemlist .item-option-checks label {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #itemlist .item-option-checks label:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#itemlist .item-option-checks label::before, #itemlist .item-option-checks label::after {
  backface-visibility: hidden;
}
#itemlist .item-option-checks input {
  margin: 0;
  height: 16px;
  width: 16px;
}

/*
  ----------------
  signin | 登録済みアカウントにログイン
  ----------------
*/
#wrapper .form.signin-form {
  margin-top: 80px;
}
@media all and (max-width : 768px) {
  #wrapper .form.signin-form {
    margin-top: 60px;
  }
}

#wrapper .form.signin-form .form-body.form-signin form {
  background-color: #f6f6f6;
  padding: 20px 0;
  margin-top: 20px;
}
@media all and (min-width: 769px) {
  #wrapper .form.signin-form .form-body.form-signin form {
    padding: 25px 0 58px;
  }
}

body:not(#signin):not(#signup) #wrapper .form.signin-form .form-body.form-signin form {
  padding-inline: 40px;
}
@media all and (max-width : 768px) {
  body:not(#signin):not(#signup) #wrapper .form.signin-form .form-body.form-signin form {
    padding-inline: 0;
  }
}

#wrapper .form.signin-form .form-body.form-signin form > div {
  display: block;
  margin-top: 15px;
  padding-inline: 15px;
}
@media all and (min-width: 769px) {
  #wrapper .form.signin-form .form-body.form-signin form > div {
    padding-inline: 45px;
  }
}

#wrapper .form.signin-form .form-body.form-signin form .input-label {
  padding: 0;
  display: flex;
  align-items: center;
}

#wrapper .form.signin-form .form-body.form-signin form .input-label > .input-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  margin-inline-end: 5px;
}

#wrapper .form.signin-form .form-body.form-signin form .input-control {
  padding: 0;
  display: flex;
  align-items: start;
  flex-direction: column-reverse;
}

#wrapper .form.signin-form .form-body.form-signin form .input-control > .input-group {
  flex: auto;
  margin-top: 10px;
}

#wrapper .form.signin-form .form-body.form-signin form .input-control > .input-group > input {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

#wrapper .form.signin-form .form-body.form-signin form > .btn-submit {
  margin-top: 60px;
  padding: 0;
}

#wrapper .form.signin-form .reminder {
  text-align: center;
  padding: 40px 0;
}

#wrapper .form.signin-form .reminder a {
  text-decoration: underline;
}

.form-auth.form-signin #includeDocument {
  padding-inline: 45px;
}
@media all and (max-width : 768px) {
  .form-auth.form-signin #includeDocument {
    padding: 20px 15px;
  }
}
.form-auth.form-signin .btn-submit-signup {
  background: #005c69;
  margin-top: 24px;
}
.form-auth.form-signin div.formrow div.input-note {
  margin-left: 0;
  padding-left: 0;
}
.form-auth.form-signin div.form.signin-form > div.reminder {
  font-size: 16px;
  padding-top: 40px;
  text-align: center;
}

/*
  ---------------
  Button common
  ---------------
*/
.c-btn {
  border-radius: 2px;
  display: flex;
  align-items: center;
}
.c-btn--simple {
  padding: 0 10px;
  justify-content: center;
}
.c-btn--bd-green-sm {
  border: 1px solid #005c69;
  min-height: 34px;
}
.c-btn--bd-green-md {
  border: 1px solid #005c69;
  min-height: 40px;
}
.c-btn--bd-green-lg {
  border: 1px solid #005c69;
  min-height: 50px;
}
.c-btn--more-top-01 {
  background-color: #f6f6f6;
  min-height: 50px;
  padding: 0 12px 0 22px;
}
.c-btn--more-top-02 {
  background-color: transparent;
  border: 1px solid #fff;
  min-height: 50px;
  padding: 0 12px 0 22px;
}
.c-btn--arrow-green-square {
  background-color: #f6f6f6;
  min-height: 50px;
  padding: 0 12px 0 22px;
}
.c-btn--square-rg {
  background-color: #66aca5;
  border-radius: 2px;
  color: #005c69;
  min-height: 40px;
  padding: 0 10px;
  justify-content: center;
}
@media all and (max-width: 1024px) {
  .c-btn--square-rg {
    padding: 0 3px;
  }
}
.c-btn--square-dg {
  background-color: #005c69;
  border-radius: 2px;
  min-height: 40px;
  padding: 0 10px;
  justify-content: center;
}
@media all and (max-width: 1024px) {
  .c-btn--square-dg {
    padding: 0 3px;
  }
}

/*
  ---------------
  icon common
  ---------------
*/
.c-icon {
  display: block;
  text-align: center;
  width: 100%;
  position: relative;
}
.c-icon--inline {
  display: inline-block;
  position: relative;
}
.c-icon--arrow-green-square {
  color: #005c69;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33333333;
  padding-right: 36px;
}
.c-icon--arrow-green-square::after {
  content: "";
  border-radius: 2px;
  background: #005c69 url("/f/resources/images/top/icon_arrow_right_bg_green.svg") center/contain no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.c-icon--arrow-white-square {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33333333;
  padding-right: 36px;
}
.c-icon--arrow-white-square::after {
  content: "";
  border-radius: 2px;
  background: #fff url("/f/resources/images/top/icon_arrow_right_bg_white.svg") center/contain no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.c-icon--series-back-number {
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 1;
}
@media all and (min-width: 769px) {
  .c-icon--series-back-number {
    padding-left: 14px;
  }
}
@media all and (max-width : 768px) {
  .c-icon--series-back-number {
    font-size: 13px;
    letter-spacing: -0.18em;
    line-height: 1.46153846;
    padding: 39px 0 2px;
  }
}
.c-icon--series-back-number::before {
  content: "";
  background: transparent url("/f/resources/images/partwork/icon_backnumber.svg") center/contain no-repeat;
  display: inline-block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .c-icon--series-back-number::before {
    width: 25px;
    height: 25px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.c-icon--series-subscription {
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 1;
}
@media all and (min-width: 769px) {
  .c-icon--series-subscription {
    padding-left: 16px;
  }
}
@media all and (max-width : 768px) {
  .c-icon--series-subscription {
    font-size: 13px;
    line-height: 1.46153846;
    padding: 39px 0 2px;
  }
}
.c-icon--series-subscription::before {
  content: "";
  background: transparent url("/f/resources/images/partwork/icon_subscription.svg") center/contain no-repeat;
  display: inline-block;
  width: 12px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .c-icon--series-subscription::before {
    width: 22px;
    height: 25px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

/*
  ---------------
  Text common

  ▼ 基本的なテキスト
  .c-text

  ▼ パートワーク テキスト(ジャンル)
  .c-text--genre

  ▼ h2タイトル
  .c-text--ttl-h2

  ▼ h3タイトル
  .c-text--ttl-h3

  ---------------
*/
.c-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  /*
    ---------------
    title text
    ---------------
  */
}
.c-text--ttl-h2 {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.44;
  padding: 0;
}
@media all and (max-width : 768px) {
  .c-text--ttl-h2 {
    text-align: center;
  }
}
@media all and (min-width: 769px) {
  .c-text--ttl-h2 {
    font-size: 30px;
    line-height: 1.43333333;
  }
}
.c-text--ttl-h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
  padding: 0;
}
@media all and (min-width: 769px) {
  .c-text--ttl-h3 {
    font-size: 24px;
    line-height: 1.45833333;
  }
}

/*
  ---------------
  Tag common
  ---------------
*/
div.item-tags div.item-tag {
  background-color: transparent;
  padding: 0;
}
div.item-tags div.item-tag > p {
  background-color: #c10000;
  color: #fff;
  padding: 0 2px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
@media all and (max-width : 768px) {
  div.item-tags div.item-tag > p {
    font-size: 15px;
    line-height: 15px;
    min-width: auto;
    padding: 2px 6px 4px;
  }
}

.site-banner {
  position: relative;
}
.site-banner .item-tags {
  position: absolute;
  top: 1.0178117vw;
  right: 0;
  z-index: 1;
}
@media all and (min-width: 769px) {
  .site-banner .item-tags {
    top: 10px;
    right: -3px;
  }
}
.site-banner div.item-tags div.item-tag > p {
  border-radius: 12px;
  font-size: 14px;
  padding: 2px 10px 4px;
}
.site-banner div.item-tags div.item-tag.large > p {
  border-radius: 18px;
  font-size: 14px;
  line-height: 30px;
  min-width: 100px;
  padding: 2px 12px;
}
@media all and (max-width : 768px) {
  .site-banner div.item-tags div.item-tag.large > p {
    font-size: 15px;
    line-height: 15px;
    min-width: auto;
    padding: 2px 6px 4px;
  }
}

@media all and (min-width: 769px) {
  .top .site-banner .item-tags {
    top: 10px;
    right: 10px;
  }
}
@media all and (min-width: 769px) {
  .top .site-banner .div.item-tags div.item-tag {
    margin: 0;
  }
}
@media all and (min-width: 769px) {
  .top .site-banner div.item-tags div.item-tag > p {
    line-height: 20px;
    padding: 2px 12px 3px;
  }
}

.c-tag--genre {
  border: 1px solid #00ac5a;
  border-radius: 2px;
  color: #00ac5a;
  font-size: 12px;
  line-height: 1.41666667;
  width: 65px;
  min-height: 25px;
  padding: 3px 7px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
  ---------------
  Contributions common | みんなの投稿
  ---------------
*/
.p-contributions__title {
  color: #005c69;
}
.p-contributions__list {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.03562341vw;
}
@media all and (min-width: 769px) {
  .p-contributions__list {
    grid-template-columns: repeat(4, 1fr);
    gap: 13px;
  }
}
.p-contributions__item-link {
  display: inline-block;
}
@media all and (max-width : 768px) {
  .p-contributions__btn {
    text-align: center;
    max-width: 192px;
    margin: 17px auto 0;
  }
}

/*
  ---------------
  filter | 絞り込み検索
  ---------------
*/
@media all and (min-width: 769px) {
  .p-filter {
    min-width: 220px;
  }
}
.p-filter__series-box-list {
  background-color: #f6f6f6;
}
@media all and (max-width : 768px) {
  .p-filter__series-box-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.p-filter__series-box-item {
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42857143;
  min-height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}
@media all and (min-width: 769px) {
  .p-filter__series-box-item {
    font-size: 14px;
  }
}
@media all and (max-width : 768px) {
  .p-filter__series-box-item:nth-child(2n-1) {
    border-right: 1px solid #d9d9d9;
  }
}
.p-filter__series-box-item:last-child {
  border-bottom: 0;
}
.p-filter__series-box-item.is-active {
  background-color: #005c69;
  color: #fff;
  font-weight: 700;
  position: relative;
}
.p-filter__category {
  margin-top: 24px;
}
@media all and (max-width : 768px) {
  .p-filter__category {
    margin-top: 12px;
  }
}
.p-filter__category-inner.is-open .p-filter__category-btn-text::after {
  transform: translateY(-50%) rotate(-180deg);
}
.p-filter__category-btn {
  background-color: #b2b2b2;
  color: #fff;
  min-height: 40px;
  padding: 10px 10px 10px 14px;
  display: flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .p-filter__category-btn {
    min-height: 50px;
  }
}
.p-filter__category-btn-text {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857143;
  width: 100%;
}
@media all and (max-width : 768px) {
  .p-filter__category-btn-text {
    padding-right: 24px;
    position: relative;
  }
}
@media all and (min-width: 769px) {
  .p-filter__category-btn-text {
    font-size: 12px;
    line-height: 1.41666667;
    text-align: center;
  }
}
@media all and (max-width : 768px) {
  .p-filter__category-btn-text::after {
    content: "";
    display: inline-block;
    background: url("/f/resources/images/partwork/icon_arrow_bg_gray.svg") center/contain no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
.p-filter__category-block {
  background-color: #f6f6f6;
  border-radius: 2px 0 2px 2px;
  padding-block: 10px;
}
@media all and (max-width : 768px) {
  .p-filter__category-block {
    display: none;
  }
}
.p-filter__category-box + .p-filter__category-box {
  margin-top: 20px;
}
.p-filter__category-box-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.45454545;
  padding-inline: 14px;
}
@media all and (min-width: 769px) {
  .p-filter__category-box-title {
    font-size: 11px;
  }
}
.p-filter__category-box-list {
  margin-top: 7px;
}
@media all and (min-width: 769px) {
  .p-filter__category-box-list {
    margin-top: 9px;
  }
}
.p-filter__category-box-item-control {
  display: flex;
  gap: 8px;
  padding: 0 10px 8px;
}
.p-filter__category-box-item {
  cursor: pointer;
  color: #005c69;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding: 7px 10px 7px 40px;
}
@media all and (min-width: 769px) {
  .p-filter__category-box-item {
    font-size: 14px;
  }
}
.p-filter__category-box-item.select-all, .p-filter__category-box-item.unselect-all {
  padding: 0;
  text-align: right;
  width: 100%;
}
.p-filter__category-box-item.select-all .p-filter__category-box-item-span, .p-filter__category-box-item.unselect-all .p-filter__category-box-item-span {
  border-radius: 2px;
  border: 1px solid #005c69;
  color: #005c69;
  cursor: pointer;
  display: block;
  font-size: 11px;
  padding: 5px 0 5px 12px;
  position: relative;
  text-align: center;
  transition: 0.2s ease-in-out;
  width: auto;
}
@media all and (max-width : 768px) {
  .p-filter__category-box-item.select-all .p-filter__category-box-item-span, .p-filter__category-box-item.unselect-all .p-filter__category-box-item-span {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.p-filter__category-box-item.select-all .p-filter__category-box-item-span:hover, .p-filter__category-box-item.unselect-all .p-filter__category-box-item-span:hover {
  opacity: 0.8;
}
.p-filter__category-box-item:not(.select-all, .unselect-all) .p-filter__category-box-item-span::before {
  content: "";
  background-color: #fff;
  border: 1px solid #005c69;
  border-radius: 2px;
  display: inline-block;
  overflow: hidden;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
}
.p-filter__category-box-item.select-all .p-filter__category-box-item-span::before, .p-filter__category-box-item.unselect-all .p-filter__category-box-item-span::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-45%);
}
.p-filter__category-box-item.select-all .p-filter__category-box-item-span::before {
  background: url(/f/resources/images/common/icon_check_green.svg) 50%/contain no-repeat;
  height: 7px;
  width: 9px;
}
.p-filter__category-box-item.unselect-all .p-filter__category-box-item-span::before {
  background: url(/f/resources/images/common/icon_cross_green.svg) 50%/contain no-repeat;
  height: 14px;
  width: 14px;
}
.p-filter__category-box-item.is-active:not(.select-all, .unselect-all) .p-filter__category-box-item-span::after {
  background: url(/f/resources/images/common/icon_check_green.svg) 50%/contain no-repeat;
  content: "";
  color: #fff;
  display: inline-block;
  height: 11px;
  width: 13px;
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
}
.p-filter__category-box-item-span {
  display: block;
  width: 100%;
  position: relative;
}
@media all and (max-width : 768px) {
  .p-filter__link-wrap {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
}
.p-filter__link {
  margin-top: 12px;
}
@media all and (max-width : 768px) {
  .p-filter__link {
    width: 50%;
  }
}
@media all and (max-width : 768px) {
  .p-filter__link .c-btn--arrow-green-square {
    padding-left: 8px;
  }
}
@media all and (max-width : 768px) {
  .p-filter__link .c-icon--arrow-green-square {
    font-size: 13px;
    padding-right: 32px;
  }
}

/*
  ---------------
  Internal Banner | 内部バナー
  ---------------
*/
.p-internal-bnr__item-community {
  background-color: #1d1d1b;
}

.p-internal-bnr__item-link {
  display: block;
  color: #fff;
  height: 100%;
  line-height: 78px;
  text-align: center;
  width: 100%;
}

.p-internal-bnr__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.p-internal-bnr__item {
  flex-basis: 232px;
}
/*
  ---------------
  Recommend common | おすすめ商品
  ---------------
*/
/*==========================================
  デフォルトのスタイル削除
==========================================*/
.recommend_pw__wrap .item-list > li.item-list-each {
  border: 0;
  border-radius: 0;
  margin-top: 0;
  width: auto;
}

div.item-thumbnail-img img {
  box-shadow: none;
}

div.item-thumbnail-img-md {
  width: auto;
  height: auto;
}

h2 {
  padding: 0;
}

.p-recommend--pw .p-recommend__title {
  color: #fff;
  position: relative;
}
.p-recommend--pw .p-recommend__title::after {
  content: "";
  background-color: #005c69;
  display: block;
  text-align: right;
  width: 100vw;
  height: 159px;
  position: absolute;
  top: -12px;
  z-index: -1;
}
@media all and (max-width : 768px) {
  .p-recommend--pw .p-recommend__title::after {
    margin: 0 calc(50% - 50vw);
  }
}
@media all and (min-width: 769px) {
  .p-recommend--pw .p-recommend__title::after {
    border-radius: 0 20px 0 0;
    height: 217px;
    margin-left: calc(50% - 50vw);
    top: -27px;
    right: -179px;
  }
}
@media all and (max-width : 768px) {
  .p-recommend--pw .p-recommend__btn {
    text-align: center;
    max-width: 250px;
    margin: 20px auto 0;
  }
}
.p-recommend--pw .p-recommend__list {
  background-color: #fff;
  border-radius: 20px 0;
  margin-top: 27px;
  padding: 60px 4vw 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2.8vw;
  align-items: flex-start;
}
@media all and (max-width : 768px) {
  .p-recommend--pw .p-recommend__list {
    border-radius: 2px;
    padding: 44px 4.16vw 0;
    margin-top: 14px;
  }
}
.p-recommend--pw .p-recommend .item-list-each {
  width: calc((100% - 8.4vw) / 4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
@media all and (max-width : 768px) {
  .p-recommend--pw .p-recommend .item-list-each {
    width: calc((100% - 2.8vw) / 2);
  }
}
.p-recommend--pw .p-recommend .item-list-each .item-tags {
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 1;
}
.p-recommend--pw .p-recommend__list li.item-list-each div.item-thumbnail-img-md {
  border: 1px solid #d9d9d9;
  text-align: center;
  aspect-ratio: 1/1;
}
.p-recommend--pw .p-recommend__list li.item-list-each div.item-thumbnail-img-md img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 2px 4px rgba(127, 127, 127, 0.3));
}
.p-recommend--pw .p-recommend__list li.item-list-each div.item-dtl {
  text-align: center;
  flex: initial;
  margin-top: 5px;
  min-height: 40px;
  overflow: hidden;
}
@media all and (min-width: 769px) {
  .p-recommend--pw .p-recommend__list li.item-list-each div.item-dtl {
    min-height: 52px;
    margin-top: 11px;
  }
}
.p-recommend--pw .p-recommend__list li.item-list-each .item-name {
  display: inline-block;
  text-align: left;
}
@media all and (max-width : 768px) {
  .p-recommend--pw .p-recommend__list li.item-list-each .item-name {
    margin: 0 auto;
  }
}
.p-recommend--pw .p-recommend__list li.item-list-each .item-name a:nth-child(1) {
  display: none;
}
.p-recommend--pw .p-recommend__list li.item-list-each .item-name a {
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.42857143;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
@media all and (min-width: 769px) {
  .p-recommend--pw .p-recommend__list li.item-list-each .item-name a {
    font-size: 16px;
    text-align: center;
    -webkit-line-clamp: 2;
  }
}
.p-recommend--pw .p-recommend__list li.item-list-each .caption {
  font-size: 14px;
}
@media all and (max-width : 768px) {
  .p-recommend--pw .p-recommend__list li.item-list-each .caption {
    font-size: 12px;
  }
}

/*
  ---------------
  series common
  ---------------
*/
.p-series__wrap {
  margin-top: 50px;
  display: flex;
  gap: min(6.11111111vw, 88px);
}
@media all and (max-width: 1186px) {
  .p-series__wrap {
    gap: min(3.50877193vw, 40px);
  }
}
@media all and (max-width : 768px) {
  .p-series__wrap {
    display: block;
    margin-top: 24px;
  }
}
.p-series__content-wrap {
  width: 100%;
}
@media all and (max-width : 768px) {
  .p-series__content-wrap {
    margin-top: 25px;
  }
}
.p-series__content {
  display: none;
}
.p-series__content + .p-series__content {
  margin-top: 50px;
}
.p-series__content.is-show {
  display: grid;
}
.p-series__content-description {
  margin-top: 3px;
}
.p-series__list {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: min(2.01388889vw, 29px);
}
@media all and (max-width: 1150px) {
  .p-series__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
.p-series__item {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 3;
  gap: 10px;
  margin-bottom: 40px;
}
@media all and (max-width: 1024px) {
  .p-series__item {
    gap: 8px;
  }
}
.p-series__item {
  display: none;
}
.p-series__item.is-show {
  display: grid;
}
.p-series__card {
  height: 100%;
}
.p-series__card-link {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.p-series__card-img {
  text-align: center;
  padding-top: 88.1294%;
  overflow: hidden;
  position: relative;
}
@media all and (max-width : 768px) {
  .p-series__card-img {
    padding-top: 88.0681%;
  }
}
.p-series__card-img::after {
  content: "";
  background: transparent url("/f/resources/images/partwork/icon_link.svg") center/contain no-repeat;
  display: inline-block;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0;
  right: 0;
}
.p-series__card-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.p-series__card-genre {
  color: #b2b2b2;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-top: 6px;
}
@media all and (min-width: 769px) {
  .p-series__card-genre {
    font-size: 11px;
    line-height: 1.45454545;
  }
}
.p-series__card-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857143;
  margin-top: 10px;
}
.p-series__card-text {
  margin-top: 7px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.p-series__btn-group-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
@media all and (max-width: 1024px) {
  .p-series__btn-group-list {
    gap: min(2.03562341vw, 8px);
  }
}
.p-series__btn-group-item {
  width: calc(50% - 5px);
}
.p-series__btn-group-item:first-child:last-child {
  width: 100%;
}
.p-series__btn-group-item:nth-child(3):last-child {
  width: 100%;
}
.p-series__btn-group--upper .p-series__btn-group {
  margin-top: 4px;
}
@media all and (max-width: 1024px) {
  .p-series__btn-group--upper .p-series__btn-group {
    margin-top: 2px;
  }
}
@media all and (max-width : 768px) {
  .p-series__btn-group--lower .p-series__btn-group-list {
    grid-template-columns: repeat(auto-fit, minmax(96px, 1fr));
  }
}
.p-series__btn-group--lower .p-series__btn-group-item-link {
  padding: 0 3px;
}
.p-series__btn-group--lower .p-series__btn-group-item-text {
  color: #005c69;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.45454545;
  text-align: center;
}

/*
  ---------------
  Topics common
  ---------------
*/
.p-topics {
  background-color: #00828c;
}
.p-topics__inner {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-block: 24px 29px;
}
@media all and (max-width: 768px) {
  .p-topics__inner {
    padding-inline: 12px;
  }
}
.p-topics__title {
  color: #fff;
}
@media all and (max-width : 768px) {
  .p-topics__title {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }
}
.p-topics__navi {
  display: none;
}
.p-topics__navi.slick-slider {
  display: flex;
}
@media all and (max-width : 768px) {
  .p-topics__navi {
    margin-top: 12px;
    justify-content: center;
  }
}
.p-topics__category {
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  text-align: center;
  padding: 4px 0;
  white-space: nowrap;
}
@media all and (min-width: 769px) {
  .p-topics__category {
    font-weight: 500;
    padding: 9px 0;
  }
}
.p-topics__category.active {
  position: relative;
}
.p-topics__category.active::after {
  content: "";
  background-color: #fff;
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.p-topics__category:hover {
  color: #00ada0;
  transition: all 0.2s ease-in-out;
}
.p-topics__btn {
  text-align: center;
}
@media all and (max-width : 768px) {
  .p-topics__btn {
    max-width: 150px;
    margin: 24px auto 0;
  }
}
.p-topics__btn-link.is-hide {
  visibility: hidden;
}
@media all and (max-width : 768px) {
  .p-topics__btn-link.is-hide {
    display: none;
  }
}
.p-topics .p-topics__navi.slick-slider {
  text-align: center;
  max-width: 339px;
  padding: 0 28px;
}
@media all and (max-width : 768px) {
  .p-topics .p-topics__navi.slick-slider {
    margin: 16px auto 0;
  }
}
@media all and (min-width: 769px) {
  .p-topics .p-topics__navi.slick-slider {
    max-width: 570px;
    padding: 0 59px;
  }
}
.p-topics .p-topics__navi .slick-slide {
  display: inline-block;
  margin: 0 10px;
}
@media all and (min-width: 769px) {
  .p-topics .p-topics__navi .slick-slide {
    margin: 0 15px;
  }
}
.p-topics .p-topics__navi .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.p-topics .p-topics__navi .slick-prev {
  left: 0;
}
.p-topics .p-topics__navi .slick-next {
  right: 0;
}
.p-topics .p-topics__navi .slick-prev, .p-topics .p-topics__navi .slick-next {
  width: 10px;
  height: 20px;
}
@media all and (max-width : 768px) {
  .p-topics .p-topics__navi .slick-prev, .p-topics .p-topics__navi .slick-next {
    width: 11px;
    height: 19px;
  }
}
.p-topics .p-topics__navi .slick-prev::before {
  background: transparent url("/f/resources/images/top/icon_prev_white.svg") center/contain no-repeat;
}
.p-topics .p-topics__navi .slick-next::before {
  background: transparent url("/f/resources/images/top/icon_next_white.svg") center/contain no-repeat;
}
.p-topics .p-topics__navi .slick-prev:before, .p-topics .p-topics__navi .slick-next:before {
  content: "";
  display: inline-block;
  width: inherit;
  height: inherit;
}
.p-topics .p-topics__navi .slick-prev.slick-disabled:before, .p-topics .p-topics__navi .slick-next.slick-disabled:before {
  opacity: 0;
}

/*
  ----------------
  responsive utility
  ----------------
*/
@media all and (min-width: 769px) {
  .u-pc--hide {
    display: none;
  }
}

@media all and (max-width : 768px) {
  .u-sp--hide {
    display: none;
  }
}

@media all and (max-width: 1024px) and (min-width: 769px) {
  .u-tab--hide {
    display: none;
  }
}

@media all and (max-width: 1024px) {
  .u-tab-under--hide {
    display: none;
  }
}

@media all and (min-width: 1025px) {
  .u-tab-over--hide {
    display: none;
  }
}

/*
  ----------------
  utility
  ----------------
*/
@media all and (min-width: 769px) {
  .u-mt--10 {
    margin-top: 10px;
  }
}

@media all and (min-width: 769px) {
  .u-mt--20 {
    margin-top: 10px;
  }
}

@media all and (min-width: 769px) {
  .u-mt--30 {
    margin-top: 30px;
  }
}

@media all and (min-width: 769px) {
  .u-mt--40 {
    margin-top: 40px;
  }
}

@media all and (min-width: 769px) {
  .u-mt--50 {
    margin-top: 50px;
  }
}

@media all and (min-width: 769px) {
  .u-mt--60 {
    margin-top: 60px;
  }
}

@media all and (min-width: 769px) {
  .u-mt--70 {
    margin-top: 70px;
  }
}

@media all and (min-width: 769px) {
  .u-mt--80 {
    margin-top: 80px;
  }
}

@media all and (min-width: 769px) {
  .u-mt--90 {
    margin-top: 90px;
  }
}

@media all and (min-width: 769px) {
  .u-mt--100 {
    margin-top: 100px;
  }
}

@media all and (max-width : 768px) {
  .u-mt--10-sp {
    margin-top: 10px;
  }
}

@media all and (max-width : 768px) {
  .u-mt--20-sp {
    margin-top: 20px;
  }
}

@media all and (max-width : 768px) {
  .u-mt--30-sp {
    margin-top: 30px;
  }
}

@media all and (max-width : 768px) {
  .u-mt--40-sp {
    margin-top: 30px;
  }
}

@media all and (max-width : 768px) {
  .u-mt--50-sp {
    margin-top: 30px;
  }
}

@media all and (min-width: 769px) {
  .u-h--100p {
    height: 100%;
  }
}

@media all and (max-width : 768px) {
  .u-h--100p-sp {
    height: 100%;
  }
}

.u-container {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width : 768px) {
  .u-container {
    padding-inline: 12px;
  }
}

/*
  ----------------
  Editor Utils | 管理画面のエディタで利用するためのユーティリティー
  ----------------
*/
@media all and (max-width : 768px) {
  .d-pc-only {
    display: none;
  }
}

@media all and (min-width: 769px) {
  .d-sp-only {
    display: none;
  }
}

/*
  ---------------
  全体共通 | 2ページ以上で使用する共通のスタイル
  ---------------
*/
.icons div.item-tags div.item-tag {
  background-color: transparent;
  padding: 0;
}

.icons .c-itemtag {
  margin-left: 0;
}

.primary-message {
  border: 1px solid #c10000;
  background-color: #ffebeb;
  color: #c10000;
  font-size: 16px;
  margin: 40px 0;
  padding: 20px;
}
@media all and (max-width : 768px) {
  .primary-message {
    font-size: 14px;
    margin: 20px 0;
    padding: 10px;
  }
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/* マイページトップへ戻る 調整 */
#account__view_partwork_subscriptions #page_aside2 .btn-account,
#account__view_partwork_subscription #page_aside2 .btn-account,
#account__edit_partwork_consignee #page_aside2 .btn-account,
#account__edit_partwork_payment #page_aside2 .btn-account,
#account__unsubscribe_partwork #page_aside2 .btn-account,
#account__view_purchased_merchandisecontents #page_aside2 .btn-account,
#account__your_communities #page_aside2 .btn-account {
  margin-top: 80px;
  position: static;
  transform: none;
}

#account__view_partwork_subscriptions #page_aside1 .btn-account,
#account__view_partwork_subscription #page_aside1 .btn-account,
#account__edit_partwork_consignee #page_aside1 .btn-account,
#account__edit_partwork_payment #page_aside1 .btn-account,
#account__unsubscribe_partwork #page_aside1 .btn-account,
#account__your_communities #page_aside1 .btn-account,
#account__view_purchased_merchandisecontents #page_aside1 .btn-account {
  display: none;
}

/*
  ---------------
  マイページ | 共通
  ---------------
*/
#account__account #outer,
#account__close_account #outer,
#account__edit_account #outer,
#account__edit_address #outer,
#account__edit_account_properties #outer,
#account__edit_profile #outer,
#account__manage_addressbook #outer,
#account__manage_paymentmethod #outer,
#account__view_inquiries #outer,
#account__order #outer,
#account__view_account_message_history #outer,
#account__view_order_history #outer,
#account__view_order_history #signout #outer,
#account__view_orders #outer,
#account__your_coupons #outer,
#entry_password_reminder #outer,
#order__payment_status #outer,
#update_password #outer,
#account__view_points_history #outer,
#account__view_partwork_subscription #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  #account__account #outer,
  #account__close_account #outer,
  #account__edit_account #outer,
  #account__edit_address #outer,
  #account__edit_account_properties #outer,
  #account__edit_profile #outer,
  #account__manage_addressbook #outer,
  #account__manage_paymentmethod #outer,
  #account__view_inquiries #outer,
  #account__order #outer,
  #account__view_account_message_history #outer,
  #account__view_order_history #outer,
  #account__view_order_history #signout #outer,
  #account__view_orders #outer,
  #account__your_coupons #outer,
  #entry_password_reminder #outer,
  #order__payment_status #outer,
  #update_password #outer,
  #account__view_points_history #outer,
  #account__view_partwork_subscription #outer {
    padding-inline: 12px;
  }
}
#account__account #page_aside1,
#account__account #page_aside2,
#account__close_account #page_aside1,
#account__close_account #page_aside2,
#account__edit_account #page_aside1,
#account__edit_account #page_aside2,
#account__edit_address #page_aside1,
#account__edit_address #page_aside2,
#account__edit_account_properties #page_aside1,
#account__edit_account_properties #page_aside2,
#account__edit_profile #page_aside1,
#account__edit_profile #page_aside2,
#account__manage_addressbook #page_aside1,
#account__manage_addressbook #page_aside2,
#account__manage_paymentmethod #page_aside1,
#account__manage_paymentmethod #page_aside2,
#account__view_inquiries #page_aside1,
#account__view_inquiries #page_aside2,
#account__order #page_aside1,
#account__order #page_aside2,
#account__view_account_message_history #page_aside1,
#account__view_account_message_history #page_aside2,
#account__view_order_history #page_aside1,
#account__view_order_history #page_aside2,
#account__view_order_history #signout #page_aside1,
#account__view_order_history #signout #page_aside2,
#account__view_orders #page_aside1,
#account__view_orders #page_aside2,
#account__your_coupons #page_aside1,
#account__your_coupons #page_aside2,
#entry_password_reminder #page_aside1,
#entry_password_reminder #page_aside2,
#order__payment_status #page_aside1,
#order__payment_status #page_aside2,
#update_password #page_aside1,
#update_password #page_aside2,
#account__view_points_history #page_aside1,
#account__view_points_history #page_aside2,
#account__view_partwork_subscription #page_aside1,
#account__view_partwork_subscription #page_aside2 {
  position: relative;
}
#account__account h1,
#account__close_account h1,
#account__edit_account h1,
#account__edit_address h1,
#account__edit_account_properties h1,
#account__edit_profile h1,
#account__manage_addressbook h1,
#account__manage_paymentmethod h1,
#account__view_inquiries h1,
#account__order h1,
#account__view_account_message_history h1,
#account__view_order_history h1,
#account__view_order_history #signout h1,
#account__view_orders h1,
#account__your_coupons h1,
#entry_password_reminder h1,
#order__payment_status h1,
#update_password h1,
#account__view_points_history h1,
#account__view_partwork_subscription h1 {
  font-size: 24px;
  font-weight: 400;
  padding: 0;
}
@media all and (min-width: 769px) {
  #account__account .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__close_account .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__edit_account .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__edit_address .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__edit_account_properties .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__edit_profile .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__manage_addressbook .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__manage_paymentmethod .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__view_inquiries .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__order .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__view_account_message_history .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__view_order_history .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__view_order_history #signout .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__view_orders .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__your_coupons .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #entry_password_reminder .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #order__payment_status .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #update_password .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__view_points_history .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div,
  #account__view_partwork_subscription .form-auth.form-signin div.form.signin-form > div.form-body.form-signin form > div {
    padding-inline: 0;
  }
}

/*--------------------------------------------------
注文一覧系
--------------------------------------------------*/
#account__order_inquiry .order-list,
#account__order_inquiry .order-valid,
#account__close_account .order-list,
#account__close_account .order-valid,
#account__view_order_history .order-list,
#account__view_order_history .order-valid,
#account__view_orders .order-list,
#account__view_orders .order-valid,
#account__order .order-list,
#account__order .order-valid,
#account__view_orders .order-list,
#account__view_orders .order-valid,
#order__payment_status .order-list,
#order__payment_status .order-valid {
  padding-top: 30px;
}
#account__order_inquiry .order-list li,
#account__close_account .order-list li,
#account__view_order_history .order-list li,
#account__view_orders .order-list li,
#account__order .order-list li,
#account__view_orders .order-list li,
#order__payment_status .order-list li {
  padding-bottom: 0;
}
#account__order_inquiry .order-list li + li,
#account__close_account .order-list li + li,
#account__view_order_history .order-list li + li,
#account__view_orders .order-list li + li,
#account__order .order-list li + li,
#account__view_orders .order-list li + li,
#order__payment_status .order-list li + li {
  padding-top: 50px;
}
#account__order_inquiry ul.order-list li > .panel,
#account__close_account ul.order-list li > .panel,
#account__view_order_history ul.order-list li > .panel,
#account__view_orders ul.order-list li > .panel,
#account__order ul.order-list li > .panel,
#account__view_orders ul.order-list li > .panel,
#order__payment_status ul.order-list li > .panel {
  border: 1px solid #d9d9d9;
  padding-bottom: 40px;
}
@media all and (max-width : 768px) {
  #account__order_inquiry ul.order-list li > .panel,
  #account__close_account ul.order-list li > .panel,
  #account__view_order_history ul.order-list li > .panel,
  #account__view_orders ul.order-list li > .panel,
  #account__order ul.order-list li > .panel,
  #account__view_orders ul.order-list li > .panel,
  #order__payment_status ul.order-list li > .panel {
    padding-bottom: 16px;
  }
}
#account__order_inquiry .panel-order > .panel-heading,
#account__order_inquiry .panel-order-smr > .panel-heading,
#account__close_account .panel-order > .panel-heading,
#account__close_account .panel-order-smr > .panel-heading,
#account__view_order_history .panel-order > .panel-heading,
#account__view_order_history .panel-order-smr > .panel-heading,
#account__view_orders .panel-order > .panel-heading,
#account__view_orders .panel-order-smr > .panel-heading,
#account__order .panel-order > .panel-heading,
#account__order .panel-order-smr > .panel-heading,
#account__view_orders .panel-order > .panel-heading,
#account__view_orders .panel-order-smr > .panel-heading,
#order__payment_status .panel-order > .panel-heading,
#order__payment_status .panel-order-smr > .panel-heading {
  background: transparent;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .panel-order > .panel-heading,
  #account__order_inquiry .panel-order-smr > .panel-heading,
  #account__close_account .panel-order > .panel-heading,
  #account__close_account .panel-order-smr > .panel-heading,
  #account__view_order_history .panel-order > .panel-heading,
  #account__view_order_history .panel-order-smr > .panel-heading,
  #account__view_orders .panel-order > .panel-heading,
  #account__view_orders .panel-order-smr > .panel-heading,
  #account__order .panel-order > .panel-heading,
  #account__order .panel-order-smr > .panel-heading,
  #account__view_orders .panel-order > .panel-heading,
  #account__view_orders .panel-order-smr > .panel-heading,
  #order__payment_status .panel-order > .panel-heading,
  #order__payment_status .panel-order-smr > .panel-heading {
    align-items: flex-start;
  }
}
#account__order_inquiry .panel-order .panel-heading > div,
#account__order_inquiry .panel-order-smr .panel-heading > div,
#account__close_account .panel-order .panel-heading > div,
#account__close_account .panel-order-smr .panel-heading > div,
#account__view_order_history .panel-order .panel-heading > div,
#account__view_order_history .panel-order-smr .panel-heading > div,
#account__view_orders .panel-order .panel-heading > div,
#account__view_orders .panel-order-smr .panel-heading > div,
#account__order .panel-order .panel-heading > div,
#account__order .panel-order-smr .panel-heading > div,
#account__view_orders .panel-order .panel-heading > div,
#account__view_orders .panel-order-smr .panel-heading > div,
#order__payment_status .panel-order .panel-heading > div,
#order__payment_status .panel-order-smr .panel-heading > div {
  flex: none;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .panel-order .panel-heading > div,
  #account__order_inquiry .panel-order-smr .panel-heading > div,
  #account__close_account .panel-order .panel-heading > div,
  #account__close_account .panel-order-smr .panel-heading > div,
  #account__view_order_history .panel-order .panel-heading > div,
  #account__view_order_history .panel-order-smr .panel-heading > div,
  #account__view_orders .panel-order .panel-heading > div,
  #account__view_orders .panel-order-smr .panel-heading > div,
  #account__order .panel-order .panel-heading > div,
  #account__order .panel-order-smr .panel-heading > div,
  #account__view_orders .panel-order .panel-heading > div,
  #account__view_orders .panel-order-smr .panel-heading > div,
  #order__payment_status .panel-order .panel-heading > div,
  #order__payment_status .panel-order-smr .panel-heading > div {
    width: 100%;
  }
}
@media all and (max-width : 768px) {
  #account__order_inquiry .panel-order .panel-heading > div.shipment-status,
  #account__order_inquiry .panel-order .panel-heading > div.shipment-code,
  #account__order_inquiry .panel-order-smr .panel-heading > div.shipment-status,
  #account__order_inquiry .panel-order-smr .panel-heading > div.shipment-code,
  #account__close_account .panel-order .panel-heading > div.shipment-status,
  #account__close_account .panel-order .panel-heading > div.shipment-code,
  #account__close_account .panel-order-smr .panel-heading > div.shipment-status,
  #account__close_account .panel-order-smr .panel-heading > div.shipment-code,
  #account__view_order_history .panel-order .panel-heading > div.shipment-status,
  #account__view_order_history .panel-order .panel-heading > div.shipment-code,
  #account__view_order_history .panel-order-smr .panel-heading > div.shipment-status,
  #account__view_order_history .panel-order-smr .panel-heading > div.shipment-code,
  #account__view_orders .panel-order .panel-heading > div.shipment-status,
  #account__view_orders .panel-order .panel-heading > div.shipment-code,
  #account__view_orders .panel-order-smr .panel-heading > div.shipment-status,
  #account__view_orders .panel-order-smr .panel-heading > div.shipment-code,
  #account__order .panel-order .panel-heading > div.shipment-status,
  #account__order .panel-order .panel-heading > div.shipment-code,
  #account__order .panel-order-smr .panel-heading > div.shipment-status,
  #account__order .panel-order-smr .panel-heading > div.shipment-code,
  #account__view_orders .panel-order .panel-heading > div.shipment-status,
  #account__view_orders .panel-order .panel-heading > div.shipment-code,
  #account__view_orders .panel-order-smr .panel-heading > div.shipment-status,
  #account__view_orders .panel-order-smr .panel-heading > div.shipment-code,
  #order__payment_status .panel-order .panel-heading > div.shipment-status,
  #order__payment_status .panel-order .panel-heading > div.shipment-code,
  #order__payment_status .panel-order-smr .panel-heading > div.shipment-status,
  #order__payment_status .panel-order-smr .panel-heading > div.shipment-code {
    width: unset;
  }
}
#account__order_inquiry .panel-heading .order-code h5,
#account__close_account .panel-heading .order-code h5,
#account__view_order_history .panel-heading .order-code h5,
#account__view_orders .panel-heading .order-code h5,
#account__order .panel-heading .order-code h5,
#account__view_orders .panel-heading .order-code h5,
#order__payment_status .panel-heading .order-code h5 {
  font-size: 16px;
  padding: 0;
}
#account__order_inquiry .panel-heading .order-code .code,
#account__close_account .panel-heading .order-code .code,
#account__view_order_history .panel-heading .order-code .code,
#account__view_orders .panel-heading .order-code .code,
#account__order .panel-heading .order-code .code,
#account__view_orders .panel-heading .order-code .code,
#order__payment_status .panel-heading .order-code .code {
  font-size: 20px;
  font-weight: 500;
  padding: 0;
}
#account__order_inquiry .panel-heading .order-code h5 + div,
#account__close_account .panel-heading .order-code h5 + div,
#account__view_order_history .panel-heading .order-code h5 + div,
#account__view_orders .panel-heading .order-code h5 + div,
#account__order .panel-heading .order-code h5 + div,
#account__view_orders .panel-heading .order-code h5 + div,
#order__payment_status .panel-heading .order-code h5 + div {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
#account__order_inquiry .panel-heading .order-code + div a,
#account__order_inquiry .panel-heading .order-code a,
#account__close_account .panel-heading .order-code + div a,
#account__close_account .panel-heading .order-code a,
#account__view_order_history .panel-heading .order-code + div a,
#account__view_order_history .panel-heading .order-code a,
#account__view_orders .panel-heading .order-code + div a,
#account__view_orders .panel-heading .order-code a,
#account__order .panel-heading .order-code + div a,
#account__order .panel-heading .order-code a,
#account__view_orders .panel-heading .order-code + div a,
#account__view_orders .panel-heading .order-code a,
#order__payment_status .panel-heading .order-code + div a,
#order__payment_status .panel-heading .order-code a {
  background: #1d1d1b;
  border-radius: 2px;
  color: #fff;
  display: block;
  font-size: 14px;
  padding: 4px 12px 4px;
  white-space: nowrap;
}
#account__order_inquiry .total-invoice,
#account__close_account .total-invoice,
#account__view_order_history .total-invoice,
#account__view_orders .total-invoice,
#account__order .total-invoice,
#account__view_orders .total-invoice,
#order__payment_status .total-invoice {
  padding-top: 20px;
}
#account__order_inquiry .payment-status__tag .status,
#account__order_inquiry .order-status .status,
#account__order_inquiry .shipment-status .status,
#account__order_inquiry .journal-status .status,
#account__close_account .payment-status__tag .status,
#account__close_account .order-status .status,
#account__close_account .shipment-status .status,
#account__close_account .journal-status .status,
#account__view_order_history .payment-status__tag .status,
#account__view_order_history .order-status .status,
#account__view_order_history .shipment-status .status,
#account__view_order_history .journal-status .status,
#account__view_orders .payment-status__tag .status,
#account__view_orders .order-status .status,
#account__view_orders .shipment-status .status,
#account__view_orders .journal-status .status,
#account__order .payment-status__tag .status,
#account__order .order-status .status,
#account__order .shipment-status .status,
#account__order .journal-status .status,
#account__view_orders .payment-status__tag .status,
#account__view_orders .order-status .status,
#account__view_orders .shipment-status .status,
#account__view_orders .journal-status .status,
#order__payment_status .payment-status__tag .status,
#order__payment_status .order-status .status,
#order__payment_status .shipment-status .status,
#order__payment_status .journal-status .status {
  border: 1px solid #396dcf;
  border-radius: 2px;
  color: #396dcf;
  font-size: 12px;
  padding: 4px 15px;
}
#account__order_inquiry .payment-status__tag .status.payment-status-DONE,
#account__close_account .payment-status__tag .status.payment-status-DONE,
#account__view_order_history .payment-status__tag .status.payment-status-DONE,
#account__view_orders .payment-status__tag .status.payment-status-DONE,
#account__order .payment-status__tag .status.payment-status-DONE,
#account__view_orders .payment-status__tag .status.payment-status-DONE,
#order__payment_status .payment-status__tag .status.payment-status-DONE {
  border: 1px solid #005c69;
  color: #005c69;
}
#account__order_inquiry .payment-status__tag .status.payment-status-UNCLAIMED,
#account__close_account .payment-status__tag .status.payment-status-UNCLAIMED,
#account__view_order_history .payment-status__tag .status.payment-status-UNCLAIMED,
#account__view_orders .payment-status__tag .status.payment-status-UNCLAIMED,
#account__order .payment-status__tag .status.payment-status-UNCLAIMED,
#account__view_orders .payment-status__tag .status.payment-status-UNCLAIMED,
#order__payment_status .payment-status__tag .status.payment-status-UNCLAIMED {
  border: 1px solid #747474;
  color: #747474;
}
#account__order_inquiry .payment-status__tag .status.payment-status-UNPAID,
#account__close_account .payment-status__tag .status.payment-status-UNPAID,
#account__view_order_history .payment-status__tag .status.payment-status-UNPAID,
#account__view_orders .payment-status__tag .status.payment-status-UNPAID,
#account__order .payment-status__tag .status.payment-status-UNPAID,
#account__view_orders .payment-status__tag .status.payment-status-UNPAID,
#order__payment_status .payment-status__tag .status.payment-status-UNPAID {
  border: 1px solid #c10000;
  color: #c10000;
}
#account__order_inquiry .cdt-RECEIPT a.btn,
#account__close_account .cdt-RECEIPT a.btn,
#account__view_order_history .cdt-RECEIPT a.btn,
#account__view_orders .cdt-RECEIPT a.btn,
#account__order .cdt-RECEIPT a.btn,
#account__view_orders .cdt-RECEIPT a.btn,
#order__payment_status .cdt-RECEIPT a.btn {
  background: #494949;
  border: 1px solid #494949;
  border-radius: 2px;
  color: #fff !important;
  padding: 5px 16px;
  font-size: 14px;
}
@media (hover: hover) and (pointer: fine) {
  #account__order_inquiry .cdt-RECEIPT a.btn,
  #account__close_account .cdt-RECEIPT a.btn,
  #account__view_order_history .cdt-RECEIPT a.btn,
  #account__view_orders .cdt-RECEIPT a.btn,
  #account__order .cdt-RECEIPT a.btn,
  #account__view_orders .cdt-RECEIPT a.btn,
  #order__payment_status .cdt-RECEIPT a.btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #account__order_inquiry .cdt-RECEIPT a.btn:hover,
  #account__close_account .cdt-RECEIPT a.btn:hover,
  #account__view_order_history .cdt-RECEIPT a.btn:hover,
  #account__view_orders .cdt-RECEIPT a.btn:hover,
  #account__order .cdt-RECEIPT a.btn:hover,
  #account__view_orders .cdt-RECEIPT a.btn:hover,
  #order__payment_status .cdt-RECEIPT a.btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#account__order_inquiry .cdt-RECEIPT a.btn::before, #account__order_inquiry .cdt-RECEIPT a.btn::after,
#account__close_account .cdt-RECEIPT a.btn::before,
#account__close_account .cdt-RECEIPT a.btn::after,
#account__view_order_history .cdt-RECEIPT a.btn::before,
#account__view_order_history .cdt-RECEIPT a.btn::after,
#account__view_orders .cdt-RECEIPT a.btn::before,
#account__view_orders .cdt-RECEIPT a.btn::after,
#account__order .cdt-RECEIPT a.btn::before,
#account__order .cdt-RECEIPT a.btn::after,
#account__view_orders .cdt-RECEIPT a.btn::before,
#account__view_orders .cdt-RECEIPT a.btn::after,
#order__payment_status .cdt-RECEIPT a.btn::before,
#order__payment_status .cdt-RECEIPT a.btn::after {
  backface-visibility: hidden;
}
#account__order_inquiry .account .panel-heading + .panel-body,
#account__order_inquiry .form-close-account .panel-heading + .panel-body,
#account__close_account .account .panel-heading + .panel-body,
#account__close_account .form-close-account .panel-heading + .panel-body,
#account__view_order_history .account .panel-heading + .panel-body,
#account__view_order_history .form-close-account .panel-heading + .panel-body,
#account__view_orders .account .panel-heading + .panel-body,
#account__view_orders .form-close-account .panel-heading + .panel-body,
#account__order .account .panel-heading + .panel-body,
#account__order .form-close-account .panel-heading + .panel-body,
#account__view_orders .account .panel-heading + .panel-body,
#account__view_orders .form-close-account .panel-heading + .panel-body,
#order__payment_status .account .panel-heading + .panel-body,
#order__payment_status .form-close-account .panel-heading + .panel-body {
  margin: 0;
  padding: 26px 20px 0;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .account .panel-heading + .panel-body,
  #account__order_inquiry .form-close-account .panel-heading + .panel-body,
  #account__close_account .account .panel-heading + .panel-body,
  #account__close_account .form-close-account .panel-heading + .panel-body,
  #account__view_order_history .account .panel-heading + .panel-body,
  #account__view_order_history .form-close-account .panel-heading + .panel-body,
  #account__view_orders .account .panel-heading + .panel-body,
  #account__view_orders .form-close-account .panel-heading + .panel-body,
  #account__order .account .panel-heading + .panel-body,
  #account__order .form-close-account .panel-heading + .panel-body,
  #account__view_orders .account .panel-heading + .panel-body,
  #account__view_orders .form-close-account .panel-heading + .panel-body,
  #order__payment_status .account .panel-heading + .panel-body,
  #order__payment_status .form-close-account .panel-heading + .panel-body {
    padding: 20px 10px 0;
  }
}
@media all and (max-width : 768px) {
  #account__order_inquiry .account .panel-heading + .panel-body .panel-body,
  #account__close_account .account .panel-heading + .panel-body .panel-body,
  #account__view_order_history .account .panel-heading + .panel-body .panel-body,
  #account__view_orders .account .panel-heading + .panel-body .panel-body,
  #account__order .account .panel-heading + .panel-body .panel-body,
  #account__view_orders .account .panel-heading + .panel-body .panel-body,
  #order__payment_status .account .panel-heading + .panel-body .panel-body {
    padding: 0;
  }
}
#account__order_inquiry .panel-heading + .panel-body .shipment-header,
#account__order_inquiry .panel-heading + .panel-body .panel-heading,
#account__close_account .panel-heading + .panel-body .shipment-header,
#account__close_account .panel-heading + .panel-body .panel-heading,
#account__view_order_history .panel-heading + .panel-body .shipment-header,
#account__view_order_history .panel-heading + .panel-body .panel-heading,
#account__view_orders .panel-heading + .panel-body .shipment-header,
#account__view_orders .panel-heading + .panel-body .panel-heading,
#account__order .panel-heading + .panel-body .shipment-header,
#account__order .panel-heading + .panel-body .panel-heading,
#account__view_orders .panel-heading + .panel-body .shipment-header,
#account__view_orders .panel-heading + .panel-body .panel-heading,
#order__payment_status .panel-heading + .panel-body .shipment-header,
#order__payment_status .panel-heading + .panel-body .panel-heading {
  background: #d9d9d9;
  padding: 12px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .panel-heading + .panel-body .shipment-header,
  #account__order_inquiry .panel-heading + .panel-body .panel-heading,
  #account__close_account .panel-heading + .panel-body .shipment-header,
  #account__close_account .panel-heading + .panel-body .panel-heading,
  #account__view_order_history .panel-heading + .panel-body .shipment-header,
  #account__view_order_history .panel-heading + .panel-body .panel-heading,
  #account__view_orders .panel-heading + .panel-body .shipment-header,
  #account__view_orders .panel-heading + .panel-body .panel-heading,
  #account__order .panel-heading + .panel-body .shipment-header,
  #account__order .panel-heading + .panel-body .panel-heading,
  #account__view_orders .panel-heading + .panel-body .shipment-header,
  #account__view_orders .panel-heading + .panel-body .panel-heading,
  #order__payment_status .panel-heading + .panel-body .shipment-header,
  #order__payment_status .panel-heading + .panel-body .panel-heading {
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 14px;
  }
}
#account__order_inquiry .shipment-code,
#account__close_account .shipment-code,
#account__view_order_history .shipment-code,
#account__view_orders .shipment-code,
#account__order .shipment-code,
#account__view_orders .shipment-code,
#order__payment_status .shipment-code {
  line-height: 1;
  display: flex;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .shipment-code,
  #account__close_account .shipment-code,
  #account__view_order_history .shipment-code,
  #account__view_orders .shipment-code,
  #account__order .shipment-code,
  #account__view_orders .shipment-code,
  #order__payment_status .shipment-code {
    width: 100%;
  }
}
#account__order_inquiry .shipment-code span,
#account__close_account .shipment-code span,
#account__view_order_history .shipment-code span,
#account__view_orders .shipment-code span,
#account__order .shipment-code span,
#account__view_orders .shipment-code span,
#order__payment_status .shipment-code span {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  gap: 20px;
}
#account__order_inquiry .shipment-code span::before,
#account__close_account .shipment-code span::before,
#account__view_order_history .shipment-code span::before,
#account__view_orders .shipment-code span::before,
#account__order .shipment-code span::before,
#account__view_orders .shipment-code span::before,
#order__payment_status .shipment-code span::before {
  background: url() 50%/contain no-repeat;
  content: "出荷番号";
  display: block;
  font-size: 20px;
  font-weight: 400;
}
#account__order_inquiry .shipment-status,
#account__close_account .shipment-status,
#account__view_order_history .shipment-status,
#account__view_orders .shipment-status,
#account__order .shipment-status,
#account__view_orders .shipment-status,
#order__payment_status .shipment-status {
  padding: 0;
}
#account__order_inquiry .itemdetails-body .itemdetail-row,
#account__close_account .itemdetails-body .itemdetail-row,
#account__view_order_history .itemdetails-body .itemdetail-row,
#account__view_orders .itemdetails-body .itemdetail-row,
#account__order .itemdetails-body .itemdetail-row,
#account__view_orders .itemdetails-body .itemdetail-row,
#order__payment_status .itemdetails-body .itemdetail-row {
  border-bottom: 1px solid #d9d9d9;
  padding-block: 30px;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .itemdetails-body .itemdetail-row,
  #account__close_account .itemdetails-body .itemdetail-row,
  #account__view_order_history .itemdetails-body .itemdetail-row,
  #account__view_orders .itemdetails-body .itemdetail-row,
  #account__order .itemdetails-body .itemdetail-row,
  #account__view_orders .itemdetails-body .itemdetail-row,
  #order__payment_status .itemdetails-body .itemdetail-row {
    padding-block: 20px;
    flex-direction: column;
  }
}
@media all and (max-width : 768px) {
  #account__order_inquiry .itemdetail-row .goods,
  #account__close_account .itemdetail-row .goods,
  #account__view_order_history .itemdetail-row .goods,
  #account__view_orders .itemdetail-row .goods,
  #account__order .itemdetail-row .goods,
  #account__view_orders .itemdetail-row .goods,
  #order__payment_status .itemdetail-row .goods {
    display: flex;
    justify-content: flex-start;
  }
}
@media all and (max-width : 768px) {
  #account__order_inquiry .item-thumbnail-img-wrapper,
  #account__close_account .item-thumbnail-img-wrapper,
  #account__view_order_history .item-thumbnail-img-wrapper,
  #account__view_orders .item-thumbnail-img-wrapper,
  #account__order .item-thumbnail-img-wrapper,
  #account__view_orders .item-thumbnail-img-wrapper,
  #order__payment_status .item-thumbnail-img-wrapper {
    margin: 0;
  }
}
#account__order_inquiry .itemdetails-body .itemdetail-row .goods .item-thumbnail-img,
#account__close_account .itemdetails-body .itemdetail-row .goods .item-thumbnail-img,
#account__view_order_history .itemdetails-body .itemdetail-row .goods .item-thumbnail-img,
#account__view_orders .itemdetails-body .itemdetail-row .goods .item-thumbnail-img,
#account__order .itemdetails-body .itemdetail-row .goods .item-thumbnail-img,
#account__view_orders .itemdetails-body .itemdetail-row .goods .item-thumbnail-img,
#order__payment_status .itemdetails-body .itemdetail-row .goods .item-thumbnail-img {
  padding: 0;
}
#account__order_inquiry .itemdetail-row .goods-text,
#account__close_account .itemdetail-row .goods-text,
#account__view_order_history .itemdetail-row .goods-text,
#account__view_orders .itemdetail-row .goods-text,
#account__order .itemdetail-row .goods-text,
#account__view_orders .itemdetail-row .goods-text,
#order__payment_status .itemdetail-row .goods-text {
  padding: 0 0 0 26px;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .itemdetail-row .goods-text,
  #account__close_account .itemdetail-row .goods-text,
  #account__view_order_history .itemdetail-row .goods-text,
  #account__view_orders .itemdetail-row .goods-text,
  #account__order .itemdetail-row .goods-text,
  #account__view_orders .itemdetail-row .goods-text,
  #order__payment_status .itemdetail-row .goods-text {
    padding: 0 0 0 14px;
  }
}
#account__order_inquiry .itemdetail-row .goods-text .goods-text-heading a,
#account__order_inquiry .itemdetail-row .goods-text .item-name,
#account__close_account .itemdetail-row .goods-text .goods-text-heading a,
#account__close_account .itemdetail-row .goods-text .item-name,
#account__view_order_history .itemdetail-row .goods-text .goods-text-heading a,
#account__view_order_history .itemdetail-row .goods-text .item-name,
#account__view_orders .itemdetail-row .goods-text .goods-text-heading a,
#account__view_orders .itemdetail-row .goods-text .item-name,
#account__order .itemdetail-row .goods-text .goods-text-heading a,
#account__order .itemdetail-row .goods-text .item-name,
#account__view_orders .itemdetail-row .goods-text .goods-text-heading a,
#account__view_orders .itemdetail-row .goods-text .item-name,
#order__payment_status .itemdetail-row .goods-text .goods-text-heading a,
#order__payment_status .itemdetail-row .goods-text .item-name {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}
#account__order_inquiry .goods-text-heading .unit-price,
#account__close_account .goods-text-heading .unit-price,
#account__view_order_history .goods-text-heading .unit-price,
#account__view_orders .goods-text-heading .unit-price,
#account__order .goods-text-heading .unit-price,
#account__view_orders .goods-text-heading .unit-price,
#order__payment_status .goods-text-heading .unit-price {
  display: none;
}
#account__order_inquiry .purchase-contents,
#account__close_account .purchase-contents,
#account__view_order_history .purchase-contents,
#account__view_orders .purchase-contents,
#account__order .purchase-contents,
#account__view_orders .purchase-contents,
#order__payment_status .purchase-contents {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 34px;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .purchase-contents,
  #account__close_account .purchase-contents,
  #account__view_order_history .purchase-contents,
  #account__view_orders .purchase-contents,
  #account__order .purchase-contents,
  #account__view_orders .purchase-contents,
  #order__payment_status .purchase-contents {
    padding-left: 164px;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
  }
}
#account__order_inquiry .quantity,
#account__order_inquiry .subtotal,
#account__close_account .quantity,
#account__close_account .subtotal,
#account__view_order_history .quantity,
#account__view_order_history .subtotal,
#account__view_orders .quantity,
#account__view_orders .subtotal,
#account__order .quantity,
#account__order .subtotal,
#account__view_orders .quantity,
#account__view_orders .subtotal,
#order__payment_status .quantity,
#order__payment_status .subtotal {
  flex: none;
  padding: 0;
}
#account__order_inquiry .quantity,
#account__close_account .quantity,
#account__view_order_history .quantity,
#account__view_orders .quantity,
#account__order .quantity,
#account__view_orders .quantity,
#order__payment_status .quantity {
  font-size: 16px;
  font-weight: 500;
}
#account__order_inquiry .subtotal,
#account__close_account .subtotal,
#account__view_order_history .subtotal,
#account__view_orders .subtotal,
#account__order .subtotal,
#account__view_orders .subtotal,
#order__payment_status .subtotal {
  font-size: 16px;
  font-weight: 700;
}

/*---------------
  未発送の注文、注文詳細、アカウントのクローズ
---------------*/
#account__order_inquiry .view-order .panel-heading,
#account__close_account .view-order .panel-heading,
body#account__order .view-order .panel-heading,
body#account__view_orders .view-order .panel-heading {
  flex-direction: column-reverse;
}
#account__order_inquiry .order-inquiry .panel-heading > div:not(.order-code):not(.shipment-status):not(.shipment-code),
#account__order_inquiry .view-order .panel-heading > div:not(.order-code):not(.shipment-status):not(.shipment-code),
#account__order_inquiry .total-invoice,
#account__order_inquiry .order-date,
#account__order_inquiry .order-status,
#account__close_account .order-inquiry .panel-heading > div:not(.order-code):not(.shipment-status):not(.shipment-code),
#account__close_account .view-order .panel-heading > div:not(.order-code):not(.shipment-status):not(.shipment-code),
#account__close_account .total-invoice,
#account__close_account .order-date,
#account__close_account .order-status,
body#account__order .order-inquiry .panel-heading > div:not(.order-code):not(.shipment-status):not(.shipment-code),
body#account__order .view-order .panel-heading > div:not(.order-code):not(.shipment-status):not(.shipment-code),
body#account__order .total-invoice,
body#account__order .order-date,
body#account__order .order-status,
body#account__view_orders .order-inquiry .panel-heading > div:not(.order-code):not(.shipment-status):not(.shipment-code),
body#account__view_orders .view-order .panel-heading > div:not(.order-code):not(.shipment-status):not(.shipment-code),
body#account__view_orders .total-invoice,
body#account__view_orders .order-date,
body#account__view_orders .order-status {
  padding: 12px 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
#account__order_inquiry .panel-heading > div:not(.order-code) h5,
#account__order_inquiry .total-invoice h5,
#account__order_inquiry .order-date h5,
#account__order_inquiry .order-status h5,
#account__close_account .panel-heading > div:not(.order-code) h5,
#account__close_account .total-invoice h5,
#account__close_account .order-date h5,
#account__close_account .order-status h5,
body#account__order .panel-heading > div:not(.order-code) h5,
body#account__order .total-invoice h5,
body#account__order .order-date h5,
body#account__order .order-status h5,
body#account__view_orders .panel-heading > div:not(.order-code) h5,
body#account__view_orders .total-invoice h5,
body#account__view_orders .order-date h5,
body#account__view_orders .order-status h5 {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  text-align: left;
  width: 115px;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .panel-heading > div:not(.order-code) h5,
  #account__order_inquiry .total-invoice h5,
  #account__order_inquiry .order-date h5,
  #account__order_inquiry .order-status h5,
  #account__close_account .panel-heading > div:not(.order-code) h5,
  #account__close_account .total-invoice h5,
  #account__close_account .order-date h5,
  #account__close_account .order-status h5,
  body#account__order .panel-heading > div:not(.order-code) h5,
  body#account__order .total-invoice h5,
  body#account__order .order-date h5,
  body#account__order .order-status h5,
  body#account__view_orders .panel-heading > div:not(.order-code) h5,
  body#account__view_orders .total-invoice h5,
  body#account__view_orders .order-date h5,
  body#account__view_orders .order-status h5 {
    width: fit-content;
  }
}
#account__order_inquiry .panel-heading > div:not(.order-code) h5 + div,
#account__order_inquiry .total-invoice h5 + div,
#account__order_inquiry .order-date h5 + div,
#account__order_inquiry .order-status h5 + div,
#account__close_account .panel-heading > div:not(.order-code) h5 + div,
#account__close_account .total-invoice h5 + div,
#account__close_account .order-date h5 + div,
#account__close_account .order-status h5 + div,
body#account__order .panel-heading > div:not(.order-code) h5 + div,
body#account__order .total-invoice h5 + div,
body#account__order .order-date h5 + div,
body#account__order .order-status h5 + div,
body#account__view_orders .panel-heading > div:not(.order-code) h5 + div,
body#account__view_orders .total-invoice h5 + div,
body#account__view_orders .order-date h5 + div,
body#account__view_orders .order-status h5 + div {
  font-size: 16px;
  text-align: right;
  width: 150px;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .panel-heading > div:not(.order-code) h5 + div,
  #account__order_inquiry .total-invoice h5 + div,
  #account__order_inquiry .order-date h5 + div,
  #account__order_inquiry .order-status h5 + div,
  #account__close_account .panel-heading > div:not(.order-code) h5 + div,
  #account__close_account .total-invoice h5 + div,
  #account__close_account .order-date h5 + div,
  #account__close_account .order-status h5 + div,
  body#account__order .panel-heading > div:not(.order-code) h5 + div,
  body#account__order .total-invoice h5 + div,
  body#account__order .order-date h5 + div,
  body#account__order .order-status h5 + div,
  body#account__view_orders .panel-heading > div:not(.order-code) h5 + div,
  body#account__view_orders .total-invoice h5 + div,
  body#account__view_orders .order-date h5 + div,
  body#account__view_orders .order-status h5 + div {
    width: fit-content;
  }
}
#account__order_inquiry .panel-heading .order-code,
#account__close_account .panel-heading .order-code,
body#account__order .panel-heading .order-code,
body#account__view_orders .panel-heading .order-code {
  background: #f6f6f6;
  padding: 22px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}
@media all and (max-width : 768px) {
  #account__order_inquiry .panel-heading .order-code,
  #account__close_account .panel-heading .order-code,
  body#account__order .panel-heading .order-code,
  body#account__view_orders .panel-heading .order-code {
    padding: 16px 14px;
  }
}

/*--------------------------------------------------
注文履歴一覧
--------------------------------------------------*/
body#account__view_orders .order-reset-paymentmethod-form {
  padding-block: 20px;
}
body#account__view_orders .order-reset-paymentmethod-form .description {
  padding-top: 20px;
}

/*--------------------------------------------------
  注文詳細
--------------------------------------------------*/
body#account__order_inquiry #outer,
body#account__order #outer {
  padding-bottom: 0;
}
body#account__order_inquiry .order-inquiry .panel-heading,
body#account__order_inquiry .view-order .panel-heading,
body#account__order .order-inquiry .panel-heading,
body#account__order .view-order .panel-heading {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}
body#account__order_inquiry .order-inquiry .panel .panel-heading > div:nth-child(1),
body#account__order_inquiry .view-order .panel .panel-heading > div:nth-child(1),
body#account__order .order-inquiry .panel .panel-heading > div:nth-child(1),
body#account__order .view-order .panel .panel-heading > div:nth-child(1) {
  order: 4;
}
body#account__order_inquiry .order-inquiry .panel .panel-heading > div:nth-child(2),
body#account__order_inquiry .view-order .panel .panel-heading > div:nth-child(2),
body#account__order .order-inquiry .panel .panel-heading > div:nth-child(2),
body#account__order .view-order .panel .panel-heading > div:nth-child(2) {
  order: 3;
}
body#account__order_inquiry .order-inquiry .panel .panel-heading > div:nth-child(3),
body#account__order_inquiry .view-order .panel .panel-heading > div:nth-child(3),
body#account__order .order-inquiry .panel .panel-heading > div:nth-child(3),
body#account__order .view-order .panel .panel-heading > div:nth-child(3) {
  order: 1;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .order-inquiry .panel .panel-heading > div:nth-child(3),
  body#account__order_inquiry .view-order .panel .panel-heading > div:nth-child(3),
  body#account__order .order-inquiry .panel .panel-heading > div:nth-child(3),
  body#account__order .view-order .panel .panel-heading > div:nth-child(3) {
    width: fit-content;
  }
}
body#account__order_inquiry .order-inquiry .panel .panel-heading > div:nth-child(4),
body#account__order_inquiry .view-order .panel .panel-heading > div:nth-child(4),
body#account__order .order-inquiry .panel .panel-heading > div:nth-child(4),
body#account__order .view-order .panel .panel-heading > div:nth-child(4) {
  order: 2;
  width: 190px;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .order-inquiry .panel .panel-heading > div:nth-child(4),
  body#account__order_inquiry .view-order .panel .panel-heading > div:nth-child(4),
  body#account__order .order-inquiry .panel .panel-heading > div:nth-child(4),
  body#account__order .view-order .panel .panel-heading > div:nth-child(4) {
    margin-left: auto;
    width: fit-content;
  }
}
body#account__order_inquiry .order-inquiry .panel-heading > div:nth-child(4):not(.order-code):not(.shipment-status):not(.shipment-code),
body#account__order_inquiry .view-order .panel-heading > div:nth-child(4):not(.order-code):not(.shipment-status):not(.shipment-code),
body#account__order .order-inquiry .panel-heading > div:nth-child(4):not(.order-code):not(.shipment-status):not(.shipment-code),
body#account__order .view-order .panel-heading > div:nth-child(4):not(.order-code):not(.shipment-status):not(.shipment-code) {
  justify-content: flex-end;
}
body#account__order_inquiry .order-inquiry .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt + div),
body#account__order_inquiry .view-order .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt + div),
body#account__order .order-inquiry .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt + div),
body#account__order .view-order .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt + div) {
  width: 100%;
}
@media all and (min-width: 769px) {
  body#account__order_inquiry .order-inquiry .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt + div),
  body#account__order_inquiry .view-order .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt + div),
  body#account__order .order-inquiry .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt + div),
  body#account__order .view-order .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt + div) {
    display: flex;
    justify-content: flex-end;
  }
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .order-inquiry .panel .panel-heading > div,
  body#account__order_inquiry .view-order .panel .panel-heading > div,
  body#account__order .order-inquiry .panel .panel-heading > div,
  body#account__order .view-order .panel .panel-heading > div {
    justify-content: space-between;
  }
}
body#account__order_inquiry .panel-body .request-paymentmethod-reset > h3,
body#account__order .panel-body .request-paymentmethod-reset > h3 {
  background-color: #ffebeb;
  border: 1px solid #c10000;
  color: #c10000;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  text-align: center;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .panel-body .request-paymentmethod-reset > h3,
  body#account__order .panel-body .request-paymentmethod-reset > h3 {
    font-size: 16px;
  }
}
body#account__order_inquiry .order-reset-paymentmethod-form > form,
body#account__order .order-reset-paymentmethod-form > form {
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
body#account__order_inquiry .order-reset-paymentmethod-form > form .description,
body#account__order .order-reset-paymentmethod-form > form .description {
  padding-top: 20px;
  width: 100%;
}
@media all and (min-width: 769px) {
  body#account__order_inquiry .shipment-header a.link-detail,
  body#account__order .shipment-header a.link-detail {
    padding-inline: 16px;
  }
}
body#account__order_inquiry .derived-subscriptions,
body#account__order .derived-subscriptions {
  display: flex;
  justify-content: center;
}
body#account__order_inquiry .derived-subscriptions + div,
body#account__order .derived-subscriptions + div {
  padding-top: 40px;
}
body#account__order_inquiry .derived-subscriptions ol,
body#account__order .derived-subscriptions ol {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .derived-subscriptions ol,
  body#account__order .derived-subscriptions ol {
    flex-direction: column;
    align-items: center;
  }
}
body#account__order_inquiry div.shipment,
body#account__order div.shipment {
  margin-bottom: 0;
}
body#account__order_inquiry #page_aside2 .btn-account,
body#account__order #page_aside2 .btn-account {
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .shipment-total-invoice .amount,
  body#account__order .shipment-total-invoice .amount {
    white-space: normal;
  }
}
body#account__order_inquiry .order .order-buttons,
body#account__order .order .order-buttons {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  gap: 16px;
}
body#account__order_inquiry .order .order-buttons > :first-child,
body#account__order .order .order-buttons > :first-child {
  margin-inline: auto;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .order .order-buttons > :first-child,
  body#account__order .order .order-buttons > :first-child {
    padding-inline: 0;
  }
}
body#account__order_inquiry .applied-coupons ul,
body#account__order .applied-coupons ul {
  padding: 28px 8px 24px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .applied-coupons ul,
  body#account__order .applied-coupons ul {
    gap: 8px 16px;
    padding-top: 20px;
  }
}
body#account__order_inquiry .applied-coupons ul::before,
body#account__order .applied-coupons ul::before {
  content: "適用されたクーポン";
  display: block;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 8px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .applied-coupons ul::before,
  body#account__order .applied-coupons ul::before {
    font-size: 14px;
  }
}
body#account__order_inquiry .applied-coupons ul li,
body#account__order .applied-coupons ul li {
  background-color: #f6f6f6;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #005c69 !important;
  overflow: hidden;
  font-size: 13px;
  font-weight: 500;
  padding: 14px 22px;
  position: relative;
  text-align: center;
  min-width: 163px;
  width: fit-content;
}
@media all and (max-width: 600px) {
  body#account__order_inquiry .applied-coupons ul li,
  body#account__order .applied-coupons ul li {
    width: calc(50% - 8px);
  }
}
body#account__order_inquiry .applied-coupons ul li::before,
body#account__order .applied-coupons ul li::before {
  background: #c10000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  content: "";
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  padding: 4px 0;
  text-align: center;
  width: 50px;
  z-index: 1;
  position: absolute;
  left: -14px;
  top: 8px;
  transform: rotate(-45deg);
}
body#account__order_inquiry .applied-coupons ul li::after,
body#account__order .applied-coupons ul li::after {
  border: 1px solid #005c69;
  border-radius: 2px;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  inset: 4px;
}

/*--------------------------------------------------
  注文詳細、お支払いの状態
--------------------------------------------------*/
body#account__order_inquiry .order-shipments,
body#account__view_partwork_subscription .order-shipments,
body#order__payment_status .order-shipments,
body#account__order .order-shipments {
  padding-top: 20px;
}
body#account__order_inquiry .form-reset-paymentmethod,
body#account__view_partwork_subscription .form-reset-paymentmethod,
body#order__payment_status .form-reset-paymentmethod,
body#account__order .form-reset-paymentmethod {
  padding-top: 10px;
}
body#account__order_inquiry .shipment-valid,
body#account__view_partwork_subscription .shipment-valid,
body#order__payment_status .shipment-valid,
body#account__order .shipment-valid {
  border-top: none;
}
body#account__order_inquiry .form-reset-paymentmethod .description,
body#account__view_partwork_subscription .form-reset-paymentmethod .description,
body#order__payment_status .form-reset-paymentmethod .description,
body#account__order .form-reset-paymentmethod .description {
  padding-top: 10px;
}
body#account__order_inquiry .panel-heading + .panel-body .shipment-header,
body#account__view_partwork_subscription .panel-heading + .panel-body .shipment-header,
body#order__payment_status .panel-heading + .panel-body .shipment-header,
body#account__order .panel-heading + .panel-body .shipment-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .panel-heading + .panel-body .shipment-header,
  body#account__view_partwork_subscription .panel-heading + .panel-body .shipment-header,
  body#order__payment_status .panel-heading + .panel-body .shipment-header,
  body#account__order .panel-heading + .panel-body .shipment-header {
    gap: 8px;
  }
}
body#account__order_inquiry .shipment-header > div,
body#account__view_partwork_subscription .shipment-header > div,
body#order__payment_status .shipment-header > div,
body#account__order .shipment-header > div {
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: none;
  gap: 20px;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .shipment-header > div,
  body#account__view_partwork_subscription .shipment-header > div,
  body#order__payment_status .shipment-header > div,
  body#account__order .shipment-header > div {
    gap: 8px;
    flex-wrap: wrap;
    flex: none;
  }
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .shipment-header > .payments,
  body#account__view_partwork_subscription .shipment-header > .payments,
  body#order__payment_status .shipment-header > .payments,
  body#account__order .shipment-header > .payments {
    width: 100%;
    flex: auto;
    justify-content: space-between;
  }
}
body#account__order_inquiry .shipment-header h5,
body#account__view_partwork_subscription .shipment-header h5,
body#order__payment_status .shipment-header h5,
body#account__order .shipment-header h5 {
  font-size: 16px;
  padding: 0;
}
body#account__order_inquiry .shipment-code,
body#account__view_partwork_subscription .shipment-code,
body#order__payment_status .shipment-code,
body#account__order .shipment-code {
  margin: 0;
  gap: 20px;
  justify-content: flex-start;
}
body#account__order_inquiry .shipment-code span::before,
body#account__view_partwork_subscription .shipment-code span::before,
body#order__payment_status .shipment-code span::before,
body#account__order .shipment-code span::before {
  display: none;
}
body#account__order_inquiry .shipment-header .shipment-status,
body#account__view_partwork_subscription .shipment-header .shipment-status,
body#order__payment_status .shipment-header .shipment-status,
body#account__order .shipment-header .shipment-status {
  margin-left: auto;
}
body#account__order_inquiry .shipment-status h5,
body#account__view_partwork_subscription .shipment-status h5,
body#order__payment_status .shipment-status h5,
body#account__order .shipment-status h5 {
  display: none;
}
body#account__order_inquiry .shipment-header .link-detail,
body#account__view_partwork_subscription .shipment-header .link-detail,
body#order__payment_status .shipment-header .link-detail,
body#account__order .shipment-header .link-detail {
  background: #1d1d1b;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  padding: 2px 6px 3px;
}
body#account__order_inquiry .panel .wrapper,
body#account__order_inquiry .shipment-delivery-address,
body#account__view_partwork_subscription .panel .wrapper,
body#account__view_partwork_subscription .shipment-delivery-address,
body#order__payment_status .panel .wrapper,
body#order__payment_status .shipment-delivery-address,
body#account__order .panel .wrapper,
body#account__order .shipment-delivery-address {
  padding-block: 30px 0;
  display: flex;
  gap: 30px;
}
@media all and (max-width: 900px) {
  body#account__order_inquiry .panel .wrapper,
  body#account__order_inquiry .shipment-delivery-address,
  body#account__view_partwork_subscription .panel .wrapper,
  body#account__view_partwork_subscription .shipment-delivery-address,
  body#order__payment_status .panel .wrapper,
  body#order__payment_status .shipment-delivery-address,
  body#account__order .panel .wrapper,
  body#account__order .shipment-delivery-address {
    flex-direction: column;
    align-items: flex-start;
  }
}
body#account__order_inquiry .panel .wrapper > div,
body#account__order_inquiry .shipment-delivery-address .person,
body#account__view_partwork_subscription .panel .wrapper > div,
body#account__view_partwork_subscription .shipment-delivery-address .person,
body#order__payment_status .panel .wrapper > div,
body#order__payment_status .shipment-delivery-address .person,
body#account__order .panel .wrapper > div,
body#account__order .shipment-delivery-address .person {
  flex: auto;
  margin: 0;
  width: calc((100% - 30px) / 2);
}
@media all and (max-width: 900px) {
  body#account__order_inquiry .panel .wrapper > div,
  body#account__order_inquiry .shipment-delivery-address .person,
  body#account__view_partwork_subscription .panel .wrapper > div,
  body#account__view_partwork_subscription .shipment-delivery-address .person,
  body#order__payment_status .panel .wrapper > div,
  body#order__payment_status .shipment-delivery-address .person,
  body#account__order .panel .wrapper > div,
  body#account__order .shipment-delivery-address .person {
    width: 100%;
  }
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .panel .wrapper > div,
  body#account__order_inquiry .shipment-delivery-address .person,
  body#account__view_partwork_subscription .panel .wrapper > div,
  body#account__view_partwork_subscription .shipment-delivery-address .person,
  body#order__payment_status .panel .wrapper > div,
  body#order__payment_status .shipment-delivery-address .person,
  body#account__order .panel .wrapper > div,
  body#account__order .shipment-delivery-address .person {
    width: 100%;
  }
}
body#account__order_inquiry .order-buttons-shipment,
body#account__view_partwork_subscription .order-buttons-shipment,
body#order__payment_status .order-buttons-shipment,
body#account__order .order-buttons-shipment {
  display: flex;
}
body#account__order_inquiry .order-buttons > .shipment-cancel,
body#account__view_partwork_subscription .order-buttons > .shipment-cancel,
body#order__payment_status .order-buttons > .shipment-cancel,
body#account__order .order-buttons > .shipment-cancel {
  margin-inline: auto;
}
body#account__order_inquiry .order-buttons > .shipment-cancel form,
body#account__view_partwork_subscription .order-buttons > .shipment-cancel form,
body#order__payment_status .order-buttons > .shipment-cancel form,
body#account__order .order-buttons > .shipment-cancel form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
body#account__order_inquiry .panel .wrapper > div,
body#account__view_partwork_subscription .panel .wrapper > div,
body#order__payment_status .panel .wrapper > div,
body#account__order .panel .wrapper > div {
  border: 1px dotted #ccc;
}
body#account__order_inquiry .panel .wrapper h4,
body#account__view_partwork_subscription .panel .wrapper h4,
body#order__payment_status .panel .wrapper h4,
body#account__order .panel .wrapper h4 {
  font-weight: 700;
  border-bottom: 1px dotted #ccc;
  background-color: #f5f5f5;
  padding: 0.5em;
}
body#account__order_inquiry .panel .wrapper > div > div,
body#account__view_partwork_subscription .panel .wrapper > div > div,
body#order__payment_status .panel .wrapper > div > div,
body#account__order .panel .wrapper > div > div {
  padding: 0.5em;
}
body#account__order_inquiry .panel .panel-footer .note,
body#account__view_partwork_subscription .panel .panel-footer .note,
body#order__payment_status .panel .panel-footer .note,
body#account__order .panel .panel-footer .note {
  color: #c10000;
  font-size: 14px;
}
body#account__order_inquiry .panel .btn-group,
body#account__view_partwork_subscription .panel .btn-group,
body#order__payment_status .panel .btn-group,
body#account__order .panel .btn-group {
  margin-top: 10px;
  padding: 0;
}
body#account__order_inquiry .shipment-contents,
body#account__view_partwork_subscription .shipment-contents,
body#order__payment_status .shipment-contents,
body#account__order .shipment-contents {
  margin-top: 30px;
}
body#account__order_inquiry .delivery,
body#account__view_partwork_subscription .delivery,
body#order__payment_status .delivery,
body#account__order .delivery {
  flex-direction: column;
  gap: 30px;
}
body#account__order_inquiry .shipment-contents .delivery > .delivery-request,
body#account__order_inquiry .shipment-contents .delivery > .delivery-info,
body#account__view_partwork_subscription .shipment-contents .delivery > .delivery-request,
body#account__view_partwork_subscription .shipment-contents .delivery > .delivery-info,
body#order__payment_status .shipment-contents .delivery > .delivery-request,
body#order__payment_status .shipment-contents .delivery > .delivery-info,
body#account__order .shipment-contents .delivery > .delivery-request,
body#account__order .shipment-contents .delivery > .delivery-info {
  border: 1px solid #d9d9d9;
  margin: 0;
  padding: 0;
}
@media all and (max-width: 900px) {
  body#account__order_inquiry .shipment-contents .delivery > .delivery-request,
  body#account__order_inquiry .shipment-contents .delivery > .delivery-info,
  body#account__view_partwork_subscription .shipment-contents .delivery > .delivery-request,
  body#account__view_partwork_subscription .shipment-contents .delivery > .delivery-info,
  body#order__payment_status .shipment-contents .delivery > .delivery-request,
  body#order__payment_status .shipment-contents .delivery > .delivery-info,
  body#account__order .shipment-contents .delivery > .delivery-request,
  body#account__order .shipment-contents .delivery > .delivery-info {
    width: 100%;
  }
}
body#account__order_inquiry .shipment-contents .delivery table,
body#account__view_partwork_subscription .shipment-contents .delivery table,
body#order__payment_status .shipment-contents .delivery table,
body#account__order .shipment-contents .delivery table {
  width: 100%;
}
body#account__order_inquiry .panel caption,
body#account__view_partwork_subscription .panel caption,
body#order__payment_status .panel caption,
body#account__order .panel caption {
  background: #f6f6f6;
  padding: 13px 10px 14px 20px;
  text-align: left;
}
body#account__order_inquiry .delivery-info table,
body#account__view_partwork_subscription .delivery-info table,
body#order__payment_status .delivery-info table,
body#account__order .delivery-info table {
  padding-bottom: 20px;
}
body#account__order_inquiry .shipment-contents .delivery table tbody,
body#account__view_partwork_subscription .shipment-contents .delivery table tbody,
body#order__payment_status .shipment-contents .delivery table tbody,
body#account__order .shipment-contents .delivery table tbody {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
body#account__order_inquiry .shipment-contents .delivery table tr,
body#account__view_partwork_subscription .shipment-contents .delivery table tr,
body#order__payment_status .shipment-contents .delivery table tr,
body#account__order .shipment-contents .delivery table tr {
  display: flex;
  align-items: center;
}
body#account__order_inquiry .shipment-contents .delivery table th,
body#account__order_inquiry .shipment-contents .delivery table td,
body#account__view_partwork_subscription .shipment-contents .delivery table th,
body#account__view_partwork_subscription .shipment-contents .delivery table td,
body#order__payment_status .shipment-contents .delivery table th,
body#order__payment_status .shipment-contents .delivery table td,
body#account__order .shipment-contents .delivery table th,
body#account__order .shipment-contents .delivery table td {
  border: none;
  font-size: 16px;
  padding: 0;
}
body#account__order_inquiry .shipment-contents .delivery table th,
body#account__view_partwork_subscription .shipment-contents .delivery table th,
body#order__payment_status .shipment-contents .delivery table th,
body#account__order .shipment-contents .delivery table th {
  background: transparent;
  font-weight: 500;
  min-width: 135px;
  max-width: 150px;
  width: 100%;
}
body#account__order_inquiry .order-items h4,
body#account__order_inquiry .order-items .itemdetails-header,
body#account__view_partwork_subscription .order-items h4,
body#account__view_partwork_subscription .order-items .itemdetails-header,
body#order__payment_status .order-items h4,
body#order__payment_status .order-items .itemdetails-header,
body#account__order .order-items h4,
body#account__order .order-items .itemdetails-header {
  display: none;
}
body#account__order_inquiry .itemdetail-row .item,
body#account__view_partwork_subscription .itemdetail-row .item,
body#order__payment_status .itemdetail-row .item,
body#account__order .itemdetail-row .item {
  flex: auto;
}
body#account__order_inquiry .itemdetails-body .itemdetail-row .purchase-contents,
body#account__view_partwork_subscription .itemdetails-body .itemdetail-row .purchase-contents,
body#order__payment_status .itemdetails-body .itemdetail-row .purchase-contents,
body#account__order .itemdetails-body .itemdetail-row .purchase-contents {
  align-items: flex-end;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .itemdetail-row .item .goods,
  body#account__view_partwork_subscription .itemdetail-row .item .goods,
  body#order__payment_status .itemdetail-row .item .goods,
  body#account__order .itemdetail-row .item .goods {
    flex-direction: row;
  }
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .itemdetails-body .itemdetail-row .purchase-contents,
  body#account__view_partwork_subscription .itemdetails-body .itemdetail-row .purchase-contents,
  body#order__payment_status .itemdetails-body .itemdetail-row .purchase-contents,
  body#account__order .itemdetails-body .itemdetail-row .purchase-contents {
    align-items: flex-start;
  }
}
body#account__order_inquiry .shipment-total-invoice,
body#account__order_inquiry #order-total,
body#account__view_partwork_subscription .shipment-total-invoice,
body#account__view_partwork_subscription #order-total,
body#order__payment_status .shipment-total-invoice,
body#order__payment_status #order-total,
body#account__order .shipment-total-invoice,
body#account__order #order-total {
  max-width: 500px;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .shipment-total-invoice,
  body#account__order_inquiry #order-total,
  body#account__view_partwork_subscription .shipment-total-invoice,
  body#account__view_partwork_subscription #order-total,
  body#order__payment_status .shipment-total-invoice,
  body#order__payment_status #order-total,
  body#account__order .shipment-total-invoice,
  body#account__order #order-total {
    max-width: 100%;
  }
}
body#account__order_inquiry .shipment-total-invoice td,
body#account__order_inquiry #order-total td,
body#account__view_partwork_subscription .shipment-total-invoice td,
body#account__view_partwork_subscription #order-total td,
body#order__payment_status .shipment-total-invoice td,
body#order__payment_status #order-total td,
body#account__order .shipment-total-invoice td,
body#account__order #order-total td {
  min-width: 140px;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .shipment-total-invoice td,
  body#account__order_inquiry #order-total td,
  body#account__view_partwork_subscription .shipment-total-invoice td,
  body#account__view_partwork_subscription #order-total td,
  body#order__payment_status .shipment-total-invoice td,
  body#order__payment_status #order-total td,
  body#account__order .shipment-total-invoice td,
  body#account__order #order-total td {
    min-width: fit-content;
  }
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .shipment-total-invoice td a,
  body#account__order_inquiry #order-total td a,
  body#account__view_partwork_subscription .shipment-total-invoice td a,
  body#account__view_partwork_subscription #order-total td a,
  body#order__payment_status .shipment-total-invoice td a,
  body#order__payment_status #order-total td a,
  body#account__order .shipment-total-invoice td a,
  body#account__order #order-total td a {
    white-space: nowrap;
  }
}
body#account__order_inquiry .shipment-total-invoice th,
body#account__order_inquiry .shipment-total-invoice td,
body#account__order_inquiry #order-total th,
body#account__order_inquiry #order-total td,
body#account__view_partwork_subscription .shipment-total-invoice th,
body#account__view_partwork_subscription .shipment-total-invoice td,
body#account__view_partwork_subscription #order-total th,
body#account__view_partwork_subscription #order-total td,
body#order__payment_status .shipment-total-invoice th,
body#order__payment_status .shipment-total-invoice td,
body#order__payment_status #order-total th,
body#order__payment_status #order-total td,
body#account__order .shipment-total-invoice th,
body#account__order .shipment-total-invoice td,
body#account__order #order-total th,
body#account__order #order-total td {
  font-size: 14px;
  border: none;
}
body#account__order_inquiry .shipment-total-invoice tr,
body#account__order_inquiry #order-total tr,
body#account__view_partwork_subscription .shipment-total-invoice tr,
body#account__view_partwork_subscription #order-total tr,
body#order__payment_status .shipment-total-invoice tr,
body#order__payment_status #order-total tr,
body#account__order .shipment-total-invoice tr,
body#account__order #order-total tr {
  border-bottom: 1px solid #d9d9d9;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .shipment-total-invoice tr,
  body#account__order_inquiry #order-total tr,
  body#account__view_partwork_subscription .shipment-total-invoice tr,
  body#account__view_partwork_subscription #order-total tr,
  body#order__payment_status .shipment-total-invoice tr,
  body#order__payment_status #order-total tr,
  body#account__order .shipment-total-invoice tr,
  body#account__order #order-total tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .shipment-total-invoice tr.service-extra th,
  body#account__order_inquiry #order-total tr.service-extra th,
  body#account__view_partwork_subscription .shipment-total-invoice tr.service-extra th,
  body#account__view_partwork_subscription #order-total tr.service-extra th,
  body#order__payment_status .shipment-total-invoice tr.service-extra th,
  body#order__payment_status #order-total tr.service-extra th,
  body#account__order .shipment-total-invoice tr.service-extra th,
  body#account__order #order-total tr.service-extra th {
    padding-block: 7px;
    display: flex;
    flex-direction: column;
  }
}
body#account__order_inquiry .shipment-total-invoice .total,
body#account__order_inquiry #order-total .total,
body#account__view_partwork_subscription .shipment-total-invoice .total,
body#account__view_partwork_subscription #order-total .total,
body#order__payment_status .shipment-total-invoice .total,
body#order__payment_status #order-total .total,
body#account__order .shipment-total-invoice .total,
body#account__order #order-total .total {
  border-bottom: 2px solid #d9d9d9;
}
body#account__order_inquiry .shipment-total-invoice .total th,
body#account__order_inquiry .shipment-total-invoice .total td,
body#account__order_inquiry #order-total .total th,
body#account__order_inquiry #order-total .total td,
body#account__view_partwork_subscription .shipment-total-invoice .total th,
body#account__view_partwork_subscription .shipment-total-invoice .total td,
body#account__view_partwork_subscription #order-total .total th,
body#account__view_partwork_subscription #order-total .total td,
body#order__payment_status .shipment-total-invoice .total th,
body#order__payment_status .shipment-total-invoice .total td,
body#order__payment_status #order-total .total th,
body#order__payment_status #order-total .total td,
body#account__order .shipment-total-invoice .total th,
body#account__order .shipment-total-invoice .total td,
body#account__order #order-total .total th,
body#account__order #order-total .total td {
  color: #333;
  font-size: 24px;
  font-weight: 700;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .shipment-total-invoice .amount,
  body#account__order_inquiry #order-total .amount,
  body#account__view_partwork_subscription .shipment-total-invoice .amount,
  body#account__view_partwork_subscription #order-total .amount,
  body#order__payment_status .shipment-total-invoice .amount,
  body#order__payment_status #order-total .amount,
  body#account__order .shipment-total-invoice .amount,
  body#account__order #order-total .amount {
    white-space: nowrap;
  }
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .amount-table,
  body#account__view_partwork_subscription .amount-table,
  body#order__payment_status .amount-table,
  body#account__order .amount-table {
    padding: 8px 0;
  }
}
body#account__order_inquiry .ln-order-inq,
body#account__view_partwork_subscription .ln-order-inq,
body#order__payment_status .ln-order-inq,
body#account__order .ln-order-inq {
  display: flex;
  justify-content: center;
}
body#account__order_inquiry .total-item-amount .qty,
body#account__view_partwork_subscription .total-item-amount .qty,
body#order__payment_status .total-item-amount .qty,
body#account__order .total-item-amount .qty {
  display: none;
}

/*---------------
  仮パスワードの発行
---------------*/
#entry_password_reminder .submit-note {
  margin-top: 20px;
}
#entry_password_reminder #reminder-done {
  padding-top: 100px;
  display: grid;
  place-items: center;
}
#entry_password_reminder #reminder-done .msg-head {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
#entry_password_reminder #reminder-done .msg-body {
  margin-top: 40px;
  text-align: center;
}
#entry_password_reminder #reminder-done .btn-submit {
  padding-block: 80px 0;
}
#entry_password_reminder #reminder-done .btn-submit a {
  display: grid;
  place-items: center;
}

ol.issues span.price.with-special span.special-price {
  color: #c10000;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  マイページトップ
  ---------------
*/
body#account__account .account-menus {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 70px;
}
@media all and (max-width : 768px) {
  body#account__account .account-menus {
    gap: 40px;
    padding-block: 32px 50px;
  }
}

body#account__account h2.account-title {
  font-size: 24px;
  font-weight: 500;
  margin-top: 40px;
}

body#account__account .account-summary {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  body#account__account .account-summary {
    flex-direction: column;
    margin-top: 16px;
  }
}

body#account__account .account-summary__name {
  font-weight: 500;
}

body#account__account .account-summary__code {
  font-size: 14px;
}

body#account__account .account-summary__nc-box {
  padding: 15px 12px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media all and (max-width: 1024px) and (min-width: 769px) {
  body#account__account .account-summary__nc-box {
    width: 50%;
  }
}
@media all and (max-width : 768px) {
  body#account__account .account-summary__nc-box {
    padding: 8px;
    width: 100%;
  }
}

body#account__account .account-summary__nc-box:has(+ .account-summary__point-box) {
  border-right: 1px solid #d9d9d9;
}
@media all and (max-width : 768px) {
  body#account__account .account-summary__nc-box:has(+ .account-summary__point-box) {
    border-right: none;
  }
}

body#account__account .account-summary__point-box {
  padding: 4px 12px 12px 12px;
  width: 40%;
}
@media all and (max-width: 1024px) and (min-width: 769px) {
  body#account__account .account-summary__point-box {
    width: 50%;
  }
}
@media all and (max-width : 768px) {
  body#account__account .account-summary__point-box {
    border-top: 1px solid #d9d9d9;
    padding: 2px 8px 8px 8px;
    width: 100%;
  }
}

body#account__account .account-summary__haspoint {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

body#account__account .account-summary__haspoint-amount {
  color: #005c69;
  font-weight: 500;
}
body#account__account .account-summary__haspoint-amount .point-amount {
  font-size: 24px;
  margin-right: 4px;
}

body#account__account .account-summary__haspoint-label,
body#account__account .account-summary__expoint-label {
  font-weight: 500;
}

body#account__account .account-summary__expirypoint {
  display: flex;
  justify-content: space-between;
}

body#account__account .account-summary__expoint-date {
  color: #005c69;
  font-weight: 500;
}

body#account__account .account-summary__what-point {
  display: inline-block;
  font-size: 11px;
  text-align: right;
  text-decoration: underline;
  margin-left: 4px;
}

body#account__account .account-summary__what-point::after {
  content: url(/f/resources/images/common/icon_link_othertab_green.svg);
  display: inline-block;
  margin-left: 2px;
  transform: translateY(2px);
}

@media all and (max-width : 768px) {
  body#account__account .am-account {
    padding-bottom: 10px;
  }
}

body#account__account .account-menu-list {
  padding-top: 20px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media all and (max-width: 1000px) {
  body#account__account .account-menu-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media all and (max-width : 768px) {
  body#account__account .account-menu-list {
    gap: 10px;
  }
}
@media all and (max-width: 600px) {
  body#account__account .account-menu-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

body#account__account .account-menu-list li {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
  padding: 20px 25px 20px 120px;
  position: relative;
  min-height: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}
@media (hover: hover) and (pointer: fine) {
  body#account__account .account-menu-list li {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#account__account .account-menu-list li:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#account__account .account-menu-list li::before, body#account__account .account-menu-list li::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  body#account__account .account-menu-list li {
    gap: 0;
    min-height: 70px;
    padding: 10px 12px 10px 70px;
  }
}

body#account__account .account-menu-list li::before {
  background: url() 50%/contain no-repeat;
  content: "";
  display: block;
  height: 60px;
  width: 80px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  body#account__account .account-menu-list li::before {
    left: 10px;
    height: 35px;
    width: 45px;
  }
}

body#account__account li.menu-ViewOrderHistory::before {
  background-image: url(/f/resources/images/account/icon_truck.svg);
}

body#account__account li.menu-ViewPartworkSubs::before {
  background-image: url(/f/resources/images/account/icon_book.svg);
}

body#account__account li.menu-EmailPassword::before {
  background-image: url(/f/resources/images/account/icon_lock.svg);
}

body#account__account li.menu-EditAccountProperties::before {
  background-image: url(/f/resources/images/account/icon_clip-board.svg);
}

body#account__account li.menu-ManageAddressBook::before {
  background-image: url(/f/resources/images/account/icon_house.svg);
}
@media all and (min-width: 769px) {
  body#account__account li.menu-ManageAddressBook::before {
    width: 70px;
  }
}

body#account__account li.menu-ManageDefaultPaymentMethod::before {
  background-image: url(/f/resources/images/account/icon_payment.svg);
}

body#account__account li.menu-Reminder::before {
  background-image: url(/f/resources/images/account/icon_lock-change.svg);
}

body#account__account li.menu-Game::before {
  background-image: url(/f/resources/images/account/icon_darts.svg);
}

body#account__account li.menu-DigitalContents::before {
  background-image: url(/f/resources/images/account/icon_device.svg);
}

body#account__account li.menu-Profile::before {
  background-image: url(/f/resources/images/account/icon_profile.svg);
}
@media all and (min-width: 769px) {
  body#account__account li.menu-Profile::before {
    width: 70px;
  }
}

body#account__account li.menu-YourCommunities::before {
  background-image: url(/f/resources/images/account/icon_chat.svg);
}
@media all and (min-width: 769px) {
  body#account__account li.menu-YourCommunities::before {
    height: 50px;
  }
}

body#account__account li.menu-Points::before {
  background-image: url(/f/resources/images/account/icon_point.svg);
}

body#account__account li.menu-ViewInquiriesHistory::before {
  background-image: url(/f/resources/images/account/icon_letter.svg);
}

body#account__account li.menu-ViewCoupons::before {
  background-image: url(/f/resources/images/account/icon_coupon.svg);
}

body#account__account li.menu-Signout,
body#account__account li.menu-CloseAccount {
  border: none;
  background: transparent;
  padding: 0;
  height: 20px;
  min-height: 20px;
  width: max-content;
  position: absolute;
  bottom: -20px;
}
@media all and (max-width : 768px) {
  body#account__account li.menu-Signout,
  body#account__account li.menu-CloseAccount {
    bottom: -30px;
  }
}

body#account__account li.menu-Signout .note,
body#account__account li.menu-CloseAccount .note {
  display: none;
}

body#account__account li.menu-Signout {
  right: 0;
}

body#account__account li.menu-Signout::before {
  display: none;
}

body#account__account .menu-Signout a::before {
  background: url(/f/resources/images/common/icon_logout.svg) 50%/contain no-repeat;
  height: 14px;
  width: 14px;
  left: -20px;
}

body#account__account .account-menu-list li.menu-Signout a,
body#account__account .account-menu-list li.menu-CloseAccount a {
  font-size: 14px;
}

body#account__account .account-menu-list li.menu-Signout a::before,
body#account__account .account-menu-list li.menu-CloseAccount a::before {
  content: "";
  display: block;
  position: absolute;
  top: 56%;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  body#account__account .account-menu-list li.menu-Signout a::before,
  body#account__account .account-menu-list li.menu-CloseAccount a::before {
    top: 50%;
  }
}

body#account__account .menu-CloseAccount {
  right: 30px;
}

body#account__account .menu-CloseAccount a::before {
  background: url(/f/resources/images/common/icon_arrow_right_circle_green.svg) 50%/contain no-repeat;
  right: -30px;
  height: 20px;
  width: 20px;
}

body#account__account .account-menu-list li a {
  color: #005c69;
  font-size: 18px;
  font-weight: 500;
}

body#account__account .account-menu-list li:not(.menu-Signout) a::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
}

body#account__account .account-menu-list li .note {
  font-size: 14px;
  margin: 0;
}
@media all and (max-width : 768px) {
  body#account__account .account-menu-list li .note {
    font-size: 15px;
  }
}

body#account__account li.menu-blank {
  border: none;
  background: transparent;
}
@media all and (max-width: 1270px) {
  body#account__account li.menu-blank {
    display: none;
  }
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  アドレス帳一覧・編集
  ---------------
*/
body#account__manage_addressbook #page-title + .btn-group {
  padding-top: 40px;
}
body#account__manage_addressbook .btn-group {
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
body#account__manage_addressbook .btn-group > div {
  padding: 0;
}
body#account__manage_addressbook .btn-group > div:nth-child(3) {
  position: absolute;
  top: 0;
  right: 0;
}
body#account__manage_addressbook .panel-footer .btn-group {
  justify-content: flex-end;
}
@media all and (max-width : 768px) {
  body#account__manage_addressbook .panel-footer .btn-group {
    justify-content: center;
  }
}
body#account__manage_addressbook .panel-list {
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
@media all and (max-width : 768px) {
  body#account__manage_addressbook .panel-list {
    flex-direction: column;
  }
}
body#account__manage_addressbook .panel-list li {
  border: 1px solid #d9d9d9;
  display: flex;
  padding: 16px;
  width: calc((100% - 30px) / 2);
}
@media all and (max-width : 768px) {
  body#account__manage_addressbook .panel-list li {
    width: 100%;
  }
}
body#account__manage_addressbook .panel {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}
body#account__manage_addressbook .panel-body {
  margin-bottom: 0;
}
body#account__manage_addressbook .person-contents {
  padding: 0;
}
body#account__manage_addressbook .names {
  padding-right: 175px;
}
@media all and (max-width : 768px) {
  body#account__manage_addressbook .addresses {
    margin-top: 8px;
  }
}

body#account__edit_address div.form fieldset {
  border-top: 0;
  padding: 0;
  margin: 0;
}
body#account__edit_address .form-body .input-group-address {
  margin-top: 40px;
}
body#account__edit_address fieldset + fieldset {
  margin-top: 0;
}
body#account__edit_address fieldset .formrow-checkbox {
  background: #f6f6f6;
  border-top: 1px solid #d9d9d9;
  padding: 40px;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  アカウントのクローズ
  ---------------
*/
body#account__close_account .form-form-CLOSE-ACCOUNT {
  padding-top: 200px;
}
body#account__close_account .form-form-CLOSE-ACCOUNT h3 {
  font-size: 20px;
  text-align: center;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  クーポン一覧
  ---------------
*/
body#account__your_coupons .yourcoupons {
  padding: 0;
}
body#account__your_coupons .yourcoupon-list {
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 20px;
}
@media all and (max-width : 768px) {
  body#account__your_coupons .yourcoupon-list {
    flex-direction: column;
  }
}
body#account__your_coupons .coupon {
  background-color: #f6f6f6;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  width: calc((100% - 40px) / 3);
  overflow: hidden;
  padding: 10px;
  position: relative;
}
@media all and (max-width: 1024px) and (min-width: 769px) {
  body#account__your_coupons .coupon {
    width: calc((100% - 20px) / 2);
  }
}
@media all and (max-width : 768px) {
  body#account__your_coupons .coupon {
    width: 100%;
  }
}
body#account__your_coupons .coupon::before {
  content: "";
  display: inline-block;
  position: absolute;
  padding: 8px 0;
  left: -23px;
  top: 22px;
  width: 100px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  background: #c10000;
  color: #fff;
  letter-spacing: 0.05em;
  transform: rotate(-45deg);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
body#account__your_coupons .coupon::after {
  content: "DEAGOSTINI \a COUPON";
  color: #005c69;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  white-space: pre;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%) rotate(-90deg);
}
body#account__your_coupons .coupon-props {
  border-radius: 2px;
  border: 1px solid #005c69;
  padding: 20px 60px 8px 8px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
}
body#account__your_coupons .coupon-props::before {
  content: "";
  background-image: linear-gradient(to bottom, #005c69, #005c69 2px, transparent 2px, transparent 6px);
  background-size: 1px 6px;
  background-position: right top;
  background-repeat: repeat-y;
  width: 1px;
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 60px;
}
body#account__your_coupons .label {
  padding: 0;
}
body#account__your_coupons .code {
  background: transparent;
  color: #333;
  display: block;
  font-size: 10px;
  font-weight: normal;
  padding: 0;
}
body#account__your_coupons .name {
  border-bottom: 1px solid #005c69;
  color: #005c69;
  display: block;
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto;
  padding: 2px 0;
  text-align: center;
  width: 80%;
}
body#account__your_coupons .expiry {
  color: #333;
  padding: 0;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
body#account__your_coupons .coupon-props > .description {
  color: #333;
  cursor: pointer;
  font-size: 11px;
  height: 16px;
  margin-top: 4px;
  padding-top: 12px;
  overflow-y: hidden;
  position: relative;
  line-height: 2;
}
body#account__your_coupons .coupon-props > .description:hover {
  opacity: 0.8;
}
body#account__your_coupons .coupon-props > .description:not(:empty)::before {
  content: url(/f/resources/images/common/icon_help_sp.svg);
  display: block;
  margin-top: 3px;
  position: absolute;
  top: -4px;
  left: 31%;
  transform: translateX(-100%) scale(0.9);
}
body#account__your_coupons .coupon-props > .description:not(:empty)::after {
  content: "利用上の注意事項はこちら";
  display: block;
  position: absolute;
  top: -4px;
  right: 50%;
  transform: translateX(60%);
}
body#account__your_coupons .coupon-props > .description:empty {
  pointer-events: none;
}
body#account__your_coupons .text-panel {
  background-color: rgba(29, 29, 27, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}
body#account__your_coupons .text-panel .text-panel__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 90vw;
}
@media all and (max-width : 768px) {
  body#account__your_coupons .text-panel .text-panel__inner {
    height: auto;
    max-height: 70vh;
  }
}
body#account__your_coupons .text-panel .text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  border: 1px solid #005c69;
  border-radius: 2px;
  max-height: 900px;
  max-width: 900px;
  padding: 60px;
  height: auto;
  width: 100%;
}
@media all and (max-width : 768px) {
  body#account__your_coupons .text-panel .text-wrapper {
    overflow-y: scroll;
    padding: 62px 10px 40px;
  }
}
body#account__your_coupons .text-panel .text-panel__closer {
  cursor: pointer;
  height: 60px;
  width: 60px;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
}
body#account__your_coupons .text-panel__closer::before,
body#account__your_coupons .text-panel__closer::after {
  content: "";
  display: block;
  background-color: #1d1d1b;
  height: 50px;
  width: 2px;
  position: absolute;
  top: 5px;
  right: 30px;
}
body#account__your_coupons .text-panel__closer::before {
  transform: rotate(45deg);
}
body#account__your_coupons .text-panel__closer::after {
  transform: rotate(-45deg);
}
body#account__your_coupons .text-panel .description {
  object-fit: contain;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  マイページ | お問い合わせ一覧
  ---------------
*/
body#account__view_inquiries .inquiries-list {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
body#account__view_inquiries .panel-inquiry {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
}
body#account__view_inquiries .panel-inquiry.has-response:not(.is-open):hover {
  opacity: 0.9;
}
body#account__view_inquiries .panel-heading,
body#account__view_inquiries .inq-item {
  display: flex;
  gap: 18px;
}
body#account__view_inquiries .panel-heading,
body#account__view_inquiries .inq-item-SELECT_ONE_RADIO {
  background: #d9d9d9;
  padding-inline: 24px;
}
@media all and (max-width : 768px) {
  body#account__view_inquiries .panel-heading,
  body#account__view_inquiries .inq-item-SELECT_ONE_RADIO {
    padding-inline: 12px;
  }
}
body#account__view_inquiries .account .panel-heading {
  padding-top: 22px;
}
@media all and (max-width : 768px) {
  body#account__view_inquiries .account .panel-heading {
    padding-top: 16px;
  }
}
body#account__view_inquiries .view-inquiries .panel-body {
  margin-bottom: 0;
  padding-bottom: 26px;
  position: relative;
}
body#account__view_inquiries .view-inquiries .panel-inquiry.has-response .panel-body::before,
body#account__view_inquiries .view-inquiries .panel-inquiry.has-response .panel-body::after {
  background: #005c69;
  content: "";
  display: block;
  position: absolute;
}
body#account__view_inquiries .view-inquiries .panel-body::before {
  height: 1px;
  width: 15px;
  top: 151px;
  right: 20px;
}
@media all and (max-width : 768px) {
  body#account__view_inquiries .view-inquiries .panel-body::before {
    top: 71px;
  }
}
body#account__view_inquiries .view-inquiries .panel-body::after {
  height: 15px;
  width: 1px;
  top: 144px;
  right: 27px;
  transform: rotate(0);
  transition: transform 0.3s ease;
}
@media all and (max-width : 768px) {
  body#account__view_inquiries .view-inquiries .panel-body::after {
    top: 64px;
  }
}
body#account__view_inquiries .panel-inquiry.is-open .panel-body::after {
  transform: rotate(90deg);
}
body#account__view_inquiries .inq-item-SELECT_ONE_RADIO {
  padding-top: 6px;
  padding-bottom: 22px;
}
@media all and (max-width : 768px) {
  body#account__view_inquiries .inq-item-SELECT_ONE_RADIO {
    padding-bottom: 16px;
  }
}
body#account__view_inquiries .panel-body {
  margin-top: 0;
}
body#account__view_inquiries .panel-heading .inq-type,
body#account__view_inquiries .inq-item-label {
  font-size: 14px;
  font-weight: 500;
}
body#account__view_inquiries .panel-heading .inq-date,
body#account__view_inquiries .inq-item-values span {
  font-size: 14px;
}
body#account__view_inquiries .inq-item {
  padding-inline: 24px;
}
@media all and (max-width : 768px) {
  body#account__view_inquiries .inq-item {
    padding-inline: 12px;
  }
}
body#account__view_inquiries .has-response .inq-item {
  cursor: pointer;
}
body#account__view_inquiries .inq-item:not(:first-child) {
  padding-top: 16px;
}
body#account__view_inquiries .inq-item-WORD {
  padding-top: 18px;
}
body#account__view_inquiries .panel-body .inq-item-WORD,
body#account__view_inquiries .panel-body .inq-item-TEXT,
body#account__view_inquiries .panel-body .inq-item-FILE_UPLOAD {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
body#account__view_inquiries .inq-item-FILE_UPLOAD img {
  max-height: 200px;
}
body#account__view_inquiries .inq-response {
  border-top: 1px solid #d9d9d9;
  display: none;
  margin-top: 20px;
  padding: 26px 24px 0;
}
@media all and (max-width : 768px) {
  body#account__view_inquiries .inq-response {
    border: none;
    padding: 15px 10px 0;
  }
}
body#account__view_inquiries .inq-response-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
body#account__view_inquiries .inq-resp {
  border: 1px solid #d9d9d9;
  font-size: 14px;
  padding: 18px 22px;
  display: flex;
  flex-direction: column-reverse;
  gap: 15px;
}
body#account__view_inquiries .inq-resp-heading {
  display: flex;
  justify-content: space-between;
}
body#account__view_inquiries .inq-resp-date {
  color: #747474;
}
body#account__view_inquiries .inq-re-entry h6 {
  display: none;
}
body#account__view_inquiries .inq-re-entry form {
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
body#account__view_inquiries .inq-resp-content {
  word-break: break-all;
}
body#account__view_inquiries .inq-re-entry textarea {
  appearance: none;
  border: 1px solid #d9d9d9;
  overflow: auto;
  padding: 10px;
  resize: none;
  height: 110px;
  width: 100%;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  発送履歴
  ---------------
*/
body#account__view_order_history #outer {
  padding-bottom: 0;
}
body#account__view_order_history .message {
  background-color: #ffebeb;
  border: 1px solid #c10000;
  color: #c10000;
  margin-top: 40px;
  padding: 40px;
  text-align: center;
}
body#account__view_order_history .order {
  padding-top: 40px;
}
body#account__view_order_history .panel-journal > .panel-heading {
  min-height: 80px;
  padding-block: 22px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .panel-journal > .panel-heading {
    padding: 16px 14px;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
}
body#account__view_order_history .panel-journal .panel-heading > div {
  flex: auto;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .panel-journal .panel-heading > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
body#account__view_order_history .payment-method,
body#account__view_order_history .total-invoice,
body#account__view_order_history .purchase-date {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 12px;
}
body#account__view_order_history .panel-heading h5 {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
}
body#account__view_order_history .panel-heading h5 + div {
  font-size: 16px;
  font-weight: 400;
}
body#account__view_order_history .journal-status {
  display: flex;
  align-items: center;
}
@media all and (min-width: 769px) {
  body#account__view_order_history .journal-status h5 {
    display: none;
  }
}
body#account__view_order_history .panel-heading > .control {
  display: flex;
  gap: 70px;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .panel-heading > .control {
    margin-top: 6px;
    padding-top: 10px;
    position: relative;
    gap: 20px;
    align-items: flex-end;
  }
  body#account__view_order_history .panel-heading > .control::before {
    background: #d9d9d9;
    content: "";
    display: block;
    width: calc(100% + 28px);
    height: 1px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
body#account__view_order_history .panel-heading > .control h5 {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 70px;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .panel-heading > .control h5 {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    gap: 6px;
  }
}
body#account__view_order_history .panel-heading > .control h5 span {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
}
body#account__view_order_history #outer .panel .panel-body {
  margin-block: 0;
  padding: 20px;
}
@media all and (max-width : 768px) {
  body#account__view_order_history #outer .panel .panel-body {
    padding: 10px;
  }
}
body#account__view_order_history ul.panels {
  background: #f6f6f6;
  border-radius: 2px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media all and (max-width : 768px) {
  body#account__view_order_history ul.panels {
    gap: 10px;
    padding: 10px 14px;
  }
}
body#account__view_order_history ul.panels li {
  margin: 0;
  display: flex;
  gap: 70px;
}
@media all and (max-width : 768px) {
  body#account__view_order_history ul.panels li {
    flex-wrap: wrap;
    gap: 0 24px;
  }
}
body#account__view_order_history ul.panels li h6 {
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  text-align: left;
  width: 100px;
}
body#account__view_order_history ul.panels li h6 + div {
  font-size: 16px;
  padding: 0;
}
body#account__view_order_history .simple-person {
  display: flex;
  gap: 10px;
}
body#account__view_order_history .itemdetail-row > .extra-controls {
  display: none;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .itemdetail-row .goods {
    flex-direction: row;
  }
}
@media all and (max-width : 768px) {
  body#account__view_order_history .itemdetails-body .itemdetail-row .goods .item-thumbnail-img {
    margin: 0;
  }
}
body#account__view_order_history .goods-text .unit-price {
  display: none;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .purchase-contents {
    margin-top: -50px;
  }
}
body#account__view_order_history .amount-table {
  background: #f6f6f6;
  border-radius: 2px;
  margin: 20px 0 0;
  padding: 26px 20px 24px;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .amount-table {
    padding: 16px 20px;
  }
}
body#account__view_order_history .amount-table table {
  width: max-content;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .amount-table table {
    width: 100%;
  }
}
body#account__view_order_history .amount-table tbody {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
body#account__view_order_history .amount-table tr {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
body#account__view_order_history .amount-table tr.total {
  border-top: 2px solid #d9d9d9;
  margin-top: 10px;
  padding-top: 18px;
  display: flex;
  gap: 60px;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .amount-table tr.total {
    gap: 10px;
    justify-content: space-between;
  }
}
body#account__view_order_history .amount-table th,
body#account__view_order_history .amount-table td {
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
}
body#account__view_order_history .amount-table td {
  padding-left: 14px;
  position: relative;
}
body#account__view_order_history .amount-table td::before {
  content: "：";
  font-size: 14px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}
body#account__view_order_history .amount-table .service-payment_commission .service-label {
  display: none;
}
body#account__view_order_history .amount-table tr.total th {
  color: #005c69;
  font-size: 24px;
  font-weight: 500;
}
body#account__view_order_history .amount-table tr.total td {
  color: #333;
  font-size: 24px;
  font-weight: 700;
}
body#account__view_order_history .amount-table tr.total td::before {
  display: none;
}
body#account__view_order_history .sj-panel {
  border: 1px solid #d9d9d9;
}
body#account__view_order_history .sj-header {
  background-color: #f6f6f6;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-header {
    flex-direction: column;
  }
}
body#account__view_order_history .sj-header__summary {
  padding: 16px 12px;
  display: flex;
  align-items: center;
  flex-grow: 1;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-header__summary {
    flex-direction: column;
    flex-grow: 0;
    font-size: 14px;
    padding: 10px;
    width: 100%;
  }
}
body#account__view_order_history .sj-header__shipmentcode {
  border-left: 1px solid #d9d9d9;
  padding: 16px 12px;
  display: flex;
  align-items: center;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-header__shipmentcode {
    border-top: 1px solid #d9d9d9;
    border-left: none;
    font-size: 14px;
    padding: 10px;
    width: 100%;
  }
}
@media all and (max-width : 768px) {
  body#account__view_order_history .sj-header__shipmentcode {
    justify-content: space-between;
  }
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-header__orderdate,
  body#account__view_order_history .sj-header__total {
    width: 100%;
    display: flex;
  }
}
@media all and (max-width : 768px) {
  body#account__view_order_history .sj-header__orderdate,
  body#account__view_order_history .sj-header__total {
    justify-content: space-between;
  }
}
body#account__view_order_history .sj-header__orderdate-label,
body#account__view_order_history .sj-header__total-label,
body#account__view_order_history .sj-header__s-status-label,
body#account__view_order_history .sj-header__p-status-label,
body#account__view_order_history .sj-header__code-label {
  font-weight: 500;
  padding-right: 8px;
}
@media all and (max-width: 1024px) and (min-width: 769px) {
  body#account__view_order_history .sj-header__orderdate-label,
  body#account__view_order_history .sj-header__total-label,
  body#account__view_order_history .sj-header__s-status-label,
  body#account__view_order_history .sj-header__p-status-label,
  body#account__view_order_history .sj-header__code-label {
    width: 50%;
  }
}
body#account__view_order_history .sj-header__total {
  margin-left: 40px;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-header__total {
    margin-top: 8px;
    margin-left: 0;
  }
}
body#account__view_order_history .sj-header__status {
  margin-left: auto;
  display: flex;
  gap: 8px;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-header__status {
    margin-left: 0;
    padding-top: 8px;
    width: 100%;
    flex-direction: column;
  }
}
body#account__view_order_history .sj-header__s-status,
body#account__view_order_history .sj-header__p-status {
  width: 100%;
  display: flex;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .sj-header__s-status,
  body#account__view_order_history .sj-header__p-status {
    justify-content: space-between;
  }
}
body#account__view_order_history .s-status-CREATED .sj-header__s-status-tag,
body#account__view_order_history .s-status-VALIDATED .sj-header__s-status-tag,
body#account__view_order_history .s-status-ACCEPTED .sj-header__s-status-tag,
body#account__view_order_history .s-status-PREPARING_FOR_SHIPMENT .sj-header__s-status-tag {
  border: 1px solid #396dcf;
  color: #396dcf;
}
body#account__view_order_history .s-status-SHIPPED .sj-header__s-status-tag,
body#account__view_order_history .s-status-ARRIVED .sj-header__s-status-tag,
body#account__view_order_history .s-status-INSPECTED .sj-header__s-status-tag,
body#account__view_order_history .s-status-COMPLETED .sj-header__s-status-tag {
  border: 1px solid #005c69;
  color: #005c69;
}
body#account__view_order_history .p-status-UNCLAIMED .sj-header__p-status-tag {
  border: 1px solid #747474;
  color: #747474;
}
body#account__view_order_history .p-status-UNPAID .sj-header__p-status-tag {
  border: 1px solid #c10000;
  color: #c10000;
}
body#account__view_order_history .p-status-DONE .sj-header__p-status-tag {
  border: 1px solid #005c69;
  color: #005c69;
}
body#account__view_order_history .sj-header__s-status-label,
body#account__view_order_history .sj-header__p-status-label {
  display: none;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-header__s-status-label,
  body#account__view_order_history .sj-header__p-status-label {
    display: block;
  }
}
body#account__view_order_history .sj-header__s-status-tag,
body#account__view_order_history .sj-header__p-status-tag {
  background-color: #fff;
  border: 1px solid #747474;
  border-radius: 2px;
  color: #747474;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  min-width: 80px;
  padding: 2px 4px;
  text-align: center;
}
body#account__view_order_history .sj-header__code-number {
  font-size: 20px;
  font-weight: 700;
  padding: 0 24px;
}
@media all and (max-width : 768px) {
  body#account__view_order_history .sj-header__code-number {
    display: block;
    padding: 0;
  }
}
body#account__view_order_history .sj-header__detail-link {
  background-color: #494949;
  border-radius: 2px;
  color: #fff;
  padding: 2px 12px;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-header__detail-link {
    font-size: 16px;
  }
}
body#account__view_order_history .sj-body {
  padding: 10px;
}
body#account__view_order_history .sj-shipment {
  background-color: #f6f6f6;
  border-radius: 2px;
  padding: 8px 10px;
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-shipment {
    flex-direction: column;
  }
}
body#account__view_order_history .sj-shipment > div {
  padding: 4px 0;
  width: 50%;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-shipment > div {
    display: flex;
    font-size: 14px;
    padding: 0;
    width: 100%;
  }
}
body#account__view_order_history .sj-shipment > div:nth-child(even) {
  border-left: 1px solid #d9d9d9;
  padding-left: 20px;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-shipment > div:nth-child(even) {
    border: none;
    padding: 0;
  }
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-shipment > div + div {
    margin-top: 4px;
  }
}
body#account__view_order_history .sj-shipment__payment-label,
body#account__view_order_history .sj-shipment__shipped-label,
body#account__view_order_history .sj-shipment__delivery-label,
body#account__view_order_history .sj-shipment__tracking-label {
  display: inline-block;
  font-weight: 500;
  min-width: 120px;
}
body#account__view_order_history .sj-shipment__tracking-number {
  text-decoration: underline;
}
body#account__view_order_history .sj-item {
  margin-top: 10px;
  padding: 10px;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-item {
    padding: 0;
  }
}
body#account__view_order_history .sj-item-detail {
  font-size: 18px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-item-detail {
    font-size: 14px;
    padding: 8px 0;
  }
}
body#account__view_order_history .sj-item-detail + .sj-item-detail {
  border-top: 1px solid #d9d9d9;
}
body#account__view_order_history .sj-header__PGerror {
  background-color: #ffebeb;
  border: 1px solid #c10000;
  color: #c10000;
  font-size: 14px;
  margin-left: 20px;
  padding: 0 4px;
}
@media all and (max-width: 1024px) {
  body#account__view_order_history .sj-header__PGerror {
    margin-top: 8px;
    margin-left: auto;
  }
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  お知らせ履歴
  ---------------
*/
body#account__view_account_message_history ul.block-list {
  padding-top: 40px;
  max-width: 900px;
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
body#account__view_account_message_history ul.block-list li {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 20px;
  width: 100%;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ----------------
  注文に関するお問い合わせ
  ----------------
*/
body#account__order_inquiry #outer {
  padding-block: 0;
}
body#account__order_inquiry h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.45833333;
  padding: 0 40px;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry h1 {
    font-size: 20px;
    line-height: 1.45;
    padding-inline: 12px;
  }
}
body#account__order_inquiry .order-inq {
  padding-top: 20px;
  padding-inline: 40px;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .order-inq {
    padding-inline: 12px;
  }
}
body#account__order_inquiry .order.order-valid {
  margin-top: 40px;
  padding-inline: 20px;
}
@media all and (max-width : 768px) {
  body#account__order_inquiry .order.order-valid {
    padding-inline: 0;
  }
}
body#account__order_inquiry .btn-submit {
  margin: 40px 0 20px;
  max-width: fit-content;
  padding-inline: 40px;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
}
body#account__order_inquiry #page_aside2 .btn-account {
  margin-top: 0;
}
body#account__order_inquiry .order-shipments {
  padding-top: 40px;
}
body#account__order_inquiry .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt + div) {
  width: 100%;
}
@media all and (min-width: 769px) {
  body#account__order_inquiry .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt + div) {
    display: flex;
    justify-content: flex-end;
  }
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  決済ステータス
  ---------------
*/
body#order__payment_status .panel-payment > .panel-heading {
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
}
body#order__payment_status .panel-payment > .panel-heading > div,
body#order__payment_status .panel-body > .status {
  padding: 12px 20px 0;
  max-width: 350px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media all and (max-width : 768px) {
  body#order__payment_status .panel-payment > .panel-heading > div,
  body#order__payment_status .panel-body > .status {
    max-width: 100%;
    gap: 20px;
  }
}
body#order__payment_status .panel-payment > .panel-heading > div h5,
body#order__payment_status .panel-payment .panel-body .label {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  text-align: left;
  width: 120px;
}
@media all and (max-width : 768px) {
  body#order__payment_status .panel-payment > .panel-heading > div h5,
  body#order__payment_status .panel-payment .panel-body .label {
    width: fit-content;
  }
}
body#order__payment_status .panel-payment > .panel-heading > div h5 + div {
  flex: none;
}
body#order__payment_status .shipment {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
body#order__payment_status .panel-payment > .panel-body {
  margin-block: 0;
}
body#order__payment_status .panel-body > .status {
  margin-left: auto;
}
body#order__payment_status .shipment-valid {
  padding-block: 20px 0;
  margin-bottom: 0;
}
body#order__payment_status h4 {
  font-size: 20px;
  margin-top: 20px;
  padding: 0;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  既定のお支払方法
  ---------------
*/
body#account__manage_paymentmethod #add-new-payment-method {
  padding-top: 40px;
}
body#account__manage_paymentmethod #add-new-payment-method .wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 20px;
}
body#account__manage_paymentmethod h4 {
  background: #f6f6f6;
  font-size: 16px;
  font-weight: 400;
  padding: 14px 18px;
}
body#account__manage_paymentmethod select.date {
  padding-block: 8px;
}
body#account__manage_paymentmethod label:has(input[disabled]),
body#account__manage_paymentmethod input[disabled] {
  pointer-events: none;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  マイページ | ポイント履歴
  ---------------
*/
body#account__view_points_history #page-title + .note {
  font-size: 14px;
  margin-block: 10px 0;
}
body#account__view_points_history .points-summary {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  body#account__view_points_history .points-summary {
    display: block;
  }
}
body#account__view_points_history .points-summary .points-amount {
  flex: auto;
}
body#account__view_points_history .points-amount-available {
  width: 60%;
}
@media all and (max-width : 768px) {
  body#account__view_points_history .points-amount-available {
    width: 100%;
  }
}
body#account__view_points_history .points-amount-pending {
  width: 40%;
}
@media all and (min-width: 769px) {
  body#account__view_points_history .points-amount-pending {
    border-left: 1px solid #d9d9d9;
  }
}
@media all and (max-width : 768px) {
  body#account__view_points_history .points-amount-pending {
    border-top: 1px solid #d9d9d9;
    width: 100%;
  }
}
body#account__view_points_history .points-summary .points-amount .wrapper {
  border: none;
  height: 67px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width : 768px) {
  body#account__view_points_history .points-summary .points-amount .wrapper {
    height: auto;
    padding: 12px 14px;
  }
}
body#account__view_points_history .wrapper .label {
  font-size: 16px;
  font-weight: 500;
}
@media all and (max-width : 768px) {
  body#account__view_points_history .points-summary .points-amount-available .wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media all and (max-width : 768px) {
  body#account__view_points_history .points-summary .points-amount-available .wrapper .value {
    text-align: right;
    width: 100%;
  }
}
body#account__view_points_history .points-summary .points-amount-available .wrapper span.value {
  color: #005c69;
  font-size: 20px;
  font-weight: 700;
}
body#account__view_points_history .points-detail {
  margin: 20px 0 0;
  width: 100%;
}
body#account__view_points_history .points-detail tr:first-child {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
}
body#account__view_points_history .points-detail tr:not(:first-child) {
  border: 1px solid #d9d9d9;
  height: 58px;
}
body#account__view_points_history .points-detail th {
  border-bottom: none;
  font-size: 14px;
}
body#account__view_points_history .points-detail td {
  font-size: 16px;
}
body#account__view_points_history .points-detail th,
body#account__view_points_history .points-detail td {
  padding: 10px 24px;
  text-align: left;
}
@media all and (max-width : 768px) {
  body#account__view_points_history .points-detail th,
  body#account__view_points_history .points-detail td {
    font-size: 13px;
    padding: 10px 8px;
    text-align: center;
  }
}
body#account__view_points_history .points-detail tr th:last-child,
body#account__view_points_history .points-detail tr .pd-expiry {
  display: none;
}
body#account__view_points_history .points-detail span.pd-type {
  font-size: 12px;
  padding: 4px 32px;
  white-space: nowrap;
}
@media all and (max-width : 768px) {
  body#account__view_points_history .points-detail span.pd-type {
    padding: 4px 8px;
  }
}
:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  アカウント情報の変更
  ---------------
*/
body#account__edit_account_properties fieldset {
  margin-top: 40px;
  padding: 40px;
}
@media all and (max-width : 768px) {
  body#account__edit_account_properties fieldset {
    padding: 20px 16px;
  }
}
body#account__edit_account_properties div.btn-submit {
  padding-bottom: 0;
}
body#account__edit_account_properties .btn-submit .btn {
  max-width: max-content;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  パートワーク購読一覧
  ---------------
*/
/* マイページ パートワーク一覧 | pwsubslist */
#account__view_partwork_subscriptions #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-bottom: 90px;
}

#account__view_partwork_subscriptions .subscription-list > .subscription {
  border: 1px solid #ccc;
  margin-top: 40px;
  padding: 20px;
}

#account__view_partwork_subscriptions .subscription .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
}

#account__view_partwork_subscriptions .subscription .buttons > a {
  background-color: #1d1d1b;
  color: #fff;
  display: inline-block;
  min-width: 120px;
  padding: 12px;
  text-align: center;
}

#account__view_partwork_subscriptions .subscription .buttons > a + a {
  margin-left: 12px;
}

#account__view_partwork_subscriptions .subscription .distribution {
  margin-top: 20px;
}

#account__view_partwork_subscriptions .subscription .title {
  font-size: 24px;
}

#account__view_partwork_subscriptions .status {
  font-size: 24px;
  font-weight: bold;
}

#account__view_partwork_subscriptions .status.RUNNING {
  color: #00ac5a;
}

#account__view_partwork_subscriptions .status.PEND_WITH_FAILURE {
  color: red;
}

#account__view_partwork_subscriptions #page_aside2 .btn-account {
  position: static;
  transform: none;
}

#account__view_partwork_subscriptions #page_aside1 .btn-account {
  display: none;
}

#account__view_partwork_subscriptions .rp-subscriptions #page-title > h1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions #page-title > h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.44444444;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription-list {
  margin-top: 40px;
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription {
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0;
  padding: 20px;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription {
    padding: 20px 0 10px;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .wrapper {
  position: relative;
  min-height: 160px;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .wrapper {
    min-height: 90px;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .subscription-code {
  color: #747474;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71428571;
  margin-inline: 200px 110px;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .subscription-code {
    margin-inline: 0 70px;
    position: absolute;
    top: 0;
    left: 112px;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .status {
  border: 1px solid #1d1d1b;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.41666667;
  min-width: 90px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .status {
    min-width: 50px;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .status.PEND {
  border: 1px solid #ff9c08;
  color: #ff9c08;
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .status.RUNNING,
#account__view_partwork_subscriptions .rp-subscriptions .subscription .status.WAITING,
#account__view_partwork_subscriptions .rp-subscriptions .subscription .status.PEND_WITH_FAILURE {
  border: 1px solid #005c69;
  color: #005c69;
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .distribution {
  margin-top: 0;
}
@media all and (min-width: 769px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .distribution {
    margin-inline: 200px 110px;
  }
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .distribution {
    margin-right: 0;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .contents {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .contents {
    gap: 10px;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .thumbnail {
  width: 100%;
  max-width: 180px;
}
@media all and (min-width: 769px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .thumbnail {
    flex: 0 0 102px;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .thumbnail img {
  aspect-ratio: 180/159;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .thumbnail img {
    aspect-ratio: 102/90;
  }
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .text {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .title {
    width: 100%;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .title.distribution {
  display: none;
}
#account__view_partwork_subscriptions .rp-subscriptions .subscription .subscribed-from {
  color: #747474;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-top: 10px;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscriptions .rp-subscriptions .subscription .subscribed-from {
    width: 100%;
  }
}
#account__view_partwork_subscriptions .rp-subscriptions .buttons {
  display: inline-block;
  margin-top: 10px;
}
#account__view_partwork_subscriptions .rp-subscriptions .buttons a {
  background-color: transparent;
  color: #005c69;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  min-width: auto;
  padding: 0 26px 0 0;
  position: relative;
}
#account__view_partwork_subscriptions .rp-subscriptions .buttons a::after {
  background: url(/f/resources/images/common/icon_plus_circle_green.svg) center/contain no-repeat;
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  パートワーク購読の詳細
  ---------------
*/
#account__view_partwork_subscription #container div.panel-body {
  margin-bottom: 20px;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscription #container div.panel-body {
    margin-bottom: 30px;
  }
}
#account__view_partwork_subscription div.message-wrapper div.error {
  width: 100%;
}
#account__view_partwork_subscription .panel-footer {
  border: 1px dotted #ccc;
  padding: 2em;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscription .panel-footer {
    padding: 0.5em;
  }
}
#account__view_partwork_subscription .distribution {
  margin-top: 4em;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscription .distribution {
    margin-top: 30px;
  }
}
#account__view_partwork_subscription .distribution .main-title {
  text-align: center;
}
#account__view_partwork_subscription .distribution .heading .main-title .pwk-title,
#account__view_partwork_subscription .distribution .heading .main-title .dstr-title {
  text-align: left;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscription .distribution .heading .main-title .pwk-title,
  #account__view_partwork_subscription .distribution .heading .main-title .dstr-title {
    padding-top: 0;
  }
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscription .distribution .heading .main-title .pwk-title {
    font-size: 22px;
  }
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscription .distribution .subs-addon-list {
    margin-top: 8px;
    margin-bottom: 0;
  }
}
#account__view_partwork_subscription .distribution > .heading {
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 20px 0;
}
#account__view_partwork_subscription .distribution > .body {
  padding: 20px 0;
}
#account__view_partwork_subscription .distribution .distribution-schedule {
  border-top: none;
  margin-top: 40px;
  padding: 0;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscription .distribution .distribution-schedule {
    margin-top: 0;
  }
}
#account__view_partwork_subscription .rp-subscriptions .not-subscribable {
  color: #c10000;
  font-size: 14px;
  padding: 0;
}
#account__view_partwork_subscription .distribution .distribution-schedule .summary {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 12px;
}
#account__view_partwork_subscription .distribution .distribution-schedule .show-all-parcels {
  display: block;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscription .distribution .distribution-schedule .show-all-parcels {
    text-align: center;
  }
}
#account__view_partwork_subscription .distribution .distribution-schedule .show-all-parcels-button {
  color: #005c69;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
  transition: 0.2s ease-in-out;
  padding: 0 26px 0 0;
  position: relative;
}
#account__view_partwork_subscription .distribution .distribution-schedule .show-all-parcels-button::after {
  background: url(/f/resources/images/common/icon_plus_circle_green.svg) center/contain no-repeat;
  content: "";
  display: inline-block;
  margin-top: 2px;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
#account__view_partwork_subscription .distribution .distribution-schedule .show-all-parcels-button:hover {
  opacity: 0.8;
}
#account__view_partwork_subscription .distribution .distribution-schedule .parcel .issues {
  background-color: transparent;
  margin-top: 0;
}
#account__view_partwork_subscription .distribution .distribution-schedule .parcel .subscription-privileges {
  background-color: transparent;
  margin-top: 0;
}
#account__view_partwork_subscription .distribution .distribution-schedule .parcel > .option-parcels {
  background-color: transparent;
}
#account__view_partwork_subscription .distribution .distribution-schedule .parcel > .shipping {
  display: flex;
}
@media all and (max-width : 768px) {
  #account__view_partwork_subscription .distribution .distribution-schedule .parcel > .shipping {
    background-color: #fff;
  }
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  公開プイロフィールの編集
  ---------------
*/
body#account__edit_profile .form-edit-account .formrow-submit {
  padding: 0;
}
body#account__edit_profile .btn-submit {
  margin-top: 40px;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  パートワークお届け先の編集
  ---------------
*/
.account__edit_partwork_consignee #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .account__edit_partwork_consignee #outer {
    padding-inline: 12px;
  }
}
.account__edit_partwork_consignee .form-body .input-group-address {
  margin-top: 40px;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  パートワークお支払い方法
  ---------------
*/
.account__edit_partwork_payment #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .account__edit_partwork_payment #outer {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .account__edit_partwork_payment h1 {
    font-size: 20px;
  }
}
.account__edit_partwork_payment .main-payment-method .wrapper {
  margin: 0;
  max-width: 100%;
}
.account__edit_partwork_payment .main-payment-method .wrapper + .wrapper {
  border-top: 1px solid #d9d9d9;
}
.account__edit_partwork_payment h4 {
  background: #f6f6f6;
  font-size: 16px;
  font-weight: 400;
  padding: 14px 18px;
}
.account__edit_partwork_payment #update-rp-payment {
  border: 1px solid #d9d9d9;
  margin-top: 40px;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  パートワーク購読の解約
  ---------------
*/
.account__unsubscribe_partwork #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .account__unsubscribe_partwork #outer {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .account__unsubscribe_partwork h1 {
    font-size: 20px;
  }
}
.account__unsubscribe_partwork h4 {
  padding: 0;
}
@media all and (max-width : 768px) {
  .account__unsubscribe_partwork .partwork-unsubscribe {
    margin-top: 16px;
  }
}
.account__unsubscribe_partwork .distribution .dstr-options {
  text-align: left;
}
.account__unsubscribe_partwork .form-partwork-unsubscribe h4,
.account__unsubscribe_partwork .form-partwork-unsubscribe .input-label .input-name,
.account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule .heading {
  font-size: 20px;
  font-weight: 500;
}
@media all and (max-width : 768px) {
  .account__unsubscribe_partwork .form-partwork-unsubscribe h4,
  .account__unsubscribe_partwork .form-partwork-unsubscribe .input-label .input-name,
  .account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule .heading {
    font-size: 18px;
  }
}
.account__unsubscribe_partwork .form-partwork-unsubscribe .note {
  font-size: 11px;
  color: #666;
  margin-top: 0;
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution > .heading {
  background-color: transparent;
  border-bottom: 1px solid #d9d9d9;
  text-align: center;
  padding: 20px 0;
}
@media all and (max-width : 768px) {
  .account__unsubscribe_partwork .unsubscribe-parcel .distribution > .heading {
    padding-top: 0;
  }
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution > .heading .pwk-title,
.account__unsubscribe_partwork .unsubscribe-parcel .distribution > .heading .dstr-title {
  text-align: left;
}
@media all and (max-width : 768px) {
  .account__unsubscribe_partwork .unsubscribe-parcel .distribution > .heading .pwk-title {
    font-size: 22px;
  }
}
@media all and (max-width : 768px) {
  .account__unsubscribe_partwork .unsubscribe-parcel .distribution > .heading .dstr-title {
    padding-top: 0;
  }
}
@media all and (max-width : 768px) {
  .account__unsubscribe_partwork .distribution .subs-addon-list {
    margin-bottom: 0;
  }
}
.account__unsubscribe_partwork .unsubscribe-parcel .parcel-label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44444444;
  margin-left: 5px;
}
@media all and (max-width : 768px) {
  .account__unsubscribe_partwork .unsubscribe-parcel .parcel-label {
    font-size: 16px;
  }
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule {
  border: 0;
  padding: 0;
  margin-top: 40px;
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule .summary {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 12px;
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule .summary > .label {
  display: block;
  width: 100%;
  padding-right: 0;
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule .summary > .label label {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule .summary > .label input[type=radio] {
  accent-color: #005c69;
  width: 20px;
  height: 20px;
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule .parcel .issues {
  background-color: transparent;
  margin-top: 0;
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule .parcel .subscription-privileges {
  background-color: transparent;
  margin-top: 0;
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule .parcel > .option-parcels {
  background-color: transparent;
}
.account__unsubscribe_partwork .unsubscribe-parcel .distribution-schedule .parcel > .option-parcels .heading {
  font-size: 14px;
  font-weight: 500;
}
.account__unsubscribe_partwork .unsubscribe-reason {
  margin-top: 40px;
}
.account__unsubscribe_partwork .unsubscribe-reason .input-note {
  padding-top: 0;
}
.account__unsubscribe_partwork .unsubscribe-reason .confirm-group {
  width: 100%;
}
.account__unsubscribe_partwork .unsubscribe-reason .confirm-group .ip-cfm {
  background-color: #f6f6f6;
  border-radius: 2px;
  display: block;
  width: 100%;
  padding: 16px;
}
@media all and (max-width : 768px) {
  .account__unsubscribe_partwork .unsubscribe-reason .confirm-group .ip-cfm {
    font-size: 14px;
    padding: 12px 16px;
  }
}
.account__unsubscribe_partwork .unsubscribe-reason .confirm-group .ip-cfm.empty {
  display: none;
}
.account__unsubscribe_partwork .form.form-confirm .unsubscribe-reason .input-control {
  flex-direction: column;
}
.account__unsubscribe_partwork .unsubscribe-submit {
  text-align: center;
  margin-top: 30px;
}
.account__unsubscribe_partwork .unsubscribe-submit .after-confirm {
  font-size: 0;
}
.account__unsubscribe_partwork .unsubscribe-submit .btn-confirm {
  background: #494949 url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) calc(100% - 10px) 50%/24px no-repeat;
  border-radius: 2px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 50px 13px;
  min-width: 175px;
}
@media (hover: hover) and (pointer: fine) {
  .account__unsubscribe_partwork .unsubscribe-submit .btn-confirm {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .account__unsubscribe_partwork .unsubscribe-submit .btn-confirm:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.account__unsubscribe_partwork .unsubscribe-submit .btn-confirm::before, .account__unsubscribe_partwork .unsubscribe-submit .btn-confirm::after {
  backface-visibility: hidden;
}
.account__unsubscribe_partwork .unsubscribe-submit .btn-confirm:hover {
  background: #494949 url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) calc(100% - 10px) 50%/24px no-repeat;
}
.account__unsubscribe_partwork .unsubscribe-submit .btn-back {
  font-size: 16px;
  font-weight: 700;
  min-width: 175px;
}
.account__unsubscribe_partwork .unsubscribe-submit .btn-submit {
  background: #494949 url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) calc(100% - 10px) 50%/24px no-repeat;
  border-radius: 2px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 50px 13px;
  min-width: 175px;
}
.account__unsubscribe_partwork .unsubscribe-submit .btn-submit:hover {
  background: #494949 url(/f/resources/images/common/icon_arrow_right_circle_white_gray.svg) calc(100% - 10px) 50%/24px no-repeat;
}
@media all and (max-width : 768px) {
  .account__unsubscribe_partwork .unsubscribe-submit .btn-submit {
    margin-left: 0;
  }
}
.account__unsubscribe_partwork .unsubscribe-submit .btn-back,
.account__unsubscribe_partwork .unsubscribe-submit .btn-submit {
  min-height: 50px;
  margin: 10px 0.5em 0;
  vertical-align: middle;
}
@media (hover: hover) and (pointer: fine) {
  .account__unsubscribe_partwork .unsubscribe-submit .btn-back,
  .account__unsubscribe_partwork .unsubscribe-submit .btn-submit {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .account__unsubscribe_partwork .unsubscribe-submit .btn-back:hover,
  .account__unsubscribe_partwork .unsubscribe-submit .btn-submit:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.account__unsubscribe_partwork .unsubscribe-submit .btn-back::before, .account__unsubscribe_partwork .unsubscribe-submit .btn-back::after,
.account__unsubscribe_partwork .unsubscribe-submit .btn-submit::before,
.account__unsubscribe_partwork .unsubscribe-submit .btn-submit::after {
  backface-visibility: hidden;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  購入したコンテンツ一覧
  ---------------
*/
#account__view_purchased_merchandisecontents #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  #account__view_purchased_merchandisecontents #outer {
    padding-inline: 12px;
  }
}

#account__view_purchased_merchandisecontents .filename {
  margin-top: 12px;
  text-align: center;
}

#account__view_purchased_merchandisecontents .pmc-order {
  margin-top: 12px;
  text-decoration: underline;
}

/* デジタルコンテンツ 非表示要素 */
#account__view_purchased_merchandisecontents .filename,
#account__view_purchased_merchandisecontents .pmc-order,
#account__view_purchased_merchandisecontents .pmc-text .description,
#account__view_purchased_merchandisecontents .download-data {
  display: none;
}

.purchased-merchandise-contents h1 {
  font-size: 20px;
  line-height: 1.45;
  margin-top: 28px;
  padding: 0;
}
@media all and (max-width : 768px) {
  .purchased-merchandise-contents h1 {
    font-size: 18px;
    line-height: 1.44444444;
    margin-top: 20px;
  }
}
.purchased-merchandise-contents .block-list {
  margin-top: 20px;
}
.purchased-merchandise-contents .block-list > li {
  border: 0;
  border-top: 1px solid #d9d9d9;
  padding: 20px;
  width: 100%;
}
@media all and (min-width: 769px) {
  .purchased-merchandise-contents .block-list > li {
    display: grid;
    grid-template-columns: 120px 1fr 30%;
    align-items: center;
    gap: 40px;
  }
}
@media all and (max-width : 768px) {
  .purchased-merchandise-contents .block-list > li {
    min-height: 130px;
    padding: 20px 0;
    position: relative;
  }
}
.purchased-merchandise-contents .block-list > li:last-child {
  border-bottom: 1px solid #d9d9d9;
}
.purchased-merchandise-contents .block-list > li > .item-thumbnail-img-wrapper {
  margin: 0;
  flex: 0 0 120px;
}
@media all and (max-width : 768px) {
  .purchased-merchandise-contents .block-list > li > .item-thumbnail-img-wrapper {
    max-width: 102px;
    min-width: 102px;
    position: absolute;
    top: 20px;
    left: 0;
  }
}
.purchased-merchandise-contents .item-thumbnail-img.item-thumbnail-img-sm {
  width: 100%;
  height: 100%;
}
.purchased-merchandise-contents .item-thumbnail-img a {
  display: block;
  width: 100%;
  height: 100%;
}
.purchased-merchandise-contents .item-thumbnail-img a img,
.purchased-merchandise-contents .item-thumbnail-img a div {
  aspect-ratio: 120/105;
  width: 100%;
  height: 100%;
}
@media all and (max-width : 768px) {
  .purchased-merchandise-contents .item-thumbnail-img a img,
  .purchased-merchandise-contents .item-thumbnail-img a div {
    aspect-ratio: 102/90;
  }
}
.purchased-merchandise-contents .pmc-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
@media all and (max-width : 768px) {
  .purchased-merchandise-contents .pmc-text {
    margin-left: 117px;
    height: 50%;
  }
}
@media all and (min-width: 769px) {
  .purchased-merchandise-contents .pmc-access-control {
    margin-left: auto;
  }
}
@media all and (max-width : 768px) {
  .purchased-merchandise-contents .pmc-access-control {
    margin-top: 5px;
    margin-left: 117px;
  }
}
.purchased-merchandise-contents .pmc-access-control .link div {
  color: #b2b2b2;
  font-size: 14px;
  line-height: 1.42857143;
}
@media all and (min-width: 769px) {
  .purchased-merchandise-contents .pmc-access-control .merchandise-contents,
  .purchased-merchandise-contents .pmc-access-control .pmc-not-available {
    text-align: right;
  }
}
.purchased-merchandise-contents .pmc-access-control .link li div {
  font-size: 12px;
  text-align: left;
  margin-top: 10px;
}
@media all and (max-width : 768px) {
  .purchased-merchandise-contents .pmc-access-control .link li div {
    margin-top: 4px;
  }
}
.purchased-merchandise-contents .pmc-access-control .link a {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.4375;
  text-align: center;
  min-width: 126px;
  padding: 14px;
}
@media all and (max-width : 768px) {
  .purchased-merchandise-contents .pmc-access-control .link a {
    margin-top: 10px;
    padding: 8px;
  }
}
.purchased-merchandise-contents .notice {
  margin-top: 20px;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  参加コミュニティ一覧
  ---------------
*/
#account__your_communities #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  #account__your_communities #outer {
    padding-inline: 12px;
  }
}
#account__your_communities h1 {
  padding: 0;
}
#account__your_communities #page_aside2 .contents-wrapper {
  display: flex;
  flex-direction: column;
}
#account__your_communities #page_aside2 .btn-account {
  margin-top: 40px;
  order: 2;
}
#account__your_communities .backlink.community-list {
  text-align: center;
  margin-top: 40px;
  order: 1;
}
#account__your_communities .backlink.community-list a {
  display: inline-flex;
}

div.talkthreads {
  margin-top: 30px;
}
div.talkthreads + div.talkthreads {
  margin-top: 50px;
}
div.talkthreads h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
}
div.talkthreads li {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  margin-top: 20px;
  padding: 20px;
}
@media all and (max-width : 768px) {
  div.talkthreads li {
    padding: 0;
  }
}
.talkthread + div.talkthreads {
  margin-top: 20px;
}
@media all and (min-width: 769px) {
  div.talkthreads .board {
    display: flex;
    align-items: center;
    gap: 26px;
  }
}
div.talkthreads .board img {
  width: 100%;
  height: 100%;
}
@media all and (min-width: 769px) {
  div.talkthreads .board img {
    max-width: 353px;
    aspect-ratio: 353/106;
  }
}
@media all and (max-width : 768px) {
  div.talkthreads .board img {
    aspect-ratio: 370/111;
  }
}
div.talkthreads .board .board-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
}
@media all and (max-width : 768px) {
  div.talkthreads .board .board-name {
    font-size: 16px;
    line-height: 1.4375;
    margin-top: 10px;
    padding-inline: 10px;
  }
}
div.talkthreads .talkthread-detail {
  background-color: #fff;
  margin-top: 20px;
  padding: 30px 20px;
}
@media all and (max-width : 768px) {
  div.talkthreads .talkthread-detail {
    margin: 10px;
    padding: 15px;
  }
}
div.talkthreads .talkthread-detail .title {
  text-align: right;
}
div.talkthreads .talkthread-detail .title a {
  display: block;
  font-size: 20px;
  line-height: 1.45;
  text-align: left;
}
@media all and (min-width: 769px) {
  div.talkthreads .talkthread-detail .title a {
    position: relative;
  }
}
@media all and (max-width : 768px) {
  div.talkthreads .talkthread-detail .title a {
    font-size: 16px;
    line-height: 1.4375;
  }
}
@media all and (min-width: 769px) {
  div.talkthreads .talkthread-detail .title a::after {
    background: url("/f/resources/images/common/icon_arrow_right.svg") center/contain no-repeat;
    content: "";
    width: 6px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
@media all and (max-width : 768px) {
  div.talkthreads .talkthread-detail .title a::after {
    display: none;
  }
}
div.talkthreads .talkthread-detail .title a:visited {
  color: #005c69;
}
div.talkthreads .talkthread-detail .title .comment-count {
  color: #005c69;
  display: inline-block;
  font-size: 14px;
  line-height: 1.41666667;
  text-align: right;
  margin-top: 2px;
  position: relative;
}
@media all and (max-width : 768px) {
  div.talkthreads .talkthread-detail .title .comment-count {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
div.talkthreads .talkthread-detail .title .comment-count::before {
  background: url(/f/resources/images/common/icon_comment.svg) center/contain no-repeat;
  content: "";
  display: inline-block;
  height: 14px;
  width: 15px;
  position: absolute;
  top: 50%;
  left: -19px;
  transform: translateY(-50%);
}
div.talkthreads .talkthread-detail .latest-comment {
  margin-top: 2px;
  display: flex;
  flex-direction: column-reverse;
}
div.talkthreads .talkthread-detail .latest-comment .comment {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 10px;
  height: 60px;
}
div.talkthreads .talkthread-detail .latest-comment .comment {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
div.talkthreads .talkthread-detail .latest-comment .info {
  color: #b2b2b2;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ----------------
  注文詳細
  ----------------
*/
body#account__order .shipment-contents div.delivery-request {
  display: none;
}
body#account__order div.itemdetail-row .item {
  width: 100%;
}
body#account__order div.item-thumbnail-img img {
  object-fit: contain;
}
body#account__order .tracking-number .delivery-tracking a {
  text-decoration: underline;
}
body#account__order .itemdetails-body .itemdetail-row {
  position: relative;
}
body#account__order .itemdetail-row .extra-controls {
  display: none;
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  padding: 16px;
  position: absolute;
  bottom: 20px;
  right: 0;
}
@media all and (max-width : 768px) {
  body#account__order .itemdetail-row .extra-controls {
    position: static;
    margin-top: 20px;
    width: 100%;
  }
}
body#account__order .extra-controls .merchandise-contents {
  display: flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  body#account__order .extra-controls .merchandise-contents {
    flex-direction: column;
  }
}
body#account__order .extra-controls .merchandise-contents .heading {
  font-size: 16px;
  margin-right: 16px;
}
@media all and (max-width : 768px) {
  body#account__order .extra-controls .merchandise-contents .heading {
    margin-right: 0;
  }
}
@media all and (max-width : 768px) {
  body#account__order .extra-controls .merchandise-contents > ul {
    margin-top: 12px;
    width: 100%;
  }
}
body#account__order .extra-controls .merchandise-contents .btn {
  background: #494949;
  border-radius: 2px;
  color: #fff !important;
  padding: 8px 12px;
}
@media all and (max-width: 1024px) {
  body#account__order .extra-controls .merchandise-contents .btn {
    font-size: 16px;
    width: 100%;
  }
}

/*
  ---------------
  コミュニティ一覧
  ---------------
*/
body#community__boardlist .boardlist {
  position: relative;
}
body#community__boardlist .boardlist .root-category {
  margin-top: 60px;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .root-category {
    margin-top: 44px;
  }
}
body#community__boardlist .boardlist .root-category:first-child {
  margin-top: 25px;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .root-category:first-child {
    margin-top: 22px;
  }
}
body#community__boardlist .boardlist .category {
  margin-top: 0;
}
body#community__boardlist .boardlist .header {
  padding-top: 8px;
  padding-bottom: 19px;
  position: relative;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .header {
    padding-bottom: 14px;
  }
}
body#community__boardlist .boardlist .header::before,
body#community__boardlist .boardlist .header::after {
  content: "";
  background-color: #005C69;
  display: block;
  height: 1px;
  width: 25%;
  position: absolute;
  top: 0;
  left: 0;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .header::before,
  body#community__boardlist .boardlist .header::after {
    width: 54%;
  }
}
body#community__boardlist .boardlist .header::after {
  background-color: #D9D9D9;
  width: 75%;
  right: 0;
  left: auto;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .header::after {
    width: 46%;
  }
}
body#community__boardlist .boardlist .header .breadcrumbs {
  display: none;
}
body#community__boardlist .boardlist .header .category-name {
  border-bottom: none;
  color: #005C69;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.448;
}
body#community__boardlist .boardlist .content ol {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .content ol {
    gap: 40px 20px;
  }
}
body#community__boardlist .boardlist .content ol li {
  width: calc((100% - 60px) / 3);
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .content ol li {
    width: calc(50% - 10px);
  }
}
body#community__boardlist .boardlist .content .board-summary a {
  width: 100%;
}
body#community__boardlist .boardlist .content .board-summary a:has(.uri-to-img) {
  border: 1px solid #d9d9d9;
  padding: 10px;
}
@media all and (min-width: 769px) {
  body#community__boardlist .boardlist .content .board-summary img {
    object-fit: contain;
    height: 145px;
    width: 100%;
  }
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .content .board-summary img {
    width: 100%;
  }
}
body#community__boardlist .boardlist .content .text {
  padding-bottom: 20px;
  position: relative;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .content .text {
    padding-bottom: 24px;
  }
}
body#community__boardlist .boardlist .content .heading {
  margin-top: 12px;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .content .heading {
    margin-top: 10px;
  }
}
body#community__boardlist .boardlist .content .name {
  color: #000;
  font-family: Noto Sans CJK JP;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .content .name {
    font-size: 14px;
  }
}
body#community__boardlist .boardlist .content .threads-count {
  background-color: #F6F6F6;
  border-radius: 2px;
  color: #005C69;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  height: 20px;
  min-width: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .content .threads-count {
    font-size: 14px;
    height: 24px;
    min-width: 108px;
  }
}
body#community__boardlist .boardlist .content .body {
  margin: 7px 0 13px;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .content .body {
    margin: 4px 0 5px;
  }
}
body#community__boardlist .boardlist .content .description {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4479;
}
@media all and (max-width : 768px) {
  body#community__boardlist .boardlist .content .description {
    font-size: 14px;
  }
}

/*
  ---------------
  コミュニティ | コメント検索
  ---------------
*/
body#community__search .link-search,
body#community__commentlist .link-search {
  margin-top: 25px;
}
@media all and (max-width : 768px) {
  body#community__search .link-search,
  body#community__commentlist .link-search {
    margin-top: 20px;
    padding-inline: 12px;
  }
}
body#community__search .link-search a,
body#community__commentlist .link-search a {
  color: #005c69;
  font-weight: 500;
}
@media all and (max-width : 768px) {
  body#community__search .link-search a,
  body#community__commentlist .link-search a {
    font-size: 18px;
  }
}
body#community__search form.community-search,
body#community__commentlist form.community-search {
  background-color: #f6f6f6;
  margin-top: 8px;
  padding: 25px;
}
@media all and (max-width : 768px) {
  body#community__search form.community-search,
  body#community__commentlist form.community-search {
    padding: 14px;
    margin-top: 14px;
  }
}
body#community__search form.community-search select,
body#community__commentlist form.community-search select {
  appearance: none;
  border: 1px solid #b2b2b2;
  background: #fff url(/f/resources/images/common/icon_arrow_down_green.svg) 99% 50%/10px 7px no-repeat;
  border-radius: 2px;
  font-size: 12px;
  padding: 11px 18px;
  width: 100%;
}
@media (hover: hover) and (pointer: fine) {
  body#community__search form.community-search select,
  body#community__commentlist form.community-search select {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#community__search form.community-search select:hover,
  body#community__commentlist form.community-search select:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#community__search form.community-search select::before, body#community__search form.community-search select::after,
body#community__commentlist form.community-search select::before,
body#community__commentlist form.community-search select::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  body#community__search form.community-search select,
  body#community__commentlist form.community-search select {
    font-size: 14px;
    background-position: 97% 50%;
    background-size: 12px 9px;
  }
}
body#community__search .community .property-filter,
body#community__commentlist .community .property-filter {
  padding-top: 14px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
@media all and (max-width : 768px) {
  body#community__search .community .property-filter,
  body#community__commentlist .community .property-filter {
    flex-direction: column;
    gap: 15px;
  }
}
body#community__search .community .property-filter fieldset,
body#community__commentlist .community .property-filter fieldset {
  border: none;
  margin: 0;
  padding: 0;
  width: calc(50% - 20px);
}
@media all and (max-width : 768px) {
  body#community__search .community .property-filter fieldset,
  body#community__commentlist .community .property-filter fieldset {
    width: 100%;
  }
}
body#community__search .community .property-filter legend,
body#community__commentlist .community .property-filter legend {
  font-size: 11px;
  font-weight: bold;
  padding-bottom: 10px;
}
@media all and (max-width : 768px) {
  body#community__search .community .property-filter legend,
  body#community__commentlist .community .property-filter legend {
    padding-bottom: 4px;
  }
}
body#community__search .community .community-property,
body#community__commentlist .community .community-property {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
@media all and (max-width : 768px) {
  body#community__search .community .community-property,
  body#community__commentlist .community .community-property {
    gap: 5px;
  }
}
body#community__search .community .community-property label,
body#community__commentlist .community .community-property label {
  border-radius: 2px;
  border: 1px solid #005c69;
  color: #005c69;
  font-size: 12px;
  overflow: hidden;
  padding-inline: 12px;
  position: relative;
  z-index: 0;
  height: 30px;
  min-width: 90px;
  display: grid;
  place-items: center;
}
@media (hover: hover) and (pointer: fine) {
  body#community__search .community .community-property label,
  body#community__commentlist .community .community-property label {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#community__search .community .community-property label:hover,
  body#community__commentlist .community .community-property label:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#community__search .community .community-property label::before, body#community__search .community .community-property label::after,
body#community__commentlist .community .community-property label::before,
body#community__commentlist .community .community-property label::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  body#community__search .community .community-property label,
  body#community__commentlist .community .community-property label {
    font-size: 14px;
    padding-inline: 10px;
  }
}
body#community__search .community .community-property label.is-active,
body#community__commentlist .community .community-property label.is-active {
  background: #005c69;
  color: #fff;
}
body#community__search .community .community-property input,
body#community__commentlist .community .community-property input {
  appearance: none;
  display: none;
}
body#community__search .community .community-property label[type=checkbox]:checked,
body#community__commentlist .community .community-property label[type=checkbox]:checked {
  background-color: #000;
}
body#community__search .community .community-property input[type=checkbox]:checked,
body#community__commentlist .community .community-property input[type=checkbox]:checked {
  background-color: #005c69;
  color: #fff;
}
body#community__search .community .community-search .keyword,
body#community__commentlist .community .community-search .keyword {
  display: flex;
  margin-top: 20px;
  width: 100%;
}
@media all and (min-width: 769px) {
  body#community__search .community .community-search .keyword,
  body#community__commentlist .community .community-search .keyword {
    background-color: #fff;
    border: 1px solid #b2b2b2;
    border-radius: 2px;
    overflow: hidden;
  }
}
@media all and (max-width : 768px) {
  body#community__search .community .community-search .keyword,
  body#community__commentlist .community .community-search .keyword {
    margin-top: 14px;
    flex-direction: column;
    gap: 5px;
  }
}
body#community__search .community .community-search .keyword .label,
body#community__commentlist .community .community-search .keyword .label {
  font-size: 12px;
  display: inline-block;
  padding: 12px 0;
}
@media all and (min-width: 769px) {
  body#community__search .community .community-search .keyword .label,
  body#community__commentlist .community .community-search .keyword .label {
    background-color: #f6f6f6;
    border-right: 1px solid #b2b2b2;
    height: 40px;
    min-width: 100px;
    text-align: center;
  }
}
@media all and (max-width : 768px) {
  body#community__search .community .community-search .keyword .label,
  body#community__commentlist .community .community-search .keyword .label {
    font-size: 11px;
    font-weight: 700;
    padding: 0;
  }
}
body#community__search .community .community-search .keyword .input,
body#community__commentlist .community .community-search .keyword .input {
  display: inline-block;
  width: 100%;
}
body#community__search .community .community-search .keyword input,
body#community__commentlist .community .community-search .keyword input {
  appearance: none;
  background-color: #fff;
  border: none;
  font-size: 12px;
  height: 40px;
  padding: 13px 20px;
  width: 100%;
}
@media all and (max-width : 768px) {
  body#community__search .community .community-search .keyword input,
  body#community__commentlist .community .community-search .keyword input {
    border: 1px solid #b2b2b2;
    border-radius: 2px;
    font-size: 14px;
    padding: 10px 10px;
  }
}
body#community__search .community .community-search .keyword input:placeholder,
body#community__commentlist .community .community-search .keyword input:placeholder {
  color: #b2b2b2;
}
body#community__search .community .community-search .search-btn,
body#community__commentlist .community .community-search .search-btn {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  body#community__search .community .community-search .search-btn,
  body#community__commentlist .community .community-search .search-btn {
    margin-top: 15px;
  }
}
body#community__search .community .community-search .search-btn .btn.search::before,
body#community__commentlist .community .community-search .search-btn .btn.search::before {
  margin-right: 8px;
  margin-left: 0;
}
body#community__search ol.comments,
body#community__commentlist ol.comments {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
body#community__search .comments li,
body#community__commentlist .comments li {
  background-color: #f6f6f6;
  border: none;
  margin-top: 0;
  padding: 16px 24px;
  position: relative;
}
@media all and (max-width : 768px) {
  body#community__search .comments li,
  body#community__commentlist .comments li {
    padding: 14px 16px;
  }
}
body#community__search .thread-contents .thread-comment .header,
body#community__commentlist .thread-contents .thread-comment .header {
  padding: 0 123px 0 0;
}
@media all and (max-width : 768px) {
  body#community__search .thread-contents .thread-comment .header,
  body#community__commentlist .thread-contents .thread-comment .header {
    padding: 0 115px 0 0;
  }
}
body#community__search .thread-contents .thread-comment.with-seq .header:before,
body#community__commentlist .thread-contents .thread-comment.with-seq .header:before {
  content: "投稿NO." attr(data-seq);
  font-size: 14px;
}
body#community__search .thread-comment .code,
body#community__commentlist .thread-comment .code {
  font-size: 14px;
  display: none;
}
body#community__search .thread-comment .info,
body#community__commentlist .thread-comment .info {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
}
@media all and (max-width : 768px) {
  body#community__search .thread-comment .info,
  body#community__commentlist .thread-comment .info {
    padding-top: 4px;
    gap: 4px;
  }
}
body#community__search .thread-comment .timestamp,
body#community__commentlist .thread-comment .timestamp {
  color: #b2b2b2;
  font-size: 12px;
}
body#community__search .thread-comment .timestamp::before,
body#community__commentlist .thread-comment .timestamp::before {
  content: "投稿日時：";
}
body#community__search .thread-comment .owner-profile,
body#community__commentlist .thread-comment .owner-profile {
  font-size: 12px;
  font-weight: 400;
}
body#community__search .thread-comment .owner-profile .nickname::before,
body#community__commentlist .thread-comment .owner-profile .nickname::before {
  content: "投稿者：";
}
body#community__search .thread-comment .content,
body#community__commentlist .thread-comment .content {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-top: 8px;
  padding: 22px;
}
@media all and (max-width : 768px) {
  body#community__search .thread-comment .content,
  body#community__commentlist .thread-comment .content {
    margin-top: 6px;
    padding: 8px 14px;
  }
}
body#community__search .thread-comment .comment-content,
body#community__commentlist .thread-comment .comment-content {
  font-size: 14px;
}
body#community__search .thread-comment .images li,
body#community__commentlist .thread-comment .images li {
  background-color: transparent;
  padding-inline: 0;
}
body#community__search .thread-comment .images img,
body#community__commentlist .thread-comment .images img {
  max-width: 172px;
}
body#community__search .thread-comment .trailer .reactions,
body#community__commentlist .thread-comment .trailer .reactions {
  position: absolute;
  top: 26px;
  right: 24px;
}
@media all and (max-width : 768px) {
  body#community__search .thread-comment .trailer .reactions,
  body#community__commentlist .thread-comment .trailer .reactions {
    top: 14px;
    right: 16px;
  }
}
body#community__search .thread-comment .trailer .reactions li,
body#community__commentlist .thread-comment .trailer .reactions li {
  background-color: #005c69;
  border-radius: 2px;
  color: #fff;
  height: 30px;
  padding: 6px 10px 6px 26px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (hover: hover) and (pointer: fine) {
  body#community__search .thread-comment .trailer .reactions li,
  body#community__commentlist .thread-comment .trailer .reactions li {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#community__search .thread-comment .trailer .reactions li:hover,
  body#community__commentlist .thread-comment .trailer .reactions li:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#community__search .thread-comment .trailer .reactions li::before, body#community__search .thread-comment .trailer .reactions li::after,
body#community__commentlist .thread-comment .trailer .reactions li::before,
body#community__commentlist .thread-comment .trailer .reactions li::after {
  backface-visibility: hidden;
}
body#community__search .thread-comment .trailer .reactions li::before,
body#community__commentlist .thread-comment .trailer .reactions li::before {
  background: url(/f/resources/images/common/icon_heart_white.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 13px;
  width: 14px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
body#community__search .thread-comment .trailer .reactions li span,
body#community__commentlist .thread-comment .trailer .reactions li span {
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  line-height: 1;
  margin: 0;
}

body#community__commentlist .search-btn button.btn {
  min-width: fit-content;
}

/*
  ---------------
  コミュニティ | コミュニティボードTOP
  ---------------
*/
body#community__board .community .link-search {
  background-color: transparent;
  border: none;
  height: 50px;
  padding: 0;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  body#community__board .community .link-search {
    height: 40px;
    margin-top: 20px;
  }
}
body#community__board h2 {
  font-size: 24px;
  font-weight: 400;
  padding-block: 80px 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
}
@media all and (max-width : 768px) {
  body#community__board h2 {
    font-size: 20px;
    padding-top: 36px;
    align-items: flex-start;
    flex-direction: column;
    gap: 6px;
  }
}
body#community__board h2 .thread-count {
  background-color: #f6f6f6;
  border-radius: 2px;
  color: #005c69;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 9px;
}
@media all and (max-width : 768px) {
  body#community__board h2 .thread-count {
    font-size: 14px;
  }
}
body#community__board .community-board-threads {
  padding-top: 30px;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-threads {
    padding-top: 20px;
  }
}
body#community__board .comments-heading {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}
body#community__board .sorting > span {
  border: 1px solid #b2b2b2;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  font-size: 0;
  margin-left: auto;
  position: relative;
  height: 40px;
  width: 40px;
}
@media all and (min-width: 769px) {
  body#community__board .sorting > span {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4375;
    width: 180px;
    min-height: 50px;
    padding: 13px;
    display: flex;
    align-items: center;
  }
}
body#community__board .sorting span::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_filter.svg") center/contain no-repeat;
  display: inline-block;
  width: 18px;
  height: 12px;
  position: absolute;
}
@media all and (max-width : 768px) {
  body#community__board .sorting span::after {
    top: 50%;
    right: calc(50% + 1px);
    transform: translate(50%, -50%);
  }
}
@media all and (min-width: 769px) {
  body#community__board .sorting span::after {
    right: 13px;
  }
}
body#community__board .sorting ul {
  background-color: #fff;
  bottom: auto;
  display: none;
  left: auto;
  list-style-type: none;
  position: absolute;
  z-index: 10;
  max-width: 180px;
  width: 100%;
}
@media all and (max-width : 768px) {
  body#community__board .sorting ul {
    right: 12px;
  }
}
body#community__board .sorting ul > li {
  display: block;
  padding: 0;
}
@media all and (max-width : 768px) {
  body#community__board .sorting ul > li {
    text-align: left;
  }
}
body#community__board .sorting ul > li > a {
  border: 1px solid #b2b2b2;
  display: block;
  padding: 0.5em 1em;
}
body#community__board .sorting ul > li:not(:first-child) > a {
  border-top: 0;
}
body#community__board .community-board-threads .talk-threads {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
body#community__board .community-board-threads .thread {
  border: 1px solid #d9d9d9;
  padding: 22px;
}
body#community__board .community-board-threads .thread-title {
  border: none;
  font-size: 20px;
  padding: 0;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-threads .thread-title {
    font-size: 16px;
  }
}
body#community__board .community-board-threads .thread-title::before {
  display: none;
}
body#community__board .community-board-threads .thread-title a {
  text-decoration: underline;
}
body#community__board .community-board-threads .info {
  display: flex;
  position: relative;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-threads .info {
    display: block;
  }
}
body#community__board .community-board-threads .owner-profile {
  margin-right: auto;
}
body#community__board .community-board-threads .owner-profile span {
  color: #b2b2b2;
  font-size: 12px;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-threads .owner-profile span {
    font-size: 14px;
  }
}
body#community__board .community-board-threads .owner-profile .nickname::before {
  content: "投稿者：";
}
body#community__board .community-board-threads .created {
  color: #b2b2b2;
  font-size: 12px;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-threads .created {
    font-size: 14px;
  }
}
body#community__board .community-board-threads .total-comments {
  font-size: 12px;
  color: #005c69;
  position: relative;
  position: absolute;
  bottom: -12px;
  right: 0;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-threads .total-comments {
    top: 0;
    bottom: unset;
    font-size: 14px;
  }
}
body#community__board .community-board-threads .total-comments::before {
  background: url(/f/resources/images/common/icon_comment.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 12px;
  width: 13px;
  position: absolute;
  top: 54%;
  left: -14px;
  transform: translateY(-50%);
}
body#community__board .community-board-threads .properties {
  display: flex;
  gap: 4px;
  position: absolute;
  bottom: -24px;
  left: 0;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-threads .properties {
    flex-wrap: wrap;
    padding-top: 6px;
    position: static;
  }
}
body#community__board .community-board-threads .properties span {
  background-color: #005c69;
  border-radius: 50px;
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  padding: 2px 8px;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-threads .properties span {
    font-size: 14px;
  }
}
body#community__board .community-board-threads .heading:has(.comm-prop) + .summary {
  padding-top: 40px;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-threads .heading:has(.comm-prop) + .summary {
    padding-top: 12px;
  }
}
body#community__board .community-board-threads .summary {
  padding-top: 20px;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-threads .summary {
    padding-top: 60px;
  }
}
body#community__board .create-new-thread {
  padding-top: 80px;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  body#community__board .create-new-thread {
    padding-top: 36px;
  }
}
body#community__board .create-new-thread .btn {
  background: -webkit-gradient(linear, left top, right top, from(#005c69), to(#00ada0));
  background: linear-gradient(90deg, #005c69 0%, #00ada0 100%);
  color: #fff !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45833333;
  padding: 14px 20px 14px 54px;
  position: relative;
}
@media all and (max-width : 768px) {
  body#community__board .create-new-thread .btn {
    font-size: 14px;
    padding: 14px 8px 14px 40px;
    text-align: center;
    width: 100%;
  }
}
body#community__board .create-new-thread .btn::before {
  background: url(/f/resources/images/common/icon_create_new_white.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 18px;
  width: 18px;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  body#community__board .create-new-thread .btn::before {
    left: 16px;
  }
}

/*
  ---------------
  コミュニティ | スレッドのコメント一覧
  ---------------
*/
body#community__talk .community .link-search {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
}
body#community__talk .thread-title .thread-content {
  margin-top: 8px;
}
body#community__talk .comments-heading {
  margin-top: 40px;
}
body#community__talk .thread-image li {
  padding-block: 16px;
  display: flex;
  justify-content: center;
}
body#community__talk ol.comments {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
body#community__talk .comments li {
  background-color: #f6f6f6;
  border: none;
  margin-top: 0;
  padding: 16px 24px;
  position: relative;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  body#community__talk .comments li {
    padding: 14px 16px;
  }
}
body#community__talk .thread-contents .thread-comment {
  width: 100%;
}
body#community__talk .thread-contents .thread-comment .header {
  padding: 0 123px 0 0;
}
@media all and (max-width : 768px) {
  body#community__talk .thread-contents .thread-comment .header {
    padding: 0 115px 0 0;
  }
}
body#community__talk .thread-contents .thread-comment.with-seq .header:before {
  content: "投稿NO." attr(data-seq);
  font-size: 14px;
}
body#community__talk .thread-comment .code {
  color: #b2b2b2;
  font-size: 14px;
  display: none;
}
body#community__talk .thread-contents .info {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
}
@media all and (max-width : 768px) {
  body#community__talk .thread-contents .info {
    padding-top: 4px;
    gap: 4px;
  }
}
body#community__talk .thread-contents .timestamp {
  color: #b2b2b2;
  font-size: 12px;
}
body#community__talk .thread-contents .timestamp::before {
  content: "投稿日時：";
}
body#community__talk .thread-contents .owner-profile {
  font-size: 12px;
  font-weight: 400;
}
body#community__talk .thread-contents .owner-profile .nickname::before {
  content: "投稿者：";
}
body#community__talk .thread-comment .content {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-top: 8px;
  padding: 22px;
}
@media all and (max-width : 768px) {
  body#community__talk .thread-comment .content {
    margin-top: 6px;
    padding: 8px 14px;
  }
}
body#community__talk .thread-comment .comment-content {
  font-size: 14px;
}
body#community__talk .thread-comment .images li {
  background-color: transparent;
  padding-inline: 0;
}
body#community__talk .thread-comment .images img {
  max-width: 172px;
}
body#community__talk .thread-comment .trailer {
  display: flex;
  flex-direction: column-reverse;
}
body#community__talk .thread-comment .trailer .reactions {
  position: absolute;
  top: 26px;
  right: 24px;
}
@media all and (max-width : 768px) {
  body#community__talk .thread-comment .trailer .reactions {
    top: 14px;
    right: 16px;
  }
}
body#community__talk .thread-comment .trailer .reactions li {
  background-color: #005c69;
  border-radius: 2px;
  color: #fff;
  height: 30px;
  padding: 6px 10px 6px 26px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (hover: hover) and (pointer: fine) {
  body#community__talk .thread-comment .trailer .reactions li {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#community__talk .thread-comment .trailer .reactions li:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#community__talk .thread-comment .trailer .reactions li::before, body#community__talk .thread-comment .trailer .reactions li::after {
  backface-visibility: hidden;
}
body#community__talk .thread-comment .trailer .reactions li::before {
  background: url(/f/resources/images/common/icon_heart_white.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 13px;
  width: 14px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
body#community__talk .thread-comment .trailer .reactions li span {
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  line-height: 1;
  margin: 0;
}
body#community__talk .thread-comment .send-report {
  position: relative;
  width: 100%;
}
body#community__talk .thread-comment .show-report-form {
  position: absolute;
  top: -18px;
  right: 0;
}
body#community__talk .thread-comment .report-form {
  top: 8px;
}
body#community__talk .post-thread-comment-wrapper {
  background: #f6f6f6;
  border-radius: 0;
  border: none;
  margin-bottom: 0;
  padding: 40px 45px;
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  body#community__talk .post-thread-comment-wrapper {
    padding: 20px 16px;
  }
}
body#community__talk .post-thread-comment-wrapper .formrow-file input[type=file] {
  border: 0;
}
body#community__talk .signin-to-post {
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
body#community__talk .signin-to-post .signin {
  display: grid;
  place-items: center;
}

/*
  ---------------
  コミュニティ | コメント詳細
  ---------------
*/
body#community__comment .community .link-search {
  background-color: transparent;
  border: none;
  height: 50px;
  padding: 0;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  body#community__comment .community .link-search {
    height: 40px;
  }
}
body#community__comment .comment > .comment-content {
  background-color: #f6f6f6;
  margin-top: 40px;
  padding: 16px 24px;
  position: relative;
}
@media all and (max-width : 768px) {
  body#community__comment .comment > .comment-content {
    margin-top: 20px;
    padding: 14px 16px;
  }
}
body#community__comment .thread-comment {
  display: flex;
  flex-direction: column-reverse;
}
body#community__comment .thread-comment li {
  padding-block: 16px;
  display: flex;
  justify-content: center;
}
body#community__comment .content > .comment-content {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-top: 8px;
  padding: 22px;
}
@media all and (max-width : 768px) {
  body#community__comment .content > .comment-content {
    margin-top: 6px;
    padding: 8px 14px;
  }
}
body#community__comment .supplement {
  position: relative;
}
body#community__comment .supplement .info {
  display: flex;
  flex-direction: column-reverse;
}
body#community__comment .supplement .timestamp {
  color: #b2b2b2;
  font-size: 12px;
}
body#community__comment .supplement .timestamp::before {
  content: "投稿日時：";
}
body#community__comment .supplement .owner-profile {
  font-size: 12px;
  font-weight: 400;
}
body#community__comment .supplement .owner-profile .nickname::before {
  content: "投稿者：";
}
body#community__comment .supplement .trailer {
  padding-top: 8px;
}
body#community__comment .supplement .reactions {
  position: absolute;
  top: 0;
  right: 0;
}
body#community__comment .supplement .reactions li {
  background-color: #005c69;
  border-radius: 2px;
  color: #fff;
  height: 30px;
  padding: 6px 10px 6px 26px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (hover: hover) and (pointer: fine) {
  body#community__comment .supplement .reactions li {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#community__comment .supplement .reactions li:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#community__comment .supplement .reactions li::before, body#community__comment .supplement .reactions li::after {
  backface-visibility: hidden;
}
body#community__comment .supplement .reactions li::before {
  background: url(/f/resources/images/common/icon_heart_white.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 13px;
  width: 14px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
body#community__comment .trailer .link a {
  color: #005c69;
  font-size: 14px;
}

/*
  ---------------
  コミュニティ | 新規スレッドの作成
  ---------------
*/
body#community__newthread .community .link-search,
body#community__threadcreated .community .link-search {
  background-color: transparent;
  border: none;
  height: 50px;
  padding: 0;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  body#community__newthread .community .link-search,
  body#community__threadcreated .community .link-search {
    height: 40px;
    margin-top: 20px;
  }
}
body#community__newthread .create-new-thread,
body#community__threadcreated .create-new-thread {
  padding-top: 40px;
}

body#community__threadcreated h2 {
  margin-top: 40px;
  text-align: center;
}
body#community__threadcreated .link ul {
  padding-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

/*
  ---------------
  コミュニティ共通
  ---------------
*/
body#community__boardlist #outer,
body#community__search #outer,
body#community__commentlist #outer,
body#community__board #outer,
body#community__talk #outer,
body#community__newthread #outer,
body#community__threadcreated #outer,
body#community__comment #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  width: 100%;
}
@media all and (max-width: 768px) {
  body#community__boardlist #outer,
  body#community__search #outer,
  body#community__commentlist #outer,
  body#community__board #outer,
  body#community__talk #outer,
  body#community__newthread #outer,
  body#community__threadcreated #outer,
  body#community__comment #outer {
    padding-inline: 12px;
  }
}
body#community__boardlist #container,
body#community__search #container,
body#community__commentlist #container,
body#community__board #container,
body#community__talk #container,
body#community__newthread #container,
body#community__threadcreated #container,
body#community__comment #container {
  overflow-x: visible;
}
body#community__boardlist #page_aside1,
body#community__search #page_aside1,
body#community__commentlist #page_aside1,
body#community__board #page_aside1,
body#community__talk #page_aside1,
body#community__newthread #page_aside1,
body#community__threadcreated #page_aside1,
body#community__comment #page_aside1 {
  background-color: #d9d9d9;
  margin: 0 calc(50% - 50vw);
  padding-inline: clamp(0px, 2.77777778vw, 40px);
}
@media all and (min-width: 769px) {
  body#community__boardlist #page_aside1,
  body#community__search #page_aside1,
  body#community__commentlist #page_aside1,
  body#community__board #page_aside1,
  body#community__talk #page_aside1,
  body#community__newthread #page_aside1,
  body#community__threadcreated #page_aside1,
  body#community__comment #page_aside1 {
    width: 100vw;
  }
}
@media all and (max-width : 768px) {
  body#community__boardlist #page_aside1,
  body#community__search #page_aside1,
  body#community__commentlist #page_aside1,
  body#community__board #page_aside1,
  body#community__talk #page_aside1,
  body#community__newthread #page_aside1,
  body#community__threadcreated #page_aside1,
  body#community__comment #page_aside1 {
    padding-inline: 12px;
  }
}
body#community__boardlist #page_aside1 .newslist-community-announce,
body#community__search #page_aside1 .newslist-community-announce,
body#community__commentlist #page_aside1 .newslist-community-announce,
body#community__board #page_aside1 .newslist-community-announce,
body#community__talk #page_aside1 .newslist-community-announce,
body#community__newthread #page_aside1 .newslist-community-announce,
body#community__threadcreated #page_aside1 .newslist-community-announce,
body#community__comment #page_aside1 .newslist-community-announce {
  margin-top: 0;
  position: relative;
}
body#community__boardlist #page_aside1 .newslist-community-announce .panel-heading,
body#community__search #page_aside1 .newslist-community-announce .panel-heading,
body#community__commentlist #page_aside1 .newslist-community-announce .panel-heading,
body#community__board #page_aside1 .newslist-community-announce .panel-heading,
body#community__talk #page_aside1 .newslist-community-announce .panel-heading,
body#community__newthread #page_aside1 .newslist-community-announce .panel-heading,
body#community__threadcreated #page_aside1 .newslist-community-announce .panel-heading,
body#community__comment #page_aside1 .newslist-community-announce .panel-heading {
  position: relative;
  z-index: 5;
}
@media (hover: hover) and (pointer: fine) {
  body#community__boardlist #page_aside1 .newslist-community-announce .panel-heading,
  body#community__search #page_aside1 .newslist-community-announce .panel-heading,
  body#community__commentlist #page_aside1 .newslist-community-announce .panel-heading,
  body#community__board #page_aside1 .newslist-community-announce .panel-heading,
  body#community__talk #page_aside1 .newslist-community-announce .panel-heading,
  body#community__newthread #page_aside1 .newslist-community-announce .panel-heading,
  body#community__threadcreated #page_aside1 .newslist-community-announce .panel-heading,
  body#community__comment #page_aside1 .newslist-community-announce .panel-heading {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#community__boardlist #page_aside1 .newslist-community-announce .panel-heading:hover,
  body#community__search #page_aside1 .newslist-community-announce .panel-heading:hover,
  body#community__commentlist #page_aside1 .newslist-community-announce .panel-heading:hover,
  body#community__board #page_aside1 .newslist-community-announce .panel-heading:hover,
  body#community__talk #page_aside1 .newslist-community-announce .panel-heading:hover,
  body#community__newthread #page_aside1 .newslist-community-announce .panel-heading:hover,
  body#community__threadcreated #page_aside1 .newslist-community-announce .panel-heading:hover,
  body#community__comment #page_aside1 .newslist-community-announce .panel-heading:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#community__boardlist #page_aside1 .newslist-community-announce .panel-heading::before, body#community__boardlist #page_aside1 .newslist-community-announce .panel-heading::after,
body#community__search #page_aside1 .newslist-community-announce .panel-heading::before,
body#community__search #page_aside1 .newslist-community-announce .panel-heading::after,
body#community__commentlist #page_aside1 .newslist-community-announce .panel-heading::before,
body#community__commentlist #page_aside1 .newslist-community-announce .panel-heading::after,
body#community__board #page_aside1 .newslist-community-announce .panel-heading::before,
body#community__board #page_aside1 .newslist-community-announce .panel-heading::after,
body#community__talk #page_aside1 .newslist-community-announce .panel-heading::before,
body#community__talk #page_aside1 .newslist-community-announce .panel-heading::after,
body#community__newthread #page_aside1 .newslist-community-announce .panel-heading::before,
body#community__newthread #page_aside1 .newslist-community-announce .panel-heading::after,
body#community__threadcreated #page_aside1 .newslist-community-announce .panel-heading::before,
body#community__threadcreated #page_aside1 .newslist-community-announce .panel-heading::after,
body#community__comment #page_aside1 .newslist-community-announce .panel-heading::before,
body#community__comment #page_aside1 .newslist-community-announce .panel-heading::after {
  backface-visibility: hidden;
}
body#community__boardlist #page_aside1 .panel-heading .news-name,
body#community__search #page_aside1 .panel-heading .news-name,
body#community__commentlist #page_aside1 .panel-heading .news-name,
body#community__board #page_aside1 .panel-heading .news-name,
body#community__talk #page_aside1 .panel-heading .news-name,
body#community__newthread #page_aside1 .panel-heading .news-name,
body#community__threadcreated #page_aside1 .panel-heading .news-name,
body#community__comment #page_aside1 .panel-heading .news-name {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  padding: 14px 0;
  position: relative;
  text-align: left;
}
@media all and (max-width: 768px) {
  body#community__boardlist #page_aside1 .panel-heading .news-name,
  body#community__search #page_aside1 .panel-heading .news-name,
  body#community__commentlist #page_aside1 .panel-heading .news-name,
  body#community__board #page_aside1 .panel-heading .news-name,
  body#community__talk #page_aside1 .panel-heading .news-name,
  body#community__newthread #page_aside1 .panel-heading .news-name,
  body#community__threadcreated #page_aside1 .panel-heading .news-name,
  body#community__comment #page_aside1 .panel-heading .news-name {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  body#community__boardlist #page_aside1 .panel-heading .news-name,
  body#community__search #page_aside1 .panel-heading .news-name,
  body#community__commentlist #page_aside1 .panel-heading .news-name,
  body#community__board #page_aside1 .panel-heading .news-name,
  body#community__talk #page_aside1 .panel-heading .news-name,
  body#community__newthread #page_aside1 .panel-heading .news-name,
  body#community__threadcreated #page_aside1 .panel-heading .news-name,
  body#community__comment #page_aside1 .panel-heading .news-name {
    font-size: 14px;
    padding-inline: 0;
  }
}
body#community__boardlist #page_aside1 .panel-heading .news-name::before,
body#community__search #page_aside1 .panel-heading .news-name::before,
body#community__commentlist #page_aside1 .panel-heading .news-name::before,
body#community__board #page_aside1 .panel-heading .news-name::before,
body#community__talk #page_aside1 .panel-heading .news-name::before,
body#community__newthread #page_aside1 .panel-heading .news-name::before,
body#community__threadcreated #page_aside1 .panel-heading .news-name::before,
body#community__comment #page_aside1 .panel-heading .news-name::before {
  background: url(/f/resources/images/common/icon_plus_circle.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
body#community__boardlist #page_aside1 .panel-heading.is-open .news-name::before,
body#community__search #page_aside1 .panel-heading.is-open .news-name::before,
body#community__commentlist #page_aside1 .panel-heading.is-open .news-name::before,
body#community__board #page_aside1 .panel-heading.is-open .news-name::before,
body#community__talk #page_aside1 .panel-heading.is-open .news-name::before,
body#community__newthread #page_aside1 .panel-heading.is-open .news-name::before,
body#community__threadcreated #page_aside1 .panel-heading.is-open .news-name::before,
body#community__comment #page_aside1 .panel-heading.is-open .news-name::before {
  background: url(/f/resources/images/common/icon_minus_circle_green.svg) 50%/contain no-repeat;
}
body#community__boardlist #page_aside1 .panel-body,
body#community__search #page_aside1 .panel-body,
body#community__commentlist #page_aside1 .panel-body,
body#community__board #page_aside1 .panel-body,
body#community__talk #page_aside1 .panel-body,
body#community__newthread #page_aside1 .panel-body,
body#community__threadcreated #page_aside1 .panel-body,
body#community__comment #page_aside1 .panel-body {
  background-color: #d9d9d9;
  border: none;
  line-height: 0;
  margin: 0;
  max-height: 0;
  padding: 0 clamp(0px, 2.77777778vw, 40px);
  opacity: 0;
  overflow: hidden;
  width: 100vw;
  z-index: 1;
  transition: max-height 0.3s ease-out, line-height 0.3s ease-out, padding 0.3s ease-out, opacity 0.2s ease-out;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
}
@media all and (max-width : 768px) {
  body#community__boardlist #page_aside1 .panel-body,
  body#community__search #page_aside1 .panel-body,
  body#community__commentlist #page_aside1 .panel-body,
  body#community__board #page_aside1 .panel-body,
  body#community__talk #page_aside1 .panel-body,
  body#community__newthread #page_aside1 .panel-body,
  body#community__threadcreated #page_aside1 .panel-body,
  body#community__comment #page_aside1 .panel-body {
    padding-inline: 12px;
    margin: 0;
  }
}
body#community__boardlist #page_aside1 .panel-body.is-open,
body#community__search #page_aside1 .panel-body.is-open,
body#community__commentlist #page_aside1 .panel-body.is-open,
body#community__board #page_aside1 .panel-body.is-open,
body#community__talk #page_aside1 .panel-body.is-open,
body#community__newthread #page_aside1 .panel-body.is-open,
body#community__threadcreated #page_aside1 .panel-body.is-open,
body#community__comment #page_aside1 .panel-body.is-open {
  line-height: 1.5;
  max-height: 500px;
  padding-bottom: 14px;
  opacity: 1;
}
body#community__boardlist #page_aside1 .panel-body .news-entries,
body#community__search #page_aside1 .panel-body .news-entries,
body#community__commentlist #page_aside1 .panel-body .news-entries,
body#community__board #page_aside1 .panel-body .news-entries,
body#community__talk #page_aside1 .panel-body .news-entries,
body#community__newthread #page_aside1 .panel-body .news-entries,
body#community__threadcreated #page_aside1 .panel-body .news-entries,
body#community__comment #page_aside1 .panel-body .news-entries {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-inline: 0;
  max-width: 1200px;
  width: 100%;
}
@media all and (max-width: 768px) {
  body#community__boardlist #page_aside1 .panel-body .news-entries,
  body#community__search #page_aside1 .panel-body .news-entries,
  body#community__commentlist #page_aside1 .panel-body .news-entries,
  body#community__board #page_aside1 .panel-body .news-entries,
  body#community__talk #page_aside1 .panel-body .news-entries,
  body#community__newthread #page_aside1 .panel-body .news-entries,
  body#community__threadcreated #page_aside1 .panel-body .news-entries,
  body#community__comment #page_aside1 .panel-body .news-entries {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  body#community__boardlist #page_aside1 .panel-body .news-entries,
  body#community__search #page_aside1 .panel-body .news-entries,
  body#community__commentlist #page_aside1 .panel-body .news-entries,
  body#community__board #page_aside1 .panel-body .news-entries,
  body#community__talk #page_aside1 .panel-body .news-entries,
  body#community__newthread #page_aside1 .panel-body .news-entries,
  body#community__threadcreated #page_aside1 .panel-body .news-entries,
  body#community__comment #page_aside1 .panel-body .news-entries {
    padding-inline: 0;
  }
}
body#community__boardlist #page_aside1 .panel-body .news-entries .news-entry,
body#community__search #page_aside1 .panel-body .news-entries .news-entry,
body#community__commentlist #page_aside1 .panel-body .news-entries .news-entry,
body#community__board #page_aside1 .panel-body .news-entries .news-entry,
body#community__talk #page_aside1 .panel-body .news-entries .news-entry,
body#community__newthread #page_aside1 .panel-body .news-entries .news-entry,
body#community__threadcreated #page_aside1 .panel-body .news-entries .news-entry,
body#community__comment #page_aside1 .panel-body .news-entries .news-entry {
  border-top: none;
}
body#community__boardlist #page_aside1 .panel-body .entry-text,
body#community__search #page_aside1 .panel-body .entry-text,
body#community__commentlist #page_aside1 .panel-body .entry-text,
body#community__board #page_aside1 .panel-body .entry-text,
body#community__talk #page_aside1 .panel-body .entry-text,
body#community__newthread #page_aside1 .panel-body .entry-text,
body#community__threadcreated #page_aside1 .panel-body .entry-text,
body#community__comment #page_aside1 .panel-body .entry-text {
  padding: 0;
}
body#community__boardlist #page_aside1 .panel-body .entry-text span,
body#community__search #page_aside1 .panel-body .entry-text span,
body#community__commentlist #page_aside1 .panel-body .entry-text span,
body#community__board #page_aside1 .panel-body .entry-text span,
body#community__talk #page_aside1 .panel-body .entry-text span,
body#community__newthread #page_aside1 .panel-body .entry-text span,
body#community__threadcreated #page_aside1 .panel-body .entry-text span,
body#community__comment #page_aside1 .panel-body .entry-text span {
  font-size: 14px;
}
body#community__boardlist .breadcrumbs,
body#community__search .breadcrumbs,
body#community__commentlist .breadcrumbs,
body#community__board .breadcrumbs,
body#community__talk .breadcrumbs,
body#community__newthread .breadcrumbs,
body#community__threadcreated .breadcrumbs,
body#community__comment .breadcrumbs {
  display: none;
}
body#community__boardlist #page-title h1,
body#community__search #page-title h1,
body#community__commentlist #page-title h1,
body#community__board #page-title h1,
body#community__talk #page-title h1,
body#community__newthread #page-title h1,
body#community__threadcreated #page-title h1,
body#community__comment #page-title h1 {
  color: #333;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  margin-top: 24px;
  padding: 7px 0;
}
@media all and (max-width : 768px) {
  body#community__boardlist #page-title h1,
  body#community__search #page-title h1,
  body#community__commentlist #page-title h1,
  body#community__board #page-title h1,
  body#community__talk #page-title h1,
  body#community__newthread #page-title h1,
  body#community__threadcreated #page-title h1,
  body#community__comment #page-title h1 {
    font-size: 20px;
    padding: 3px 0 3px 12px;
  }
}
body#community__boardlist .community-board-header,
body#community__search .community-board-header,
body#community__commentlist .community-board-header,
body#community__board .community-board-header,
body#community__talk .community-board-header,
body#community__newthread .community-board-header,
body#community__threadcreated .community-board-header,
body#community__comment .community-board-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  body#community__boardlist .community-board-header,
  body#community__search .community-board-header,
  body#community__commentlist .community-board-header,
  body#community__board .community-board-header,
  body#community__talk .community-board-header,
  body#community__newthread .community-board-header,
  body#community__threadcreated .community-board-header,
  body#community__comment .community-board-header {
    flex-direction: column;
    margin-top: 20px;
  }
}
body#community__boardlist .news-icon,
body#community__search .news-icon,
body#community__commentlist .news-icon,
body#community__board .news-icon,
body#community__talk .news-icon,
body#community__newthread .news-icon,
body#community__threadcreated .news-icon,
body#community__comment .news-icon {
  text-align: center;
  width: 50%;
}
@media all and (max-width : 768px) {
  body#community__boardlist .news-icon,
  body#community__search .news-icon,
  body#community__commentlist .news-icon,
  body#community__board .news-icon,
  body#community__talk .news-icon,
  body#community__newthread .news-icon,
  body#community__threadcreated .news-icon,
  body#community__comment .news-icon {
    width: 100%;
  }
}
body#community__boardlist .news-icon img,
body#community__search .news-icon img,
body#community__commentlist .news-icon img,
body#community__board .news-icon img,
body#community__talk .news-icon img,
body#community__newthread .news-icon img,
body#community__threadcreated .news-icon img,
body#community__comment .news-icon img {
  object-fit: contain;
  max-height: 200px;
  width: 100%;
}
body#community__boardlist .community-board-header-contents,
body#community__search .community-board-header-contents,
body#community__commentlist .community-board-header-contents,
body#community__board .community-board-header-contents,
body#community__talk .community-board-header-contents,
body#community__newthread .community-board-header-contents,
body#community__threadcreated .community-board-header-contents,
body#community__comment .community-board-header-contents {
  background-color: #f6f6f6;
  padding: 40px;
  width: 50%;
}
@media all and (max-width : 768px) {
  body#community__boardlist .community-board-header-contents,
  body#community__search .community-board-header-contents,
  body#community__commentlist .community-board-header-contents,
  body#community__board .community-board-header-contents,
  body#community__talk .community-board-header-contents,
  body#community__newthread .community-board-header-contents,
  body#community__threadcreated .community-board-header-contents,
  body#community__comment .community-board-header-contents {
    width: 100%;
  }
}

.community .backlink {
  margin-top: -50px;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
@media all and (max-width : 768px) {
  .community .backlink {
    display: inline-block;
    margin-top: 0;
    padding-block: 16px;
    vertical-align: middle;
    width: 49%;
  }
}

.community .backlink a {
  background: #005c69;
  border: none;
  border-radius: 30px;
  box-shadow: none;
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  padding-inline: 16px 38px;
  position: relative;
  display: flex;
  align-items: center;
}
@media (hover: hover) and (pointer: fine) {
  .community .backlink a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .community .backlink a:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.community .backlink a::before, .community .backlink a::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .community .backlink a {
    font-size: 14px;
    height: 40px;
    margin-top: 0;
    padding-inline: 8px 30px;
  }
}

.community .backlink a::before {
  background: transparent url(/f/resources/images/common/icon_arrow_right_circle_white.svg) center/contain no-repeat;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
}
body#community__boardlist .link-search {
  background-color: transparent;
  border: none;
  height: 50px;
  margin-top: -50px;
  padding: 0;
  position: absolute;
  right: 0;
}
@media all and (max-width : 768px) {
  body#community__boardlist .link-search {
    height: 40px;
    margin-top: -40px;
    right: 12px;
  }
}

@media all and (max-width : 768px) {
  body:not(#community__boardlist) .community .link-search {
    display: inline-block;
    height: auto;
    margin-top: 0;
    padding-block: 16px;
    position: static;
    vertical-align: middle;
    width: 49%;
  }
}

body#community__boardlist .link-search a,
body#community__talk .link-search a,
body#community__board .link-search a,
body#community__comment .link-search a,
body#community__newthread .link-search a,
body#community__threadcreated .link-search a,
body#community__commentlist .search-btn button.btn,
body#community__search .search-btn button.btn {
  background: #005c69;
  border: none;
  border-radius: 30px;
  box-shadow: none;
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  padding-right: 22px;
  display: flex;
  align-items: center;
}
@media (hover: hover) and (pointer: fine) {
  body#community__boardlist .link-search a,
  body#community__talk .link-search a,
  body#community__board .link-search a,
  body#community__comment .link-search a,
  body#community__newthread .link-search a,
  body#community__threadcreated .link-search a,
  body#community__commentlist .search-btn button.btn,
  body#community__search .search-btn button.btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#community__boardlist .link-search a:hover,
  body#community__talk .link-search a:hover,
  body#community__board .link-search a:hover,
  body#community__comment .link-search a:hover,
  body#community__newthread .link-search a:hover,
  body#community__threadcreated .link-search a:hover,
  body#community__commentlist .search-btn button.btn:hover,
  body#community__search .search-btn button.btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#community__boardlist .link-search a::before, body#community__boardlist .link-search a::after,
body#community__talk .link-search a::before,
body#community__talk .link-search a::after,
body#community__board .link-search a::before,
body#community__board .link-search a::after,
body#community__comment .link-search a::before,
body#community__comment .link-search a::after,
body#community__newthread .link-search a::before,
body#community__newthread .link-search a::after,
body#community__threadcreated .link-search a::before,
body#community__threadcreated .link-search a::after,
body#community__commentlist .search-btn button.btn::before,
body#community__commentlist .search-btn button.btn::after,
body#community__search .search-btn button.btn::before,
body#community__search .search-btn button.btn::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  body#community__boardlist .link-search a,
  body#community__talk .link-search a,
  body#community__board .link-search a,
  body#community__comment .link-search a,
  body#community__newthread .link-search a,
  body#community__threadcreated .link-search a,
  body#community__commentlist .search-btn button.btn,
  body#community__search .search-btn button.btn {
    font-size: 14px;
    height: 40px;
    margin-top: 0;
    right: 12px;
  }
}

body#community__boardlist .link-search a::before,
body#community__talk .link-search a::before,
body#community__board .link-search a::before,
body#community__comment .link-search a::before,
body#community__newthread .link-search a::before,
body#community__threadcreated .link-search a::before,
body#community__commentlist .search-btn button::before,
body#community__search .search-btn button::before {
  background: transparent url(/f/resources/images/common/icon_search.svg) center/contain no-repeat;
  content: "";
  height: 20px;
  margin: 0 12px 0 18px;
  width: 20px;
}
@media all and (max-width : 768px) {
  body#community__boardlist .link-search a::before,
  body#community__talk .link-search a::before,
  body#community__board .link-search a::before,
  body#community__comment .link-search a::before,
  body#community__newthread .link-search a::before,
  body#community__threadcreated .link-search a::before,
  body#community__commentlist .search-btn button::before,
  body#community__search .search-btn button::before {
    margin: 0 6px 0 10px;
    width: 36px;
  }
}

body#community__board .community-board-mv,
body#community__talk .community-board-mv,
body#community__newthread .community-board-mv,
body#community__comment .community-board-mv {
  padding-top: 30px;
}
body#community__board .community-board-mv .community-board-mv__wrap,
body#community__talk .community-board-mv .community-board-mv__wrap,
body#community__newthread .community-board-mv .community-board-mv__wrap,
body#community__comment .community-board-mv .community-board-mv__wrap {
  display: flex;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-mv .community-board-mv__wrap,
  body#community__talk .community-board-mv .community-board-mv__wrap,
  body#community__newthread .community-board-mv .community-board-mv__wrap,
  body#community__comment .community-board-mv .community-board-mv__wrap {
    display: block;
  }
}
body#community__board .community-board-mv .community-board-mv__visual,
body#community__talk .community-board-mv .community-board-mv__visual,
body#community__newthread .community-board-mv .community-board-mv__visual,
body#community__comment .community-board-mv .community-board-mv__visual {
  width: 50%;
  display: grid;
  place-items: center;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-mv .community-board-mv__visual,
  body#community__talk .community-board-mv .community-board-mv__visual,
  body#community__newthread .community-board-mv .community-board-mv__visual,
  body#community__comment .community-board-mv .community-board-mv__visual {
    width: 100%;
  }
}
body#community__board .community-board-mv .community-board-mv__img,
body#community__talk .community-board-mv .community-board-mv__img,
body#community__newthread .community-board-mv .community-board-mv__img,
body#community__comment .community-board-mv .community-board-mv__img {
  width: 100%;
}
body#community__board .community-board-mv .community-board-mv__text,
body#community__talk .community-board-mv .community-board-mv__text,
body#community__newthread .community-board-mv .community-board-mv__text,
body#community__comment .community-board-mv .community-board-mv__text {
  background-color: #f6f6f6;
  padding: 50px 40px;
  width: 50%;
}
@media all and (max-width : 768px) {
  body#community__board .community-board-mv .community-board-mv__text,
  body#community__talk .community-board-mv .community-board-mv__text,
  body#community__newthread .community-board-mv .community-board-mv__text,
  body#community__comment .community-board-mv .community-board-mv__text {
    padding: 12px 15px;
    width: 100%;
  }
}
body#community__board .community-board-mv .community-board-mv__text-inner,
body#community__talk .community-board-mv .community-board-mv__text-inner,
body#community__newthread .community-board-mv .community-board-mv__text-inner,
body#community__comment .community-board-mv .community-board-mv__text-inner {
  font-size: 14px;
  line-height: 2.1;
}

.thread-comment .trailer {
  border: none;
  padding: 12px 0 0;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.thread-comment .trailer .send-report {
  position: relative;
}

.thread-comment .trailer .report-form {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  max-width: 500px;
  padding: 16px;
  width: 90vw;
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 1;
}
@media all and (max-width : 768px) {
  .thread-comment .trailer .report-form {
    right: -14px;
  }
}

.thread-comment .trailer .report-form div.btn-submit {
  padding-bottom: 12px;
}

.thread-comment .trailer .send-report > button {
  background: none;
  border: none;
  box-shadow: none;
  color: #929292 !important;
  font-size: 12px;
  padding: 0;
  position: relative;
}
@media (hover: hover) and (pointer: fine) {
  .thread-comment .trailer .send-report > button {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .thread-comment .trailer .send-report > button:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.thread-comment .trailer .send-report > button::before, .thread-comment .trailer .send-report > button::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .thread-comment .trailer .send-report > button {
    font-size: 14px;
  }
}

.thread-comment .trailer .send-report > button::before {
  background: url(/f/resources/images/common/icon_attention_mark.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 14px;
  width: 14px;
  position: absolute;
  top: 50%;
  left: -18px;
  transform: translateY(-50%);
}

.thread-comment .trailer .comment-link {
  margin: 0 auto 0 0;
}

.thread-comment .trailer .comment-link a {
  color: #005c69;
  font-size: 14px;
}

body#community__talk .thread-title,
body#community__comment .thread-title {
  border: 1px solid #d9d9d9;
  margin-top: 40px;
  padding: 24px;
}
@media all and (max-width : 768px) {
  body#community__talk .thread-title,
  body#community__comment .thread-title {
    padding: 16px;
  }
}
body#community__talk .thread-title::before,
body#community__comment .thread-title::before {
  display: none;
}
body#community__talk .thread-title h3,
body#community__comment .thread-title h3 {
  font-size: 24px;
  padding: 0;
}
@media all and (max-width : 768px) {
  body#community__talk .thread-title h3,
  body#community__comment .thread-title h3 {
    font-size: 22px;
  }
}
body#community__talk .thread-comment ul.images,
body#community__comment .thread-comment ul.images {
  padding-top: 10px;
}

/* リアクション（評価）| Reactions */
.reactions {
  display: flex;
}

.reactions > li {
  border: 1px solid #222;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 2px;
}

.reactions > li + li {
  margin-left: 20px;
}

.reactions .count {
  font-weight: bold;
  margin-left: 4px;
}

.community-anchor-link {
  background-color: #fff;
  margin: auto calc((50vw - 50%) * -1);
}

.community-anchor-link__wrap {
  padding-block: 40px;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
}
@media all and (max-width : 768px) {
  .community-anchor-link__wrap {
    padding-block: 20px;
  }
}

.community-anchor-link ul {
  width: 100%;
  display: flex;
  gap: 16px;
}
@media all and (max-width : 768px) {
  .community-anchor-link ul {
    flex-wrap: wrap;
  }
}

.community-anchor-link ul li {
  margin-right: 20px;
}

.community-anchor-link button {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  .community-anchor-link button {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .community-anchor-link button:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.community-anchor-link button::before, .community-anchor-link button::after {
  backface-visibility: hidden;
}

/*
  ---------------
  カート | トップ
  ---------------
*/
body#cart .cart-contents > h3 {
  margin-top: 22px;
  padding: 0;
}
body#cart .itemdetails-cart {
  margin-top: 30px;
}
@media all and (max-width : 768px) {
  body#cart .itemdetails-cart {
    padding: 0;
  }
}
body#cart .itemdetails-cart + .itemdetails-cart {
  margin-top: 40px;
}
body#cart .itemdetails-cart > h3 {
  background-color: #d9d9d9;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 18px;
}
body#cart .itemdetails-cart.pwk-exclusive .itemdetail-row:not([data-distribution*=UPS]) .entry-item .item-name > a::after,
body#cart .itemdetails-cart.pwk-exclusive .itemdetail-row:not([data-distribution*=UPS]) .entry-item .item-name > .my-item-name::after {
  content: "から定期購読";
}
body#cart .cart-msgs.posterior {
  padding: 20px 0;
}
body#cart .cart-message-for-dlv-fee .message {
  background-color: #f6f6f6;
  border: 1px solid #005c69;
  color: #005c69;
  font-size: 18px;
  font-weight: 500;
  padding: 12px;
  text-align: center;
}
body#cart .cart-msg-no_payments {
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  body#cart .cart-msgs .note {
    font-size: 14px;
  }
}
body#cart div.alert.alert-cart-empty {
  padding: 40px 20px;
  margin: 40px 0;
}
@media all and (max-width : 768px) {
  body#cart div.alert.alert-cart-empty {
    padding: 20px 10px;
    margin: 20px 0;
  }
}
body#cart .cart-contents-saved > h3 {
  font-size: 20px;
  font-weight: 500;
  padding: 0;
}
@media all and (max-width : 768px) {
  body#cart .cart-contents-saved > h3 {
    font-size: 18px;
  }
}
body#cart .cart-contents-saved {
  padding-top: 50px;
}
body#cart .cart-contents-saved .itemdetails-header {
  display: none;
}
body#cart .cart-contents-saved .itemdetails-body {
  margin-top: 16px;
}
@media (max-width: 800px) {
  body#cart .cart-contents-saved .itemdetails-body {
    margin-top: 12px;
  }
}
@media all and (max-width : 768px) {
  body#cart .cart-contents-saved .itemdetail-row .item .control {
    position: relative;
    bottom: unset;
  }
}
@media all and (max-width : 768px) {
  body#cart .cart-contents-saved .itemdetail-row .item .wrapper {
    flex-direction: row-reverse;
    gap: 20px;
  }
}
body#cart .display-box-related-cart {
  padding-top: 40px;
}
@media all and (max-width : 768px) {
  body#cart .display-box-related-cart {
    padding-top: 50px;
  }
}
body#cart .display-box-related-cart .panel-heading .display-name {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
}
@media all and (max-width : 768px) {
  body#cart .display-box-related-cart .panel-heading .display-name {
    font-size: 18px;
  }
}
body#cart .display-box-related-cart .item-thumbnail-img-sm {
  height: auto;
  width: 100%;
  aspect-ratio: 1/1;
}
body#cart .display-box-related-cart .item-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
}
body#cart .display-box-related-cart .display .item-name {
  font-size: 14px;
  margin-top: 16px;
  padding: 0;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}
body#cart .display-box-related-cart .display .selling-price {
  font-size: 14px;
  font-weight: 500;
}
@media all and (max-width : 768px) {
  body#cart .display-box-related-cart .slick-next {
    right: -13px;
  }
}
@media all and (max-width : 768px) {
  body#cart .display-box-related-cart .slick-prev {
    left: -13px;
  }
}

/*
  ---------------
  カート | 共通
  ---------------
*/
/* カート 商品詳細 | Cart itemdetails */
.itemdetails .distribution .dstr-title {
  font-size: 16px;
}

.itemdetails .parcel .label {
  font-size: 16px;
}

body[class*=community][class*=HOBICOM] {
  background: #444;
  color: #fff;
}

body[class*=community][class*=HOBICOM] a {
  color: #cfc;
}

body[class*=community][class*=HOBICOM] .info,
body[class*=community][class*=HOBICOM] .summary,
body[class*=community][class*=HOBICOM] .thread-comment .header {
  background: inherit;
}

body[class*=community][class*=HOBICOM] .text {
  color: #ccc;
}

body[class*=community][class*=HOBICOM] ol.comments {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

body[class*=community][class*=HOBICOM] ol.comments > li {
  width: 340px;
  padding: 1rem;
}

body[class*=community][class*=HOBICOM] ol.comments > li > div.thread-comment {
  border: 1px solid #ccc;
}

div.distribution div.subscribe span.selling-price {
  display: none;
}

/*-- Cart View -- */
div.cartrow > div.item div.cartitem-basic-control > div {
  display: inline;
  font-size: 11px;
  white-space: nowrap;
}

div.cartrow > div.item div.rp-alternatives {
  font-size: 11px;
}

div.cartrow span.curr-qty,
div.cartrow div.subtotal span.price {
  font-size: 110%;
}

div.cartrow > div.item div.rp-alternatives div.header {
  color: #a00;
}

div.cartrow > div.item div.rp-alternatives ul {
  padding: 0 0 1em 2em;
}

div.cartrow > div.item div.rp-alternatives ul li {
  list-style-type: disc;
}

div.cartrow > div.qty div.show-update-qty {
  padding: 0 1em;
}

div.cart-checkout {
  text-align: center;
}

div.cart-checkout-ng button.btn-checkout {
  color: #fff !important;
  cursor: no-drop;
  opacity: 0.4;
  pointer-events: none;
}

div.cart-checkout-btn-group > div {
  margin: 1em;
}

div.cartitem div.item-code,
div.cartitem div.item-name {
  display: inline-block;
  font-size: 14px;
}

div.rp-conf-wrapper div.rp-opts div.input-group {
  display: inline-block;
}

div.cart-clear-all {
  padding: 1em 0;
  text-align: right;
}

/*-- Cart View End -- */
body#cart #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  body#cart #outer {
    padding-inline: 12px;
  }
}
body#cart h1 {
  font-size: 24px;
  font-weight: 400;
  padding: 0;
}
body#cart .itemdetails-body .goods.entry-item .goods-text > .purchases-messages .stock-message-none .stock-msg-dtl {
  color: #c10000;
}

.update-channel {
  padding-bottom: 40px;
}
@media all and (max-width : 768px) {
  .update-channel {
    padding-block: 20px 40px;
  }
}

.update-channel__wrap {
  padding: 0 0 20px 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
@media all and (max-width : 768px) {
  .update-channel__wrap {
    padding: 10px 10px 0 10px;
    flex-wrap: wrap;
  }
}

.update-channel__title {
  font-size: 14px;
  padding-bottom: 4px;
}

.update-channel form {
  width: 100%;
  display: flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .update-channel form {
    width: 100%;
  }
}

.update-channel__input-box {
  width: 50%;
}
@media all and (max-width : 768px) {
  .update-channel__input-box {
    width: 100%;
  }
}

.update-channel__input {
  border: 1px solid #d9d9d9;
  border-right: 0;
  border-radius: 2px 0 0 2px;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px;
  max-width: 500px;
  width: 100%;
}
@media all and (max-width : 768px) {
  .update-channel__input {
    max-width: 100%;
  }
}

input.update-channel__submit {
  background-color: #ff9c08;
  border: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 16px;
  text-align: center;
}

.update-channel__result {
  padding-bottom: 10px;
  width: 50%;
}
@media all and (max-width : 768px) {
  .update-channel__result {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
}

@media all and (max-width : 768px) {
  .update-channel__result #message {
    padding-bottom: 10px;
  }
}

.update-channel__result .result-success {
  color: #00ac5a;
}

/*
  ----------------
  エラーページ
  ----------------
*/
#error #outer,
#error-page #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 40px;
}
@media all and (max-width: 768px) {
  #error #outer,
  #error-page #outer {
    padding-inline: 12px;
  }
}
#error h1,
#error-page h1 {
  font-size: 24px;
  font-weight: 400;
  padding: 0;
}
#error #container,
#error-page #container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#error .system-error,
#error-page .system-error {
  padding-top: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
@media all and (max-width : 768px) {
  #error .system-error,
  #error-page .system-error {
    padding-top: 40px;
  }
}

/*
  ---------------
  注文内容確認
  ** 一部SPのブレイクポイントを900pxに変更（レイアウト崩れるため）
  ---------------
*/
body#account__view_orders .order-valid,
body#order__confirm {
  padding-top: 0;
  /*--------------------------------------------------
    購入者情報、お支払い方法、その他共通
  --------------------------------------------------*/
  /*--------------------------------------------------
    配送情報
  --------------------------------------------------*/
  /*--------------------------------------------------
    テーブル
  --------------------------------------------------*/
  /*--------------------------------------------------
   出荷関連
  --------------------------------------------------*/
  /*==========================================
    購入時注意事項
  ==========================================*/
}
body#account__view_orders .order-valid #order-entry #order-entry-order,
body#account__view_orders .order-valid #order-entry .shipments .shipment-entry-adr,
body#order__confirm #order-entry #order-entry-order,
body#order__confirm #order-entry .shipments .shipment-entry-adr {
  padding-bottom: 0;
  display: flex;
  gap: 30px;
}
@media all and (max-width: 900px) {
  body#account__view_orders .order-valid #order-entry #order-entry-order,
  body#account__view_orders .order-valid #order-entry .shipments .shipment-entry-adr,
  body#order__confirm #order-entry #order-entry-order,
  body#order__confirm #order-entry .shipments .shipment-entry-adr {
    flex-direction: column;
    align-items: flex-start;
  }
}
body#account__view_orders .order-valid #order-entry-content h2,
body#order__confirm #order-entry-content h2 {
  font-size: 20px;
  font-weight: 500;
}
body#account__view_orders .order-valid #order-entry #order-entry-content .panel,
body#order__confirm #order-entry #order-entry-content .panel {
  border: 1px solid #d9d9d9;
  margin: 0;
}
@media all and (max-width: 900px) {
  body#account__view_orders .order-valid #order-entry #order-entry-content .panel,
  body#order__confirm #order-entry #order-entry-content .panel {
    width: 100%;
  }
}
body#account__view_orders .order-valid #order-entry .panel .header,
body#order__confirm #order-entry .panel .header {
  background: #f6f6f6;
  padding: 13px 10px 14px 20px;
}
body#account__view_orders .order-valid #order-entry .panel .body,
body#order__confirm #order-entry .panel .body {
  padding: 16px 20px;
}
body#account__view_orders .order-valid #order-entry .panel .partwork-subscription > .body,
body#order__confirm #order-entry .panel .partwork-subscription > .body {
  position: fixed;
  padding: 0 0 22px;
}
body#account__view_orders .order-valid #order-entry .panel .partwork-subscription > .body .body,
body#order__confirm #order-entry .panel .partwork-subscription > .body .body {
  padding: 0;
}
body#account__view_orders .order-valid #order-entry .panel .body .person-contents,
body#order__confirm #order-entry .panel .body .person-contents {
  padding: 0;
}
body#account__view_orders .order-valid #order-entry-content .panel > .body .control,
body#order__confirm #order-entry-content .panel > .body .control {
  padding-top: 10px;
  position: relative;
  top: unset;
  right: unset;
  transform: unset;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid #order-entry-content .panel > .body .control,
  body#order__confirm #order-entry-content .panel > .body .control {
    flex-direction: column;
  }
}
body#account__view_orders .order-valid #order-entry-content .panel > .body .control .btn,
body#account__view_orders .order-valid #order-entry-content .panel > .body .control a,
body#order__confirm #order-entry-content .panel > .body .control .btn,
body#order__confirm #order-entry-content .panel > .body .control a {
  font-size: 13px;
  margin-bottom: 0;
  min-width: auto;
  padding: 10px 0;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid #order-entry-content .panel > .body .control .btn,
  body#account__view_orders .order-valid #order-entry-content .panel > .body .control a,
  body#order__confirm #order-entry-content .panel > .body .control .btn,
  body#order__confirm #order-entry-content .panel > .body .control a {
    text-align: center;
    width: 100%;
  }
}
body#account__view_orders .order-valid #order-entry-content .panel > .body .control > a, body#account__view_orders .order-valid #order-entry-content .panel > .body .control > form,
body#order__confirm #order-entry-content .panel > .body .control > a,
body#order__confirm #order-entry-content .panel > .body .control > form {
  width: 32.352941%;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid #order-entry-content .panel > .body .control > a, body#account__view_orders .order-valid #order-entry-content .panel > .body .control > form,
  body#order__confirm #order-entry-content .panel > .body .control > a,
  body#order__confirm #order-entry-content .panel > .body .control > form {
    margin-top: 8px;
    width: 100%;
  }
}
body#account__view_orders .order-valid #order-entry div.panel .control,
body#order__confirm #order-entry div.panel .control {
  top: 9px;
}
body#account__view_orders .order-valid .payment.panel .body,
body#order__confirm .payment.panel .body {
  min-height: 160px;
}
body#account__view_orders .order-valid .person-contents .names .kana,
body#order__confirm .person-contents .names .kana {
  color: #747474;
  font-size: 12px;
  font-style: normal;
}
body#account__view_orders .order-valid .person-contents .names .name,
body#order__confirm .person-contents .names .name {
  font-size: 16px;
  font-weight: 500;
}
body#account__view_orders .order-valid .person-contents .addresses,
body#order__confirm .person-contents .addresses {
  margin-top: 12px;
}
body#account__view_orders .order-valid .person-contents .addresses .place,
body#account__view_orders .order-valid .person-contents .phone-numbers .phone-number,
body#account__view_orders .order-valid .person-contents .email,
body#order__confirm .person-contents .addresses .place,
body#order__confirm .person-contents .phone-numbers .phone-number,
body#order__confirm .person-contents .email {
  font-size: 14px;
}
body#account__view_orders .order-valid .payment .use-points-amount,
body#account__view_orders .order-valid .payment .payment-method,
body#order__confirm .payment .use-points-amount,
body#order__confirm .payment .payment-method {
  font-size: 14px;
}
body#account__view_orders .order-valid .payment .available-points-amount,
body#order__confirm .payment .available-points-amount {
  color: #747474;
  font-size: 12px;
}
body#account__view_orders .order-valid .payment .payment-total-invoice,
body#order__confirm .payment .payment-total-invoice {
  font-size: 20px;
  margin-top: 16px;
}
body#account__view_orders .order-valid #detach-coupons .wrapper,
body#order__confirm #detach-coupons .wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
body#account__view_orders .order-valid #detach-coupons .wrapper .btn,
body#order__confirm #detach-coupons .wrapper .btn {
  margin-top: 8px;
}
body#account__view_orders .order-valid .shipments,
body#order__confirm .shipments {
  padding-top: 20px;
}
body#account__view_orders .order-valid .shipments .shipment-list,
body#order__confirm .shipments .shipment-list {
  padding-top: 24px;
}
body#account__view_orders .order-valid .shipments .shipment,
body#order__confirm .shipments .shipment {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
body#account__view_orders .order-valid .shipments .shipment-each > h3,
body#order__confirm .shipments .shipment-each > h3 {
  background: #d9d9d9;
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 30px;
  padding: 10px 18px;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid .shipments .shipment-each > h3,
  body#order__confirm .shipments .shipment-each > h3 {
    margin-bottom: 20px;
    padding: 4px 12px;
  }
}
body#account__view_orders .order-valid .shipments .shipment-each + .shipment-each,
body#order__confirm .shipments .shipment-each + .shipment-each {
  margin-top: 60px;
}
body#account__view_orders .order-valid #order-entry #order-entry-content .shipment-content,
body#order__confirm #order-entry #order-entry-content .shipment-content {
  margin-top: 30px;
  padding-bottom: 20px;
}
body#account__view_orders .order-valid .shipment-content h4,
body#order__confirm .shipment-content h4 {
  font-size: 16px;
  font-weight: 500;
  max-width: 135px;
  padding: 0;
}
body#account__view_orders .order-valid .shipment ul.delivery-service-conf li > h4 + div,
body#order__confirm .shipment ul.delivery-service-conf li > h4 + div {
  font-size: 16px;
  padding-left: 0;
}
body#account__view_orders .order-valid .shipment-content ul.delivery-service-conf,
body#order__confirm .shipment-content ul.delivery-service-conf {
  margin-inline: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
body#account__view_orders .order-valid .select-delivery-service ul.ds-available,
body#order__confirm .select-delivery-service ul.ds-available {
  padding-top: 10px;
  justify-content: flex-start;
  gap: 10px;
}
body#account__view_orders .order-valid .select-delivery-service ul.ds-available .ds-available,
body#order__confirm .select-delivery-service ul.ds-available .ds-available {
  border: none;
  margin: 0;
  width: 200px;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
}
body#account__view_orders .order-valid .select-delivery-service ul.ds-available .ds-available .ds-desc,
body#order__confirm .select-delivery-service ul.ds-available .ds-available .ds-desc {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
}
body#account__view_orders .order-valid .select-delivery-service ul.ds-available li label,
body#order__confirm .select-delivery-service ul.ds-available li label {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 8px;
}
@media (hover: hover) and (pointer: fine) {
  body#account__view_orders .order-valid .select-delivery-service ul.ds-available li label,
  body#order__confirm .select-delivery-service ul.ds-available li label {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  body#account__view_orders .order-valid .select-delivery-service ul.ds-available li label:hover,
  body#order__confirm .select-delivery-service ul.ds-available li label:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
body#account__view_orders .order-valid .select-delivery-service ul.ds-available li label::before, body#account__view_orders .order-valid .select-delivery-service ul.ds-available li label::after,
body#order__confirm .select-delivery-service ul.ds-available li label::before,
body#order__confirm .select-delivery-service ul.ds-available li label::after {
  backface-visibility: hidden;
}
body#account__view_orders .order-valid .select-delivery-service ul.ds-available li label input,
body#order__confirm .select-delivery-service ul.ds-available li label input {
  accent-color: #005c69;
  border: 1px solid #d9d9d9;
  margin: 0;
  height: 20px;
  width: 20px;
}
body#account__view_orders .order-valid #order-entry .itemdetails .itemdetails-caption,
body#order__confirm #order-entry .itemdetails .itemdetails-caption {
  padding-left: 0;
}
body#account__view_orders .order-valid #order-entry .itemdetails .itemdetails-caption h3,
body#order__confirm #order-entry .itemdetails .itemdetails-caption h3 {
  font-size: 20px;
  font-weight: 500;
  padding: 0;
}
body#account__view_orders .order-valid #order-entry .itemdetails .itemdetails-caption .msg-add-item,
body#order__confirm #order-entry .itemdetails .itemdetails-caption .msg-add-item {
  color: #005c69;
  font-size: 14px;
  line-height: 1.428567143;
  letter-spacing: 0em;
  padding-block: 4px 0;
}
body#account__view_orders .order-valid .itemdetails-header .itemdetail-row .purchase-contents,
body#order__confirm .itemdetails-header .itemdetail-row .purchase-contents {
  padding-top: 0;
}
body#account__view_orders .order-valid .itemdetail-row[data-distribution]:not([data-distribution*=UPS]) .entry-item .item-name > a::after,
body#account__view_orders .order-valid .itemdetail-row[data-distribution]:not([data-distribution*=UPS]) .entry-item .item-name > .my-item-name::after,
body#order__confirm .itemdetail-row[data-distribution]:not([data-distribution*=UPS]) .entry-item .item-name > a::after,
body#order__confirm .itemdetail-row[data-distribution]:not([data-distribution*=UPS]) .entry-item .item-name > .my-item-name::after {
  content: "から定期購読";
}
body#account__view_orders .order-valid .itemdetail-row .purchase-contents .subtotal,
body#order__confirm .itemdetail-row .purchase-contents .subtotal {
  font-size: 16px;
}
body#account__view_orders .order-valid .itemdetail-row .purchase-contents,
body#order__confirm .itemdetail-row .purchase-contents {
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 0;
}
body#account__view_orders .order-valid .itemdetail-row .purchase-contents .subtotal .amount,
body#order__confirm .itemdetail-row .purchase-contents .subtotal .amount {
  white-space: nowrap;
}
body#account__view_orders .order-valid .itemdetail-row .purchase-contents .quantity .remove-one,
body#order__confirm .itemdetail-row .purchase-contents .quantity .remove-one {
  padding-left: 17px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid .itemdetail-row .purchase-contents .quantity .remove-one,
  body#order__confirm .itemdetail-row .purchase-contents .quantity .remove-one {
    bottom: 20px;
  }
}
body#account__view_orders .order-valid .remove-one .btn,
body#order__confirm .remove-one .btn {
  color: #005c69;
  font-size: 14px;
  position: relative;
}
body#account__view_orders .order-valid .remove-one .btn::before,
body#order__confirm .remove-one .btn::before {
  content: "";
  background: url(/f/resources/images/common/icon_remove.svg) 50%/contain no-repeat;
  display: block;
  height: 12px;
  width: 11px;
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
}
body#account__view_orders .order-valid .price-total,
body#order__confirm .price-total {
  max-width: fit-content;
  padding: 0 16px 0 0;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid .price-total,
  body#order__confirm .price-total {
    margin-left: 16px;
  }
}
body#account__view_orders .order-valid .price-total .row,
body#order__confirm .price-total .row {
  display: flex;
  align-items: center;
}
body#account__view_orders .order-valid .price-total .shipment-total-qty,
body#account__view_orders .order-valid .price-total .label,
body#order__confirm .price-total .shipment-total-qty,
body#order__confirm .price-total .label {
  font-size: 16px;
  font-weight: 700;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid .price-total .shipment-total-qty,
  body#account__view_orders .order-valid .price-total .label,
  body#order__confirm .price-total .shipment-total-qty,
  body#order__confirm .price-total .label {
    font-size: 14px;
    padding-right: 12px;
    white-space: nowrap;
  }
}
body#account__view_orders .order-valid .price-total .subtotal,
body#order__confirm .price-total .subtotal {
  margin-left: 40px;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid .price-total .subtotal,
  body#order__confirm .price-total .subtotal {
    margin-left: 0;
  }
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid .price-total .subtotal .price,
  body#order__confirm .price-total .subtotal .price {
    font-size: 16px;
  }
}
body#account__view_orders .order-valid .present-applied,
body#order__confirm .present-applied {
  background: #f6f6f6;
  margin: 20px 16px 16px;
  padding: 18px 22px;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid .present-applied,
  body#order__confirm .present-applied {
    padding: 15px;
    margin-top: 10px;
  }
}
body#account__view_orders .order-valid .present-applied .caption,
body#order__confirm .present-applied .caption {
  color: #005c69;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.428567143;
}
body#account__view_orders .order-valid .list.present-list,
body#order__confirm .list.present-list {
  padding-top: 8px;
}
body#account__view_orders .order-valid .item.present,
body#order__confirm .item.present {
  background: #f6f6f6;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.428567143;
  letter-spacing: 0em;
  padding-block: 15px;
  padding-inline: 10px 16px;
}
body#account__view_orders .order-valid .present-applied .name-and-code,
body#order__confirm .present-applied .name-and-code {
  padding-left: 43px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
body#account__view_orders .order-valid .present-applied .name-and-code::before,
body#account__view_orders .order-valid .present-applied .name-and-code::after,
body#order__confirm .present-applied .name-and-code::before,
body#order__confirm .present-applied .name-and-code::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
body#account__view_orders .order-valid .present-applied .name-and-code::before,
body#order__confirm .present-applied .name-and-code::before {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  left: 0;
}
body#account__view_orders .order-valid .present-applied .name-and-code::after,
body#order__confirm .present-applied .name-and-code::after {
  background: transparent url("/f/resources/images/common/icon_checked.svg") center/contain no-repeat;
  width: 16px;
  height: 11px;
  left: 7px;
}
body#account__view_orders .order-valid form#checkout_form .checkout,
body#order__confirm form#checkout_form .checkout {
  text-align: center;
  padding: 40px 12px 0;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid form#checkout_form .checkout,
  body#order__confirm form#checkout_form .checkout {
    padding-top: 30px;
  }
}
body#account__view_orders .order-valid .btn-moveitem,
body#order__confirm .btn-moveitem {
  display: flex;
  justify-content: center;
}
body#account__view_orders .order-valid .move-item-wrapper .note,
body#order__confirm .move-item-wrapper .note {
  color: #c10000;
  font-size: 14px;
}
body#account__view_orders .order-valid .move-item-dst,
body#order__confirm .move-item-dst {
  background: transparent;
  padding: 0;
}
body#account__view_orders .order-valid .move-item-dst > li,
body#order__confirm .move-item-dst > li {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 28px 0;
  position: relative;
}
body#account__view_orders .order-valid .move-item-dst > li::before,
body#order__confirm .move-item-dst > li::before {
  background: #fff url() 50%/contain no-repeat;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  content: "";
  display: block;
  height: 28px;
  width: 28px;
  position: absolute;
  top: 52px;
  left: 22px;
  transform: translateY(-50%);
}
body#account__view_orders .order-valid .move-item-dst li.active::before,
body#order__confirm .move-item-dst li.active::before {
  background-image: url(/f/resources/images/common/icon_checkbox_checkmark.svg);
  background-size: 30px;
  background-position: 50%;
}
body#account__view_orders .order-valid .move-item-dst > li > .label,
body#order__confirm .move-item-dst > li > .label {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  padding-inline: 74px 20px;
}
body#account__view_orders .order-valid .move-item-dst > li > .note,
body#order__confirm .move-item-dst > li > .note {
  color: #333;
  font-size: 16px;
  padding-inline: 74px 20px;
  margin: 0;
}
body#account__view_orders .order-valid .new-destination-consignee .addressbook,
body#order__confirm .new-destination-consignee .addressbook {
  max-width: 100%;
  padding-top: 34px;
  text-align: center;
}
body#account__view_orders .order-valid .move-item-dst li .form-body,
body#order__confirm .move-item-dst li .form-body {
  border-top: 1px solid #d9d9d9;
  padding: 30px 24px 0;
}
body#account__view_orders .order-valid .purchase-contents .move-item-wrapper-item .move-item-qty-select,
body#order__confirm .purchase-contents .move-item-wrapper-item .move-item-qty-select {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: column;
}
body#account__view_orders .order-valid .purchase-contents .move-item-wrapper-item .move-item-qty-select > span,
body#order__confirm .purchase-contents .move-item-wrapper-item .move-item-qty-select > span {
  color: #c10000;
}
body#account__view_orders .order-valid .purchase-contents .move-item-wrapper-item .move-item-qty-select select,
body#order__confirm .purchase-contents .move-item-wrapper-item .move-item-qty-select select {
  font-size: 14px;
  font-weight: 400;
  margin-left: 0;
  max-width: 150px;
}
body#account__view_orders .order-valid #separate-billing-msg > li,
body#order__confirm #separate-billing-msg > li {
  background-color: #f6f6f6;
  border: 1px solid #005c69;
  color: #005c69;
  margin-top: 16px;
  padding: 8px;
  text-align: center;
}
@media all and (max-width : 768px) {
  body#account__view_orders .order-valid #separate-billing-msg > li,
  body#order__confirm #separate-billing-msg > li {
    font-size: 14px;
  }
}
body#account__view_orders .order-valid #separate-billing-msg > li + li,
body#order__confirm #separate-billing-msg > li + li {
  display: none;
}
body#account__view_orders .order-valid #checkout-control .order-properties > .formrow,
body#order__confirm #checkout-control .order-properties > .formrow {
  align-items: center;
  gap: 10px;
}
body#account__view_orders .order-valid #checkout-control .order-properties .input-label,
body#order__confirm #checkout-control .order-properties .input-label {
  flex: 0 1 auto;
}
body#account__view_orders .order-valid #checkout-control .order-properties .input-control,
body#order__confirm #checkout-control .order-properties .input-control {
  flex: 0 1 auto;
  flex-direction: row;
}
body#account__view_orders .order-valid #checkout-control .order-properties .input-control .input-group,
body#order__confirm #checkout-control .order-properties .input-control .input-group {
  flex: 1 1 100%;
}
body#account__view_orders .order-valid .order-entry-processing,
body#order__confirm .order-entry-processing {
  border: 1px solid #d9d9d9;
  padding: 20px 16px 0;
  display: grid;
  place-items: center;
}

/*
  ---------------
  注文完了
  ---------------
*/
body#order__complete {
  padding-top: 0;
}
body#order__complete .order-process-main-complete h1 {
  border-bottom: 1px solid #1d1d1b;
  padding-bottom: 10px;
  text-align: left;
}
body#order__complete .order-process-main-complete .main-message {
  text-align: left;
}
body#order__complete .order-process-main-complete .main-contents {
  margin-top: 0;
  text-align: left;
}
body#order__complete div.order-code {
  margin-top: 32px;
}
body#order__complete span.order-code {
  display: block;
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 30px;
  font-weight: 700;
  margin-top: 12px;
  padding: 25px;
  text-align: center;
}
body#order__complete .order-process-main-complete .view-order-detail {
  margin-top: 66px;
  text-align: center;
}
body#order__complete .request-paymentmethod-reset {
  margin-top: 20px;
}
body#order__complete .request-paymentmethod-reset h3 {
  background-color: #ffebeb;
  border: 1px solid #c10000;
  color: #c10000;
  text-align: center;
}
@media all and (max-width : 768px) {
  body#order__complete .request-paymentmethod-reset h3 {
    font-size: 16px;
  }
}
body#order__complete .request-paymentmethod-reset .form-reset-paymentmethod {
  text-align: center;
}
body#order__complete .request-paymentmethod-reset .description {
  text-align: left;
}
body#order__complete .request-paymentmethod-reset button.btn {
  margin: 20px 0;
}
body#order__complete .complete-with-pgfailed #page-title h1 {
  display: none;
}
body#order__complete .complete-with-pgfailed .main-message {
  border-bottom: 1px solid #1d1d1b;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
  line-height: 1.45833333;
}
body#order__complete .complete-with-pgfailed .main-contents #order-code {
  display: none;
}
body#order__complete .complete-with-pgfailed .main-contents .message {
  padding-top: 20px;
}
body#order__complete .complete-with-pgfailed .main-contents .view-order-detail {
  margin-top: 20px;
}

/*
  ---------------
  決済方法選択
  ---------------
*/
@media all and (max-width : 768px) {
  body#order__payment .form-payment {
    padding-inline: 12px;
  }
}
body#order__payment #select-payment-method {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 40px;
}
body#order__payment h2 {
  background: #f6f6f6;
  font-size: 16px;
  font-weight: 400;
  padding: 14px 18px;
}
body#order__payment .payment-method {
  position: relative;
}
body#order__payment div.payment-method.toggle .pm-body,
body#order__payment div.payment-method.toggle .pm-trailing {
  display: none;
}
body#order__payment div.payment-method.toggle::after {
  background: url(/f/resources/images/common/icon_plus_circle_green.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 18px;
  width: 18px;
  z-index: -1;
  position: absolute;
  top: 23px;
  left: 9px;
}
body#order__payment div.payment-method.toggle:has(.is-open)::after {
  content: "";
  background: url(/f/resources/images/common/icon_minus_circle_green.svg) 50%/contain no-repeat;
  z-index: -1;
}
body#order__payment .payment-method-list .payment-method .pm-wrapper .pm-label {
  padding-left: 25px;
  text-align: left;
}
body#order__payment .payment-method.CARD .pm-heading,
body#order__payment .payment-method.CARD2 .pm-heading {
  cursor: pointer;
}
body#order__payment .payment-method.CARD .pm-heading::after,
body#order__payment .payment-method.CARD2 .pm-heading::after {
  content: "おすすめ！";
  color: #c10000;
  display: block;
  font-size: 14px;
  margin-left: 25px;
}
@media all and (max-width : 768px) {
  body#order__payment .payment-method.CARD .pm-heading::after,
  body#order__payment .payment-method.CARD2 .pm-heading::after {
    text-align: left;
  }
}
body#order__payment .payment-method.CARD .pm-label,
body#order__payment .payment-method.CARD2 .pm-label {
  font-size: 18px;
}
body#order__payment .amount-table .msg-paymentfee-excluded {
  font-size: 15px;
}
body#order__payment .amount-table .service-payment_commission .service-label {
  display: none;
}

/*
  ---------------
  配送に関するご要望
  ---------------
*/
body#order__deliveryservice .form-body {
  margin-top: 40px;
}
body#order__deliveryservice .form-body .form {
  margin-top: 16px;
}
body#order__deliveryservice .formrow {
  margin-top: 16px;
}
body#order__deliveryservice .input-label {
  font-weight: 700;
}
body#order__deliveryservice select {
  padding: 5px 30px 5px 10px;
  height: 40px;
  min-width: 100px;
  max-width: 300px;
  width: 100%;
}

/*
  ---------------
  注文 | 共通
  ---------------
*/
/* 注文内容の確認 | Order Confirm */
#order__confirm #checkout_form .order-properties input {
  display: block;
}

#order__confirm #checkout_form .order-properties label::before {
  display: none;
}

body#cart #outer,
body#order__complete #outer,
body#order__confirm #outer,
body#order__consignee #outer,
body#order__deliveryservice #outer,
body#order__itemoptions #outer,
body#order__order_error #outer,
body#order__orderer #outer,
body#order__payment #outer,
body#order__points #outer,
body#order__sender #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 20px;
}
@media all and (max-width: 768px) {
  body#cart #outer,
  body#order__complete #outer,
  body#order__confirm #outer,
  body#order__consignee #outer,
  body#order__deliveryservice #outer,
  body#order__itemoptions #outer,
  body#order__order_error #outer,
  body#order__orderer #outer,
  body#order__payment #outer,
  body#order__points #outer,
  body#order__sender #outer {
    padding-inline: 12px;
  }
}
body#cart .signin-form,
body#order__complete .signin-form,
body#order__confirm .signin-form,
body#order__consignee .signin-form,
body#order__deliveryservice .signin-form,
body#order__itemoptions .signin-form,
body#order__order_error .signin-form,
body#order__orderer .signin-form,
body#order__payment .signin-form,
body#order__points .signin-form,
body#order__sender .signin-form {
  margin-top: 10px;
}
body#cart .signup-form,
body#order__complete .signup-form,
body#order__confirm .signup-form,
body#order__consignee .signup-form,
body#order__deliveryservice .signup-form,
body#order__itemoptions .signup-form,
body#order__order_error .signup-form,
body#order__orderer .signup-form,
body#order__payment .signup-form,
body#order__points .signup-form,
body#order__sender .signup-form {
  padding-top: 40px;
}
body#cart h1,
body#order__complete h1,
body#order__confirm h1,
body#order__consignee h1,
body#order__deliveryservice h1,
body#order__itemoptions h1,
body#order__order_error h1,
body#order__orderer h1,
body#order__payment h1,
body#order__points h1,
body#order__sender h1 {
  font-size: 24px;
  font-weight: 400;
  padding: 0;
}
body#cart .order-cancel-edit,
body#cart .form-body > .addressbook,
body#order__complete .order-cancel-edit,
body#order__complete .form-body > .addressbook,
body#order__confirm .order-cancel-edit,
body#order__confirm .form-body > .addressbook,
body#order__consignee .order-cancel-edit,
body#order__consignee .form-body > .addressbook,
body#order__deliveryservice .order-cancel-edit,
body#order__deliveryservice .form-body > .addressbook,
body#order__itemoptions .order-cancel-edit,
body#order__itemoptions .form-body > .addressbook,
body#order__order_error .order-cancel-edit,
body#order__order_error .form-body > .addressbook,
body#order__orderer .order-cancel-edit,
body#order__orderer .form-body > .addressbook,
body#order__payment .order-cancel-edit,
body#order__payment .form-body > .addressbook,
body#order__points .order-cancel-edit,
body#order__points .form-body > .addressbook,
body#order__sender .order-cancel-edit,
body#order__sender .form-body > .addressbook {
  max-width: 100%;
  display: flex;
  justify-content: center;
}
body#cart .order-cancel-edit,
body#order__complete .order-cancel-edit,
body#order__confirm .order-cancel-edit,
body#order__consignee .order-cancel-edit,
body#order__deliveryservice .order-cancel-edit,
body#order__itemoptions .order-cancel-edit,
body#order__order_error .order-cancel-edit,
body#order__orderer .order-cancel-edit,
body#order__payment .order-cancel-edit,
body#order__points .order-cancel-edit,
body#order__sender .order-cancel-edit {
  padding: 0;
  margin-top: 12px;
}
body#cart .formrow-submit,
body#order__complete .formrow-submit,
body#order__confirm .formrow-submit,
body#order__consignee .formrow-submit,
body#order__deliveryservice .formrow-submit,
body#order__itemoptions .formrow-submit,
body#order__order_error .formrow-submit,
body#order__orderer .formrow-submit,
body#order__payment .formrow-submit,
body#order__points .formrow-submit,
body#order__sender .formrow-submit {
  padding-bottom: 0;
}

body#order__payment #order-total,
body#order__confirm #order-total {
  max-width: 500px;
}
body#order__payment #order-total th,
body#order__payment #order-total td,
body#order__confirm #order-total th,
body#order__confirm #order-total td {
  font-size: 14px;
  border: none;
}
body#order__payment #order-total .inv-subtotal,
body#order__payment #order-total .odr-ttl-amt,
body#order__payment #order-total .total.odr-ttl-amt,
body#order__confirm #order-total .inv-subtotal,
body#order__confirm #order-total .odr-ttl-amt,
body#order__confirm #order-total .total.odr-ttl-amt {
  border-bottom: 1px solid #d9d9d9;
}
body#order__payment #order-total .odr-ttl-amt,
body#order__confirm #order-total .odr-ttl-amt {
  border-bottom: 2px solid #d9d9d9;
}
body#order__payment #order-total .odr-ttl-amt th,
body#order__payment #order-total .odr-ttl-amt td,
body#order__confirm #order-total .odr-ttl-amt th,
body#order__confirm #order-total .odr-ttl-amt td {
  color: #333;
  font-size: 24px;
  font-weight: 700;
}
@media all and (max-width : 768px) {
  body#order__payment #order-total .odr-ttl-amt th,
  body#order__payment #order-total .odr-ttl-amt td,
  body#order__confirm #order-total .odr-ttl-amt th,
  body#order__confirm #order-total .odr-ttl-amt td {
    font-size: 20px;
  }
}
body#order__payment #order-total .total.odr-ttl-amt th,
body#order__payment #order-total .total.odr-ttl-amt td,
body#order__confirm #order-total .total.odr-ttl-amt th,
body#order__confirm #order-total .total.odr-ttl-amt td {
  color: #005c69;
  white-space: nowrap;
}
@media all and (max-width : 768px) {
  body#order__payment #order-total .amount,
  body#order__confirm #order-total .amount {
    white-space: nowrap;
  }
}

.order__orderer .orderer fieldset {
  margin-bottom: 0;
}

.order__itemoptions #outer,
body#order__order_error:not(:has(.signin-form)) #outer,
body#order__complete:not(:has(.signin-form)) #outer {
  flex: 1 1 0;
}
@media all and (min-width: 769px) {
  .order__itemoptions .breadcrumbs,
  body#order__order_error:not(:has(.signin-form)) .breadcrumbs,
  body#order__complete:not(:has(.signin-form)) .breadcrumbs {
    margin-bottom: 100px;
  }
}
.order__itemoptions h1,
body#order__order_error:not(:has(.signin-form)) h1,
body#order__complete:not(:has(.signin-form)) h1 {
  text-align: center;
}
.order__itemoptions .order-process-main-complete h1,
body#order__order_error:not(:has(.signin-form)) .order-process-main-complete h1,
body#order__complete:not(:has(.signin-form)) .order-process-main-complete h1 {
  font-size: 20px;
  font-weight: 500;
}
.order__itemoptions .description,
body#order__order_error:not(:has(.signin-form)) .description,
body#order__complete:not(:has(.signin-form)) .description {
  margin-top: 20px;
  text-align: center;
}
.order__itemoptions .main-message,
.order__itemoptions .main-contents,
body#order__order_error:not(:has(.signin-form)) .main-message,
body#order__order_error:not(:has(.signin-form)) .main-contents,
body#order__complete:not(:has(.signin-form)) .main-message,
body#order__complete:not(:has(.signin-form)) .main-contents {
  margin-top: 16px;
  text-align: center;
}
.order__itemoptions .order-process-main-complete h1,
.order__itemoptions .order-process-main-complete .main-message,
.order__itemoptions .order-process-main-complete .main-contents,
body#order__order_error:not(:has(.signin-form)) .order-process-main-complete h1,
body#order__order_error:not(:has(.signin-form)) .order-process-main-complete .main-message,
body#order__order_error:not(:has(.signin-form)) .order-process-main-complete .main-contents,
body#order__complete:not(:has(.signin-form)) .order-process-main-complete h1,
body#order__complete:not(:has(.signin-form)) .order-process-main-complete .main-message,
body#order__complete:not(:has(.signin-form)) .order-process-main-complete .main-contents {
  text-align: left;
}
.order__itemoptions .order-process-main-complete .main-contents,
body#order__order_error:not(:has(.signin-form)) .order-process-main-complete .main-contents,
body#order__complete:not(:has(.signin-form)) .order-process-main-complete .main-contents {
  margin-top: 0;
}
.order__itemoptions .main-contents,
body#order__order_error:not(:has(.signin-form)) .main-contents,
body#order__complete:not(:has(.signin-form)) .main-contents {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.order__itemoptions .view-order-detail a,
body#order__order_error:not(:has(.signin-form)) .view-order-detail a,
body#order__complete:not(:has(.signin-form)) .view-order-detail a {
  color: #005c69;
  text-decoration: underline;
}
.order__itemoptions .order-process-main-complete .view-order-detail a,
body#order__order_error:not(:has(.signin-form)) .order-process-main-complete .view-order-detail a,
body#order__complete:not(:has(.signin-form)) .order-process-main-complete .view-order-detail a {
  text-decoration: none;
}
.order__itemoptions .order-error a,
body#order__order_error:not(:has(.signin-form)) .order-error a,
body#order__complete:not(:has(.signin-form)) .order-error a {
  color: #005c69;
  display: block;
  text-align: center;
  text-decoration: underline;
}

#order__confirm #detach-coupons {
  padding-block: 40px;
}
@media all and (max-width : 768px) {
  #order__confirm #detach-coupons {
    padding-block: 20px;
  }
}
#order__confirm .cpn-exp {
  display: flex;
}
#order__confirm #detach-coupons ul span.cpn-exp span.date {
  color: #c10000;
}

#apply-coupons .wrapper .coupons {
  padding: 8px 8px 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
@media all and (max-width : 768px) {
  #apply-coupons .wrapper .coupons {
    gap: 8px 16px;
  }
}
#apply-coupons .wrapper .coupon {
  background-color: #f6f6f6;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  outline: 1px solid #005c69;
  outline-offset: -4px;
  padding: 4px;
  position: relative;
  height: fit-content;
  max-width: 250px;
  display: grid;
  place-items: center;
}
@media all and (max-width: 600px) {
  #apply-coupons .wrapper .coupon {
    max-width: calc(50% - 8px);
    width: calc(50% - 8px);
  }
}
#apply-coupons .wrapper .coupon::before {
  background: #c10000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  content: "";
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  padding: 4px 0;
  text-align: center;
  transform: rotate(-45deg);
  z-index: 1;
  width: 50px;
  position: absolute;
  left: -14px;
  top: 8px;
}
#apply-coupons .wrapper .coupon .notation {
  color: #005c69;
  font-size: 10px;
  padding: 0 8px 6px;
}
#apply-coupons .btn-apply-coupon {
  border: none;
  color: #005c69 !important;
  font-weight: 500;
  height: 100%;
  min-width: 155px;
  width: 100%;
}

.step-progress {
  padding-top: 20px;
  padding-bottom: 80px;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .step-progress {
    padding-top: 10px;
    padding-bottom: 40px;
  }
}

/*
  ---------------
  購入者情報
  ---------------
*/
body#order__orderer .orderer fieldset:not(.name-and-address) {
  background: #f6f6f6;
  padding: 40px 45px;
}
@media all and (max-width : 768px) {
  body#order__orderer .orderer fieldset:not(.name-and-address) {
    padding: 20px 16px;
  }
}
body#order__orderer div.form fieldset.name-and-address {
  border-top: 0;
  padding: 0;
  margin: 0;
}
body#order__orderer .form-body .input-group-address {
  border-top: 1px solid #d9d9d9;
}

/*
  ---------------
  ログインして注文
  ---------------
*/
body#order__signin_to_checkout #outer {
  padding-top: 20px;
}
body#order__signin_to_checkout .form.form-order-signin {
  margin-top: 20px;
}
body#order__signin_to_checkout .form .form-note {
  margin: 1em 0;
}
body#order__signin_to_checkout div.form fieldset {
  border-top: 0;
}
body#order__signin_to_checkout .form .form-body .input-group-address {
  padding: 0;
  margin-top: 0;
}

/*
  ---------------
  バックトイ TOP | バナー スライダー
  ---------------
*/
/* パックトイ TOP */
.site-banners-FLOWPACK_ROTATION_01 .banners ul {
  width: 100%;
}

.site-banners-FLOWPACK_ROTATION_01 img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.flowpack__series-lists {
  display: flex;
  justify-content: space-between;
}

.flowpack__series-item {
  width: calc(25% - 20px);
}

.flowpack__series-link {
  display: block;
}

.flowpack__series-img {
  object-fit: cover;
  height: 120px;
  width: 100%;
}

.flowpack__newitem-lists {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flowpack__newitem-item {
  width: calc((100% - 90px) / 3);
}

.flowpack__newitem-item + .flowpack__newitem-item {
  margin-left: 45px;
}
@media all and (max-width : 768px) {
  .flowpack__newitem-item + .flowpack__newitem-item {
    margin-left: 0;
  }
}

.flowpack__newitem-item:nth-child(3n+1) {
  margin-left: 0;
}

.flowpack__newitem-item:nth-child(n+4) {
  margin-top: 45px;
}
@media all and (max-width : 768px) {
  .flowpack__newitem-item:nth-child(n+4) {
    margin-top: 0;
  }
}

.flowpack__newitem-img {
  height: 200px;
  width: 100%;
}

.flowpack__newitem-button-wrap {
  text-align: center;
  padding: 20px 0;
}

.flowpack__newitem-all {
  border: 1px solid #333;
  border-radius: 12px;
  color: #333;
  display: block;
  padding: 20px;
  text-decoration: none;
}

.flowpack__about-free {
  height: 500px;
  line-height: 500px;
  text-align: center;
  background-color: #ddd;
}

.pt-msg {
  border: 2px solid red;
  padding: 1em;
  border-radius: 0.25rem;
  background-color: rgba(255, 238, 238, 0.9333333333);
}

.pt-msg dt {
  font-weight: bold;
  color: #a00;
}

.pt-msg dd {
  font-size: 0.9rem;
}

.pt-msg ul li {
  list-style-type: disc;
  margin-left: 2em;
}

.site-banners-FLOWPACK_ROTATION_01 {
  /*==========================================
  デフォルトのスタイル削除
  ==========================================*/
  /*==========================================
  スタイル追加
  ==========================================*/
}
.site-banners-FLOWPACK_ROTATION_01 a {
  display: block;
  height: auto;
}
.site-banners-FLOWPACK_ROTATION_01 .banner-img a {
  height: auto;
}
.site-banners-FLOWPACK_ROTATION_01 .heading {
  display: none;
}
.site-banners-FLOWPACK_ROTATION_01 .banners ul {
  width: 100%;
}
.site-banners-FLOWPACK_ROTATION_01 .banners ul.slider:not(.slick-initialized) > li + li {
  display: none;
}
.site-banners-FLOWPACK_ROTATION_01 .slick-slider {
  margin-bottom: 0;
  padding-bottom: 55px;
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .slick-slider {
    padding-bottom: 42px;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .slick-slide {
  position: relative;
}
.site-banners-FLOWPACK_ROTATION_01 ul .slick-slide:not(.slick-active)::before {
  content: "";
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
}
.site-banners-FLOWPACK_ROTATION_01 .slick-list {
  overflow: visible;
}
.site-banners-FLOWPACK_ROTATION_01 .slick-arrow {
  top: calc(50% - 25px);
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .slick-arrow {
    top: calc(50% - 21px);
  }
}
.site-banners-FLOWPACK_ROTATION_01 .slick-prev,
.site-banners-FLOWPACK_ROTATION_01 .slick-next {
  width: 50px;
  height: 50px;
  z-index: 2;
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .slick-prev,
  .site-banners-FLOWPACK_ROTATION_01 .slick-next {
    width: 30px;
    height: 60px;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .slick-prev {
  left: -25px;
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .slick-prev {
    left: 0;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .slick-next {
  right: -25px;
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .slick-next {
    right: 0;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .site-banners-FLOWPACK_ROTATION_01 .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .site-banners-FLOWPACK_ROTATION_01 .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .thumbnail:not(.slick-initialized) {
  display: none;
}
.site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-slide {
  margin-inline: 0;
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-slide {
    max-width: 38.1679389vw;
    margin-inline: 4px;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .thumbnail .item-tags {
  top: auto;
  right: 0;
  bottom: 5px;
}
.site-banners-FLOWPACK_ROTATION_01 .slick-dots {
  font-size: 0;
  bottom: 20px;
}
.site-banners-FLOWPACK_ROTATION_01 .slick-dots li {
  height: auto;
  margin: 0 4px;
  padding: 5px 0;
  width: auto;
}
.site-banners-FLOWPACK_ROTATION_01 .slick-dots li button {
  background-color: #d9d9d9;
  height: 4px;
  padding: 0;
  width: 40px;
}
@media all and (min-width: 769px) {
  .site-banners-FLOWPACK_ROTATION_01 .slick-dots li button {
    height: 5px;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .slick-dots li button:before {
  display: none;
}
.site-banners-FLOWPACK_ROTATION_01 .slick-dots li.slick-active button {
  background-color: #ffbd08;
}
.site-banners-FLOWPACK_ROTATION_01 .thumbnail {
  background-color: #f6f6f6;
  padding: 20px 0;
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .thumbnail {
    padding: 10px 0;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .thumbnail img {
  object-fit: contain;
  height: 100%;
}
.site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-prev,
.site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-next {
  height: 70px;
  width: 70px;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-prev,
  .site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-next {
    height: 50px;
    width: 50px;
    left: 1px;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-prev::before,
.site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-next::before {
  background: transparent url(/f/resources/images/packtoy/top/icon_arrow_left.svg) center/contain no-repeat;
  content: "";
  display: inline-block;
  height: inherit;
  width: inherit;
}
.site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-next {
  right: 30px;
  left: auto;
}
@media all and (max-width : 768px) {
  .site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-next {
    right: 1px;
  }
}
.site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-next::before {
  background: transparent url(/f/resources/images/packtoy/top/icon_arrow_right.svg) center/contain no-repeat;
}

@media all and (min-width: 769px) {
  .flowpack .flowpack__series .site-banners-FLOWPACK_ROTATION_01 .banners {
    max-width: 1280px;
    margin-inline: auto;
    padding-inline: clamp(0px, 2.77777778vw, 40px);
  }
}
.flowpack .flowpack__series .site-banners-FLOWPACK_ROTATION_01 .slider img {
  width: 100%;
}
.flowpack .flowpack__series .site-banners-FLOWPACK_ROTATION_01 .thumbnail {
  margin: 0 calc(50% - 50vw);
  width: 100vw;
}
@media all and (min-width: 769px) {
  .flowpack .flowpack__series .site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-list {
    max-width: 1280px;
    margin: 0 auto;
    padding-inline: clamp(0px, 2.77777778vw, 40px);
    width: 100%;
  }
}
@media all and (min-width: 769px) and (max-width: 768px) {
  .flowpack .flowpack__series .site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-list {
    padding-inline: 12px;
  }
}
.flowpack .flowpack__series .site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-track {
  display: flex;
  justify-content: center;
}
.flowpack .flowpack__series-head {
  display: none;
}
.flowpack .flowpack__newitem {
  max-width: 1280px;
  margin: 0 auto;
  padding: 46px clamp(0px, 2.77777778vw, 40px) 58px;
  position: relative;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__newitem {
    padding: 25px 12px 30px;
  }
}
.flowpack .flowpack__newitem-head {
  color: #00828c;
  font-family: "M PLUS 2";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  padding: 0;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__newitem-head {
    font-size: 20px;
    text-align: center;
  }
}
.flowpack .flowpack__newitem-lists {
  margin-top: 46px;
  padding: 0;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__newitem-lists {
    margin-top: 27px;
    justify-content: space-between;
    gap: 20px;
  }
}
.flowpack .flowpack__newitem-item {
  background: #fff;
  border: 1px solid #f6f6f6;
  border-radius: 30px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  padding-bottom: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__newitem-item {
    width: calc(50% - 10px);
    padding-bottom: 12px;
  }
}
.flowpack .flowpack__newitem-item > p {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 5px;
  padding-inline: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__newitem-item > p {
    font-size: 10px;
    padding-inline: 12px;
  }
}
.flowpack .flowpack__newitem-item > p > a {
  color: #005c69;
  text-decoration: underline;
}
.flowpack .flowpack__newitem-item > .item-tags {
  order: 1;
  top: 8px;
  right: -2px;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__newitem-item > .item-tags {
    top: 2px;
  }
}
.flowpack .flowpack__newitem-item > .flowpack__newitem-link {
  order: 2;
}
.flowpack .flowpack__newitem-item > p:nth-child(3) {
  text-align: right;
  order: 4;
}
.flowpack .flowpack__newitem-item > p:nth-child(4) {
  display: none;
}
.flowpack .flowpack__newitem-item > p:nth-child(5) {
  flex-grow: 1;
  order: 3;
}
.flowpack .flowpack__newitem-item > p:nth-child(6) {
  display: none;
}
.flowpack .flowpack__newitem-link {
  display: block;
}
.flowpack .flowpack__newitem-img {
  aspect-ratio: 370/290;
  border-radius: 30px 30px 0 0;
  width: 100%;
  height: auto;
  object-fit: contain;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__newitem-img {
    aspect-ratio: 175/138;
    border-radius: 20px 20px 0 0;
  }
}
.flowpack .flowpack__newitem-name {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 24px;
  padding-inline: 24px;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__newitem-name {
    font-size: 12px;
    margin-top: 12px;
    padding-inline: 12px;
  }
}
.flowpack .flowpack__newitem-new {
  height: 46px;
  width: 90px;
  position: absolute;
  top: -23px;
  left: 16px;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__newitem-new {
    height: 25px;
    width: 49px;
    top: -12px;
    left: 8px;
  }
}
.flowpack .flowpack__newitem-button-wrap {
  padding: 0;
}
@media all and (min-width: 769px) {
  .flowpack .flowpack__newitem-button-wrap {
    position: absolute;
    top: 40px;
    right: 40px;
  }
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__newitem-button-wrap {
    margin-top: 30px;
  }
}
.flowpack .flowpack__newitem-button-wrap > a {
  background-color: #00ada0;
  border: none;
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.33;
  padding: 0 48px 0 22px;
  position: relative;
  min-width: 296px;
  min-height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.flowpack .flowpack__newitem-button-wrap > a::before {
  content: "";
  border-radius: 100%;
  background: #fff url("/f/resources/images/top/icon_arrow_right_bg_white.svg") center/contain no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
.flowpack .flowpack__about {
  text-align: center;
}
@media all and (min-width: 769px) {
  .flowpack .flowpack__about {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
  }
}
.flowpack .flowpack__about-inner {
  padding-top: 90px;
  padding-bottom: 122px;
  position: relative;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__about-inner {
    padding-top: 35px;
    padding-bottom: 311px;
  }
}
.flowpack .flowpack__about-inner::before {
  content: "";
  background: url(/f/resources/images/packtoy/top/bg_about.svg) center bottom no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.flowpack .flowpack__about-inner::after {
  content: "";
  background: url(/f/resources/images/packtoy/top/bg_about_img.png) center top no-repeat;
  display: block;
  height: 522px;
  width: 1562px;
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-51.805555556%);
  z-index: -1;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__about-inner::after {
    background: url(/f/resources/images/packtoy/top/bg_about_img_sp.png) center top no-repeat;
    height: 323px;
    width: 473px;
    top: auto;
    bottom: 34px;
    left: 50%;
    transform: translateX(-54.5%);
  }
}
.flowpack .flowpack__about-title {
  color: #00828c;
  text-align: center;
  font-family: "M PLUS 2";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__about-title {
    font-size: 20px;
  }
}
.flowpack .flowpack__about-title::before, .flowpack .flowpack__about-title::after {
  content: "";
  background: url("/f/resources/images/packtoy/top/ttl_border_left.svg") center/contain no-repeat;
  display: inline-block;
  margin-right: 35px;
  height: 30.21px;
  width: 41.356px;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__about-title::before, .flowpack .flowpack__about-title::after {
    margin-right: 5px;
    transform: translateY(25%);
  }
}
.flowpack .flowpack__about-title::after {
  background: url("/f/resources/images/packtoy/top/ttl_border_right.svg") center/contain no-repeat;
  margin-right: auto;
  margin-left: 35px;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__about-title::after {
    margin-left: 5px;
  }
}
.flowpack .flowpack__about-lead {
  color: #005c69;
  font-family: "M PLUS 2";
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 1.447916667;
  margin-top: 39px;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__about-lead {
    font-size: 18px;
    line-height: 1.3889;
    margin-top: 15px;
  }
}
.flowpack .flowpack__about-lead > span {
  background-color: #ffe900;
  display: inline-block;
  line-height: 1.25;
  padding: 0 11px;
}
.flowpack .flowpack__about-text {
  color: #1d1d1b;
  font-family: "M PLUS 2";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 2.2667;
  margin-top: 23px;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__about-text {
    font-size: 14px;
    line-height: 2.1429;
    margin: 15px auto 0;
    max-width: 85.063291139%;
    text-align: left;
  }
}
.flowpack .flowpack__about-under-text {
  color: #005c69;
  font-family: "M PLUS 2";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4167;
  margin-top: 22px;
}
@media all and (max-width : 768px) {
  .flowpack .flowpack__about-under-text {
    font-size: 18px;
    margin-top: 12px;
  }
}
.flowpack .newslist-info {
  margin-top: 78px;
}
.flowpack .newslist-info .panel-newslist {
  padding-top: 0;
}
.flowpack .newslist-info .panel-heading + .panel-body {
  margin-bottom: 0;
}
.flowpack .newslist-info .news-name span {
  color: #00828c;
  font-weight: 700;
}
@media all and (max-width : 768px) {
  .flowpack .newslist-info .news-name span {
    font-size: 20px;
  }
}
@media all and (max-width : 768px) {
  .flowpack .newslist-info .news-archives-link {
    margin-top: 25px;
  }
}
.flowpack .newslist-info .news-archives-link a {
  border-radius: 50px;
  color: #00ada0;
  font-size: 16px;
  font-weight: 500;
}
.flowpack .newslist-info .news-archives-link a::after {
  background: url(/f/resources/images/packtoy/top/icon_arrow_news.svg) 50%/contain no-repeat;
}

/*
  ---------------
  パックトイ | 商品一覧
  ---------------
*/
body.itemlist.is-flowpack .txc-header,
body.is-flowpack .txc-header {
  display: none !important;
}
body.itemlist.is-flowpack .txc-body,
body.is-flowpack .txc-body {
  margin: 0 calc(50% - 50vw) 40px;
  width: 100vw;
}
@media all and (max-width : 768px) {
  body.itemlist.is-flowpack .txc-body,
  body.is-flowpack .txc-body {
    margin-bottom: 20px;
  }
}
body.itemlist.is-flowpack .txc-body img,
body.is-flowpack .txc-body img {
  width: 100%;
}
body.itemlist.is-flowpack .slider-logo,
body.is-flowpack .slider-logo {
  background-color: #f6f6f6;
  padding: 20px 0;
}
@media all and (min-width: 769px) {
  body.itemlist.is-flowpack .slider-logo,
  body.is-flowpack .slider-logo {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
  }
}
@media all and (max-width : 768px) {
  body.itemlist.is-flowpack .slider-logo,
  body.is-flowpack .slider-logo {
    padding: 10px 0;
  }
}
body.itemlist.is-flowpack .slider-logo img,
body.is-flowpack .slider-logo img {
  height: 8.3333333vw;
}
@media all and (max-width : 768px) {
  body.itemlist.is-flowpack .slider-logo img,
  body.is-flowpack .slider-logo img {
    height: 21.9053165vw;
  }
}
body.itemlist.is-flowpack .slider-logo .slick-prev,
body.itemlist.is-flowpack .slider-logo .slick-next,
body.is-flowpack .slider-logo .slick-prev,
body.is-flowpack .slider-logo .slick-next {
  height: 70px;
  width: 70px;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  body.itemlist.is-flowpack .slider-logo .slick-prev,
  body.itemlist.is-flowpack .slider-logo .slick-next,
  body.is-flowpack .slider-logo .slick-prev,
  body.is-flowpack .slider-logo .slick-next {
    height: 50px;
    width: 50px;
    left: 1px;
  }
}
body.itemlist.is-flowpack .slider-logo .slick-prev::before,
body.itemlist.is-flowpack .slider-logo .slick-next::before,
body.is-flowpack .slider-logo .slick-prev::before,
body.is-flowpack .slider-logo .slick-next::before {
  background: transparent url(/f/resources/images/packtoy/top/icon_arrow_left.svg) center/contain no-repeat;
  content: "";
  display: inline-block;
  height: inherit;
  width: inherit;
}
body.itemlist.is-flowpack .slider-logo .slick-next,
body.is-flowpack .slider-logo .slick-next {
  right: 30px;
  left: auto;
}
@media all and (max-width : 768px) {
  body.itemlist.is-flowpack .slider-logo .slick-next,
  body.is-flowpack .slider-logo .slick-next {
    right: 1px;
  }
}
body.itemlist.is-flowpack .slider-logo .slick-next::before,
body.is-flowpack .slider-logo .slick-next::before {
  background: transparent url(/f/resources/images/packtoy/top/icon_arrow_right.svg) center/contain no-repeat;
}
body.itemlist.is-flowpack div.item-thumbnail-img img,
body.is-flowpack div.item-thumbnail-img img {
  border-radius: 20px;
  object-fit: cover;
}
body.itemlist.is-flowpack div.item-thumbnail-img div.no-img,
body.is-flowpack div.item-thumbnail-img div.no-img {
  border-radius: 20px;
}
body.itemlist.is-flowpack .article .item-tags,
body.is-flowpack .article .item-tags {
  display: none;
}
body.itemlist.is-flowpack .text-contents,
body.is-flowpack .text-contents {
  margin-top: 8px;
}
body.itemlist.is-flowpack .text-contents a,
body.is-flowpack .text-contents a {
  font-size: 20px;
  color: #00828c;
  font-weight: 700;
}
body.itemlist.is-flowpack select.item-qty,
body.is-flowpack select.item-qty {
  border-radius: 30px !important;
}
body.itemlist.is-flowpack .add-item-to-cart,
body.is-flowpack .add-item-to-cart {
  background: #00ada0 !important;
  border-radius: 30px !important;
}
body.itemlist.is-flowpack .add-item-to-cart::after,
body.is-flowpack .add-item-to-cart::after {
  content: "";
  background-color: #ffd759;
  border-radius: 30px;
  display: inline-block;
  height: 50px;
  min-width: 178px;
  position: absolute;
  right: 0;
  bottom: -5px;
  z-index: -1;
}
body.itemlist.is-flowpack .checkout-item,
body.is-flowpack .checkout-item {
  border-radius: 30px !important;
}
body.itemlist.is-flowpack .checkout-item::after,
body.is-flowpack .checkout-item::after {
  content: "" !important;
  background-color: #ffd759;
  border-radius: 30px;
  display: inline-block;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: -5px;
  z-index: -1;
}
body.itemlist.is-flowpack .bnr_sold-out,
body.is-flowpack .bnr_sold-out {
  display: block;
  margin-top: 77px;
  text-align: center;
}
@media all and (max-width : 768px) {
  body.itemlist.is-flowpack .bnr_sold-out,
  body.is-flowpack .bnr_sold-out {
    margin: 40px 10px 0;
  }
}

body.is-flowpack .breadcrumbs {
  display: none !important;
}

body#itemlist.is-flowpack .breadcrumbs {
  display: block !important;
  margin-bottom: 0;
}

/*
  ---------------
  パックトイ | 商品詳細
  ---------------
*/
.item-productclass-flowpack #item-contents-main {
  padding-block: 30px;
  gap: min(3.33333333vw, 48px);
}
.item-productclass-flowpack #item-contents-purchase {
  min-width: auto;
  flex: 1;
}
.item-productclass-flowpack .item-image-main {
  height: auto;
  width: auto;
}
@media all and (max-width : 768px) {
  .item-productclass-flowpack div.item-thumbnail-img-lg a {
    display: inline-block;
    overflow: hidden;
    height: 150px;
    width: 150px;
  }
}

/*
  ---------------
  パックトイ | シリーズTOP
  ---------------
*/
#itemlist.is-flowpack ul.item-list li.item-list-each .item-thumbnail-img img {
  border-radius: 30px;
}

#itemlist.is-flowpack ul.item-list li.item-list-each .item-dtl .item-tags .item-tag .c-itemtag {
  border-radius: 40px;
}

#itemlist.is-flowpack ul.item-list li.item-list-each select.item-qty,
#itemlist.is-flowpack ul.item-list li.item-list-each .add-item-to-cart,
#itemlist.is-flowpack ul.item-list li.item-list-each .checkout-item {
  border-radius: 30px;
}

#itemlist.is-flowpack ul.item-list li.item-list-each select.item-qty {
  cursor: pointer;
  padding-inline: 20px 29px;
}

#itemlist.is-flowpack .pagination ul li a {
  border-radius: 30px;
}

#itemlist.is-flowpack .pagination ul li.active a {
  color: #00ada0;
}

/*
  ---------------
  パックトイ共通
  ---------------
*/
#itemlist.is-flowpack ul.item-list li.item-list-each .add-item-to-cart,
.flowpack button.add-item-to-cart {
  background-color: #00ada0 !important;
}

#itemlist.is-flowpack ul.item-list li.item-list-each .checkout-item,
.flowpack button.checkout-item {
  background: #ff9c08 !important;
}

#itemlist.is-flowpack ul.item-list li.item-list-each .add-item-to-cart,
#itemlist.is-flowpack ul.item-list li.item-list-each .checkout-item,
.flowpack button.add-item-to-cart,
.flowpack button.checkout-item {
  border-radius: 30px !important;
}

#itemlist.is-flowpack .txc-body .txc-description {
  background-color: transparent;
  border: 0;
  margin-top: 0;
  padding: 0;
}

/*
  ---------------
  パートワーク共通
  ---------------
*/
ol.partwork-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

ol.partwork-title > li {
  width: var(--ers-deago-boxwidth);
}

ol.partwork-title > li > div.thumbnail {
  width: 100%;
  height: var(--ers-deago-boxwidth);
  border: 1px solid #999;
}

ol.partwork-title > li > div.thumbnail img {
  width: 100%;
}

ol.partwork-title > li > div.text div.name {
  padding: 1em 0;
  font-weight: bold;
  text-align: center;
}

div.item-purchase div.partwork-distributions {
  border: 4px solid var(--ers-deago-col1);
  border-radius: 0.5em;
  padding: 1em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

div.item-purchase div.partwork-distributions label {
  background-color: #f5f5f5;
  display: block;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 0.25em;
}

div.item-purchase div.partwork-issues {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: lime;
  border-radius: 0.5rem;
}

div.partwork > div.description {
  border: 2px solid lime;
  padding: 2em;
  margin: 0 0 1em;
}

div.partwork div.txc-body {
  border: 2px solid lime;
  padding: 2em;
  margin: 0 0 1em;
}

div.partwork div.distribution > table {
  border-collapse: collapse;
  margin-bottom: 2rem;
}

div.partwork div.distribution > table caption {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 1rem 0 1rem;
}

div.partwork div.distribution > table th {
  background-color: lime;
  text-align: center;
  white-space: nowrap;
  padding: 1em;
}

div.partwork div.distribution > table td {
  border: 1px solid lime;
  padding: 1em;
}

ol.issues span.price.with-special span.selling-price {
  text-decoration: line-through;
}

ol.issues span.price.with-special span.special-price {
  color: red;
}

div.thread-title {
  border: 2px solid lime;
  padding: 2em;
  position: relative;
}

div.thread-title:before {
  content: "スレッドタイトル";
  position: absolute;
  left: 0;
  top: 0;
  color: lime;
}

/* パートワークカテゴリ一覧 */
.series {
  padding: 40px 0;
}

.category__lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.category__item {
  margin-top: 20px;
  width: calc(33.3333333333% - 20px);
}

.category__img {
  text-align: center;
  height: 160px;
}

.category__img img {
  height: 100%;
}

.category__footer-upper,
.category__footer-lower {
  display: flex;
  justify-content: space-between;
}

.category__footer-upper a,
.category__footer-lower a {
  border: 1px solid #333;
  border-radius: 4px;
  color: #333;
  font-size: 12px;
  margin-top: 8px;
  padding: 4px;
  text-align: center;
  text-decoration: none;
}

.category__footer-upper a {
  width: calc(50% - 16px);
}

.category__footer-lower a {
  width: calc(33.3333333333% - 16px);
}

.related__item-list {
  display: flex;
  justify-content: space-between;
}

.related__item-list li {
  border: 1px solid #333;
  border-radius: 12px;
  cursor: pointer;
  height: 200px;
  width: calc(25% - 20px);
}

.related__item-list li a {
  display: block;
  color: #333;
  font-size: 16px;
  height: 100%;
  line-height: 200px;
  text-align: center;
  text-decoration: none;
  transition: 0.2s ease;
  width: 100%;
}

.related__item-list li a:hover {
  opacity: 0.7;
}

/* パートワーク ナビゲーション */
.rp-subscriptions-nav-list .rp-subscriptions-nav-item > a,
.rp-subscriptions-nav-list .rp-subscriptions-nav-item > span {
  display: inline-block;
  white-space: nowrap;
  width: auto;
}

.rp-subscriptions-nav-list ol {
  overflow-x: scroll;
}

/* ディストリビューション詳細 */
.partwork .option .option-icon {
  display: block;
}

.partwork .option .option-icon img {
  height: 100px;
}

/* 商品一覧 */
.itemlist #page_aside2 {
  max-width: var(--ers-deago-maxwidth);
  margin: 0 auto;
  overflow-x: hidden;
}

.pagination ul {
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .pagination ul {
    justify-content: left;
  }
}

.pagination ul li {
  padding: 0 8px;
}

.related__pw {
  border-bottom: 1px solid #b2b2b2;
  padding: 20px 0;
}

.itemlist .txc-header img {
  width: 100%;
}

/* 0件の際の表示 */
.p-series__nocontents,
.search-result-empty {
  border: 1px solid #c10000;
  background-color: #ffebeb;
  color: #c10000;
  margin: 40px 0;
  padding: 40px 10px;
  text-align: center;
}

.p-series__nocontents {
  margin: 4px 0 0;
}

/* カテゴリTOP */
.itemlist .txc-body {
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .itemlist .txc-body {
    flex-direction: column;
  }
}

.itemlist .txc-body .txc-description {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  padding: 12px;
  margin-top: 20px;
  width: 100%;
}

.itemlist .txc-body .txc-spec {
  border: 1px solid #d9d9d9;
  background-color: #f6f6f6;
  display: block;
  font-size: 14px;
  padding: 12px;
  text-align: left;
  margin-top: 20px;
  width: 100%;
}

.itemlist .txc-body > div:nth-child(2) {
  border-left: none;
}
@media all and (max-width : 768px) {
  .itemlist .txc-body > div:nth-child(2) {
    border-left: 1px solid #d9d9d9;
  }
}

.itemlist .txc-body .txc-spec caption {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.itemlist .txc-body .txc-spec th {
  min-width: 60px;
}

#itemlist #inner,
#item #inner,
#partwork #inner,
#distribution #inner {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  #itemlist #inner,
  #item #inner,
  #partwork #inner,
  #distribution #inner {
    padding-inline: 12px;
  }
}

#itemlist #category-txc .txc-header h1,
#partwork .partwork.main > h1,
#distribution .partwork.main > h1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0;
}
@media all and (min-width: 769px) {
  #itemlist #category-txc .txc-header h1,
  #partwork .partwork.main > h1,
  #distribution .partwork.main > h1 {
    font-size: 24px;
    line-height: 1.45833333;
    margin-bottom: 10px;
  }
}

#partwork .partwork.main > h1,
#distribution .partwork.main > h1 {
  margin-top: 20px;
  pointer-events: none;
}
@media all and (min-width: 769px) {
  #partwork .partwork.main > h1,
  #distribution .partwork.main > h1 {
    margin-top: 30px;
  }
}

#partwork .head-contents .images,
#distribution .head-contents .images {
  margin-top: 20px;
}
@media all and (min-width: 769px) {
  #partwork .head-contents .images,
  #distribution .head-contents .images {
    margin-top: 30px;
  }
}

#partwork .head-contents .main,
#distribution .head-contents .main {
  text-align: center;
}

#partwork .head-contents .images .main img,
#distribution .head-contents .images .main img {
  height: 100%;
  object-fit: contain;
}

#partwork #category-txc .txc-body,
#distribution #category-txc .txc-body {
  border: 0;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

#partwork #category-txc .txc-description,
#distribution #category-txc .txc-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71428571;
}

#itemlist div.category-item-list div.item-list-heading {
  display: block;
  padding-top: 20px;
  position: relative;
}
@media all and (max-width : 768px) {
  #itemlist div.category-item-list div.item-list-heading {
    padding-bottom: 10px;
  }
}

#itemlist div.category-item-list div.category-item-list-sorting ul li {
  border: 0;
}

#itemlist div.category-item-list ul.item-list li.item-list-each div.item-dtl div.article-prices,
#itemlist div.category-item-list .item-purchase div.delivery-message,
#itemlist div.category-item-list .points,
#itemlist div.category-item-list .offering-description,
#itemlist div.category-item-list ul.item-list li.item-list-each .regular-price,
#itemlist div.category-item-list .item-list-each form.item-purchase .partwork #itemlist div.category-item-list .item-list-each .article-price-range,
#itemlist div.category-item-list div.item-add-favorite, div.item-add-itemrequest,
#itemlist div.category-item-list .item-list .item-list-each form.item-purchase .partwork,
#itemlist div.category-item-list .item-list .item-list-each .article-price-range,
#itemlist ul.item-list li.item-list-each .add-item-to-cart::after,
#itemlist ul.item-list li.item-list-each .checkout-item::after,
#itemlist .item-list .offering-price-info .price-label,
#item .item-productclass-flowpack span.item-name-and-code span.item-code,
#item .item-productclass-flowpack .offering-description,
#item .item-productclass-flowpack div.exp-promotion-prices,
#item .item-productclass-flowpack .regular-price,
#item .item-productclass-flowpack .offering-price-info .price-label,
#item .item-productclass-flowpack .points,
#item .item-productclass-flowpack div.stock-message-enough,
#item .item-productclass-flowpack .item-purchase div.delivery-message,
#item .item-productclass-flowpack div.item-add-favorite {
  display: none;
}

div.ac.panel-list-wrapper > div.container {
  box-shadow: none;
  padding: 30px 55px 60px;
}
@media all and (max-width : 768px) {
  div.ac.panel-list-wrapper > div.container {
    width: 94.2% !important;
    padding: 17px 20px 23px;
  }
}

#distribution .distribution-detail .body .subscribe .select-qty-buttons-wrap {
  text-align: right;
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  #distribution .distribution-detail .body .subscribe .select-qty-buttons-wrap {
    margin-top: 20px;
  }
}

/*
  ---------------
  シリーズトップ | 商品画像, title, description
  ---------------
*/
#itemlist #category-txc .txc-header {
  display: flex;
  flex-direction: column;
}

#itemlist #category-txc .txc-header {
  order: 1;
}

#itemlist #category-txc .txc-header div {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.71428571;
  margin-top: 20px;
  order: 3;
}
@media all and (min-width: 769px) {
  #itemlist #category-txc .txc-header div {
    margin-top: 10px;
  }
}

#itemlist #category-txc .txc-header img {
  border: 1px solid #d9d9d9;
  height: 250px;
  width: 250px;
  object-fit: contain;
  order: 2;
  margin: 0 auto;
}
@media all and (min-width: 769px) {
  #itemlist #category-txc .txc-header img {
    max-height: 460px;
  }
}
@media all and (max-width : 768px) {
  #itemlist #category-txc .txc-header img {
    height: 200px;
    width: 200px;
  }
}

#itemlist #category-txc .txc-header div {
  order: 3;
}

#itemlist #category-txc .txc-html {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71428571;
  margin-top: 20px;
}

/*
  ---------------
  シリーズトップ | 絞り込みエリア
  ---------------
*/
#itemlist .filter-criteria {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 12px;
}

#itemlist .filter-criteria .filtered-by__name {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  min-width: 100px;
}

#itemlist .item-list-heading .category-item-list-sorting {
  max-width: 150px;
  position: absolute;
  bottom: 0;
  right: 0;
}

#itemlist .filter-criteria .filtered-by__criteria-label {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  min-width: 100px;
}

#itemlist .filter-criteria .filtered-by__list {
  display: inline-flex;
  flex-wrap: wrap;
  padding-left: 8px;
}

#itemlist .filter-criteria .filtered-by__item {
  font-size: 14px;
}

#itemlist .item-list-heading .page-position {
  margin-top: 20px;
}

@media all and (max-width : 768px) {
  #itemlist .item-list-heading .category-item-list-sorting {
    text-align: right;
    width: 150px;
  }
}

#itemlist .item-list-heading .category-item-list-sorting .heading {
  border: 1px solid #b2b2b2;
  border-radius: 2px;
  cursor: pointer;
  color: #b2b2b2;
  font-size: 0;
  margin-left: auto;
  position: relative;
  height: 40px;
  width: 40px;
}
@media all and (min-width: 769px) {
  #itemlist .item-list-heading .category-item-list-sorting .heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4375;
    width: 150px;
    min-height: 50px;
    padding: 13px;
    display: flex;
    align-items: center;
  }
}

#itemlist .item-list-heading .category-item-list-sorting .heading::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_filter.svg") center/contain no-repeat;
  display: inline-block;
  width: 18px;
  height: 12px;
  position: absolute;
}
@media all and (max-width : 768px) {
  #itemlist .item-list-heading .category-item-list-sorting .heading::after {
    top: 50%;
    right: calc(50% + 1px);
    transform: translate(50%, -50%);
  }
}
@media all and (min-width: 769px) {
  #itemlist .item-list-heading .category-item-list-sorting .heading::after {
    right: 13px;
  }
}

#itemlist .item-list-heading .category-item-list-sorting ul {
  background-color: #fff;
  bottom: auto;
  display: none;
  left: auto;
  list-style-type: none;
  position: absolute;
  z-index: 10;
  max-width: 150px;
  width: 100%;
}

#itemlist .item-list-heading .category-item-list-sorting ul > li {
  display: block;
  padding: 0;
}
@media all and (max-width : 768px) {
  #itemlist .item-list-heading .category-item-list-sorting ul > li {
    text-align: left;
  }
}

#itemlist .item-list-heading .category-item-list-sorting ul > li > a {
  border: 1px solid #b2b2b2;
  display: block;
  padding: 0.5em 1em;
}

#itemlist .item-list-heading .category-item-list-sorting ul > li:not(:first-child) > a {
  border-top: 0;
}

/*
  ---------------
  シリーズトップ | 刊行一覧
  ---------------
*/
@media all and (max-width : 768px) {
  #itemlist ul.item-list {
    padding: 0;
  }
}

#itemlist ul.item-list li.item-list-each,
#item .item-productclass-flowpack #item-contents-main {
  flex-wrap: nowrap;
}
@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each,
  #item .item-productclass-flowpack #item-contents-main {
    padding: 20px 0 95px;
    position: relative;
    gap: 18px;
  }
}
@media all and (min-width: 769px) {
  #itemlist ul.item-list li.item-list-each,
  #item .item-productclass-flowpack #item-contents-main {
    padding-block: 30px;
    gap: min(3.33333333vw, 48px);
  }
}

#itemlist ul.item-list li.item-list-each:last-child {
  border-bottom: 1px dotted #ccc;
}
@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each:last-child {
    padding-bottom: 95px;
  }
}
@media all and (min-width: 769px) {
  #itemlist ul.item-list li.item-list-each:last-child {
    padding-bottom: 30px;
  }
}

#itemlist #container > div > ul > li .item-thumbnail-img-wrapper > div > a {
  display: inline-block;
  width: 150px;
  height: 150px;
  overflow: hidden;
}
@media all and (min-width: 769px) {
  #itemlist #container > div > ul > li .item-thumbnail-img-wrapper > div > a {
    width: 250px;
    height: 250px;
  }
}

#container > div > ul > li > div.item-thumbnail-img-wrapper > div > a > img {
  border-radius: 0;
  object-fit: contain;
  height: 100%;
}

#itemlist div.item-thumbnail-img div.no-img {
  width: 100%;
}

#itemlist ul.item-list li.item-list-each .item-dtl,
#item .item-productclass-flowpack .item-dtl {
  margin: 0;
}
@media all and (min-width: 769px) {
  #itemlist ul.item-list li.item-list-each .item-dtl,
  #item .item-productclass-flowpack .item-dtl {
    position: relative;
  }
}

#itemlist ul.item-list li.item-list-each .item-name,
#item .item-productclass-flowpack .item-name {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}

#itemlist ul.item-list li.item-list-each .product-desc,
#item .item-productclass-flowpack .product-desc {
  display: none;
}

#itemlist ul.item-list li.item-list-each .product-dtl > .item-tags {
  display: inline-block;
  vertical-align: 19px;
}
@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each .product-dtl > .item-tags {
    vertical-align: 0;
  }
}

#itemlist ul.item-list li.item-list-each div.item-purchase > div.item-tags {
  display: none;
}

#itemlist ul.item-list li.item-list-each .item-dtl > div.item-tags div.item-tag {
  border-radius: 0;
  display: inline-block;
  font-size: 12px;
  padding: 0 4px 2px;
  margin: 0 0 4px 8px;
}
@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each .item-dtl > div.item-tags div.item-tag {
    margin: 4px 4px 0 0;
  }
}

#itemlist .item-list .offering-price-info,
#item .item-productclass-flowpack .offering-price-info {
  margin-top: 3px;
}

#itemlist .item-list .individual-delivery-fee {
  font-size: 14px;
}

#itemlist ul.item-list li.item-list-each .final-price,
#item .item-productclass-flowpack .final-price {
  text-align: left;
}

#itemlist ul.item-list li.item-list-each .final-price span.price,
#item .item-productclass-flowpack .final-price span.price {
  color: #333;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.45;
}
@media all and (min-width: 769px) {
  #itemlist ul.item-list li.item-list-each .final-price span.price,
  #item .item-productclass-flowpack .final-price span.price {
    font-size: 35px;
    line-height: 1.45714286;
  }
}

#itemlist ul.item-list li.item-list-each .final-price span.price > span,
#item .item-productclass-flowpack .final-price span.price > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.41666667;
}
@media all and (min-width: 769px) {
  #itemlist ul.item-list li.item-list-each .final-price span.price > span,
  #item .item-productclass-flowpack .final-price span.price > span {
    font-size: 16px;
    line-height: 1.4375;
  }
}

#itemlist ul.item-list li.item-list-each .item-code {
  display: block;
}

#itemlist ul.item-list li.item-list-each form.item-purchase,
#item .item-productclass-flowpack form.item-purchase {
  font-size: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}
@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each form.item-purchase,
  #item .item-productclass-flowpack form.item-purchase {
    margin-top: 6px;
  }
}
@media all and (min-width: 769px) {
  #itemlist ul.item-list li.item-list-each form.item-purchase,
  #item .item-productclass-flowpack form.item-purchase {
    margin-top: auto;
    position: absolute;
    bottom: 0;
  }
}

#itemlist ul.item-list li.item-list-each .item-qty.item-qty-item,
#item .item-productclass-flowpack .item-qty.item-qty-item {
  display: inline-flex;
  align-items: center;
}
@media all and (max-width: 914px) and (min-width: 768px) {
  #itemlist ul.item-list li.item-list-each .item-qty.item-qty-item,
  #item .item-productclass-flowpack .item-qty.item-qty-item {
    margin-bottom: 0.69444444vw;
  }
}

#itemlist ul.item-list li.item-list-each .item-qty-label,
#item .item-productclass-flowpack .item-qty-label {
  font-size: 14px;
  line-height: 1.42857143;
  margin-inline-end: 15px;
}

#itemlist ul.item-list li.item-list-each select.item-qty,
#item .item-productclass-flowpack select.item-qty {
  appearance: none;
  background: #F6F6F6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  border: 0;
  border-radius: 2px;
  color: #1D1D1B;
  cursor: pointer;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  line-height: normal;
  padding-inline: 10px 29px;
  display: inline-flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each select.item-qty,
  #item .item-productclass-flowpack select.item-qty {
    font-size: 16px;
    height: 35px;
    padding-inline-end: 13px;
    width: 84px;
  }
}
@media all and (min-width: 769px) {
  #itemlist ul.item-list li.item-list-each select.item-qty,
  #item .item-productclass-flowpack select.item-qty {
    width: calc(50% - clamp(0px, 0.69444444vw, 20px) / 2);
  }
}

@media all and (max-width: 1119px) {
  #itemlist ul.item-list li.item-list-each select.item-qty {
    width: 84px;
  }
}

#itemlist form.item-purchase div.btn-group-purchase,
#item .item-productclass-flowpack form.item-purchase div.btn-group-purchase {
  font-size: 0;
  display: inline-flex;
}
@media all and (max-width : 768px) {
  #itemlist form.item-purchase div.btn-group-purchase,
  #item .item-productclass-flowpack form.item-purchase div.btn-group-purchase {
    width: 100%;
    justify-content: space-between;
    gap: 2.54452926vw;
    position: absolute;
    left: 0;
    bottom: 20px;
  }
}
@media all and (min-width: 769px) {
  #itemlist form.item-purchase div.btn-group-purchase,
  #item .item-productclass-flowpack form.item-purchase div.btn-group-purchase {
    gap: clamp(0px, 0.69444444vw, 20px);
    margin-left: 1.38888889vw;
  }
}

@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each .add-item-to-cart,
  #itemlist ul.item-list li.item-list-each .checkout-item,
  #item .item-productclass-flowpack .add-item-to-cart,
  #item .item-productclass-flowpack .checkout-item {
    width: 50%;
  }
}

#itemlist ul.item-list li.item-list-each .add-item-to-cart,
#itemlist ul.item-list li.item-list-each .checkout-item,
#item .item-productclass-flowpack .add-item-to-cart,
#item .item-productclass-flowpack .checkout-item {
  margin-left: 0;
  border: 0;
  border-radius: 2px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.33333333;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#itemlist ul.item-list li.item-list-each .add-item-to-cart,
#item .item-productclass-flowpack .add-item-to-cart {
  background: #00828c;
  min-width: 178px;
  height: 50px;
  padding: 0 19px 0 52px;
}
@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each .add-item-to-cart,
  #item .item-productclass-flowpack .add-item-to-cart {
    min-width: auto;
    padding: 0 14px 0 40px;
  }
}
#itemlist ul.item-list li.item-list-each .add-item-to-cart::before,
#item .item-productclass-flowpack .add-item-to-cart::before {
  content: "";
  background: url("/f/resources/images/packtoy/list/icon_cart.svg") center/contain no-repeat;
  display: inline-block;
  width: 17px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: auto;
  left: 21px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each .add-item-to-cart::before,
  #item .item-productclass-flowpack .add-item-to-cart::before {
    left: 17px;
  }
}
@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each .add-item-to-cart::after,
  #item .item-productclass-flowpack .add-item-to-cart::after {
    min-width: 100%;
  }
}

#itemlist ul.item-list li.item-list-each .checkout-item,
#item .item-productclass-flowpack .checkout-item {
  background: #ff9c08;
  min-width: 178px;
  height: 50px;
  padding: 0 52px 0 19px;
}
@media all and (max-width : 768px) {
  #itemlist ul.item-list li.item-list-each .checkout-item,
  #item .item-productclass-flowpack .checkout-item {
    min-width: auto;
    padding: 0 47px 0 19px;
  }
}
#itemlist ul.item-list li.item-list-each .checkout-item::before,
#item .item-productclass-flowpack .checkout-item::before {
  content: "";
  border-radius: 100%;
  background: url("/f/resources/images/common/icon_arrow_right_bg_white.svg") center/contain no-repeat;
  display: inline-block;
  height: 24px;
  margin-left: 20px;
  width: 24px;
  position: absolute;
  top: 50%;
  right: 14px;
  left: auto;
  transform: translateY(-50%);
}

@media (hover: hover) and (pointer: fine) {
  #itemlist ul.item-list li.item-list-each form button {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  #itemlist ul.item-list li.item-list-each form button:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
#itemlist ul.item-list li.item-list-each form button::before, #itemlist ul.item-list li.item-list-each form button::after {
  backface-visibility: hidden;
}

#itemlist ul.item-list li.item-list-each .screen-message,
#item .item-productclass-flowpack .screen-message {
  display: none;
}

#container > div > ul > li > div.item-dtl.product-dtl > div.articles > div > div.purchase > div > div.item-purchase.available.rpa-unavailable {
  margin-bottom: 0;
}

#itemlist ul.item-list li.item-list-each select.item-qty,
#itemlist ul.item-list li.item-list-each .add-item-to-cart,
#itemlist ul.item-list li.item-list-each .checkout-item {
  border-radius: 0;
}

#itemlist .pagination ul li a {
  border-radius: 0;
}

#itemlist .pagination ul li.active a {
  background-color: transparent;
  color: #005c69;
}

@media all and (max-width : 768px) {
  #item .p-product__inner.l-section--inner,
  .flowpack.select-product .p-product__inner {
    padding-inline: 0;
  }
}

/*
  ---------------
  シリーズトップ | 関連ボタン(定期購読~グッズ)
  ---------------
*/
.related__pw .related__pw-inner {
  display: flex;
}
@media all and (min-width: 769px) {
  .related__pw .related__pw-inner {
    gap: clamp(0px, 4.16666667vw, 60px);
    align-items: stretch;
    justify-content: space-between;
  }
}
@media all and (max-width: 1024px) {
  .related__pw .related__pw-inner {
    flex-direction: column-reverse;
  }
}

.related__pw .related__pw-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 400px;
  width: 100%;
}
@media all and (max-width: 1024px) {
  .related__pw .related__pw-box {
    display: block;
    max-width: 100%;
  }
}

.related__item-description {
  font-size: 20px;
  padding-bottom: 20px;
}
@media all and (max-width : 768px) {
  .related__item-description {
    font-size: 16px;
  }
}

@media all and (min-width: 769px) {
  .related__pw .button-wrap {
    max-width: 400px;
  }
}
@media all and (max-width: 1024px) {
  .related__pw .button-wrap {
    max-width: 100%;
  }
}

.related__pw .button-wrap .show-partwork-dtl {
  background: linear-gradient(90deg, #005C69 0%, #00ADA0 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.45833333;
  width: 100%;
  min-height: 80px;
}
@media (hover: hover) and (pointer: fine) {
  .related__pw .button-wrap .show-partwork-dtl {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .related__pw .button-wrap .show-partwork-dtl:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.related__pw .button-wrap .show-partwork-dtl::before, .related__pw .button-wrap .show-partwork-dtl::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .related__pw .button-wrap .show-partwork-dtl {
    min-height: 65px;
  }
}

.related__pw .button-wrap .show-partwork-dtl span {
  color: #fff;
  padding-left: 36px;
  position: relative;
}

.related__pw .button-wrap .show-partwork-dtl span::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_cart.svg") center/contain no-repeat;
  display: inline-block;
  width: 26px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.related__pw .related__pw-btn {
  margin-top: 10px;
}

.related__pw .related__pw-btn a {
  background-color: #494949;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  min-height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
@media all and (min-width: 769px) {
  .related__pw .related__pw-btn a {
    max-width: 400px;
  }
}
@media all and (max-width: 1024px) {
  .related__pw .related__pw-btn a {
    max-width: 100%;
  }
}

.related__pw .related__pw-btn a::after {
  background: url(/f/resources/images/common/icon_link_othertab_white.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  margin-left: 4px;
  height: 16px;
  width: 16px;
}

.related__pw .related__item-usage {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.related__pw .related__item-usage li {
  width: 100%;
}

.related__pw .related__item-usage li a {
  background: linear-gradient(0deg, #F6F6F6, #F6F6F6), linear-gradient(0deg, #B2B2B2, #B2B2B2);
  border: 1px solid #b2b2b2;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42857143;
  width: 100%;
  min-height: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.related__pw .related__item-usage li.button-faq a {
  font-size: 17px;
  letter-spacing: 2px;
}

.related__item-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

@media all and (max-width: 1024px) {
  .related__pw .related__item-list {
    display: block;
    margin-top: 20px;
  }
}
@media all and (min-width: 769px) {
  .related__pw .related__item-list {
    gap: 20px;
  }
}

.related__pw .related__item-list li {
  border-color: #d9d9d9;
  border-radius: 2px;
  width: 100%;
  height: auto;
}

@media all and (max-width: 1024px) {
  .related__pw .related__item-list li + li {
    margin-top: 10px;
  }
}

.related__pw .related__item-list li a {
  color: #005c69;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4375;
  text-align: left;
  width: 100%;
  padding-right: 10px;
  display: flex;
}

.related__pw .related__item-list li a img {
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 108px;
  height: 100%;
  max-height: 108px;
}
@media all and (max-width : 768px) {
  .related__pw .related__item-list li a img {
    max-width: 100px;
    max-height: 100px;
  }
}

.related__pw .related__item-list li a span {
  padding: 20px 0;
  width: 100%;
  margin-left: 20px;
  display: flex;
  align-items: center;
  position: relative;
}
@media all and (max-width : 768px) {
  .related__pw .related__item-list li a span {
    padding: 13px 0 14px;
  }
}

.related__pw .related__item-list li a span::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_chevron_right.svg") center/cover no-repeat;
  display: inline-block;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

/*
  ---------------
  定期購読 |
  ---------------
*/
/*
  ---------------
  定期購読 |
  ---------------
*/
/*
  ---------------
  パートワーク共通
  ---------------
*/
#partwork ul.distributions {
  margin-top: 12px;
}

#partwork ul.distributions > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #747474;
  min-height: 90px;
  padding: 10px 30px 10px 20px;
  position: relative;
}
@media all and (max-width : 768px) {
  #partwork ul.distributions > li {
    min-height: auto;
    padding: 16px 20px 16px 16px;
  }
}
#partwork ul.distributions > li:hover {
  opacity: 0.7;
}

#partwork ul.distributions.no-group > li {
  background: #f6f6f6;
}

#partwork ul.distributions > li + li {
  margin-top: 12px;
}

#partwork ul.distributions > li .distribution-summary::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_chevron_right.svg") center/contain no-repeat;
  display: inline-block;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  #partwork ul.distributions > li .distribution-summary::after {
    right: 8px;
  }
}

#partwork ul.distributions > li .distribution-summary .title {
  color: #1d1d1b;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}
@media all and (max-width : 768px) {
  #partwork ul.distributions > li .distribution-summary .title {
    font-size: 18px;
    line-height: 1.2;
  }
}

#partwork .distribution-summary .text-contents.description {
  font-size: 13px;
  margin-top: 4px;
  line-height: 1.1;
}
@media all and (max-width : 768px) {
  #partwork .distribution-summary .text-contents.description {
    font-size: 12px;
  }
}

#partwork ul.distributions > li .distribution-summary .btn,
#partwork ul.distributions > li .distribution-summary .btn a {
  font-size: 0;
}

#partwork ul.distributions > li .distribution-summary .btn a {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#partwork #page_aside2 {
  display: none;
}

#partwork .distribution-group-list {
  margin-top: 80px;
}
@media all and (max-width : 768px) {
  #partwork .distribution-group-list {
    margin-top: 40px;
  }
}

#partwork .distribution-group-list .distribution-group {
  border-radius: 2px;
  margin-top: 12px;
}

@media all and (max-width : 768px) {
  #partwork .distribution-group-list .distribution-group .distributions.group {
    margin-top: 20px;
  }
}

#partwork .distribution-group-list .distribution-group .heading .group-name {
  font-size: 20px;
  font-weight: 700;
}
@media all and (max-width : 768px) {
  #partwork .distribution-group-list .distribution-group .heading .group-name {
    font-size: 18px;
  }
}

#partwork .distribution-group-list .distribution-group .heading .description {
  font-size: 12px;
}

#partwork .distribution-group-list .distribution-group.primary .distributions.group > li {
  border: 1px solid #005c69;
  background: rgba(0, 130, 140, 0.1);
}

#partwork .distribution-group.primary ul.distributions > li .distribution-summary::after {
  background: transparent url(/f/resources/images/common/icon_chevron_right_green.svg) center/contain no-repeat;
}

#partwork .distribution-group-list .distribution-group.hide-heading > .heading {
  display: none;
}

#partwork .distribution-group-list .distribution-group.primary.toggle {
  background: rgba(0, 130, 140, 0.1);
  border: 1px solid #005c69;
}
#partwork .distribution-group-list .distribution-group.primary.toggle > .heading {
  cursor: pointer;
  min-height: 90px;
  padding: 20px;
  position: relative;
}
@media all and (max-width : 768px) {
  #partwork .distribution-group-list .distribution-group.primary.toggle > .heading {
    min-height: auto;
    padding: 16px 36px 16px 16px;
  }
}
#partwork .distribution-group-list .distribution-group.primary.toggle > .heading:hover {
  opacity: 0.7;
}
#partwork .distribution-group-list .distribution-group.primary.toggle > .heading::after {
  content: "";
  background: transparent url(/f/resources/images/common/icon_minus_green_bold.svg) center/contain no-repeat;
  display: inline-block;
  width: 15px;
  height: 13px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
#partwork .distribution-group-list .distribution-group.primary.toggle.is-hide > .heading::after {
  background: transparent url(/f/resources/images/common/icon_plus_green_bold.svg) center/contain no-repeat;
}
#partwork .distribution-group-list .distribution-group.primary.toggle .distributions.group {
  margin-top: 0;
  padding: 0 12px 12px 24px;
}
@media all and (max-width : 768px) {
  #partwork .distribution-group-list .distribution-group.primary.toggle .distributions.group {
    padding-left: 12px;
  }
}
#partwork .distribution-group-list .distribution-group.primary.toggle .distributions.group > li {
  background: #f6f6f6;
  border: 1px solid #747474;
}
#partwork .distribution-group-list .distribution-group.primary.toggle .distributions.group > li .distribution-summary::after {
  background: transparent url(/f/resources/images/common/icon_chevron_right.svg) center/contain no-repeat;
}

/*
  ---------------
  ディストリビューション詳細 |
  ---------------
*/
#distribution .head-contents .description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71428571;
  margin-top: 20px;
}

#distribution #category-txc .txc-header {
  display: none;
}

#distribution .pwk-title {
  display: none;
}

/*
  ---------------
  ディストリビューション詳細 |
  ---------------
*/
/*
  ---------------
  ディストリビューション詳細 |
  ---------------
*/
.distribution-detail {
  margin-top: 20px;
}

.distribution-detail > .distribution > .heading {
  background-color: transparent;
  padding: 0;
  margin-bottom: 20px;
}
@media all and (min-width: 769px) {
  .distribution-detail > .distribution > .heading {
    margin-bottom: 40px;
  }
}

.distribution-detail > .distribution > .heading .main-title .pwk-icon {
  display: none;
}

.distribution-detail > .distribution > .heading .main-title {
  padding: 0;
}

.distribution .subscribe .purchase-backnumber .heading .title {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  text-decoration: none;
  padding: 13px 35px 13px 20px;
  position: relative;
}
@media all and (max-width : 768px) {
  .distribution .subscribe .purchase-backnumber .heading .title {
    padding: 15px 60px 15px 20px;
  }
}

.distribution .subscribe .purchase-backnumber .heading .title::after {
  content: "";
  background: transparent url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") center/contain no-repeat;
  display: inline-block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 11px;
  right: 20px;
}

.distribution .subscribe .purchase-backnumber.is-open .heading .title::after {
  background: transparent url(/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg) center/contain no-repeat;
  rotate: 180deg;
}

.distribution .subscribe .purchase-backnumber .heading .exp {
  display: none;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  padding: 20px 20px 0;
}
@media all and (max-width : 768px) {
  .distribution .subscribe .purchase-backnumber .heading .exp {
    padding: 12px 12px 0;
  }
}

.distribution .subscribe .purchase-backnumber .heading .note {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285143;
  margin: 0;
}

.distribution .subscribe .purchase-backnumber .heading .check-all {
  margin: 20px 0 0;
}
@media all and (max-width : 768px) {
  .distribution .subscribe .purchase-backnumber .heading .check-all {
    margin-top: 12px;
  }
}

.distribution .subscribe .purchase-backnumber .heading .check-all label {
  cursor: pointer;
  display: block;
  font-size: 18px;
}

.distribution .subscribe .purchase-backnumber .heading .check-all span {
  padding-left: 6px;
  vertical-align: middle;
}

.distribution .subscribe .purchase-backnumber .heading .check-all input[type=checkbox] {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  inline-size: 20px;
  block-size: 20px;
}

.distribution-detail > .distribution .subscribe .purchase-backnumber .body {
  display: none;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 20px;
}
@media all and (max-width : 768px) {
  .distribution-detail > .distribution .subscribe .purchase-backnumber .body {
    padding: 8px;
  }
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcels {
  background-color: #f6f6f6;
  max-height: 255px;
  overflow-y: scroll;
  padding: 10px 20px;
}
@media all and (max-width : 768px) {
  .distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcels {
    padding: 10px 4px;
  }
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcel {
  border: 0;
  margin-top: 0;
  padding: 0;
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcel label {
  cursor: pointer;
  display: block;
  font-size: 18px;
  margin-block: 2px;
}
@media all and (max-width : 768px) {
  .distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcel label {
    margin-block: 8px;
  }
}

@media all and (max-width : 768px) {
  .distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcel label > input[type=checkbox] {
    vertical-align: middle;
    height: 16px;
    width: 16px;
  }
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcel label > issue-name {
  vertical-align: middle;
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .issue-thumbnail,
.distribution-detail > .distribution .subscribe .parcels.select-start > .body .issue-thumbnail {
  display: none;
}

.distribution-detail .body .subscribe .select-start {
  margin: 80px 0;
  position: relative;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe .select-start {
    margin: 40px 0;
  }
}

.distribution-detail .body .subscribe .select-start .heading .title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
  padding: 0;
}
@media all and (min-width: 769px) {
  .distribution-detail .body .subscribe .select-start .heading .title {
    font-size: 24px;
    line-height: 1.45833333;
    padding-top: 5px;
  }
}

.distribution-detail .body .subscribe .select-start .heading .note {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285143;
  margin: 0;
  margin-top: 10px;
}

.distribution-detail .body .subscribe .select-start .body {
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe .select-start .body {
    margin-top: 16px;
  }
}

.selec-start-issue {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
@media all and (max-width : 768px) {
  .selec-start-issue {
    align-items: flex-start;
    flex-direction: column;
  }
}
.selec-start-issue::after {
  content: "からお届け";
  display: block;
  font-size: 20px;
}
@media all and (max-width : 768px) {
  .selec-start-issue::after {
    font-size: 16px;
  }
}

.select-start .select-issues {
  appearance: none; /* デフォルトの矢印を非表示 */
  background: #f6f6f6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  border-radius: 2px;
  color: #1d1d1b;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  font-family: "Noto Sans JP";
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  padding: 0 34px 0 13px;
  min-width: 300px;
  min-height: 60px;
  display: flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .select-start .select-issues {
    background-position: right 6px center;
    font-size: 18px;
    padding: 0 30px 0 10px;
    min-height: 50px;
    min-width: auto;
    width: 100%;
  }
}

.select-start .select-issues:has(> option:first-child:last-child) {
  background: #f6f6f6;
  pointer-events: none;
}

.select-start .issue.unavailable {
  display: none;
}

.distribution-detail .body .subscribe .choose-option {
  margin-bottom: 80px;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe .choose-option {
    margin-bottom: 40px;
  }
}

.distribution-detail .body .subscribe .choose-option .heading {
  font-size: 24px;
  line-height: 1.45833333;
  padding-top: 5px;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe .choose-option .heading {
    font-size: 20px;
  }
}

.distribution-detail .body .subscribe .choose-option .body {
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe .choose-option .body {
    margin-top: 16px;
  }
}

@media all and (min-width: 769px) {
  .distribution-detail .body .subscribe > form .choose-option .options {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.distribution-detail .body .subscribe > form .choose-option .option {
  background: linear-gradient(0deg, #F6F6F6, #F6F6F6), linear-gradient(0deg, #005C69, #005C69);
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 12px;
}
@media all and (min-width: 769px) {
  .distribution-detail .body .subscribe > form .choose-option .option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px 6px 6px 20px;
    width: calc(50% - 10px);
  }
}
@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe > form .choose-option .option {
    padding: 4px;
  }
}

@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe > form .choose-option .option + .option {
    margin-top: 10px;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option.is-checked {
  border: 1px solid #005c69;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option input[type=checkbox] {
  block-size: 30px;
  display: inline-block;
  inline-size: 30px;
  margin: 0;
}
@media all and (min-width: 769px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option input[type=checkbox] {
    accent-color: #fff;
  }
}
@media all and (max-width : 768px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option input[type=checkbox] {
    width: 20px;
    height: 20px;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option > label > input[type=checkbox]:checked {
  border: 1px solid #d9d9d9;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-label {
  color: #1d1d1b;
  font-size: 16px;
  line-height: normal;
}
@media all and (min-width: 769px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option .option-label {
    font-size: 20px;
    margin-left: 19px;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-icon {
  aspect-ratio: 120/105;
  border: 1px solid #d9d9d9;
  object-fit: contain;
  height: 100%;
  max-width: 115px;
  max-height: 100px;
  margin-left: 20px;
}
@media all and (max-width : 768px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option .option-icon {
    aspect-ratio: 102/90;
    max-width: 104px;
    margin-left: 8px;
    width: 100%;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-contents {
  color: #1d1d1b;
  font-size: 14px;
  line-height: normal;
  margin-top: 4px;
}
@media all and (max-width : 768px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option .option-contents {
    width: 100%;
    font-size: 13px;
  }
}
@media all and (min-width: 769px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option .option-contents {
    font-size: 12px;
  }
}

.distribution-detail .choose-option .option .option-custom {
  display: flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .distribution-detail .choose-option .option .option-custom {
    gap: 4px;
    position: relative;
  }
}

@media all and (max-width : 768px) {
  .distribution-detail .choose-option .option .option-custom:has(.option-multiplier) {
    gap: 0;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom input[type=checkbox] {
  cursor: pointer;
  flex-shrink: 0;
  width: 20px;
}
@media all and (min-width: 769px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom input[type=checkbox] {
    order: 1;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom .option-icon {
  order: 0;
  cursor: pointer;
  pointer-events: auto;
}
@media all and (max-width : 768px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom .option-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom figure {
  pointer-events: none;
  position: relative;
}
@media all and (min-width: 769px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom figure {
    order: 3;
  }
}
@media all and (max-width : 768px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom figure {
    position: static;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom figure::before {
  content: url(/f/resources/images/common/icon_zoomin_green.svg);
  position: absolute;
  right: 49px;
  top: 3px;
  z-index: 1;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom figure::after {
  content: "拡大する";
  color: #005c69;
  font-size: 12px;
  z-index: 1;
  position: absolute;
  right: 2px;
  top: 2px;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom figure:hover {
  cursor: pointer;
  opacity: 0.8;
}

.distribution-detail .choose-option .option .option-custom .option-custom-contents {
  margin-left: 20px;
}
@media all and (min-width: 769px) {
  .distribution-detail .choose-option .option .option-custom .option-custom-contents {
    margin-right: auto;
    order: 2;
  }
}
@media all and (max-width : 768px) {
  .distribution-detail .choose-option .option .option-custom .option-custom-contents {
    margin-left: 0;
    width: calc(100% - 20px);
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom-contents .option-label {
  margin-left: 0;
}
.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom-contents .option-label:hover {
  opacity: 0.7;
}
@media all and (max-width : 768px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom-contents .option-label {
    display: flex;
    align-items: center;
    min-height: 100px;
    padding-right: 110px;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom-contents .option-multiplier {
  display: inline-block;
  margin-top: 8px;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom-contents .option-multiplier::before {
  content: "数量";
  vertical-align: middle;
  padding-right: 4px;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom-contents .option-multiplier select {
  padding: 2px 4px;
}

.distribution-detail .choose-option .option .option-custom.no-image {
  padding-top: 12px;
  padding-bottom: 12px;
}

@media all and (max-width : 768px) {
  .distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom.no-image .option-custom-contents .option-label {
    min-height: auto;
    padding-right: 0;
  }
}

.distribution-detail .body .subscribe .select-qty-buttons-wrap {
  text-align: right;
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe .select-qty-buttons-wrap {
    margin-top: 20px;
  }
}

.distribution-detail .body .subscribe .select-qty {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe .select-qty {
    width: 100%;
  }
}

.distribution-detail .body .subscribe .select-qty > .heading {
  color: #1d1d1b;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 15px;
  margin-top: 0;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe .select-qty > .heading {
    font-size: 16px;
    text-align: right;
    width: 50%;
  }
}

@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe .select-qty > .body {
    width: 50%;
  }
}

.distribution-detail .body .subscribe .select-qty .body select.item-qty {
  appearance: none; /* デフォルトの矢印を非表示 */
  background: #F6F6F6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  border-radius: 2px;
  border: 3px solid #fff;
  color: #1d1d1b;
  cursor: pointer;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  line-height: normal;
  padding: 0 10px 0 29px;
  width: 151px;
  display: inline-flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .subscribe .select-qty .body select.item-qty {
    font-size: 18px;
    height: 50px;
    padding: 0 0 0 13px;
    width: 100%;
  }
}

.distribution-detail .body .subscribe .buttons {
  padding-top: 20px;
}
@media all and (min-width: 769px) {
  .distribution-detail .body .subscribe .buttons {
    margin-inline: auto;
    width: fit-content;
  }
}

.distribution-detail .body .subscribe .buttons button[type=submit] {
  background: linear-gradient(90deg, #005C69 0%, #00ADA0 100%);
  border: 0;
  border-radius: 2px;
  color: #fff !important;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  min-height: 50px;
  padding-inline: 46px 18px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.distribution-detail .body .subscribe .buttons button[type=submit]::before {
  background: transparent url("/f/resources/images/packtoy/list/icon_cart.svg") center/contain no-repeat;
  content: "";
  display: inline-block;
  width: 18px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}

.distribution-detail .body .detail-description {
  font-size: 16px;
  margin-top: 80px;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .detail-description {
    font-size: 14px;
    margin-top: 40px;
  }
}

.distribution-detail .body .distribution-schedule {
  border-top: none;
  margin-top: 50px;
  padding: 0;
}
@media all and (min-width: 769px) {
  .distribution-detail .body .distribution-schedule {
    margin-top: 30px;
  }
}

.distribution-detail .body .distribution-schedule > .heading {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
}

.distribution-detail .body .distribution-schedule .body {
  border-top: 1px solid #b2b2b2;
  margin-top: 10px;
}

.distribution-detail .body .distribution-schedule .note {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285143;
  margin: 0;
  margin-top: 10px;
}

.distribution-detail .distribution .distribution-schedule .parcels {
  gap: 0;
}

.distribution-detail .body .distribution-schedule .parcels .parcel {
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  margin-top: 10px;
  padding: 0;
}

.distribution-detail .distribution-schedule .parcels .parcel.is-open {
  border-bottom: 1px solid #d9d9d9;
}

.distribution-detail .body .distribution-schedule .parcel .summary {
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  padding: 12px 60px 12px 20px;
  position: relative;
}
@media all and (max-width : 768px) {
  .distribution-detail .body .distribution-schedule .parcel .summary {
    padding-right: 40px;
    padding-left: 12px;
  }
}

.distribution-detail .body .distribution-schedule .parcels .parcel .summary::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_plus_green.svg") center/contain no-repeat;
  display: inline-block;
  width: 15px;
  height: 13px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.distribution-detail .body .distribution-schedule .body > ol > li .summary .parcel-privilege {
  margin-top: 16px;
  padding: 0 20px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues,
.distribution-detail .body .distribution-schedule .parcel > .option-parcels,
.distribution-detail .body .distribution-schedule .parcel > .subscription-privileges {
  background-color: #fff;
  margin-top: 15px;
  padding: 0 20px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > .label {
  display: none;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > .issues {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 20px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol > li {
  max-width: 150px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues .issue-thumbnail {
  display: block;
  max-width: 140px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues .issue-thumbnail img {
  aspect-ratio: 140/123;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .item-name,
.distribution-detail .body .distribution-schedule .parcel > .issues > ol .price {
  display: block;
  font-size: 12px;
  line-height: normal;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .item-name {
  font-weight: 500;
  letter-spacing: -0.12px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .price {
  font-weight: 400;
  margin-top: 7px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .individual-shipping-fee {
  margin-top: 4px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .individual-shipping-fee .label,
.distribution-detail .body .distribution-schedule .parcel > .issues > ol .individual-shipping-fee .fee {
  font-size: 12px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .individual-shipping-fee .fee {
  padding-left: 4px;
}

.distribution-detail .body .distribution-schedule .parcel .shipping > * {
  display: none;
}

.distribution-detail .body .distribution-schedule .parcels .parcel.is-open {
  padding-bottom: 20px;
}

.distribution-detail .body .distribution-schedule .parcels .parcel.is-open .summary::after {
  background: transparent url("/f/resources/images/common/icon_minus_green.svg") center/contain no-repeat;
}

.distribution-detail .body .distribution-schedule .parcel > *:not(.summary) {
  display: none;
}

/*
  ---------------
  パートワーク商品詳細
  ---------------
*/
.partwork-product .p-product {
  margin-top: 40px;
}

@media all and (min-width: 1025px) {
  .partwork-product .p-product .p-product__block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: min(5.55555556vw, 80px);
  }
}

.partwork-product .p-product .p-product__btn-group {
  margin-top: 90px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__btn-group {
    margin-top: 50px;
  }
}

.partwork-product .p-product .p-product__btn {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  width: calc(50% - 15px);
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__btn {
    width: 100%;
  }
}

@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__btn-group--item {
    display: flex;
    gap: 30px;
  }
}

@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__btn-group--item .p-product__btn + .p-product__btn {
    margin-top: 10px;
  }
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn-link {
  color: #005c69;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4375;
  text-align: left;
  width: 100%;
  padding-right: 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn-link > p {
  flex: 0 0 108px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__btn-group--item .p-product__btn-link > p {
    flex: 0 0 100px;
    height: 100px;
  }
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn-link img {
  width: 100%;
  height: 100%;
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn-link span {
  display: block;
  width: 100%;
  margin-left: 20px;
  padding-right: 10px;
  position: relative;
}

.partwork-product .p-product .p-product__btn-group--item .p-product__btn-link span::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_chevron_right.svg") center/cover no-repeat;
  display: inline-block;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__btn-group--usage {
  margin-top: 30px;
  display: flex;
  gap: 30px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__btn-group--usage {
    gap: 10px;
    margin-top: 16px;
  }
}

.partwork-product .p-product .p-product__btn-group--usage .p-product__btn-link {
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partwork-product .p-product .p-product__btn-group--usage .p-product__btn-link span {
  display: inline-block;
  padding-left: 26px;
  position: relative;
}

.partwork-product .p-product .p-product__btn-group--usage .p-product__btn-link span::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__btn-group--usage .p-product__btn-link span.community::after {
  background: transparent url("/f/resources/images/common/icon_community.svg") center/cover no-repeat;
  width: 22px;
  height: 20px;
}

.partwork-product .p-product .p-product__btn-group--usage .p-product__btn-link span.qa::after {
  background: transparent url("/f/resources/images/common/icon_qa.svg") center/cover no-repeat;
  width: 18px;
  height: 20px;
}

.partwork-product .p-product .p-product__display-btn-group {
  display: grid;
  gap: 20px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__display-btn-group {
    gap: 10px;
  }
}

.partwork-product .p-product .p-product__display-btn-link--series {
  background-color: #00828c;
  color: #fff;
  min-height: 50px;
  padding: 0 10px;
  justify-content: center;
}

.partwork-product .p-product .p-product__display-btn-link--other {
  border: 1px solid #005c69;
  color: #00828c;
  min-height: 50px;
  padding: 0 10px;
  justify-content: center;
}

.partwork-product .p-product .p-product__display-btn-icon {
  padding-right: 23px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__display-btn-icon {
    font-size: 14px;
    line-height: 1.42857143;
  }
}

.partwork-product .p-product .p-product__display-btn-icon::after {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__display-btn-link--series .p-product__display-btn-icon::after {
  background: transparent url("/f/resources/images/common/icon_link_white.svg") center/contain no-repeat;
}

.partwork-product .p-product .p-product__display-btn-link--other .p-product__display-btn-icon::after {
  background: transparent url("/f/resources/images/common/icon_link_green.svg") center/contain no-repeat;
}

.partwork-product .p-product .p-product__display-slider {
  margin-top: 20px;
  position: relative;
}
@media all and (min-width: 1025px) {
  .partwork-product .p-product .p-product__display-slider {
    max-width: 560px;
  }
}

.partwork-product .p-product .p-product__display-slide {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  display: table;
  position: relative;
  text-align: center;
  transition: 0.2s ease-in-out opacity;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
@media all and (min-width: 1025px) {
  .partwork-product .p-product .p-product__display-slide {
    max-width: 560px;
  }
}

.partwork-product .p-product .p-product__display-slide:hover {
  opacity: 0.8;
}

.partwork-product .p-product .p-product__display-slide::before {
  background: url("/f/resources/images/common/icon_zoomin_green.svg") center/contain no-repeat;
  content: "";
  display: inline-block;
  width: 23px;
  height: 23px;
  z-index: 1;
  position: absolute;
  right: 68px;
  top: 7px;
}

.partwork-product .p-product .p-product__display-slide::after {
  content: "拡大する";
  color: #005c69;
  font-size: 16px;
  z-index: 1;
  position: absolute;
  right: 4px;
  top: 6px;
}

.partwork-product .p-product .p-product__display-slide img {
  aspect-ratio: 560/560;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: auto;
}

.partwork-product .p-product .p-product__display-dots {
  position: relative;
}

@media all and (max-width: 1024px) {
  .partwork-product .p-product .p-product__detail {
    margin-top: 20px;
  }
}

.partwork-product .p-product .p-product__detail-header-title {
  display: inline;
}

.partwork-product .p-product div.item-tags {
  display: inline-flex;
  vertical-align: 2px;
}

.partwork-product .p-product div.item-tags div.item-tag {
  border-radius: 0;
  padding: 0 4px 2px;
}

.partwork-product .p-product .p-product__detail-header-title span:first-child {
  font-weight: 700;
}

.partwork-product .p-product .p-product__detail-header-number {
  color: #747474;
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 7px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail-header-number {
    margin-top: 9px;
  }
}

.partwork-product .p-product .p-product__deliveryFee {
  margin-top: 12px;
}

.partwork-product .p-product .p-product__deliveryFee-header {
  font-size: 14px;
}

.partwork-product .p-product .p-product__deliveryFee-body {
  font-weight: 700;
  font-size: 22px;
}

.partwork-product .p-product .p-product__detail form {
  margin-top: 50px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail form {
    margin-top: 30px;
  }
}

.partwork-product .p-product .p-product__detail form .item-qty-item-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.partwork-product .p-product .p-product__detail form .item-qty-note {
  color: #c10000;
  margin-inline-end: 20px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail form .item-qty-note {
    font-size: 14px;
    line-height: 1.42857143;
    margin-inline-end: 17px;
  }
}

.partwork-product .p-product .p-product__detail form .item-qty-item {
  display: flex;
  align-items: center;
}

.partwork-product .p-product .p-product__detail form .item-qty-label {
  font-size: 14px;
  line-height: 1.42857143;
  margin-inline-end: 15px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail form .item-qty-label {
    margin-inline-end: 8px;
  }
}

.partwork-product .p-product .p-product__detail form .qty.item-qty {
  appearance: none;
  background: #F6F6F6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  border: 0;
  border-radius: 2px;
  color: #1D1D1B;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  line-height: normal;
  padding-inline: 10px 29px;
  display: inline-flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail form .qty.item-qty {
    font-size: 16px;
    height: 35px;
    padding-inline-end: 13px;
    width: 84px;
  }
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__detail form .qty.item-qty {
    width: 150px;
  }
}

.partwork-product .p-product .p-product__detail form .btn-group-purchase {
  font-size: 0;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail form .btn-group-purchase {
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
    gap: 2.54452926vw;
  }
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__detail form .btn-group-purchase {
    gap: clamp(0px, 0.69444444vw, 20px);
  }
}

.partwork-product .p-product .p-product__detail form button.btn.add-item-to-cart,
.partwork-product .p-product .p-product__detail form button.btn.checkout-item {
  margin-left: 0;
  border: 0;
  border-radius: 2px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.33333333;
  width: 50%;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail form button.btn.add-item-to-cart,
  .partwork-product .p-product .p-product__detail form button.btn.checkout-item {
    width: 100%;
  }
}

.partwork-product .p-product .p-product__detail form .add-item-to-cart {
  background: #00828c;
  min-width: 178px;
  height: 50px;
  padding: 0 19px 0 47px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail form .add-item-to-cart {
    min-width: auto;
    padding-left: 35px;
  }
}

.partwork-product .p-product .p-product__detail form .add-item-to-cart::before {
  content: "";
  background: url("/f/resources/images/packtoy/list/icon_cart.svg") center/contain no-repeat;
  display: inline-block;
  width: 17px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: auto;
  left: calc(50% - 66px);
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail form .add-item-to-cart::before {
    left: calc(50% - 67px);
  }
}

.partwork-product .p-product .checkout-item {
  background: #ff9c08;
  min-width: 178px;
  height: 50px;
  padding: 0 47px 0 19px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .checkout-item {
    min-width: auto;
  }
}

.partwork-product .p-product .checkout-item::before {
  content: "";
  border-radius: 100%;
  background: url("/f/resources/images/common/icon_arrow_right_bg_white.svg") center/contain no-repeat;
  display: inline-block;
  height: 24px;
  margin-left: 20px;
  width: 24px;
  position: absolute;
  top: 50%;
  right: 14px;
  left: auto;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__detail-btn-group {
  margin-top: 30px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__detail-btn-group {
    margin-top: 20px;
  }
}

.partwork-product .p-product .p-product__detail-btn-link.subscription {
  background: linear-gradient(90deg, #005C69 0%, #00ADA0 100%);
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.45833333;
  width: 100%;
  min-height: 80px;
}
@media (hover: hover) and (pointer: fine) {
  .partwork-product .p-product .p-product__detail-btn-link.subscription {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .partwork-product .p-product .p-product__detail-btn-link.subscription:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.partwork-product .p-product .p-product__detail-btn-link.subscription::before, .partwork-product .p-product .p-product__detail-btn-link.subscription::after {
  backface-visibility: hidden;
}

.partwork-product .p-product .p-product__detail-btn-link.subscription span {
  color: #fff;
  padding-left: 36px;
  position: relative;
}

.partwork-product .p-product .p-product__detail-btn-link.subscription span::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_cart.svg") center/contain no-repeat;
  display: inline-block;
  width: 26px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__detail-btn-link:not(.subscription) {
  background-color: #494949;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  min-height: 50px;
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.partwork-product .p-product .p-product__detail-btn-link:not(.subscription)::after {
  background: url(/f/resources/images/common/icon_link_othertab_white.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  margin-left: 4px;
  height: 16px;
  width: 16px;
}

.partwork-product .p-product .p-product__price-amount {
  margin-top: 28px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__price-amount {
    margin-top: 24px;
  }
}

.partwork-product .p-product .p-product__price-amount-price {
  font-size: 35px;
  font-weight: 700;
  line-height: 1.45714286;
}

.partwork-product .p-product .p-product__price-amount-tax {
  font-weight: 500;
}

.partwork-product .p-product .p-product__price-base {
  color: #747474;
  font-size: 14px;
  line-height: 1.42857143;
}

.partwork-product .p-product .p-product__price-original {
  color: #747474;
  font-size: 14px;
  line-height: 1.42857143;
  text-decoration: line-through;
  margin-top: 5px;
}

.partwork-product .p-product .p-product__summary {
  border-bottom: 1px solid #d9d9d9;
  margin-top: 30px;
  padding-bottom: 30px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__summary {
    padding-bottom: 20px;
    margin-top: 20px;
  }
}

.partwork-product .p-product .p-product__summary-title {
  font-size: 18px;
  line-height: 1.45833333;
  margin-top: 20px;
}

.partwork-product .p-product .p-product__summary-description {
  font-size: 16px;
  line-height: 1.42857143;
  margin-top: 6px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__summary-description {
    font-size: 14px;
    margin-top: 12px;
  }
}

.partwork-product .p-product .p-product__summary-description + .p-product__summary-description {
  margin-top: 10px;
}

.partwork-product .p-product .p-product__summary-description r {
  color: red;
}

.partwork-product .p-product .p-product__summary-description rb {
  color: red;
  font-weight: bold;
}

.partwork-product .p-product .p-product__summary-description b {
  font-weight: bold;
}

.partwork-product .p-product .p-product__summary-content {
  padding: 27px 30px 40px;
  margin-top: 30px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__summary-content {
    margin-top: 20px;
    padding: 17px 10px 30px;
  }
}

.partwork-product .p-product .p-product__summary-content-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.44444444;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__summary-content-title {
    font-size: 16px;
    line-height: 1.4375;
  }
}

.partwork-product .p-product .p-product__summary-content-body {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 10px;
}

.partwork-product .p-product .p-product__issue {
  margin-top: 80px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__issue {
    margin-top: 50px;
  }
}

@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__issue-inner {
    position: relative;
  }
}

.partwork-product .p-product .p-product__issue-btn {
  text-align: center;
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__issue-btn {
    position: absolute;
    top: 5px;
    right: 0;
  }
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__issue-btn {
    margin-top: 40px;
  }
}

.partwork-product .p-product .p-product__issue-btn-link {
  color: #005c69;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.46666667;
}

.partwork-product .p-product .p-product__issue-btn-link span {
  padding-right: 37px;
  position: relative;
  display: block;
  text-align: center;
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__issue-btn-link span {
    width: 100%;
  }
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__issue-btn-link span {
    display: inline-block;
  }
}

.partwork-product .p-product .p-product__issue-btn-link span::after {
  content: "";
  border-radius: 2px;
  background: #fff url("/f/resources/images/top/icon_arrow_right_bg_green.svg") center/contain no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.partwork-product .p-product .p-product__issue-slider {
  margin-top: 20px;
}

.partwork-product .p-product .p-product__card {
  margin-inline: min(1.04166667vw, 15px);
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__card {
    margin-inline: 2.54452926vw;
  }
}

.partwork-product .p-product .p-product__card-link {
  width: 100%;
}

.partwork-product .p-product .p-product__card-img {
  text-align: center;
  aspect-ratio: 278/245;
  border: 1px solid #d9d9d9;
  position: relative;
}

.partwork-product .p-product .p-product__card-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.partwork-product .p-product .p-product__card-title {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 17px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__card-title {
    font-weight: 500;
    margin-top: 10px;
  }
}

.partwork-product .p-product .p-product__card-text {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 10px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__card-text {
    font-weight: 500;
  }
}

.partwork-product .p-product .p-product__display .slick-dotted.slick-slider {
  margin-bottom: 20px;
  touch-action: auto;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__display .slick-dotted.slick-slider {
    margin-bottom: 10px;
  }
}
.partwork-product .p-product .p-product__display .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__display .slick-prev,
  .partwork-product .p-product .p-product__display .slick-next {
    width: 15px;
    height: 30px;
  }
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__display .slick-prev {
    left: -30px;
  }
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__display .slick-next {
    right: -30px;
  }
}
.partwork-product .p-product .p-product__display .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__display .slick-prev:before {
    background: transparent url("/f/resources/images/partwork/product/icon_prev.svg") center/contain no-repeat;
    width: 15px;
    height: 30px;
  }
}
.partwork-product .p-product .p-product__display .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__display .slick-next:before {
    background: transparent url("/f/resources/images/partwork/product/icon_next.svg") center/contain no-repeat;
    width: 15px;
    height: 30px;
  }
}
.partwork-product .p-product .p-product__display .slick-dots {
  position: static;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 14px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__display .slick-dots {
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
}
.partwork-product .p-product .p-product__display .slick-dots li {
  aspect-ratio: 26/23;
  border: 1px solid #d9d9d9;
  display: block;
  width: auto;
  height: auto;
  margin: 0;
  position: relative;
}
.partwork-product .p-product .p-product__display .slick-dots li:not(.slick-active) {
  opacity: 0.5;
}
.partwork-product .p-product .p-product__display .slick-dots img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__issue .slick-list {
    margin: 0 min(-1.04166667vw, -15px);
  }
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__issue .slick-list {
    overflow: visible;
  }
}
.partwork-product .p-product .p-product__issue .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.partwork-product .p-product .p-product__issue .slick-prev,
.partwork-product .p-product .p-product__issue .slick-next {
  width: 50px;
  height: 50px;
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__issue .slick-prev,
  .partwork-product .p-product .p-product__issue .slick-next {
    width: 30px;
    height: 60px;
  }
}
.partwork-product .p-product .p-product__issue .slick-prev {
  left: min(-2.08333333vw, -30px);
}
@media all and (max-width: 1024px) {
  .partwork-product .p-product .p-product__issue .slick-prev {
    left: min(-2.44140625vw, -25px);
  }
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__issue .slick-prev {
    left: -14px;
  }
}
.partwork-product .p-product .p-product__issue .slick-next {
  right: min(-2.08333333vw, -30px);
}
@media all and (max-width: 1024px) {
  .partwork-product .p-product .p-product__issue .slick-next {
    right: min(-2.44140625vw, -25px);
  }
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__issue .slick-next {
    right: -14px;
  }
}
.partwork-product .p-product .p-product__issue .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__issue .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__issue .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.partwork-product .p-product .p-product__issue .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .partwork-product .p-product .p-product__issue .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .partwork-product .p-product .p-product__issue .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

/*
  ----------------
  signup | 共通
  ----------------
*/
#signup #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  #signup #outer {
    padding-inline: 12px;
  }
}

#signup div.formrow,
#signup .signup-form,
#signup .signin-form {
  padding: 0;
}

#signup .signup-form div.input-label,
#signup .signin-form div.input-label {
  display: block;
}

#signup .formrow-yyyymmdd div.input-label {
  display: flex;
}

#signup .signup-form form,
#signup .signin-form form {
  background-color: #f6f6f6;
  padding: 20px 0;
  margin-top: 20px;
}
@media all and (min-width: 769px) {
  #signup .signup-form form,
  #signup .signin-form form {
    padding: 25px 0 58px;
  }
}

#signup .signup-form .form-signup .formrow-input,
#signup .signup-form .form-signup .formrow-radio,
#signup .signup-form .form-signup .formrow-yyyymmdd,
#signup .signup-form .form-signup .formrow-checkbox,
#signup .signin-form .form-signin .formrow {
  display: block;
  padding-inline: 15px;
}
@media all and (min-width: 769px) {
  #signup .signup-form .form-signup .formrow-input,
  #signup .signup-form .form-signup .formrow-radio,
  #signup .signup-form .form-signup .formrow-yyyymmdd,
  #signup .signup-form .form-signup .formrow-checkbox,
  #signup .signin-form .form-signin .formrow {
    padding-inline: 45px;
  }
}

#signup h1,
#signup h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
  padding: 0;
}
@media all and (min-width: 769px) {
  #signup h1,
  #signup h3 {
    font-size: 24px;
    line-height: 1.45833333;
  }
}

#signup .signup-form .form-signup .formrow,
#signup .signin-form .form-signin .formrow {
  margin-top: 15px;
}

#signup .signup-form .form-signup .formrow-input .input-control,
#signup .signup-form .form-signup .formrow-boolean .input-control,
#signup .signin-form .form-signin .formrow .input-control {
  display: flex;
  align-items: start;
  flex-direction: column-reverse;
}

#signup .signup-form .form-signup .formrow-input .input-control .input-note,
#signup .signup-form .form-signup .formrow-boolean .input-control .input-note,
#signup .signin-form .form-signin .formrow .input-control .input-note,
#signup .signup-form .formrow-submit .note {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  margin-left: 0;
  padding-left: 0;
}

#signup .form-note {
  margin: 0;
  margin-block-start: 22px;
}

#signup .signup-form .form-signup .input-label,
#signup .signup-form .form-signup .input-control,
#signup .signin-form .form-signin .input-label,
#signup .signin-form .form-signin .input-control {
  padding: 0;
}

#signup .signup-form .formrow-submit,
#signup .signin-form form > .btn-submit {
  padding: 0;
}

#signup div.formrow div.input-control div.input-group {
  flex: auto;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0,
.signup-form #signup-form .form-signup .formrow.a9816990-05da-4433-9d0d-2ef9965732b0 {
  padding-bottom: 32px;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0 .input-label,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0 .input-label,
.signup-form .a9816990-05da-4433-9d0d-2ef9965732b0 .input-label {
  display: flex;
  width: fit-content;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name,
.signup-form .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name {
  display: inline-block;
  line-height: 2;
  position: relative;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::before,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::before,
.signup-form .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::before {
  background: url(/f/resources/images/common/icon_plus_circle_green.svg) 50%/contain no-repeat;
  content: "";
  cursor: pointer;
  display: block;
  height: 18px;
  width: 18px;
  position: absolute;
  top: 130%;
  left: 0;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-name::before,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-name::before,
.signup-form .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-name::before {
  background: url(/f/resources/images/common/icon_minus_circle_green.svg) 50%/contain no-repeat;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::after,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::after,
.signup-form .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::after {
  content: "タイトルを選ぶ";
  color: #005c69;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 130%;
  left: 32px;
  transform: translateY(-23%);
}

#account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group,
#form .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group,
#signin .form-signup .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group,
#signup .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group,
#order__signin_to_checkout .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group,
.form-auth.form-signin #signup-form .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group {
  display: none;
}

#account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0 .input-control,
#form .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0 .input-control,
#signin .form-signup .a9816990-05da-4433-9d0d-2ef9965732b0 .input-control,
#signup .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0 .input-control,
#order__signin_to_checkout .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control,
.form-auth.form-signin div.formrow.a9816990-05da-4433-9d0d-2ef9965732b0 div.input-control {
  flex-direction: column;
  margin-top: 40px;
}

#account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-control .input-group,
#form .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-control .input-group,
#signin .form-signup .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-control .input-group,
#signup .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-control .input-group,
#order__signin_to_checkout .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox.is-open .input-control .input-group,
.form-auth.form-signin #signup-form .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox.is-open .input-control .input-group {
  display: flex;
  padding-bottom: 10px;
}

/*
  ----------------
  signup
  ----------------
*/
#signup .signup-form {
  margin-top: 20px;
}

#signup .signup-form .form-signup .formrow .input-control .input-group,
#signup .signup-form .form-signup .formrow.formrow-checkbox.formrow-boolean .checkbox {
  font-size: 0;
  padding: 0;
  margin-top: 8px;
}
@media all and (max-width : 768px) {
  #signup .signup-form .form-signup .formrow .input-control .input-group,
  #signup .signup-form .form-signup .formrow.formrow-checkbox.formrow-boolean .checkbox {
    width: 100%;
  }
}

#signup .signup-form .form-signup fieldset .formrow .input-control .input-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media all and (max-width : 768px) {
  #signup .signup-form .form-signup fieldset .formrow .input-control .input-group .radio-item {
    width: 100%;
  }
}

@media all and (max-width : 768px) {
  #signup .signup-form .form-signup fieldset .formrow .input-control .input-group .radio-item label {
    width: 100%;
  }
}

#signup .signup-form .form-signup .formrow-yyyymmdd .input-control > div.input-group {
  gap: 5px;
}
@media all and (max-width : 768px) {
  #signup .signup-form .form-signup .formrow-yyyymmdd .input-control > div.input-group {
    flex-wrap: nowrap;
  }
}

#signup .signup-form .form-signup .formrow-input .input-control .input-group input {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

#signup .signup-form .form-signup .formrow-input .input-label,
#signup .signup-form .form-signup .formrow-radio .input-label {
  display: flex;
  align-items: center;
}

#signup .signup-form .form-signup .formrow .input-label .input-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  margin-inline-end: 5px;
}

#signup .signup-form fieldset {
  background-color: transparent;
  border-top: 1px solid #d9d9d9;
  border-radius: 0;
  margin: 0;
  padding: 0;
  margin-block-start: 50px;
  padding-block-start: 20px;
}
@media all and (min-width: 769px) {
  #signup .signup-form fieldset {
    padding-block-start: 25px;
  }
}

#signup .signup-form .form-signup fieldset .formrow-radio .input-control label,
#signup .signup-form .form-signup fieldset .formrow-yyyymmdd .input-control select {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  position: relative;
}
@media all and (max-width : 768px) {
  #signup .signup-form .form-signup fieldset .formrow-radio .input-control label,
  #signup .signup-form .form-signup fieldset .formrow-yyyymmdd .input-control select {
    width: calc((100% - 10px) / 3);
  }
}

#signup .signup-form .form-signup fieldset .formrow-radio .input-control label {
  padding-block: 9px;
  padding-inline: 38px 14px;
}

#signup .signup-form .form-signup .formrow-radio input[type=radio],
#signup .signup-form .form-signup .input-control .input-group select {
  appearance: none;
  margin: 0;
  border: none;
}

#signup .signup-form .form-signup fieldset .formrow-radio .input-control label::before, #signup .signup-form .form-signup fieldset .formrow-radio .input-control label::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#signup .signup-form .form-signup fieldset .formrow-radio .input-control label::before {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  left: 10px;
}

#signup .signup-form .form-signup fieldset .formrow-radio .input-control label.is-checked {
  border: 1px solid #005c69;
}
#signup .signup-form .form-signup fieldset .formrow-radio .input-control label.is-checked::after {
  background-color: #005c69;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  left: 13px;
}

#signup .signup-form .form-signup .input-control .input-group select {
  background: transparent url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  color: #333;
  padding-block: 10px;
  padding-inline: 10px 8px;
  width: 100%;
  max-width: 150px;
  min-width: 150px;
  display: inline-flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  #signup .signup-form .form-signup .input-control .input-group select {
    min-width: 94px;
    padding-inline: 8px;
  }
}

#signup .signup-form .form-signup .formrow-checkbox .input-control label {
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
  padding: 10px 14px 10px 10px;
  display: flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  #signup .signup-form .form-signup .formrow-checkbox .input-control label {
    width: 100%;
  }
}

#signup .signup-form .form-signup .formrow-checkbox .input-control input {
  accent-color: #005c69;
  margin: 0 8px 0 0;
  height: 20px;
  width: 20px;
}

#signup .signup-form .form-signup .formrow-checkbox .input-control label.is-checked {
  border: 1px solid #005c69;
}

#signup .signup-form .formrow-submit {
  margin-top: 60px;
  padding-inline: 15px;
}

#signup .signup-form .formrow-submit .note {
  margin: 0;
}

#signup .signup-form .signup-agree-and-submit .note-signup {
  font-size: 16px;
  padding-bottom: 12px;
}

/*
  ----------------
  signin
  ----------------
*/
#signup .signin-form {
  margin-block-start: 40px;
}
@media all and (min-width: 769px) {
  #signup .signin-form {
    margin-block-start: 100px;
  }
}

#signup .signin-form .form-signin .formrow .input-control .input-group {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#signup .signin-form .form-signin .formrow .input-control .input-group:has(.password-str-meter) {
  gap: 0;
}

#signup .signin-form .form-signin .formrow .input-control .input-group input {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

#signup .signin-form .form-signin .formrow .input-label,
#signup .signin-form .form-signin .formrow .input-label {
  display: flex;
  align-items: center;
}

#signup .signin-form .form-signin .formrow .input-label .input-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  margin-inline-end: 5px;
}

#signup .signin-form form > .btn-submit {
  margin-top: 60px;
}

#signup .signin-form .reminder {
  text-align: center;
  padding: 40px 0 0;
}

#signup .signin-form .reminder a {
  font-size: 16px;
  text-decoration: underline;
}

/*
  ----------------
  signin
  ----------------
*/
#order__resignin #outer,
#signout #outer,
#signin #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  #order__resignin #outer,
  #signout #outer,
  #signin #outer {
    padding-inline: 12px;
  }
}

#signout h1,
#signout h3,
#signin h1,
#signin h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
  padding: 0;
}
@media all and (min-width: 769px) {
  #signout h1,
  #signout h3,
  #signin h1,
  #signin h3 {
    font-size: 24px;
    line-height: 1.45833333;
  }
}

#signin #container > div > div.form.signin-form {
  padding: 0;
}

#signin div.formrow,
#signin .signup-form,
#signin .signin-form {
  padding: 0;
}

#signin .signup-form .form-signup .input-label,
#signin .signup-form .form-signup .input-control,
#signin .signin-form .form-signin .input-label,
#signin .signin-form .form-signin .input-control {
  padding: 0;
}

#signin .signup-form .form-signup .formrow-input .input-control .input-note,
#signin .signup-form .form-signup .formrow-boolean .input-control .input-note,
#signin .signin-form .form-signin .formrow .input-control .input-note,
#signin .signup-form .formrow-submit .note {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  margin-left: 0;
  padding-left: 0;
}

.initpass {
  background-color: #f6f6f6;
  margin-top: 100px;
  padding: 45px;
  text-align: center;
}
@media all and (max-width : 768px) {
  .initpass {
    margin-top: 60px;
    padding: 15px;
  }
}
.initpass > a {
  background-image: none;
  background-color: #005c69;
  border: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8;
  margin-top: 10px;
  padding: 10px;
  position: relative;
  width: 100%;
  max-width: 420px;
  height: 50px;
}
.initpass > div {
  margin-top: 20px;
}

@media all and (max-width : 768px) {
  .form-auth.form-signin .input-group-radio {
    flex-direction: column;
  }
}
@media all and (max-width : 768px) {
  .form-auth.form-signin .input-group-radio .radio-item label,
  .form-auth.form-signin .input-group-radio .radio-item {
    width: 100%;
  }
}

#order__signin_to_checkout div.btn-submit {
  padding-bottom: 0;
}

#order__resignin.order__resignin h3 {
  font-size: 24px;
}
@media all and (max-width : 768px) {
  #order__resignin.order__resignin h3 {
    font-size: 20px;
  }
}
#order__resignin.order__resignin div.form.signin-form > div.reminder {
  margin-top: 40px;
  text-align: center;
}

/*
  ----------------
  signin | はじめてご利用のお客様
  ----------------
*/
.form-auth.form-signin #container .signup-form,
#signin #container .signup-form {
  margin-top: 100px;
}
@media all and (max-width : 768px) {
  .form-auth.form-signin #container .signup-form,
  #signin #container .signup-form {
    margin-top: 60px;
  }
}
.form-auth.form-signin #signup-form,
#signin #signup-form {
  background-color: #f6f6f6;
  padding: 20px 0;
  margin-top: 20px;
}
@media all and (min-width: 769px) {
  .form-auth.form-signin #signup-form,
  #signin #signup-form {
    padding: 25px 0 58px;
  }
}
.form-auth.form-signin #signup-form .formrow,
#signin #signup-form .formrow {
  display: block;
  margin-top: 15px;
  padding-inline: 15px;
}
@media all and (min-width: 769px) {
  .form-auth.form-signin #signup-form .formrow,
  #signin #signup-form .formrow {
    padding-inline: 45px;
  }
}
.form-auth.form-signin #signup-form .input-label,
#signin #signup-form .input-label {
  padding: 0;
  display: flex;
  align-items: center;
}
.form-auth.form-signin #signup-form .input-label .input-name,
#signin #signup-form .input-label .input-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  margin-inline-end: 5px;
}
.form-auth.form-signin #signup-form .formrow-input .input-control,
#signin #signup-form .formrow-input .input-control {
  padding: 0;
  display: flex;
  align-items: start;
  flex-direction: column-reverse;
}
.form-auth.form-signin .signup-form .form-signup .formrow .input-control .input-group,
.form-auth.form-signin .signup-form .form-signup .formrow.formrow-checkbox.formrow-boolean .checkbox,
#signin .signup-form .form-signup .formrow .input-control .input-group,
#signin .signup-form .form-signup .formrow.formrow-checkbox.formrow-boolean .checkbox {
  font-size: 0;
  padding: 0;
  margin-top: 8px;
}
.form-auth.form-signin #signup-form .input-control .input-group,
#signin #signup-form .input-control .input-group {
  flex: auto;
  flex-wrap: wrap;
  margin-top: 10px;
}
.form-auth.form-signin #signup-form .input-control .input-group input,
#signin #signup-form .input-control .input-group input {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.form-auth.form-signin #signup-form fieldset,
#signin #signup-form fieldset {
  background-color: transparent;
  border-top: 1px solid #d9d9d9;
  border-radius: 0;
  margin: 0;
  padding: 0;
  margin-block-start: 50px;
  padding-block-start: 20px;
}
@media all and (min-width: 769px) {
  .form-auth.form-signin #signup-form fieldset,
  #signin #signup-form fieldset {
    padding-block-start: 25px;
  }
}
.form-auth.form-signin .signup-form .form-signup .formrow-input,
.form-auth.form-signin .signup-form .form-signup .formrow-radio,
.form-auth.form-signin .signup-form .form-signup .formrow-yyyymmdd,
.form-auth.form-signin .signup-form .form-signup .formrow-checkbox,
.form-auth.form-signin .signin-form .form-signin .formrow,
#signin .signup-form .form-signup .formrow-input,
#signin .signup-form .form-signup .formrow-radio,
#signin .signup-form .form-signup .formrow-yyyymmdd,
#signin .signup-form .form-signup .formrow-checkbox,
#signin .signin-form .form-signin .formrow {
  display: block;
  padding-inline: 15px;
}
@media all and (min-width: 769px) {
  .form-auth.form-signin .signup-form .form-signup .formrow-input,
  .form-auth.form-signin .signup-form .form-signup .formrow-radio,
  .form-auth.form-signin .signup-form .form-signup .formrow-yyyymmdd,
  .form-auth.form-signin .signup-form .form-signup .formrow-checkbox,
  .form-auth.form-signin .signin-form .form-signin .formrow,
  #signin .signup-form .form-signup .formrow-input,
  #signin .signup-form .form-signup .formrow-radio,
  #signin .signup-form .form-signup .formrow-yyyymmdd,
  #signin .signup-form .form-signup .formrow-checkbox,
  #signin .signin-form .form-signin .formrow {
    padding-inline: 45px;
  }
}
.form-auth.form-signin .signup-form fieldset .formrow .input-control .input-group,
#signin .signup-form fieldset .formrow .input-control .input-group {
  display: flex;
  gap: 10px;
}
.form-auth.form-signin .signup-form fieldset .formrow-yyyymmdd .input-control > div.input-group,
#signin .signup-form fieldset .formrow-yyyymmdd .input-control > div.input-group {
  gap: 5px;
}
.form-auth.form-signin #signup-form fieldset > div.formrow.formrow-checkbox.formrow-boolean > div.input-control,
#signin #signup-form fieldset > div.formrow.formrow-checkbox.formrow-boolean > div.input-control {
  padding: 0;
  display: flex;
  align-items: start;
  flex-direction: column-reverse;
}
.form-auth.form-signin .signup-form .form-signup fieldset .formrow-radio .input-control label,
.form-auth.form-signin .signup-form .form-signup fieldset .formrow-yyyymmdd .input-control select,
#signin .signup-form .form-signup fieldset .formrow-radio .input-control label,
#signin .signup-form .form-signup fieldset .formrow-yyyymmdd .input-control select {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  position: relative;
}
.form-auth.form-signin .signup-form .form-signup fieldset .formrow-radio .input-control label,
#signin .signup-form .form-signup fieldset .formrow-radio .input-control label {
  padding: 9px 14px 9px 38px;
}
.form-auth.form-signin .signup-form .form-signup .formrow-radio input[type=radio],
.form-auth.form-signin .signup-form .form-signup .input-control .input-group select,
#signin .signup-form .form-signup .formrow-radio input[type=radio],
#signin .signup-form .form-signup .input-control .input-group select {
  appearance: none;
  margin: 0;
  display: none;
}
.form-auth.form-signin .signup-form .form-signup fieldset .formrow-radio .input-control label::before, .form-auth.form-signin .signup-form .form-signup fieldset .formrow-radio .input-control label::after,
#signin .signup-form .form-signup fieldset .formrow-radio .input-control label::before,
#signin .signup-form .form-signup fieldset .formrow-radio .input-control label::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.form-auth.form-signin .signup-form .form-signup fieldset .formrow-radio .input-control label::before,
#signin .signup-form .form-signup fieldset .formrow-radio .input-control label::before {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  left: 10px;
}
.form-auth.form-signin .signup-form .form-signup fieldset .formrow-radio .input-control label.is-checked,
#signin .signup-form .form-signup fieldset .formrow-radio .input-control label.is-checked {
  border: 1px solid #005c69;
}
.form-auth.form-signin .signup-form .form-signup fieldset .formrow-radio .input-control label.is-checked::after,
#signin .signup-form .form-signup fieldset .formrow-radio .input-control label.is-checked::after {
  background-color: #005c69;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  left: 13px;
}
.form-auth.form-signin .signup-form .form-signup .input-control .input-group select,
#signin .signup-form .form-signup .input-control .input-group select {
  background: transparent url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  color: #333;
  padding-block: 10px;
  padding-inline: 10px 8px;
  width: 100%;
  max-width: 150px;
  min-width: 150px;
  display: inline-flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .form-auth.form-signin .signup-form .form-signup .input-control .input-group select,
  #signin .signup-form .form-signup .input-control .input-group select {
    min-width: 94px;
    padding-inline: 8px;
  }
}
.form-auth.form-signin .signup-form .form-signup .formrow-checkbox .input-control label,
#signin .signup-form .form-signup .formrow-checkbox .input-control label {
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
  padding: 10px 14px 10px 10px;
  display: flex;
  align-items: center;
}
.form-auth.form-signin .signup-form .form-signup .formrow-checkbox .input-control input,
#signin .signup-form .form-signup .formrow-checkbox .input-control input {
  accent-color: #005c69;
  margin: 0 8px 0 0;
  height: 20px;
  width: 20px;
}
.form-auth.form-signin .signup-form .form-signup .formrow-checkbox .input-control label.is-checked,
#signin .signup-form .form-signup .formrow-checkbox .input-control label.is-checked {
  border: 1px solid #005c69;
}
.form-auth.form-signin .signup-form .formrow-submit,
#signin .signup-form .formrow-submit {
  padding: 0;
  margin-top: 60px;
  padding-inline: 15px;
}
.form-auth.form-signin .signup-form .formrow-submit .note,
#signin .signup-form .formrow-submit .note {
  margin: 0;
}

.form-auth.form-signin div.formrow div.input-control {
  padding: 0;
}
@media all and (max-width : 768px) {
  .form-auth.form-signin .checkbox {
    width: 100%;
  }
}
@media all and (max-width : 768px) {
  .form-auth.form-signin .formrow-checkbox .input-group {
    flex-direction: column;
    width: 100%;
  }
}

/*
  ----------------
  signout
  ----------------
*/
#signout .signout .note {
  margin: 0;
  padding-top: 40px;
  text-align: center;
}

#signout .signout .linkarea__head {
  padding-top: 40px;
  text-align: center;
}

#signout .linkarea__wrapper {
  margin-top: 40px;
}

#signout .signout-message {
  margin-top: 40px;
}

/*
  ---------------
  TOP | サイトバナー スライダー
  ---------------
*/
@media all and (min-width: 769px) {
  .select-top .p-fv__slider {
    max-width: 1280px;
    margin-inline: auto;
    padding-inline: clamp(0px, 2.77777778vw, 40px);
  }
}
.select-top .p-fv__slider:not(.slick-initialized) .p-fv__slide + .p-fv__slide {
  display: none;
}
.select-top .p-fv__slide-link {
  display: block;
}
.select-top .p-fv__slide-link img {
  width: 100%;
}
.select-top .p-fv .slick-slider {
  margin-bottom: 0;
  padding-bottom: 31px;
}
@media all and (min-width: 769px) {
  .select-top .p-fv .slick-slider {
    padding-bottom: 33px;
  }
}
.select-top .p-fv .slick-slide {
  position: relative;
}
.select-top .p-fv .slick-slide:not(.slick-active)::before {
  content: "";
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
}
.select-top .p-fv .slick-list {
  overflow: visible;
}
.select-top .p-fv .slick-dots {
  font-size: 0;
  bottom: 0;
}
.select-top .p-fv .slick-dots li {
  width: auto;
  height: auto;
  padding: 5px 0;
  margin: 0 4px;
}
.select-top .p-fv .slick-dots li button {
  background-color: #d9d9d9;
  width: 40px;
  height: 4px;
  padding: 0;
}
@media all and (min-width: 769px) {
  .select-top .p-fv .slick-dots li button {
    height: 5px;
  }
}
.select-top .p-fv .slick-dots li button:before {
  display: none;
}
.select-top .p-fv .slick-dots li.slick-active button {
  background-color: #00ac5a;
}
.select-top .p-fv .slick-arrow {
  top: calc(50% - 16px);
  transform: translateY(-50%);
}
.select-top .p-fv .slick-prev,
.select-top .p-fv .slick-next {
  width: 50px;
  height: 50px;
  z-index: 2;
}
@media all and (max-width : 768px) {
  .select-top .p-fv .slick-prev,
  .select-top .p-fv .slick-next {
    width: 30px;
    height: 60px;
  }
}
.select-top .p-fv .slick-prev {
  left: 11px;
}
@media all and (max-width : 768px) {
  .select-top .p-fv .slick-prev {
    left: 0;
  }
}
.select-top .p-fv .slick-next {
  right: 11px;
}
@media all and (max-width : 768px) {
  .select-top .p-fv .slick-next {
    right: 0;
  }
}
.select-top .p-fv .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-top .p-fv .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-top .p-fv .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.select-top .p-fv .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-top .p-fv .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-top .p-fv .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

/*
  ---------------
  TOP | おすすめの特集
  ---------------
*/
.select-top .p-edition {
  margin-top: 50px;
}
@media all and (max-width : 768px) {
  .select-top .p-edition {
    margin-top: 40px;
    overflow: hidden;
  }
}
.select-top .p-edition__header {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .select-top .p-edition__header {
    padding-inline: 12px;
  }
}
.select-top .p-edition__header h2 {
  font-size: 30px;
  line-height: 1.43333333;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
@media all and (max-width: 768px) {
  .select-top .p-edition__header h2 {
    font-size: 20px;
    line-height: 1.45;
    text-align: center;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-edition__header h2::before {
    margin-right: 15px;
  }
}
@media all and (min-width: 769px) {
  .select-top .p-edition__header h2::after {
    content: "";
    display: block;
    background-color: #d9d9d9;
    width: 100%;
    height: 1px;
    margin-left: 2.91666667vw;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-edition__header h2::after {
    margin-left: 15px;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-edition__header h2::before, .select-top .p-edition__header h2::after {
    content: "";
    display: block;
    background-color: #d9d9d9;
    width: 100%;
    height: 1px;
  }
}
.select-top .p-edition__bnr {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  margin-top: 20px;
}
@media all and (max-width: 768px) {
  .select-top .p-edition__bnr {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .select-top .p-edition__bnr {
    margin-top: 10px;
  }
}
.select-top .p-edition__bnr .item-tags {
  position: static;
  justify-content: flex-end;
}
@media all and (max-width : 768px) {
  .select-top .p-edition__bnr .item-tags div.item-tag {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}
.select-top .p-edition__bnr-link {
  display: block;
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .select-top .p-edition__bnr-link {
    margin-top: 10px;
  }
}
.select-top .p-edition .l-slider {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  margin-top: 40px;
}
@media all and (max-width: 768px) {
  .select-top .p-edition .l-slider {
    padding-inline: 12px;
  }
}
.select-top .p-edition .l-slider .item-tags {
  top: -30px;
  right: -10px;
}
@media all and (max-width : 768px) {
  .select-top .p-edition .l-slider .item-tags {
    top: -26px;
  }
}
.select-top .p-edition .l-slider__slide-img {
  aspect-ratio: 1/1;
  border: 1px solid #d9d9d9;
  overflow: hidden;
  position: relative;
}
.select-top .p-edition .l-slider__slide-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.select-top .p-edition .l-slider__slide-title {
  color: #005c69;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4375;
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .select-top .p-edition .l-slider__slide-title {
    font-size: 14px;
    line-height: 1.42857143;
    margin-top: 10px;
  }
}
.select-top .p-edition .l-slider__slide-description {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 6px;
}
@media all and (max-width : 768px) {
  .select-top .p-edition .l-slider__slide-description {
    font-size: 13px;
    line-height: 1.46153846;
  }
}
.select-top .p-edition__btn {
  max-width: 250px;
  margin: 50px auto 0;
}
@media all and (max-width : 768px) {
  .select-top .p-edition__btn {
    margin-top: 40px;
  }
}
.select-top .p-edition__btn-link {
  background-color: #00ac5a;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  width: 100%;
  min-height: 50px;
  padding-inline: 19px;
  display: flex;
  align-items: center;
}
.select-top .p-edition__btn-link span {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.46666667;
  text-align: center;
  width: 100%;
  padding-right: 35px;
  position: relative;
}
@media all and (max-width : 768px) {
  .select-top .p-edition__btn-link span {
    font-size: 16px;
    line-height: 1.4375;
  }
}
.select-top .p-edition__btn-link span::after {
  content: "";
  background: transparent url("/f/resources/images/select/common/icon_arrow.svg") center/contain no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.select-top .p-edition .l-slider:not(.slick-initialized) {
  display: none;
}
.select-top .p-edition .slick-list {
  margin-inline: -15px;
  overflow: visible;
}
@media all and (max-width : 768px) {
  .select-top .p-edition .slick-list {
    margin-inline: -5px;
  }
}
.select-top .p-edition .slick-track {
  display: flex;
}
.select-top .p-edition .slick-slide {
  margin-inline: 15px;
}
@media all and (max-width : 768px) {
  .select-top .p-edition .slick-slide {
    margin-inline: 5px;
  }
}
.select-top .p-edition .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.select-top .p-edition .slick-prev,
.select-top .p-edition .slick-next {
  width: 50px;
  height: 50px;
}
@media all and (max-width : 768px) {
  .select-top .p-edition .slick-prev,
  .select-top .p-edition .slick-next {
    width: 30px;
    height: 60px;
  }
}
.select-top .p-edition .slick-prev {
  left: 0;
}
.select-top .p-edition .slick-next {
  right: 0;
}
.select-top .p-edition .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-top .p-edition .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-top .p-edition .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.select-top .p-edition .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-top .p-edition .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-top .p-edition .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
@media all and (min-width: 769px) {
  .select-top .p-edition .slick-prev.slick-disabled:before,
  .select-top .p-edition .slick-next.slick-disabled:before {
    opacity: 0;
  }
}

/*
  ---------------
  select TOP | おすすめの商品
  ---------------
*/
.select-top .p-recommend {
  margin-top: 50px;
}
@media all and (max-width : 768px) {
  .select-top .p-recommend {
    margin-top: 40px;
    overflow: hidden;
  }
}
.select-top .p-recommend .display-name {
  font-size: 30px;
  line-height: 1.43333333;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
@media all and (max-width: 768px) {
  .select-top .p-recommend .display-name {
    font-size: 20px;
    line-height: 1.45;
    text-align: center;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-recommend .display-name::before {
    margin-right: 15px;
  }
}
@media all and (min-width: 769px) {
  .select-top .p-recommend .display-name::after {
    content: "";
    display: block;
    background-color: #d9d9d9;
    width: 100%;
    height: 1px;
    margin-left: 2.91666667vw;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-recommend .display-name::after {
    margin-left: 15px;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-recommend .display-name::before, .select-top .p-recommend .display-name::after {
    content: "";
    display: block;
    background-color: #d9d9d9;
    width: 100%;
    height: 1px;
  }
}
.select-top .p-recommend .panel-body {
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  .select-top .p-recommend .panel-body {
    margin-top: 20px;
  }
}
.select-top .p-recommend .item.article img,
.select-top .p-recommend .item.article .no-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*
  ---------------
  TOP | 刊行シリーズから探す
  ---------------
*/
.select-top .p-publication {
  margin-top: 60px;
}
@media all and (max-width : 768px) {
  .select-top .p-publication {
    margin-top: 50px;
  }
}

.select-top .p-publication .p-publication__inner {
  background-color: #f6f6f6;
  padding-block: 50px;
}

.select-top .p-publication .p-publication__header {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .select-top .p-publication .p-publication__header {
    padding-inline: 12px;
  }
}
.select-top .p-publication .p-publication__header h2 {
  font-size: 30px;
  line-height: 1.43333333;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
@media all and (max-width: 768px) {
  .select-top .p-publication .p-publication__header h2 {
    font-size: 20px;
    line-height: 1.45;
    text-align: center;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-publication .p-publication__header h2::before {
    margin-right: 15px;
  }
}
@media all and (min-width: 769px) {
  .select-top .p-publication .p-publication__header h2::after {
    content: "";
    display: block;
    background-color: #d9d9d9;
    width: 100%;
    height: 1px;
    margin-left: 2.91666667vw;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-publication .p-publication__header h2::after {
    margin-left: 15px;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-publication .p-publication__header h2::before, .select-top .p-publication .p-publication__header h2::after {
    content: "";
    display: block;
    background-color: #d9d9d9;
    width: 100%;
    height: 1px;
  }
}

.p-publication .l-slider {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  margin-top: 40px;
}
@media all and (max-width: 768px) {
  .p-publication .l-slider {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .p-publication .l-slider {
    margin-top: 20px;
  }
}

.p-publication .l-slider__slide-link {
  aspect-ratio: 385/136;
  border: 1px solid #d9d9d9;
  overflow: hidden;
  position: relative;
}
.p-publication .l-slider__slide-link img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.select-top .p-publication .p-publication__btn {
  max-width: 250px;
  margin: 50px auto 0;
}
@media all and (max-width : 768px) {
  .select-top .p-publication .p-publication__btn {
    margin-top: 40px;
  }
}

.select-top .p-publication .p-publication__btn-link {
  background-color: #00ac5a;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  width: 100%;
  min-height: 50px;
  padding-inline: 19px;
  display: flex;
  align-items: center;
}

.select-top .p-publication .p-publication__btn-link span {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.46666667;
  text-align: center;
  width: 100%;
  padding-right: 35px;
  position: relative;
}
@media all and (max-width : 768px) {
  .select-top .p-publication .p-publication__btn-link span {
    font-size: 16px;
    line-height: 1.4375;
  }
}

.select-top .p-publication .p-publication__btn-link span::after {
  content: "";
  background: transparent url("/f/resources/images/select/common/icon_arrow.svg") center/contain no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.select-top .p-publication .l-slider:not(.slick-initialized) {
  display: none;
}

.select-top .p-publication .slick-list {
  margin-inline: -10px;
}

.select-top .p-publication .slick-track {
  display: flex;
}

.select-top .p-publication .slick-slide {
  margin-inline: 10px;
}

.select-top .p-publication .slick-slide > div {
  font-size: 0;
  display: flex;
  gap: 20px;
}
@media all and (max-width : 768px) {
  .select-top .p-publication .slick-slide > div {
    gap: 16px;
  }
}

.select-top .p-publication .slick-slide > div + div {
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .select-top .p-publication .slick-slide > div + div {
    margin-top: 16px;
  }
}

.select-top .p-publication .slick-slide > div > li > a {
  display: inline-block;
  width: 100%;
}

.select-top .p-publication .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}

.select-top .p-publication .slick-prev,
.select-top .p-publication .slick-next {
  width: 50px;
  height: 50px;
}
@media all and (max-width : 768px) {
  .select-top .p-publication .slick-prev,
  .select-top .p-publication .slick-next {
    width: 30px;
    height: 60px;
  }
}

.select-top .p-publication .slick-prev {
  left: 0;
}

.select-top .p-publication .slick-next {
  right: 0;
}

.select-top .p-publication .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-top .p-publication .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-top .p-publication .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

.select-top .p-publication .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-top .p-publication .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-top .p-publication .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

@media all and (min-width: 769px) {
  .select-top .p-publication .slick-prev.slick-disabled:before,
  .select-top .p-publication .slick-next.slick-disabled:before {
    opacity: 0;
  }
}

/*
  ---------------
  TOP | お楽しみいただけそうな商品
  ---------------
*/
.select-top .p-pleasure {
  margin-top: 50px;
}
@media all and (max-width : 768px) {
  .select-top .p-pleasure {
    margin-top: 40px;
    overflow: hidden;
  }
}

.select-top .p-pleasure__header {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .select-top .p-pleasure__header {
    padding-inline: 12px;
  }
}

.select-top .p-pleasure h2 {
  font-size: 30px;
  line-height: 1.43333333;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
@media all and (max-width: 768px) {
  .select-top .p-pleasure h2 {
    font-size: 20px;
    line-height: 1.45;
    text-align: center;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-pleasure h2::before {
    margin-right: 15px;
  }
}
@media all and (min-width: 769px) {
  .select-top .p-pleasure h2::after {
    content: "";
    display: block;
    background-color: #d9d9d9;
    width: 100%;
    height: 1px;
    margin-left: 2.91666667vw;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-pleasure h2::after {
    margin-left: 15px;
  }
}
@media all and (max-width: 768px) {
  .select-top .p-pleasure h2::before, .select-top .p-pleasure h2::after {
    content: "";
    display: block;
    background-color: #d9d9d9;
    width: 100%;
    height: 1px;
  }
}

.select-top .p-pleasure .l-slider {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  margin-top: 40px;
}
@media all and (max-width: 768px) {
  .select-top .p-pleasure .l-slider {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .select-top .p-pleasure .l-slider {
    margin-top: 28px;
  }
}

.select-top .p-pleasure .l-slider .item-tags {
  top: 4px;
  right: 0;
}

.select-top .p-pleasure .l-slider__slide-link {
  aspect-ratio: 270/98;
  border: 1px solid #d9d9d9;
  display: block;
  overflow: visible;
  position: relative;
}

.select-top .p-pleasure .l-slider__slide-link img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.select-top .p-pleasure .l-slider:not(.slick-initialized) {
  display: none;
}

.select-top .p-pleasure .slick-list {
  margin-inline: -10px;
  overflow: hidden;
}

.select-top .p-pleasure .slick-track {
  display: flex;
}

.select-top .p-pleasure .slick-slide {
  margin-inline: 10px;
}

.select-top .p-pleasure .slick-slide > div {
  font-size: 0;
  display: flex;
  gap: 20px;
}
@media all and (max-width : 768px) {
  .select-top .p-pleasure .slick-slide > div {
    gap: 16px;
  }
}

.select-top .p-pleasure .slick-slide > div + div {
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .select-top .p-pleasure .slick-slide > div + div {
    margin-top: 16px;
  }
}

.select-top .p-pleasure .slick-slide > div > li > a {
  display: inline-block;
  width: 100%;
}

.select-top .p-pleasure .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}

.select-top .p-pleasure .slick-prev,
.select-top .p-pleasure .slick-next {
  width: 50px;
  height: 50px;
}
@media all and (max-width : 768px) {
  .select-top .p-pleasure .slick-prev,
  .select-top .p-pleasure .slick-next {
    width: 30px;
    height: 60px;
  }
}

.select-top .p-pleasure .slick-prev {
  left: 0;
}

.select-top .p-pleasure .slick-next {
  right: 0;
}

.select-top .p-pleasure .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-top .p-pleasure .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-top .p-pleasure .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

.select-top .p-pleasure .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-top .p-pleasure .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-top .p-pleasure .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

/*
  ---------------
  セレクトTOP | 最近チェックした商品
  ---------------
*/
.select-top .p-history {
  margin-top: 60px;
}
@media all and (max-width : 768px) {
  .select-top .p-history {
    margin-top: 50px;
    overflow: hidden;
  }
}
.select-top .p-history__inner {
  background-color: #f6f6f6;
  padding-block: 50px 60px;
}
@media all and (max-width : 768px) {
  .select-top .p-history__inner {
    padding-block: 50px;
  }
}
.select-top .p-history .sc-display {
  margin-top: 0;
}
.select-top .p-history .sc-display .display-name {
  font-size: 20px;
  line-height: 1.45;
}
@media all and (max-width : 768px) {
  .select-top .p-history .sc-display .display-name {
    text-align: center;
  }
}
@media all and (min-width: 769px) {
  .select-top .p-history .sc-display .item-display {
    position: relative;
  }
}
.select-top .p-history .sc-display .panel-body::after {
  display: none;
}
@media all and (min-width: 769px) {
  .select-top .p-history .sc-display .item-display::after {
    content: "";
    display: inline-block;
    background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%);
    width: 181px;
    height: 100%;
    margin-right: calc(50% - 50vw);
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media all and (min-width: 769px) {
  .select-top .p-history .sc-display .display-box .item-display .slick-list {
    max-width: 1220px;
    overflow: hidden;
  }
}
.select-top .p-history .sc-display .item.article {
  max-width: 240px;
}
.select-top .p-history .sc-display div.item-thumbnail-img-sm a {
  aspect-ratio: 1/1;
}
.select-top .p-history .sc-display .item-thumbnail-img-sm img {
  position: relative;
  top: unset;
  left: unset;
  transform: none;
}
@media all and (min-width: 769px) {
  .select-top .p-history .slick-prev.slick-disabled:before,
  .select-top .p-history .slick-next.slick-disabled:before {
    opacity: 0.25;
  }
}

/*
  ---------------
  TOP | お知らせ
  ---------------
*/
.select-top .newslist-info {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  /*==========================================
    スタイル追加
  ==========================================*/
  margin-top: 40px;
}
.select-top .newslist-info div.news-entries div.news-entry {
  border-top: 1px solid #d9d9d9;
}
.select-top .newslist-info div.news-entries div.news-entry div.entry-text {
  border: 0;
  padding: 0;
}
.select-top .newslist-info .news-entry:last-child {
  border-bottom: 1px solid #d9d9d9;
}
@media all and (max-width: 800px) {
  .select-top .newslist-info div.news-entries div.header {
    display: flex;
  }
}
@media all and (max-width : 768px) {
  .select-top .newslist-info div.news-entries div.header {
    display: block;
  }
}
.select-top .newslist-info div.news-entries div.header div.meta div.release-date {
  margin-right: 0;
}
.select-top .newslist-info .news-archives-link {
  margin-top: 0;
}
.select-top .newslist-info .news-archives-link a {
  border: 0;
  border-radius: 0;
}
@media all and (max-width : 768px) {
  .select-top .newslist-info {
    margin-top: 50px;
  }
}
.select-top .newslist-info .panel-newslist {
  padding-right: 3.05343511vw;
  padding-left: 3.05343511vw;
  position: relative;
}
@media all and (min-width: 769px) {
  .select-top .newslist-info .panel-newslist {
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 7px;
    padding-right: clamp(0px, 2.77777778vw, 40px);
    padding-left: clamp(0px, 2.77777778vw, 40px);
  }
}
.select-top .newslist-info .news-name {
  color: #1d1d1b;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.44;
}
@media all and (max-width : 768px) {
  .select-top .newslist-info .news-name {
    text-align: center;
  }
}
@media all and (min-width: 769px) {
  .select-top .newslist-info .news-name {
    font-size: 30px;
    line-height: 1.43333333;
  }
}
.select-top .newslist-info div.panel-heading + div.panel-body {
  margin-block: 30px 0;
}
.select-top .newslist-info .entry-text {
  width: 100%;
}
.select-top .newslist-info .release-date {
  color: #747474;
  font-size: 13px;
  font-weight: 500;
}
@media all and (min-width: 769px) {
  .select-top .newslist-info .release-date {
    font-size: 15px;
  }
}
.select-top .newslist-info .header {
  width: 100%;
}
@media all and (max-width : 768px) {
  .select-top .newslist-info .header {
    padding-top: 14px;
  }
}
@media all and (min-width: 769px) {
  .select-top .newslist-info .header {
    padding: 0 6px;
    display: flex;
    align-items: center;
  }
}
.select-top .newslist-info .headline {
  width: 100%;
}
@media all and (min-width: 769px) {
  .select-top .newslist-info .headline {
    margin-left: 40px;
  }
}
.select-top .newslist-info .headline a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857143;
  padding: 3px 0 18px;
}
@media all and (min-width: 769px) {
  .select-top .newslist-info .headline a {
    font-size: 15px;
    line-height: 1.46666667;
    padding: 23px 0;
  }
}
.select-top .newslist-info .headline a .article {
  color: #494949;
  display: block;
  font-weight: 400;
  line-height: 1.4479;
  margin-top: 10px;
}
.select-top .newslist-info .headline a:hover {
  color: #00828c;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
@media all and (max-width : 768px) {
  .select-top .newslist-info .news-archives-link {
    margin-top: 17px;
  }
}
@media all and (min-width: 769px) {
  .select-top .newslist-info .news-archives-link {
    position: absolute;
    top: 0;
    right: 40px;
  }
}
.select-top .newslist-info .news-archives-link a {
  background-color: #f6f6f6;
  border-radius: 30px;
  color: #00ac5a;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33333333;
  min-width: 192px;
  min-height: 50px;
  padding: 0 48px 0 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.select-top .newslist-info .news-archives-link a::after {
  background: transparent url("/f/resources/images/select/common/icon_arrow_bg_green_circle.svg") center/contain no-repeat;
}
.select-top .newslist-info .news-archives-link a::after {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

/*
  ---------------
  セレクトショップ 商品詳細 | メインスライダー
  ---------------
*/
.select-product .p-product__block {
  margin-top: 50px;
}
@media all and (min-width: 1025px) {
  .select-product .p-product__block {
    display: flex;
    justify-content: center;
    gap: min(5.71428571vw, 80px);
  }
}
@media all and (max-width : 768px) {
  .select-product .p-product__block {
    margin-top: 20px;
  }
}
.select-product .p-product__display {
  min-width: 0;
  flex: 0 0 530px;
}
.select-product .p-product__display-slider {
  position: relative;
}
@media all and (min-width: 1025px) {
  .select-product .p-product__display-slider {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
@media all and (max-width: 1024px) and (min-width: 769px) {
  .select-product .p-product__display-slider {
    max-width: 446px;
    margin-inline: auto;
  }
}
.select-product .p-product__display-slides {
  min-width: 0;
  margin-bottom: 0;
}
@media all and (min-width: 769px) {
  .select-product .p-product__display-slides {
    max-width: 446px;
  }
}
@media all and (min-width: 1025px) {
  .select-product .p-product__display-slides {
    flex-shrink: 0;
  }
}
.select-product .p-product__display-slide {
  border: 1px solid #d9d9d9;
  width: 100%;
  height: 100%;
}
@media all and (min-width: 769px) {
  .select-product .p-product__display-slide {
    max-width: 446px;
  }
}
.select-product .p-product__display-slide img, .select-product .p-product__display-slide div.no-img {
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.select-product .p-product__display-slide div.no-img {
  background-color: #eee;
  box-shadow: 0 2px 4px rgba(127, 127, 127, 0.3);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-product .p-product__display .slick-slider {
  touch-action: auto;
}
.select-product .p-product__display .slick-slider .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}
@media all and (min-width: 769px) {
  .select-product .p-product__display .slick-slider .slick-prev,
  .select-product .p-product__display .slick-slider .slick-next {
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-product .p-product__display .slick-slider .slick-prev,
  .select-product .p-product__display .slick-slider .slick-next {
    width: 30px;
    height: 60px;
  }
}
.select-product .p-product__display .slick-slider .slick-prev {
  left: -25px;
}
@media all and (max-width : 768px) {
  .select-product .p-product__display .slick-slider .slick-prev {
    left: -12px;
  }
}
.select-product .p-product__display .slick-slider .slick-next {
  right: -25px;
}
@media all and (max-width : 768px) {
  .select-product .p-product__display .slick-slider .slick-next {
    right: -12px;
  }
}
.select-product .p-product__display .slick-slider .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-product .p-product__display .slick-slider .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-product .p-product__display .slick-slider .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.select-product .p-product__display .slick-slider .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-product .p-product__display .slick-slider .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-product .p-product__display .slick-slider .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.select-product .p-product__display .p-product__display-dots {
  min-width: 0;
}
@media all and (min-width: 1025px) {
  .select-product .p-product__display .p-product__display-dots {
    flex-shrink: 0;
    flex: 0 0 76px;
    height: 446px;
    overflow-y: auto;
  }
}
@media (max-width: 1024px) {
  .select-product .p-product__display .p-product__display-dots {
    margin-top: 15px;
  }
}
.select-product .p-product__display .p-product__display-dots ul {
  position: static;
}
@media all and (min-width: 1025px) {
  .select-product .p-product__display .p-product__display-dots ul {
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
@media all and (max-width: 1024px) {
  .select-product .p-product__display .p-product__display-dots ul {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }
}
@media all and (max-width : 768px) {
  .select-product .p-product__display .p-product__display-dots ul {
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
}
.select-product .p-product__display .p-product__display-dots li {
  border: 1px solid #d9d9d9;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
}
.select-product .p-product__display .p-product__display-dots li:not(.slick-active) {
  opacity: 0.5;
}
.select-product .p-product__display .p-product__display-dots img {
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
}

/*
  ---------------
  セレクトショップ 商品詳細 | 商品タイトル〜購入ボタンエリア
  ---------------
*/
@media all and (min-width: 769px) {
  .select-product .p-product__block {
    padding-right: min(2.77777778%, 40px);
    padding-left: min(2.77777778%, 40px);
  }
}

.select-product .p-product__detail {
  min-width: 0;
}
@media all and (max-width: 1024px) {
  .select-product .p-product__detail {
    margin-top: 40px;
  }
}

.select-product .p-product__detail-title {
  display: inline;
  font-weight: 700;
  margin-top: 6px;
}
@media all and (max-width : 768px) {
  .select-product .p-product__detail-title {
    margin-top: 4px;
  }
}

div.item-tags {
  display: inline-flex;
  vertical-align: 2px;
}

div.item-tags div.item-tag {
  border-radius: 0;
  padding: 0 4px 2px;
}

.select-product .p-product__detail-title-text {
  display: inline;
}

.select-product .p-product__detail-summary-wrap {
  margin-top: 30px;
}

.select-product .p-product__detail-summary-title {
  font-size: 18px;
}

.select-product .p-product__detail-summary {
  display: block;
  font-size: 14px;
  margin-top: 12px;
}

.select-product .p-product__detail-number {
  color: #747474;
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 7px;
}
@media all and (max-width: 1024px) {
  .select-product .p-product__detail-number {
    margin-top: 60px;
  }
}

@media all and (min-width: 1025px) {
  .select-product .p-product__price-amount {
    margin-top: 28px;
  }
}

.select-product .p-product__price-amount-price {
  font-size: 35px;
  font-weight: 700;
  line-height: 1.45714286;
}

.select-product .p-product__price-amount-tax {
  font-weight: 500;
}

.select-product .p-product__price-base {
  color: #747474;
  font-size: 14px;
  line-height: 1.42857143;
}

.select-product .p-product__price-original {
  color: #747474;
  font-size: 14px;
  line-height: 1.42857143;
  text-decoration: line-through;
  margin-top: 5px;
}

.select-product .p-product__deliveryFee {
  margin-top: 12px;
}

.select-product .p-product__deliveryFee-header {
  font-size: 14px;
}

.select-product .p-product__deliveryFee-body {
  font-weight: 700;
  font-size: 18px;
}

.select-product .p-product__deliveryFee-whole {
  font-size: 14px;
  font-weight: 500;
}

.select-product .p-product__point {
  background-color: #00ac5a;
  border-radius: 2px;
  font-size: 12px;
  line-height: 1.41666667;
  margin-top: 14px;
  padding: 1px;
  display: inline-flex;
}

.select-product .p-product__point-header {
  border-radius: 2px;
  color: #fff;
  padding: 0 4px 1px 6px;
}

.select-product .p-product__point-body {
  background-color: #fff;
  color: #00ac5a;
  padding: 0 6px 1px;
}

.select-product .p-product__detail-purchase {
  margin-top: 50px;
}
@media all and (max-width : 768px) {
  .select-product .p-product__detail-purchase {
    margin-top: 30px;
  }
}
.select-product .p-product__detail-purchase .item-qty-item-wrap {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media all and (max-width : 768px) {
  .select-product .p-product__detail-purchase .item-qty-item-wrap {
    margin-top: 20px;
  }
}
.select-product .p-product__detail-purchase .item-qty-note {
  color: #c10000;
  margin-inline-end: 20px;
}
@media all and (max-width : 768px) {
  .select-product .p-product__detail-purchase .item-qty-note {
    font-size: 14px;
    line-height: 1.42857143;
    margin-inline-end: 17px;
  }
}
.select-product .p-product__detail-purchase .item-qty-item {
  display: flex;
  align-items: center;
}
.select-product .p-product__detail-purchase .item-qty-label {
  font-size: 14px;
  line-height: 1.42857143;
  margin-inline-end: 15px;
}
@media all and (max-width : 768px) {
  .select-product .p-product__detail-purchase .item-qty-label {
    margin-inline-end: 8px;
  }
}
.select-product .p-product__detail-purchase .qty.item-qty {
  appearance: none;
  background: #F6F6F6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  border: 0;
  border-radius: 2px;
  color: #1D1D1B;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  line-height: normal;
  padding-inline: 10px 29px;
  display: inline-flex;
  align-items: center;
}
@media all and (max-width : 768px) {
  .select-product .p-product__detail-purchase .qty.item-qty {
    font-size: 16px;
    height: 35px;
    padding-inline-end: 13px;
    width: 84px;
  }
}
@media all and (min-width: 769px) {
  .select-product .p-product__detail-purchase .qty.item-qty {
    width: 150px;
  }
}
.select-product .p-product__detail-purchase .btn-group-purchase {
  font-size: 0;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
@media all and (max-width : 768px) {
  .select-product .p-product__detail-purchase .btn-group-purchase {
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
    gap: 2.54452926vw;
  }
}
@media all and (min-width: 769px) {
  .select-product .p-product__detail-purchase .btn-group-purchase {
    gap: clamp(0px, 0.69444444vw, 20px);
  }
}
.select-product .p-product__detail-purchase button.btn.add-item-to-cart,
.select-product .p-product__detail-purchase button.btn.checkout-item {
  margin-left: 0;
  border: 0;
  border-radius: 2px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.33333333;
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .select-product .p-product__detail-purchase button.btn.add-item-to-cart,
  .select-product .p-product__detail-purchase button.btn.checkout-item {
    width: 100%;
  }
}
.select-product .p-product__detail-purchase .add-item-to-cart {
  background: #00ac5a;
  min-width: 178px;
  height: 50px;
  padding: 0 19px 0 47px;
}
.select-product .p-product__detail-purchase .add-item-to-cart::before {
  content: "";
  background: url("/f/resources/images/packtoy/list/icon_cart.svg") center/contain no-repeat;
  display: inline-block;
  width: 17px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: auto;
  left: calc(50% - 66px);
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .select-product .p-product__detail-purchase .add-item-to-cart::before {
    left: calc(50% - 67px);
  }
}
.select-product .p-product__detail-purchase .checkout-item {
  background: #ff9c08;
  min-width: 178px;
  height: 50px;
  padding: 0 47px 0 19px;
}
@media all and (max-width : 768px) {
  .select-product .p-product__detail-purchase .checkout-item {
    min-width: auto;
  }
}
.select-product .p-product__detail-purchase .checkout-item::before {
  content: "";
  border-radius: 100%;
  background: url("/f/resources/images/common/icon_arrow_right_bg_white.svg") center/contain no-repeat;
  display: inline-block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  right: 13px;
  left: auto;
  transform: translateY(-50%);
}

.select-product .p-product__info {
  border-top: 1px solid #d9d9d9;
  padding-top: 20px;
  margin-top: 30px;
}
@media all and (max-width : 768px) {
  .select-product .p-product__info {
    padding-top: 15px;
    margin-top: 20px;
  }
}

.select-product .p-product__info-delivery,
.select-product .p-product__info-period {
  display: flex;
  align-items: center;
}

.select-product .p-product__info-delivery-header,
.select-product .p-product__info-period-header {
  background-color: #747474;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  line-height: 1.42857143;
  width: 80px;
  min-height: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-product .p-product__info-delivery-body,
.select-product .p-product__info-period-body {
  margin-left: 10px;
}

.select-product .p-product__info-delivery-body-text,
.select-product .p-product__info-period-body-text {
  font-size: 16px;
  line-height: 1.4375;
}

.select-product .p-product__info-delivery-body-note,
.select-product .p-product__info-period-body-note {
  font-size: 12px;
  line-height: 1.41666667;
}

.select-product .p-product__info-period {
  margin-top: 6px;
}

.select-product .p-product__summary {
  margin-top: 30px;
}

.select-product .p-product__summary-title {
  font-size: 18px;
  line-height: 1.45833333;
}

.select-product .p-product__summary-description {
  font-size: 14px;
  line-height: 1.42857143;
  margin-top: 6px;
}
.select-product .p-product__summary-description + .select-product .p-product__summary-description {
  margin-top: 10px;
}
.select-product .p-product__summary-description r {
  color: red;
}
.select-product .p-product__summary-description rb {
  color: red;
  font-weight: bold;
}
.select-product .p-product__summary-description b {
  font-weight: bold;
}

/*
  ---------------
  セレクトショップ 商品詳細 | 関連商品
  ---------------
*/
.select-product .p-relation {
  margin-top: 45px;
}
@media all and (max-width : 768px) {
  .select-product .p-relation {
    margin-top: 40px;
  }
}
@media all and (min-width: 1025px) {
  .select-product .p-relation__inner {
    max-width: 562px;
  }
}
.select-product .p-relation__list {
  margin-top: 10px;
}
.select-product .p-relation__item {
  margin-inline: 5px;
}
@media all and (min-width: 1025px) {
  .select-product .p-relation__item {
    max-width: 133px;
  }
}
.select-product .p-relation__item-link {
  width: 100%;
}
.select-product .p-relation__item-img {
  border: 1px solid #d9d9d9;
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
}
.select-product .p-relation__item-img img {
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
}
.select-product .p-relation__item-title {
  font-size: 13px;
  line-height: 1.46153846;
  margin-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.select-product .p-relation__item-price {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.46153846;
  margin-top: 2px;
}
.select-product .p-relation .slick-list {
  margin-inline: -5px;
}
.select-product .p-relation .slick-track {
  margin: 0;
  display: flex;
}
.select-product .p-relation .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.select-product .p-relation .slick-prev,
.select-product .p-relation .slick-next {
  width: 50px;
  height: 50px;
}
@media all and (max-width : 768px) {
  .select-product .p-relation .slick-prev,
  .select-product .p-relation .slick-next {
    width: 30px;
    height: 60px;
  }
}
.select-product .p-relation .slick-prev {
  left: -25px;
}
@media all and (max-width : 768px) {
  .select-product .p-relation .slick-prev {
    left: -12px;
  }
}
.select-product .p-relation .slick-next {
  right: -25px;
}
@media all and (max-width : 768px) {
  .select-product .p-relation .slick-next {
    right: -12px;
  }
}
.select-product .p-relation .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-product .p-relation .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-product .p-relation .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.select-product .p-relation .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-product .p-relation .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-product .p-relation .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.select-product .p-relation .slick-prev.slick-disabled:before,
.select-product .p-relation .slick-next.slick-disabled:before {
  opacity: 0;
}

/*
  ---------------
  セレクトショップ 商品詳細 | 商品バリエーション
  ---------------
*/
.select-product .p-variation {
  margin-top: 50px;
}
.select-product .p-variation__links {
  margin-top: 9px;
  padding-bottom: 10px;
  overflow-x: auto;
}
.select-product .p-variation__links-list {
  width: 470px;
  white-space: nowrap;
  display: flex;
  gap: 10px;
}
.select-product .p-variation__links-item {
  width: 150px;
}
.select-product .p-variation__radio-input input {
  appearance: none;
  margin: 0;
  position: absolute;
}
.select-product .p-variation__links-item-link {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #f6f6f6;
  cursor: pointer;
  display: block;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}
.select-product .p-variation__links-item-link:hover {
  border: 1px solid #00ac5a;
}
.select-product .p-variation__links-item-link > div {
  border: 1px solid #d9d9d9;
}
.select-product .p-variation__links-item-link img, .select-product .p-variation__links-item-link div.no-img {
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.select-product .p-variation__links-item-link div.no-img {
  background-color: #eee;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-product .p-variation__links-item-link > p {
  font-size: 14px;
  line-height: 1.57142857;
  text-align: center;
  margin-top: 5px;
  white-space: wrap;
}
.select-product .p-variation__checkbox-wrap {
  margin-top: 10px;
  padding-bottom: 10px;
  overflow-x: auto;
}
.select-product .p-variation__checkbox {
  width: 470px;
  white-space: nowrap;
  display: flex;
  gap: 8px;
}
.select-product .p-variation__checkbox input {
  appearance: none;
  margin: 0;
  position: absolute;
}
.select-product .p-variation__checkbox label {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #f6f6f6;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding-block: 9px;
  padding-inline: 38px 14px;
  position: relative;
}
.select-product .p-variation__checkbox label::before, .select-product .p-variation__checkbox label::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.select-product .p-variation__checkbox label::before {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  left: 10px;
}
.select-product .p-variation__checkbox input:checked + label {
  border: 1px solid #00ac5a;
}
.select-product .p-variation__checkbox input:checked + label::after {
  background-color: #00ac5a;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  left: 13px;
}

/*
  ---------------
  セレクトショップ 商品詳細 | 他のお客様が閲覧している商品、最近チェックした商品 共通
  ---------------
*/
.p-other, .p-history {
  background-color: #f6f6f6;
  width: 100vw;
  margin: 50px calc(50% - 50vw) 0;
  padding-block: 50px 60px;
}
@media all and (max-width : 768px) {
  .p-other, .p-history {
    overflow: hidden;
  }
}
.p-other .sc-display, .p-history .sc-display {
  margin-top: 0;
}
.p-other .sc-display .display-name, .p-history .sc-display .display-name {
  font-size: 20px;
  line-height: 1.45;
}
@media all and (max-width : 768px) {
  .p-other .sc-display .display-name, .p-history .sc-display .display-name {
    text-align: center;
  }
}
.p-other .sc-display .panel-body, .p-history .sc-display .panel-body {
  position: relative;
}
@media all and (min-width: 769px) {
  .p-other .sc-display .panel-body::after, .p-history .sc-display .panel-body::after {
    content: "";
    display: inline-block;
    background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%);
    width: 181px;
    height: 100%;
    margin-right: calc(50% - 50vw);
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media all and (min-width: 769px) {
  .p-other .sc-display .display-box .item-display .slick-list, .p-history .sc-display .display-box .item-display .slick-list {
    max-width: 88.8888889%;
  }
}
@media all and (min-width: 769px) {
  .p-other .sc-display .slick-list, .p-history .sc-display .slick-list {
    overflow: visible;
  }
}
.p-other .sc-display div.item-thumbnail-img-sm a, .p-history .sc-display div.item-thumbnail-img-sm a {
  aspect-ratio: 1/1;
}
@media all and (min-width: 769px) {
  .p-other .slick-prev.slick-disabled:before,
  .p-other .slick-next.slick-disabled:before, .p-history .slick-prev.slick-disabled:before,
  .p-history .slick-next.slick-disabled:before {
    opacity: 0;
  }
}

/*
  ---------------
  セレクトショップ 商品詳細 | 最近チェックした商品
  ---------------
*/
.select-product .p-history {
  margin-top: 50px;
}
.select-product .p-history .l-slider {
  margin-top: 18px;
}
@media all and (min-width: 769px) {
  .select-product .p-history .l-slider {
    position: relative;
  }
}
@media all and (max-width : 768px) {
  .select-product .p-history .l-slider {
    margin-top: 20px;
  }
}
@media all and (min-width: 769px) {
  .select-product .p-history .l-slider::after {
    content: "";
    display: inline-block;
    background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%);
    width: 181px;
    height: 100%;
    margin-right: calc(50% - 50vw);
    position: absolute;
    top: 0;
    right: 0;
  }
}
.select-product .p-history .l-slider__slide-img img {
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.select-product .p-history .l-slider__slide-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4375;
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .select-product .p-history .l-slider__slide-title {
    font-size: 13px;
    line-height: 1.42857143;
    margin-top: 10px;
  }
}
.select-product .p-history .l-slider__slide-price {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857143;
  margin-top: 6px;
}
@media all and (max-width : 768px) {
  .select-product .p-history .l-slider__slide-price {
    font-size: 13px;
    line-height: 1.46153846;
  }
}
@media all and (min-width: 769px) {
  .select-product .p-history .slick-list {
    overflow: visible;
  }
}
.select-product .p-history .slick-track {
  display: flex;
}
.select-product .p-history .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.select-product .p-history .slick-prev,
.select-product .p-history .slick-next {
  width: 50px;
  height: 50px;
}
@media all and (max-width : 768px) {
  .select-product .p-history .slick-prev,
  .select-product .p-history .slick-next {
    width: 30px;
    height: 60px;
  }
}
.select-product .p-history .slick-prev {
  left: 0;
}
.select-product .p-history .slick-next {
  right: 0;
}
.select-product .p-history .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-product .p-history .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-product .p-history .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.select-product .p-history .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .select-product .p-history .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .select-product .p-history .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.select-product .p-history .slick-prev.slick-disabled:before,
.select-product .p-history .slick-next.slick-disabled:before {
  opacity: 0;
}

/*
  ---------------
  セレクトショップ 商品詳細 | 購入者向け特典
  ---------------
*/
.p-special-offers {
  margin-top: 60px;
}
@media all and (max-width : 768px) {
  .p-special-offers {
    margin-top: 40px;
  }
}
.p-special-offers__inner {
  text-align: center;
}
.p-special-offers__inner > div {
  text-align: center;
}
.p-special-offers__header {
  background-color: #f6f6f6;
  color: #b2b2b2;
  font-size: 20px;
  height: 150px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .p-special-offers__header {
    margin-top: 10px;
  }
}
.p-special-offers__body {
  background-color: #f6f6f6;
  color: #b2b2b2;
  height: 258px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.p-special-offers__body h3 {
  font-size: 34px;
  padding: 0;
}
.p-special-offers__body p {
  font-size: 14px;
}
.p-special-offers__footer {
  max-width: 583px;
  margin: 40px auto 0;
}
@media all and (max-width : 768px) {
  .p-special-offers__footer {
    max-width: 370px;
    margin-top: 20px;
  }
}
.p-special-offers__footer a {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  font-size: 16px;
  line-height: 1.4375;
  text-align: left;
  width: 100%;
  min-height: 70px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media all and (max-width : 768px) {
  .p-special-offers__footer a {
    font-size: 14px;
    line-height: 1.42857143;
    min-height: 50px;
  }
}
.p-special-offers__footer span {
  display: inline-block;
  padding-left: 26px;
  position: relative;
}
.p-special-offers__footer span::before {
  content: "";
  background: transparent url("/f/resources/images/common/icon_community.svg") center/cover no-repeat;
  display: inline-block;
  width: 22px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

/*
  ---------------
  総合TOP | 共通
  ---------------
*/
nav#nav {
  background-color: var(--ers-deago-col1);
  color: var(--ers-deago-col2);
}

nav#nav a {
  color: inherit;
}

nav#nav ul.nav-menu {
  display: flex;
  justify-content: center;
  max-width: var(--ers-deago-maxwidth);
  margin: 0 auto;
}

nav#nav ul.nav-menu > li {
  padding: 1rem;
}

/* TOP */
.site-banners-TOP_ROTATION_01 .banners ul {
  width: 100%;
}

.site-banners-TOP_ROTATION_01 a {
  display: block;
  height: 600px;
}

.site-banners-TOP_ROTATION_01 img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.site-banners-TOP_SUB_01 .banners ul {
  display: flex;
}

.site-banners-TOP_SUB_01 .banners ul > li {
  padding: 16px;
  width: 50%;
}

.site-banners-TOP_SUB_01 .site-banner {
  border: 1px solid #333;
  border-radius: 10px;
  height: 550px;
  padding: 12px;
  position: relative;
}

.site-banners-TOP_SUB_01 .uri-to-img {
  width: 100%;
}

.site-banners-TOP_ICON_LIST {
  margin-top: 40px;
}

.site-banners-TOP_ICON_LIST .banners ul {
  display: flex;
  justify-content: space-between;
}

.site-banners-TOP_ICON_LIST .site-banner {
  text-align: center;
}

.site-banners-TOP_ICON_LIST .caption {
  margin-top: 8px;
}

.site-banners-TOP_ICON_LIST .uri-to-img {
  width: 80px;
}

.recommend_pw {
  margin-top: 40px;
}

.recommend_pw__wrap .item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.recommend_pw__wrap .item-list > li.item-list-each {
  border: 1px solid #333;
  border-radius: 4px;
  margin-top: 20px;
  width: 48%;
}

.recommend_pw__wrap ul.item-list li.item-list-each:last-child {
  border: 1px solid #333;
  padding-bottom: 1em;
}

.recommend_pw__wrap .item-list > li:nth-child(n+5) {
  display: none;
}

.search_title {
  margin-top: 40px;
  text-align: center;
}

.search_title__button {
  background-color: #333;
  border-radius: 8px;
  display: block;
  margin: 0 auto;
  padding: 24px 18px;
  width: 400px;
}

.search_title__inner {
  color: #fff;
  font-size: 22px;
}

.top-newslist div.panel-body {
  margin-bottom: 0;
}

nav#nav {
  background-color: transparent;
}

.top .newslist.newslist-info .panel-body {
  margin-bottom: 0;
}

/*
  ---------------
  deago-top-news | TOP Topics一覧
  ---------------
*/
#deago-top-news {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  /*==========================================
    スタイル追加
  ==========================================*/
  margin-top: 20px;
}
#deago-top-news .top_news__entry_list {
  display: block;
}
#deago-top-news .top_news__entry {
  min-width: auto;
  margin-left: 0;
}
#deago-top-news .top_news__entry_cate {
  background: transparent;
  border: 0;
}
#deago-top-news .top_news__entry_thumbnail {
  margin: 0;
}
#deago-top-news .top_news__entry_list {
  overflow: visible;
}
@media all and (max-width : 768px) {
  #deago-top-news .top_news__entry_list {
    padding-right: 1.52671756vw;
    padding-left: 1.52671756vw;
  }
}
#deago-top-news .top_news__entry {
  background-color: #fff;
  padding: 1.78117048vw 1.78117048vw 10px;
  display: flex;
  flex-direction: column;
}
@media all and (min-width: 769px) {
  #deago-top-news .top_news__entry {
    padding: 12px 11px 16px;
  }
}
#deago-top-news .top_news__entry_list:not(.slick-slider) {
  height: 354px;
  text-align: center;
}
#deago-top-news .top_news__entry_list:not(.slick-slider)::after {
  content: "";
  display: inline-flex;
  border: solid 3px;
  border-color: #333 #f6f6f6 #f6f6f6;
  border-radius: 50%;
  height: 30px;
  opacity: 0.7;
  width: 30px;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-top: 130px;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
#deago-top-news .top_news__entry_list:not(.slick-slider) .top_news__entry {
  display: none;
}
#deago-top-news .top_news__entry_upper {
  margin-top: 12px;
  order: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3px;
}
#deago-top-news .top_news__entry_cate {
  border-radius: 40px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  max-width: 170px;
  min-height: 24px;
  padding: 5px 13px 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #747474;
  order: 2;
}
@media (max-width: 768px) {
  #deago-top-news .top_news__entry_cate {
    font-size: 10px;
    max-width: 105px;
    min-height: 20px;
    padding: 4px 8px;
  }
}
#deago-top-news .top_news__entry_cate.tp-news {
  background-color: rgb(0, 104, 183);
}
#deago-top-news .top_news__entry_cate.tp-campaign {
  background-color: rgb(230, 0, 18);
}
#deago-top-news .top_news__entry_cate.tp-report {
  background-color: rgb(0, 167, 60);
}
#deago-top-news .top_news__entry_cate.tp-newitem {
  background-color: rgb(250, 190, 0);
}
#deago-top-news .top_news__entry_cate.tp-others01 {
  background-color: rgb(165, 0, 130);
}
#deago-top-news .top_news__entry_date {
  color: #b2b2b2;
  font-size: 12px;
  order: 1;
}
@media all and (min-width: 769px) {
  #deago-top-news .top_news__entry_date {
    font-size: 15px;
  }
}
#deago-top-news .top_news__entry_thumbnail {
  aspect-ratio: 1/1;
  order: 1;
}
#deago-top-news .top_news__entry_link {
  margin-top: 2px;
  flex-grow: 1;
  order: 3;
}
@media all and (min-width: 769px) {
  #deago-top-news .top_news__entry_link {
    margin-top: 6px;
  }
}
#deago-top-news .top_news__entry_title {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  height: 40px;
}
@media all and (max-width : 768px) {
  #deago-top-news .top_news__entry_title {
    font-size: 12px;
    -webkit-line-clamp: 3;
    height: 36px;
  }
}
#deago-top-news .slick-track {
  margin: 0;
}
#deago-top-news .slick-slide {
  height: auto;
  margin: 0 1.52671756vw;
}
@media all and (min-width: 769px) {
  #deago-top-news .slick-slide {
    margin: 0 0.86805556vw;
  }
}
#deago-top-news .slick-slide > div {
  display: flex;
}
@media all and (min-width: 769px) {
  #deago-top-news .slick-slide > div {
    flex-wrap: nowrap;
  }
}
@media all and (max-width : 768px) {
  #deago-top-news .slick-slide > div {
    gap: 2.54452926vw;
  }
}
@media all and (max-width : 768px) {
  #deago-top-news .slick-slide > div + div {
    margin-top: 2.54452926vw;
  }
}
#deago-top-news .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}
#deago-top-news .slick-prev {
  left: -26px;
}
@media all and (max-width : 768px) {
  #deago-top-news .slick-prev {
    left: 0;
  }
}
#deago-top-news .slick-next {
  right: -26px;
}
@media all and (max-width : 768px) {
  #deago-top-news .slick-next {
    right: 0;
  }
}
#deago-top-news .slick-prev,
#deago-top-news .slick-next {
  width: 16px;
  height: 32px;
}
@media all and (max-width : 768px) {
  #deago-top-news .slick-prev,
  #deago-top-news .slick-next {
    width: 30px;
    height: 60px;
  }
}
#deago-top-news .slick-prev::before {
  background: transparent url("/f/resources/images/top/icon_prev_white.svg") center/contain no-repeat;
}
@media all and (max-width : 768px) {
  #deago-top-news .slick-prev::before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
#deago-top-news .slick-next::before {
  background: transparent url("/f/resources/images/top/icon_next_white.svg") center/contain no-repeat;
}
@media all and (max-width : 768px) {
  #deago-top-news .slick-next::before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
#deago-top-news .slick-prev:before,
#deago-top-news .slick-next:before {
  content: "";
  display: inline-block;
  width: inherit;
  height: inherit;
}
#deago-top-news .top_news__to-list {
  display: none;
}

@media all and (max-width : 768px) {
  .p-topics__inner {
    padding-inline: 0;
  }
}

/*
  ---------------
  TOP | サイトバナー スライダー
  ---------------
*/
.site-banners-TOP_ROTATION_01 a,
.site-banners-TOP_ROTATION_SP_01 a {
  display: block;
  height: auto;
}
.site-banners-TOP_ROTATION_01 .heading,
.site-banners-TOP_ROTATION_SP_01 .heading {
  display: none;
}
.site-banners-TOP_ROTATION_01 .banners ul,
.site-banners-TOP_ROTATION_SP_01 .banners ul {
  width: 100%;
}
.site-banners-TOP_ROTATION_01 .banners ul,
.site-banners-TOP_ROTATION_SP_01 .banners ul {
  display: flex;
  justify-content: center;
}
@media all and (min-width: 769px) {
  .site-banners-TOP_ROTATION_01 .banners ul,
  .site-banners-TOP_ROTATION_SP_01 .banners ul {
    display: block;
  }
}
.site-banners-TOP_ROTATION_01 .slick-slider,
.site-banners-TOP_ROTATION_SP_01 .slick-slider {
  margin-bottom: 0;
}
.site-banners-TOP_ROTATION_01 .slick-slider .site-banner,
.site-banners-TOP_ROTATION_SP_01 .slick-slider .site-banner {
  width: 100%;
}
.site-banners-TOP_ROTATION_01 .slick-slide,
.site-banners-TOP_ROTATION_SP_01 .slick-slide {
  position: relative;
}
.site-banners-TOP_ROTATION_01 .slick-slide:not(.slick-active)::before,
.site-banners-TOP_ROTATION_SP_01 .slick-slide:not(.slick-active)::before {
  content: "";
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
}
.site-banners-TOP_ROTATION_01 .slick-dots,
.site-banners-TOP_ROTATION_SP_01 .slick-dots {
  font-size: 0;
  bottom: 0;
}
.site-banners-TOP_ROTATION_01 .slick-dots li,
.site-banners-TOP_ROTATION_SP_01 .slick-dots li {
  width: auto;
  height: auto;
  padding: 5px 0;
  margin: 0 4px;
}
.site-banners-TOP_ROTATION_01 .slick-dots li button:before,
.site-banners-TOP_ROTATION_SP_01 .slick-dots li button:before {
  display: none;
}
.site-banners-TOP_ROTATION_01 .slick-dots li.slick-active button,
.site-banners-TOP_ROTATION_SP_01 .slick-dots li.slick-active button {
  background-color: #00828c;
}
.site-banners-TOP_ROTATION_01 .slick-dots li button,
.site-banners-TOP_ROTATION_SP_01 .slick-dots li button {
  background-color: #1d1d1b;
  width: 40px;
  padding: 0;
}
.site-banners-TOP_ROTATION_01 .slick-arrow,
.site-banners-TOP_ROTATION_SP_01 .slick-arrow {
  top: calc(50% - 16px);
  transform: translateY(-50%);
}
.site-banners-TOP_ROTATION_01 .slick-prev,
.site-banners-TOP_ROTATION_01 .slick-next,
.site-banners-TOP_ROTATION_SP_01 .slick-prev,
.site-banners-TOP_ROTATION_SP_01 .slick-next {
  width: 50px;
  height: 50px;
  z-index: 2;
}
@media all and (max-width : 768px) {
  .site-banners-TOP_ROTATION_01 .slick-prev,
  .site-banners-TOP_ROTATION_01 .slick-next,
  .site-banners-TOP_ROTATION_SP_01 .slick-prev,
  .site-banners-TOP_ROTATION_SP_01 .slick-next {
    width: 30px;
    height: 60px;
  }
}
.site-banners-TOP_ROTATION_01 .slick-prev,
.site-banners-TOP_ROTATION_SP_01 .slick-prev {
  left: -25px;
}
@media all and (max-width : 768px) {
  .site-banners-TOP_ROTATION_01 .slick-prev,
  .site-banners-TOP_ROTATION_SP_01 .slick-prev {
    left: 0;
  }
}
.site-banners-TOP_ROTATION_01 .slick-next,
.site-banners-TOP_ROTATION_SP_01 .slick-next {
  right: -25px;
}
@media all and (max-width : 768px) {
  .site-banners-TOP_ROTATION_01 .slick-next,
  .site-banners-TOP_ROTATION_SP_01 .slick-next {
    right: 0;
  }
}
.site-banners-TOP_ROTATION_01 .slick-prev:before,
.site-banners-TOP_ROTATION_SP_01 .slick-prev:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .site-banners-TOP_ROTATION_01 .slick-prev:before,
  .site-banners-TOP_ROTATION_SP_01 .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .site-banners-TOP_ROTATION_01 .slick-prev:before,
  .site-banners-TOP_ROTATION_SP_01 .slick-prev:before {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}
.site-banners-TOP_ROTATION_01 .slick-next:before,
.site-banners-TOP_ROTATION_SP_01 .slick-next:before {
  content: "";
  display: inline-block;
}
@media all and (min-width: 769px) {
  .site-banners-TOP_ROTATION_01 .slick-next:before,
  .site-banners-TOP_ROTATION_SP_01 .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next.svg") center/contain no-repeat;
    width: 50px;
    height: 50px;
  }
}
@media all and (max-width : 768px) {
  .site-banners-TOP_ROTATION_01 .slick-next:before,
  .site-banners-TOP_ROTATION_SP_01 .slick-next:before {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center/contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

@media all and (max-width : 768px) {
  .site-banners-TOP_ROTATION_01 {
    display: none;
  }
}
.site-banners-TOP_ROTATION_01 img {
  max-height: 520px;
}
.site-banners-TOP_ROTATION_01 .banners {
  max-width: 1280px;
  margin-inline: auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
}
.site-banners-TOP_ROTATION_01 div.banners > ul:not(.slick-initialized) {
  padding-bottom: 33px;
}
.site-banners-TOP_ROTATION_01 .slick-slider {
  padding-bottom: 33px;
}
.site-banners-TOP_ROTATION_01 .slick-list {
  overflow: visible;
}
.site-banners-TOP_ROTATION_01 .slick-dots li button {
  height: 5px;
}

@media all and (min-width: 769px) {
  .site-banners-TOP_ROTATION_SP_01 {
    display: none;
  }
}
.site-banners-TOP_ROTATION_SP_01 img {
  width: 100%;
  height: 106.666667vw;
}
.site-banners-TOP_ROTATION_SP_01 div.banners:not(:has(.slick-initialized)) {
  padding: 0 3.56234097%;
}
.site-banners-TOP_ROTATION_SP_01 div.banners > ul:not(.slick-initialized) {
  padding-bottom: 31px;
}
.site-banners-TOP_ROTATION_SP_01 .slick-slider {
  padding-bottom: 31px;
}
.site-banners-TOP_ROTATION_SP_01 .slick-dots li button {
  height: 4px;
}

/*
  ---------------
  TOP | サイトバナー スライダー下
  ---------------
*/
article#container {
  max-width: 100%;
  margin: 0;
  overflow: visible;
}

.site-banners-TOP_SUB_01 {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  /*==========================================
    スタイル追加
  ==========================================*/
  margin-top: 18px;
}
.site-banners-TOP_SUB_01 .site-banner {
  border: 0;
  border-radius: 0;
  height: auto;
  padding: 0;
}
.site-banners-TOP_SUB_01 .banners ul > li {
  padding: 0;
  width: auto;
}
.site-banners-TOP_SUB_01 a {
  display: block;
}
@media all and (min-width: 769px) {
  .site-banners-TOP_SUB_01 {
    margin-top: 27px;
  }
}
.site-banners-TOP_SUB_01 .heading {
  display: none;
}
.site-banners-TOP_SUB_01 .banners {
  padding-right: 3.05343511vw;
  padding-left: 3.05343511vw;
}
@media all and (min-width: 769px) {
  .site-banners-TOP_SUB_01 .banners {
    max-width: 1280px;
    margin: 0 auto;
    padding-right: clamp(0px, 2.77777778vw, 40px);
    padding-left: clamp(0px, 2.77777778vw, 40px);
  }
}
.site-banners-TOP_SUB_01 .banners ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5.08905852vw;
}
@media all and (min-width: 769px) {
  .site-banners-TOP_SUB_01 .banners ul {
    gap: 5.27777778vw;
  }
}

.site-banners-TOP_SUB_PC_01 {
  padding-top: 24px;
}
.site-banners-TOP_SUB_PC_01 .banners {
  max-width: 1280px;
  margin: 0 auto;
  padding-right: clamp(0px, 2.77777778vw, 40px);
  padding-left: clamp(0px, 2.77777778vw, 40px);
}
.site-banners-TOP_SUB_PC_01 .banners ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}
.site-banners-TOP_SUB_PC_01 .site-banner .item-tags {
  top: 6px;
  right: -8px;
}

/*
  ---------------
  TOP | サイトバナー アイコンリスト
  ---------------
*/
.site-banners-TOP_ICON_LIST {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  /*==========================================
    スタイル追加
  ==========================================*/
}
.site-banners-TOP_ICON_LIST .banners ul {
  display: block;
}
.site-banners-TOP_ICON_LIST .banners > ul:not(.slick-initialized) {
  display: none;
}
.site-banners-TOP_ICON_LIST .uri-to-img {
  width: 100%;
}
.site-banners-TOP_ICON_LIST .slick-arrow {
  top: calc(50% - 24px);
  transform: translateY(-50%);
}
.site-banners-TOP_ICON_LIST .heading {
  display: none;
}
.site-banners-TOP_ICON_LIST .banners {
  padding-right: 3.05343511vw;
  padding-left: 3.05343511vw;
}
@media all and (min-width: 769px) {
  .site-banners-TOP_ICON_LIST .banners {
    max-width: 1280px;
    margin: 0 auto;
    padding-right: clamp(0px, 2.77777778vw, 40px);
    padding-left: clamp(0px, 2.77777778vw, 40px);
  }
}
.site-banners-TOP_ICON_LIST .banners ul {
  padding-inline: max(5.59796438vw, 18px);
}
@media all and (min-width: 769px) {
  .site-banners-TOP_ICON_LIST .banners ul {
    padding-inline: 40px;
  }
}
.site-banners-TOP_ICON_LIST .banners a {
  display: block;
}
.site-banners-TOP_ICON_LIST .caption {
  font-size: 16px;
  height: 38px;
  line-height: 1.41666667;
  overflow: hidden;
  text-align: center;
  margin-top: 10px;
  word-break: break-all;
}
@media all and (max-width : 768px) {
  .site-banners-TOP_ICON_LIST .caption {
    font-size: 10px;
    height: 40px;
    margin-top: 7px;
  }
}
.site-banners-TOP_ICON_LIST .banners ul.slick-slider {
  display: flex;
}
.site-banners-TOP_ICON_LIST .item-tags {
  top: 0;
  right: -15px;
}
.site-banners-TOP_ICON_LIST .slick-prev {
  left: 0;
}
.site-banners-TOP_ICON_LIST .slick-next {
  right: 0;
}
.site-banners-TOP_ICON_LIST .slick-prev, .site-banners-TOP_ICON_LIST .slick-next {
  width: 14px;
  height: 23px;
}
@media all and (max-width : 768px) {
  .site-banners-TOP_ICON_LIST .slick-prev, .site-banners-TOP_ICON_LIST .slick-next {
    width: 11px;
    height: 19px;
  }
}
.site-banners-TOP_ICON_LIST .slick-prev::before {
  background: transparent url("/f/resources/images/top/icon_prev_green.svg") center/contain no-repeat;
}
.site-banners-TOP_ICON_LIST .slick-next::before {
  background: transparent url("/f/resources/images/top/icon_next_green.svg") center/contain no-repeat;
}
.site-banners-TOP_ICON_LIST .slick-prev:before, .site-banners-TOP_ICON_LIST .slick-next:before {
  content: "";
  display: inline-block;
  width: inherit;
  height: inherit;
}
.site-banners-TOP_ICON_LIST .slick-list {
  margin: 0 -1.90839695vw;
}
@media all and (min-width: 769px) {
  .site-banners-TOP_ICON_LIST .slick-list {
    margin: 0 -1.38888889vw;
  }
}
.site-banners-TOP_ICON_LIST .slick-slide {
  margin: 0 1.90839695vw;
}
@media all and (min-width: 769px) {
  .site-banners-TOP_ICON_LIST .slick-slide {
    margin: 0 1.38888889vw;
    padding: 0 20px;
  }
}

/*
  ---------------
  News | news機能
  ---------------
*/
.top_news {
  margin-top: 40px;
}

.top_news__navi {
  display: flex;
}

.top_news__category {
  background-color: #f1f1f1;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  margin-left: 4px;
  padding: 15px 0;
  text-align: center;
  width: 100px;
}

.top_news__category.active {
  background-color: #333;
  border: 1px solid #333;
  color: #fff;
}

.top_news__category:hover {
  opacity: 0.8;
}

.top_news__entry_list {
  display: flex;
  overflow-x: scroll;
}

.top_news__entry {
  margin-left: 20px;
}

.top_news__entry_upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.top_news__entry_thumbnail {
  margin-top: 4px;
  object-fit: cover;
  width: 100%;
}

.top_news__entry_cate {
  background: #F1F1F1;
  border: 1px solid #C4C4C4;
  border-radius: 16px;
  font-size: 11px;
  padding: 4px 8px;
}

.top_news__entry_date {
  font-size: 14px;
}

.top_news__entry_title {
  font-size: 14px;
  margin-top: 0;
  text-decoration: none;
}

.top_news__to-list {
  text-align: center;
}

a.top_news__to-list-button {
  background-color: #F1F1F1;
  border: 1px solid #c3c3c3;
  border-radius: 25px;
  color: #333;
  display: inline-block;
  min-width: 300px;
  padding: 12px;
  text-decoration: none;
  width: auto;
}

.newslist-info {
  margin-top: 40px;
}

.newslist-info .news-name {
  text-align: left;
}

.newslist-info .news-entry:last-child {
  border-bottom: 1px solid #ccc;
}

.newslist-info {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  /*==========================================
    スタイル追加
  ==========================================*/
  margin-top: 40px;
}
.newslist-info div.news-entries div.news-entry {
  border-top: 1px solid #d9d9d9;
}
.newslist-info div.news-entries div.news-entry div.entry-text {
  border: 0;
  padding: 0;
}
.newslist-info .news-entry:last-child {
  border-bottom: 1px solid #d9d9d9;
}
@media all and (max-width: 800px) {
  .newslist-info div.news-entries div.header {
    display: flex;
  }
}
@media all and (max-width : 768px) {
  .newslist-info div.news-entries div.header {
    display: block;
  }
}
.newslist-info div.news-entries div.header div.meta div.release-date {
  margin-right: 0;
}
.newslist-info .news-archives-link {
  margin-top: 0;
}
.newslist-info .news-archives-link a {
  border: 0;
  border-radius: 0;
}
@media all and (max-width : 768px) {
  .newslist-info {
    margin-top: 50px;
  }
}
.newslist-info .panel-newslist {
  padding-right: 3.05343511vw;
  padding-left: 3.05343511vw;
  position: relative;
}
@media all and (min-width: 769px) {
  .newslist-info .panel-newslist {
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 7px;
    padding-right: clamp(0px, 2.77777778vw, 40px);
    padding-left: clamp(0px, 2.77777778vw, 40px);
  }
}
.newslist-info .news-name {
  color: #005c69;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.44;
}
@media all and (max-width : 768px) {
  .newslist-info .news-name {
    text-align: center;
  }
}
@media all and (min-width: 769px) {
  .newslist-info .news-name {
    font-size: 30px;
    line-height: 1.43333333;
  }
}
.newslist-info div.panel-heading + div.panel-body {
  margin-top: 30px;
}
.newslist-info .entry-text {
  width: 100%;
}
.newslist-info .release-date {
  color: #747474;
  font-size: 13px;
  font-weight: 500;
}
@media all and (min-width: 769px) {
  .newslist-info .release-date {
    font-size: 15px;
  }
}
.newslist-info .header {
  width: 100%;
}
@media all and (max-width : 768px) {
  .newslist-info .header {
    padding-top: 14px;
  }
}
@media all and (min-width: 769px) {
  .newslist-info .header {
    padding: 0 6px;
    display: flex;
    align-items: center;
  }
}
.newslist-info .headline {
  width: 100%;
}
@media all and (min-width: 769px) {
  .newslist-info .headline {
    margin-left: 40px;
  }
}
.newslist-info .headline a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857143;
  padding: 3px 0 18px;
}
@media all and (min-width: 769px) {
  .newslist-info .headline a {
    font-size: 15px;
    line-height: 1.46666667;
    padding: 23px 0;
  }
}
.newslist-info .headline a .article {
  color: #494949;
  display: block;
  font-weight: 400;
  line-height: 1.4479;
  margin-top: 10px;
}
.newslist-info .headline a:hover {
  color: #00828c;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
@media all and (max-width : 768px) {
  .newslist-info .news-archives-link {
    margin-top: 17px;
    display: flex;
    justify-content: center;
  }
}
@media all and (min-width: 769px) {
  .newslist-info .news-archives-link {
    position: absolute;
    top: 0;
    right: 40px;
  }
}
.newslist-info .news-archives-link a {
  background-color: #f6f6f6;
  color: #005c69;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33333333;
  min-width: 192px;
  min-height: 50px;
  padding: 0 48px 0 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.newslist-info .news-archives-link a::after {
  background: #005c69 url("/f/resources/images/top/icon_arrow_right_bg_green.svg") center/contain no-repeat;
  border-radius: 2px;
}
.newslist-info .news-archives-link a::after {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

/*
  ---------------
  top-newslist-announce | 重要なお知らせ header上
  ---------------
*/
.news-entry-announce {
  color: #C10000;
  padding: 2px 0;
}

.news-entry-announce .entry-text {
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.news-entry-announce .text {
  text-decoration: none;
  color: #C10000;
}

#header .top-newslist-announce {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  /*==========================================
    スタイル追加
  ==========================================*/
  background-color: #ffebeb;
}
#header .top-newslist-announce div.panel-heading + div.panel-body {
  margin-top: 0;
}
#header .top-newslist-announce div.news-entries div.news-entry {
  border: 0;
}
#header .top-newslist-announce div.news-entries div.news-entry div.entry-text {
  padding: 0;
  max-width: 100%;
}
#header .top-newslist-announce div.news-entries div.header {
  line-height: 1;
}
#header .top-newslist-announce div.news-entries div.header div.news-type span {
  background-color: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
}
#header .top-newslist-announce .news-entry-announce {
  padding: 0;
}
@media screen and (max-width: 800px) {
  #header .top-newslist-announce div.news-entries div.header {
    display: flex;
  }
}
#header .top-newslist-announce .panel.panel-news {
  gap: 0;
  padding-top: 9px;
  padding-bottom: 8px;
  padding-right: 3.05343511vw;
  padding-left: 3.05343511vw;
}
@media all and (min-width: 769px) {
  #header .top-newslist-announce .panel.panel-news {
    max-width: 1280px;
    margin: 0 auto;
    padding-right: clamp(0px, 2.77777778vw, 40px);
    padding-left: clamp(0px, 2.77777778vw, 40px);
  }
}
#header .top-newslist-announce .panel-body {
  position: relative;
}
#header .top-newslist-announce .announce-accordion {
  border: 1px solid #c10000;
  border-radius: 4px;
  color: #c10000;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  padding: 4px 8px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  #header .top-newslist-announce .announce-accordion {
    padding: 4px;
  }
}
#header .top-newslist-announce .announce-accordion img {
  display: inline-block;
  height: 14px;
  margin-left: 2px;
}
#header .top-newslist-announce.is-open .announce-accordion img {
  transform: rotate(180deg);
}
#header .top-newslist-announce .news-entry:nth-child(n+2) {
  display: none;
}
#header .top-newslist-announce.is-open .news-entry:nth-child(n+2) {
  display: block;
}
@media all and (max-width : 768px) {
  #header .top-newslist-announce.is-open .news-entry:nth-child(n+2) {
    margin-top: 4px;
  }
}
#header .top-newslist-announce .meta {
  min-width: 0;
  display: flex;
  align-items: center;
}
#header .top-newslist-announce .release-date {
  color: #c10000;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.41666667;
  min-width: 0;
  margin-left: clamp(0px, 2.79898219vw, 11px);
  order: 2;
}
@media all and (min-width: 769px) {
  #header .top-newslist-announce .release-date {
    font-size: 14px;
    line-height: 1.42857143;
    margin-left: 2.36111111vw;
  }
}
#header .top-newslist-announce .news-type-announce {
  min-width: 0;
  flex: 1 0 auto;
  padding-left: 20px;
  order: 1;
  position: relative;
}
@media all and (min-width: 769px) {
  #header .top-newslist-announce .news-type-announce {
    color: #c10000;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4375;
    padding-left: 24px;
  }
}
#header .top-newslist-announce .news-type-announce::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_notice.svg") center/contain no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  #header .top-newslist-announce .news-type-announce span {
    display: none;
  }
}
#header .top-newslist-announce .headline {
  flex: 1;
  min-width: 0;
  padding-right: 65px;
  margin-left: 2.54452926vw;
}
@media all and (max-width : 768px) {
  #header .top-newslist-announce .headline {
    min-width: 0;
    display: flex;
  }
}
@media all and (min-width: 769px) {
  #header .top-newslist-announce .headline {
    margin-left: 1.04166667vw;
  }
}
#header .top-newslist-announce .headline a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
#header .top-newslist-announce .headline .text {
  color: #c10000;
  display: block;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
@media all and (min-width: 769px) {
  #header .top-newslist-announce .headline .text {
    font-size: 14px;
    line-height: 1.42857143;
  }
}

/*
  ---------------
  みんなの投稿 | news機能
  ---------------
*/
.newslist-user-post {
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  .newslist-user-post {
    margin-top: 30px;
  }
}
.newslist-user-post > div {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .newslist-user-post > div {
    padding-inline: 12px;
  }
}
@media all and (min-width: 769px) {
  .newslist-user-post > div {
    position: relative;
  }
}
.newslist-user-post > div > div.panel-heading.panel-heading-text > div > span {
  color: #005c69;
  display: block;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.44;
  text-align: left;
  padding: 0;
}
@media all and (max-width : 768px) {
  .newslist-user-post > div > div.panel-heading.panel-heading-text > div > span {
    text-align: center;
  }
}
@media all and (min-width: 769px) {
  .newslist-user-post > div > div.panel-heading.panel-heading-text > div > span {
    font-size: 30px;
    line-height: 1.43333333;
  }
}
.newslist-user-post div.panel-heading + div.panel-body {
  margin-top: 20px;
}
.newslist-user-post div.news-entries div.news-entry,
.newslist-user-post div.news-entries div.news-entry-with-icon {
  border: 0;
}
.newslist-user-post div.news-entries div.news-entry {
  display: block;
}
.newslist-user-post div.news-entries div.news-entry div.entry-text {
  display: none;
  padding: 0;
}
.newslist-user-post div.news-entries div.news-entry-with-icon {
  padding: 0;
}
.newslist-user-post > div > div.panel-body > div.news-entries {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.03562341vw;
}
@media all and (min-width: 769px) {
  .newslist-user-post > div > div.panel-body > div.news-entries {
    grid-template-columns: repeat(4, 1fr);
    gap: 13px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .newslist-user-post > div > div.panel-body > div.news-entries > div:hover {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .newslist-user-post > div > div.panel-body > div.news-entries > div:hover:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.newslist-user-post > div > div.panel-body > div.news-entries > div:hover::before, .newslist-user-post > div > div.panel-body > div.news-entries > div:hover::after {
  backface-visibility: hidden;
}
.newslist-user-post > div > div.panel-body > div.news-entries > div > div.entry-icon.heading > span {
  display: block;
  max-width: 290px;
  max-height: 290px;
  aspect-ratio: 1/1;
  position: relative;
}
.newslist-user-post > div > div.panel-body > div.news-entries > div > div.entry-icon.heading > span img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media all and (min-width: 769px) {
  .newslist-user-post > div > div.panel-body > div.news-archives-link {
    position: absolute;
    top: 0;
    right: clamp(0px, 2.77777778vw, 40px);
  }
}
@media all and (max-width : 768px) {
  .newslist-user-post > div > div.panel-body > div.news-archives-link {
    text-align: center;
    margin: 17px auto 0;
  }
}
.newslist-user-post > div > div.panel-body > div.news-archives-link > a {
  border-radius: 2px;
  background-color: #f6f6f6;
  color: #005c69;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33333333;
  text-align: center;
  min-height: 50px;
  padding: 0 48px 0 22px;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.newslist-user-post > div > div.panel-body > div.news-archives-link > a::after {
  content: "";
  border-radius: 2px;
  background: #005c69 url("/f/resources/images/top/icon_arrow_right_bg_green.svg") center/contain no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  ご利用ガイド | 共通
  ---------------
*/
.top-internal-banner {
  margin-top: 40px;
}

.top-internal-banner ul {
  display: flex;
  justify-content: center;
}

.top-internal-banner img {
  height: auto;
  width: 360px;
}

.top-internal-banner li + li {
  margin-left: 16px;
}

.guide .section-title {
  border-left: 4px solid #005c69;
  font-size: 30px;
  font-weight: 500;
  padding-left: 20px;
}
@media all and (max-width : 768px) {
  .guide .section-title {
    font-size: 24px;
    padding-left: 16px;
  }
}

.news-archive-faq #outer,
.news-archive-faq_imported #outer,
.newsentries.faq #outer,
.faq_imported #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .news-archive-faq #outer,
  .news-archive-faq_imported #outer,
  .newsentries.faq #outer,
  .faq_imported #outer {
    padding-inline: 12px;
  }
}
.news-archive-faq h1,
.news-archive-faq_imported h1,
.newsentries.faq h1,
.faq_imported h1 {
  font-size: 24px;
  font-weight: 400;
  padding: 0;
}
@media all and (max-width : 768px) {
  .news-archive-faq h1,
  .news-archive-faq_imported h1,
  .newsentries.faq h1,
  .faq_imported h1 {
    padding-right: 154px;
  }
}

/*---------------
  FAQ
---------------*/
.guide-faq .guide-faq__wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 67px;
}
@media all and (max-width: 768px) {
  .guide-faq .guide-faq__wrap {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .guide-faq .guide-faq__wrap {
    padding-top: 50px;
  }
}

.guide-faq .guide-faq__list {
  padding-top: 26px;
}
@media all and (max-width : 768px) {
  .guide-faq .guide-faq__list {
    padding-top: 16px;
  }
}

.newsentries.faq #container .news-entries .news-entry,
.faq_imported #container .news-entries .news-entry,
.guide-faq .guide-faq__item {
  border-top: 1px solid #d9d9d9;
  position: relative;
}

.newsentries.faq #container .news-entries .news-entry:last-child,
.faq_imported #container .news-entries .news-entry:last-child,
.guide-faq .guide-faq__item:last-child {
  border-bottom: 1px solid #d9d9d9;
}

body.news .news-archive-faq .news-entry-header::before,
body.news .news-archive-faq_imported .news-entry-header::before,
.newsentries.faq #container .news-entries .news-entry::before,
.faq_imported #container .news-entries .news-entry::before,
.guide-faq .guide-faq__item::before {
  background: #005c69;
  border-radius: 2px;
  color: #fff;
  content: "Q";
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  display: grid;
  place-items: center;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 18px;
  left: 12px;
}

.guide-faq .guide-faq__item:last-child {
  border-bottom: 1px solid #d9d9d9;
}

.guide-faq .guide-faq__title {
  cursor: pointer;
}

body.news .news-archive-faq .news-entry .news-entry-subject,
body.news .news-archive-faq_imported .news-entry .news-entry-subject,
.newsentries.faq #container .news-entries .news-entry .entry-text span.text,
.faq_imported #container .news-entry .entry-text span.text,
.guide-faq .guide-faq__title {
  color: #005c69;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  padding: 18px 40px 18px 47px;
  position: relative;
  width: 100%;
}

.guide-faq .guide-faq__title::before,
.guide-faq .guide-faq__title::after {
  background: #005c69;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(0);
}

.guide-faq .guide-faq__title::before {
  right: 20px;
  height: 1px;
  width: 15px;
}

.newsentries.faq #container .news-entries .news-entry .entry-text span.text::after,
.faq_imported #container .news-entry .entry-text span.text::after,
.guide-faq .guide-faq__title::after {
  right: 27px;
  transition: transform 0.3s ease;
  height: 15px;
  width: 1px;
}

.is-open .guide-faq__title::after {
  transform: translateY(-50%) rotate(90deg);
}

body.news .news-archive-faq .news-entry-body,
body.news .news-archive-faq_imported .news-entry-body,
.guide-faq .guide-faq__text {
  background: #f6f6f6;
  display: none;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px;
  padding: 15px 12px 15px;
  position: relative;
}

body.news .news-archive-faq .news-entry-body:not(.appendix)::before,
body.news .news-archive-faq_imported .news-entry-body:not(.appendix)::before .guide-faq .guide-faq__text:not(.appendix)::before {
  background: #494949;
  border-radius: 2px;
  color: #fff;
  content: "A";
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  display: grid;
  place-items: center;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 14px;
  left: 12px;
}

body.news .news-archive-faq .news-entry-body a,
body.news .news-archive-faq_imported .news-entry-body a,
.guide-faq .guide-faq__text a {
  color: #005c69;
  text-decoration: underline;
}

.guide-faq .trailer-link {
  padding-block: 50px 0;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .guide-faq .trailer-link {
    padding-block: 30px 0;
  }
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  ご利用ガイドトップ
  ---------------
*/
.guide-nav {
  min-width: 100%;
  width: 100%;
}

.guide-nav .guide-nav__title {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  padding-block: 20px 16px;
}
@media all and (max-width: 768px) {
  .guide-nav .guide-nav__title {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .guide-nav .guide-nav__title {
    font-size: 24px;
    padding-block: 20px;
  }
}

.guide-nav .guide-nav__related {
  padding-bottom: 20px;
}

.guide-nav .guide-nav__related-list {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
@media all and (max-width: 768px) {
  .guide-nav .guide-nav__related-list {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .guide-nav .guide-nav__related-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
  }
}

.guide-nav .guide-nav__related-item {
  min-height: 80px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width : 768px) {
  .guide-nav .guide-nav__related-item {
    min-height: 60px;
  }
}

.guide-nav .guide-nav__related-link {
  background-color: rgba(0, 92, 105, 0.2);
  display: block;
  color: #005c69;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width : 768px) {
  .guide-nav .guide-nav__related-link {
    font-size: 11px;
    padding-inline: 8px;
  }
}

.guide-nav .guide-nav__related-link::after {
  content: "";
  background: transparent url(/f/resources/images/common/icon_chevron_right.svg) center/cover no-repeat;
  display: inline-block;
  height: 20px;
  width: 12px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .guide-nav .guide-nav__related-link::after {
    right: 5px;
    height: 10px;
    width: 6px;
  }
}

.guide-nav .guide-nav__body {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 30px;
}
@media all and (max-width: 768px) {
  .guide-nav .guide-nav__body {
    padding-inline: 12px;
  }
}

.guide-nav .guide-nav__body .section-title {
  border-left: 0;
  padding-left: 0;
}

.guide-nav .guide-nav__body-wrap {
  background: #f6f6f6;
  width: 100vw;
  margin-top: 20px;
  margin-inline: calc(50% - 50vw);
}
@media all and (min-width: 769px) {
  .guide-nav .guide-nav__body-wrap {
    border-top: 1px solid #d9d9d9;
  }
}
@media all and (max-width : 768px) {
  .guide-nav .guide-nav__body-wrap {
    background: #fff;
  }
}

.guide-nav .guide-nav__list {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
}
@media all and (max-width: 768px) {
  .guide-nav .guide-nav__list {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .guide-nav .guide-nav__list {
    max-width: 100%;
    padding-inline: 0;
    flex-wrap: wrap;
    gap: 2px;
  }
}

@media all and (max-width : 768px) {
  .guide-nav .guide-nav__item {
    width: calc(50% - 2px);
  }
}

.guide-nav .guide-nav__link {
  display: inline-block;
  font-size: 14px;
  padding-block: 20px;
  position: relative;
}
@media all and (max-width : 768px) {
  .guide-nav .guide-nav__link {
    background: #f6f6f6;
    padding-block: 15px;
    text-align: center;
    width: 100%;
  }
}

@media (hover: hover) and (pointer: fine) {
  .guide-nav .guide-nav__link:hover {
    color: #005c69;
    font-weight: 700;
    opacity: 1;
  }
  .guide-nav .guide-nav__link:hover::before {
    background: #1d1d1b;
    content: "";
    display: block;
    height: 2px;
    width: 98%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/* FAQシナリオ | FAQ-Sequence */
.scenario-faq__btn-wrap {
  padding: 20px 0;
  text-align: center;
}

.scenario-faq__btn {
  background-color: #1d1d1b;
  border-radius: 4px;
  color: #fff;
  padding: 12px 20px;
}

.newsentries .scenario-sequence {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  padding-top: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.newsentries .sequential-contents {
  background-color: #eee;
  padding: 40px;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.newsentries .subsequence-label {
  display: block;
}

.newsentries .subsequence-label a {
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 4px;
  display: block;
  padding: 8px;
}

.newsentries .subsequence-label li + li {
  margin-left: 0;
}

.newsentries.faq #container #page-wrapper,
.faq_imported #container #page-wrapper {
  padding-top: 26px;
}
.newsentries.faq #container .entry-text .header .meta,
.newsentries.faq #container .entry-text .body,
.faq_imported #container .entry-text .header .meta,
.faq_imported #container .entry-text .body {
  display: none;
}
.newsentries.faq #container .news-entries .news-entry .entry-text,
.faq_imported #container .news-entries .news-entry .entry-text {
  padding: 0;
  width: 100%;
}
.newsentries.faq #container .news-entries .header,
.newsentries.faq #container .news-entries .headline,
.faq_imported #container .news-entries .header,
.faq_imported #container .news-entries .headline {
  width: 100%;
}
.newsentries.faq #container .news-entries .news-entry .entry-text a,
.faq_imported #container .news-entries .news-entry .entry-text a {
  display: inline-block;
  width: 100%;
}

.newsentries.faq #page_aside1,
.faq_imported #page_aside1 {
  position: relative;
}
.newsentries.faq h1,
.faq_imported h1 {
  padding-right: 230px;
}
@media all and (max-width : 768px) {
  .newsentries.faq h1,
  .faq_imported h1 {
    padding-right: 0;
    padding-bottom: 56px;
  }
}
.newsentries.faq .scenario-faq__btn-wrap,
.faq_imported .scenario-faq__btn-wrap {
  background-color: transparent;
  border: none;
  height: 50px;
  padding: 0;
  position: absolute;
  top: 80px;
  right: 0;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .newsentries.faq .scenario-faq__btn-wrap,
  .faq_imported .scenario-faq__btn-wrap {
    max-width: 204px;
    width: 100%;
    top: 120px;
    right: 50%;
    transform: translateX(50%);
  }
}
.newsentries.faq .scenario-faq__btn,
.faq_imported .scenario-faq__btn {
  background: #005c69;
  border: none;
  border-radius: 30px;
  box-shadow: none;
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  padding-right: 22px;
  display: flex;
  align-items: center;
}
@media (hover: hover) and (pointer: fine) {
  .newsentries.faq .scenario-faq__btn,
  .faq_imported .scenario-faq__btn {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .newsentries.faq .scenario-faq__btn:hover,
  .faq_imported .scenario-faq__btn:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.newsentries.faq .scenario-faq__btn::before, .newsentries.faq .scenario-faq__btn::after,
.faq_imported .scenario-faq__btn::before,
.faq_imported .scenario-faq__btn::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .newsentries.faq .scenario-faq__btn,
  .faq_imported .scenario-faq__btn {
    font-size: 14px;
    height: 40px;
    margin-top: 0;
    right: 12px;
  }
}
.newsentries.faq .scenario-faq__btn::before,
.faq_imported .scenario-faq__btn::before {
  background: transparent url(/f/resources/images/common/icon_search.svg) center/contain no-repeat;
  height: 20px;
  content: "";
  margin: 0 7px 0 0;
  width: 20px;
}
@media all and (max-width : 768px) {
  .newsentries.faq .scenario-faq__btn::before,
  .faq_imported .scenario-faq__btn::before {
    margin: 0 6px 0 10px;
  }
}
.newsentries.faq #container .news-entry .entry-text .headline a span.text,
.faq_imported #container .news-entry .entry-text .headline a span.text {
  padding-right: 20px;
}
@media all and (max-width : 768px) {
  .newsentries.faq #container .news-entry .entry-text .headline a span.text,
  .faq_imported #container .news-entry .entry-text .headline a span.text {
    padding-right: 10px;
  }
}

.faq-recommend {
  padding-bottom: 40px;
}
.faq-recommend + .faq-recommend {
  padding-bottom: 80px;
}
.faq-recommend h2.faq-recommend__head {
  font-size: 24px;
  font-weight: 400;
}
.faq-recommend .faq-recommend__body {
  padding-top: 10px;
}
.faq-recommend .faq-recommend__entry {
  border-top: 1px solid #d9d9d9;
  position: relative;
}
.faq-recommend .faq-recommend__entry:last-child {
  border-bottom: 1px solid #d9d9d9;
}
.faq-recommend .faq-recommend__entry::before {
  background: #005c69;
  border-radius: 2px;
  content: "Q";
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  display: grid;
  place-items: center;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 18px;
  left: 12px;
}
.faq-recommend .faq-recommend__link {
  color: #005c69;
  display: block;
  font-size: 16px;
  font-weight: 500;
  padding: 18px 40px 18px 47px;
  width: 100%;
}
@media all and (max-width : 768px) {
  .faq-recommend .faq-recommend__link {
    padding: 12px 10px 12px 47px;
  }
}

.newsentries.faq .scenario-sequence,
.faq_imported.newsentries .scenario-sequence {
  padding-top: 0;
}
.newsentries.faq .sequential-contents,
.faq_imported.newsentries .sequential-contents {
  background: #fff;
  overflow-y: scroll;
  max-height: 85%;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media all and (max-width : 768px) {
  .newsentries.faq .sequential-contents,
  .faq_imported.newsentries .sequential-contents {
    padding: 36px 16px 16px;
    width: 95%;
  }
}
.newsentries.faq .sequential-contents::before,
.faq_imported.newsentries .sequential-contents::before {
  background: url(/f/resources/images/common/icon_close_large.svg) 50%/contain no-repeat;
  cursor: pointer;
  content: "";
  display: block;
  height: 35px;
  width: 35px;
  position: absolute;
  top: 20px;
  right: 20px;
}
@media all and (max-width : 768px) {
  .newsentries.faq .sequential-contents::before,
  .faq_imported.newsentries .sequential-contents::before {
    height: 25px;
    width: 25px;
    top: 10px;
    right: 10px;
  }
}
.newsentries.faq .scenario-sequence .active-contents,
.faq_imported.newsentries .scenario-sequence .active-contents {
  font-size: 30px;
  font-weight: 500;
}
@media all and (max-width : 768px) {
  .newsentries.faq .scenario-sequence .active-contents,
  .faq_imported.newsentries .scenario-sequence .active-contents {
    font-size: 20px;
  }
}
.newsentries.faq .subsequence-label,
.faq_imported.newsentries .subsequence-label {
  padding-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 136px;
  gap: 30px;
}
@media all and (max-width : 768px) {
  .newsentries.faq .subsequence-label,
  .faq_imported.newsentries .subsequence-label {
    padding-top: 14px;
    grid-auto-rows: 100px;
    gap: 20px;
  }
}
.newsentries.faq .subsequence-label li,
.faq_imported.newsentries .subsequence-label li {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 100%;
  margin: 0;
}
.newsentries.faq .subsequence-label a,
.faq_imported.newsentries .subsequence-label a {
  background: #f6f6f6;
  border: none;
  color: #005c69;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  opacity: 1;
  padding: 0;
  text-align: center;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
@media (hover: hover) and (pointer: fine) {
  .newsentries.faq .subsequence-label a,
  .faq_imported.newsentries .subsequence-label a {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .newsentries.faq .subsequence-label a:hover,
  .faq_imported.newsentries .subsequence-label a:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.newsentries.faq .subsequence-label a::before, .newsentries.faq .subsequence-label a::after,
.faq_imported.newsentries .subsequence-label a::before,
.faq_imported.newsentries .subsequence-label a::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .newsentries.faq .subsequence-label a,
  .faq_imported.newsentries .subsequence-label a {
    font-size: 16px;
  }
}

body.news .news-archive-faq,
body.news .news-archive-faq_imported {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  body.news .news-archive-faq,
  body.news .news-archive-faq_imported {
    padding-inline: 12px;
  }
}
body.news .news-archive-faq h1,
body.news .news-archive-faq_imported h1 {
  padding-right: 0;
}
body.news .news-archive-faq #page-wrapper,
body.news .news-archive-faq_imported #page-wrapper {
  position: relative;
}
body.news .news-archive-faq .news-entry,
body.news .news-archive-faq_imported .news-entry {
  padding-top: 35px;
}
@media all and (max-width : 768px) {
  body.news .news-archive-faq .news-entry,
  body.news .news-archive-faq_imported .news-entry {
    padding-top: 25px;
  }
}
body.news .news-archive-faq .news-entry-header,
body.news .news-archive-faq_imported .news-entry-header {
  border-top: 1px solid #d9d9d9;
  position: relative;
}
body.news .news-archive-faq .release-date,
body.news .news-archive-faq_imported .release-date {
  display: none;
}
body.news .news-archive-faq .news-entry-body,
body.news .news-archive-faq_imported .news-entry-body {
  display: block;
  margin-bottom: 0;
  padding: 15px 15px 60px;
}
body.news .news-archive-faq .reactions.news,
body.news .news-archive-faq_imported .reactions.news {
  position: relative;
  position: absolute;
  bottom: 115px;
  right: 19px;
}
@media all and (max-width : 768px) {
  body.news .news-archive-faq .reactions.news,
  body.news .news-archive-faq_imported .reactions.news {
    bottom: 95px;
  }
}
body.news .news-archive-faq .reactions.news li,
body.news .news-archive-faq_imported .reactions.news li {
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #005c69;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 9px;
}
body.news .news-archive-faq .trailer-link,
body.news .news-archive-faq_imported .trailer-link {
  padding: 50px 0 0;
  display: flex;
  justify-content: center;
}
@media all and (max-width : 768px) {
  body.news .news-archive-faq .trailer-link,
  body.news .news-archive-faq_imported .trailer-link {
    padding-top: 30px;
  }
}

body.news .news-archive-faqforinternal .newslist__search {
  display: none;
}

@media all and (max-width : 768px) {
  .newsentries.faqforinternal h1,
  .newsentries.faq h1 {
    padding-bottom: 0;
  }
}
.newsentries.faqforinternal .newslist__header,
.newsentries.faq .newslist__header {
  position: relative;
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
@media all and (max-width : 768px) {
  .newsentries.faqforinternal .newslist__header,
  .newsentries.faq .newslist__header {
    padding-block: 0 20px;
    flex-direction: column;
    align-items: flex-end;
  }
}
.newsentries.faqforinternal .newslist__search,
.newsentries.faq .newslist__search {
  max-width: calc(100% - 224px);
  width: 100%;
}
@media all and (max-width : 768px) {
  .newsentries.faqforinternal .newslist__search,
  .newsentries.faq .newslist__search {
    max-width: 100%;
  }
}
.newsentries.faqforinternal .newslist__search form,
.newsentries.faq .newslist__search form {
  display: flex;
  align-items: center;
}
.newsentries.faqforinternal .newslist__search-input-wrap,
.newsentries.faq .newslist__search-input-wrap {
  width: 100%;
}
.newsentries.faqforinternal .newslist__search-input-wrap input,
.newsentries.faq .newslist__search-input-wrap input {
  border: 1px solid #d9d9d9;
  border-right: 0;
  border-radius: 2px 0 0 2px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.416;
  padding: 5px 16px 5px;
  height: 50px;
  width: 100%;
}
.newsentries.faqforinternal .newslist__search-submit,
.newsentries.faq .newslist__search-submit {
  position: relative;
}
.newsentries.faqforinternal .newslist__search-submit::before,
.newsentries.faq .newslist__search-submit::before {
  background: url("/f/resources/images/common/icon_search.svg") center/contain no-repeat;
  content: "";
  cursor: pointer;
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 52%;
  left: 34px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .newsentries.faqforinternal .newslist__search-submit::before,
  .newsentries.faq .newslist__search-submit::before {
    width: 20px;
    height: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.newsentries.faqforinternal .newslist__search-submit input,
.newsentries.faq .newslist__search-submit input {
  background-color: #005c69;
  border: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.416;
  padding: 5px 34px 5px 52px;
  text-align: center;
  height: 50px;
  min-width: 120px;
}
@media all and (max-width : 768px) {
  .newsentries.faqforinternal .newslist__search-submit input,
  .newsentries.faq .newslist__search-submit input {
    font-size: 0;
    padding: 0;
    height: 50px;
    min-width: 50px;
    width: 50px;
  }
}
@media all and (max-width : 768px) {
  .newsentries.faqforinternal .newslist__scinario-wrap,
  .newsentries.faq .newslist__scinario-wrap {
    margin: 0 auto;
  }
}
.newsentries.faqforinternal .newslist__result-filter,
.newsentries.faq .newslist__result-filter {
  padding-bottom: 12px;
}
.newsentries.faqforinternal .scenario-faq__btn-wrap,
.newsentries.faq .scenario-faq__btn-wrap {
  position: static;
  transform: translateY(0);
}
.newsentries.faqforinternal .news-entries:not(:has(div)),
.newsentries.faq .news-entries:not(:has(div)) {
  background-color: #ffebeb;
  height: 80px;
  margin-block: 40px;
  display: grid;
  place-items: center;
}
@media all and (max-width : 768px) {
  .newsentries.faqforinternal .news-entries:not(:has(div)),
  .newsentries.faq .news-entries:not(:has(div)) {
    margin-block: 20px;
  }
}
.newsentries.faqforinternal .news-entries:not(:has(div))::before,
.newsentries.faq .news-entries:not(:has(div))::before {
  content: "見つかりませんでした";
  color: #c10000;
  display: block;
  position: absolute;
  font-size: 16px;
}

.newsentries.faqforinternal:not(.faq):not(.archive) .news-header-contents {
  width: 100%;
}
.newsentries.faqforinternal:not(.faq):not(.archive) .newslist__search {
  min-width: 100%;
}
.newsentries.faqforinternal:not(.faq):not(.archive) .newslist__result-filter {
  padding-bottom: 0;
}
.newsentries.faqforinternal:not(.faq):not(.archive) .news-archive-faqforinternal .news-entries {
  margin-top: 10px;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  ご利用ガイド | 規約系ページ
  ---------------
*/
.guide-terms .guide-terms__wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 120px;
}
@media all and (max-width: 768px) {
  .guide-terms .guide-terms__wrap {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .guide-terms .guide-terms__wrap {
    padding-top: 80px;
  }
}

.guide-terms__title {
  font-size: 32px;
  font-weight: bold;
}
@media all and (max-width : 768px) {
  .guide-terms__title {
    font-size: 24px;
  }
}

.guide-terms .guide-terms__block {
  padding-top: 20px;
}

.guide-terms .guide-terms__block + .guide-terms__block {
  padding-top: 40px;
}

.guide-terms .guide-terms__title-sub {
  border-bottom: 1px solid #d9d9d9;
  color: #005c69;
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 20px;
  position: relative;
}

.guide-terms .guide-terms__title-sub::before {
  background: #005c69;
  content: "";
  display: block;
  height: 1px;
  width: 300px;
  position: absolute;
  bottom: -1px;
  left: 0px;
}

.guide-terms .guide-terms__title-detail {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.62;
  margin-top: 40px;
  padding: 0;
}

.guide-terms .guide-terms__title-sub + .guide-terms__title-detail {
  margin-top: 20px;
}

.guide-terms .guide-terms__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.85;
  margin-top: 20px;
}

.guide-terms .guide-terms__title-sub + .guide-terms__text {
  margin-top: 28px;
}

.guide-terms .guide-terms__title-detail + .guide-terms__text {
  margin-top: 0;
}

.guide-terms .guide-terms__link {
  color: #005c69;
  font-size: 14px;
  text-decoration: underline;
  padding: 0;
}

.guide-terms .guide-terms__link-pdf {
  display: block;
  color: #005c69;
  font-size: 14px;
  margin-top: 8px;
  padding-left: 34px;
  position: relative;
  text-decoration: underline;
}

.guide-terms .guide-terms__link-pdf::before {
  background: url(/f/resources/images/common/icon_pdf.png) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

/*--------------------------------------------------
  個人情報保護方針
--------------------------------------------------*/
.guide-terms .guide-terms__privacy {
  padding-top: 20px;
  display: flex;
  align-items: flex-end;
  gap: 40px;
  justify-content: space-between;
}
@media all and (max-width : 768px) {
  .guide-terms .guide-terms__privacy {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

@media all and (max-width : 768px) {
  .guide-terms .guide-terms__privacy-left {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}

.guide-terms .guide-terms__privacy .guide-terms__text,
.guide-terms .guide-terms__privacy-contact .guide-terms__text {
  margin-top: 0;
}

.guide-terms .guide-terms__privacy-right .guide-terms__text {
  text-align: right;
}

.guide-terms .guide-terms__privacy-icon {
  padding-top: 10px;
  text-align: right;
}

.guide-terms .guide-terms__privacy-contact {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 20px;
  margin-inline: auto;
  padding: 16px;
  max-width: 450px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.guide-terms .guide-terms__privacy-contact .guide-terms__text {
  text-align: center;
}

/*--------------------------------------------------
特定商取引に基づく表記
--------------------------------------------------*/
.guide-terms.certainly table {
  padding-top: 20px;
}

.guide-terms.certainly tbody {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media all and (max-width : 768px) {
  .guide-terms.certainly tr {
    display: flex;
    flex-direction: column;
  }
}

.guide-terms.certainly th {
  text-align: left;
  vertical-align: top;
  width: 120px;
}

.guide-terms.certainly th,
.guide-terms.certainly td {
  line-height: 1.7;
}
@media all and (max-width : 768px) {
  .guide-terms.certainly th,
  .guide-terms.certainly td {
    width: 100%;
  }
}

/*--------------------------------------------------
公表事項
--------------------------------------------------*/
.guide-terms.public table {
  border-collapse: collapse;
  margin-top: 8px;
  width: 100%;
}

.guide-terms.public th {
  background: #d9d9d9;
  padding: 8px;
}

.guide-terms.public td {
  padding: 16px;
  text-align: left;
  max-width: 220px;
  width: 30%;
}
@media all and (max-width : 768px) {
  .guide-terms.public td {
    padding: 8px;
  }
}

.guide-terms.public td + td {
  width: 70%;
}
.guide-terms.public th,
.guide-terms.public td {
  border: 1px solid #d9d9d9;
  line-height: 1.7;
}

/*--------------------------------------------------
セキュリティについて
--------------------------------------------------*/
.guide-terms.security .guide-terms__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
@media all and (max-width : 768px) {
  .guide-terms.security .guide-terms__flex {
    flex-direction: column;
  }
}

.guide-terms.security .guide-terms__flex .guide-terms__text {
  margin-top: 0;
  width: calc(100% - 170px);
}
@media all and (max-width : 768px) {
  .guide-terms.security .guide-terms__flex .guide-terms__text {
    margin-top: 20px;
    width: 100%;
  }
}

.guide-terms.security .globalsign_img__wrap {
  width: 128px;
}
@media all and (max-width : 768px) {
  .guide-terms.security .globalsign_img__wrap {
    width: 160px;
  }
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  ご利用ガイドトップ
  ---------------
*/
.select-shop-guide .select-shop-guide__wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 50px;
}
@media all and (max-width: 768px) {
  .select-shop-guide .select-shop-guide__wrap {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .select-shop-guide .select-shop-guide__wrap {
    padding-top: 24px;
  }
}

.select-shop-guide .select-shop-guide__list {
  padding-top: 22px;
  display: grid;
  gap: 8px;
  grid-auto-rows: 200px;
}
@media all and (min-width: 1025px) {
  .select-shop-guide .select-shop-guide__list {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
}
@media all and (max-width: 1024px) and (min-width: 769px) {
  .select-shop-guide .select-shop-guide__list {
    grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
    gap: 16px;
  }
}
@media all and (max-width : 768px) {
  .select-shop-guide .select-shop-guide__list {
    padding-top: 12px;
    gap: 0;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 140px;
  }
}

.select-shop-guide .select-shop-guide__item {
  border: 1px solid #d9d9d9;
  padding: 16px 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -1px;
  margin-left: -1px;
}

.select-shop-guide .select-shop-guide__title-sub {
  color: #005c69;
  font-size: min(1.38vw, 20px);
  font-weight: 500;
  height: 47px;
  line-height: 1.16;
  position: relative;
  text-align: center;
}
@media all and (max-width: 1024px) and (min-width: 769px) {
  .select-shop-guide .select-shop-guide__title-sub {
    font-size: 20px;
  }
}
@media all and (max-width : 768px) {
  .select-shop-guide .select-shop-guide__title-sub {
    font-size: 13px;
    height: 31px;
  }
}

.select-shop-guide .select-shop-guide__mark {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.16;
}

.select-shop-guide .select-shop-guide__figure {
  display: inline-block;
  flex: 1 1 auto;
  display: grid;
  place-items: center;
}

.select-shop-guide .select-shop-guide__image {
  max-width: max-content;
}
@media all and (max-width : 768px) {
  .select-shop-guide .select-shop-guide__image {
    max-width: 60%;
  }
}

.select-shop-guide .select-shop-guide__annotation {
  font-size: 12px;
  margin-top: 12px;
}
.select-shop-guide .select-shop-guide__annotation + .select-shop-guide__annotation {
  margin-top: 4px;
}

.select-shop-guide .select-shop-guide__annotation a {
  text-decoration: underline;
}

.select-shop-guide .select-shop-guide__annotation a:hover {
  text-decoration: none;
  transition: none;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  ご利用ガイドトップ
  ---------------
*/
.guide-flow .guide-flow__wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 60px;
}
@media all and (max-width: 768px) {
  .guide-flow .guide-flow__wrap {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__wrap {
    padding-top: 54px;
  }
}

.guide-flow .guide-flow__list {
  padding-top: 28px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 28px;
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__list {
    padding-top: 14px;
    grid-template-columns: repeat(1, 1fr);
    gap: 48px;
  }
}

.guide-flow .guide-flow__item {
  background: #f6f6f6;
  border-top: 3px solid #00828c;
  position: relative;
}

@media all and (max-width : 768px) {
  .guide-flow .guide-flow__item::before {
    background: url(/f/resources/images/guide/icon_arrow_down.svg) 50%/contain no-repeat;
    content: "";
    display: block;
    display: block;
    height: 24px;
    width: 47px;
    position: absolute;
    bottom: -38px;
    right: 50%;
    transform: translateX(50%);
  }
}

@media all and (min-width: 769px) {
  .guide-flow .guide-flow__item + .guide-flow__item::before {
    background: url(/f/resources/images/guide/icon_arrow_right.svg) 50%/contain no-repeat;
    content: "";
    display: block;
    height: 60px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
  }
}

.guide-flow .guide-flow__item + .guide-flow__item:last-child::after {
  background: url(/f/resources/images/guide/icon_arrow_down.svg) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 24px;
  width: 47px;
  position: absolute;
  bottom: -37px;
  right: 50%;
  transform: translateX(50%);
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__item + .guide-flow__item:last-child::after {
    display: none;
  }
}

.guide-flow .guide-flow__inner {
  padding: 0 26px 26px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__inner {
    padding: 18px 16px;
  }
}

.guide-flow .guide-flow__main {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__main {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }
}

.guide-flow .guide-flow__heading {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__heading {
    min-width: 190px;
    align-items: flex-start;
    gap: 0;
  }
}

.guide-flow .guide-flow__number {
  color: #005c69;
  font-size: 60px;
  font-weight: 900;
  text-align: center;
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__number {
    font-size: 40px;
    text-align: left;
  }
}

.guide-flow .guide-flow__title-sub {
  color: #005c69;
  font-size: 25px;
  font-weight: 500;
  height: 88px;
  line-height: 1.24;
  text-align: center;
  display: grid;
  place-items: center;
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__title-sub {
    height: auto;
    line-height: 1;
    padding: 0;
    text-align: left;
  }
}

.guide-flow .guide-flow__figure {
  background: #fff;
  border-radius: 2px;
  padding: 20px;
  height: 150px;
  max-width: 224px;
  width: 100%;
  display: grid;
  place-items: center;
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__figure {
    padding: 8px;
    height: 93px;
    max-width: 140px;
  }
}

.guide-flow .guide-flow__figure img {
  object-fit: contain;
  max-width: 80%;
  height: 70px;
  width: 120px;
}

.guide-flow .guide-flow__text {
  font-size: 14px;
  margin-top: 16px;
}

.guide-flow .guide-flow__text-s {
  font-size: 12px;
}

.guide-flow .guide-flow__link {
  margin-top: 12px;
  padding: 0;
  text-align: center;
  min-height: 50px;
  max-width: 224px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.guide-flow .guide-flow__link:hover {
  padding: 0;
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__link {
    max-width: 340px;
  }
}

.guide-flow .guide-flow__last {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background: #f6f6f6;
  margin-top: 46px;
  padding-block: 30px;
  width: 100%;
  display: grid;
  place-items: center;
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__last {
    padding-block: 19px;
    padding-left: 84px;
    place-items: start;
  }
}

.guide-flow .guide-flow__last-text {
  color: #005c69;
  font-size: 25px;
  font-weight: 500;
  position: relative;
}

.guide-flow .guide-flow__last-text::before {
  background: url(/f/resources/images/guide/icon_house.png) 50%/contain no-repeat;
  content: "";
  display: block;
  height: 32px;
  width: 35px;
  position: absolute;
  top: 50%;
  left: -46px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .guide-flow .guide-flow__last-text::before {
    left: -60px;
    height: 50px;
    width: 50px;
  }
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ---------------
  ご利用ガイドトップ
  ---------------
*/
.guide-mypage .guide-mypage__wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 60px;
}
@media all and (max-width: 768px) {
  .guide-mypage .guide-mypage__wrap {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .guide-mypage .guide-mypage__wrap {
    padding-top: 50px;
  }
}

.guide-mypage .guide-mypage__body {
  padding-top: 16px;
  display: flex;
  gap: 64px;
}
@media all and (max-width : 768px) {
  .guide-mypage .guide-mypage__body {
    padding-top: 15px;
    align-items: center;
    flex-direction: column;
    gap: 26px;
  }
}

.guide-mypage .guide-mypage__figure {
  max-width: 476px;
  width: 100%;
}
.guide-mypage .guide-mypage__figure img {
  width: 476px;
}

.guide-mypage .guide-mypage__text {
  font-size: 18px;
}
@media all and (max-width : 768px) {
  .guide-mypage .guide-mypage__text {
    font-size: 14px;
    line-height: 2;
  }
}

/*
  ---------------
  商品一覧 | 検索結果
  ---------------
*/
#itemlist .to-partwork-linkArea {
  margin-top: 20px;
}

#itemlist .to-partwork {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}
@media all and (max-width : 768px) {
  #itemlist .to-partwork {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
}

#itemlist .to-partwork-link {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  padding-right: 10px;
  display: flex;
}
@media all and (max-width : 768px) {
  #itemlist .to-partwork-link {
    padding-right: 2px;
  }
}

#itemlist .to-partwork-link img {
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 108px;
  height: 100%;
  max-height: 108px;
}
@media all and (max-width : 768px) {
  #itemlist .to-partwork-link img {
    max-width: 60px;
    max-height: 60px;
  }
}

#itemlist .to-partwork-link .to-partwork-link-text {
  color: #005c69;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4375;
  text-align: left;
  width: 100%;
  margin-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  position: relative;
}
@media all and (max-width : 768px) {
  #itemlist .to-partwork-link .to-partwork-link-text {
    font-size: 11px;
    margin-left: 6px;
    padding-right: 8px;
  }
}
@media (max-width: 390px) {
  #itemlist .to-partwork-link .to-partwork-link-text {
    font-size: 10px;
  }
}

#itemlist .to-partwork-link .to-partwork-link-text::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_chevron_right.svg") center/cover no-repeat;
  display: inline-block;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

/*
  ----------------
  会社情報 | 共通
  ----------------
*/
.company {
  text-align: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}
@media all and (max-width : 768px) {
  .company {
    padding: 0 11px;
  }
}

.inner {
  width: 100%;
  max-width: 760px;
  margin-inline: auto;
}

.company .column-2 {
  display: flex;
}

.company h1 {
  color: #005c69;
  font-size: 26px;
  font-weight: bold;
  margin: 40px 0;
  padding: 12px 0;
}
@media all and (max-width : 768px) {
  .company h1 {
    font-size: 22px;
  }
}

.company h2 {
  color: #005c69;
  font-size: 24px;
  font-weight: bold;
  padding: 40px 0;
  text-align: center;
}
@media all and (max-width : 768px) {
  .company h2 {
    font-size: 22px;
  }
}

.company h3 {
  border-bottom: 1px solid #555;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  margin: 16px 0;
}
@media all and (max-width : 768px) {
  .company h3 {
    font-size: 20px;
  }
}

.company h4 {
  font-weight: bold;
  padding: 20px 0;
  text-align: left;
}

.company span,
.company strong,
.company p {
  text-align: left;
}
@media all and (max-width : 768px) {
  .company span,
  .company strong,
  .company p {
    font-size: 14px;
  }
}

.company-navigation {
  background-color: #1d1d1b;
}
@media all and (max-width : 768px) {
  .company-navigation {
    overflow-x: auto;
  }
}

.company-navigation ul {
  width: max-content;
  margin-inline: auto;
  display: flex;
}

.company-navigation a {
  color: #fff;
  padding: 14px 10px;
}
@media all and (max-width : 768px) {
  .company-navigation a {
    font-size: 14px;
  }
}

.company div.column-4 {
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}
@media all and (max-width : 768px) {
  .company div.column-4 {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

.company div.column-4 > dl dd {
  margin-top: 12px;
}

.company div.column-4 > dl strong {
  color: #005c69;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
}

.company div.column-4 > dl span {
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
}

.company p + p {
  line-height: 1.5;
}

.company a.button {
  background-color: #005c69;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  line-height: 40px;
  padding: 0 20px;
}

.company .panel-gray {
  background-color: #ccc;
  color: #1d1d1b;
  margin: 20px 0;
  padding: 32px 0;
  text-align: center;
  width: 100%;
}

.company ol {
  padding: 40px 20px;
}

.company ol li {
  border-bottom: 1px solid #555;
  padding: 20px;
  text-align: left;
}

.company ol li:first-child {
  border-top: 1px solid #555;
}

.company .newslist-press .news-entry {
  padding: 12px 0;
}

.todo {
  background-color: #ffebeb;
  border: 1px solid #c10000;
  color: #c10000;
  margin: 20px 0;
  padding: 8px;
  text-align: center;
  width: 100%;
}

/*
  ----------------
  社長挨拶
  ----------------
*/
.company.greeting .column-2 {
  max-width: 760px;
  margin-inline: auto;
  gap: 40px;
}
@media all and (max-width : 768px) {
  .company.greeting .column-2 {
    display: block;
  }
}

@media all and (min-width: 769px) {
  .company.greeting .column-2 > div:first-child {
    flex: 0 0 175px;
  }
}

@media all and (max-width : 768px) {
  .company.greeting .column-2 > div:nth-child(2) {
    margin-top: 30px;
  }
}

.company.greeting p {
  margin-bottom: 12px;
}

/*
  ----------------
  沿革
  ----------------
*/
.company.history > p {
  margin-bottom: 80px;
}
@media all and (max-width : 768px) {
  .company.history > p {
    margin-bottom: 60px;
  }
}

.company.history h2 {
  padding: 0 0 20px;
}

.company.history h2 span {
  border: 1px solid #005c69;
  border-radius: 2px;
  cursor: pointer;
  display: block;
  max-width: 430px;
  margin-inline: auto;
  padding: 10px 40px 10px 20px;
  position: relative;
}
@media all and (max-width : 768px) {
  .company.history h2 span {
    font-size: 20px;
    padding: 8px 34px 8px 14px;
  }
}

.company.history h2 span.is-open {
  background-color: #005c69;
  color: #fff;
}

.company.history h2 span.is-open::after {
  background: #fff;
  width: 15px;
  height: 1px;
}

.company.history h2 span::after {
  content: "";
  background: transparent url(/f/resources/images/common/icon_plus_green.svg) center/contain no-repeat;
  display: inline-block;
  width: 15px;
  height: 13px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .company.history h2 span::after {
    right: 10px;
  }
}

.company.history .content {
  display: none;
  margin-bottom: 100px;
}
@media all and (max-width : 768px) {
  .company.history .content {
    margin-bottom: 16px;
  }
}

.company.history dt.img {
  aspect-ratio: 1/1;
}

.company.history dt.img img {
  height: 100%;
}

.company.history div.img {
  text-align: left;
}

/*
  ----------------
  プレスリリース
  ----------------
*/
.company .newslist-press .news-entry {
  padding: 12px 0;
}

/*
  ----------------
  会社概要
  ----------------
*/
.company .summary {
  border: 1px solid #555;
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.company .summary tr {
  text-align: left;
}

.company .summary tr {
  border-top: 1px solid #555;
}

.company .summary th,
.company .summary td {
  border-right: 1px solid #555;
  padding: 4px 20px;
}
@media all and (max-width : 768px) {
  .company .summary th,
  .company .summary td {
    padding: 10px;
  }
}

.company .noborder tr,
.company .noborder th,
.company .noborder td {
  border: none;
}

.company .noborder th,
.company .noborder td {
  padding: 10px;
}
@media all and (max-width : 768px) {
  .company .noborder th,
  .company .noborder td {
    display: block;
    padding: 0;
  }
}

.company .noborder th {
  vertical-align: top;
}

.company .noborder td {
  padding-bottom: 5px;
}

.company iframe {
  max-width: 600px;
}

/*
  ----------------
  会社情報トップ
  ----------------
*/
.company .panel-list ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media all and (min-width: 769px) {
  .company .panel-list ul {
    gap: 0 20px;
  }
}
@media all and (max-width : 768px) {
  .company .panel-list ul {
    grid-template-columns: 1fr;
  }
}

.company .panel-list ul > li {
  border-top: 1px dashed #d9d9d9;
}

@media all and (min-width: 769px) {
  .company .panel-list ul > li:nth-child(4) {
    border-bottom: 1px dashed #d9d9d9;
  }
}

.company .panel-list ul > li:last-child {
  border-bottom: 1px dashed #d9d9d9;
}

.company .panel-list > ul > li a {
  display: block;
  text-align: left;
  width: 100%;
  padding: 16px 0 16px 16px;
}

.company .panel-list > ul > li span {
  display: block;
  width: 100%;
  padding-left: 22px;
  position: relative;
}

.company .panel-list > ul > li span::before {
  background: transparent url("/f/resources/images/common/icon_arrow_right_green.svg") center/contain no-repeat;
  content: "";
  display: inline-block;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

/*
  ----------------
  パートワークとは
  ----------------
*/
.company.partwork .column-2 {
  max-width: 760px;
  margin-inline: auto;
  gap: 40px;
}
@media all and (max-width : 768px) {
  .company.partwork .column-2 {
    display: block;
  }
}

@media all and (min-width: 769px) {
  .company.partwork .column-2 > div:first-child {
    flex: 0 0 260px;
  }
}
@media all and (max-width : 768px) {
  .company.partwork .column-2 > div:first-child {
    max-width: 220px;
    margin-inline: auto;
  }
}

@media all and (max-width : 768px) {
  .company.partwork .column-2 > div:nth-child(2) {
    margin-top: 30px;
  }
}

.company.partwork .message {
  color: #005c69;
  font-size: 26px;
  font-weight: bold;
  margin: 100px 0 40px;
  text-align: center;
}
@media all and (max-width : 768px) {
  .company.partwork .message {
    font-size: 20px;
    margin: 80px 0 40px;
  }
}

/*
  ---------------
  仮パスワードの発行
  ---------------
*/
body#init_password #outer {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  body#init_password #outer {
    padding-inline: 12px;
  }
}
body#init_password h1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
  padding: 0;
}
@media all and (min-width: 769px) {
  body#init_password h1 {
    font-size: 24px;
    line-height: 1.45833333;
  }
}
body#init_password .description {
  margin-top: 20px;
}
body#init_password #reminder-entry-form {
  background-color: #f6f6f6;
  padding: 40px;
  margin-top: 40px;
}
@media all and (max-width : 768px) {
  body#init_password #reminder-entry-form {
    padding: 20px;
  }
}
body#init_password .submit-note {
  margin-top: 20px;
}

/*
  ----------------
  デアゴミュージアム 共通
  ----------------
*/
.news-archive-archive h1,
.dm__title {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.43333333;
  padding: 0;
  padding-bottom: 10px;
  position: relative;
}
@media all and (max-width : 768px) {
  .news-archive-archive h1,
  .dm__title {
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
  }
}

.news-archive-archive h1::before,
.dm__title::before,
.news-archive-archive h1::after,
.dm__title::after {
  content: "";
  display: inline-block;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.news-archive-archive h1::before,
.dm__title::before {
  background-color: #f6f6f6;
  width: 100%;
}

.news-archive-archive h1::after,
.dm__title::after {
  background-color: #00828c;
  width: 108px;
}

/*
  ----------------
  デアゴミュージアム 一覧
  ----------------
*/
.dm__wrraper {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .dm__wrraper {
    padding-inline: 12px;
  }
}

.dm__noentry {
  background-color: #ffebeb;
  color: #c10000;
  margin-top: 40px;
  padding: 20px 0;
  text-align: center;
}

.dm__heading {
  margin-top: 40px;
}

.dm__title-desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-top: 15px;
}

.dm__filter {
  background-color: #f6f6f6;
  margin-top: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 900px) {
  .dm__filter {
    padding: 10px;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.dm__filter form {
  width: 100%;
}

.dm__search {
  width: 100%;
  display: flex;
  align-items: center;
}

.dm__search-input-wrap {
  width: 100%;
}

.dm__search-input-wrap input {
  border: 1px solid #d9d9d9;
  border-right: 0;
  border-radius: 2px 0 0 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.41666667;
  width: 100%;
  padding: 16px 16px 15px;
}

.dm__submit-input-wrap {
  position: relative;
}

.dm__submit-input-wrap::before {
  background: url("/f/resources/images/common/icon_search.svg") center/contain no-repeat;
  content: "";
  cursor: pointer;
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 52%;
  left: 34px;
  transform: translateY(-50%);
}
@media all and (max-width : 768px) {
  .dm__submit-input-wrap::before {
    width: 20px;
    height: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.dm__submit-input-wrap input {
  background-color: #005c69;
  border: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}
@media all and (min-width: 769px) {
  .dm__submit-input-wrap input {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4375;
    text-align: center;
    min-width: 120px;
    padding: 13px 34px 12px 52px;
  }
}
@media all and (max-width : 768px) {
  .dm__submit-input-wrap input {
    font-size: 0;
    width: 50px;
    height: 50px;
  }
}

.dm__result-filter {
  background-color: #f6f6f6;
  padding: 0 20px 15px;
}
@media (min-width: 901px) {
  .dm__result-filter {
    margin-top: -5px;
  }
}
@media (max-width: 900px) {
  .dm__result-filter {
    padding: 5px 10px 15px;
  }
}

.dm__result-keyword {
  font-size: 14px;
  line-height: 1.42857143;
}

.dm__select-wrap {
  position: relative;
}
@media all and (min-width: 769px) {
  .dm__select-wrap {
    flex: 1 0 210px;
  }
}
@media (max-width: 900px) {
  .dm__select-wrap {
    width: calc((100% - 10px) / 2);
  }
}

.dm__select-wrap::after {
  background: url("/f/resources/images/common/icon_arrow_green_bottom_bg_white.svg") center/contain no-repeat;
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 1;
}
@media all and (max-width : 768px) {
  .dm__select-wrap::after {
    right: 5px;
  }
}

.dm__select {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  width: 100%;
  padding: 13px 34px 12px 14px;
  appearance: none;
}
@media (max-width: 900px) {
  .dm__select {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 15px 31px 13px 12px;
  }
}

.dm__newslist {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}
@media all and (max-width : 768px) {
  .dm__newslist {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 20px;
  }
}

.dm__item a {
  display: block;
  width: 100%;
}

.dm__thumbnail {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  padding: 28px;
}
@media all and (max-width : 768px) {
  .dm__thumbnail {
    padding: 15px;
  }
}

.dm__thumbnail img {
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dm__name {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-top: 8px;
}

/*
  ----------------
  デアゴミュージアム 詳細
  ----------------
*/
.news-archive-archive {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
}
@media all and (max-width: 768px) {
  .news-archive-archive {
    padding-inline: 12px;
  }
}
.news-archive-archive .news-entry {
  margin-top: 60px;
}
@media all and (min-width: 769px) {
  .news-archive-archive .news-entry {
    min-height: 560px;
    position: relative;
  }
}
@media all and (max-width : 768px) {
  .news-archive-archive .news-entry {
    margin-top: 25px;
  }
}
.news-archive-archive .news-entry-icon {
  border: 1px solid #d9d9d9;
  padding: 56px;
}
@media all and (min-width: 769px) {
  .news-archive-archive .news-entry-icon {
    display: inline-block;
    width: 46.6666667%;
    max-width: 560px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media all and (max-width : 768px) {
  .news-archive-archive .news-entry-icon {
    max-width: 400px;
    margin-inline: auto;
    padding: 37px;
  }
}
.news-archive-archive .news-entry-icon img {
  aspect-ratio: 1/1;
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.news-archive-archive .news-entry-header {
  width: 100%;
}
@media all and (min-width: 769px) {
  .news-archive-archive .news-entry-header {
    margin-left: 53.33333337%;
  }
}
@media all and (max-width : 768px) {
  .news-archive-archive .news-entry-header {
    margin-top: 20px;
  }
}
.news-archive-archive .news-entry .news-entry-header .news-entry-subject {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.45833333;
}
@media all and (max-width : 768px) {
  .news-archive-archive .news-entry .news-entry-header .news-entry-subject {
    font-size: 20px;
    line-height: 1.45;
  }
}
.news-archive-archive .news-entry-body {
  margin-top: 20px;
}
@media all and (min-width: 769px) {
  .news-archive-archive .news-entry-body {
    margin-left: 53.33333337%;
  }
}
@media all and (max-width : 768px) {
  .news-archive-archive .news-entry-body {
    margin-top: 14px;
  }
}
.news-archive-archive .museum-item__info-head {
  background-color: #f6f6f6;
  color: #005c69;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44444444;
  padding: 20px 30px 0;
}
@media all and (max-width : 768px) {
  .news-archive-archive .museum-item__info-head {
    font-size: 16px;
    line-height: 1.4375;
    padding: 12px 14px 0;
  }
}
.news-archive-archive .museum-item__info {
  background-color: #f6f6f6;
  font-size: 0;
  padding: 13px 30px 20px;
}
@media all and (max-width : 768px) {
  .news-archive-archive .museum-item__info {
    padding: 4px 14px 12px;
  }
}
.news-archive-archive .museum-item__info dt,
.news-archive-archive .museum-item__info dd {
  display: inline-block;
}
.news-archive-archive .museum-item__info dt {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  min-width: 100px;
}
@media all and (max-width : 768px) {
  .news-archive-archive .museum-item__info dt {
    font-size: 14px;
    line-height: 1.78571429;
  }
}
.news-archive-archive .museum-item__info dd {
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  width: calc(100% - 101px);
}
@media all and (max-width : 768px) {
  .news-archive-archive .museum-item__info dd {
    font-size: 14px;
    line-height: 1.78571429;
  }
}
.news-archive-archive .museum-item__content {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44444444;
  margin-block: 20px;
}
@media all and (max-width : 768px) {
  .news-archive-archive .museum-item__content {
    font-size: 14px;
    line-height: 1.42857143;
    padding-inline: 14px;
  }
}
.news-archive-archive .hashtag {
  color: #005c69;
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44444444;
}
@media all and (max-width : 768px) {
  .news-archive-archive .hashtag {
    font-size: 14px;
    line-height: 1.42857143;
    padding-inline: 14px;
  }
}
.news-archive-archive .trailer-link {
  text-align: left;
  margin-top: 40px;
  padding: 0;
}
@media all and (min-width: 769px) {
  .news-archive-archive .trailer-link {
    text-align: center;
  }
}
.news-archive-archive .trailer-link a {
  background-color: #f6f6f6;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #005c69;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3125;
  min-height: 50px;
  padding: 13px 21px 13px 48px;
  display: inline-flex;
  align-items: center;
  position: relative;
}
@media all and (min-width: 769px) {
  .news-archive-archive .trailer-link a {
    min-width: 390px;
  }
}
@media all and (max-width : 768px) {
  .news-archive-archive .trailer-link a {
    width: 100%;
    max-width: 270px;
    min-height: 60px;
    margin-inline: auto;
    padding: 9px 23px 9px 53px;
    display: flex;
  }
}
.news-archive-archive .trailer-link a::after {
  background: transparent url("/f/resources/images/common/icon_arrow_right_bg_green.svg") center/contain no-repeat;
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 17px;
  transform: translateY(-50%);
}
.news-archive-archive div.news-entry div.release-date,
.news-archive-archive .reactions.news {
  display: none;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ----------------
  お問い合わせ完了ページ
  ----------------
*/
.contact-complete .contact-complete__wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 100px;
  display: grid;
  place-items: center;
}
@media all and (max-width: 768px) {
  .contact-complete .contact-complete__wrap {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .contact-complete .contact-complete__wrap {
    padding-top: 40px;
  }
}

.contact-complete .contact-complete__title {
  font-size: 24px;
  font-weight: 500;
  padding: 0;
}

.contact-complete .contact-complete__text {
  font-size: 16px;
  font-weight: 400;
  margin-top: 40px;
  padding: 0;
}
@media all and (max-width : 768px) {
  .contact-complete .contact-complete__text {
    font-size: 14px;
    margin-top: 20px;
  }
}

.contact-complete .contact-complete__text + .contact-complete__text {
  margin-top: 8px;
}

.contact-complete .contact-complete__link {
  padding-top: 80px;
  text-align: center;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ----------------
  問合せ前シナリオ（シナリオシーケンス）
  ----------------
*/
.contact-scenario .scenario-sequence {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 100px;
  display: grid;
  place-items: center;
}
@media all and (max-width: 768px) {
  .contact-scenario .scenario-sequence {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .contact-scenario .scenario-sequence {
    padding-top: 40px;
  }
}
.contact-scenario .sequential-contents {
  width: 100%;
}
.contact-scenario .active-contents {
  font-size: 24px;
  font-weight: 500;
  padding: 0 0 40px 0;
}
@media all and (max-width: 1024px) {
  .contact-scenario .active-contents {
    padding-bottom: 20px;
  }
}
.contact-scenario ol.subsequence-label {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: left;
}
@media all and (max-width: 1024px) {
  .contact-scenario ol.subsequence-label {
    gap: 10px;
  }
}
.contact-scenario ol.subsequence-label > li {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  min-height: 130px;
  width: calc((100% - 60px) / 4);
}
@media all and (max-width: 1024px) {
  .contact-scenario ol.subsequence-label > li {
    width: calc((100% - 10px) / 2);
  }
}
@media all and (max-width : 768px) {
  .contact-scenario ol.subsequence-label > li {
    min-height: 80px;
  }
}
.contact-scenario ol.subsequence-label > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  color: #005c69;
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  height: 100%;
  width: 100%;
}
@media all and (max-width : 768px) {
  .contact-scenario ol.subsequence-label > li > a {
    font-size: 16px;
  }
}
.contact-scenario .input-area {
  padding-bottom: 20px;
}
@media all and (max-width: 1024px) {
  .contact-scenario .input-area {
    padding-bottom: 10px;
  }
}
.contact-scenario .input-area .sequential-contents-input {
  border: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  padding: 5px 16px 5px;
  height: 50px;
  width: 100%;
}
.contact-scenario .search-result__heading {
  font-weight: 400;
}
@media all and (max-width : 768px) {
  .contact-scenario .search-result__heading {
    font-size: 20px;
  }
}
.contact-scenario .search-result__faq {
  border: 1px solid #d9d9d9;
  margin-top: 40px;
  padding: 8px 20px;
}
@media all and (max-width : 768px) {
  .contact-scenario .search-result__faq {
    padding: 8px;
    margin-top: 20px;
  }
}
.contact-scenario .search-result__faq ol li {
  position: relative;
}
.contact-scenario .search-result__faq ol li + li {
  border-top: 1px solid #d9d9d9;
}
.contact-scenario .search-result__faq ol li::before {
  background: #005c69;
  border-radius: 2px;
  color: #fff;
  content: "Q";
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  display: grid;
  place-items: center;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 18px;
  left: 12px;
}
.contact-scenario .search-result__faq ol li a {
  color: #005c69;
  display: block;
  font-size: 16px;
  font-weight: 500;
  padding: 18px 40px 18px 47px;
}
@media all and (max-width : 768px) {
  .contact-scenario .search-result__faq ol li a {
    padding: 12px 10px 12px 47px;
  }
}
.contact-scenario .search-result__show-all {
  color: #005c69;
  display: block;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
  margin-top: 20px;
}
.contact-scenario .search-result__message {
  font-weight: 400;
  margin-top: 80px;
  text-align: center;
}
@media all and (max-width : 768px) {
  .contact-scenario .search-result__message {
    font-size: 20px;
    margin-top: 40px;
    text-align: left;
  }
}
.contact-scenario .search-result__message a {
  text-decoration: underline;
}

:root {
  --ers-deago-col1:#009a9a;
  --ers-deago-col2:#fff;
  --ers-deago-maxwidth:1200px;
  --ers-deago-boxwidth:380px;
}

/*
  ----------------
  Hover style

  ▼Default：
    transition 0.2s ease-in-out
    hover opacity 0.6
  ----------------
*/
/*
  ----------------
  Container style
  ----------------
*/
/*
  ----------------
  title style
  ----------------
*/
/*
  ----------------
  Loading Spinner
  ----------------
*/
/*
  ---------------
  ニュースアイコン | tp-**
  ---------------
*/
/*
  ----------------
  お問い合わせ一覧
  ----------------
*/
.contact-list__wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;
  padding-top: 100px;
  display: grid;
  place-items: center;
}
@media all and (max-width: 768px) {
  .contact-list__wrap {
    padding-inline: 12px;
  }
}
@media all and (max-width : 768px) {
  .contact-list__wrap {
    padding-top: 40px;
  }
}

.contact-list__title {
  font-size: 24px;
  font-weight: 500;
  padding: 0;
}

.contact-list__list {
  padding-top: 40px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media all and (max-width : 768px) {
  .contact-list__list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.contact-list__link {
  background: #f6f6f6;
  border: 1px solid #d9d9d9;
  padding: 20px 25px 20px;
  position: relative;
  text-decoration: none;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
@media (hover: hover) and (pointer: fine) {
  .contact-list__link {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }
  .contact-list__link:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
  }
}
.contact-list__link::before, .contact-list__link::after {
  backface-visibility: hidden;
}
@media all and (max-width : 768px) {
  .contact-list__link {
    gap: 10px;
    min-height: 70px;
    padding: 10px 12px 10px;
  }
}

.contact-list__icon {
  object-fit: contain;
  object-position: left;
  width: 80px;
  height: 48px;
}
@media all and (max-width : 768px) {
  .contact-list__icon {
    height: 40px;
    width: 45px;
  }
}