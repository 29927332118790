@use "global" as g;

/*
  ---------------
  セレクトショップ 商品詳細 | 他のお客様が閲覧している商品、最近チェックした商品 共通
  ---------------
*/
.p-other, .p-history {
  background-color: g.$bg-gray-primary;
  width: 100vw;
  margin: 50px calc(50% - 50vw) 0;
  padding-block: 50px 60px;

  @media #{g.$sp} {
    overflow: hidden;
  }

  .sc-display {
    margin-top: 0;
  }

  .sc-display .display-name {
    font-size: 20px;
    line-height: 1.45;

    @media #{g.$sp} {
      text-align: center;
    }
  }

  .sc-display .panel-body {
    position: relative;

    &::after {
      @media #{g.$pc} {
        content: "";
        display: inline-block;
        background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%);
        width: 181px;
        height: 100%;
        margin-right: calc(50% - 50vw);

        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .sc-display .display-box .item-display .slick-list {
    @media #{g.$pc} {
      max-width: 88.8888889%;
    }
  }

  .sc-display .slick-list {
    @media #{g.$pc} {
      overflow: visible;
    }
  }

  // .sc-display .item.article {
  //   @media #{g.$pc} {
  //     max-width: 240px;
  //   }
  // }

  .sc-display div.item-thumbnail-img-sm a {
    aspect-ratio: 1 / 1;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    @media #{g.$pc} {
      opacity: 0;
    }
  }

  // .l-slider {
  //   margin-top: 18px;

  //   @media #{g.$pc} {
  //     position: relative;
  //   }

  //   @media #{g.$sp} {
  //     margin-top: 20px;
  //   }

  //   &::after {
  //     @media #{g.$pc} {
  //       content: "";
  //       display: inline-block;
  //       background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%);
  //       width: 181px;
  //       height: 100%;
  //       margin-right: calc(50% - 50vw);

  //       position: absolute;
  //       top: 0;
  //       right: 0;
  //     }
  //   }
  // }

  // .l-slider__slide {
  //   @media #{g.$pc} {
  //     max-width: 240px;
  //   }
  // }

  // .l-slider__slide-img {
  //   aspect-ratio: 1 / 1;
  //   overflow: hidden;
  //   position: relative;

  //   img {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: contain;

  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //   }
  // }

  // .l-slider__slide-title {
  //   font-size: 14px;
  //   font-weight: 500;
  //   line-height: 1.4375;
  //   margin-top: 20px;

  //   @media #{g.$sp} {
  //     font-size: 13px;
  //     line-height: 1.42857143;
  //     margin-top: 10px;
  //   }
  // }

  // .l-slider__slide-price {
  //   font-size: 14px;
  //   font-weight: 500;
  //   line-height: 1.42857143;
  //   margin-top: 6px;

  //   @media #{g.$sp} {
  //     font-size: 13px;
  //     line-height: 1.46153846;
  //   }
  // }

  // // slick
  // .slick-list {
  //   margin-inline: -15px;

  //   @media #{g.$pc} {
  //     overflow: visible;
  //   }

  //   @media #{g.$sp} {
  //     margin-inline: -5px;
  //   }
  // }

  // .slick-track {
  //   display: flex;
  // }

  // .slick-slide {
  //   margin-inline: 15px;

  //   @media #{g.$sp} {
  //     margin-inline: 5px;
  //   }
  // }

  // .slick-arrow {
  //   top: 50%;
  //   transform: translateY(-50%);
  // }

  // .slick-prev,
  // .slick-next {
  //   width: 50px;
  //   height: 50px;

  //   @media #{g.$sp} {
  //     width: 30px;
  //     height: 60px;
  //   }
  // }

  // .slick-prev {
  //   left: -12px;
  // }

  // .slick-next {
  //   right: -12px;
  // }

  // .slick-prev:before {
  //   content: "";
  //   display: inline-block;

  //   @media #{g.$pc} {
  //     background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
  //     width: 50px;
  //     height: 50px;
  //   }

  //   @media #{g.$sp} {
  //     background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
  //     width: 30px;
  //     height: 60px;
  //   }
  // }

  // .slick-next:before {
  //   content: "";
  //   display: inline-block;

  //   @media #{g.$pc} {
  //     background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
  //     width: 50px;
  //     height: 50px;
  //   }

  //   @media #{g.$sp} {
  //     background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
  //     width: 30px;
  //     height: 60px;
  //   }
  // }

  // .slick-prev.slick-disabled:before,
  // .slick-next.slick-disabled:before {
  //   opacity: 0;
  // }
}
