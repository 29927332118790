@use "global"as g;

/*-- n2karyon defaults --*/
/*-- AddressBook --*/
div.addressbook{
	max-width:800px;
	text-align:right;
	position:relative;
}
div.addressbook div.ab-list{
	position:absolute;
	right:0;
	background-color:#fff;
	box-shadow:0 2px 4px rgba(0,0,0,0.1);
	z-index:1000;
	border:1px solid #ccc;
	border-radius:4px;
}
div.addressbook div.ab-list ul li{
	padding:0.5em 2em;
	text-align:left;
	cursor:pointer;
}
div.addressbook div.ab-list ul li:hover{
	background-color:#eee;
}
div.addressbook div.ab-list ul li div.naa{
	display:flex;
}

div.addressbook div.ab-list ul li span.name{
	padding:0 1em 0 0;
	width:8em;
	white-space:nowrap;
	text-overflow:ellipsis;
	overflow-x:hidden;
}
div.addressbook div.ab-list ul li span.address{
	max-width:16em;
	white-space:nowrap;
	text-overflow:ellipsis;
	overflow-x:hidden;
}
/*-- AddressBook End--*/

/*-- Form Element --*/
div.form{
	padding:0;
}

div.form h3{
	padding:0;
}

div.form fieldset{
	border:none;
	border-top:1px solid #eee;
	background-color: #fefefe;
	margin:0 0 1em;
	border-radius: 4px;
	padding:1em 0 1em;
	margin:1em 0;
}
div.form fieldset legend{
	background-color:#fff;
	padding:0 1em;
	text-align:center;
	color:#966;
}
div.form fieldset fieldset{
	background-color: #ececec;
	margin:1em;
}
div.form fieldset legend{
	font-size:12px;
}
div.form-note, div.note{
	margin:1em 0;
	padding:0;
	color:#444;
}
div.formrow input[type=text],
div.formrow input[type=email],
div.formrow input[type=tel],
div.formrow input[type=number],
div.formrow input[type=password],
div.formrow select,
div.formrow textarea{
    border:1px solid #ccc;
    border-radius:2px;
    font-size:14px;
    font-family:inherit;
    font-weight:500;
    padding:8px 8px;
    width:100%;
    font-size:16px;
    box-sizing:border-box;
}
div.formrow textarea{
    height:8em;
}
div.formrow select.date{
	padding:4px 0;
}
div.formrow select.date-yyyy{
	width:5em;
}
div.formrow select.date-mm{
	width:4em;
}
div.formrow select.date-dd{
	width:4em;
}

div.formrow{
	position:relative;
	margin:0;
}
div.input-group-address div.formrow{
	border-bottom:1px dotted #ccc;
}

div.input-group-address div.formrow:last-child{
	border:none;
}

div.formrow div.input-label{
	position:relative;
	display:flex;
}
div.formrow div.input-label span{
	display:block;
}
div.formrow div.input-label span.input-name{
	margin-right:auto;
}
div.formrow div.input-label span.input-required,
div.formrow div.input-label span.input-may-required,
div.formrow div.input-label span.input-disabled{
	background-color:transparent;
	color:#c00;
	padding:1px 4px;
	font-size:11px;
	border:1px solid #c00;
	border-radius:2px;
	display:inline-block;
	float:right;
}
div.formrow div.input-label span.input-may-required{
	background-color:#eee;
}
div.formrow div.input-control{
	position:relative;
}
div.formrow div.input-control div.input-group{
	width:100%;
}
div.formrow div.input-note{
	font-size:14px;
	color:#666;
}

div.formrow div.input-control.input-control-group{
	display:flex;
	width:100%;
}
div.formrow-radio div.input-control div.input-group,
div.formrow-checkbox div.input-control div.input-group{
	padding:8px 4px;
}
div.formrow div.input-control div.input-group-radio div.radio-item{
	display: inline-block;
}

div.formrow div.input-control.input-control-group div.input-group{
	flex:1;
	padding-left:8px;
	position:relative;
}
div.formrow div.input-control.input-control-group div.input-group:first-child{
	padding-left:0;
}
div.formrow span.input-group-addon{
	position:absolute;
	top:-13px;
	line-height:11px;
	font-size:11px;
    padding:0;
}



div.formrow input.required {
    background-color:#fee;
}
div.formrow input.error {
	border:1px solid #c99;
}
div.formrow input.valid {
	background-color:#efe;
	border:1px solid #9c9;
}
.nmessage-default{
	z-index: 100;
	position: absolute;
	top:2px;
	left:1em;
	font-size:12px;
	white-space: nowrap;
	background-color: #fff;
	padding:4px 8px;
	border:1px solid #fac;
	border-radius: 4px;
	color:#e06;
	animation:blink 1.2s ease-out infinite alternate;
}

@keyframes blink{
    0% {opacity:0.6;}
    100% {opacity:1.0;}
}



div.btn-submit,
div.formrow-submit{
	padding:2em 0 4em;
  text-align:center;
  font-size:16px;
}
div.btn-group{
	padding:0.5em;
	text-align:right;
}
div.btn-group > div{
	display:inline-block;
	padding:4px;
}
.btn + .btn{
	margin-left:1em;
}

button.btn, a.btn, input[type=button], a.btn:visited,a.btn:hover
{
	display:inline-block;
	font-size:14px;
	line-height:1.3;
	font-family: inherit;
	padding:0.5em 1em;
	text-decoration:none;
	cursor:pointer;
  border-radius:4px;
  background:linear-gradient(#fff,#eee);;
  color:#444 !important;
  border:1px solid #999;
  box-shadow:0 1px 2px rgba(0,0,0,0.2);
}

button.btn-submit{
    font-size:24px;
    font-family:inherit;
    padding:16px 24px;
	min-width:280px;
    border-radius:6px;
    background:linear-gradient(#fff,#eee);
    color:#444;
    border:1px solid #999;
    box-shadow:0 1px 2px rgba(0,0,0,0.2);
}
button.btn-submit:hover{
    background:linear-gradient(#fff,#ddd);
}
button.btn-submit[disabled]{
    color:#666;
    opacity: 0.4;
		pointer-events: none;
}
/*-- Form Element END--*/
