@use "global"as g;

/*-- n2karyon 2020  --*/
/*-- reset --*/
body {
  margin: 0;
  padding: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

ul li,
ol li {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* base style */
html {
  font-size: 14px;
}

body {
  font-size: 16px;
}

body #wrapper {
  display: flex;
  flex-direction: column;
}

body #wrapper #outer {
  margin-bottom: auto;
  width: 100%;
}

#outer,
#nav>div.contents-wrapper,
#footer>div.contents-wrapper {
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0.5em;
}

h1 {
  /*-- Page Title --*/
  font-size: 2rem;
}

h2 {
  /*-- Sub Titles --*/
  font-size: 1.5rem;
  padding: 0.5em 0;
}

h3 {
  /*-- Adhoc Title --*/
  font-size: 1.25rem;
}

h4 {
  /*-- Caption for panels and tables --*/
  font-size: 1em;
}

h5 {
  /*-- Titles in Small Panels --*/
  font-size: 1em;
}

h6 {
  /*-- Titles in Small Panels --*/
  font-size: 1em;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
  color: inherit;
}

.clickable {
  cursor: pointer;
}

span.badge {
  margin-left: 1em;
}

div.pop-msg {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(220, 220, 220, 0.9);
  border-bottom: 1px solid #ccc;
  color: #666;
  padding: 0.5em 1em;
  z-index: 9000;
}

div.alert {
  color: #c44;
  text-align: center;
  border: 1px solid #c44;
  padding: 0.5em 1em;
  background-color: #fff5f5f5;
  margin: 1em 0;
}

/*-- Panel Common --*/
div.panel div.panel-heading {
  text-align: center;
}

div.panel div.panel-heading div.description {
  text-align: center;
  font-size: 0.75em;
  padding: 0.5em;
}

div.panel-heading+div.panel-body {
  margin-top: 2em;
}

div.panel-body {
  margin-bottom: 4em;
}

div.panel div.panel-body div.panel div.panel-heading {
  background-color: #eee;
  ;
  border: none;
}

/*-- Panel Common END --*/

ul.panels {
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  font-size: 12px;
}

ul.panels>li {
  flex: 1;
  min-width: 150px;
  margin: 0.5em;
}

ul.panels>li>div {}

ul.panels>li>h6 {
  text-align: center;
  font-weight: normal;
  padding: 0.5em;
  background-color: #eee;
  border-radius: 3px;
}

ul.panels>li>h6+div {
  text-align: center;
  padding: 0.5em;
}

div.close-slide-menu {
  text-align: right;
  border-top: 1px solid #ccc;
  padding-top: 1em;
  margin-top: 1em;
}


div.open-slide-menu {
  padding: 0.5em;
  position: absolute;
  top: 0;
}

div.open-slide-menu-prepend {
  left: 0;
}

div.open-slide-menu-append {
  right: 0;
}

div.close-slide-menu button {
  font-size: 11px;
}

div.error {
  padding: 1em;
}

div.error span.message {
  color: #c00;
}

div.error ul.message-list {
  padding: 0.5em 1em;
  color: #c00;
  background-color: #ffebeb;
  text-align: left;
  border-radius: 3px;
  border: 1px solid #c00
}

div.error ul.message-list li.msg {
  font-size: 14px;
  padding: 0.25em 0;
}

.price-overridden {
  text-decoration: line-through;
}


/*-- Item Search Box --*/
div.item-search {
  position: relative;
}

div.item-search div.sb-suggest {
  position: absolute;
  left: 0;
  z-index: 1000;
  width: 280px;
}

div.item-search div.item-search-suggest {
  background-color: #fff;
}

div.item-search div.item-search-suggest a {
  text-decoration: none;
}

div.item-search div.item-search-suggest ul {
  border: 1px solid #ccc;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
}

div.item-search div.item-search-suggest li.item-list-each {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

div.item-search div.item-search-suggest div.item-dtl {
  padding-left: 8px;
}

div.item-search div.item-search-suggest div.item-dtl div.name {
  font-size: 14px;
  font-weight: bold;
}

div.item-search div.item-search-suggest div.item-dtl div.summary {
  font-size: 11px;
}

div.item-search div.item-search-suggest div.view-more-items {
  text-align: right;
  font-size: 12px;
  padding: 8px;
}

/*-- Item Search Box End --*/

/*-- Item Tag base --*/
div.item-tags {
  display: flex;
  flex-wrap: wrap;
}

div.item-tags div.item-tag {
  padding: 0.25em 1em;
  margin: 0 0.5em 0.5em 0;
  background-color: #bbb;
  color: #fff;
  white-space: nowrap;
}

/*-- Item Tag base End --*/



/*-- Category List --*/
div.category-list {}

div.category-list div.category-list-desc {
  font-size: 0.75rem;
  padding: 1em 0;
}

aside div.category-list div.list-items {}

div.category-list div.list-item {
  padding: 0.5em;
}

div.category-list div.list-item div.desc {
  font-size: 75%;
  padding-left: 1em;
}

div.category-list div.list-items div.category-name a {
  color: inherit;
  text-decoration: none;
}

div.category-list .active {
  font-weight: bold;
}

/*-- Category List End --*/
/*-- Item Search Filter --*/
div.item-search-filter .active {
  font-weight: bold;
}

/*-- Item Search Filter End--*/


/*-- Item with Thumbnail End--*/

/*-- ItemDetails --*/
div.itemdetail-row {
  display: flex;
  flex-wrap: nowrap;
}

div.itemdetail-row div.item {
  flex: 2;
}

div.itemdetail-row div.purchase-contents {
  flex: 1;
}

div.itemdetails-header div.itemdetail-row {
  align-items: center;
  text-align: center;
  justify-content: center;
}

div.itemdetails-body div.itemdetail-row {
  padding: 1em 0;
  border-bottom: 1px solid #999;
}

div.itemdetail-row div.item>div.control {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  white-space: nowrap;
  text-align: center;
}

div.itemdetail-row div.item div.item-thumbnail-img-wrapper {
  margin: 0;
}

div.itemdetail-row div.purchase-contents {
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

div.itemdetail-row div.purchase-contents>div {
  padding: 1em;
  flex: 1;
}

div.itemdetail-row div.purchase-contents>div.subtotal {
  text-align: right;
}

div.goods {
  display: flex;
  position: relative;
}

div.goods-text div.error-message {
  color: #c00;
  background-color: #fff5f5f5;
  border: 1px solid #a00;
  padding: 2px 4px;
  margin: 0.5em 0;
}

div.goods-text-heading {
  display: flex;
  flex-direction: column-reverse;
}

div.goods-text div.unit-price {
  text-align: right;
}

div.goods-text div.unit-price span.price {
  display: block;
}

div.goods-text div.item-options {
  font-size: 12px;
}

div.goods-text div.paymentmethod-restriction {
  color: #c66;
  font-size: 11px;
}

div.goods-text div.paymentmethod-restriction>div {
  display: inline;
}

div.goods-text div.manufacturer {
  display: flex;
  font-size: 0.9rem;
}

div.goods-text div.manufacturer>div {
  margin-right: 1em;
}

div.goods-text span.rp-opt-conf {
  border: 1px solid #d51;
  color: #d51;
  padding: 1px 2px;
  border-radius: 3px;
}

div.goods span.rp-opt-next {
  display: block;
  color: #d51;
}

div.goods div.applied-promotions span.promotion {
  font-size: 12px;
  background-color: #fff;
  color: #f82;
  border: 1px solid #f82;
  border-radius: 2px;
  display: inline-block;
  padding: 1px 4px;
  margin: 2px 0;
}

div.goods div.applied-itemset span.itemset {
  display: inline-block;
  font-size: 12px;
  background-color: #f82;
  border: 1px solid #f82;
  color: #fff;
  border-radius: 2px;
  padding: 1px 4px;
  margin: 2px 0;
}

div.itemdetails div.msg-add-item {
  padding: 0.5em 0;
  font-size: 12px;
  color: #090;
}

div.move-item-wrapper ul.move-item-dst {
  margin: 1em 0;
  background-color: #f5f5f5;
  padding: 1em;
}

div.move-item-wrapper ul.move-item-dst>li {
  cursor: pointer;
  margin-bottom: 0.5em;
  padding: 0.5em 1em;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

div.move-item-wrapper ul.move-item-dst>li.inactive {
  background-color: #eee;
}

div.move-item-wrapper ul.move-item-dst>li.active,
div.move-item-wrapper ul.move-item-dst>li.inactive:hover {
  background-color: #fff;
}

div.move-item-wrapper ul.move-item-dst>li.active {
  border: 1px solid #090;
}

div.move-item-wrapper ul.move-item-dst>li:last-child {
  margin-bottom: 0;
}

div.move-item-wrapper ul.move-item-dst>li>div.label {
  color: #090;
  font-size: 90%;
}

div.move-item-wrapper ul.move-item-dst>li>div.note {
  font-size: 75%;
  color: #666;
}

div.move-item-wrapper ul.move-item-dst>li>div.note-person {
  display: flex;
}

div.move-item-wrapper ul.move-item-dst>li>div.note>div.items-summary ul>li>span {
  padding: 0 1em;
}

div.move-item-wrapper div.note {
  padding: 0 1em;
  color: #e70;
}

div.move-item-wrapper div.submit-button {
  padding: 1em 0;
  text-align: center;
}

div.move-item-qty-select {
  text-align: center;
  color: #fff;
  font-size: 12px;
  background-color: #0c0;
  border: 1px solid #090;
  border-radius: 4px;
  padding: 0.5em;
  overflow: hidden;
}

div.move-item-qty-select>span {
  display: inline-block;
  padding: 0.5em;
}

div.move-item-qty-select>select {
  border: none;
}

div.btn-moveitem button {
  background: linear-gradient(#0d0, #0c0);
  color: #fff !important;
  border: 1px solid #090;
  margin: 1em;
}

div.submit-moveitem {
  text-align: center;
}

div.submit-moveitem button {
  font-size: 1.5rem;
}


/*-- ItemDetails End --*/

/*-- Total Price --*/
div.price-total {
  padding: 1em 0;
  max-width: 400px;
  margin-left: auto;
  font-size: 1.2rem;
  font-weight: bold;
}

div.price-total div.row {
  display: flex;
}

div.price-total div.qty {
  padding: 0 1em;
}

div.price-total div.subtotal {
  color: #a00;
  margin-left: auto;
}

/*-- Total Price End --*/

/*-- EntryItems Table Row --*/
div.present-applied {
  padding: 2em 1em;
}

div.present-applied div.caption {
  text-align: left;
  color: #0a0;
}

div.present-applied div.present-list ul {
  padding: 0;
}

div.present-applied div.present-list ul>li {
  background-color: #eee;
  border: 1px solid #ccc;
  display: inline-block;
  margin: 0 0.5em 0.5em 0;
}

div.present-applied div.present-list ul>li div.present {
  padding: 0.5em;
}

div.present-applied div.present-list ul>li div.present div.description {
  font-size: 75%;
  color: #999;
}

div.present-applied div.present-list ul>li div.present div.present-option {
  font-size: 75%;
}

div.present-applied div.present-list ul>li div.present span.present-option-value {
  margin: 0 0.5em;
}

/*-- EntryItems Table Row End --*/

/*-- Order History --*/
ul.order-list li {
  padding: 0 0 2em;
}

ul.order-list li>div.panel {
  border: 1px solid #ccc;
}

div.panel-order-smr div.panel-heading {
  display: flex;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
}

div.panel-order-smr div.panel-heading>div {
  flex: 1;
  padding: 0.5em;
}

div.panel div.panel-body div.ordersmr-shipment div.panel-heading {
  background-color: transparent;
  display: block;
}

div.panel div.panel-body div.ordersmr-shipment div.panel-heading div.shipment-code {
  padding: 0;
  font-size: 2rem;
}

div.panel div.panel-footer {
  display: flex;
  flex-wrap: wrap;
}

div.panel div.panel-footer>div {
  flex: 1;
  min-width: 200px;
  text-align: center;
  padding: 1em;
}

div.panel div.panel-footer>div div.note {
  font-size: 0.9rem;
}

div.extra-controls {
  text-align: center;
}

div.order-history-extra-btns>div {
  padding: 4px;
  text-align: center;
}

div.order-history-extra-btns .btn,
div.order-history-extra-btns .btn:hover {
  font-size: 0.8rem;
}

/*-- Order History End --*/



/*-- Item -- */
h1.item-name,
h2.item-name {
  margin: 0;
  background-color: inherit;
  color: inherit;
  text-shadow: none;
}

span.item-name-and-code {}

span.item-name-and-code span.item-code {
  font-size: 12px;
  font-weight: normal;
}

#item-contents-main {
  display: flex;
  flex-wrap: wrap;
}

#item-contents-image {
  position: relative;
}

#item-contents-purchase {
  min-width: 300px;
}

#item-contents-text {}

#item-contents-review {}

.item-purchase {
  margin-bottom: 1em;
  text-align: left;
}

.item-purchase .purchase-item-prices {
  text-align: right;
}

form.item-purchase {
  text-align: right;
  padding: 1em 0 2em;
}

.item-purchase div.exp-promotion-prices {
  text-align: right;
}

.item-purchase div.exp-promotion-prices ol li {
  margin-left: auto;
}

.item-purchase div.exp-promotion-prices ol li>div {
  display: inline-block;
}

.item-purchase .promotion-price span.applied-promotion {
  color: #444;
  border: 1px solid #444;
  font-size: 12px;
  background-color: #fff;
  padding: 1px 2px;
  border-radius: 2px;
  white-space: nowrap;
}

.price-label {
  padding-right: 1em;
}

.regular-price {
  font-size: 0.8em;
}

.item-purchase .selling-price .price {
  font-weight: bold;
  white-space: nowrap;
}

.item-purchase .promotion-price .price {
  font-weight: bold;
}

.selling-price .price,
.final-price .price {
  color: #a00;
}

div.final-price span.price {
  font-size: 1.25em;
}

.overridden {
  text-decoration: line-through;
}

div.stock-message {
  font-size: 14px;
  text-align: left;
}

div.stock-message-enough {
  color: #090;
}

div.stock-message-low {
  color: #f73;
}

.item-purchase div.stock-message-none {
  color: #c00;
}

.item-purchase div.delivery-message {
  font-size: 12px;
  color: #977;
}

.item-option-ctrl .item-option-out-of-stock {
  color: #c00;
  font-size: 11px;
  border: 1px solid #c00;
  padding: 2px;
  border-radius: 2px;
}

table.item-purchase-table {
  width: 100%;
  font-size: 90%;
  border-collapse: collapse;
  margin: 2em 0;
}

table.item-purchase-table th {
  border: 1px solid #ccc;
  padding: 8px;
  white-space: nowrap;
  background-color: #eee;
  text-align: center;
}

table.item-purchase-table td {
  border: 1px solid #ccc;
  padding: 8px;
}

table.item-purchase-table td.summary div.summary-txt {
  padding: 8px;
  text-align: center;
}

table.item-purchase-table td.purchase button.btn {
  font-size: inherit;
}

table.item-purchase-table td.purchase form {
  text-align: center;
}

div.item-thumbnail-img-wrapper {
  margin: 0 auto;
}

div.item-thumbnail-img {
  margin: 0 auto;
}

div.item-thumbnail-img a:has(.no-img) {
  display: inline;
}

div.item-thumbnail-img img {
  width: 100%;
  overflow-y: hidden;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(127, 127, 127, 0.3);
}

div.item-thumbnail-img div.no-img {
  height: 100%;
  border: 1px solid #ccc;
  background-color: #eee;
  box-shadow: 0 2px 4px rgba(127, 127, 127, 0.3);
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.item-thumbnail-img-xs {
  width: 75px;
  height: 75px;
}

div.item-thumbnail-img-sm {
  width: 150px;
  height: 150px;
}

div.item-thumbnail-img-md {
  width: 240px;
  height: 240px;
}

div.item-thumbnail-img-lg {
  width: 300px;
  height: 300px;
}

.item-image-main {
  margin: 0;
  width: 300px;
  max-height: 300px;
  overflow: hidden;
  position: relative;
}

.item-image div.item-tags {
  position: absolute;
  top: 2px;
  left: 2px;
}

.item-image-subs {
  padding: 0.5em 0;
  display: flex;
  flex-wrap: wrap;
}

.item-image-sub .item-thumbnail-img {
  margin: 4px 4px 0 0;
  width: 74px;
  height: 74px;
  line-height: 70px;
  overflow: hidden;
}

div.item-add-favorite,
div.item-add-itemrequest {
  text-align: right;
  padding: 0.25em 0;
}

div.item-add-favorite button.btn-add-favorite-item,
div.item-add-itemrequest button.btn-add-itemrequest {
  font-size: 1rem;
  background: #aaa;
  border: 1px solid #999 !important;
  box-shadow: none;
  color: #fff !important;
  border: none;
}

div.item-add-favorite button.btn-remove-favorite-item,
div.item-add-itemrequest button.btn-remove-itemrequest {
  font-size: 90%;
  width: 12em;
  background: #ccc;
  border: 1px solid #999 !important;
  box-shadow: none;
  color: #fff !important;
  border: none;
}

div.item-variation ul li {
  display: inline-block;
  padding: 0.5em;
}

div.item-variation ul li>div.item {
  text-align: center;
  width: 100px;
}

div.item-variation ul li>div.item div.item-text {
  padding: 0.5em 0;
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

form.item-purchase div.item-options {
  text-align: left;
  width: 100%;
}

form.item-purchase div.item-options div.item-option {
  padding: 1em;
  margin: 1em 0;
  border: 1px solid #ccc;
}


form.item-purchase div.item-options div.item-option-name {
  font-size: 0.8rem;
  font-weight: bold;
}

form.item-purchase div.item-options div.item-option-dsc {
  font-size: 0.8rem;
  color: #999;
}

form.item-purchase div.item-options div.item-option-ctrl {
  padding: 0.5em 0;
}

form.item-purchase div.item-options div.item-option-ctrl select,
form.item-purchase div.item-options div.item-option-ctrl input[type=text] {
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5em;
  margin-bottom: 0.5em;
}
/*-- Item End -- */

/*-- Item List Common--*/
ul.item-list li.item-list-each {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 1em 0;
  border-bottom: 1px dotted #ccc;
}

ul.item-list li.item-list-each:last-child {
  border-bottom: none;
  padding-bottom: 4em;
}

ul.item-list li.item-list-each div.item-dtl {
  width: 90%;
  margin: 0 auto;
}

ul.item-list li.item-list-each div.item-dtl div.article-prices {
  text-align: right;
  padding: 1em 0;
  font-size: 12px;
}

/*-- Item List Common End--*/


/*-- Category Item List --*/

div.category-item-list div.item-list-heading {
  display: flex;
  flex-wrap: wrap;
  padding: 1em 0 2em;
}

div.category-item-list div.item-list-heading>div.page-position {
  margin-left: auto;
  color: #888;
}

/*-- Category Item List End --*/


/*-- CartItem Reponse --*/
div.cart-add-item-resp {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1500;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

div.cart-add-item-resp>div {
  width: 90%;
}

div.cart-add-item-resp div.checkout-cart {
  padding: 1em;
  background-color: #fff;
  text-align: left;
  position: relative;
}

div.cart-add-item-resp div.checkout-cart>ul {
  height: 300px;
  overflow-y: scroll;
}

div.cart-add-item-resp div.checkout-cart>ul>li {
  display: flex;
  padding: 0.5em 0;
}

div.cart-add-item-resp div.checkout-cart>ul>li div.item-thumbnail-img-wrapper {
  flex: 1;
}

div.cart-add-item-resp div.checkout-cart>ul>li div.cart-item {
  flex: 2;
}

div.cart-add-item-resp div.checkout-cart>ul>li div.cart-item span.error {
  color: #c00;
}

div.cart-add-item-resp div.checkout-cart div.total {
  text-align: right;
}

div.cart-add-item-resp div.checkout-cart div.controls {
  text-align: right;
}

div.cart-add-item-resp div.checkout-cart div.closer {
  position: absolute;
  top: 0.5em;
  right: 1em;
}

/*-- CartItem Reponse End --*/



/*-- SignIn/Up --*/
div.signin-form div.reminder {
  text-align: right;
  font-size: 12px;
  padding: 0 2em;
}

/*-- SignIn/Up End --*/



/*-- Order Entry --*/
div.order-process-main {
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

#order-entry div.panel {
  border: 1px solid #ccc;
  margin: 0.5em;
  position: relative;
}

#order-entry div.panel div.header {
  background-color: #eee;
  padding: 1em;
}

#order-entry div.panel div.body {
  position: relative;
  padding: 1em;
}

#order-entry div.panel .control {
  position: absolute;
  top: 0.75em;
  right: 1em;
  text-align: right;
}

#order-entry div.panel .control .btn {
  padding: 4px 8px;
  margin: 0 0 4px;
}

#order-entry div.itemdetails {
  padding: 1em;
}

#order-entry div.itemdetails div.itemdetails-caption {
  padding: 0 1em;
}

#order-entry div.itemdetails div.update-itemoptions {
  text-align: right;
}

#order-entry div.itemdetails div.update-itemoptions a {
  color: #38c;
  text-decoration: underline;
}

#order-entry div.panel .footer {
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
}

#order-entry div.shipments div.shipment {}

#order-entry #order-entry-order {
  display: flex;
  flex-wrap: wrap;
}

#order-entry #order-entry-order div.panel {
  flex: 1;
  min-width: 320px;
}

#order-entry #order-entry-order div.payment {
  flex: 1;
}

#order-entry div.shipments div.shipment-entry-adr {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4px;
}

#order-entry div.shipments div.shipment-entry-adr div.consignee {
  flex: 1;
}

#order-entry div.shipments div.shipment-entry-adr div.sender {
  flex: 1;
}

#order-entry .shipment ul.delivery-service-conf {
  margin: 0 1em;
}

#order-entry .shipment ul.delivery-service-conf li h4 {
  display: block;
  flex: 1;
}

#order-entry .shipment ul.delivery-service-conf li h4+div {
  flex: 2;
  padding-left: 1em;
}

#order-entry .shipment .update-present-option {
  position: relative;
  height: 2em;
}

#order-entry div.checkout {
  padding: 4em 1em 4em;
  text-align: center;
}

#order-entry div.order-cancel-edit {
  text-align: right;
}

div.main-payment-method div.wrapper {
  max-width: 620px;
  margin: 0 auto 2em;
}

div.main-payment-method div.method-name {
  font-size: 150%;
  padding: 1em;
  width: 300px;
  border-radius: 4px;
  margin: 0 auto;

}

div.main-payment-method div.wrapper>div {
  text-align: center;
}

div.payment-method-list {
  position: relative;
}

div.payment-method-list div.payment-method {
  padding: 8px;
}

div.payment-method-list div.payment-method div.pm-wrapper {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-heading {}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-label {
  font-size: 12px;
  font-weight: bold;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-heading div.pm-submit {
  text-align: center;
  padding: 8px;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-form {
  padding-bottom: 2em;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-body {}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-body div.pm-description {
  font-size: 12px;
  padding: 1em;
  line-height: 1.5;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-trailing {
  text-align: center;
}

button.btn-payment {
  width: 300px;
  padding: 1em;
  margin: 0.5em;
}

div.payment-method-list div.payment-method div.pm-wrapper div.pm-use-as-default {
  font-size: 12px;
}

div.order-cancel-edit {
  text-align: right;
  padding: 1em;
  font-size: 14px;
}

div#detach-coupons {
  text-align: right;
}

div#detach-coupons h3 {
  text-align: left;
  font-size: inherit;
  margin-bottom: 0.5em;
}

div#detach-coupons div.wrapper {
  background-color: #f3f3f3;
  border-radius: 6px;
  padding: 0.5em 1em;
  max-width: 400px;
  margin-left: auto;
}

div#detach-coupons ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

div#detach-coupons ul span.cpn-exp span.date {
  padding-left: 1em;
  color: #f90;
}

div.select-delivery-service ul.ds-available {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

div.select-delivery-service ul.ds-available li {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0.5em 1em;
  width: 300px;
  text-align: center;
}

div.select-delivery-service ul.ds-available li label {
  display: block;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.5em 1em;
}

div.select-delivery-service ul.ds-available li label span.ds-avl-fat {
  display: block;
  font-size: 11px;
  color: #666;
}

div.select-delivery-service ul.ds-available li div.ds-desc {
  padding: 1em;
  background-color: #fff;
  font-size: 11px;
  text-align: left;
}


/*-- Order Entry End --*/

/*-- Amount Table --*/
div.amount-table {
  max-width: 400px;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: 0;
  padding: 0.5em 1em;
  background-color: #fff;
  border-radius: 2px;
}

#order-total .amount-table {
  @media #{g.$sp} {
    padding-inline: 0;
  }
}

div.amount-table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

div.amount-table th {
  text-align: left;
  font-weight: normal;
}

div.amount-table td {
  font-weight: bold;
  padding: 0.5em 0;
}

div.amount-table th,
div.amount-table td {
  border-bottom: 1px solid #999;
}

div.amount-table td.amount {
  text-align: right;
}

div.amount-table .service-payment_commission .service-label {
  display: none;
}

div.amount-table tr.total {
  color: #a00;
}

div.amount-table div.msg-paymentfee-excluded {
  font-size: 12px;
  color: #c00;
  margin-top: 8px;
}

/*-- Amount Table End --*/

/*-- PG Launcher  --*/
div.pg-auth-launcher-note {
  padding: 1em;
  color: #e73;
}

div.launcher-form {
  padding: 1em 0 2em;
  text-align: center;
}

div.launcher-form {}

/*-- PG Launcher End --*/



/*-- Bread Crumbs -- */
div.breadcrumbs {
  margin-bottom: 1em;
}

div.breadcrumbs>ul {
  padding: 0;
}

div.breadcrumbs>ul li {
  display: inline-block;
}

div.breadcrumbs>ul li.parent:before,
div.breadcrumbs>ul li.end:before {
  content: " > "
}

/*-- Bread Crumbs End -- */


/*-- Well --*/
div.wl h1+div,
div.wl h2+div,
div.wl h3+div,
div.wl h4+div,
div.wl h5+div,
div.wl h6+div {
  padding: 0 1em 1em;
}

div.wl h1 {
  font-size: 300%;
}

div.wl h2 {
  font-size: 250%;
}

div.wl h3 {
  font-size: 200%;
}

div.wl h4 {
  font-size: 175%;
}

div.wl h5 {
  font-size: 150%;
  font-weight: bold;
}

div.wl h5+div {}

div.wl div.wl-img {
  width: 40%;
  margin: 1em auto;
  padding: 4px;
  border: 1px solid #eee;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
}

div.wl div.wl-img-left {
  float: left;
}

div.wl div.wl-img-right {
  float: right;
}

div.wl div.wl-img img {
  width: 100%;
}

div.wl h2,
div.wl h3,
div.wl h4 {
  clear: both;
}

/*-- Well End --*/


/*-- for slick slider -- */
div.sc-carousel-slider {
  padding: 0 0 40px;
  width: 900px;
  margin: 0 auto;
}

div.sc-carousel-slider div.sc-carousel-slick {
  display: none;
  height: 420px;
}

div.sc-carousel-slider div.sc-carousel-slick.slick-initialized {
  display: block;
}

div.sc-carousel-slider ul.sc-carousel-slick-nav li img {
  height: 80px;
  margin: 0 auto;
}

div.sc-carousel-slider {
  width: 100%;
}

/*-- for slick slider end-- */


/*-- for Journal History -- */
div.order div.panel div.panel-heading {
  padding: 8px 16px;
}

div.order div.panel div.panel-body div.delivery>div {
  padding-bottom: 4px;
}

div.order div.panel div.panel-body div.delivery>div>h6 {
  display: inline-block;
  min-width: 120px;
  padding-right: 1em;
}

div.order div.panel div.panel-body div.delivery>div>div {
  display: inline-block;
}

/*-- for Journal History end-- */

/*-- AC My Coupon List --*/
div.yourcoupons {
  padding: 1em 0;
}

div.yourcoupons div.leading {
  text-align: center;
  border-bottom: 1px solid #ccc;
  font-size: 18px;
}

div.yourcoupons div.yourcoupon-list {
  display: flex;
}

div.yourcoupons div.coupon {
  padding: 4px;
  position: relative;
}

div.yourcoupons div.coupon div.coupon-props {
  border: 1px solid #999;
  border-radius: 4px;
}

div.yourcoupons div.coupon div.coupon-props div.label {
  text-align: center;
  padding: 4px 8px;
}

div.yourcoupons div.coupon div.coupon-props span.code {
  display: inline-block;
  background-color: #0c0;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
}

div.yourcoupons div.coupon div.coupon-props div.expiry {
  text-align: right;
  font-size: 12px;
  padding: 2px 8px;
  color: darkorange;
}

div.yourcoupons div.coupon div.coupon-props div.description {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.3;
  color: #666;

}

div.yourcoupons div.coupon-clearing-date {
  text-align: center;
  padding: 2px;
}

div.yourcoupons div.coupon-clearing-date span {
  font-size: 11px;
  color: #900;
  background-color: #fee;
  border: 1px solid #966;
  display: inline-block;
  padding: 2px 1em;
  border-radius: 4px;

}

/*-- AC My Coupon List End--*/


/*-- Present Select --*/
div.form-present div.present-list>ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

div.form-present div.present-list>ul li {
  padding: 0 0.5em;
  margin: 0.5em;
  max-width: 400px;
  min-width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
}

div.form-present div.present-list div.present {
  padding: 1em;
}

div.form-present div.present-list div.present div.property {
  padding: 1em;
}

div.form-present div.present-list div.present div.property span.code {
  color: #999;
  padding: 0 0.5em;
  border: 1px solid #999;
  border-radius: 2px;
  margin: 0 1em;
}

div.form-present div.present-list div.present div.property span.name {
  display: inline;
  font-size: 110%;
}

div.form-present div.present-list div.present div.property div.description {
  padding: 1em 0;
  font-size: 90%;
  color: #996;

}

div.form-present div.present-list div.present div.present-option div.no-option {
  color: #ccc;
  font-size: 90%;
}

/*-- Present Select End--*/


/*-- Points History --*/
div.points-history div.points-summary {
  display: flex;
}

div.points-history div.points-summary div.points-amount {
  flex: 1;
  text-align: center;
}

div.points-history div.points-summary div.points-amount div.wrapper {
  padding: 1em;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 6px;
}

div.points-history div.points-summary div.points-amount-available div.wrapper span.value {
  color: #0c0
}

table.points-detail {
  width: 90%;
  margin: 1em auto;
  border-collapse: collapse;
}

table.points-detail th {
  font-weight: normal;
  border-bottom: 1px solid #999;
}

table.points-detail td {
  border-bottom: 1px solid #ccc;
  padding: 0.5em;
  text-align: center;
}

table.points-detail td span.pd-type {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 2px 4px;
}

table.points-detail td.pdd-type,
table.points-detail td.pd-note {
  text-align: left;
}

table.points-detail tr.pd-type-confirmed span.pd-type {
  border-color: #0c0;
  color: #0c0;
}

table.points-detail tr.pd-type-pending span.pd-type {
  border-color: #f90;
  color: #f90;
}

table.points-detail tr.pd-type-redeemed span.pd-type {
  border-color: #c00;
  color: #c00;
}

table.points-detail tr.pd-type-redeemed td.pd-amount {
  color: #c00;
}

table.points-detail tr.pd-type-redeemed td.pd-amount span:before {
  content: "-"
}

/*-- Points History End--*/

/*-- RP Subscriptions --*/
div.rp-subscriptions {}

div.rp-subscriptions div.panel div.panel-body div.wrapper {
  display: flex;
  flex-wrap: wrap;
}

div.rp-subscriptions div.panel div.panel-body div.wrapper>div {
  min-width: 200px;
  flex: 1;
}

div.rp-subscriptions div.panel div.panel-body div.message-wrapper {
  padding: 1em 0.5em;
  text-align: center;
}

div.rp-subscriptions div.panel div.panel-body div.message-wrapper div.error {
  border: 1px solid #c99;
  color: #c44;
  background-color: #fee;
  padding: 1em;
  display: inline-block;
  border-radius: 4px;

}

div.rp-subscriptions div.panel div.panel-footer span.note {
  font-size: 90%;
  color: #944;
}

div.rp-subscriptions-nav-list ol {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 0.5em 0;
}

div.rp-subscriptions-nav-list ol li>* {
  margin: 0 0.5em;
  padding: 0.5em 1em;
  border: 1px solid #ccc;
  background-color: #eee;
}

div.rp-subscriptions-nav-list ol li.active>span {
  background-color: #fff;
  border-bottom: 1px solid #fff;
}

/*-- RP Subscriptions End --*/

/*-- Account Message History --*/
div.account-message-history div.message-body {
  white-space: pre-wrap;
  max-height: 20em;
  overflow-y: scroll;
}

/*-- Account Message History End --*/


/*-- Display --*/
div.display-box ul.item-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

div.display-box ul.item-display li {
  padding: 0 1em 1.5em;
}

div.display-box ul.item-display li>div {
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
}

div.display-box ul.item-display li>div.text {
  white-space: nowrap;
}

div.display-box ul.item-display li div.display-xs {
  width: 100px;
}

div.display-box ul.item-display li div.display-sm {
  width: 152px;
}

div.display-box ul.item-display li div.display-md {
  width: 242px;
}

div.display-box ul.item-display li div.display-lg {
  width: 302px;
}

div.display-box ul.item-display li div.item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5em 0;
}

div.display-box ul.item-display li div.item-desc {
  font-size: 0.75em;
}

div.display-box ul.item-display li div.thumbnail {
  width: 150px;
}

div.display-box ul.item-display li div.catalogue {
  max-width: 400px;
}

/*-- Display End--*/


/*-- News Entry (List,Archive) --*/
div.news-entries {}

div.news-entries div.news-entry-with-icon {
  border-top: 1px solid #ccc;
  padding-top: 1em;
  padding-bottom: 1em;
}

div.news-entries div.news-entry-has-body-link:hover {
  cursor: pointer;
}

div.news-entries div.news-entry {
  display: flex;
  border-top: 1px solid #ccc;
}

div.news-entry div.entry-icon.inline {
  float: left;
  padding: 0 1em 0.5em 0;
}

div.news-entry div.entry-icon span.icon img {
  width: 150px;
}

div.news-entry div.entry-icon span.no-image {
  display: block;
  width: 150px;
  height: 150px;
  border: 1px solid #ccc;
  background-color: #fefefe;
  text-align: center;
  line-height: 150px;
}

div.news-entry div.entry-icon span.no-image:after {
  content: "No Image";
  display: block;
  color: #999;
  font-size: 14px;
  font-family: Arial;
}

div.news-entries div.news-entry div.entry-text {
  padding: 0 1em;
}

div.news-entries div.header {
  display: flex;
  line-height: 24px;
}

div.news-entries div.header div.meta {
  display: flex;
}

div.news-entries div.header div.meta div.release-date {
  margin-right: 1em;
}

div.news-entries div.header div.news-type {}

div.news-entries div.header div.news-type span {
  margin: 0 auto;
  background-color: #666;
  color: #fff;
  padding: 0.25em 0.5em;
  white-space: nowrap;
  margin-right: 1em;
}

div.news-entries div.body {
  padding: 1em 0;
  font-size: 90%;
  line-height: 175%;
}

div.news-images {
  display: flex;
}

div.news-images div.news-image {
  max-width: 300px;
  padding: 0.5em;
}

div.news-images div.news-image img {
  max-width: 100%;
}

/*-- News Entry (List,Archive) End --*/

/*-- News Entry (Archive) --*/
div.news-archive div.news-entry div.news-entry-subject {
  font-size: 28px;
}

div.news-archive div.news-entry div.release-date {
  color: #666;
}

/*-- News Entry (Archive) End--*/

/*-- Item Review --*/
/*-- Item Review  ----------------------------------------------------------------------*/
.item-reviews .progress-bar {
  background: linear-gradient(#fc9, #FFA32A);
}

.rv-stars {
  margin-right: 1em;
  color: #FFA32A;
  text-shadow: 1px 2px 4px rgba(256, 64, 0, 0.4);
}

.rv-star {
  font-size: 14px;
}

.rv-star-fill:before {
  content: "★";
}

.rv-star-empty:before {
  content: "☆";
}

.item-reviews {
  clear: both;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1em;
  margin: 1em 0;

}

.customer-review {
  border-top: 1px solid #ccc;
  padding: 1em 2em;
}

.customer-review>.heading {
  height: 24px;
  ;
}

.customer-review>.heading>div {
  float: left;
  height: 24px;
  line-height: 24px;
}

.customer-review .heading .reviewer {
  color: #999;
  font-size: 80%;
}

.customer-review .heading .subject {
  font-weight: bold;
  margin-right: 1em;
}

.customer-review .heading .written {
  margin-left: 2em;
  color: #999;
  font-size: 80%;
}

.customer-review .heading .item-link {
  padding-left: 1em;
}

.customer-review .content {
  font-size: 90%;
  word-wrap: break-word;
}

.customer-review div.images {
  display: flex;
  flex-wrap: wrap;
}

.customer-review div.images div.image {
  padding: 4px;
}

.customer-review div.control {
  display: flex;
}

.customer-review div.control>div {
  padding: 0.5em;
}

.rv-summary {
  float: left;
}

.review-avg {
  color: #99b;
}

.review-count {
  color: #99b;
}

.rv-histgram {
  padding-left: 220px;
}

.rv-histgram-each {
  position: relative;
  height: 21px;
}

.rv-histgram-each .rate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
}

.rv-histgram-each .count {
  position: absolute;
  top: 0;
  left: 100px;
}

.rv-histgram-each .percentage {
  position: absolute;
  top: 0;
  left: 120px;
  width: 100px;
}

.write-review .article {
  margin: 0 1em;
  float: left;
  width: 200px;
}

.write-review-form .rv-stars {
  font-size: 200%;
  cursor: pointer;
}

.write-review-form #rrl {
  font-size: 14px;
}


.review-smr {
  /*-- Review Summary in ItemList --*/
}

.review-smr div.count {
  font-size: 75%;
  color: #999;
}

/*-- Item Review End --*/

div.item-review-comments ul.comments>li div.comment {
  padding-left: 1em;
  padding-bottom: 1em;
}

div.item-review-comments ul.comments>li div.comment>div.heading {
  display: flex;
  align-items: center;
}

div.item-review-comments ul.comments>li div.comment>div.heading div.date {
  margin-left: 1em;
}

div.poster div.nickname {
  display: flex;
  align-items: center;
}

div.poster div.avatar {}

div.poster div.avatar img {
  width: 50px;
}

/*-- Person --*/
div.person {
  margin: 1em 0;
  padding: 0;
  border: 1px dotted #ccc;
}

div.person-label {
  font-weight: 700;
  border-bottom: 1px dotted #ccc;
  background-color: #f5f5f5;
  padding: 0.5em;
}

div.person-contents {
  padding: 0.5em;
}

div.person-contents div.kana,
div.person-contents div.place,
div.person-contents div.phone-number,
div.person-contents div.email {
  font-size: 80%;
}

div.person-contents div.locale,
div.person-contents div.kana {
  color: #ccc;
  font-style: oblique;
}

div.person-contents div.place>div {
  display: inline;
}

/*-- Person END --*/
/*-- Order --*/
div.panel-order div.panel-heading {
  display: flex;
  background-color: #f5f5f5;
}

div.panel-order div.panel-heading>div {
  flex: 1;
}

span.status {
  border: 1px solid #444;
  padding: 2px 4px;
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
}

span.status-canceled {
  border-color: #e00;
  color: #e00;
}

span.status {
  border-color: #09f;
  color: #09f;
}

.order-status-COMPLETED span.status,
.shipment-status-COMPLETED span.status {
  border-color: #0c0;
  color: #0c0;
}

div.shipment-header {
  text-align: center;
}

div.shipment-header>div {
  margin: 4px;
  border-radius: 3px;
}

div.shipment-delivery {
  margin-top: 1em;
  border-top: 1px solid #ccc;
  padding: 1em 0;
}

div.panel-order div.panel-body {}

div.panel-order div.panel-body div.order-shipments {}

div.shipment {
  padding-bottom: 2em;
  margin-bottom: 2em;
  border-top: 2px solid #ccc;
}

div.shipment div.shipment-header {
  display: flex;
  flex-wrap: wrap;
}

div.shipment div.shipment-header>div {
  flex: 1;
}

div.shipment div.shipment-delivery-address {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

div.shipment div.shipment-delivery-address>div {
  min-width: 300px;
  flex: 1;
  margin: 0.5em;
}

div.shipment div.shipment-contents div.delivery {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

div.shipment div.shipment-contents div.delivery>div {
  flex: 1;
}

div.shipment div.shipment-contents div.delivery table {
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
}

div.shipment div.shipment-contents div.delivery table caption {
  padding: 0.5em;
}

div.shipment div.shipment-contents div.delivery table tr>* {
  padding: 0.5em;
  border-bottom: 1px dotted #ccc;
}

div.shipment div.shipment-contents div.delivery table th {
  text-align: left;
  font-weight: normal;
  background-color: #f5f5f5;
  min-width: 150px;
}

div.shipment div.shipment-contents div.delivery table td {
  min-width: 150px;
}

div.order div.order-buttons {
  padding: 0 1em;
  display: flex;
}

div.order div.order-buttons>div {
  padding: 0 1em;
}

div.order div.order-buttons>div:first-child {
  margin-left: auto;
}

/*-- Order End --*/

/*-- Account Menu --*/
ul.account-menu-list li div.note {
  margin: 0.5em 0 1em;
}

ul.account-menu-list li div.custompropkeys {
  color: #999;
  font-size: 11px;
}

/*-- Account Menu End--*/

/*-- SC Resume Panel --*/
div.ac-resume-checkout {
  position: fixed;
  top: 10%;
  left: 1em;
  z-index: 1000;
  background-color: rgba(240, 255, 240, 0.9);
  border: 1px solid #0a0;
  padding: 1em;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 12px;
}

div.sc-resume-checkout div.resume-checkout-msg {
  color: #090;
}

div.sc-resume-checkout div.resume-checkout-content {
  text-align: center;
}

div.sc-resume-checkout div.resume-checkout-btn {
  text-align: center;
  padding: 0.5em 1em;
}

/*-- SC Resume Panel End--*/

/*-- One-time message --*/
div.onetime-message {
  color: #c00;
  padding: 1em;
}

div.xp-onetime-message {
  position: fixed;
  z-index: 2000;
  top: 0;
  width: 100%;
  padding: 0.5em 1em;
  color: #a00;
  background-color: #ffe;
  border-bottom: 1px solid #a00;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/*-- One-time message End --*/
/*-- Trailer Link --*/
div.trailer-link {
  padding: 2em 0;
  text-align: right;

}

/*-- Trailer Link End--*/

/*-- ExRP --*/
div.exrp-subscription>div.panel {
  border: 1px solid #ccc;
}

div.exrp-subscription div.subscription-summary {
  display: flex;
  padding: 0.5em;
}

div.exrp-subscription div.subscription-summary>div {
  flex: 1;
  margin: 2px;
  border: 1px solid #ddd;
  border-radius: 2px;
}

div.exrp-subscription div.subscription-summary>div h4 {
  display: block;
  font-weight: normal;
  background-color: #eee;
  border-bottom: 1px solid #ddd;
  margin: 0;
  padding: 1em 0;
}

div.exrp-subscription div.subscription-summary>div h4+div {
  padding: 1em;
}


div.exrp-subscription div.subscription-details {}

div.exrp-subscription div.subscription-details ul.course-details li {
  display: flex;
  flex-wrap: wrap;
}

div.exrp-subscription div.subscription-details ul.course-details li div.detail-id {
  width: 100px;
}

div.exrp-subscription div.subscription-details ul.course-details li div.course {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.exrp-subscription div.subscription-details ul.course-details li div.config {
  width: 100px;
  padding: 0 1em;
  color: #0a0;
}

div.exrp-subscription div.subscription-details ul.course-details li div.course-dsc {
  color: #966;
}


.exrp-shipment span.mylabel {
  font-size: 11px;
  display: block;
  color: #900;
}

ul.exrp-shipments li.exrp-shipment {
  border: 1px solid #ccc;
  margin-bottom: 2em;
}

.exrp-shipment div.content,
.exrp-shipment div.properties {
  display: flex;
}

.exrp-shipment div.content>div,
.exrp-shipment div.properties>div {
  flex: 1;
  text-align: center;
  padding: 0.5em 1em;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  margin: 0.5em;
  border-radius: 4px
}

/*-- ExRP END --*/

/*-- Points to Grant --*/
div.points-not-positive {
  display: none;
}

/*-- Points to Grant END--*/

/*-- Async Cart --*/
div.ac-cart div.cart-contents {
  border: 1px solid #999;
  font-size: 0.8em;
}

div.ac-cart div.cart-contents-heading {
  text-align: center;
}

div.ac-cart div.cart-contents-heading div.main {
  padding: 1em;
}

div.ac-cart div.cart-contents-heading div.checkout button {
  margin: 0 auto 1em;
  width: 90%;
}

div.ac-cart div.cart-contents-heading a {
  color: inherit;
  text-decoration: none;
}

div.ac-cart div.cart-contents ul.cart-details {
  display: flex;
  flex-wrap: wrap;
}

div.ac-cart div.cart-contents li.cart-detail {
  width: 100%;
  max-width: 300px;
  padding-bottom: 1em;
}

div.ac-cart div.cart-contents li.cart-detail div.image {
  padding-top: 1em;
}

div.ac-cart div.cart-contents li.cart-detail div.text .desc {
  text-align: center;
  padding: 0 1em;
}

div.ac-cart div.cart-contents li.cart-detail div.text .desc .code {
  font-size: 0.5rem;
}

div.ac-cart div.cart-contents li.cart-detail div.text div.options ul {
  font-size: 0.5rem;
  text-align: center;
}

div.ac-cart div.cart-contents li.cart-detail div.control {
  display: flex;
  padding: 0 1em;
}

div.ac-cart div.cart-contents li.cart-detail div.control div.remove {
  cursor: pointer;
  white-space: nowrap;
}

div.ac-cart div.cart-contents li.cart-detail div.control>div {
  padding: 0.25em 0.5em;
  white-space: nowrap;
}

/*-- Async Cart END--*/

/*-- Tracking GW --*/
div.delivery-tracker-gw {
  position: relative;
}

div.delivery-tracker-gw div.delvery-tracking {
  border: 1px solid #ccc;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 2em;
  background-color: #fff;
  z-index: 1000;
}

div.delivery-tracker-gw div.delvery-tracking div.heading {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 1em;
}

div.delivery-tracker-gw div.delvery-tracking div.main {
  padding: 1em;
}

div.delivery-tracker-gw div.delvery-tracking div.main ol li {
  position: relative;
  padding: 0 0 1em;
}

div.delivery-tracker-gw div.delvery-tracking div.main ol li div.location-name {
  background-color: #ccc;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0 auto;
  line-height: 50px;
  text-align: center;
  white-space: pre;
  font-size: 13px;
}

div.delivery-tracker-gw div.delvery-tracking div.main ol li div.dt-timestamp {
  font-size: 11px;
}

div.delivery-tracker-gw div.delvery-tracking div.main ol li.pre-ship div.location-name {
  background-color: #9cc;
}

div.delivery-tracker-gw div.delvery-tracking div.main ol li.undone div.location-name {
  background-color: #fff;
  border: 1px solid #ccc;
}

div.delivery-tracker-gw div.delvery-tracking div.main div.contents {
  padding: 1em;
}

/*-- Tracking GW END--*/

/*-- ExAuthenticator --*/
div.exag-signin {
  text-align: center;
}

/*-- ExAuthenticator END--*/

/*-- ExAuthenticator --*/
div.exag-signin {
  text-align: center;
}

/*-- ExAuthenticator END--*/

/*-- ItemPurchase PropVar --*/
div#item-purchase-propvar div.dominant-properties>ul>li {
  display: flex;
}

div#item-purchase-propvar div.dominant-properties>ul>li {
  border-top: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0.5em 0;
  padding-top: 1em;
}

div#item-purchase-propvar div.dominant-properties>ul>li div.item-prop-dominant {
  min-width: 150px;
  text-align: center;
}

div#item-purchase-propvar div.articles>ul>li.article {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
}

div#item-purchase-propvar div.articles>ul>li.article>div {
  min-width: 150px;
}

div#item-purchase-propvar div.articles>ul>li.article:last-child {
  border-bottom: none;
}

div#item-purchase-propvar div.offerings>ul>li.offering {
  display: flex;
}

div#item-purchase-propvar div.offerings>ul>li.offering form.item-purchase {
  padding: 0;
}

div#item-purchase-propvar div.offerings>ul>li.offering div.btn-group-purchase {
  padding: 0 1em;
  ;
}

div#item-purchase-propvar div.prop-value .code {
  padding: 0 1em;
  color: #aaa;
}

/*-- ItemPurchase PropVar END--*/

/*-- ItemProperties --*/
ul.item-properties {
  margin-bottom: 1em;
}

li.item-property {
  padding: 0.5em 0.5em 0.5em 0;
}

li.item-property {
  display: inline-block;
  padding: 2px 4px;
  font-size: 0.8em;
}

li.item-property div.wrapper-no-contents {
  border: 1px solid #444;
  border-radius: 2px;
  padding: 4px;
}

li.item-property div.wrapper-no-contents>div {
  display: inline;
}

li.item-property .code:before {
  content: "(";
}

li.item-property .code:after {
  content: ")";
}

/*-- ItemProperties END--*/

/*-- VariationProperties --*/
div.item-variation-properties {}

div.item-variation-properties div.varprop {
  margin-bottom: 1em;
}

div.item-variation-properties div.varprop div.varprop-key {
  background-color: #eee;
  padding: 0.5em;
  text-align: center;

}

div.item-variation-properties div.varprop div.varprop-key .code:before {
  content: " ("
}

div.item-variation-properties div.varprop div.varprop-key .code:after {
  content: ")"
}

div.item-variation-properties div.varprop ul.varprop-values {
  display: flex;
  font-size: 0.75em;
}

div.item-variation-properties div.varprop ul.varprop-values li {
  width: 120px;
  padding: 0.5em;
  text-align: center;
}

div.item-variation-properties div.varprop ul.varprop-values li:hover {
  cursor: pointer;
}

div.item-variation-properties div.varprop ul.varprop-values li>div.wrapper {
  padding: 0.5em;
  height: 100%;
  border: 1px solid transparent;
}

div.item-variation-properties div.varprop ul.varprop-values li.out-of-stock>div.wrapper {
  color: #aaa;
  background-color: #f5f5f5;
}

div.item-variation-properties div.varprop ul.varprop-values li.out-of-stock img {
  opacity: 0.3;
}

div.item-variation-properties div.varprop ul.varprop-values li.active>div.wrapper {
  border: 1px solid #ccc;
}

div.item-variation-properties div.varprop ul.varprop-values li div.varprop-value-desc {
  font-size: 0.8em;
  text-align: left;
}

div.item-variation-properties div.varprop ul.varprop-values li span.varprop-stock-msg.instock {
  color: #080;
}

div.item-variation-properties div.varprop ul.varprop-values li span.varprop-stock-msg.nostock {
  color: #c66;
}

div.variation-properties ul.vp-labels li.variation-property {
  display: inline-block;
  font-size: 0.75em;
  background-color: #369;
  color: #fff;
  padding: 4px 8px;
  margin-right: 1em;
  margin-top: 0.5em;
}

div.variation-properties ul.vp-labels li.variation-property .code:before {
  content: " (";
}

div.variation-properties ul.vp-labels li.variation-property .code:after {
  content: ")";
}

div.variation-properties ul.vp-labels li.variation-property .vp-key:after {
  content: ":"
}

div.variation-properties ul.vp-labels li.variation-property div.wrapper>div {
  display: inline-block;
}

div.variation-properties ul.vp-descriptions {
  margin: 1em 0;
  font-size: 0.8em;
}

div.variation-properties ul.vp-descriptions li div.label {
  display: inline-block;
}

div.variation-properties ul.vp-descriptions li div.desc {
  display: inline-block;
  padding-left: 1em;
}

/*-- VariationProperties END --*/


/*-- Order Journal History --*/
div.panel-journal {
  border: 1px solid #ccc;
  margin-bottom: 2em;
}

div.panel-journal div.panel-heading {
  background-color: #eee;
  display: flex;
}

div.panel-journal div.panel-heading>div {
  flex: 1;
}

/*-- Order Journal History END--*/

/*-- Common Input-Submit --*/
div.input-submit {
  white-space: nowrap;
  font-size: 11px;
}

div.input-submit input {
  margin: 0;
  width: 120px;
}

div.input-submit button {
  margin: 0;
}

/*-- Common Input-Submit END--*/

/*-- SubmitBox --*/
div.submit-box {
  text-align: center;
  background-color: #eee;
  padding: 1em 0;
}

div.submit-box .btn {
  font-size: 18px;
}

div.submit-box div.note {
  font-size: 12px;
}

/*-- SubmitBox END--*/

/*-- OrderEntryControl --*/
div.sc-orderentry-control {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: linear-gradient(rgba(0, 0, 0, 0.6), #000);
  color: #fff;
}

div.ac-orderentry-control {
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
}

div.ac-orderentry-control div.main {
  min-width: 300px;
}

div.ac-orderentry-control div.main div.heading {
  font-size: 18px;
  color: #f82
}

div.ac-orderentry-control div.main div.description {
  font-size: 12px;
}

div.ac-orderentry-control div.summary {
  display: flex;
  align-items: center;
  padding: 0 1em;
  color: #f33;
  font-weight: bold;
  min-width: 100px;
  margin-left: auto;
}

div.ac-orderentry-control div.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

div.ac-orderentry-control div.controls>div {
  padding: 0 0.5em
}

div.sc-orderentry-control div.not-verified {
  text-align: center;
  padding: 1em 0;
}

div.sc-orderentry-control div.not-verified div.msg {
  font-size: 12px;
}

div.sc-orderentry-control div.not-verified div.signin a {
  color: #fff;
}

div.remove-one span.btn {
  font-size: 11px;
  cursor: pointer;
  color: #999;
}

div.remove-one span.btn:hover {
  text-decoration: underline;
}

div.update-admail-opt-in {
  padding: 1em;
}

div.update-admail-opt-in-note {
  font-size: 80%;
}

/*-- OrderEntryControl END--*/


/*-- RegularPurchaseOrderEntries--*/
.rp-orderentry div.panel {
  border: 1px solid #ccc;
  margin-bottom: 1em;
}

.rp-orderentry div.panel-heading div {
  height: 30px;
  line-height: 30px;
}

.rp-orderentry div.panel-heading button.btn {
  font-size: 12px;
  margin: 0;
}

.rp-orderentry div.panel-body {
  padding: 1em;
}

span.rpoe-status {
  display: inline-block;
  background-color: #ccc;
  color: #fff;
  padding: 0 1em;
  border-radius: 3px;
  font-size: 14px;
}

span.rpoe-status-WAITING_CHECKOUT {
  background-color: #0c0;
}

/*-- RegularPurchaseOrderEntries END--*/

/*-- RegularPurchaseOrderEntries END--*/

/*-- Item Custom Field --*/
div.item-custom-fields {}

div.item-custom-fields div.field-def {
  border-top: 2px solid #999;
  padding: 0.5em;
  text-align: center;
}

div.item-custom-fields div.field-def .cf-desc {
  font-size: 0.8em;
}

div.item-custom-fields div.field-entry .cf-subject {
  font-size: 1.25em;
  color: #fff;
  padding: 0.5em;
  background-color: #666;
  text-align: center;
}

div.item-custom-fields div.field-entry .cf-contents.with-body-and-file {
  padding: 1em 0;
  display: flex;
}

div.item-custom-fields div.field-entry .cf-contents .cf-body {
  padding: 1em;
}

div.item-custom-fields div.field-entry .cf-contents .cf-files {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

/*-- Item Custom Field END--*/

/*-- Return Request--*/
ol.items-to-return {}

ol.items-to-return li.item-to-return {
  margin-bottom: 1em;
  border: 1px solid #eee;
  display: flex;
}

ol.items-to-return li.item-to-return>div {
  display: flex;
  align-items: center;

}

/*-- Return Request END--*/

/*-- Calendar --*/
table.calendar th,
table.calendar td {
  text-align: center;
  padding: 0.25em;
}

table.calendar td.last-month,
table.calendar td.next-month {
  color: #ccc;
}

table.calendar .SUNDAY {
  color: red;
}

table.calendar .SATURDAY {
  color: #00c;
}

table.calendar .close,
table.calendar .unshippable {
  background-color: #eee;
}

/*-- Calendar END--*/

/*-- Cart Configurator --*/
#cart-configurator #add-destination {
  display: none;
}

#cart-configurator .toggle-add-dst {
  padding: 2em 0;
}

#cart-configurator .formrow-submit.configurator>div {
  padding: 1em;
}

#cart-configurator ul.itemdetails li {
  display: flex;
}

#cart-configurator ul.itemdetails div.qty {
  display: flex;
  align-items: center;
}

#cart-configurator ul.itemdetails div.qty select {
  font-size: 18px;
}

#cart-configurator #destination-to-add {
  background-color: #eee;
  padding: 0.5em;
}

#cart-configurator #destination-to-add ol {
  display: flex;
  flex-wrap: wrap;
}

#cart-configurator #destination-to-add ol li.dest {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  margin: 0.5em;
}

#cart-configurator #destination-to-add ol li.dest.active {
  border: 1px solid #0c0;
  background-color: #fff;
}

/*-- Cart Configurator END --*/

// ers-dev.css 追加

div.stub-contents {
  margin: 1rem;
  border: 2px solid #f82;
  padding: 4rem 1rem;
  font-size: 1.5rem;
  color: #f82;
  text-align: center;
  position: relative;
  border-radius: 0.25rem;
}

div.stub-contents.html:before {
  content: "任意のHTML";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  background-color: #f82;
  color: #fff;
  padding: 2px 4px;
}

article#container {
  max-width: var(--ers-deago-maxwidth);
  margin: 0 auto;
  overflow-x: hidden;
}


div.display-box-history_recommendation {
  margin-top: 80px;
}

div.display-box-history_recommendation .display-name {
  font-size: 24px;
  text-align: left;
}

div.display-box-history_recommendation ul.item-display {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
}

/* LP共通 | LP Common */
.lp-outer {
  padding: 80px;
}

.lp-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px solid green;
  height: 500px;
  padding: 20px;
}

.lp-contents p {
  font-size: 20px;
}

.lp-outer .show-partwork-dtl.btn {
  font-size: 24px;
  margin: 40px auto 0;
}

.lp-outer .button-wrap {
  text-align: center;
}

/* 抽選ゲーム | Game */
.game {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.game .sequential-contents {
  padding: 0;
}

.game h1 {
  border: 1px solid #333;
  padding: 80px;
  text-align: center;
  margin: 20px;
}

.game h2 {
  background-color: #494949;
  color: #fff;
  padding: 12px;
  text-align: center;
  margin: 40px 20px 20px;
}

.game h3 {
  border-bottom: 2px solid #555;
  display: inline-block;
  font-size: 20px;
  margin: 0 auto;
}

.game select {
  font-size: 20px;
  padding: 8px;
  margin: 20px 0;
}

.game ul {
  text-align-last: left;
  max-width: 700px;
  margin: 20px auto 0;
  width: 100%;
}

.game li {
  margin-top: 8px;
}

.game .input-area textarea {
  display: none;
}

.game .stab-contents {
  background-color: #f6f6f6;
  margin: 40px 20px;
  padding: 160px 0;
}

.game .subsequence-label {
  margin-top: 80px;
}

.game .subsequence-label li {
  text-align: center;
  width: 100%;
}

.game .subseq-link {
  background-color: #00ac5a;
  color: #fff;
  display: block;
  font-size: 30px;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
}

.game .message-wrap {
  font-size: 20px;
  margin: 20px;
  padding: 40px 0;
}

.game .message-wrap.win {
  color: #00ac5a;
  font-size: 36px;
  font-weight: bold;
  padding: 40px 0;
}

.game .message-wrap.lose {
  background-color: #efefef;
  padding: 80px 0;
}

.game .go-top {
  display: block;
  font-size: 18px;
  margin-top: 80px;
  text-decoration: underline;
}

.game__history-inner {
  margin: 40px 20px 20px;
}

.game__history-table {
  border-collapse: collapse;
  width: 100%;
}

.game__history-table td {
  border: 1px solid #aaa;
  padding: 8px 4px;
}

.game__history-table .game__win {
  color: #00ac5a;
  font-size: 20px;
  font-weight: bold;
  padding: 8px 0;
}

.game__win-info {
  background-color: #efefef;
  margin-top: 8px;
  padding: 8px;
}

.game .active-contents {
  text-align: center;
}

.game__win-info button,
.game .active-contents .button {
  background-color: #005c69;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  margin-top: 8px;
  text-decoration: none;
  padding: 4px 8px;
}

.game__win-info button:hover {
  opacity: 0.5;
}

.game__win-info .no-address {
  font-weight: bold;
}

.game div.no-address {
  padding: 20px 0 40px;
}

.game div.waiting {
  display: block;
}

.game div.result {
  display: none;
}

.game .waiting .message-wrap {
  color: red;
  font-size: 24px;
  animation: blink 0.5s linear 0s infinite normal;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  29.9% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.game .game-kusudama {
  height: 300px;
}

// スライダー
.slick-arrow {
  top: 45%;
  z-index: 1;
}

.slick-arrow:before {
  color: #000;
  font-size: 50px;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 40px;
}
// ers-dev.css ここまで