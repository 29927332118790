/*
  ----------------
  title style
  ----------------
*/
@mixin h2TitleBgBorder() {
  font-size: 30px;
  line-height: 1.43333333;
  white-space: nowrap;

  display: flex;
  align-items: center;

  @media all and (max-width : 768px) {
    font-size: 20px;
    line-height: 1.45;
    text-align: center;
  }

  &::before {
    @media all and (max-width : 768px) {
      margin-right: 15px;
    }
  }

  &::after {
    @media all and (min-width : 769px) {
      content: "";
      display: block;
      background-color: #d9d9d9;
      width: 100%;
      height: 1px;
      margin-left: 2.91666667vw;
    }

    @media all and (max-width : 768px) {
      margin-left: 15px;
    }
  }

  &::before,
  &::after {
    @media all and (max-width : 768px) {
      content: "";
      display: block;
      background-color: #d9d9d9;
      width: 100%;
      height: 1px;
    }
  }
}
