@use "global" as g;

/*
  ---------------
  deago-top-news | TOP Topics一覧
  ---------------
*/
#deago-top-news {
  /*==========================================
    デフォルトのスタイル削除
  ==========================================*/
  .top_news__entry_list {
    display: block;
    // overflow: visible;
  }

  .top_news__entry {
    min-width: auto;
    margin-left: 0;
  }

  .top_news__entry_cate {
    background: transparent;
    border: 0;
  }

  .top_news__entry_thumbnail {
    margin: 0;
  }

  /*==========================================
    スタイル追加
  ==========================================*/
  margin-top: 20px;

  .top_news__entry_list {
    overflow: visible;

    @media #{g.$sp} {
      padding-right: 1.52671756vw;
      padding-left: 1.52671756vw;
    }
  }

  .top_news__entry {
    background-color: g.$bg-white-primary;
    padding: 1.78117048vw 1.78117048vw 10px;
    display: flex;
    flex-direction: column;

    @media #{g.$pc} {
      padding: 12px 11px 16px;
    }
  }

  //slickが動くまで非表示
  .top_news__entry_list:not(.slick-slider) {
    height: 354px;
    text-align: center;
  }

  .top_news__entry_list:not(.slick-slider)::after {
    @include g.loadingSpinner();
    margin-top: 130px;
  }

  .top_news__entry_list:not(.slick-slider) .top_news__entry {
    display: none;
  }

  .top_news__entry_upper  {
    margin-top: 12px;
    order: 2;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3px;
  }

  // カテゴリータグ
  .top_news__entry_cate {
    @include g.tpIconCommonStyle();
    @include g.tpIconBgColor();
    order: 2;

    &.tp-news {
      @include g.tpIconBgColor(news);
    }

    &.tp-campaign {
      @include g.tpIconBgColor(campaign);
    }

    &.tp-report {
      @include g.tpIconBgColor(report);
    }

    &.tp-newitem {
      @include g.tpIconBgColor(newitem);
    }

    &.tp-others01 {
      @include g.tpIconBgColor(others01);
    }
  }

  .top_news__entry_date {
    color: g.$text-gray-primary;
    font-size: 12px;
    order: 1;

    @media #{g.$pc} {
      font-size: 15px;
    }
  }

  .top_news__entry_thumbnail {
    aspect-ratio: 1/1;
    order: 1;
  }

  .top_news__entry_link {
    margin-top: 2px;
    flex-grow: 1;
    order: 3;

    @media #{g.$pc} {
      margin-top: 6px;
    }
  }

  .top_news__entry_title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    height: 40px;

    @media #{g.$sp} {
      font-size: 12px;
      -webkit-line-clamp: 3;
      height: 36px;
    }
  }

  // slick
  .slick-track {
    // display: flex;
    margin: 0;
  }

  .slick-slide {
    height: auto;
    margin: 0 1.52671756vw;

    @media #{g.$pc} {
      margin: 0 0.86805556vw;
    }
  }

  .slick-slide > div {
    display: flex;

    @media #{g.$pc} {
      flex-wrap: nowrap;
    }

    @media #{g.$sp} {
      gap: 2.54452926vw;
    }
  }

  .slick-slide > div + div {
    @media #{g.$sp} {
      margin-top: 2.54452926vw;
    }
  }

  // arrow icon PC
  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-prev {
    left: -26px;

    @media #{g.$sp} {
      left: 0;
    }
  }

  .slick-next {
    right: -26px;

    @media #{g.$sp} {
      right: 0;
    }
  }

  .slick-prev,
  .slick-next {
    width: 16px;
    height: 32px;

    @media #{g.$sp} {
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev::before {
    background: transparent url("/f/resources/images/top/icon_prev_white.svg") center / contain no-repeat;

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-next::before {
    background: transparent url("/f/resources/images/top/icon_next_white.svg") center / contain no-repeat;

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev:before,
  .slick-next:before {
      content: "";
      display: inline-block;
      width: inherit;
      height: inherit;
  }

  .top_news__to-list {
    display: none;
  }
}

.p-topics__inner {
  @media #{g.$sp} {
    padding-inline: 0;
  }
}