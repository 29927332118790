@use "global" as g;
/*
  ----------------
  message common
  ----------------
*/
.l-modal-bg {
  background-color: rgba(0, 0, 0, 0.6);
  content: '';
  display: none;
  height: 110vh;
  z-index: 0;

  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}