@use "global" as g;
/*
  ----------------
  signin | はじめてご利用のお客様
  ----------------
*/
.form-auth.form-signin,
#signin {
  #container .signup-form {
    margin-top: 100px;

    @media #{g.$sp} {
      margin-top: 60px;
    }
  }

  #signup-form {
    background-color: g.$bg-gray-primary;
    padding: 20px 0;
    margin-top: 20px;

    @media #{g.$pc} {
      padding: 25px 0 58px;
    }
  }

  #signup-form .formrow {
    display: block;
    margin-top: 15px;
    padding-inline: 15px;

    @media #{g.$pc} {
      padding-inline: 45px;
    }
  }

  #signup-form .input-label {
    padding: 0;

    display: flex;
    align-items: center;
  }

  #signup-form .input-label .input-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
    margin: 0;
    margin-inline-end: 5px;
  }

  #signup-form .formrow-input .input-control {
    padding: 0;

    display: flex;
    align-items: start;
    flex-direction: column-reverse;
  }

  .signup-form .form-signup .formrow .input-control .input-group,
  .signup-form .form-signup .formrow.formrow-checkbox.formrow-boolean .checkbox {
    font-size: 0;
    padding: 0;
    margin-top: 8px;
  }

  #signup-form .input-control .input-group {
    flex: auto;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  #signup-form .input-control .input-group input {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-gray;
  }

  #signup-form fieldset {
    background-color: transparent;
    border-top: 1px solid g.$border-gray;
    border-radius: 0;
    margin: 0;
    padding: 0;
    margin-block-start: 50px;
    padding-block-start: 20px;

    @media #{g.$pc} {
      padding-block-start: 25px;
    }
  }

  .signup-form .form-signup .formrow-input,
  .signup-form .form-signup .formrow-radio,
  .signup-form .form-signup .formrow-yyyymmdd,
  .signup-form .form-signup .formrow-checkbox,
  .signin-form .form-signin .formrow {
    display: block;
    padding-inline: 15px;

    @media #{g.$pc} {
      padding-inline: 45px;
    }
  }

  .signup-form fieldset .formrow .input-control .input-group {
    display: flex;
    gap: 10px;
  }

  .signup-form fieldset .formrow-yyyymmdd .input-control > div.input-group {
    gap: 5px;
  }

  #signup-form fieldset > div.formrow.formrow-checkbox.formrow-boolean > div.input-control {
    padding: 0;

    display: flex;
    align-items: start;
    flex-direction: column-reverse;
  }

  // ボタン類スタイル
  .signup-form .form-signup fieldset .formrow-radio .input-control label,
  .signup-form .form-signup fieldset .formrow-yyyymmdd .input-control select {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;

    position: relative;
  }

  .signup-form .form-signup fieldset .formrow-radio .input-control label {
    padding: 9px 14px 9px 38px;
  }

  // ボタン類デフォルトスタイル削除
  .signup-form .form-signup .formrow-radio input[type="radio"],
  .signup-form .form-signup .input-control .input-group select {
    appearance: none;
    margin: 0;
    // position: absolute;
    display: none;
  }

  // ラジオボタンカスタマイズ
  .signup-form .form-signup fieldset .formrow-radio .input-control label {
    &::before,
    &::after {
      content: "";
      display: inline-block;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      background-color: g.$bg-white-primary;
      border: 1px solid g.$border-gray;
      border-radius: 50%;
      width: 18px;
      height: 18px;

      left: 10px;
    }
  }

  .signup-form .form-signup fieldset .formrow-radio .input-control label.is-checked {
    border: 1px solid g.$border-green;

    &::after {
      background-color: g.$bg-green-primary;
      border-radius: 50%;
      width: 14px;
      height: 14px;

      left: calc((20px / 2) - (14px / 2) + 10px);
    }
  }

  // セレクトタグ(生年月日)カスタマイズ
  .signup-form .form-signup .input-control .input-group select {
    background: transparent url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
    background-position: right 10px center;
    background-size: 24px;
    color: g.$text-black-primary;
    padding-block: 10px;
    padding-inline: 10px 8px;
    width: 100%;
    max-width: 150px;
    min-width: 150px;

    display: inline-flex;
    align-items: center;

    @media #{g.$sp} {
      min-width: 94px;
      padding-inline: 8px;
    }
  }

  // チェックボックスカスタマイズ
  .signup-form .form-signup .formrow-checkbox .input-control label {
    cursor: pointer;
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    font-size: 14px;
    padding: 10px 14px 10px 10px;

    display: flex;
    align-items: center;
  }

  .signup-form .form-signup .formrow-checkbox .input-control input {
    accent-color: g.$bg-green-primary;
    margin: 0 8px 0 0;
    height: 20px;
    width: 20px;
  }

  .signup-form .form-signup .formrow-checkbox .input-control label.is-checked {
    border: 1px solid g.$border-green;
  }

  .signup-form .formrow-submit {
    padding: 0;
    margin-top: 60px;
    padding-inline: 15px;
  }

  .signup-form .formrow-submit .note {
    margin: 0;
  }
}

.form-auth.form-signin {
  div.formrow div.input-control {
    padding: 0;
  }

  .checkbox  {
    @media #{g.$sp} {
      width: 100%;
    }
  }

  .formrow-checkbox .input-group {
    @media #{g.$sp} {
      flex-direction: column;
      width: 100%;
    }
  }
}
