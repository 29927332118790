@use "global" as g;

/*
  ---------------
  コミュニティ | スレッドのコメント一覧
  ---------------
*/

body#community__talk {
  .community .link-search {
    background-color: transparent;
    border: none;
    padding: 0;

    display: flex;
    justify-content: center;
  }

  .thread-title .thread-content {
    margin-top: 8px;
  }

  .comments-heading {
    margin-top: 40px;
  }

  .thread-image li {
    padding-block: 16px;

    display: flex;
    justify-content: center;
  }

  ol.comments {
    padding-top: 10px;

    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .comments li {
    background-color: g.$bg-gray-primary;
    border: none;
    margin-top: 0;
    padding: 16px 24px;
    position: relative;

    display: flex;
    justify-content: center;

    @media #{g.$sp} {
      padding: 14px 16px;
    }
  }

  .thread-contents .thread-comment {
    width: 100%;
  }

  .thread-contents .thread-comment .header {
    padding: 0 123px 0 0;

    @media #{g.$sp} {
      padding: 0 115px 0 0;
    }
  }

  .thread-contents .thread-comment.with-seq .header:before{
    content:"投稿NO." attr(data-seq);
    font-size: 14px;
  }

  .thread-comment .code {
    color: g.$text-gray-primary;
    font-size: 14px;
    display: none;
  }

  .thread-contents .info {
    border: none;
    padding: 0;

    display: flex;
    flex-direction: column-reverse;

    @media #{g.$sp} {
      padding-top: 4px;
      gap: 4px;
    }
  }

  .thread-contents .timestamp {
    color: g.$text-gray-primary;
    font-size: 12px;
  }

  .thread-contents .timestamp::before {
    content: '投稿日時：';
  }

  .thread-contents .owner-profile {
    font-size: 12px;
    font-weight: 400;
  }

  .thread-contents .owner-profile .nickname::before {
    content: '投稿者：';
  }

  .thread-comment .content {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-gray;
    margin-top: 8px;
    padding: 22px;

    @media #{g.$sp} {
      margin-top: 6px;
      padding: 8px 14px;
    }
  }

  .thread-comment .comment-content {
    font-size: 14px;
  }

  .thread-comment .images li {
    background-color: transparent;
    padding-inline: 0;
  }

  .thread-comment .images img {
    max-width: 172px;
  }

  .thread-comment .trailer {
    // position: relative;
    display: flex;
    flex-direction: column-reverse;
  }

  .thread-comment .trailer .reactions {
    position: absolute;
    top: 26px;
    right: 24px;

    @media #{g.$sp} {
      top: 14px;
      right: 16px;
    }
  }

  .thread-comment .trailer .reactions li {
    @include g.hoverLink;
    background-color: g.$bg-green-primary;
    border-radius: 2px;
    color: g.$text-white-primary;
    height: 30px;
    padding: 6px 10px 6px 26px;
    position: relative;

    display: flex;
    align-items: center;
    gap: 20px;
  }

  .thread-comment .trailer .reactions li::before {
    background: url(/f/resources/images/common/icon_heart_white.svg) 50% / contain no-repeat;
    content: '';
    display: block;

    height: 13px;
    width: 14px;

    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }

  .thread-comment .trailer .reactions li span {
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    line-height: 1;
    margin: 0;
  }

  .thread-comment .send-report {
    position: relative;
    width: 100%;
  }

  .thread-comment .show-report-form {
    position: absolute;
    top: -18px;
    right: 0;
  }

  .thread-comment .report-form {
    top: 8px;
  }

  .post-thread-comment-wrapper {
    background: g.$bg-gray-primary;
    border-radius: 0;
    border: none;
    margin-bottom: 0;
    padding: 40px 45px;
    margin-top: 40px;

    @media #{g.$sp} {
      padding: 20px 16px;
    }
  }

  .post-thread-comment-wrapper .formrow-file input[type='file'] {
    border: 0;
  }

  // サインイン
  .signin-to-post {
    padding-top: 20px;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  .signin-to-post .signin {
    display: grid;
    place-items: center;
  }
}
