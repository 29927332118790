@use "global" as g;

/*
  ---------------
  TOP | 刊行シリーズから探す
  ---------------
*/
.select-top .p-publication {
  margin-top: 60px;

  @media #{g.$sp} {
    margin-top: 50px;
  }
}

.select-top .p-publication .p-publication__inner {
  background-color: g.$bg-gray-primary;
  padding-block: 50px;
}

.select-top .p-publication .p-publication__header {
  @include g.container();

  h2 {
    @include g.h2TitleBgBorder();
  }
}

.p-publication .l-slider {
  @include g.container();
  margin-top: 40px;

  @media #{g.$sp} {
    margin-top: 20px;
  }
}

.p-publication .l-slider__slide-link {
  aspect-ratio: 385 / 136;
  border: 1px solid g.$border-gray;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.select-top .p-publication .p-publication__btn {
  max-width: 250px;
  margin: 50px auto 0;

  @media #{g.$sp} {
    margin-top: 40px;
  }
}

.select-top .p-publication .p-publication__btn-link {
  background-color: g.$bg-green-quaternary;
  border-radius: 30px;
  color: g.$text-white-primary;
  display: inline-block;
  width: 100%;
  min-height: 50px;
  padding-inline: 19px;

  display: flex;
  align-items: center;
}

.select-top .p-publication .p-publication__btn-link span {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.46666667;
  text-align: center;
  width: 100%;
  padding-right: 35px;

  position: relative;

  @media #{g.$sp} {
    font-size: 16px;
    line-height: 1.4375;
  }
}

.select-top .p-publication .p-publication__btn-link span::after {
  content: "";
  background: transparent url("/f/resources/images/select/common/icon_arrow.svg") center / contain no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

// slick
.select-top .p-publication .l-slider:not(.slick-initialized) {
  display: none;
}

.select-top .p-publication .slick-list {
  margin-inline: -10px;
}

.select-top .p-publication .slick-track {
  display: flex;
}

.select-top .p-publication .slick-slide {
  margin-inline: 10px;
}

.select-top .p-publication .slick-slide > div {
  font-size: 0;

  display: flex;
  gap: 20px;

  @media #{g.$sp} {
    gap: 16px;
  }

}

.select-top .p-publication .slick-slide > div + div {
  margin-top: 20px;

  @media #{g.$sp} {
    margin-top: 16px;
  }
}

.select-top .p-publication .slick-slide > div > li > a {
  display: inline-block;
  width: 100%;
}

.select-top .p-publication .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
}

.select-top .p-publication .slick-prev,
.select-top .p-publication .slick-next {
  width: 50px;
  height: 50px;

  @media #{g.$sp} {
    width: 30px;
    height: 60px;
  }
}

.select-top .p-publication .slick-prev {
  left: 0;
}

.select-top .p-publication .slick-next {
  right: 0;
}

.select-top .p-publication .slick-prev:before {
  content: "";
  display: inline-block;

  @media #{g.$pc} {
    background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
    width: 50px;
    height: 50px;
  }

  @media #{g.$sp} {
    background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

.select-top .p-publication .slick-next:before {
  content: "";
  display: inline-block;

  @media #{g.$pc} {
    background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
    width: 50px;
    height: 50px;
  }

  @media #{g.$sp} {
    background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
    width: 30px;
    height: 60px;
  }
}

.select-top .p-publication .slick-prev.slick-disabled:before,
.select-top .p-publication .slick-next.slick-disabled:before {
  @media #{g.$pc} {
    opacity: 0;
  }
}
