@use "global"as g;

/*
  ---------------
  商品一覧
  ---------------
*/
#itemlist #container .item-list {
  @media (min-width: 1120px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }

  .item-list-each {
    @media (min-width: 1120px) {
      align-items: flex-start;
      gap: min(1.38888889vw, 10px);
    }
    @media #{g.$sp} {
      padding-bottom: 20px;
    }
  }

  .item-list-each:has(.add-item-to-cart) {
    @media #{g.$sp} {
      padding-bottom: 80px;
    }
  }

  // 画像エリア
  .item-thumbnail-img-wrapper {
    @media (min-width: 1120px) {
      flex: 0 4 43.220339%;
      width: 100%;
      max-width: 255px;
      max-height: 255px;
      margin: 0;
    }
  }

  .item-thumbnail-img-wrapper > .item-thumbnail-img {
    @media (min-width: 1120px) {
      aspect-ratio: 1 / 1;
      width: auto;
      height: auto;
    }
  }

  .item-thumbnail-img-wrapper > div > a {
    @media (min-width: 1120px) {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
    }
  }

  .item-thumbnail-img-wrapper > .item-thumbnail-img > a > img,
  .item-thumbnail-img-wrapper > .item-thumbnail-img > a > div {
    @media (min-width: 1120px) {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
    }
  }

  // 商品詳細エリア
  .item-list-each .item-dtl {
    @media #{g.$pc} {
      display: flex;
      flex-direction: column;
    }

    @media (min-width: 1120px) {
      flex: 1;
      height: 100%;
      min-height: 255px;
    }
  }

  .item-list-each .item-dtl h2.item-name {
    @media (min-width: 1120px) {}
  }

  .item-list-each .item-dtl .item-tags {
    @media #{g.$sp} {
      vertical-align: top;
    }
  }

  .item-list-each .item-dtl .item-tag {
    margin: 0;

    @media (min-width: 1120px) {
      padding: 0;
    }
  }

  .item-list-each .item-dtl .articles,
  .item-list-each .item-dtl .article,
  .item-list-each .item-dtl div.purchase,
  .item-list-each .item-dtl div.item-purchase {
    @media #{g.$pc} {
      height: 100%;

      display: flex;
      flex-direction: column;
    }
  }

  .item-list-each .item-dtl div.ac {
    @media #{g.$pc} {
      height: 100%;
    }
  }

  .item-list-each .item-dtl span.item-name {
    font-size: 18px;
    font-weight: 600;

    @media #{g.$sp} {
      font-size: 16px;
    }
  }

  .item-list-each .item-dtl .item-code {
    font-size: 11px;
  }

  .item-list-each .item-dtl .text-contents.article-summary {
    font-size: 12px;
    padding-bottom: 4px;

    // overflow: hidden;
    display: -webkit-box;
    margin-top: 5px;
    // text-overflow: ellipsis;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
  }

  .item-list-each .item-dtl .offering-price-info .offering-prices {
    text-align: left;
  }

  .item-list-each .item-dtl .offering-price-info .regular-price.overridden {
    display: flex;
    text-align: left;
    width: 100%;
  }

  .item-list-each .item-dtl .offering-price-info .regular-price.overridden .price-label {
    display: block;
    padding-right: 0;
  }

  .item-list-each .item-dtl .offering-price-info .final-price {
    display: inline-block;
    margin-right: 4px;
  }

  .item-list-each .item-dtl .offering-price-info .final-price .price-label {
    display: none;
  }

  .item-list-each .item-dtl .offering-price-info .discount-price {
    color: #090;
    display: inline-block;
    font-size: 12px;
  }

  .item-list-each .item-dtl .offering-price-info .individual-delivery-fee {
    display: block;
  }

  .item-list-each .item-dtl .stock-message {
    @media (min-width: 1120px) {
      flex-grow: 1;
    }
  }

  .item-list-each .item-dtl .stock-message > div {
    font-size: 11px;
    margin: 0;
  }

  .item-list-each .item-dtl .stock-message .stock-msg-dtl {
    font-size: 12px;
  }

  .item-list-each .item-purchase .final-price .price {
    font-size: 20px;
    font-weight: 500;
    white-space: wrap;

    @media #{g.$sp} {
      font-size: 16px;
    }
  }

  .item-list-each form.item-purchase {
    @media (min-width: 1120px) {
      margin-top: 0;
    }

    @media #{g.$pc} {
      position: static;
    }
  }

  .item-list-each form.item-purchase div.item-qty {
    @media (min-width: 1120px) {
      margin-top: 2px;

      justify-content: flex-end;
      width: 100%;
    }

    @media (max-width: 914px) and (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  .item-list-each form.item-purchase .btn-group {
    @media (min-width: 1120px) {
      margin-top: 10px;
      margin-left: 0;

      display: flex;
    }
  }

  .item-list-each form.item-purchase div.item-qty,
  .item-list-each form.item-purchase .btn-group {
    @media (max-width: 1119px) {
      margin-top: 2px;
    }

    @media #{g.$sp} {
      margin-top: 6px;
    }
  }

  .item-list-each form.item-purchase .btn-group .btn {
    @media (min-width: 1120px) {
      font-size: 13px;
      width: 100%;
      min-width: 0;
    }
  }

  .item-list-each form.item-purchase .btn-group .add-item-to-cart {
    @media (min-width: 1120px) {
      padding-inline: 30px 10px;
    }
    @media #{g.$sp} {
      width: 100%;
    }
  }

  .item-list-each form.item-purchase .btn-group .checkout-item {
    @media (min-width: 1120px) {
      padding-inline: 10px 30px;
    }
  }

  .item-list-each form.item-purchase .btn-group .add-item-to-cart::before {
    @media (min-width: 1120px) {
      left: 104px;
      left: calc(50% - 58px);
    }
    @media #{g.$sp} {
      left: calc(50% - 67px);
    }
  }

  .item-list-each form.item-purchase .btn-group .checkout-item::before {
    @media (min-width: 1120px) {
      right: 14px;
    }
  }
}
