@use "global" as g;

/*
  ---------------
  セレクトショップ 商品詳細 | 最近チェックした商品
  ---------------
*/
.select-product .p-history {
  margin-top: 50px;

  &__inner {}

  .l-slider {
    margin-top: 18px;

    @media #{g.$pc} {
      position: relative;
    }

    @media #{g.$sp} {
      margin-top: 20px;
    }

    &::after {
      @media #{g.$pc} {
        content: "";
        display: inline-block;
        background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%);
        width: 181px;
        height: 100%;
        margin-right: calc(50% - 50vw);

        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .l-slider__slide-img {

    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .l-slider__slide-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4375;
    margin-top: 20px;

    @media #{g.$sp} {
      font-size: 13px;
      line-height: 1.42857143;
      margin-top: 10px;
    }
  }

  .l-slider__slide-price {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857143;
    margin-top: 6px;

    @media #{g.$sp} {
      font-size: 13px;
      line-height: 1.46153846;
    }
  }

  // slick
  .slick-list {
    // margin: 0 min(-1.04166667vw, -15px);

    @media #{g.$pc} {
      overflow: visible;
    }

    @media #{g.$sp} {
      // margin-inline: min(-2.54452926vw, -10px);
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;

    @media #{g.$sp} {
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-next:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
  }
}
