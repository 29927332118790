@use "global"as g;

/*
  ----------------
  heading common
  ----------------
*/
.l-heading {

  &__inner {
    @include g.container();
    padding-block: 20px;

    display: flex;
    align-items: center;
  }

  &__title {
    flex: 0 0 200px;

    @media #{g.$sp} {
      flex: 0 0 120px;
    }

    &.flowpack-title {
      font-size: 25px;
      font-weight: 400;

      @media #{g.$sp} {
        font-size: 20px;
      }
    }
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    margin-left: 20px;
    margin-right: auto;

    @media #{g.$sp} {
      font-size: 11px;
      line-height: 1.36363636;
      margin-left: 12px;
    }
  }

  &__btn {
    flex: 0 0 250px;
    margin-left: 12px;

    @media #{g.$tab-under} {
      flex: 0 0 36px;
    }
  }

  &__btn-link {
    background-color: g.$bg-green-quaternary;
    border-radius: 30px;
    color: g.$text-white-primary;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    min-height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media #{g.$tab-over} {
      padding-inline: 18px;
    }

    @media #{g.$tab-under} {
      font-size: 0;
      min-height: 36px;

      position: relative;
    }
  }

  &__btn-link > span {
    display: block;
    width: 100%;
    text-align: center;

    position: relative;

    @media #{g.$tab-over} {
      padding-left: 27px;
    }

    @media #{g.$tab-under} {
      width: 36px;
      height: 36px;
    }

    &::before {
      content: "";
      background: transparent url("/f/resources/images/common/icon_search.svg") center / contain no-repeat;
      display: inline-block;
      width: 26px;
      height: 26px;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @media #{g.$tab-under} {
        width: 18px;
        height: 18px;

        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__btn-link > span  {

  }
}
