@use "global" as g;

/*
  ---------------
  Recommend common | おすすめ商品
  ---------------
*/
/*==========================================
  デフォルトのスタイル削除
==========================================*/
.recommend_pw__wrap .item-list > li.item-list-each {
  border: 0;
  border-radius: 0;
  margin-top: 0;
  width: auto;
}

div.item-thumbnail-img img {
  box-shadow: none;
}

div.item-thumbnail-img-md {
  width: auto;
  height: auto;
}

h2 {
  padding: 0;
}

// パートワーク
.p-recommend {

  .p-recommend--pw & {
    &__title {
      color: g.$text-white-primary;

      position: relative;
    }

    &__title::after {
      content: "";
      background-color: g.$bg-green-primary;
      display: block;
      text-align: right;
      width: 100vw;
      height: 159px;

      position: absolute;
      top: -12px;
      z-index: -1;

      @media #{g.$sp} {
        margin: 0 calc(50% - 50vw);
      }

      @media #{g.$pc} {
        border-radius: 0 20px 0 0;
        height: 217px;
        margin-left: calc(50% - 50vw);

        top: -27px;
        right: -179px;
      }
    }

    &__btn {
      @media #{g.$sp} {
        text-align: center;
        max-width: 250px;
        margin: 20px auto 0;
      }
    }

    &__list {
      background-color: g.$bg-white-primary;
      border-radius: 20px 0;
      margin-top: 27px;
      padding: 60px 4vw 0;

      display: flex;
      flex-wrap: wrap;
      gap: 2.8vw;
      align-items: flex-start;

      @media #{g.$sp} {
        border-radius: 2px;
        padding: 44px 4.16vw 0;
        margin-top: 14px;
      }
    }

    .item-list-each {
      width: calc((100% - (2.8vw * 3)) / 4);

      display: flex;
      flex-direction: column;
      justify-content: center;

      position: relative;

      @media #{g.$sp} {
        width: calc((100% - 2.8vw) / 2);
      }
    }

    .item-list-each .item-tags {
      position: absolute;
      top: 4px;
      right: 0;
      z-index: 1;
    }

    &__list li.item-list-each div.item-thumbnail-img-md {
      border: 1px solid g.$border-gray;
      text-align: center;
      aspect-ratio: 1/1;
    }

    &__list li.item-list-each div.item-thumbnail-img-md img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      filter: drop-shadow(0 2px 4px rgba(127, 127, 127, 0.3));
    }

    &__list li.item-list-each div.item-dtl {
      text-align: center;
      flex: initial;
      
      margin-top: 5px;
      min-height: 40px;
      overflow: hidden;

      @media #{g.$pc} {
        min-height: 52px;
        margin-top: 11px;
      }
    }

    &__list li.item-list-each .item-name {
      display: inline-block;
      text-align: left;

      @media #{g.$sp} {
        margin: 0 auto;
      }
    }

    &__list li.item-list-each .item-name a:nth-child(1) {
      display: none;
    }

    &__list li.item-list-each .item-name a {
      display: inline-block;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.42857143;

      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

      @media #{g.$pc} {
        font-size: 16px;
        text-align: center;
        -webkit-line-clamp: 2;
      }
    }

    &__list li.item-list-each .caption {
      font-size: 14px;

      @media #{g.$sp} {
        font-size: 12px;
      }
    }
  }
}
