@use "global" as g;
/*
  ----------------
  Editor Utils | 管理画面のエディタで利用するためのユーティリティー
  ----------------
*/

// --------------------
// Respoinsive
// --------------------
.d-pc-only {
  @media #{g.$sp} {
    display: none;
  }
}

.d-sp-only {
  @media #{g.$pc} {
    display: none;
  }
}