@use "global" as g;
/*
  ---------------
  シリーズトップ | 絞り込みエリア
  ---------------
*/
// 絞り込み条件表示
#itemlist .filter-criteria {
  background-color: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  padding: 12px;
}

#itemlist .filter-criteria .filtered-by__name {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  min-width: 100px;
}

#itemlist .item-list-heading .category-item-list-sorting {
  max-width: 150px;

  position: absolute;
  bottom: 0;
  right: 0;
}

#itemlist .filter-criteria .filtered-by__criteria-label {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  min-width: 100px;
}

#itemlist .filter-criteria .filtered-by__list {
  display: inline-flex;
  flex-wrap: wrap;

  padding-left: 8px;
}

#itemlist .filter-criteria .filtered-by__item {
  font-size: 14px;
}

#itemlist .item-list-heading .page-position {
  margin-top: 20px;
}

// 並べ替え
#itemlist .item-list-heading .category-item-list-sorting {
  @media #{g.$sp} {
    text-align: right;
    width: 150px;
  }
}

#itemlist .item-list-heading .category-item-list-sorting .heading {
  border: 1px solid g.$border-gray-dark;
  border-radius: 2px;
  cursor: pointer;
  color: g.$text-gray-primary;
  font-size: 0;
  margin-left: auto;
  position: relative;

  height: 40px;
  width: 40px;

  @media #{g.$pc} {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4375;
    width: 150px;
    min-height: 50px;
    padding: 13px;

    display: flex;
    align-items: center;
  }
}

#itemlist .item-list-heading .category-item-list-sorting .heading::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_filter.svg") center / contain no-repeat;
  display: inline-block;
  width: 18px;
  height: 12px;

  position: absolute;

  @media #{g.$sp} {
    top: 50%;
    right: calc(50% + 1px);
    transform: translate(50%, -50%);
  }

  @media #{g.$pc} {
    right: 13px;
  }
}

#itemlist .item-list-heading .category-item-list-sorting ul {
  background-color: g.$bg-white-primary;
  bottom: auto;
  display: none;
  left: auto;
  list-style-type: none;
  position: absolute;
  z-index: 10;

  max-width: 150px;
  width: 100%;
}

#itemlist .item-list-heading .category-item-list-sorting ul > li {
  display: block;
  padding: 0;

  @media #{g.$sp} {
    text-align: left;
  }
}

#itemlist .item-list-heading .category-item-list-sorting ul > li > a {
  border: 1px solid g.$border-gray-dark;
  display: block;
  padding: .5em 1em;
}

#itemlist .item-list-heading .category-item-list-sorting ul > li:not(:first-child) > a {
  border-top: 0;
}
