/*
  ----------------
  Container style
  ----------------
*/
@mixin container() {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;

  @media all and (max-width : 768px) {
    padding-inline: 12px;
  }
}
