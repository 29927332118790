@use "global" as g;

/*
  ---------------
  最近チェックした商品、あなたにおすすめの商品 | 共通
  ---------------
*/

.sc-display {
  margin-top: 60px;

  @media #{g.$sp} {
    margin-top: 50px;
  }
}

.sc-display .panel {}

.sc-display div.display-box {
  position: relative;
}

.sc-display .panel-heading {
  @include g.container();
}

.sc-display .display-name {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45; // 29px
  text-align: left;
  padding: 0;

  .producttemplate & {
    @media #{g.$pc} {
      font-size: 24px;
      line-height: 1.45833333; // 35px
    }
  }
}

.sc-display div.panel-heading + div.panel-body {
  margin-top: 20px;
  margin-bottom: 0;
}

.sc-display div.display-box ul.item-display {
  @include g.container();
  display: block;
}

.sc-display div.display-box ul.item-display li {
  padding: 0;
  margin-inline: min(1.04166667vw, 15px);

  @media #{g.$sp} {
    margin-inline: min(2.54452926vw, 10px);
  }
}

.sc-display div.display-box ul.item-display li div.display-sm {
  width: auto;
}

.sc-display div.item-thumbnail-img {
  width: auto;
  height: auto;
}

.sc-display div.item-thumbnail-img-sm a {
  aspect-ratio: 278 / 245;
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;

  position: relative;
}

.sc-display div.item-thumbnail-img-sm img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sc-display div.item-thumbnail-img div.no-img {
  border: 0;
  width: 100%;
  height: 100%;
}

.sc-display div.display-box ul.item-display li div.item-name {
  font-size: 14px;
  line-height: 1.42857143;
  text-align: left;
  margin-top: 17px;
  padding: 0;

  @media #{g.$sp} {
    font-weight: 500;
    margin-top: 10px;
  }
}

.sc-display .selling-price {
  font-size: 14px;
  line-height: 1.42857143;
  text-align: left;
  margin-top: 10px;

  @media #{g.$sp} {
    font-weight: 500;
  }
}

.sc-display div.display-box ul.item-display li div.item-desc {
  display: none;
}

.sc-display {
  .slick-slider {}

  .slick-list {
    @media #{g.$pc} {
      margin: 0 min(-1.04166667vw, -15px);
    }

    @media #{g.$sp} {
      overflow: visible;
    }
  }

  .slick-track {
    margin: 0;
  }

  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;

    @media #{g.$sp} {
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-next:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }
}
#product div.sc.partwork,
#product div.sc.related-items.related-products {
  display: none;
}

body.item {
  .sc-display .display-name {

    @media #{g.$sp} {
      text-align: center;
    }
  }

  .sc-display .item-display {
    @media #{g.$pc} {
      position: relative;
    }

    &::after {
      @media #{g.$pc} {
        content: "";
        display: inline-block;
        background: linear-gradient(270deg, #F6F6F6 0%, rgba(246, 246, 246, 0) 100%);
        width: 181px;
        height: 100%;
        margin-right: calc(50% - 50vw);

        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .sc-display .slick-list {
    @media #{g.$pc} {
      overflow: visible;
    }
  }

  .sc-display .item.article {
    @media #{g.$pc} {
      max-width: 240px;
    }
  }

  .sc-display div.item-thumbnail-img-sm a {
    aspect-ratio: 1 / 1;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    @media #{g.$pc} {
      opacity: 0;
    }
  }
}
