@use "global" as g;
/*
  ----------------
  社長挨拶
  ----------------
*/
.company.greeting .column-2 {
  max-width: 760px;
  margin-inline: auto;

  gap: 40px;

  @media #{g.$sp} {
    display: block;
  }
}

.company.greeting .column-2 > div:first-child {
  @media #{g.$pc} {
    flex: 0 0 175px;
  }
}

.company.greeting .column-2 > div:nth-child(2) {
  @media #{g.$sp} {
    margin-top: 30px;
  }
}

.company.greeting p {
  margin-bottom: 12px;
}
