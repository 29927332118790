@use "global"as g;

/*
  ----------------
  Pop Message common
  ----------------
*/
div.pop-msg {
  background-color: g.$bg-red-primary;
  position: static;

  @media #{g.$sp} {
    font-size: 10px;
  }

  .msg {
    color: g.$text-red-primary;
  }
  
  a {
    color: g.$text-red-primary;
    text-decoration: underline;
  }
}