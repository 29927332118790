@use "global" as g;

/*
  ---------------
  購入者情報
  ---------------
*/
body#order__orderer {

  .orderer fieldset:not(.name-and-address) {
    background: g.$bg-gray-primary;
    padding: 40px 45px;

    @media #{g.$sp} {
      padding: 20px 16px;
    }
  }

  div.form fieldset.name-and-address {
    border-top: 0;
    padding: 0;
    margin: 0;
  }

  .form-body .input-group-address {
    border-top: 1px solid g.$border-gray;
  }
}
