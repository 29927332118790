@use "global" as g;
/*
  ---------------
  決済ステータス
  ---------------
*/
body#order__payment_status {
  .panel-payment > .panel-heading {
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
  }

  .panel-payment > .panel-heading > div,
  .panel-body > .status {
    padding: 12px 20px 0;

    max-width: 350px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media #{g.$sp} {
      max-width: 100%;
      gap: 20px;
    }
  }

  .panel-payment > .panel-heading > div h5,
  .panel-payment .panel-body .label {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    text-align: left;
    width: 120px;

    @media #{g.$sp} {
      width: fit-content;
    }
  }

  .panel-payment > .panel-heading > div h5 + div {
    flex: none;
  }

  .shipment {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .panel-payment > .panel-body {
    margin-block: 0;
  }

  .panel-body > .status {
    margin-left: auto;
  }

  .shipment-valid {
    padding-block: 20px 0;
    margin-bottom: 0;
  }

  h4 {
    font-size: 20px;
    margin-top: 20px;
    padding: 0;
  }
}