@use "global" as g;

/* diff pc */

@media screen and (min-width: 800px){
  div.item-search div.item-search-box{
    display:flex;
    border:1px solid #ccc;
    border-radius:2px;
    padding:0;
    position:relative;
    overflow:hidden;
  }
  div.item-search div.item-search-box > div{
    border:none;
    height:2em;
  }
  div.item-search div.item-search-box > div.sb-label{
    padding:0 1em;
    border-right:1px solid #ccc;
    line-height:2em;
  }
  div.item-search div.item-search-box > div.sb-category,
  div.item-search div.item-search-box > div.sb-input{
    border-right:1px solid #ccc;
  }
  div.item-search div.item-search-box div.sb-category select{
    border:none;
    height:100%;
  }
  div.item-search div.item-search-box div.sb-keywords input{
    border:none;
    padding:0 1em;
    height:100%;
  }
  div.item-search div.item-search-box div.sb-btn{
    border-left:1px solid #ccc;
  }
  div.item-search div.item-search-box div.sb-btn button{
    height:100%;
    font-family:inherit;
    margin:0;
    border:none;
    outline:none;
    appearance:none;
    padding:0 1em;
    cursor:pointer;
  }

  h1.item-name,h2.item-name{
    text-align:left;
    padding:0;
    margin:0;
  }

  div.itemdetails-header div.itemdetail-row{
	border-bottom:2px solid #999;
  }

  div.itemdetails-body div.itemdetail-row div.goods div.item-thumbnail-img{
    padding:0 1em;
  }
  div.itemdetails-body div.itemdetail-row div.item div.control{
    width:300px;
  }
  div.itemdetails-body div.itemdetail-row div.item{
	display:flex;
  }
  div.itemdetails-body div.itemdetail-row div.item div.goods{
    width:100%;
  }
  div.itemdetail-row div.item > div.control{
    width:200px;
  }

  #order-entry .shipment ul.delivery-service-conf li{
    display:flex;
    flex-wrap:wrap;
  }



	/*--------------- form -----------------*/
	div.form{
		padding:0 1em;
	}
	div.formrow{
		display:flex;
		margin:0 auto;
	}

	div.formrow > div{
		padding:1.5em 1em;
		display:flex;
		align-items:center;
	}
	div.formrow div.input-label{
		flex:1;
	}
	div.formrow div.input-control{
		display:flex;
		flex:3;
	}
	div.formrow div.input-control div.input-group{
		flex:2;
	}

	div.formrow div.input-note{
		padding-left:2em;
		margin-left:auto;
		flex:1;
	}

    div.cart-add-item-resp > div{
	  max-width:600px;
	}

	form.item-purchase div.btn-group-purchase{
		display:inline-block;
	}
	form.item-purchase div.item-qty-item{
		display:inline-block;
	}

}