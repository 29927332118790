@use "global" as g;
/*
  ----------------
  パートワークとは
  ----------------
*/
.company.partwork .column-2 {
  max-width: 760px;
  margin-inline: auto;

  gap: 40px;

  @media #{g.$sp} {
    display: block;
  }
}

.company.partwork .column-2 > div:first-child {
  @media #{g.$pc} {
    flex: 0 0 260px;
  }

  @media #{g.$sp} {
    max-width: 220px;
    margin-inline: auto;
  }
}

.company.partwork .column-2 > div:nth-child(2) {
  @media #{g.$sp} {
    margin-top: 30px;
  }
}

.company.partwork .message {
  color: #005c69;
  font-size: 26px;
  font-weight: bold;
  margin: 100px 0 40px;
  text-align: center;

  @media #{g.$sp} {
    font-size: 20px;
    margin: 80px 0 40px;
  }
}
