@use "global" as g;
/*
  ---------------
  Tag common
  ---------------
*/
// --------------------
// ▼ .tag-broadcasting
// CM放送中
//
// --------------------

// アイテムタグ共通
div.item-tags div.item-tag {
  // filter: drop-shadow(左右の位置px 上下の位置px ぼかし具合px 色);
  // box-shadow: 横の位置 縦の位置 影のぼかし度 影の広さ 色;
  background-color: transparent;
  padding: 0;

  > p {
    background-color: g.$bg-red-secondary;
    color: g.$text-white-primary;
    padding: 0 2px;
    text-align: center;

    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    @media #{g.$sp} {
      font-size: 15px;
      line-height: 15px;
      min-width: auto;
      padding: 2px 6px 4px;
    }
  }
}

// サイトバナーのアイテムタグ
.site-banner {
  position: relative;

  .item-tags {
    position: absolute;
    top: 1.0178117vw;
    right: 0;
    z-index: 1;

    @media #{g.$pc} {
      top: 10px;
      right: -3px;
    }
  }

  div.item-tags div.item-tag > p {
    border-radius: 12px;
    font-size: 14px;
    padding: 2px 10px 4px;
  }

  div.item-tags div.item-tag.large > p {
    border-radius: 18px;
    font-size: 14px;
    line-height: 30px;
    min-width: 100px;
    padding: 2px 12px;

    @media #{g.$sp} {
      font-size: 15px;
      line-height: 15px;
      min-width: auto;
      padding: 2px 6px 4px;
    }
  }
}

.top .site-banner {
  .item-tags {
    @media #{g.$pc} {
      top: 10px;
      right: 10px;
    }
  }

  .div.item-tags div.item-tag {
    @media #{g.$pc} {
      margin: 0;
    }
  }

  div.item-tags div.item-tag > p {
    @media #{g.$pc} {
      line-height: 20px;
      padding: 2px 12px 3px;
    }
  }
}

.c-tag--genre {
  border: 1px solid g.$border-green-right;
  border-radius: 2px;
  color: g.$text-green-quaternary;
  font-size: 12px;
  line-height: 1.41666667;
  width: 65px;
  min-height: 25px;
  padding: 3px 7px 3px 8px;

  display: flex;
  align-items: center;
  justify-content: center;
}
