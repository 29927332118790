@use "global" as g;

/*
  ---------------
  アカウントのクローズ
  ---------------
*/
body#account__close_account {
  .form-form-CLOSE-ACCOUNT {
    padding-top: 200px;
  }

  .form-form-CLOSE-ACCOUNT h3 {
    font-size: 20px;
    text-align: center;
  }
}