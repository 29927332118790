@use "global" as g;

/*
  ---------------
  コミュニティ | コミュニティボードTOP
  ---------------
*/

body#community__board {
  .community .link-search {
    background-color: transparent;
    border: none;
    height: 50px;
    padding: 0;

    display: flex;
    justify-content: center;

    @media #{g.$sp} {
      height: 40px;
      margin-top: 20px;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 400;
    padding-block: 80px 0;
    width: 100%;

    display: flex;
    align-items: center;
    gap: 18px;

    @media #{g.$sp} {
      font-size: 20px;
      padding-top: 36px;

      align-items: flex-start;
      flex-direction: column;
      gap: 6px;
    }
  }

  h2 .thread-count {
    background-color: g.$text-gray-secondary;
    border-radius: 2px;
    color: g.$text-green-primary;
    font-size: 12px;
    font-weight: 500;
    padding: 6px 9px;

    @media #{g.$sp} {
      font-size: 14px;
    }
  }

  .community-board-threads {
    padding-top: 30px;

    @media #{g.$sp} {
      padding-top: 20px;
    }
  }

  // 並び替え
  .comments-heading {
    padding-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .sorting > span {
    border: 1px solid g.$border-gray-dark;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    font-size: 0;
    margin-left: auto;
    position: relative;

    height: 40px;
    width: 40px;

    @media #{g.$pc} {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4375;
      width: 180px;
      min-height: 50px;
      padding: 13px;

      display: flex;
      align-items: center;
    }
  }

  .sorting span::after {
    content: "";
    background: transparent url("/f/resources/images/common/icon_filter.svg") center / contain no-repeat;
    display: inline-block;
    width: 18px;
    height: 12px;

    position: absolute;

    @media #{g.$sp} {
      top: 50%;
      right: calc(50% + 1px);
      transform: translate(50%, -50%);
    }

    @media #{g.$pc} {
      right: 13px;
    }
  }

  .sorting ul {
    background-color: g.$bg-white-primary;
    bottom: auto;
    display: none;
    left: auto;
    list-style-type: none;
    position: absolute;
    z-index: 10;

    max-width: 180px;
    width: 100%;

    @media #{g.$sp} {
      right: 12px;
    }
  }

  .sorting ul > li {
    display: block;
    padding: 0;

    @media #{g.$sp} {
      text-align: left;
    }
  }

  .sorting ul > li > a {
    border: 1px solid g.$border-gray-dark;
    display: block;
    padding: .5em 1em;
  }

  .sorting ul > li:not(:first-child) > a {
    border-top: 0;
  }
  // 並び替えここまで

  .community-board-threads .talk-threads {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .community-board-threads .thread {
    border: 1px solid g.$border-gray;
    padding: 22px;
  }

  .community-board-threads .thread-title {
    border: none;
    font-size: 20px;
    padding: 0;

    @media #{g.$sp} {
      font-size: 16px;
    }

  }

  .community-board-threads .thread-title::before {
    display: none;
  }

  .community-board-threads .thread-title a {
    text-decoration: underline;
  }

  .community-board-threads .info {
    display: flex;
    position: relative;

    @media #{g.$sp} {
      display: block;
    }
  }

  .community-board-threads .owner-profile {
    margin-right: auto;
  }

  .community-board-threads .owner-profile span {
    color: g.$text-gray-primary;
    font-size: 12px;

    @media #{g.$sp} {
      font-size: 14px;
    }
  }

  .community-board-threads .owner-profile .nickname::before {
    content: '投稿者：';
  }

  .community-board-threads .created{
    color: g.$text-gray-primary;
    font-size: 12px;

    @media #{g.$sp} {
      font-size: 14px;
    }
  }

  .community-board-threads .total-comments {
    font-size: 12px;
    color: g.$text-green-primary;
    position: relative;

    position: absolute;
    bottom: -12px;
    right: 0;

    @media #{g.$sp} {
      top: 0;
      bottom: unset;
      font-size: 14px;
    }

    &::before {
      background: url(/f/resources/images/common/icon_comment.svg) 50% / contain no-repeat;
      content: '';
      display: block;

      height: 12px;
      width: 13px;

      position: absolute;
      top: 54%;
      left: -14px;
      transform: translateY(-50%);
    }
  }

  .community-board-threads .properties {
    display: flex;
    gap: 4px;

    position: absolute;
    bottom: -24px;
    left: 0;

    @media #{g.$sp} {
      flex-wrap: wrap;
      padding-top: 6px;
      position: static;
    }
  }

  .community-board-threads .properties span {
    background-color: g.$bg-green-primary;
    border-radius: 50px;
    color: g.$text-white-primary;
    font-size: 11px;
    font-weight: 400;
    padding: 2px 8px;

    @media #{g.$sp} {
      font-size: 14px;
    }
  }

  .community-board-threads .heading:has(.comm-prop) + .summary {
    padding-top: 40px;

    @media #{g.$sp} {
      padding-top: 12px;
    }
  }

  .community-board-threads .summary {
    padding-top: 20px;

    @media #{g.$sp} {
      padding-top: 60px;
    }
  }

  .create-new-thread {
    padding-top: 80px;

    display: flex;
    justify-content: center;

    @media #{g.$sp} {
      padding-top: 36px;
    }
  }

  .create-new-thread .btn {
    background: -webkit-gradient(linear, left top, right top, from(g.$bg-green-primary), to(g.$text-green-tertiary));
    background: linear-gradient(90deg, g.$bg-green-primary 0%, g.$text-green-tertiary 100%);
    color: #fff !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.45833333;
    padding: 14px 20px 14px 54px;
    position: relative;

    @media #{g.$sp} {
      font-size: 14px;
      padding: 14px 8px 14px 40px;
      text-align: center;
      width: 100%;
    }
  }

  .create-new-thread .btn::before {
    background: url(/f/resources/images/common/icon_create_new_white.svg) 50% / contain no-repeat;
    content: '';
    display: block;

    height: 18px;
    width: 18px;

    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);

    @media #{g.$sp} {
      left: 16px;
    }
  }
}