@use "global" as g;
/*
  ---------------
  全体共通 | 2ページ以上で使用する共通のスタイル
  ---------------
*/
.icons div.item-tags div.item-tag {
  background-color: transparent;
  padding: 0;
}

.icons .c-itemtag {
  margin-left: 0;
}

.primary-message {
  border: 1px solid g.$text-red-primary;
  background-color: g.$bg-red-primary;
  color: g.$text-red-primary;
  font-size: 16px;
  margin: 40px 0;
  padding: 20px;

  @media #{g.$sp} {
    font-size: 14px;
    margin: 20px 0;
    padding: 10px;
  }
}