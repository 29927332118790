@use "global"as g;

/*
  ----------------
  scroll top common
  ----------------
*/
.l-scroll-top {
  display: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  transition: opacity .2s;

  position: fixed;
  right: 30px;
  z-index: 10;

  @media #{g.$sp} {
    right: 11px;
  }
}
