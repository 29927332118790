@use "global"as g;

// ers-dev.css 追加
/* FAQシナリオ | FAQ-Sequence */
.scenario-faq__btn-wrap {
  padding: 20px 0;
  text-align: center;
}

.scenario-faq__btn {
  background-color: #1d1d1b;
  border-radius: 4px;
  color: #fff;
  padding: 12px 20px;
}

.newsentries .scenario-sequence {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  padding-top: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.newsentries .sequential-contents {
  background-color: #eee;
  padding: 40px;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.newsentries .subsequence-label {
  display: block;
}

.newsentries .subsequence-label a {
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 4px;
  display: block;
  padding: 8px;
}

.newsentries .subsequence-label li + li {
  margin-left: 0;
}
// ers-dev.css ここまで

.newsentries.faq #container,
.faq_imported #container {
  #page-wrapper {
    padding-top: 26px;
  }

  .entry-text .header .meta,
  .entry-text .body {
    display: none;
  }

  .news-entries .news-entry .entry-text {
    padding: 0;
    width: 100%;
  }

  .news-entries .header,
  .news-entries .headline {
    width: 100%;
  }

  .news-entries .news-entry .entry-text a {
    display: inline-block;
    width: 100%;
  }
}

.newsentries.faq,
.faq_imported {
  #page_aside1 {
    position: relative;
  }

  h1 {
    padding-right: 230px;

    @media #{g.$sp} {
      padding-right: 0;
      padding-bottom: 56px;
    }
  }

  // 検索ボタン
  .scenario-faq__btn-wrap {
    background-color: transparent;
    border: none;
    height: 50px;
    padding: 0;

    position: absolute;
    top: 80px;
    right: 0;
    transform: translateY(-50%);

    @media #{g.$sp} {
      max-width: 204px;
      width: 100%;

      top: 120px;
      right: 50%;
      transform: translateX(50%);
    }
  }

  .scenario-faq__btn {
    @include g.hoverLink;
    background: g.$bg-green-primary;
    border: none;
    border-radius: 30px;
    box-shadow: none;
    // form.cssの影響で[!important]必要
    color: #fff !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    height: 50px;
    padding-right: 22px;

    display: flex;
    align-items: center;

    @media #{g.$sp} {
      font-size: 14px;
      height: 40px;
      margin-top: 0;
      right: 12px;
    }
  }

  .scenario-faq__btn::before {
    background: transparent url(/f/resources/images/common/icon_search.svg) center/contain no-repeat;
    height: 20px;
    content: "";

    margin: 0 7px 0 0;
    width: 20px;

    @media #{g.$sp} {
      margin: 0 6px 0 10px;
    }
  }

  #container .news-entry .entry-text .headline a span.text {
    padding-right: 20px;

    @media #{g.$sp} {
      padding-right: 10px;
    }
  }
}

// よく見られている質問、最新の質問
.faq-recommend {
  padding-bottom: 40px;

  &+.faq-recommend {
    padding-bottom: 80px;
  }

  h2.faq-recommend__head {
    font-size: 24px;
    font-weight: 400;
  }

  .faq-recommend__body {
    padding-top: 10px;
  }

  .faq-recommend__entry {
    border-top: 1px solid g.$border-gray;
    position: relative;

  }

  .faq-recommend__entry:last-child {
    border-bottom: 1px solid g.$border-gray;
  }

  .faq-recommend__entry::before {
    background: g.$bg-green-primary;
    border-radius: 2px;
    content: 'Q';
    color: g.$text-white-primary;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;

    display: grid;
    place-items: center;

    height: 24px;
    width: 24px;

    position: absolute;
    top: 18px;
    left: 12px;
  }

  .faq-recommend__link {
    color: g.$text-green-primary;
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding: 18px 40px 18px 47px;
    width: 100%;

    @media #{g.$sp} {
      padding: 12px 10px 12px 47px;
    }
  }
}

// モーダル
.newsentries.faq,
.faq_imported.newsentries {
  .scenario-sequence {
    padding-top: 0;
  }

  .sequential-contents {
    background: g.$bg-white-primary;
    overflow-y: scroll;
    max-height: 85%;
    width: 90%;


    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media #{g.$sp} {
      padding: 36px 16px 16px;
      width: 95%;
    }
  }

  .sequential-contents::before {
    background: url(/f/resources/images/common/icon_close_large.svg) 50% / contain no-repeat;
    cursor: pointer;
    content: '';
    display: block;

    height: 35px;
    width: 35px;

    position: absolute;
    top: 20px;
    right: 20px;

    @media #{g.$sp} {
      height: 25px;
      width: 25px;

      top: 10px;
      right: 10px;
    }
  }

  .scenario-sequence .active-contents {
    font-size: 30px;
    font-weight: 500;

    @media #{g.$sp} {
      font-size: 20px;
    }
  }

  .subsequence-label {
    padding-top: 24px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 136px;
    gap: 30px;

    @media #{g.$sp} {
      padding-top: 14px;

      grid-auto-rows: 100px;
      gap: 20px;
    }
  }

  .subsequence-label li {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    height: 100%;
    margin: 0;
  }

  .subsequence-label a {
    @include g.hoverButton;
    background: g.$bg-gray-primary;
    border: none;
    color: g.$text-green-primary;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    opacity: 1;
    padding: 0;
    text-align: center;

    height: 100%;
    width: 100%;

    display: grid;
    place-items: center;

    @media #{g.$sp} {
      font-size: 16px;
    }
  }
}

// FAQ詳細
body.news .news-archive-faq,
body.news .news-archive-faq_imported {
  @include g.container();

  h1 {
    padding-right: 0;
  }

  #page-wrapper {
    position: relative;
  }

  .news-entry {
    padding-top: 35px;

    @media #{g.$sp} {
      padding-top: 25px;
    }
  }

  .news-entry-header {
    border-top: 1px solid g.$border-gray;
    position: relative;
  }

  .release-date {
    display: none;
  }

  .news-entry-body {
    display: block;
    margin-bottom: 0;
    padding: 15px 15px 60px;
  }

  .reactions.news {
    position: relative;
    position: absolute;
    bottom: 115px;
    right: 19px;

    @media #{g.$sp} {
      bottom: 95px;
    }
  }

  .reactions.news li {
    background: g.$bg-white-primary;
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    color: g.$text-green-primary;
    font-size: 12px;
    font-weight: 500;
    padding: 6px 9px;
  }

  .trailer-link {
    padding: 50px 0 0;

    display: flex;
    justify-content: center;

    @media #{g.$sp} {
      padding-top: 30px;
    }
  }
}

// 内部向けFAQ詳細
body.news .news-archive-faqforinternal {
  .newslist__search {
    display: none;
  }
}

// 検索フォーム
.newsentries.faqforinternal,
.newsentries.faq {
  h1 {
    @media #{g.$sp} {
      padding-bottom: 0;
    }
  }

  .newslist__header {
    position: relative;
    padding: 20px 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @media #{g.$sp} {
      padding-block: 0 20px;

      flex-direction: column;
      align-items: flex-end;
    }
  }

  .newslist__search {
    max-width: calc(100% - 224px);
    width: 100%;

    @media #{g.$sp} {
      max-width: 100%;
    }
  }

  .newslist__search form {
    display: flex;
    align-items: center;
  }

  .newslist__search-input-wrap {
    width: 100%;
  }

  .newslist__search-input-wrap input {
    border: 1px solid g.$border-gray;
    border-right: 0;
    border-radius: 2px 0 0 2px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.416;
    padding: 5px 16px 5px;

    height: 50px;
    width: 100%;
  }

  .newslist__search-submit {
    position: relative;
  }

  .newslist__search-submit::before {
    background: url("/f/resources/images/common/icon_search.svg") center / contain no-repeat;
    content: "";
    cursor: pointer;
    display: inline-block;
    width: 15px;
    height: 15px;

    position: absolute;
    top: 52%;
    left: 34px;
    transform: translateY(-50%);

    @media #{g.$sp} {
      width: 20px;
      height: 20px;

      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .newslist__search-submit input {
    background-color: g.$bg-green-primary;
    border: 1px solid g.$border-gray;
    border-radius: 0 2px 2px 0;
    color: g.$text-white-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.416;
    padding: 5px 34px 5px 52px;
    text-align: center;

    height: 50px;
    min-width: 120px;

    @media #{g.$sp} {
      font-size: 0;
      padding: 0;

      height: 50px;
      min-width: 50px;
      width: 50px;
    }
  }

  .newslist__scinario-wrap {
    @media #{g.$sp} {
      margin: 0 auto;
    }
  }

  .newslist__result-filter {
    padding-bottom: 12px;
  }

  // よくある質問を探すボタン
  .scenario-faq__btn-wrap {
    position: static;
    transform: translateY(0);
  }

  .news-entries:not(:has(div)) {
    background-color: g.$bg-red-primary;
    height: 80px;
    margin-block: 40px;

    display: grid;
    place-items: center;

    @media #{g.$sp} {
      margin-block: 20px;
    }
  }

  .news-entries:not(:has(div))::before {
    content: '見つかりませんでした';
    color: g.$text-red-primary;
    display: block;
    position: absolute;
    font-size: 16px;
  }
}

.newsentries.faqforinternal:not(.faq):not(.archive) {
  .news-header-contents {
    width: 100%;
  }

  .newslist__search {
    min-width: 100%;
  }

  .newslist__result-filter {
    padding-bottom: 0;
  }

  .news-archive-faqforinternal .news-entries {
    margin-top: 10px;
  }
}