@use "global" as g;
/*
  ----------------
  signin
  ----------------
*/
#order__resignin #outer,
#signout #outer,
#signin #outer {
  @include g.container();
}

#signout,
#signin {
  h1,
  h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.45; // 29px
    padding: 0;

    @media #{g.$pc} {
      font-size: 24px;
      line-height: 1.45833333; // 35px
    }
  }
}

#signin #container > div > div.form.signin-form {
  padding: 0;
}

#signin div.formrow,
#signin .signup-form,
#signin .signin-form {
  padding: 0;
}

#signin .signup-form .form-signup .input-label,
#signin .signup-form .form-signup .input-control,
#signin .signin-form .form-signin .input-label,
#signin .signin-form .form-signin .input-control {
  padding: 0;
}

#signin .signup-form .form-signup .formrow-input .input-control .input-note,
#signin .signup-form .form-signup .formrow-boolean .input-control .input-note,
#signin .signin-form .form-signin .formrow .input-control .input-note,
#signin .signup-form .formrow-submit .note {
  color: g.$text-black-primary;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  margin-left: 0;
  padding-left: 0;
}

.initpass {
  background-color: g.$bg-gray-primary;
  margin-top: 100px;
  padding: 45px;
  text-align: center;

  @media #{g.$sp} {
    margin-top: 60px;
    padding: 15px;
  }

  > a {
    background-image: none;
    background-color: g.$bg-green-primary;
    border: 0;
    color: g.$text-white-primary;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.8;
    margin-top: 10px;
    padding: 10px;
    position: relative;
    width: 100%;
    max-width: 420px;
    height: 50px;
  }

  > div {
    margin-top: 20px;
  }
}

.form-auth.form-signin {
  .input-group-radio {
    @media #{g.$sp} {
      flex-direction: column;
    }
  }

  .input-group-radio .radio-item label,
  .input-group-radio .radio-item {
    @media #{g.$sp} {
      width: 100%;
    }
  }
}

#order__signin_to_checkout div.btn-submit {
  padding-bottom: 0;
}

// 再サインイン
#order__resignin.order__resignin {
  h3 {
    font-size: 24px;

    @media #{g.$sp} {
      font-size: 20px;
    }
  }

  div.form.signin-form > div.reminder {
    margin-top: 40px;
    text-align: center;
  }
}