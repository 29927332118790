@use "global" as g;

/*
  ---------------
  参加コミュニティ一覧
  ---------------
*/
#account__your_communities {
  #outer {
    @include g.container;
  }

  h1 {
    padding: 0;
  }

  #page_aside2 .contents-wrapper {
    display: flex;
    flex-direction: column;
  }

  #page_aside2 .btn-account {
    margin-top: 40px;
    order: 2;
  }

  .backlink.community-list {
    text-align: center;
    margin-top: 40px;
    order: 1;
  }

  .backlink.community-list a {
    display: inline-flex;
  }
}

div.talkthreads {
  margin-top: 30px;

  & + & {
    margin-top: 50px;
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.45;
  }

  li {
    background-color: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
    margin-top: 20px;
    padding: 20px;

    @media #{g.$sp} {
      padding: 0;
    }
  }

  .talkthread + & {
    margin-top: 20px;
  }

  .board {

    @media #{g.$pc} {
      display: flex;
      align-items: center;
      gap: 26px;
    }
  }

  .board img {
    width: 100%;
    height: 100%;

    @media #{g.$pc} {
      max-width: 353px;
      aspect-ratio: 353 / 106;
    }

    @media #{g.$sp} {
      aspect-ratio: 370 / 111;
    }
  }

  .board .board-name {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.45;

    @media #{g.$sp} {
      font-size: 16px;
      line-height: 1.4375;
      margin-top: 10px;
      padding-inline: 10px;
    }
  }

  .talkthread-detail {
    background-color: g.$bg-white-primary;
    margin-top: 20px;
    padding: 30px 20px;

    @media #{g.$sp} {
      margin: 10px;
      padding: 15px;
    }
  }

  .talkthread-detail .title {
    text-align: right;
  }

  .talkthread-detail .title a {
    display: block;
    font-size: 20px;
    line-height: 1.45;
    text-align: left;

    @media #{g.$pc} {
      position: relative;
    }

    @media #{g.$sp} {
      font-size: 16px;
      line-height: 1.4375;
    }
  }

  .talkthread-detail .title a::after {
    @media #{g.$pc} {
      background: url("/f/resources/images/common/icon_arrow_right.svg") center / contain no-repeat;
      content: "";
      width: 6px;
      height: 10px;

      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    @media #{g.$sp} {
      display: none;
    }
  }

  .talkthread-detail .title a:visited {
    color: g.$text-green-primary;
  }

  .talkthread-detail .title .comment-count {
    color: g.$text-green-primary;
    display: inline-block;
    font-size: 14px;
    line-height: 1.41666667;
    text-align: right;
    margin-top: 2px;

    position: relative;

    @media #{g.$sp} {
      font-size: 14px;
      line-height: 1.42857143;
    }
  }

  .talkthread-detail .title .comment-count::before {
    background: url(/f/resources/images/common/icon_comment.svg) center / contain no-repeat;
    content: "";
    display: inline-block;
    height: 14px;
    width: 15px;

    position: absolute;
    top: 50%;
    left: -19px;
    transform: translateY(-50%);
  }

  .talkthread-detail .latest-comment {
    margin-top: 2px;

    display: flex;
    flex-direction: column-reverse;
  }

  .talkthread-detail .latest-comment .comment {
    font-size: 14px;
    line-height: 1.42857143;
    margin-top: 10px;
    height: 60px;
  }

  .talkthread-detail .latest-comment .comment {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .talkthread-detail .latest-comment .info {
    color: g.$text-gray-primary;
    font-size: 14px;
    line-height: 17px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
