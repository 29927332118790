@use "global" as g;

/*
  ----------------
  Footer common
  ----------------
*/
// ers-dev.css 追加

footer#footer a {
  color: inherit;
}

footer#footer div#footer-menu {
  display: flex;
  max-width: var(--ers-deago-maxwidth);
  margin: 0 auto;
  justify-content: center;
}

footer#footer div#footer-menu > div {
  padding: 1em;
}

footer#footer div#footer-menu div.head {
  padding: 1em 0;
}

footer#footer div#footer-menu div.body {
  display: flex;
}

footer#footer div#footer-menu div.body ol {
  padding-right: 2em;
}
// ers-dev.css ここまで

footer#footer a.l-footer__global-link {
  color: g.$text-green-primary;
}

.l-footer {
  color: g.$text-white-primary;
  margin-top: 100px;

  @media #{g.$sp} {
    margin-top: 60px;
  }

  &__main {
    @media #{g.$pc} {
      background-color: g.$bg-gray-primary;
    }
  }

  &__main-inner {
    @media #{g.$pc} {
      padding: 15px 0;

      display: flex;
      justify-content: space-between;
    }
  }

  // global nav
  &__global {}

  &__global-list {
    height: 100%;

    display: flex;
    // gap: 40px;
    gap: min(2.77777778vw, 40px);
  }

  &__global-item {
    min-width: 0;
  }

  &__global-link {
    color: g.$text-green-primary;
    height: 100%;

    display: flex;
    align-items: center;
  }

  &__global-link.c-text {
    font-weight: 500;
    font-size: 15px;
  }

  // 無料会員登録
  &__signup {
    @media #{g.$sp} {
      width: 100%;
    }

    @media #{g.$pc} {
      min-width: 132px;
      margin-left: auto;
    }
  }

  &__signup-link {
    background-color: g.$bg-green-primary;
    border-radius: 4px;
    color: g.$text-white-primary;
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media #{g.$pc} {
      height: 40px;
    }
  }

  // 書店様向け
  &__bookstore {

    @media #{g.$sp} {
      width: 100%;
    }

    @media #{g.$pc} {
      min-width: 132px;
      margin-left: 0.69444444vw;
    }
  }

  &__bookstore-link {
    background-color: g.$bg-black-primary;
    border-radius: 4px;
    color: g.$text-white-primary;
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media #{g.$pc} {
      height: 40px;
    }
  }

  &__sub {
    background-color: g.$bg-green-primary;
  }

  &__sub-inner {
    padding: clamp(0px, 6.36132316vw, 25px) clamp(0px, 7.63358779vw, 30px) clamp(0px, 10.178117vw, 40px);

    @media #{g.$pc} {
      padding: 23px 0 26px;
      max-width: 1280px;
      margin: 0 auto;
      padding-right: clamp(0px, 2.77777778vw, 40px);
      padding-left: clamp(0px, 2.77777778vw, 40px);

      display: flex;
      justify-content: space-between;
    }
  }

  &__sub-box-01 {

    @media #{g.$sp} {
      display: flex;
      align-items: start;
      justify-content: space-between;
    }
  }

  // ご利用規約〜サイトマップ
  &__usage {}

  &__usage-list {
    @media #{g.$pc} {
      display: flex;
      flex-wrap: wrap;
      gap: 0.69444444vw min(1.38888889vw, 20px);
    }
  }

  &__usage-link {
    color: g.$text-white-primary;
    display: block;
    font-size: 14px;
    font-weight: bold;

    @media #{g.$sp} {
      padding: 5px 0;
    }
  }

  // ご利用規約〜サイトマップ
  &__usage + .l-footer__usage {
    margin-top: 10px;

    .l-footer__usage-link {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
    }
  }

  &__sub-box-02 {
    flex-shrink: 0;
    min-width: 0;
    display: flex;

    @media #{g.$sp} {
      justify-content: center;
    }
  }

  // sns icon
  &__sns {
    flex-shrink: 0;
    min-width: 0;

    display: flex;

    @media #{g.$sp} {
      margin-top: 47px;
    }

    @media #{g.$pc} {
      margin: 0 -0.69444444vw;
    }
  }

  &__sns-list {
    flex-grow: 1;

    display: flex;
  }

  &__sns-item {}

  &__sns-item-link {
    height: 100%;
    padding: 0 clamp(5px, 2.54452926vw, 10px);

    display: flex;
    align-items: center;

    @media #{g.$pc} {
      padding: 0 min(0.69444444vw, 10px);
    }
  }

  &__privacy {
    display: flex;
    align-items: center;
  }

  &__privacy img {
    width: 65px;

    @media #{g.$pc} {
      width: 45px;
    }
  }

  &__copyright {
    font-size: 11px;
    color: g.$text-white-primary;
    line-height: 1.45454545;
    margin-top: 20px;

    @media #{g.$sp} {
      text-align: center;
    }

    @media #{g.$pc} {
      margin-top: 10px;
    }
  }
}
