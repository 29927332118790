@use "global" as g;

/*
  ---------------
  select TOP | おすすめの商品
  ---------------
*/
.select-top .p-recommend {
  margin-top: 50px;

  @media #{g.$sp} {
    margin-top: 40px;
    overflow: hidden;
  }

  .display-name {
    @include g.h2TitleBgBorder();
  }

  .panel-body {
    margin-top: 40px;

    @media #{g.$sp} {
      margin-top: 20px;
    }
  }

  .item.article img,
  .item.article .no-img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
