@use "global"as g;
/*
  ---------------
  お知らせ履歴
  ---------------
*/
body#account__view_account_message_history {
  ul.block-list {
    padding-top: 40px;
    max-width: 900px;
    margin-inline: auto;

    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  ul.block-list li {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    padding: 20px;
    width: 100%;
  }
}