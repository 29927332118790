@use "global"as g;

/*
  ---------------
  テーブル系
  ---------------
*/

body#order__confirm,
body#cart,
body#account__view_partwork_subscription {
  .itemdetails-note {
    font-size: 14px;
    margin-top: 16px;
  }

  .itemdetails-body {
    border-top: 1px solid g.$border-gray;

    @media #{g.$sp} {
      margin-top: 30px;
    }
  }

  .itemdetails-body .itemdetail-row {
    border-bottom: 1px solid g.$border-gray;
    padding: 30px 0;

    @media #{g.$sp} {
      padding: 20px 0;

      display: flex;
      flex-direction: column;
    }
  }

  .itemdetails-body .itemdetail-row .item {
    min-width: 0;
  }

  .itemdetails-body .item-thumbnail-img {
    padding: 0;
  }

  .item-thumbnail-img .no-img {
    box-shadow: none;
  }

  .itemdetails-body .goods.entry-item {
    display: flex;
    gap: 20px;

    @media #{g.$sp} {
      flex-direction: row;
      gap: 8px;
    }
  }

  .itemdetails-body .goods.entry-item .item-thumbnail-img {
    overflow: hidden;

    height: 158px;
    width: 180px;

    @media #{g.$sp} {
      height: 90px;
      width: 100px;
    }
  }

  .itemdetails-body .goods.entry-item .item-thumbnail-img > a {
    height: 100%;
    width: 100%;
  }

  .itemdetails-body .goods.entry-item .item-thumbnail-img > img {
    height: 100%;
    object-fit: contain;
  }

  .itemdetails-body .goods.entry-item .goods-text {
    padding-right: 20px;
    width: 100%;

    display: flex;
    flex-direction: column;

    @media #{g.$sp} {
      padding: 0;
    }
  }

  .itemdetails-body .goods.entry-item .goods-text > .purchases-messages {
    padding-top: 6px;

    @media #{g.$sp} {
      padding-top: 0;
    }
  }

  .itemdetails-body .goods.entry-item .goods-text > .purchases-messages .stock-msg-dtl {
    color: g.$text-green-primary;
  }

  .itemdetails-body .goods.entry-item:not([data-product-class^=ECOM]) .goods-text > .unit-price {
    display: none;
  }

  // 無料の商品では単価を非表示
  .itemdetails-body .itemdetail-row.free-item-row .goods .goods-text > .unit-price {
    display: none;
  }

  .itemdetails-body .goods.entry-item .goods-text > .unit-price {
    font-size: 14px;
    line-height: 1.7;
    text-align: left;
    order: 4;

    @media #{g.$pc} {
      margin-top: 4px;
    }
  }

  .itemdetails-body .unit-price .individual-delivery-fee {
    font-size: 11px;
    display: block;
  }

  .itemdetails-body .goods-text .item-name {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .itemdetails-body .goods-text .item-name a {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }

  .itemdetails-body .goods-text .item-name .code {
    color: g.$text-black-secondary;
    font-size: 12px;
  }

  .itemdetail-row {
    position: relative;
  }

  .itemdetail-row .item .control {
    display: flex;
    justify-content: flex-end;

    position: absolute;
    bottom: 20px;
    right: 0;

    @media #{g.$sp} {
      padding-left: 0;
      left: 0;
      right: unset;
    }
  }

  .itemdetail-row .item .control .wrapper {
    display: flex;
    flex-direction: row;

    @media #{g.$sp} {
      flex-direction: column;
      gap: 0;
    }
  }

  .itemdetail-row .item .cartitem-basic-control,
  .itemdetail-row .item .wrapper {
    margin-left: 20px;

    display: flex;
    gap: 20px;

    @media #{g.$sp} {
      padding-top: 12px;
      margin-left: 0;

      gap: 10px;
    }
  }

  .itemdetail-row .item .wrapper .ready-cartitem,
  .itemdetail-row .item .wrapper > a,
  .itemdetail-row .item .cartitem-basic-control .remove-cartitem,
  .itemdetail-row .item .cartitem-basic-control .save-cartitem {
    color: g.$text-green-primary;
    font-size: 14px;
    padding-left: 16px;
    position: relative;

    &::before {
      content: '';
      display: block;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .itemdetail-row .item .wrapper > a::before,
  .itemdetail-row .item .cartitem-basic-control .remove-cartitem::before {
    background: url(/f/resources/images/common/icon_remove.svg) 50% / contain no-repeat;

    height: 12px;
    width: 11px;
  }

  .itemdetail-row .item .cartitem-basic-control .save-cartitem::before {
    background: url(/f/resources/images/common/icon_bug_cross.svg) 50% / contain no-repeat;

    height: 15px;
    width: 13px;
  }

  .itemdetail-row .item .wrapper .ready-cartitem::before {
    background: url(/f/resources/images/common/icon_bug.svg) 50% / contain no-repeat;

    height: 15px;
    width: 13px;
  }

  .itemdetail-row .item .partwork-subs-control button {
    @include g.hoverButton();
    background: none;
    border: none;
    box-shadow: none;
    display: none;
    font-size: 14px;
    padding-block: 0;
    padding-right: 25px;
    position: relative;

    &::before {
      background: url(/f/resources/images/common/icon_arrow_right_circle.svg) 50% / contain no-repeat;
      content: '';
      display: block;

      height: 20px;
      width: 20px;

      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
    }
  }

  .itemdetail-row .purchase-contents {
    min-width: 400px;

    display: flex;
    align-items: flex-start;

    @media #{g.$sp} {
      min-width: unset;
      padding-left: 162px;
      margin-top: 10px;

      align-items: flex-end;
      flex-direction: column;
      gap: 10px;
    }
  }

  .itemdetail-row .purchase-contents .quantity {
    padding: 0;
  }

  .itemdetail-row .purchase-contents .quantity .item-qty {
    @media #{g.$sp} {
      display: flex;
      align-items: center;
    }
  }

  .itemdetail-row .purchase-contents .quantity .item-qty-label {
    font-size: 14px;
    white-space: nowrap;
  }

  .itemdetail-row .purchase-contents .quantity select {
    @include g.hoverButton();
    appearance: none;
    background: g.$bg-gray-primary url(/f/resources/images/common/icon_arrow_down_circle.svg) calc(100% - 10px) 50% / 24px no-repeat;
    border: none;
    border-radius: 2px;
    font-size: 20px;
    font-weight: 700;
    margin-left: 16px;
    padding: 5px 34px 5px 20px;

    height: 40px;
    max-width: 150px;
    width: 100%;

    @media #{g.$sp} {
      font-size: 16px;
      min-width: 84px;
      padding: 5px 30px 5px 12px;
    }
  }

  .itemdetail-row .purchase-contents .subtotal {
    font-size: 20px;
    font-weight: 700;
    padding: 4px 0 0 0;

    @media #{g.$sp} {
      font-size: 18px;
    }
  }

  // 価格は0円であれば非表示
  .itemdetail-row .purchase-contents .subtotal.free .amount {
    visibility: hidden;
  }

  .purchase-contents .subtotal .partwork-subscription {
    @media #{g.$pc} {
      margin-left: 12px;
    }
  }

  .price-total.cart-total {
    @media #{g.$sp} {
      padding-top: 18px;
      padding-inline: 0;

      display: flex;
      justify-content: flex-end;
    }
  }

  .cart-total .label,
  .cart-total .qty {
    font-size: 16px;
    font-weight: 500;
  }

  .cart-total .row {
    @media #{g.$sp} {
      align-items: center;
    }
  }

  .price-total .qty {
    display: none;
  }

  .cart-total .subtotal {
    @media #{g.$sp} {
      margin-left: 0;
    }
  }

  .cart-total .subtotal .price,
  .cart-total .cart-total-amt {
    color: g.$text-green-primary;
    font-size: 20px;
    font-weight: 700;
  }

  .cart-msgs {
    @media #{g.$sp} {
      padding: 0;
    }
  }

  .partwork-subscription {
    padding-top: 6px;
  }

  .partwork-subscription > .label {
    @include g.hoverButton();
    color: g.$text-green-primary;
    font-size: 14px;
    line-height: 1.7;
    padding-right: 25px;
    position: relative;
    width: fit-content;

    &::before {
      background: url(/f/resources/images/common/icon_plus_circle_green.svg) 50% / contain no-repeat;
      content: '';
      display: block;

      height: 20px;
      width: 20px;

      position: absolute;
      top: 0%;
      right: 0px;
    }
  }

  .partwork-subscription > .body {
    background-color: g.$bg-white-primary;
    overflow: auto;
    padding: 0 0 22px;
    z-index: 100;

    height: 80vh;
    width: 80vw;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media #{g.$sp} {
      height: 90svh;
      width: 90vw;
    }
  }

  div.itemdetails div.partwork-subscription > div.body {
    display: none;
  }
}

// パートワーク系統（パートワーク定期購読の申込）の商品詳細のみ
div.itemdetails.pwk-exclusive {
  .stock-msg-note.note {
    display: none;
  }
}

// パートワーク購読詳細
body#account__view_partwork_subscription {

  // 購読詳細 編集ボタン
  .btn-group > div {
    @media #{g.$sp} {
      display: block;
    }
  }
}

// 申し込み詳細のヘッダー
body#order__payment_status div.order-items .itemdetails-header,
body#account__order_inquiry div.order-items .itemdetails-header,
body#account__order div.order-items .itemdetails-header {
  @media (min-width: 801px) {
    display: block;
  }
}

.itemdetails-header {
  margin-top: 16px;
}

div.itemdetails-header div.itemdetail-row {
  @media (min-width: 800px) {
    border: 0;
  }
}

#account__order .itemdetails-header .itemdetail-row .purchase-contents,
body#cart .itemdetails-header .itemdetail-row .purchase-contents,
.itemdetails-header .itemdetail-row .purchase-contents {
  display: flex;
  align-items: center;
}

// 数量、小計
#order__payment_status .itemdetails-header .itemdetail-row .purchase-contents .quantity,
#order__payment_status .itemdetails-header .itemdetail-row .purchase-contents .subtotal,
#order__confirm .itemdetails-header .itemdetail-row .purchase-contents .quantity,
#order__confirm .itemdetails-header .itemdetail-row .purchase-contents .subtotal,
#account__order_inquiry .itemdetails-header .itemdetail-row .purchase-contents .quantity,
#account__order_inquiry .itemdetails-header .itemdetail-row .purchase-contents .subtotal,
#account__order .itemdetails-header .itemdetail-row .purchase-contents .quantity,
#account__order .itemdetails-header .itemdetail-row .purchase-contents .subtotal,
body#cart .itemdetails-header .itemdetail-row .purchase-contents .quantity,
body#cart .itemdetails-header .itemdetail-row .purchase-contents .subtotal,
.itemdetails-header .itemdetail-row .purchase-contents .quantity,
.itemdetails-header .itemdetail-row .purchase-contents .subtotal {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-block: 10px;
}

// 申し込み詳細のボディ
.itemdetails-body {
  border-top: 1px solid g.$border-gray;

  @media #{g.$sp} {
    margin-top: 16px;
  }

  & + & {
    border-top: 0;
  }
}

.itemdetails-body .itemdetail-row .purchase-contents .quantity .qty::before {
  @media #{g.$sp} {
    content: "数量";
    margin-right: 6px;
  }
}

.itemdetails-body .goods.purchased-item .goods-text > .unit-price {
  text-align: left;
}

.itemdetails-body .goods.purchased-item .goods-text > .individual-delivery-fee {
  font-size: 11px;
}

.itemdetails-body .goods.purchased-item .goods-text > .individual-delivery-fee .note {
  margin: 0;
}

.itemdetails-body .goods.purchased-item .goods-text > .unit-price::before,
.itemdetails-body .goods.entry-item .goods-text > .unit-price .final::before {
  content: "単価";
  margin-right: 6px;
}

.itemdetails-body .itemdetail-row .purchase-contents .subtotal {
  @media #{g.$sp} {
    width: 100%;

    display: flex;
    justify-content: flex-end;
  }

  #order__confirm & {
    @media #{g.$sp} {
      width: auto;
      display: block;
    }
  }
}

.itemdetails-body .itemdetail-row .purchase-contents .subtotal .amount::before {
  @media #{g.$sp} {
    content: "小計";
    margin-right: 4px;
  }
}

.itemdetails-body .itemdetail-row .purchase-contents .subtotal:has(.alert) {
  position: relative;

  .amount {
    display: none;
  }
}

.itemdetails-body .itemdetail-row .purchase-contents .subtotal .alert {
  font-size: 13px;
  text-align: left;
  margin-top: 0;
  width: 400px;

  position: absolute;
  right: 0;
  top: 70px;

  @media screen and (max-width: 800px){
    position: static;
    margin-bottom: 0;
  }
}

// ヘッダー、ボディー共通
#order__payment_status .itemdetail-row .purchase-contents .quantity,
#order__payment_status .itemdetail-row .purchase-contents .subtotal,
#order__confirm .itemdetail-row .purchase-contents .quantity,
#order__confirm .itemdetail-row .purchase-contents .subtotal,
body#cart .itemdetail-row .purchase-contents .quantity,
body#cart .itemdetail-row .purchase-contents .subtotal,
#account__order_inquiry .itemdetail-row .purchase-contents .quantity,
#account__order_inquiry .itemdetail-row .purchase-contents .subtotal,
#account__order .itemdetail-row .purchase-contents .quantity,
#account__order .itemdetail-row .purchase-contents .subtotal,
.itemdetail-row .purchase-contents .quantity,
.itemdetail-row .purchase-contents .subtotal {
  flex: 1;
  text-align: center;
}

body#order__payment_status div.itemdetail-row .item,
body#account__order_inquiry div.itemdetail-row .item,
body#account__order div.itemdetail-row .item {
  flex: 2;
}

body#order__payment_status div.purchase-contents,
body#account__order_inquiry div.purchase-contents,
#account__order div.purchase-contents {
  @media #{g.$pc} {
    gap: 0;
  }
}

body#account__order .itemdetails-body .itemdetail-row {
  align-items: flex-start;
  // @media #{g.$tab-over} {
  //   align-items: flex-start;
  // }

  @media #{g.$sp} {
    align-items: flex-end;
    width: 100%;
  }
}

body#account__order .itemdetails-body .itemdetail-row .quantity {
  @media #{g.$sp} {
    text-align: right;
    width: 100%;
  }
}

// 0円であれば単価、小計は非表示
body#account__order .itemdetails-body .itemdetail-row.free-item-row .unit-price,
body#account__order .itemdetails-body .itemdetail-row.free-item-row .subtotal .amount {
  display: none;
}