@use "global" as g;
/*
  ---------------
  Text common

  ▼ 基本的なテキスト
  .c-text

  ▼ パートワーク テキスト(ジャンル)
  .c-text--genre

  ▼ h2タイトル
  .c-text--ttl-h2

  ▼ h3タイトル
  .c-text--ttl-h3

  ---------------
*/
.c-text {

  // text
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143; // 20px

  /*
    ---------------
    title text
    ---------------
  */
  // h2タイトル
  &--ttl-h2 {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.44; // 36px
    padding: 0;

    @media #{g.$sp} {
      text-align: center;
    }

    @media #{g.$pc} {
      font-size: 30px;
      line-height: 1.43333333; // 43px
    }
  }

  // h3タイトル
  &--ttl-h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.45; // 29px
    padding: 0;

    @media #{g.$pc} {
      font-size: 24px;
      line-height: 1.45833333; // 35px
    }
  }
}
