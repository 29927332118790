@use "global" as g;
/*
  ----------------
  Header common
  ----------------
*/
// ers-dev.css 追加
.account-panel__logo {
  height: 36px;
}

.account-panel__text {
  color: #fff;
  display: block;
  font-size: 12px;
  text-decoration: none;
}

/* 開発向け情報 */
.sc.dev-page-information {
  overflow-x: scroll;
}

li.search {
  cursor: pointer;
}

div#header {
  display: flex;
  background-color: #333;
}

// モーダル展開時
#wrapper.open-modal #header {
  z-index: 99999;
}

.header-logo {
  width: 100px;
}

header#header ul#global-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--ers-deago-maxwidth);
  margin: 0 auto;
}

header#header ul#global-menu > li {
  padding: 1rem;
}

header#header ul#global-menu ul.link {
  display: flex;
  flex-wrap: wrap;
}

header#header ul#global-menu ul.link > li {
  padding: 0 1rem;
}

header#header ul#global-menu ul.link > li a {
  color: #fff;
  text-decoration: none;
}

header#header ul#global-menu li.search button {
  font-size: 2rem;
}

header#header ul.account-panel-content {
  display: flex;
}

header#header ul.account-panel-content li {
  text-align: center;
}

header#header ul.account-panel-content li a {
  text-align: center;
}
// ers-dev.css ここまで
/*==========================================
  デフォルトのスタイル削除
==========================================*/
header#header ul.account-panel-content li {
  margin: 0;
}

.l-header {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);

  &.is-fixed {
    width: 100%;
    position: fixed;  /* 位置を固定する */
    top: 0;
    z-index: 100;
  }

  &__inner {
    position: relative;
    z-index: 2;
  }

  &__main {
    background-color: g.$bg-green-primary;

    position: relative;
    z-index: 1;
  }

  &__main-inner {
    padding-left: min(3.05343511vw, 15px);

    display: flex;

    @media #{g.$sp} {
      align-items: center;
      padding-left: 0;
    }

    @media #{g.$pc} {
      padding: 12px min(2.15277778vw, 31px) 13px min(1.66666667vw, 24px);

      justify-content: space-between;
    }
  }

  // logo
  &__logo {
    line-height: 1;
    max-width: 100px;

    @media #{g.$sp} {
      margin-inline: 3.3427%;
    }

    @media #{g.$pc} {
      max-width: 110px;
    }
  }

  &__logo-link {
    height: 100%;

    display: flex;
    align-items: center;
  }

  // global nav
  &__global {}

  &__global-list {
    display: flex;

    @media #{g.$pc} {
      height: 100%;
      gap: min(4.86111111vw, 50px);
    }
  }

  &__global-item {

    @media #{g.$sp} {
      background-color: g.$bg-gray-primary;
      border-bottom: 1px solid g.$border-gray;
      width: calc(100% / 3);
      min-height: 51px;

      position: relative;
    }

    @media #{g.$pc} {
      min-width: 0;
      max-width: 100%;
      display: flex;
      align-items: center;
    }

    & + & {
      @media #{g.$sp} {
        border-left: 1px solid g.$border-gray;
      }
    }
  }

  &__global-item:first-child &__global-item-tooltip::before {
    @media #{g.$sp} {
      transform: translateX(100px);
    }
  }

  &__global-item:last-child &__global-item-tooltip::before {
    @media #{g.$sp} {
      transform: translateX(-16px);
    }
  }

  &__global-item-link {
    height: 100%;

    display: flex;
    align-items: center;

    @media #{g.$sp} {
      font-size: 13px;
      text-align: center;

      justify-content: center;
    }

    @media #{g.$pc} {
      color: g.$text-white-primary;
    }
  }

  // tooltip
  &__global-item-tooltip {
    width: 15px;
    height: 15px;

    display: flex;
    align-items: center;

    @media #{g.$sp} {
      position: absolute;
      right: 7px;
      bottom: 7px;
    }

    @media #{g.$pc} {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-left: min(0.34722222vw, 5px);

      position: relative;
    }

    &:hover::before,
    &:hover::after {
      visibility: visible;
      opacity: 1;
      z-index: 10;
    }
  }

  &__global-item-tooltip::before {
    content: attr(data-tooltip);
    background-color: g.$bg-gray-primary;
    box-shadow: 0px 10px 20px -5px g.$border-gray;
    color: g.$text-black-primary;
    display: block;
    font-size: 12px;
    font-weight: 500;
    width: 204px;
    padding: 17px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s;

    position: absolute;
    top: 32px;
    right: -20px;
    z-index: -1;
  }

  &__global-item-tooltip::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent g.$bg-gray-primary transparent;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s;

    position: absolute;
    top: 26px;
    right: 50%;
    z-index: -1;
    transform: translateX(50%);
  }

  // my page, cart, search
  &__user {
    @media #{g.$sp} {
      margin-top: 8px;
      margin-right: clamp(0px, 7.12468193vw, 28px);
      margin-left: auto;
    }
  }

  &__user-list {
    height: 100%;

    display: flex;
    gap: 1.875vw;

    @media #{g.$sp} {
      gap: min(5.59796438vw, 22px);
    }
  }

  &__user-item {
    height: 100%;
    min-width: 30px;
  }

  &__user-item-link {
    @include g.hoverLink;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__user-item-link figure {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__user-item-link.deago-item-search figure .l-header__user-item-figcaption {
    @media #{g.$sp} {
      margin-top: 4px;
      line-height: 16px;
    }
  }

  &__user-item-img {
    display: flex;
    width: 20px;
    margin: 0 auto;
  }

  &__user-item-figcaption {
    color: g.$text-white-primary;
    font-size: 9px;
    line-height: 1;
    text-align: center;
    margin-top: 4px;
    white-space: nowrap;

    @media #{g.$pc} {
      font-size: 10px;
      font-weight: 500;
    }
  }

  // レキシカ出力用css (マイページ, カート)
  #deago-account-panel {
    height: 100%;
  }

  .account-panel-content {
    height: 100%;

    display: flex;
    gap: 1.40625vw;

    @media #{g.$sp} {
      gap: min(4.19847328vw, 21px);
    }
  }

  .mypage,
  .cart-smr {
    height: 100%;
    min-width: 30px;
  }

  .mypage a,
  .cart-smr a {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cart-smr a {
    position: relative;
  }

  .mypage a .account-panel__logo,
  .cart-smr a .account-panel__logo {
    width: 20px;
    height: auto;
    margin: 0 auto;
  }

  .cart-smr .cart-smr-quantity {
    background-color: g.$bg-red-secondary;
    border-radius: 50%;
    color: g.$text-white-primary;
    font-size: 11px;
    height: 16px;
    line-height: 14px;
    text-align: center;
    width: 16px;

    position: absolute;
    top: 0;
    right: -6px;

    @media #{g.$sp} {
      top: -6px;
      right: -8px;
    }
  }

  .account-panel__text {
    color: g.$text-white-primary;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    margin-top: 4px;
    white-space: nowrap;

    @media #{g.$pc} {
      font-size: 10px;
      font-weight: 500;
    }
  }

 // hamburger btn
  &__hamburger {
    @media #{g.$sp} {
      border-left: 1px solid g.$border-white;
      width: 70px;
      height: 70px;

      display: flex;
      align-items: center;
    }
  }

  &__hamburger-btn {
    @media #{g.$sp} {
      display: inline-block;
      width: 100%;
      height: 100%;

      position: relative;
      z-index: 100;
    }
  }

  .c-btn--hamburger {
    border-radius: 0;
  }

  &__hamburger-icon {
    @media #{g.$sp} {
      background-color: g.$bg-white-primary;
      border-radius: 2px;
      display: block;
      font-size: 0;
      width: 30px;
      height: 1px;
      margin: auto;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: transform 0.2s ease-in-out;
    }

    &::before,
    &::after {
      @media #{g.$sp} {
        content: '';
        background-color: inherit;
        border-radius: inherit;
        display: block;
        font-size: 0;
        width: 100%;
        height: 100%;
        transition: inherit;

        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    &::before {
      @media #{g.$sp} {
        top: -9px;
      }
    }

    &::after {
      @media #{g.$sp} {
        top: 9px;
      }
    }

  }
}

// hamburger menu animation
html.is-open--drawer {
  @media #{g.$sp} {
    // background-color: transparent;
    overflow-y: hidden;
  }

  body {
    @media #{g.$sp} {
      overflow-y: hidden;
    }
  }

  .l-header__hamburger-btn {
    background-color: g.$bg-gray-primary;
  }

  .l-header__hamburger-icon {
    @media #{g.$sp} {
      background-color: transparent;
      width: 36px;
    }

    &::before,
    &::after {
      @media #{g.$sp} {
        background-color: g.$bg-green-primary;
        top: 0;
      }
    }

    &::before {
      @media #{g.$sp} {
        transform: rotate(33deg);
      }
    }

    &::after {
      @media #{g.$sp} {
        transform: rotate(-33deg);
      }
    }
  }
}
