@use "global" as g;
/*
  ----------------
  商品検索パネル
  ----------------
*/

#deago-item-search-panel {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 10000;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#select-item-search-panel {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

#deago-item-search-panel > .item-search-panel.panel,
#select-item-search-panel > .item-search-panel.panel {
  background-color: #fff;
  overflow: auto;

  padding: 0;

  height: 90vh;
  width: 90vw;
  max-width: 1000px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{g.$sp} {
    overflow: hidden;
    height: 90svh;
    width: 95vw;
  }
}

.item-search-header {
  display: flex;
}

.item-search-list {
  display: flex;
  width: calc(100% - 50px);
}

.item-search-class {
  background-color: g.$bg-gray-primary;
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  height: 80px;
  text-align: center;
  width: calc(100% / 3);

  display: flex;
  align-items: center;
  justify-content: center;

  @media #{g.$sp} {
    font-size: 16px;
    height: 50px;
    line-height: 1;
    padding-inline: 10px;
    text-align: left;
  }
}

.item-search-class.is-active {
  background-color: g.$bg-white-primary;
  border-top: 4px solid #00828c;
  border-bottom: none;
  color: g.$text-green-secondary;
  padding-bottom: 4px;
}

.item-search-class:nth-child(2).is-active {
  border-top: 4px solid #ffbd08;
  color: #ffbd08;
}

.item-search-class:nth-child(3).is-active {
  border-top: 4px solid #00ac5a;
  color: #00ac5a;
}

.item-search-closer {
  border: 1px solid #333;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 36px;
  line-height: 42px;
  margin-left: auto;
  text-align: center;

  height: 80px;
  width: 80px;

  display: grid;
  place-items: center;

  @media #{g.$sp} {
   height: 50px;
   width: 50px;
  }
}

.item-search-body {
  @media #{g.$sp} {
    position: relative;
  }
}

.isp__body {
  height: calc(90vh - 190px);
  overflow-y: scroll;
  padding: 36px 50px 50px;

  @media #{g.$sp} {
    height: calc(90svh - 120px);
    padding: 30px 20px;
  }
}

.isp__footer {
  background-color: g.$bg-gray-primary;
  padding: 20px;

  @media #{g.$sp} {
    padding: 10px 8px;

    position: absolute;
    left: 0;
    right: 0;
    border: 0;
  }
}

.isp__by-series + .isp__by-input {
  padding-top: 40px;
}

.isp__action {
  display: flex;
  justify-content: center;
  gap: 20px;

  @media #{g.$sp} {
    gap: 8px;
  }
}

.isp__btn {
  @include g.hoverButton();
  border-radius: 4px;
  color: g.$text-white-primary;

  height: 70px;
  width: 100%;

  display: grid;
  place-items: center;

  @media #{g.$sp} {
    font-size: 18px;
    height: 50px;
  }
}

.isp__clear-criteria {
  background-color: g.$bg-gray-quinary;
}

.isp__submit {
  background: linear-gradient(90deg, #005c69 0%, #00ada0 100%);
  position: relative;
}

.isp__submit::before {
  background: url(/f/resources/images/common/icon_search.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  height: 20px;
  width: 20px;

  position: absolute;
  top: 50%;
  left: calc(50% - 36px);
  transform: translate(-50%,-50%);
}

.isp__searchby-heading {
  background-color: g.$bg-gray-primary;
  border-left: 4px solid g.$border-green;
  color: g.$text-green-primary;
  font-size: 16px;
  font-weight: 700;
  padding: 13px 18px 14px;

  @media #{g.$sp} {
    font-size: 18px;
    padding: 12px;
  }
}

.isp__searchby-body {
  padding-top: 18px;

  @media #{g.$sp} {
    padding-top: 20px;
  }
}

.isp__by-input .isp__searchby-body {
  padding: 16px 0 0;

  @media #{g.$sp} {
    padding-top: 20px;
  }
}

.isp__criteria + .isp__criteria {
  padding-top: 25px;
}

.isp__link-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.isp__link-item {
  display: inline-block;
  margin-right: 8px;
}

.isp__link-item a {
  background-color: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  border-radius: 50px;
  font-size: 12px;
  padding: 5px 10px 6px;

  @media #{g.$sp} {
    font-size: 14px;
  }
}

.isp__linkbtn-wrap {
  padding-top: 30px;
  display: flex;
  justify-content: center;

  @media #{g.$sp} {
    padding-top: 20px;
  }
}

.isp__criteria-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.isp__criteria-heading-label {
  color: g.$text-green-primary;
  font-size: 16px;
  font-weight: bold;
}

.isp__criteria-control-wrap {
  display: flex;
  gap: 5px;
}

.isp__criteria-control-btn {
  @include g.hoverButton();
  background-color: g.$bg-gray-primary;
  border-radius: 2px;
  color: g.$text-green-primary;
  cursor: pointer;
  font-size: 12px;
  padding: 3px 8px 5px 20px;
  position: relative;

}

.isp__criteria-control-btn::before {
  content: '';
  display: block;

  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
}

.isp__criteria-btn-checkall::before {
  background: url(/f/resources/images/common/icon_check_green.svg) 50% / contain no-repeat;
  height: 7px;
  width: 9px;
}

.isp__criteria-btn-uncheckall::before {
  background: url(/f/resources/images/common/icon_cross_green.svg) 50% / contain no-repeat;
  height: 14px;
  width: 14px;
}

.isp__criteria-body {
  padding-top: 14px;
}

.isp__criteria-badge {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.isp__criteria-badge-item {
  border: 1px solid g.$border-green;
  border-radius: 2px;
  color: g.$text-green-primary;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  padding: 5px 11px 6px;

  @media #{g.$sp} {
    font-size: 14px;
  }
}

.isp__criteria-badge-item.is-hide {
  display: none;
}

.isp__criteria-badge-item.is-active {
  background-color: g.$bg-green-primary;
  color: #fff;
}

.isp__criteria-badge-footer {
  text-align: center;
  padding-top: 16px;

  @media #{g.$sp} {
    padding-top: 18px;
  }
}

.isp__badge-show-all {
  color: g.$text-green-primary;
  cursor: pointer;
  font-size: 14px;
  position: relative;
}

.isp__badge-show-all::before {
  content: '';
  display: block;

  height: 20px;
  width: 20px;

  position: absolute;
  top: 50%;
  right: -27px;
  transform: translateY(-50%);
}

.isp__badge-show-all.is-hide::before {
  background: url(/f/resources/images/common/icon_plus_circle_green.svg) 50% / contain no-repeat;
}

.isp__badge-show-all.is-show::before {
  background: url(/f/resources/images/common/icon_minus_circle_green.svg) 50% / contain no-repeat;
}

.isp__criteria-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  @media #{g.$sp} {
    gap: 10px 20px;
  }
}

.isp__criteria-filter-item {
  margin-left: 0;
  padding: 8px 14px 8px 40px;
}

.isp__criteria-filter-checkbox {
  appearance: none;
  margin: 0;
  position: absolute;
}

.isp__criteria-filter-label {
  color: g.$text-green-primary;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.isp__criteria-filter-label::before,
.isp__criteria-filter-label::after {
  content: "";
  display: inline-block;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.isp__criteria-filter-label::before {
  background-color: g.$bg-white-primary;
  border: 1px solid g.$border-gray;
  border-radius: 4px;

  height: 28px;
  width: 28px;

  left: -40px;
}

.is-active .isp__criteria-filter-label::after {
  background: transparent url("/f/resources/images/common/icon_checked.svg") center / contain no-repeat;
  height: 12px;
  width: 16px;

  left: -31px;
}

.isp__criteria-body:has(.isp__criteria-keyword) {
  padding-block: 8px 0;
}

.isp__criteria-keyword input {
  border: 1px solid g.$border-green;
  border-radius: 2px;
  font-size: 16px;
  padding: 15px 14px;
  width: 100%;
}

.isp__criteria-badge-box {
  margin: 8px 0;
}

// セレクトショップ
.isp__select .isp__criteria-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.isp__select .isp__criteria-badge-box {
  margin: 0;
}

.isp__select .isp__criteria-badge-wrap {
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.isp__select .isp__criteria-badge-control {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.isp__select .isp__criteria-badge-control .isp__criteria-control-btn {
  padding-block: 11px 12px;
}

.isp__select .isp__criteria-price {
  padding-top: 10px;

  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.isp__select .isp__criteria-price-item {
  display: flex;

  @media #{g.$sp} {
    width: calc((100% - 10px) / 2);
  }
}

.isp__select .isp__criteria-price-item:first-child {
  @media #{g.$sp} {
    width: 100%;
  }
}

.isp__select .isp__criteria-price-label {
  background-color: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  cursor: pointer;
  padding-block: 9px;
  padding-inline: 38px 14px;
  position: relative;

  @media #{g.$sp} {
    font-size: 14px;
    padding-inline: 30px 2px;
    width: 100%;
  }

  &::before,
  &::after {
    content: "";
    display: inline-block;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-gray;
    border-radius: 50%;
    width: 18px;
    height: 18px;

    left: 10px;

    @media #{g.$sp} {
      left: 4px;
    }
  }
}

.isp__select .is-active .isp__criteria-price-label {
  border: 1px solid g.$border-green;

  &::after {
    background-color: g.$bg-green-primary;
    border-radius: 50%;
    width: 14px;
    height: 14px;

    left: calc((20px / 2) - (14px / 2) + 10px);

    @media #{g.$sp} {
      left: calc((20px / 2) - (4px / 2) - 1px);
    }
  }
}

.isp__select .isp__criteria-price-radio {
  appearance: none;
  margin: 0;
  position: absolute;
}

.isp__select  {

}

.isp__criteria-badge-title {
  font-size: 20px;
  background-color: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  border-radius: 4px;
  color: g.$text-green-primary;
  cursor: pointer;
  padding: 12px 30px 12px 20px;
  position: relative;

  display: flex;
  justify-content: space-between;

  @media #{g.$sp} {
    font-size: 14px;
    padding: 14px 30px 14px 20px;
  }
}

.isp__criteria-badge-title::after {
  font-size: 24px;
  background: url(/f/resources/images/common/icon_plus_green.svg) 50% / contain no-repeat;
  content: '';
  display: block;

  height: 15px;
  width: 15px;

  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.isp__criteria-badge-box.is-show .isp__criteria-badge-title::after {
  background: url(/f/resources/images/common/icon_minus_green.svg) 50% / contain
  no-repeat;
  height: 2px;
}

.isp__criteria-badge-control {
  margin-top: 8px;
}

.isp__criteria-price {
  display: flex;
}

.isp__criteria-badge-box.is-show .isp__criteria-badge-selected,
.isp__criteria-badge-box.is-show .isp__criteria-badge-selected-item {
  display: none;
}

.isp__criteria-badge-selected:has(li) {
  white-space: nowrap;
  overflow: scroll;
  padding-block: 16px 20px;

  display: flex;
  gap: 10px;
}

.isp__criteria-badge-selected-item {
  display: inline-block;
  background-color: g.$bg-gray-primary;
  border-radius: 2px;
  color: #747474;
  font-size: 12px;
  padding: 6px 8px;
}
