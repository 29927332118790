@use "global" as g;

/*
  ---------------
  ディストリビューション詳細 |
  ---------------
*/
.distribution-detail {
  margin-top: 20px;
}

.distribution-detail > .distribution > .heading {
  background-color: transparent;
  padding: 0;
  margin-bottom: 20px;

  @media #{g.$pc} {
    margin-bottom: 40px;
  }
}

.distribution-detail > .distribution > .heading .main-title .pwk-icon {
  display: none;
}

.distribution-detail > .distribution > .heading .main-title {
  padding: 0;
}

// バックナンバーの購入
.distribution .subscribe .purchase-backnumber .heading .title {
  border: 1px solid g.$border-gray;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  text-decoration: none;
  padding: 13px 35px 13px 20px;

  position: relative;

  @media #{g.$sp} {
    padding: 15px 60px 15px 20px;
  }
}

// アコーディオン開
.distribution .subscribe .purchase-backnumber .heading .title::after {
  content: "";
  background: transparent url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") center / contain no-repeat;
  display: inline-block;

  height: 24px;
  width: 24px;

  position: absolute;
  top: 11px;
  right: 20px;
}

// アコーディオン閉
.distribution .subscribe .purchase-backnumber.is-open .heading .title::after {
  background: transparent url(/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg) center/contain no-repeat;
  rotate: 180deg;
}

.distribution .subscribe .purchase-backnumber .heading .exp {
  display: none;

  border-left: 1px solid g.$border-gray;
  border-right: 1px solid g.$border-gray;
  padding: 20px 20px 0;

  @media #{g.$sp} {
    padding: 12px 12px 0;
  }
}

.distribution .subscribe .purchase-backnumber .heading .note {
  color: g.$text-black-primary;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285143;
  margin: 0;
}

.distribution .subscribe .purchase-backnumber .heading .check-all {
  margin: 20px 0 0;

  @media #{g.$sp} {
    margin-top: 12px;
  }
}

.distribution .subscribe .purchase-backnumber .heading .check-all label {
  cursor: pointer;
  display: block;
  font-size: 18px;
}

.distribution .subscribe .purchase-backnumber .heading .check-all span {
  padding-left: 6px;
  vertical-align: middle;
}

.distribution .subscribe .purchase-backnumber .heading .check-all input[type=checkbox] {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  inline-size: 20px;
  block-size: 20px;
}

.distribution-detail > .distribution .subscribe .purchase-backnumber .body {
  display: none;
  border-left: 1px solid g.$border-gray;
  border-right: 1px solid g.$border-gray;
  border-bottom: 1px solid g.$border-gray;
  padding: 20px;

  @media #{g.$sp} {
    padding: 8px;
  }
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcels {
  background-color: g.$bg-gray-primary;
  max-height: 255px;
  overflow-y: scroll;
  padding: 10px 20px;

  @media #{g.$sp} {
    padding: 10px 4px;
  }
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcel {
  border: 0;
  margin-top: 0;
  padding: 0;
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcel label {
  cursor: pointer;
  display: block;
  font-size: 18px;
  margin-block: 2px;

  @media #{g.$sp} {
    margin-block: 8px;
  }
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcel label > input[type="checkbox"] {
  @media #{g.$sp} {
    vertical-align: middle;
    height: 16px;
    width: 16px;
  }
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .parcel label > issue-name {
  vertical-align: middle;
}

.distribution-detail > .distribution .subscribe .purchase-backnumber > .body .issue-thumbnail,
.distribution-detail > .distribution .subscribe .parcels.select-start > .body .issue-thumbnail {
  display: none;
}

// 購読を開始する号を選択
.distribution-detail .body .subscribe .select-start {
  margin: 80px 0;

  position: relative;

  @media #{g.$sp} {
    margin: 40px 0;
  }
}

.distribution-detail .body .subscribe .select-start .heading .title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
  padding: 0;

  @media #{g.$pc} {
    font-size: 24px;
    line-height: 1.45833333;
    padding-top: 5px;
  }
}

.distribution-detail .body .subscribe .select-start .heading .note {
  color: g.$text-black-primary;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285143;
  margin: 0;
  margin-top: 10px;
}

.distribution-detail .body .subscribe .select-start .body {
  margin-top: 20px;

  @media #{g.$sp} {
    margin-top: 16px;
  }
}

.selec-start-issue {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;

  @media #{g.$sp} {
    align-items: flex-start;
    flex-direction: column;
  }

  &::after {
    content: 'からお届け';
    display: block;
    font-size: 20px;

    @media #{g.$sp} {
      font-size: 16px;
    }
  }
}

.select-start .select-issues {
  appearance: none; /* デフォルトの矢印を非表示 */
  background: g.$bg-gray-primary url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  border-radius: 2px;
  color: g.$text-black-tertiary;
  cursor: pointer;
  border: 1px solid g.$border-gray;;
  font-family: 'Noto Sans JP';
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  padding: 0 34px 0 13px;

  min-width: 300px;
  min-height: 60px;

  display: flex;
  align-items: center;

  @media #{g.$sp} {
    background-position: right 6px center;
    font-size: 18px;
    padding: 0 30px 0 10px;
    min-height: 50px;
    min-width: auto;
    width: 100%;
  }
}

// 選択肢が1つのみ
.select-start .select-issues:has(> option:first-child:last-child) {
  background: g.$bg-gray-primary;
  pointer-events: none;
}

.select-start .issue.unavailable {
  display: none;
}

// オプションを選択
.distribution-detail .body .subscribe .choose-option {
  margin-bottom: 80px;

  @media #{g.$sp} {
    margin-bottom: 40px;
  }
}

.distribution-detail .body .subscribe .choose-option .heading {
  font-size: 24px;
  line-height: 1.45833333;
  padding-top: 5px;

  @media #{g.$sp} {
    font-size: 20px;
  }
}

.distribution-detail .body .subscribe .choose-option .body {
  margin-top: 20px;

  @media #{g.$sp} {
    margin-top: 16px;
  }
}

// 各オプション
.distribution-detail .body .subscribe > form .choose-option .options {
  @media #{g.$pc} {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.distribution-detail .body .subscribe > form .choose-option .option {
  background: linear-gradient(0deg, #F6F6F6, #F6F6F6), linear-gradient(0deg, #005C69, #005C69);
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  padding: 12px;

  @media #{g.$pc} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px 6px 6px 20px;
    width: calc(50% - 10px);
  }

  @media #{g.$sp} {
    padding: 4px;
  }
}

.distribution-detail .body .subscribe > form .choose-option .option + .option {
  @media #{g.$sp} {
    margin-top: 10px;
  }
}

// 各オプションのアニメーション用スタイル
.distribution-detail > div > .body > .subscribe > form .choose-option .option.is-checked {
  border: 1px solid g.$border-green;
}

// 各オプションのラベル
.distribution-detail > div > .body > .subscribe > form .choose-option .option label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

// checkbox
.distribution-detail > div > .body > .subscribe > form .choose-option .option input[type="checkbox"] {
  block-size: 30px;
  display: inline-block;
  inline-size: 30px;
  margin: 0;

  @media #{g.$pc} {
    accent-color: g.$bg-white-primary;
  }

  @media #{g.$sp} {
    width: 20px;
    height: 20px;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option > label > input[type="checkbox"]:checked {
  border: 1px solid g.$border-gray;
}

// オプションタイトル
.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-label {
  color: g.$text-black-tertiary;
  font-size: 16px;
  line-height: normal;

  @media #{g.$pc} {
    font-size: 20px;
    margin-left: 19px;
  }
}

// オプション画像
.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-icon {
  aspect-ratio: 120 / 105;
  border: 1px solid g.$border-gray;
  object-fit: contain;

  height: 100%;
  max-width: 115px;
  max-height: 100px;
  margin-left: 20px;

  @media #{g.$sp} {
    aspect-ratio: 102 / 90;
    max-width: 104px;
    margin-left: 8px;
    width: 100%;
  }
}

// オプションの説明文
.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-contents {
  color: g.$text-black-tertiary;
  font-size: 14px;
  line-height: normal;
  margin-top: 4px;

  @media #{g.$sp} {
    width: 100%;
    font-size: 13px;
  }

  @media #{g.$pc} {
    font-size: 12px;
  }
}

// オプション カスタムHTMLのスタイル | option-custom
.distribution-detail .choose-option .option .option-custom {
  display: flex;
  align-items: center;

  @media #{g.$sp} {
    gap: 4px;
    position: relative;
  }
}

.distribution-detail .choose-option .option .option-custom:has(.option-multiplier) {
  @media #{g.$sp} {
    gap: 0;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom input[type=checkbox] {
  cursor: pointer;
  flex-shrink: 0;
  width: 20px;

  @media #{g.$pc} {
    order: 1;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom .option-icon {
  order: 0;
  cursor: pointer;
  pointer-events: auto;

  @media #{g.$sp} {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom figure {
  pointer-events: none;
  position: relative;

  @media #{g.$pc} {
    order: 3;
  }

  @media #{g.$sp} {
    position: static;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom figure::before {
  content: url(/f/resources/images/common/icon_zoomin_green.svg);
  position: absolute;
  right: 49px;
  top: 3px;
  z-index: 1;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom figure::after {
  content: "拡大する";
  color: #005c69;
  font-size: 12px;
  z-index: 1;
  position: absolute;
  right: 2px;
  top: 2px;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom figure:hover {
  cursor: pointer;
  opacity: .8;
}

.distribution-detail .choose-option .option .option-custom .option-custom-contents {
  margin-left: 20px;

  @media #{g.$pc} {
    margin-right: auto;
    order: 2;
  }

  @media #{g.$sp} {
    margin-left: 0;
    width: calc(100% - 20px);
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom-contents .option-label {
  margin-left: 0;

  &:hover {
    opacity: .7;
  }

  @media #{g.$sp} {
    display: flex;
    align-items: center;

    min-height: 100px;
    padding-right: 110px;
  }
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom-contents .option-multiplier {
  display: inline-block;
  margin-top: 8px;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom-contents .option-multiplier::before {
  content: "数量";
  vertical-align: middle;
  padding-right: 4px;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom-contents .option-multiplier select {
  padding: 2px 4px;
}

// 画像なし
.distribution-detail .choose-option .option .option-custom.no-image {
  padding-top: 12px;
  padding-bottom: 12px;
}

.distribution-detail > div > .body > .subscribe > form .choose-option .option .option-custom.no-image .option-custom-contents .option-label {
  @media #{g.$sp} {
    min-height: auto;
    padding-right: 0;
  }
}


// 数量を選択する
.distribution-detail .body .subscribe .select-qty-buttons-wrap {
  text-align: right;
  margin-top: 40px;

  @media #{g.$sp} {
    margin-top: 20px;
  }
}

.distribution-detail .body .subscribe .select-qty {
  margin-left: auto;

  display: inline-flex;
  align-items: center;

  @media #{g.$sp} {
    width: 100%;
  }
}

.distribution-detail .body .subscribe .select-qty > .heading {
  color: g.$text-black-tertiary;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 15px;
  margin-top: 0;

  @media #{g.$sp} {
    font-size: 16px;
    text-align: right;
    width: 50%;
  }
}

.distribution-detail .body .subscribe .select-qty > .body {
  @media #{g.$sp} {
    width: 50%;
  }
}

.distribution-detail .body .subscribe .select-qty .body select.item-qty {
  appearance: none; /* デフォルトの矢印を非表示 */
  background: #F6F6F6 url("/f/resources/images/packtoy/list/icon_arrow_under_bg_white.svg") no-repeat;
  background-position: right 10px center;
  background-size: 24px;
  border-radius: 2px;
  border: 3px solid #fff;
  color: g.$text-black-tertiary;
  cursor: pointer;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  line-height: normal;
  padding: 0 10px 0 29px;
  width: 151px;

  display: inline-flex;
  align-items: center;

  @media #{g.$sp} {
    font-size: 18px;
    height: 50px;
    padding: 0 0 0 13px;
    width: 100%;
  }
}

// 定期購読を申し込む
.distribution-detail .body .subscribe .buttons {
  padding-top: 20px;

  @media #{g.$pc} {
    margin-inline: auto;
    width: fit-content;
  }
}

.distribution-detail .body .subscribe .buttons button[type=submit] {
  background: linear-gradient(90deg, #005C69 0%, #00ADA0 100%);
  border: 0;
  border-radius: 2px;
  color: g.$text-white-primary !important;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  min-height: 50px;
  padding-inline: 46px 18px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.distribution-detail .body .subscribe .buttons button[type=submit]::before {
  background: transparent url("/f/resources/images/packtoy/list/icon_cart.svg") center / contain no-repeat;
  content: "";
  display: inline-block;
  width: 18px;
  height: 20px;

  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}

// 詳細な説明
.distribution-detail .body .detail-description {
  font-size: 16px;
  margin-top: 80px;

  @media #{g.$sp} {
    font-size: 14px;
    margin-top: 40px;
  }
}

// 刊行予定
.distribution-detail .body .distribution-schedule {
  border-top: none;
  margin-top: 50px;
  padding: 0;

  @media #{g.$pc} {
    margin-top: 30px;
  }
}

.distribution-detail .body .distribution-schedule > .heading {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
}

.distribution-detail .body .distribution-schedule .body {
  border-top: 1px solid g.$border-gray-dark;
  margin-top: 10px;
}

.distribution-detail .body .distribution-schedule .note {
  color: g.$text-black-primary;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285143;
  margin: 0;
  margin-top: 10px;
}

.distribution-detail .distribution .distribution-schedule .parcels {
  gap: 0;
}

.distribution-detail .body .distribution-schedule .parcels .parcel {
  border: 1px solid g.$border-gray;
  border-bottom: 0;
  margin-top: 10px;
  padding: 0;
}

.distribution-detail .distribution-schedule .parcels .parcel.is-open {
  border-bottom: 1px solid g.$border-gray;
}

.distribution-detail .body .distribution-schedule .parcel .summary {
  border-bottom: 1px solid g.$border-gray;
  cursor: pointer;
  padding: 12px 60px 12px 20px;
  position: relative;

  @media #{g.$sp} {
    padding-right: 40px;
    padding-left: 12px;
  }
}

.distribution-detail .body .distribution-schedule .parcels .parcel .summary::after {
  content: "";
  background: transparent url("/f/resources/images/common/icon_plus_green.svg") center / contain no-repeat;
  display: inline-block;
  width: 15px;
  height: 13px;

  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}


.distribution-detail .body .distribution-schedule .body > ol > li .summary .parcel-privilege {
  margin-top: 16px;
  padding: 0 20px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues,
.distribution-detail .body .distribution-schedule .parcel > .option-parcels,
.distribution-detail .body .distribution-schedule .parcel > .subscription-privileges {
  background-color: g.$bg-white-primary;
  margin-top: 15px;
  padding: 0 20px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > .label {
  display: none;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > .issues {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 20px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol > li {
  max-width: 150px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues .issue-thumbnail {
  display: block;
  max-width: 140px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues .issue-thumbnail img {
  aspect-ratio: 140 / 123;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .item-name,
.distribution-detail .body .distribution-schedule .parcel > .issues > ol .price {
  display: block;
  font-size: 12px;
  line-height: normal;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .item-name {
  font-weight: 500;
  letter-spacing: -0.12px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .price {
  font-weight: 400;
  margin-top: 7px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .individual-shipping-fee {
  margin-top: 4px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .individual-shipping-fee .label,
.distribution-detail .body .distribution-schedule .parcel > .issues > ol .individual-shipping-fee .fee {
  font-size: 12px;
}

.distribution-detail .body .distribution-schedule .parcel > .issues > ol .individual-shipping-fee .fee {
  padding-left: 4px;
}

.distribution-detail .body .distribution-schedule .parcel .shipping > * {
  display: none;
}

// 刊行予定 - アコーディオン用スタイル
.distribution-detail .body .distribution-schedule .parcels .parcel.is-open {
  padding-bottom: 20px;
}

.distribution-detail .body .distribution-schedule .parcels .parcel.is-open .summary::after {
  background: transparent url("/f/resources/images/common/icon_minus_green.svg") center / contain no-repeat;
}

.distribution-detail .body .distribution-schedule .parcel > *:not(.summary) {
  display: none;
}
