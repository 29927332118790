@use "global"as g;

/*
  ----------------
  Related News 関連コンテンツ
  ----------------
*/
.top-newslist-related-news {
  margin: 0 auto;
  max-width: 1280px;
  padding-top: 70px;

  .panel-newslist {
    padding-right: 3.05343511vw;
    padding-left: 3.05343511vw;

    @media #{g.$pc} {
      max-width: 1280px;
      margin: 0 auto;
      padding-top: 7px;
      padding-right: clamp(0px, 2.77777778vw, 40px);
      padding-left: clamp(0px, 2.77777778vw, 40px);
    }

    position: relative;
  }

  .panel-news {
    padding-inline: clamp(0px, 2.77777778vw, 40px);
  }

  .news-name {
    font-size: 24px;
    text-align: left;
  }

  .news-entry {
    border-bottom: 1px solid g.$border-gray;
    padding: 16px 0;

    @media #{g.$sp} {
      padding: 8px 0;
    }

    &:first-child {
      border-top: 1px solid g.$border-gray;
    }
  }

  .news-type {
    @media #{g.$sp} {
      font-size: 12px;
    }
  }

  .news-entry .release-date {
    display: none;
  }

  .headline {
    @media #{g.$sp} {
      margin-top: 4px;
    }
  }

  div.news-entries div.news-entry div.entry-text {
    @media #{g.$sp} {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
