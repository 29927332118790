@use "global" as g;

/*
  ---------------
  カート | 共通
  ---------------
*/
// ers-dev.css 追加
/* カート 商品詳細 | Cart itemdetails */
.itemdetails .distribution .dstr-title {
  font-size: 16px;
}

.itemdetails .parcel .label {
  font-size: 16px;
}

body[class*=community][class*=HOBICOM] {
  background: #444;
  color: #fff;
}

body[class*=community][class*=HOBICOM] a {
  color: #cfc;
}

body[class*=community][class*=HOBICOM] .info,
body[class*=community][class*=HOBICOM] .summary,
body[class*=community][class*=HOBICOM] .thread-comment .header {
  background: inherit;
}

body[class*=community][class*=HOBICOM] .text {
  color: #ccc;
}

body[class*=community][class*=HOBICOM] ol.comments {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

body[class*=community][class*=HOBICOM] ol.comments > li {
  width: 340px;
  padding: 1rem;
}

body[class*=community][class*=HOBICOM] ol.comments > li > div.thread-comment {
  border: 1px solid #ccc;
}

div.distribution div.subscribe span.selling-price {
  display: none;
}
// ers-dev.css ここまで

/*-- Cart View -- */
div.cartrow>div.item div.cartitem-basic-control>div {
  display: inline;
  font-size: 11px;
  white-space: nowrap;
}

div.cartrow>div.item div.rp-alternatives {
  font-size: 11px;

}

div.cartrow span.curr-qty,
div.cartrow div.subtotal span.price {
  font-size: 110%;
}

div.cartrow>div.item div.rp-alternatives div.header {
  color: #a00;
}

div.cartrow>div.item div.rp-alternatives ul {
  padding: 0 0 1em 2em;
}

div.cartrow>div.item div.rp-alternatives ul li {
  list-style-type: disc;
}

div.cartrow>div.qty div.show-update-qty {
  padding: 0 1em;
}

div.cart-checkout {
  text-align: center;
}

div.cart-checkout-ng button.btn-checkout {
  color: g.$text-white-primary !important;
  cursor: no-drop;
  opacity: 0.4;
  pointer-events: none;
}

div.cart-checkout-btn-group>div {
  margin: 1em;
}

div.cartitem div.item-code,
div.cartitem div.item-name {
  display: inline-block;
  font-size: 14px;
}

div.rp-conf-wrapper {}

div.rp-conf-wrapper div.rp-opts div.input-group {
  display: inline-block;
}

div.cart-clear-all {
  padding: 1em 0;
  text-align: right;
}
/*-- Cart View End -- */

body#cart {
  #outer {
    @include g.container();
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
    padding: 0;
  }

  .itemdetails-body .goods.entry-item .goods-text > .purchases-messages .stock-message-none .stock-msg-dtl {
    color: g.$text-red-primary;
  }
}

// キャンペーンコード
.update-channel {
  padding-bottom: 40px;

  @media #{g.$sp} {
    padding-block: 20px 40px;
  }
}

.update-channel__wrap {
  padding: 0 0 20px 0;
  width: 100%;

  display: flex;
  align-items: flex-end;
  gap: 10px;

  @media #{g.$sp} {
    padding: 10px 10px 0 10px;
    flex-wrap: wrap;
  }
}

.update-channel__title {
  font-size: 14px;
  padding-bottom: 4px;
}

.update-channel form {
  width: 100%;

  display: flex;
  align-items: center;

  @media #{g.$sp} {
    width: 100%;
  }
}

.update-channel__input-box {
  width: 50%;

  @media #{g.$sp} {
    width: 100%;
  }
}


.update-channel__input {
  border: 1px solid g.$border-gray;
  border-right: 0;
  border-radius: 2px 0 0 2px;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px;

  max-width: 500px;
  width: 100%;

  @media #{g.$sp} {
    max-width: 100%;
  }
}

input.update-channel__submit {
  background-color: g.$button-orange;
  border: 1px solid g.$border-gray;
  border-radius: 0 2px 2px 0;
  color: g.$text-white-primary;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 16px;

  text-align: center;
}

.update-channel__result {
  padding-bottom: 10px;
  width: 50%;

  @media #{g.$sp} {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
}

.update-channel__result #message {
  @media #{g.$sp} {
    padding-bottom: 10px;
  }
}

.update-channel__result .result-success {
  color: g.$text-green-quaternary;
}