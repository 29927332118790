@use "global" as g;
/*
  ---------------
  既定のお支払方法
  ---------------
*/
body#account__manage_paymentmethod {
  #add-new-payment-method {
    padding-top: 40px;
  }

  #add-new-payment-method .wrapper {
    border: 1px solid g.$border-gray;
    border-radius: 2px;
    margin-top: 20px;
  }

  h4 {
    background: g.$bg-gray-primary;
    font-size: 16px;
    font-weight: 400;
    padding: 14px 18px;
  }

  select.date {
    padding-block: 8px;
  }

  label:has(input[disabled]),
  input[disabled] {
    pointer-events: none;
  }
}