@use "global" as g;

/*
  ---------------
  セレクトショップ 商品詳細 | メインスライダー
  ---------------
*/
.select-product .p-product {

  &__block {
    margin-top: 50px;

    @media all and (min-width: 1025px) {
      display: flex;
      justify-content: center;
      gap: min(5.71428571vw, 80px);
    }

    @media #{g.$sp} {
      margin-top: 20px;
    }
  }

  &__display {
    min-width: 0;
    flex: 0 0 530px;
  }

  // メインスライド
  &__display-slider {
    position: relative;

    @media all and (min-width: 1025px) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    @media all and (max-width: 1024px) and (min-width: 769px) {
      max-width: 446px;
      margin-inline: auto;
    }
  }

  &__display-slides {
    min-width: 0;
    margin-bottom: 0;

    @media #{g.$pc} {
      max-width: 446px;
    }

    @media all and (min-width: 1025px) {
      flex-shrink: 0;
    }
  }

  &__display-slide {
    border: 1px solid g.$border-gray;
    width: 100%;
    height: 100%;

    @media #{g.$pc} {
      max-width: 446px;
    }
  }

  &__display-slide img,
  &__display-slide div.no-img {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__display-slide div.no-img {
    background-color: #eee;
    box-shadow: 0 2px 4px rgba(127, 127, 127, 0.3);
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  // slickカスタマイズ
  &__display .slick-slider {
    // ピンチイン / ピンチアウト有効化
    touch-action: auto;


    .slick-arrow {
      top: 50%;
      transform: translateY(-50%);
    }

    .slick-prev,
    .slick-next {
      @media #{g.$pc} {
        width: 50px;
        height: 50px;
      }

      @media #{g.$sp} {
        width: 30px;
        height: 60px;
      }
    }

    .slick-prev {
      left: -25px;

      @media #{g.$sp} {
        left: -12px;
      }
    }

    .slick-next {
      right: -25px;

      @media #{g.$sp} {
        right: -12px;
      }
    }

    .slick-prev:before {
      content: "";
      display: inline-block;

      @media #{g.$pc} {
        background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
        width: 50px;
        height: 50px;
      }

      @media #{g.$sp} {
        background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
        width: 30px;
        height: 60px;
      }
    }

    .slick-next:before {
      content: "";
      display: inline-block;

      @media #{g.$pc} {
        background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
        width: 50px;
        height: 50px;
      }

      @media #{g.$sp} {
        background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
        width: 30px;
        height: 60px;
      }
    }
  }

  // ドットのカスタマイズ
  &__display .p-product__display-dots {
    min-width: 0;

    @media all and (min-width: 1025px) {
      flex-shrink: 0;
      flex: 0 0 76px;

      height: 446px;
      overflow-y: auto;
    }

    @media (max-width: 1024px) {
      margin-top: 15px;
    }

    ul {
      position: static;

      @media all and (min-width: 1025px) {
        padding-right: 10px;

        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      @media all and (max-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
      }

      @media #{g.$sp} {
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
      }
    }

    li {
      border: 1px solid g.$border-gray;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    li:not(.slick-active) {
      opacity: 0.5;
    }

    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
    }
  }
}
