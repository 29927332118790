@use "global" as g;
/*
  ----------------
  デアゴミュージアム 共通
  ----------------
*/
// タイトルの装飾
.news-archive-archive h1,
.dm__title {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.43333333;
  padding: 0;
  padding-bottom: 10px;

  position: relative;

  @media #{g.$sp} {
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
  }
}

.news-archive-archive h1::before,
.dm__title::before,
.news-archive-archive h1::after,
.dm__title::after {
  content: "";
  display: inline-block;
  height: 2px;

  position: absolute;
  bottom: 0;
  left: 0;
}

.news-archive-archive h1::before,
.dm__title::before {
  background-color: g.$bg-gray-primary;
  width: 100%;
}

.news-archive-archive h1::after,
.dm__title::after {
  background-color: g.$bg-green-secondary;
  width: 108px;
}
