@use "global" as g;

/*
  ---------------
  公開プイロフィールの編集
  ---------------
*/
body#account__edit_profile {
  .form-edit-account .formrow-submit {
    padding: 0;
  }

  .btn-submit {
    margin-top: 40px;
  }
}