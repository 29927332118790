@use "global" as g;
/*
  ----------------
  Drawer common
  ----------------
*/
.l-drawer {
  @media #{g.$sp} {
    background: g.$bg-gray-primary;
    width: 100%;
    max-width: 393px;
    margin-top: 104px;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    z-index: 110;
    transform: translateX(105%);
    transition: transform .5s;

    display: flex;
    flex-direction: column;
  }

  html.is-open--drawer & {
    transform: translateX(0);
  }

  &__inner {
    @media #{g.$sp} {
      min-height: 100%;
      padding: 16px 11px;

      display: flex;
      flex-direction: column;
    }
  }

  &__global-list {
    @media #{g.$sp} {
      background-color: g.$bg-white-primary;
      border: 1px solid g.$border-gray;
      border-radius: 5px;
    }
  }

  &__global-item {
    @media #{g.$sp} {
      border-bottom: 1px solid g.$border-gray;
    }

    &:last-child {
      @media #{g.$sp} {
        border-bottom: 0;
      }
    }
  }

  &__global-item-link {
    @media #{g.$sp} {
      color: g.$text-green-primary;
      display: block;
      font-weight: 500;
      padding: 12px;
    }
  }

  &__usage {
    @media #{g.$sp} {
      margin-top: 20px;
    }
  }

  &__usage-item-link {
    @media #{g.$sp} {
      color: g.$text-green-primary;
      display: block;
      font-weight: 500;
      padding: 6px 0;
    }
  }

  &__btn-group {
    @media #{g.$sp} {
      margin-top: auto;

      display: flex;
      gap: 10px;
    }
  }

  &__signup {
    @media #{g.$sp} {
      width: 100%;
    }
  }

  &__signup-link {
    @media #{g.$sp} {
      display: block;
      background-color: g.$bg-green-primary;
      border-radius: 5px;
      color: g.$text-white-primary;
      width: 100%;
      min-height: 56px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__bookstore {
    @media #{g.$sp} {
      width: 100%;
    }
  }

  &__bookstore-link {
    @media #{g.$sp} {
      display: block;
      background-color: g.$bg-black-primary;
      border-radius: 5px;
      color: g.$text-white-primary;
      width: 100%;
      min-height: 56px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  html.is-open--drawer & {

  }
}
