@use "global" as g;
/*
  ----------------
  signin
  ----------------
*/
#signup .signin-form {
  margin-block-start: 40px;

  @media #{g.$pc} {
    margin-block-start: 100px;
  }
}

#signup .signin-form .form-signin .formrow .input-control .input-group {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#signup .signin-form .form-signin .formrow .input-control .input-group:has(.password-str-meter) {
  gap: 0;
}

#signup .signin-form .form-signin .formrow .input-control .input-group input {
  background-color: g.$bg-white-primary;
  border: 1px solid g.$border-gray;
}

#signup .signin-form .form-signin .formrow .input-label,
#signup .signin-form .form-signin .formrow .input-label {
  display: flex;
  align-items: center;
}

#signup .signin-form .form-signin .formrow .input-label .input-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  margin-inline-end: 5px;
}

#signup .signin-form form > .btn-submit {
  margin-top: 60px;
}

#signup .signin-form .reminder {
  text-align: center;
  padding: 40px 0 0;
}

#signup .signin-form .reminder a {
  font-size: 16px;
  text-decoration: underline;
}
