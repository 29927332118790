@use "global" as g;
/*
  ----------------
  デアゴミュージアム 一覧
  ----------------
*/
.dm__wrraper {
  @include g.container;
}

.dm__noentry {
  background-color: #ffebeb;
  color: #c10000;
  margin-top: 40px;
  padding: 20px 0;
  text-align: center;
}

.dm__heading {
  margin-top: 40px;
}

.dm__title-desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-top: 15px;
}

.dm__filter {
  background-color: g.$bg-gray-primary;
  margin-top: 60px;
  padding: 20px;

  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 900px) {
    padding: 10px;

    flex-wrap: wrap;
    gap: 10px;
  }
}

.dm__filter form {
  width: 100%;
}

.dm__search {
  width: 100%;

  display: flex;
  align-items: center;
}

// 検索窓
.dm__search-input-wrap {
  width: 100%;
}

.dm__search-input-wrap input {
  border: 1px solid g.$border-gray;
  border-right: 0;
  border-radius: 2px 0 0 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.41666667;
  width: 100%;
  padding: 16px 16px 15px;
}

// 検索ボタン
.dm__submit-input-wrap {
  position: relative;
}

.dm__submit-input-wrap::before {
  background: url("/f/resources/images/common/icon_search.svg") center / contain no-repeat;
  content: "";
  cursor: pointer;
  display: inline-block;
  width: 15px;
  height: 15px;

  position: absolute;
  top: 52%;
  left: 34px;
  transform: translateY(-50%);

  @media #{g.$sp} {
    width: 20px;
    height: 20px;

    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.dm__submit-input-wrap input {
  background-color: g.$bg-green-primary;
  border: 1px solid g.$border-gray;
  border-radius: 0 2px 2px 0;

  @media #{g.$pc} {
    color: g.$text-white-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4375;
    text-align: center;
    min-width: 120px;
    padding: 13px 34px 12px 52px;
  }

  @media #{g.$sp} {
    font-size: 0;
    width: 50px;
    height: 50px;
  }
}

.dm__result-filter {
  background-color: g.$bg-gray-primary;
  padding: 0 20px 15px;

  @media (min-width: 901px) {
    margin-top: -5px;
  }

  @media (max-width: 900px) {
    padding: 5px 10px 15px;
  }
}

.dm__result-keyword {
  font-size: 14px;
  line-height: 1.42857143;
}

.dm__select-wrap {
  position: relative;

  @media #{g.$pc} {
    flex: 1 0 210px;
  }

  @media (max-width: 900px) {
    width: calc((100% - 10px) / 2);
  }
}

.dm__select-wrap::after {
  background: url("/f/resources/images/common/icon_arrow_green_bottom_bg_white.svg") center / contain no-repeat;
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;

  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 1;

  @media #{g.$sp} {
    right: 5px;
  }
}

.dm__select {
  border: 1px solid g.$border-gray;
  border-radius: 2px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375;
  width: 100%;
  padding: 13px 34px 12px 14px;

  appearance: none;

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 15px 31px 13px 12px;
  }
}

.dm__newslist {
  margin-top: 40px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  @media #{g.$sp} {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 20px;
  }
}

.dm__item a {
  display: block;
  width: 100%;
}

.dm__thumbnail {
  background-color: g.$bg-gray-secondary;
  border: 1px solid g.$border-gray;
  padding: 28px;

  @media #{g.$sp} {
    padding: 15px;
  }
}

.dm__thumbnail img {
  aspect-ratio: 1 / 1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dm__name {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-top: 8px;
}
