@use 'global' as g;

/*
  ---------------
  パートワークお支払い方法
  ---------------
*/
.account__edit_partwork_payment {
  #outer {
    @include g.container;
  }

  h1 {
    @media #{g.$sp} {
      font-size: 20px;
    }
  }

  .main-payment-method .wrapper {
    margin: 0;
    max-width: 100%;
  }

  .main-payment-method .wrapper + .wrapper {
    border-top: 1px solid g.$border-gray;
  }

  h4 {
    background: g.$bg-gray-primary;
    font-size: 16px;
    font-weight: 400;
    padding: 14px 18px;
  }

  #update-rp-payment {
    border: 1px solid g.$border-gray;
    margin-top: 40px;
  }
}
