@use "global" as g;

/*
  ---------------
  コミュニティ | コメント詳細
  ---------------
*/

body#community__comment {
  .community .link-search {
    background-color: transparent;
    border: none;
    height: 50px;
    padding: 0;

    display: flex;
    justify-content: center;

    @media #{g.$sp} {
      height: 40px;
    }
  }

  .comment > .comment-content {
    background-color: g.$bg-gray-primary;
    margin-top: 40px;
    padding: 16px 24px;
    position: relative;

    @media #{g.$sp} {
      margin-top: 20px;
      padding: 14px 16px;
    }
  }

  .thread-comment {
    display: flex;
    flex-direction: column-reverse;
  }

  .thread-comment li {
    padding-block: 16px;

    display: flex;
    justify-content: center;
  }

  .content > .comment-content {
    background-color: g.$bg-white-primary;
    border: 1px solid g.$border-gray;
    margin-top: 8px;
    padding: 22px;

    @media #{g.$sp} {
      margin-top: 6px;
      padding: 8px 14px;
    }
  }

  .supplement {
    position: relative;
  }

  .supplement .info {
    display: flex;
    flex-direction: column-reverse;
  }

  .supplement .timestamp {
    color: g.$text-gray-primary;
    font-size: 12px;
  }

  .supplement .timestamp::before {
    content: '投稿日時：'; 
  }

  .supplement .owner-profile {
    font-size: 12px;
    font-weight: 400;
  }

  .supplement .owner-profile .nickname::before {
    content: '投稿者：';
  }

  .supplement .trailer {
    padding-top: 8px;
  }

  .supplement .reactions {
    position: absolute;
    top: 0;
    right: 0;
  }

  .supplement .reactions li {
    @include g.hoverLink;
    background-color: g.$bg-green-primary;
    border-radius: 2px;
    color: g.$text-white-primary;
    height: 30px;
    padding: 6px 10px 6px 26px;
    position: relative;

    display: flex;
    align-items: center;
    gap: 20px;

    &::before {
      background: url(/f/resources/images/common/icon_heart_white.svg) 50% / contain no-repeat;
      content: '';
      display: block;

      height: 13px;
      width: 14px;

      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
    }
  }

  .trailer .link a {
    color: g.$text-green-primary;
    font-size: 14px;
  }
}
