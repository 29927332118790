@use "global" as g;
/*
  ----------------
  注文に関するお問い合わせ
  ----------------
*/
body#account__order_inquiry {
  #outer {
    padding-block: 0;
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.45833333; // 35px
    padding: 0 40px;


    @media #{g.$sp} {
      font-size: 20px;
      line-height: 1.45; // 29px
      padding-inline: 12px;
    }
  }

  .order-inq {
    padding-top: 20px;
    padding-inline: 40px;

    @media #{g.$sp} {
      padding-inline: 12px;
    }
  }

  .order.order-valid {
    margin-top: 40px;
    padding-inline: 20px;

    @media #{g.$sp} {
      padding-inline: 0;
    }
  }

  .btn-submit {
    margin: 40px 0 20px;
    max-width: fit-content;
    padding-inline: 40px;

    position: relative;
    transform: translateX(-50%);
    left: 50%;
  }

  #page_aside2 .btn-account {
    margin-top: 0;
  }

  .order-shipments {
    padding-top: 40px;
  }

  .panel .panel-heading > div:not(.total-inv-amt):not(.total-inv-amt+div) {
    width: 100%;

    @media #{g.$pc} {
      display: flex;
      justify-content: flex-end;
    }
  }
}