@use "global"as g;

/*
  ---------------
  ご利用ガイド | 共通
  ---------------
*/
// ers-dev.css 追加
.top-internal-banner {
  margin-top: 40px;
}

.top-internal-banner ul {
  display: flex;
  justify-content: center;
}

.top-internal-banner img {
  height: auto;
  width: 360px;
}

.top-internal-banner li + li {
  margin-left: 16px;
}
// ers-dev.css ここまで

.guide {
  .section-title {
    border-left: 4px solid g.$border-green;
    font-size: 30px;
    font-weight: 500;
    padding-left: 20px;

    @media #{g.$sp} {
      font-size: 24px;
      padding-left: 16px;
    }
  }
}

.news-archive-faq,
.news-archive-faq_imported,
.newsentries.faq,
.faq_imported {
  #outer {
    @include g.container();
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
    padding: 0;

    @media #{g.$sp} {
      padding-right: 154px;
    }
  }
}

/*---------------
  FAQ
---------------*/
.guide-faq .guide-faq__wrap {
  @include g.container();
  padding-top: 67px;

  @media #{g.$sp} {
    padding-top: 50px;
  }
}

.guide-faq .guide-faq__list {
  padding-top: 26px;

  @media #{g.$sp} {
    padding-top: 16px;
  }
}

// Question
.newsentries.faq #container .news-entries .news-entry,
.faq_imported #container .news-entries .news-entry,
.guide-faq .guide-faq__item {
  border-top: 1px solid g.$border-gray;
  position: relative;
}

.newsentries.faq #container .news-entries .news-entry:last-child,
.faq_imported #container .news-entries .news-entry:last-child,
.guide-faq .guide-faq__item:last-child {
  border-bottom: 1px solid g.$border-gray;
}

body.news .news-archive-faq .news-entry-header::before,
body.news .news-archive-faq_imported .news-entry-header::before,
.newsentries.faq #container .news-entries .news-entry::before,
.faq_imported #container .news-entries .news-entry::before,
.guide-faq .guide-faq__item::before {
  background: g.$bg-green-primary;
  border-radius: 2px;
  color: g.$text-white-primary;
  content: 'Q';
  font-size: 14px;
  font-weight: 700;
  line-height: 1;

  display: grid;
  place-items: center;

  height: 24px;
  width: 24px;

  position: absolute;
  top: 18px;
  left: 12px;
}

.guide-faq .guide-faq__item:last-child {
  border-bottom: 1px solid g.$border-gray;
}

.guide-faq .guide-faq__title {
  cursor: pointer;
}

body.news .news-archive-faq .news-entry .news-entry-subject,
body.news .news-archive-faq_imported .news-entry .news-entry-subject,
.newsentries.faq #container .news-entries .news-entry .entry-text span.text,
.faq_imported #container .news-entry .entry-text span.text,
.guide-faq .guide-faq__title {
  color: g.$text-green-primary;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  padding: 18px 40px 18px 47px;
  position: relative;
  width: 100%;
}

// アコーディオンアイコン
.guide-faq .guide-faq__title::before,
.guide-faq .guide-faq__title::after {
  background: g.$bg-green-primary;
  content: '';
  display: block;

  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(0);
}

.guide-faq .guide-faq__title::before {
  right: 20px;

  height: 1px;
  width: 15px;
}

.newsentries.faq #container .news-entries .news-entry .entry-text span.text::after,
.faq_imported #container .news-entry .entry-text span.text::after,
.guide-faq .guide-faq__title::after {
  right: 27px;
  transition: transform 0.3s ease;

  height: 15px;
  width: 1px;
}

.is-open .guide-faq__title::after {
  transform: translateY(-50%) rotate(90deg);
}

// Answer
body.news .news-archive-faq .news-entry-body,
body.news .news-archive-faq_imported .news-entry-body,
.guide-faq .guide-faq__text {
  background: g.$bg-gray-primary;
  display: none;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px;
  padding: 15px 12px 15px;
  position: relative;
}

body.news .news-archive-faq .news-entry-body:not(.appendix)::before,
body.news .news-archive-faq_imported .news-entry-body:not(.appendix)::before
.guide-faq .guide-faq__text:not(.appendix)::before {
  background: g.$bg-gray-tertiary;
  border-radius: 2px;
  color: g.$text-white-primary;
  content: 'A';
  font-size: 14px;
  font-weight: 700;
  line-height: 1;

  display: grid;
  place-items: center;

  height: 24px;
  width: 24px;

  position: absolute;
  top: 14px;
  left: 12px;
}

body.news .news-archive-faq .news-entry-body a,
body.news .news-archive-faq_imported .news-entry-body a,
.guide-faq .guide-faq__text a {
  color: g.$text-green-primary;
  text-decoration: underline;
}

.guide-faq .trailer-link {
  padding-block: 50px 0;
  display: flex;
  justify-content: center;

  @media #{g.$sp} {
    padding-block: 30px 0;
  }
}
