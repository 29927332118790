@mixin hoverButton($type: default) {
  @media (hover: hover) and (pointer: fine) {
    backface-visibility: hidden;
    transition: opacity 0.2s ease-in-out;

    @if $type==default {
      cursor: pointer;
    }

    &:hover {
      opacity: 0.6;
      transition: opacity 0.2s ease-in-out;
    }
  }

  &::before,
  &::after {
    backface-visibility: hidden;
  }
}