@use "global" as g;
/*
  ----------------
  utility
  ----------------
*/


// --------------------
// margin
// --------------------
// margin-top - PC
.u-mt--10 {
  @media #{g.$pc} {
    margin-top: 10px;
  }
}
.u-mt--20 {
  @media #{g.$pc} {
    margin-top: 10px;
  }
}
.u-mt--30 {
  @media #{g.$pc} {
    margin-top: 30px;
  }
}
.u-mt--40 {
  @media #{g.$pc} {
    margin-top: 40px;
  }
}
.u-mt--50 {
  @media #{g.$pc} {
    margin-top: 50px;
  }
}
.u-mt--60 {
  @media #{g.$pc} {
    margin-top: 60px;
  }
}
.u-mt--70 {
  @media #{g.$pc} {
    margin-top: 70px;
  }
}
.u-mt--80 {
  @media #{g.$pc} {
    margin-top: 80px;
  }
}
.u-mt--90 {
  @media #{g.$pc} {
    margin-top: 90px;
  }
}
.u-mt--100 {
  @media #{g.$pc} {
    margin-top: 100px;
  }
}

// margin-top - SP
.u-mt--10-sp {
  @media #{g.$sp} {
    margin-top: 10px;
  }
}
.u-mt--20-sp {
  @media #{g.$sp} {
    margin-top: 20px;
  }
}
.u-mt--30-sp {
  @media #{g.$sp} {
    margin-top: 30px;
  }
}
.u-mt--40-sp {
  @media #{g.$sp} {
    margin-top: 30px;
  }
}
.u-mt--50-sp {
  @media #{g.$sp} {
    margin-top: 30px;
  }
}


// --------------------
//  height
// --------------------
// PC
.u-h--100p {
  @media #{g.$pc} {
    height: 100%;
  }
}

// SP
.u-h--100p-sp {
  @media #{g.$sp} {
    height: 100%;
  }
}

.u-container {
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: clamp(0px, 2.77777778vw, 40px);
  width: 100%;

  @media #{g.$sp} {
    padding-inline: 12px;
  }
}
