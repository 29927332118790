@use "global" as g;

/*
  ---------------
  バックトイ TOP | バナー スライダー
  ---------------
*/
// ers-dev.css 追加
/* パックトイ TOP */
.site-banners-FLOWPACK_ROTATION_01 .banners ul {
  width: 100%;
}

.site-banners-FLOWPACK_ROTATION_01 img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.flowpack__series-lists {
  display: flex;
  justify-content: space-between;
}

.flowpack__series-item {
  width: calc(25% - 20px);
}

.flowpack__series-link {
  display: block;
}

.flowpack__series-img {
  object-fit: cover;
  height: 120px;
  width: 100%;
}

.flowpack__newitem-lists {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flowpack__newitem-item {
  // width: calc(33.3333333333% - 20px);
  width: calc((100% - 90px) / 3);
}

.flowpack__newitem-item + .flowpack__newitem-item {
  margin-left: 45px;

  @media #{g.$sp} {
    margin-left: 0;
  }
}

.flowpack__newitem-item:nth-child(3n+1) {
  margin-left: 0;
}

.flowpack__newitem-item:nth-child(n+4) {
  margin-top: 45px;

  @media #{g.$sp} {
    margin-top: 0;
  }
}

.flowpack__newitem-img {
  height: 200px;
  width: 100%;
}

.flowpack__newitem-button-wrap {
  text-align: center;
  padding: 20px 0;
}

.flowpack__newitem-all {
  border: 1px solid #333;
  border-radius: 12px;
  color: #333;
  display: block;
  padding: 20px;
  text-decoration: none;
}

.flowpack__about-free {
  height: 500px;
  line-height: 500px;
  text-align: center;
  background-color: #ddd;
}

.pt-msg {
  border: 2px solid red;
  padding: 1em;
  border-radius: 0.25rem;
  background-color: rgba(255, 238, 238, 0.9333333333);
}

.pt-msg dt {
  font-weight: bold;
  color: #a00;
}

.pt-msg dd {
  font-size: 0.9rem;
}

.pt-msg ul li {
  list-style-type: disc;
  margin-left: 2em;
}
// ers-dev.css ここまで

.site-banners-FLOWPACK_ROTATION_01 {
  /*==========================================
  デフォルトのスタイル削除
  ==========================================*/
  a {
    display: block;
    height: auto;
  }

  .banner-img a {
    height: auto;
  }

  /*==========================================
  スタイル追加
  ==========================================*/
  .heading {
    display: none;
  }

  .banners ul {
    width: 100%;
  }

  // slick
  .banners ul.slider:not(.slick-initialized) > li + li {
    display: none;
  }

  .slick-slider {
    margin-bottom: 0;
    padding-bottom: 55px;

    @media #{g.$sp} {
      padding-bottom: 42px;
    }
  }

  .slick-slide {
    position: relative;
  }

  // mask
  ul .slick-slide:not(.slick-active)::before {
    content: "";
    display: block;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-arrow {
    top: calc(50% - 25px);
    transform: translateY(-50%);

    @media #{g.$sp} {
      top: calc(50% - 21px);
    }
  }

  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;
    z-index: 2;

    @media #{g.$sp} {
      width: 30px;
      height: 60px;
    }
  }

  .slick-prev {
    left: -25px;

    @media #{g.$sp} {
      left: 0;
    }
  }

  .slick-next {
    right: -25px;

    @media #{g.$sp} {
      right: 0;
    }
  }

  .slick-prev:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_prev.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_prev_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .slick-next:before {
    content: "";
    display: inline-block;

    @media #{g.$pc} {
      background: transparent url("/f/resources/images/common/icon_next.svg") center / contain no-repeat;
      width: 50px;
      height: 50px;
    }

    @media #{g.$sp} {
      background: transparent url("/f/resources/images/common/icon_next_sp.svg") center / contain no-repeat;
      width: 30px;
      height: 60px;
    }
  }

  .thumbnail:not(.slick-initialized) {
    display: none;
  }

  .thumbnail .slick-slide {
    margin-inline: 0;

    @media #{g.$sp} {
      max-width: 38.1679389vw;
      margin-inline: 4px;
    }
  }

  .thumbnail .item-tags {
    top: auto;
    right: 0;
    bottom: 5px;
  }

  .slick-dots {
    font-size: 0;
    bottom: 20px;
  }

  .slick-dots li {
    height: auto;
    margin: 0 4px;
    padding: 5px 0;
    width: auto;
  }

  .slick-dots li button {
    background-color: #d9d9d9;
    height: 4px;
    padding: 0;
    width: 40px;

    @media #{g.$pc} {
      height: 5px;
    }
  }

  .slick-dots li button:before {
    display: none;
  }

  .slick-dots li.slick-active button {
    background-color: #ffbd08;
  }

  .thumbnail {
    background-color: #f6f6f6;
    padding: 20px 0;

    @media #{g.$sp} {
      padding: 10px 0;
    }

    img {
      object-fit: contain;
      height: 100%;
    }

    .slick-prev,
    .slick-next {
      height: 70px;
      width: 70px;

      top: 50%;
      left: 30px;
      transform: translateY(-50%);

      @media #{g.$sp} {
        height: 50px;
        width: 50px;
        left: 1px;
      }

      &::before {
        background: transparent url(/f/resources/images/packtoy/top/icon_arrow_left.svg) center/contain no-repeat;
        content: "";
        display: inline-block;
        height: inherit;
        width: inherit;
      }
    }

    .slick-next {
      right: 30px;
      left: auto;

      @media #{g.$sp} {
        right: 1px;
      }

      &::before {
        background: transparent url(/f/resources/images/packtoy/top/icon_arrow_right.svg) center/contain no-repeat;
      }
    }
  }
}

.flowpack {
  .flowpack__series {

    .site-banners-FLOWPACK_ROTATION_01 .banners {
      @media #{g.$pc} {
        max-width: 1280px;
        margin-inline: auto;
        padding-inline: clamp(0px, 2.77777778vw, 40px);
      }
    }

    .site-banners-FLOWPACK_ROTATION_01 .slider img {
      width: 100%;
      // height: 35.9027778vw;
      // max-height: 520px;

      @media #{g.$sp} {
        // height: 106.666667vw;
      }
    }

    .site-banners-FLOWPACK_ROTATION_01 .thumbnail {
      margin: 0 calc(50% - 50vw);
      width: 100vw;
    }

    .site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-list {
      @media #{g.$pc} {
        @include g.container();
      }
    }

    .site-banners-FLOWPACK_ROTATION_01 .thumbnail .slick-track {
      display: flex;
      justify-content: center;
    }
  }

  .flowpack__series-head {
    display: none;
  }

  .flowpack__newitem {
    max-width: 1280px;
    margin: 0 auto;
    padding: 46px clamp(0px, 2.77777778vw, 40px) 58px;
    position: relative;

    @media #{g.$sp} {
      padding: 25px 12px 30px;
    }
  }

  .flowpack__newitem-head {
    color: g.$text-green-secondary;
    font-family: 'M PLUS 2';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    padding: 0;

    @media #{g.$sp} {
      font-size: 20px;
      text-align: center;
    }
  }

  .flowpack__newitem-lists {
    margin-top: 46px;
    padding: 0;

    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    // gap: 45px 0;

    @media #{g.$sp} {
      margin-top: 27px;
      justify-content: space-between;
      gap: 20px;
    }
  }

  .flowpack__newitem-item {
    background: g.$text-white-primary;
    border: 1px solid #f6f6f6;
    border-radius: 30px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    padding-bottom: 24px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    @media #{g.$sp} {
      width: calc(50% - 10px);
      padding-bottom: 12px;
    }
  }

  .flowpack__newitem-item > p {
    font-size: 14px;
    line-height: 1.42857143;
    margin-top: 5px;
    padding-inline: 24px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @media #{g.$sp} {
      font-size: 10px;
      padding-inline: 12px;
    }
  }

  .flowpack__newitem-item > p > a {
    color: g.$text-green-primary;
    text-decoration: underline;
  }

  .flowpack__newitem-item > .item-tags {
    order: 1;
    top: 8px;
    right: -2px;

    @media #{g.$sp} {
      top: 2px;
    }
  }
  .flowpack__newitem-item > .flowpack__newitem-link {
    order: 2;
  }
  .flowpack__newitem-item > p:nth-child(3) {
    text-align: right;
    order: 4;
  }
  .flowpack__newitem-item > p:nth-child(4) {
    display: none;
  }
  .flowpack__newitem-item > p:nth-child(5) {
    flex-grow: 1;
    order: 3;
  }
  .flowpack__newitem-item > p:nth-child(6) {
    display: none;
  }

  .flowpack__newitem-link {
    display: block;
  }

  .flowpack__newitem-img {
    aspect-ratio: 370 / 290;
    border-radius: 30px 30px 0 0;
    width: 100%;
    height: auto;
    object-fit: contain;

    @media #{g.$sp} {
      aspect-ratio: 175 / 138;
      border-radius: 20px 20px 0 0;
    }
  }

  .flowpack__newitem-name {
    color: g.$text-black-primary;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 24px;
    padding-inline: 24px;
    position: relative;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @media #{g.$sp} {
      font-size: 12px;
      margin-top: 12px;
      padding-inline: 12px;
    }
  }

  .flowpack__newitem-new {
    height: 46px;
    width: 90px;

    position: absolute;
    top: -23px;
    left: 16px;

    @media #{g.$sp} {
      height: 25px;
      width: 49px;

      top: -12px;
      left: 8px;
    }
  }

  .flowpack__newitem-button-wrap {
    padding: 0;

    @media #{g.$pc} {
      position: absolute;
      top: 40px;
      right: 40px;
    }

    @media #{g.$sp} {
      margin-top: 30px;
    }
  }

  .flowpack__newitem-button-wrap > a {
    background-color: #00ada0;
    border: none;
    border-radius: 30px;
    color: g.$text-white-primary;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.33;
    padding: 0 48px 0 22px;
    position: relative;

    min-width: 296px;
    min-height: 50px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .flowpack__newitem-button-wrap > a::before {
    content: "";
    border-radius: 100%;
    background: g.$text-white-primary url("/f/resources/images/top/icon_arrow_right_bg_white.svg") center / contain no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;

    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .flowpack__about {
    text-align: center;

    @media #{g.$pc} {
      margin: 0 calc(50% - 50vw);
      width: 100vw;
    }
  }

  .flowpack__about-inner {
    // overflow: hidden;
    padding-top: 90px;
    padding-bottom: 122px;
    position: relative;

    @media #{g.$sp} {
      padding-top: 35px;
      padding-bottom: 311px;
    }

    &::before {
      content: "";
      background: url(/f/resources/images/packtoy/top/bg_about.svg) center bottom no-repeat;
      height: 100%;
      width: 100%;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    &::after {
      content: "";
      background: url(/f/resources/images/packtoy/top/bg_about_img.png) center top no-repeat;
      display: block;
      height: 522px;
      width: 1562px;

      position: absolute;
      top: -17px;
      left: 50%;
      transform: translateX(-51.805555556%);
      z-index: -1;

      @media #{g.$sp} {
        background: url(/f/resources/images/packtoy/top/bg_about_img_sp.png) center top no-repeat;
        height: 323px;
        width: 473px;

        top: auto;
        bottom: 34px;
        left: 50%;
        transform: translateX(-54.5%);
      }
    }
  }

  .flowpack__about-title {
    color: g.$text-green-secondary;
    text-align: center;
    font-family: 'M PLUS 2';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0;

    @media #{g.$sp} {
      font-size: 20px;
    }

    &::before,
    &::after {
      content: "";
      background: url("/f/resources/images/packtoy/top/ttl_border_left.svg") center / contain no-repeat;
      display: inline-block;
      margin-right: 35px;
      height: 30.21px;
      width: 41.356px;

      @media #{g.$sp} {
        margin-right: 5px;
        transform: translateY(25%);
      }
    }

    &::after {
      background: url("/f/resources/images/packtoy/top/ttl_border_right.svg") center / contain no-repeat;
      margin-right: auto;
      margin-left: 35px;

      @media #{g.$sp} {
        margin-left: 5px;
      }
    }
  }

  .flowpack__about-lead {
    color: g.$text-green-primary;
    font-family: 'M PLUS 2';
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 1.447916667;
    margin-top: 39px;

    @media #{g.$sp} {
      font-size: 18px;
      line-height: 1.3889;
      margin-top: 15px;
    }

    & > span {
      background-color: #ffe900;
      display: inline-block;
      line-height: 1.25;
      padding: 0 11px;
    }
  }

  .flowpack__about-text {
    color: #1d1d1b;
    font-family: 'M PLUS 2';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 2.2667;
    margin-top: 23px;

    @media #{g.$sp} {
      font-size: 14px;
      line-height: 2.1429;
      margin: 15px auto 0;
      max-width: 85.063291139%;
      text-align: left;
    }
  }

  .flowpack__about-under-text {
    color: g.$text-green-primary;
    font-family: 'M PLUS 2';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4167;
    margin-top: 22px;

    @media #{g.$sp} {
      font-size: 18px;
      margin-top: 12px;
    }
  }

  // お知らせ一覧
  .newslist-info {
    margin-top: 78px;
  }

  .newslist-info .panel-newslist {
    padding-top: 0;
  }

  .newslist-info .panel-heading + .panel-body {
    margin-bottom: 0;
  }

  .newslist-info .news-name span {
    color: g.$text-green-secondary;
    font-weight: 700;

    @media #{g.$sp} {
      font-size: 20px;
    }
  }

  // パックトイ専用お知らせ一覧ボタン
  .newslist-info .news-archives-link {
    @media #{g.$sp} {
      margin-top: 25px;
    }
  }

  .newslist-info .news-archives-link a {
    border-radius: 50px;
    color: g.$text-green-tertiary;
    font-size: 16px;
    font-weight: 500;
  }

  .newslist-info .news-archives-link a::after {
    background: url(/f/resources/images/packtoy/top/icon_arrow_news.svg) 50% / contain no-repeat;
  }
}
