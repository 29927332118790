@use "global" as g;

/*
  ---------------
  マイページトップ
  ---------------
*/
body#account__account .account-menus {
  padding-top: 40px;

  display: flex;
  flex-direction: column;
  gap: 70px;

  @media #{g.$sp} {
    gap: 40px;
    padding-block: 32px 50px;
  }
}

body#account__account h2.account-title {
  font-size: 24px;
  font-weight: 500;
  margin-top: 40px;
}

body#account__account .account-summary {
  background-color: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  margin-top: 20px;

  display: flex;
  align-items: center;

  @media #{g.$sp} {
    flex-direction: column;
    margin-top: 16px;
  }
}

body#account__account .account-summary__name {
  font-weight: 500;
}

body#account__account .account-summary__code {
  font-size: 14px;
}

body#account__account .account-summary__nc-box {
  padding: 15px 12px;
  width: 60%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media #{g.$tab} {
    width: 50%;
  }

  @media #{g.$sp} {
    padding: 8px;
    width: 100%;
  }
}

body#account__account .account-summary__nc-box:has(+ .account-summary__point-box) {
  border-right: 1px solid g.$border-gray;

  @media #{g.$sp} {
    border-right: none;
  }
}

body#account__account .account-summary__point-box {
  padding: 4px 12px 12px 12px;
  width: 40%;

  @media #{g.$tab} {
    width: 50%;
  }

  @media #{g.$sp} {
    border-top: 1px solid g.$border-gray;
    padding: 2px 8px 8px 8px;
    width: 100%;
  }
}

body#account__account .account-summary__haspoint {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

body#account__account .account-summary__haspoint-amount {
  color: g.$text-green-primary;
  font-weight: 500;

  .point-amount {
    font-size: 24px;
    margin-right: 4px;
  }
}

body#account__account .account-summary__haspoint-label,
body#account__account .account-summary__expoint-label {
  font-weight: 500;
}

body#account__account .account-summary__expirypoint {
  display: flex;
  justify-content: space-between;
}

body#account__account .account-summary__expoint-date {
  color: g.$text-green-primary;
  font-weight: 500;
}

body#account__account .account-summary__what-point {
  display: inline-block;
  font-size: 11px;
  text-align: right;
  text-decoration: underline;
  margin-left: 4px;
}

body#account__account .account-summary__what-point::after {
  content: url(/f/resources/images/common/icon_link_othertab_green.svg);
  display: inline-block;
  margin-left: 2px;
  transform: translateY(2px);
}

body#account__account .am-account {
  @media #{g.$sp} {
    padding-bottom: 10px;
  }
}

body#account__account .account-menu-list {
  padding-top: 20px;
  position: relative;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;


  @media all and (max-width : 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{g.$sp} {
    gap: 10px;
  }

  @media all and (max-width : 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

body#account__account .account-menu-list li {
  @include g.hoverButton();
  background: g.$bg-gray-primary;
  border: 1px solid g.$border-gray;
  padding: 20px 25px 20px 120px;
  position: relative;
  min-height: 120px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 6px;

  @media #{g.$sp} {
    gap: 0;
    min-height: 70px;
    padding: 10px 12px 10px 70px;
  }
}

body#account__account .account-menu-list li::before {
  background: url() 50% / contain no-repeat;
  content: '';
  display: block;

  height: 60px;
  width: 80px;

  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);

  @media #{g.$sp} {
    left: 10px;
    height: 35px;
    width: 45px;
  }
}

// 注文発送履歴
body#account__account li.menu-ViewOrderHistory::before {
  background-image: url(/f/resources/images/account/icon_truck.svg);
}

// 直送定期購読商品
body#account__account li.menu-ViewPartworkSubs::before {
  background-image: url(/f/resources/images/account/icon_book.svg);
}

// ログインID パスワード
body#account__account li.menu-EmailPassword::before {
  background-image: url(/f/resources/images/account/icon_lock.svg);
}

// 生年月日
body#account__account li.menu-EditAccountProperties::before {
  background-image: url(/f/resources/images/account/icon_clip-board.svg);
}

// ご登録住所
body#account__account li.menu-ManageAddressBook::before {
  background-image: url(/f/resources/images/account/icon_house.svg);

  @media #{g.$pc} {
    width: 70px;
  }
}

// お支払い方法
body#account__account li.menu-ManageDefaultPaymentMethod::before {
  background-image: url(/f/resources/images/account/icon_payment.svg);
}

// パスワードの発行
body#account__account li.menu-Reminder::before {
  background-image: url(/f/resources/images/account/icon_lock-change.svg);
}

// 抽選ゲーム
body#account__account li.menu-Game::before {
  background-image: url(/f/resources/images/account/icon_darts.svg);
}

// デジタルコンテンツ
body#account__account li.menu-DigitalContents::before {
  background-image: url(/f/resources/images/account/icon_device.svg);
}

// コミュニティ用　プロフィール
body#account__account li.menu-Profile::before {
  background-image: url(/f/resources/images/account/icon_profile.svg);

  @media #{g.$pc} {
    width: 70px;
  }
}

// コミュニティ　参加履歴
body#account__account li.menu-YourCommunities::before {
  background-image: url(/f/resources/images/account/icon_chat.svg);

  @media #{g.$pc} {
    height: 50px;
  }
}

// ポイント履歴
body#account__account li.menu-Points::before {
  background-image: url(/f/resources/images/account/icon_point.svg);
}

// お問い合わせ履歴
body#account__account li.menu-ViewInquiriesHistory::before {
  background-image: url(/f/resources/images/account/icon_letter.svg);
}

// クーポン一覧
body#account__account li.menu-ViewCoupons::before {
  background-image: url(/f/resources/images/account/icon_coupon.svg);
}

body#account__account li.menu-Signout,
body#account__account li.menu-CloseAccount {
  border: none;
  background: transparent;
  padding: 0;

  height: 20px;
  min-height: 20px;
  width: max-content;

  position: absolute;
  bottom: -20px;

  @media #{g.$sp} {
    bottom: -30px;
  }
}

body#account__account li.menu-Signout .note,
body#account__account li.menu-CloseAccount .note {
  display: none;
}

body#account__account li.menu-Signout {
  right: 0;
}


body#account__account li.menu-Signout::before {
  display: none;
}

body#account__account .menu-Signout a::before {
  background: url(/f/resources/images/common/icon_logout.svg) 50% / contain no-repeat;

  height: 14px;
  width: 14px;

  left: -20px;
}

body#account__account .account-menu-list li.menu-Signout a,
body#account__account .account-menu-list li.menu-CloseAccount a {
  font-size: 14px;
}

body#account__account .account-menu-list li.menu-Signout a::before,
body#account__account .account-menu-list li.menu-CloseAccount a::before {
  content: '';
  display: block;

  position: absolute;
  top: 56%;
  transform: translateY(-50%);

  @media #{g.$sp} {
    top: 50%;
  }
}


body#account__account .menu-CloseAccount {
  right: 30px;
}

body#account__account .menu-CloseAccount a::before {
  background: url(/f/resources/images/common/icon_arrow_right_circle_green.svg) 50% / contain no-repeat;
  right: -30px;

  height: 20px;
  width: 20px;

}

body#account__account .account-menu-list li a {
  color: g.$text-green-primary;
  font-size: 18px;
  font-weight: 500;
}

// li全体をクリック領域にする
body#account__account .account-menu-list li:not(.menu-Signout) a::before {
  content: '';
  display: block;

  position: absolute;
  inset: 0;
}

body#account__account .account-menu-list li .note {
  font-size: 14px;
  margin: 0;

  @media #{g.$sp} {
    font-size: 15px;
  }
}

body#account__account li.menu-blank {
  border: none;
  background: transparent;

  @media all and (max-width : 1270px) {
    display: none;
  }
}