@use "global" as g;
/*
  ----------------
  signup | 共通
  ----------------
*/
#signup #outer {
  @include g.container();
}

#signup div.formrow,
#signup .signup-form,
#signup .signin-form {
  padding: 0;
}

#signup .signup-form div.input-label,
#signup .signin-form div.input-label {
  display: block;
}

#signup .formrow-yyyymmdd div.input-label {
  display: flex;
}

#signup .signup-form form,
#signup .signin-form form {
  background-color: g.$bg-gray-primary;
  padding: 20px 0;
  margin-top: 20px;

  @media #{g.$pc} {
    padding: 25px 0 58px;
  }
}

#signup .signup-form .form-signup .formrow-input,
#signup .signup-form .form-signup .formrow-radio,
#signup .signup-form .form-signup .formrow-yyyymmdd,
#signup .signup-form .form-signup .formrow-checkbox,
#signup .signin-form .form-signin .formrow {
  display: block;
  padding-inline: 15px;

  @media #{g.$pc} {
    padding-inline: 45px;
  }
}

#signup h1,
#signup h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45; // 29px
  padding: 0;

  @media #{g.$pc} {
    font-size: 24px;
    line-height: 1.45833333; // 35px
  }
}

#signup .signup-form .form-signup .formrow,
#signup .signin-form .form-signin .formrow {
  margin-top: 15px;
}

#signup .signup-form .form-signup .formrow-input .input-control,
#signup .signup-form .form-signup .formrow-boolean .input-control,
#signup .signin-form .form-signin .formrow .input-control {
  display: flex;
  align-items: start;
  flex-direction: column-reverse;
}

#signup .signup-form .form-signup .formrow-input .input-control .input-note,
#signup .signup-form .form-signup .formrow-boolean .input-control .input-note,
#signup .signin-form .form-signin .formrow .input-control .input-note,
#signup .signup-form .formrow-submit .note {
  color: g.$text-black-primary;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  margin-left: 0;
  padding-left: 0;
}

#signup .form-note {
  margin: 0;
  margin-block-start: 22px;
}

#signup .signup-form .form-signup .input-label,
#signup .signup-form .form-signup .input-control,
#signup .signin-form .form-signin .input-label,
#signup .signin-form .form-signin .input-control {
  padding: 0;
}

#signup .signup-form .formrow-submit,
#signup .signin-form form > .btn-submit {
  padding: 0;
}

#signup div.formrow div.input-control div.input-group {
  flex: auto;
}

// 発売日お知らせメール
.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0,
.signup-form  #signup-form .form-signup .formrow.a9816990-05da-4433-9d0d-2ef9965732b0  {
  padding-bottom: 32px;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0 .input-label,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0 .input-label,
.signup-form .a9816990-05da-4433-9d0d-2ef9965732b0 .input-label {
  display: flex;
  width: fit-content;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name,
.signup-form .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name {
  display: inline-block;
  line-height: 2;
  position: relative;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::before,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::before,
.signup-form .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::before {
  background: url(/f/resources/images/common/icon_plus_circle_green.svg) 50% / contain no-repeat;
  content: '';
  cursor: pointer;
  display: block;

  height: 18px;
  width: 18px;

  position: absolute;
  top: 130%;
  left: 0;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-name::before,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-name::before,
.signup-form .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-name::before {
  background: url(/f/resources/images/common/icon_minus_circle_green.svg) 50% / contain no-repeat;
}

.account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::after,
.form-order-signup .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::after,
.signup-form .a9816990-05da-4433-9d0d-2ef9965732b0 .input-name::after {
  content: 'タイトルを選ぶ';
  color: g.$text-green-primary;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 130%;
  left: 32px;
  transform: translateY(-23%);
}

#account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group,
#form .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group,
#signin .form-signup .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group,
#signup .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group,
#order__signin_to_checkout .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group,
.form-auth.form-signin #signup-form .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control .input-group {
  display: none;
}

#account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0 .input-control,
#form .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0 .input-control,
#signin .form-signup .a9816990-05da-4433-9d0d-2ef9965732b0 .input-control,
#signup .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0 .input-control,
#order__signin_to_checkout .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox .input-control,
.form-auth.form-signin div.formrow.a9816990-05da-4433-9d0d-2ef9965732b0 div.input-control {
  flex-direction: column;
  margin-top: 40px;
}

#account__edit_account_properties .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-control .input-group,
#form .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-control .input-group,
#signin .form-signup .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-control .input-group,
#signup .signup-form .form-signup fieldset .a9816990-05da-4433-9d0d-2ef9965732b0.is-open .input-control .input-group,
#order__signin_to_checkout .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox.is-open .input-control .input-group,
.form-auth.form-signin #signup-form .a9816990-05da-4433-9d0d-2ef9965732b0.formrow-checkbox.is-open .input-control .input-group {
  display: flex;
  padding-bottom: 10px;
}
