@use "global" as g;

/*
  ---------------
  総合TOP | 共通
  ---------------
*/
// ers-dev.css 追加
nav#nav {
  background-color: var(--ers-deago-col1);
  color: var(--ers-deago-col2);
}

nav#nav a {
  color: inherit;
}

nav#nav ul.nav-menu {
  display: flex;
  justify-content: center;
  max-width: var(--ers-deago-maxwidth);
  margin: 0 auto;
}

nav#nav ul.nav-menu > li {
  padding: 1rem;
}

/* TOP */
.site-banners-TOP_ROTATION_01 .banners ul {
  width: 100%;
}

.site-banners-TOP_ROTATION_01 a {
  display: block;
  height: 600px;
}

.site-banners-TOP_ROTATION_01 img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.site-banners-TOP_SUB_01 .banners ul {
  display: flex;
}

.site-banners-TOP_SUB_01 .banners ul > li {
  padding: 16px;
  width: 50%;
}

.site-banners-TOP_SUB_01 .site-banner {
  border: 1px solid #333;
  border-radius: 10px;
  height: 550px;
  padding: 12px;
  position: relative;
}

.site-banners-TOP_SUB_01 .uri-to-img {
  width: 100%;
}

.site-banners-TOP_ICON_LIST {
  margin-top: 40px;
}

.site-banners-TOP_ICON_LIST .banners ul {
  display: flex;
  justify-content: space-between;
}

.site-banners-TOP_ICON_LIST .site-banner {
  text-align: center;
}

.site-banners-TOP_ICON_LIST .caption {
  margin-top: 8px;
}

.site-banners-TOP_ICON_LIST .uri-to-img {
  width: 80px;
}

.recommend_pw {
  margin-top: 40px;
}

.recommend_pw__wrap .item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.recommend_pw__wrap .item-list > li.item-list-each {
  border: 1px solid #333;
  border-radius: 4px;
  margin-top: 20px;
  width: 48%;
}

.recommend_pw__wrap ul.item-list li.item-list-each:last-child {
  border: 1px solid #333;
  padding-bottom: 1em;
}

.recommend_pw__wrap .item-list > li:nth-child(n+5) {
  display: none;
}

.search_title {
  margin-top: 40px;
  text-align: center;
}

.search_title__button {
  background-color: #333;
  border-radius: 8px;
  display: block;
  margin: 0 auto;
  padding: 24px 18px;
  width: 400px;
}

.search_title__inner {
  color: #fff;
  font-size: 22px;
}

.top-newslist div.panel-body {
  margin-bottom: 0;
}
// ers-dev.css ここまで

nav#nav {
  background-color: transparent;
}

// お知らせ
.top {
  .newslist.newslist-info .panel-body {
    margin-bottom: 0;
  }
}