@use "global" as g;

/*
  ---------------
  セレクトショップ 商品詳細 | 購入者向け特典
  ---------------
*/
.p-special-offers {
  margin-top: 60px;

  @media #{g.$sp} {
    margin-top: 40px;
  }

  &__inner {
    text-align: center;
  }

  &__inner > div {
    text-align: center;
  }

  &__title {}

  &__header {
    background-color: #f6f6f6;
    color: #b2b2b2;
    font-size: 20px;
    height: 150px;
    margin-top: 20px;

    @media #{g.$sp} {
      margin-top: 10px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    background-color: #f6f6f6;
    color: #b2b2b2;
    height: 258px;
    margin-top: 20px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__body h3 {
    font-size: 34px;
    padding: 0;
  }

  &__body p {
    font-size: 14px;
  }

  &__footer {
    max-width: 583px;
    margin: 40px auto 0;

    @media #{g.$sp} {
      max-width: 370px;
      margin-top: 20px;
    }
  }

  &__footer a {
    background-color: g.$bg-gray-primary;
    border: 1px solid g.$border-gray;
    font-size: 16px;
    line-height: 1.4375;
    text-align: left;
    width: 100%;
    min-height: 70px;
    padding-right: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    @media #{g.$sp} {
      font-size: 14px;
      line-height: 1.42857143;
      min-height: 50px;
    }
  }

  &__footer span {
    display: inline-block;
    padding-left: 26px;

    position: relative;
  }

  &__footer span::before {
    content: "";
    background: transparent url("/f/resources/images/common/icon_community.svg") center / cover no-repeat;
    display: inline-block;
    width: 22px;
    height: 20px;

    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
